<div class="home_wrap dashboard_wrap pt_50 fixed-head" id="popular_page">
  <div class="container-fluid">
    <div class="row pb-5">
      <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
        <div class="dashboard_filter_wrap">
          <div
            class="d-flex flex-wrap gap-4 align-items-center justify-content-between"
          >
            <div class="post_que_header">
              <h2 class="decideit_head mb-0"><span>Popular</span> Threads</h2>
              <!-- <div class="searchbar float-end">
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Search in dashboard">
                                <i class="fa-solid fa-magnifying-glass"></i>
                            </div>
                        </div> -->
              <div class="clearfix"></div>
            </div>
            <div class="check_box check_fillbg m-0">
              <input
                type="checkbox"
                name="user_option"
                id="user_option"
                (change)="adminFilter($event)"
              />
              <label for="user_option" class="checkboxbtn_label pb-0 pe-0"
                >Posted by Admin</label
              >
            </div>
          </div>
          <div class="dashboard_filter_tabs">
            <ul class="nav nav-pills float-start" id="pills-tab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="pills-open-tab"
                  data-bs-toggle="pill"
                  href="#pills-open"
                  role="tab"
                  aria-controls="pills-open"
                  aria-selected="true"
                  (click)="filterChange('top')"
                  ><i class="fa-solid fa-chart-area"></i> Popular</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="pills-completed-tab"
                  data-bs-toggle="pill"
                  href="#pills-completed"
                  role="tab"
                  aria-controls="pills-completed"
                  aria-selected="false"
                  (click)="filterChange('new')"
                  ><i class="fa-solid fa-bullhorn"></i> New</a
                >
              </li>
            </ul>

            <!-- <div class="dashboard_filter_sorting float-end">
              <kendo-dropdownlist name="community_id" [defaultItem]="defaultSelect" [data]="communityList"
                [textField]="'name'" [valueField]="'id'" [valuePrimitive]="true" [itemDisabled]="itemDisabled"
                [filterable]="true" (filterChange)="handleFilter($event)" (valueChange)="getCommunityQuestion($event)">
                <ng-template kendoDropDownListItemTemplate let-dataItem let-rowIndex="rowIndex">
                  <span [ngClass]="dataItem.itemClass">{{
                    dataItem.name
                    }}</span>
                </ng-template>
              </kendo-dropdownlist>
            </div> -->
            <div class="clearfix"></div>
          </div>
          <div
            class="search-results"
            infinite-scroll
            [infiniteScrollDistance]="scrollDistance"
            [infiniteScrollUpDistance]="scrollUpDistance"
            [infiniteScrollThrottle]="throttle"
            (scrolled)="onScrollDown($event)"
          >
            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-open"
                role="tabpanel"
                aria-labelledby="pills-open-tab"
                *ngIf="!communityQuestionLoading1"
              >
                <div
                  class="questions_boxs"
                  *ngFor="let question of communityQuestionsList; let i = index"
                >
                  <div class="padding_boxs not_count_padding">
                    <div class="quebox_top">
                      <!-- <p>
                        <a (click)="questionDetail(question.id)" [innerHtml]="question.question_text"></a>
                      </p> -->
                      <div class="editor_data">
                        <a
                          (click)="questionDetail(question.id)"
                          [innerHtml]="question.question_text"
                        ></a>
                      </div>
                      <div
                        class="quebox_media"
                        *ngIf="
                          question.filedata && question.image_video_url != '[]'
                        "
                      >
                        <div
                          [attr.id]="'abc' + question.id"
                          class="carousel slide"
                          data-bs-ride="carousel"
                          data-bs-interval="false"
                        >
                          <div
                            class="carousel-indicators"
                            *ngIf="question.filedata.length > 1"
                          >
                            <button
                              type="button"
                              [attr.data-bs-target]="'#abc' + question.id"
                              [attr.data-bs-slide-to]="i"
                              [attr.aria-label]="'Slide ' + i"
                              [ngClass]="{ active: i == 0 }"
                              *ngFor="
                                let list of question.filedata;
                                let i = index
                              "
                              [attr.aria-current]="i == 0"
                            ></button>
                          </div>
                          <div class="carousel-inner">
                            <div
                              class="carousel-item"
                              [ngClass]="{ active: i == 0 }"
                              *ngFor="
                                let list of question.filedata;
                                let i = index
                              "
                            >
                              <img
                                alt=""
                                class="d-block w-100"
                                *ngIf="list.type == 'img'"
                                src="{{ globals.profileImgeapiUrl }}/question/{{
                                  question.id
                                }}/{{ list.name }}"
                              />
                              <div
                                class="ratio ratio-16x9"
                                *ngIf="list.type == 'video'"
                              >
                                <video controls>
                                  <source
                                    src="{{
                                      globals.profileImgeapiUrl
                                    }}/question/{{ question.id }}/{{
                                      list.name
                                    }}"
                                  />
                                </video>
                              </div>
                            </div>
                          </div>
                          <button
                            *ngIf="question.filedata.length > 1"
                            class="carousel-control-prev"
                            type="button"
                            [attr.data-bs-target]="'#abc' + question.id"
                            data-bs-slide="prev"
                          >
                            <span
                              class="carousel-control-prev-icon"
                              aria-hidden="true"
                            ></span>
                            <span class="visually-hidden">Previous</span>
                          </button>
                          <button
                            *ngIf="question.filedata.length > 1"
                            class="carousel-control-next"
                            type="button"
                            [attr.data-bs-target]="'#abc' + question.id"
                            data-bs-slide="next"
                          >
                            <span
                              class="carousel-control-next-icon"
                              aria-hidden="true"
                            ></span>
                            <span class="visually-hidden">Next</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <a (click)="questionDetail(question.id)">
                      <div class="quebox_bottom d-flex align-items-center">
                        <div class="qt_photo">
                          <img
                            *ngIf="
                              question.profile_image_url == null ||
                              question.profile_image_url == ''
                            "
                            id="user_img"
                            src="assets/images/dummy.jpg"
                            alt=""
                            class="img-fluid"
                          />
                          <img
                            *ngIf="
                              question.profile_image_url != null &&
                              question.profile_image_url != ''
                            "
                            id="user_img"
                            src="{{ globals.profileImgeapiUrl }}/{{
                              question.profile_image_url
                            }}"
                            alt=""
                            class="img-fluid"
                          />
                        </div>
                        <div class="qt_desc">
                          <span class="user_name"
                            ><a
                              (click)="
                                publicProfile(
                                  question.user_id,
                                  question.send_anonymously
                                )
                              "
                              >{{ question.display_name }}</a
                            ></span
                          >
                          <ul class="queicon_lists">
                            <li
                              class="like_question"
                              *ngIf="
                                globals.authData == undefined ||
                                question.is_like == '' ||
                                question.is_like == null
                              "
                            >
                              <a
                                onclick="event.stopPropagation();"
                                (click)="likeDislikeQuestionAnswer(question, 1, 'top')" id="thumb{{question.id}}"
                                ><i class="fa-regular fa-thumbs-up"></i
                              ></a>
                              <span>{{ question.likes }}</span>
                            </li>
                            <li
                              class="like_question"
                              *ngIf="
                                question.is_like != '' &&
                                question.is_like != null
                              "
                            >
                              <a
                                onclick="event.stopPropagation();"
                                (click)="likeDislikeQuestionAnswer(question, 0,'top')" id="thumb{{question.id}}"
                                ><i class="fa-solid fa-thumbs-up"></i
                              ></a>
                              <span>{{ question.likes }}</span>
                            </li>
                            <li>
                              <i class="fa-regular fa-comment-dots"></i>
                              {{ question.comments }}
                            </li>
                            <li>
                              <i class="fa-regular fa-eye"></i>
                              {{ question.views }}
                            </li>
                            <!-- <li class="community_lists">
                              <i class="fa-solid fa-bullhorn"></i>
                              <ng-container *ngFor="let community of question.community">
                                <a (click)="communityQuestion(community.id)">{{
                                  community.name
                                  }}</a>
                              </ng-container>
                            </li> -->
                            <!-- <li><a (click)="communityQuestion(question.community_id)"><i class="fa-solid fa-bullhorn"></i> {{question.community_name}}</a></li> -->

                            <!-- <li><a  class="report"><i class="fa-solid fa-flag"></i> Report</a></li>
                                                        <li *ngIf="question.invitee_can_invite_others != null"><a  class="report"><i class="fa-solid fa-share"></i> Share</a></li> -->
                          </ul>
                          <ul class="posted_expired_lists">
                            <li class="posted_date_time">
                              <span>Posted:</span> {{ question.posted_time }}
                            </li>
                            <li class="expires_date_time">
                              <span>{{ question.expire_title }}</span>
                              {{ question.Expire_time }}
                            </li>
                            <li
                              class="float-end"
                              *ngIf="
                                globals.authData == undefined ||
                                ((question.is_abused == '' ||
                                  question.is_abused == null) &&
                                  question.created_by != globals.authData.id &&
                                  globals.reportQuestionAnswerEntity
                                    .question_id != question.id)
                              "
                            >
                              <a
                                onclick="event.stopPropagation();"
                                class="report"
                                (click)="
                                  globals.reportPopUpOpen(
                                    question.id,
                                    '',
                                    question.user_name
                                  )
                                "
                                ><i class="fa-solid fa-flag"></i> Report</a
                              >
                            </li>
                            <li
                              class="float-end"
                              *ngIf="
                                (question.is_abused != '' &&
                                  question.is_abused != null) ||
                                (globals.reportQuestionAnswerEntity
                                  .reportedTime != '' &&
                                  globals.reportQuestionAnswerEntity
                                    .reportedTime != undefined &&
                                  globals.reportQuestionAnswerEntity
                                    .question_id == question.id)
                              "
                            >
                              <a
                                onclick="event.stopPropagation();"
                                *ngIf="
                                  globals.reportQuestionAnswerEntity
                                    .reportedTime != '' &&
                                  globals.reportQuestionAnswerEntity
                                    .reportedTime != undefined &&
                                  globals.reportQuestionAnswerEntity
                                    .question_id == question.id
                                "
                                class="report_question_answer"
                                title="{{
                                  globals.commonTranslationText.questionDetail
                                    .reportQuestionTooltip
                                }} {{
                                  globals.reportQuestionAnswerEntity
                                    .reportedTime | date : 'MMM d, y h:mm a'
                                }}"
                                ><i class="fa-solid fa-flag"></i> Reported
                              </a>
                              <a
                                onclick="event.stopPropagation();"
                                *ngIf="
                                  globals.reportQuestionAnswerEntity
                                    .reportedTime == '' ||
                                  globals.reportQuestionAnswerEntity
                                    .reportedTime == undefined ||
                                  globals.reportQuestionAnswerEntity
                                    .question_id != question.id
                                "
                                class="report_question_answer"
                                title="{{
                                  globals.commonTranslationText.questionDetail
                                    .reportQuestionTooltip
                                }} {{
                                  question.is_abused | date : 'MMM d, y h:mm a'
                                }}"
                                ><i class="fa-solid fa-flag"></i> Reported
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="pills-completed"
                role="tabpanel"
                aria-labelledby="pills-completed-tab"
                *ngIf="!communityQuestionLoading1"
              >
                <div
                  class="questions_boxs"
                  *ngFor="let question of communityQuestionsList; let i = index"
                >
                  <div class="padding_boxs not_count_padding">
                    <div class="quebox_top">
                      <!-- <p><a (click)="questionDetail(question.id)" [innerHtml]="question.question_text"></a>
                      </p> -->
                      <div class="editor_data">
                        <a
                          (click)="questionDetail(question.id)"
                          [innerHtml]="question.question_text"
                        ></a>
                      </div>
                      <div
                        class="quebox_media"
                        *ngIf="
                          question.filedata && question.image_video_url != '[]'
                        "
                      >
                        <div
                          [attr.id]="'new' + question.id"
                          class="carousel slide"
                          data-bs-ride="carousel"
                          data-bs-interval="false"
                          *ngIf="question.filedata"
                        >
                          <div
                            class="carousel-indicators"
                            *ngIf="question.filedata.length > 1"
                          >
                            <button
                              type="button"
                              [attr.data-bs-target]="'#new' + question.id"
                              [attr.data-bs-slide-to]="i"
                              [attr.aria-label]="'Slide ' + i"
                              [ngClass]="{ active: i == 0 }"
                              *ngFor="
                                let list of question.filedata;
                                let i = index
                              "
                              [attr.aria-current]="i == 0"
                            ></button>
                          </div>
                          <div class="carousel-inner">
                            <div
                              class="carousel-item"
                              [ngClass]="{ active: i == 0 }"
                              *ngFor="
                                let list of question.filedata;
                                let i = index
                              "
                            >
                              <img
                                alt=""
                                class="d-block w-100"
                                *ngIf="list.type == 'img'"
                                src="{{ globals.profileImgeapiUrl }}/question/{{
                                  question.id
                                }}/{{ list.name }}"
                              />
                              <div
                                class="ratio ratio-16x9"
                                *ngIf="list.type == 'video'"
                              >
                                <video controls>
                                  <source
                                    src="{{
                                      globals.profileImgeapiUrl
                                    }}/question/{{ question.id }}/{{
                                      list.name
                                    }}"
                                  />
                                </video>
                              </div>
                            </div>
                          </div>
                          <button
                            *ngIf="question.filedata.length > 1"
                            class="carousel-control-prev"
                            type="button"
                            [attr.data-bs-target]="'#new' + question.id"
                            data-bs-slide="prev"
                          >
                            <span
                              class="carousel-control-prev-icon"
                              aria-hidden="true"
                            ></span>
                            <span class="visually-hidden">Previous</span>
                          </button>
                          <button
                            *ngIf="question.filedata.length > 1"
                            class="carousel-control-next"
                            type="button"
                            [attr.data-bs-target]="'#new' + question.id"
                            data-bs-slide="next"
                          >
                            <span
                              class="carousel-control-next-icon"
                              aria-hidden="true"
                            ></span>
                            <span class="visually-hidden">Next</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <a (click)="questionDetail(question.id)">
                      <div class="quebox_bottom d-flex align-items-center">
                        <div class="qt_photo">
                          <img
                            *ngIf="
                              question.profile_image_url == null ||
                              question.profile_image_url == ''
                            "
                            id="user_img"
                            src="assets/images/dummy.jpg"
                            alt=""
                            class="img-fluid"
                          />
                          <img
                            *ngIf="
                              question.profile_image_url != null &&
                              question.profile_image_url != ''
                            "
                            id="user_img"
                            src="{{ globals.profileImgeapiUrl }}/{{
                              question.profile_image_url
                            }}"
                            alt=""
                            class="img-fluid"
                          />
                        </div>
                        <div class="qt_desc">
                          <span class="user_name"
                            ><a
                              (click)="
                                publicProfile(
                                  question.user_id,
                                  question.send_anonymously
                                )
                              "
                              >{{ question.display_name }}</a
                            ></span
                          >
                          <ul class="queicon_lists">
                            <li
                              class="like_question"
                              *ngIf="
                                globals.authData == undefined ||
                                question.is_like == '' ||
                                question.is_like == null
                              "
                            >
                              <a
                              onclick="event.stopPropagation();"
                                (click)="likeDislikeQuestionAnswer(question, 1,'new')" id="thumb{{question.id}}"
                                ><i class="fa-regular fa-thumbs-up"></i
                              ></a>
                              <span>{{ question.likes }}</span>
                            </li>
                            <li
                              class="like_question"
                              *ngIf="
                                question.is_like != '' &&
                                question.is_like != null
                              "
                            >
                              <a
                              onclick="event.stopPropagation();"
                                (click)="likeDislikeQuestionAnswer(question, 0,'new')" id="thumb{{question.id}}"
                                ><i class="fa-solid fa-thumbs-up"></i
                              ></a>
                              <span>{{ question.likes }}</span>
                            </li>
                            <li>
                              <i class="fa-regular fa-comment-dots"></i>
                              {{ question.comments }}
                            </li>
                            <li>
                              <i class="fa-regular fa-eye"></i>
                              {{ question.views }}
                            </li>
                            <!-- <li class="community_lists">
                              <i class="fa-solid fa-bullhorn"></i>
                              <ng-container *ngFor="let community of question.community">
                                <a (click)="communityQuestion(community.id)">{{
                                  community.name
                                  }}</a>
                              </ng-container>
                            </li> -->
                            <!-- <li><a (click)="communityQuestion(question.community_id)"><i class="fa-solid fa-bullhorn"></i> {{question.community_name}}</a></li> -->

                            <!-- <li><a  class="report"><i class="fa-solid fa-flag"></i> Report</a></li>
                                                    <li *ngIf="question.invitee_can_invite_others != null"><a  class="report"><i class="fa-solid fa-share"></i> Share</a></li> -->
                          </ul>
                          <ul class="posted_expired_lists">
                            <li class="posted_date_time">
                              <span>Posted:</span> {{ question.posted_time }}
                            </li>
                            <li class="expires_date_time">
                              <span>{{ question.expire_title }}</span>
                              {{ question.Expire_time }}
                            </li>
                            <li
                              class="float-end"
                              *ngIf="
                                globals.authData == undefined ||
                                ((question.is_abused == '' ||
                                  question.is_abused == null) &&
                                  question.created_by != globals.authData.id &&
                                  globals.reportQuestionAnswerEntity
                                    .question_id != question.id)
                              "
                            >
                              <a
                                onclick="event.stopPropagation();"
                                class="report"
                                (click)="
                                  globals.reportPopUpOpen(
                                    question.id,
                                    '',
                                    question.user_name
                                  )
                                "
                                ><i class="fa-solid fa-flag"></i> Report</a
                              >
                            </li>
                            <li
                              class="float-end"
                              *ngIf="
                                (question.is_abused != '' &&
                                  question.is_abused != null) ||
                                (globals.reportQuestionAnswerEntity
                                  .reportedTime != '' &&
                                  globals.reportQuestionAnswerEntity
                                    .reportedTime != undefined &&
                                  globals.reportQuestionAnswerEntity
                                    .question_id == question.id)
                              "
                            >
                              <a
                                onclick="event.stopPropagation();"
                                *ngIf="
                                  globals.reportQuestionAnswerEntity
                                    .reportedTime != '' &&
                                  globals.reportQuestionAnswerEntity
                                    .reportedTime != undefined &&
                                  globals.reportQuestionAnswerEntity
                                    .question_id == question.id
                                "
                                class="report_question_answer"
                                title="{{
                                  globals.commonTranslationText.questionDetail
                                    .reportQuestionTooltip
                                }} {{
                                  globals.reportQuestionAnswerEntity
                                    .reportedTime | date : 'MMM d, y h:mm a'
                                }}"
                                ><i class="fa-solid fa-flag"></i> Reported
                              </a>
                              <a
                                onclick="event.stopPropagation();"
                                *ngIf="
                                  globals.reportQuestionAnswerEntity
                                    .reportedTime == '' ||
                                  globals.reportQuestionAnswerEntity
                                    .reportedTime == undefined ||
                                  globals.reportQuestionAnswerEntity
                                    .question_id != question.id
                                "
                                class="report_question_answer"
                                title="{{
                                  globals.commonTranslationText.questionDetail
                                    .reportQuestionTooltip
                                }} {{
                                  question.is_abused | date : 'MMM d, y h:mm a'
                                }}"
                                ><i class="fa-solid fa-flag"></i> Reported
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <ng-container
                *ngIf="communityQuestionLoading1 || communityQuestionLoading2"
              >
                <div
                  class="questions_boxs"
                  *ngFor="let object of questionLoaderList"
                >
                  <ngx-shimmer-loading [width]="'100%'"></ngx-shimmer-loading>
                  <ngx-shimmer-loading [width]="'100%'"></ngx-shimmer-loading>
                  <ngx-shimmer-loading [width]="'100%'"></ngx-shimmer-loading>

                  <div class="row d-flex align-items-center">
                    <div class="col-xl-2 col-lg-2 col-12">
                      <ngx-shimmer-loading
                        [shape]="'circle'"
                        [width]="'75px'"
                      ></ngx-shimmer-loading>
                    </div>
                    <div class="col-xl-10 col-lg-10 col-12">
                      <ngx-shimmer-loading
                        [width]="'250px'"
                      ></ngx-shimmer-loading>
                      <ngx-shimmer-loading
                        [width]="'250px'"
                      ></ngx-shimmer-loading>
                      <ngx-shimmer-loading
                        [width]="'250px'"
                      ></ngx-shimmer-loading>
                    </div>
                  </div>
                </div>
              </ng-container>

              <div
                *ngIf="
                  communityQuestionsList.length == 0 &&
                  !communityQuestionLoading1
                "
              >
                <div class="no_que_found mt-5">
                  <img
                    src="assets/images/no_found.png"
                    alt=""
                    class="img-fluid"
                  />
                  NO THREADS POSTED YET
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
        <div class="home_wrap_right">
          <!-- <div class="decideit_info">
            <div class="post_que_header">
              <h2 class="decideit_head">
                <span>Welcome To</span> Eyedea Exchange
              </h2>
            </div>
            <div class="advertisement_box">
              <p>
                IJCAHPO Eyedea Exchange is your key to fast, easy and
                clutter-free group decisions.
              </p> -->
          <!-- <p>Send to up to 1000 friends instantly, Now!</p>
              <p>Threads Remain Live for 99 Hrs</p> -->
          <!-- </div>
          </div> -->
          <div class="decide_it_stars_wrap top_responders mt-0">
            <div class="post_que_header">
              <h2 class="decideit_head"><span>Popular</span> Stars</h2>
            </div>
            <div
              class="dis_with_info pb-5"
              *ngIf="responderList.length > 0 && !responderLoader"
            >
              <div class="decideitstar_legend">
                <ul>
                  <li><span class="fiftyqa"></span> Threads</li>
                  <li><span class="fifteenqa"></span> Comments</li>
                  <li><span class="open_que"></span> Likes</li>
                </ul>
              </div>
              <ng-container
                *ngFor="let responder of responderList; let last = last"
              >
                <div
                  class="dis_box d-flex align-items-center"
                  [ngClass]="{ no_border: last }"
                >
                  <div class="dis_photo">
                    <img
                      *ngIf="
                        responder.profile_image_url == null ||
                        responder.profile_image_url == ''
                      "
                      id="user_img"
                      src="assets/images/dummy.jpg"
                      alt=""
                      class="img-fluid"
                    />
                    <img
                      *ngIf="
                        responder.profile_image_url != null &&
                        responder.profile_image_url != ''
                      "
                      id="user_img"
                      src="{{ globals.profileImgeapiUrl }}/{{
                        responder.profile_image_url
                      }}"
                      alt=""
                      class="img-fluid"
                    />
                  </div>
                  <div class="dis_info">
                    <a (click)="publicProfile(responder.id, '')">
                      <!-- <span
                                                *ngIf="responder.is_profile_private == 0 && ((responder.first_name != '' || responder.first_name != null) && (responder.last_name != '' || responder.last_name != null) )">{{responder.first_name}}
                                                {{responder.last_name}}</span> -->
                      <span
                        >{{ responder.first_name }}
                        {{ responder.last_name }}</span
                      >
                      <!-- <span *ngIf="responder.is_profile_private == 1">{{responder.random_name}}</span> -->
                    </a>
                    <br />
                    <span class="more_than_50">{{
                      responder.active_questions_count
                    }}</span>
                    <span class="more_than_15">{{
                      responder.comments_count
                    }}</span>
                    <span class="open_question">{{
                      responder.question_likes_count
                    }}</span>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </ng-container>
              <button
                kendoButton
                type="submit"
                class="mt-4 theme_btn theme_btn d-flex justify-content-center mx-auto"
                (click)="decideItStars()"
              >
                View All
              </button>
            </div>
            <div class="dis_with_info pb-5" *ngIf="responderLoader">
              <div
                class="row community_shimmer d-flex align-items-center"
                *ngFor="let object of responderLoaderList"
              >
                <div class="col-xl-3 col-lg-3 col-12">
                  <ngx-shimmer-loading [shape]="'circle'" [width]="'50px'">
                  </ngx-shimmer-loading>
                </div>
                <div class="col-xl-9 col-lg-9 col-12">
                  <ngx-shimmer-loading [width]="'175px'"></ngx-shimmer-loading>
                  <!-- <ngx-shimmer-loading [width]="'175px'"></ngx-shimmer-loading> -->
                </div>
              </div>
            </div>
            <div
              *ngIf="responderList.length == 0 && !responderLoader"
              class="no_records_found d-flex justify-content-center align-items-center"
            >
              <span>There are no popular stars</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
