<div class="home_wrap dashboard_wrap profile pt_50 fixed-head" id="profile">
    <div class="container-fluid">
        <div class="row pb-5">
            <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                <div class="profile_left">
                    <div class="profile_general_info" *ngIf="!profileLoading1">
                        <div class="row">
                            <div class="w-auto m-sm-0 m-auto">
                                <div class="img_profile text-center">
                                    <span class="letter_img">
                                        <div class="choose_img">
                                            <div class="form-group file_upload" id="UserchooseImageIdIcon">
                                                <label>
                                                    <img *ngIf="profileEntity.profile_image_url ==null || profileEntity.profile_image_url == ''"
                                                        id="user_img" src="assets/images/dummy.jpg" alt=""
                                                        class="img-fluid" />
                                                    <img *ngIf="profileEntity.profile_image_url !=null && profileEntity.profile_image_url != ''"
                                                        id="user_img"
                                                        src="{{globals.profileImgeapiUrl}}/{{profileEntity.profile_image_url}}"
                                                        alt="" class="img-fluid" />

                                                </label>
                                                <input kendoTextBox type="text" disabled />
                                                <input type="file" (change)="profilePictureUpload(0)" id="editimage"
                                                    accept="image/*" #profile_image="ngModel"
                                                    [(ngModel)]="profileImageEntity.profile_image"
                                                    aria-describedby="editimage" name="UsereditImageId"
                                                    class="form-control" />
                                            </div>

                                            <div style="text-align: center;">
                                                <label for="editimage"><i class="profile-icon fa-pencil fa-sm fa-solid"
                                                        aria-hidden="true"></i></label>
                                                <i *ngIf="profileEntity.profile_image_url !=null && profileEntity.profile_image_url != ''"
                                                    class="profile-icon profile-delete fa-regular fa-trash-can" id="editprofile_img" aria-hidden="true"
                                                    (click)="profilePictureUpload(1)"></i>
                                            </div>
                                        </div>

                                    </span>
                                </div>
                            </div>
                            <div class="col-sm-9 col-12">
                                <div class="other_profile_content">
                                    <div class="profile_name">{{profileEntity.user_name}}</div>
                                    <div class="profile_desc">
                                        <p>
                                            {{profileEntity.about}}
                                        </p>
                                    </div>
                            <!-- <p *ngIf="globals.authData.roles_id == 2" class="float-start">Total Contacts <span><a
                                        (click)="addressBook()">{{profileEntity.contacts_count}}</a></span></p>

                            <button kendoButton *ngIf="globals.authData.roles_id == 2" class="theme_btn float-end"
                                (click)="addressBook()">Address Book</button> -->
                            <div class="clearfix"></div>
                            </div>
                            <div class="clearfix"></div>
                            </div>
                        </div>
                            <div class="questions_boxs" *ngIf="profileLoading1">
                                <ngx-shimmer-loading [shape]="'circle'" [width]="'70px'"></ngx-shimmer-loading>
                                <ngx-shimmer-loading></ngx-shimmer-loading>
                            </div>

                    <div class="profile_tabs" *ngIf="!profileLoading1">
                        <ul class="nav nav-pills" id="pills-tab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" id="pills-personal-tab" data-bs-toggle="pill"
                                    href="#pills-personal" role="tab" aria-controls="pills-personal"
                                    aria-selected="true"><i class="fa-solid fa-user"></i> Personal</a>
                            </li>
                            <li class="nav-item" 
                                [attr.title]="profileEntity.email == null ? 'you can not change password without email' : ''">
                                <a class="nav-link" [ngClass]="{disabled :profileEntity.email == null }"
                                    id="pills-password-tab" data-bs-toggle="pill" href="#pills-password" role="tab"
                                    aria-controls="pills-password" aria-selected="false"><i class="fa-solid fa-key"></i>
                                    Privacy and Security</a>
                            </li>
                            <!-- <li class="nav-item" *ngIf="globals.authData.roles_id == 2"
                                [attr.title]="profileEntity.email == null ? 'you can not add community without email' : ''">
                                <a class="nav-link" [ngClass]="{disabled :profileEntity.email == null }"
                                    id="pills-community-tab" data-bs-toggle="pill" href="#pills-community" role="tab"
                                    aria-controls="pills-community" aria-selected="false"><i class="fa-solid fa-bullhorn"></i>
                                    Topic</a>
                            </li> -->
                        </ul>
                    </div>

                    <div class="tab-content" id="pills-tabContent">
                        <div *ngIf="!profileLoading1" class="tab-pane fade show active" id="pills-personal"
                            role="tabpanel" aria-labelledby="pills-personal-tab">
                            <h3 class="tabs_head"> Personal Information</h3>
                            <form #profileForm="ngForm" (ngSubmit)="editProfile(profileForm)">
                                <div class="row">
                                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                        <div class="form-group">
                                            <label for="formGroupExampleInput2"><span class="mendatory_feild">*</span>
                                                First Name</label>
                                            <input kendoTextBox class="form-control" type="text" required
                                                #first_name="ngModel" [(ngModel)]="profileEntity.first_name"
                                                name="first_name" id="first_name"  pattern="^[a-zA-Z/]{2,}[\sa-zA-Z/]*"
                                                [ngClass]="{error: (first_name.dirty && first_name.invalid) || (first_name.pristine && submitted && first_name.errors && first_name.errors.required) || errorEntity.first_name}"
                                                maxlength="100">
                                            <span class="error_class"
                                                *ngIf="(first_name.dirty && first_name.invalid) || (first_name.pristine && submitted) || errorEntity.first_name">
                                                <span *ngIf="first_name.errors && first_name.errors.required">
                                                    {{globals.commonTranslationText.profilePage.personalInformationForm.firstName.required}}
                                                </span>
                                                <span *ngIf="first_name.errors && first_name.errors.pattern">
                                                    {{globals.commonTranslationText.profilePage.personalInformationForm.firstName.pattern}}
                                                </span>
                                                <span *ngIf="errorEntity.first_name != ''">
                                                    {{errorEntity.first_name}}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                        <div class="form-group">
                                            <label for="formGroupExampleInput2"><span class="mendatory_feild">*</span>
                                                Last Name</label>
                                            <input kendoTextBox class="form-control" type="text" required
                                                name="LastName" #last_name="ngModel"
                                                [(ngModel)]="profileEntity.last_name"
                                                [ngClass]="{error: (last_name.dirty && last_name.invalid) || (last_name.pristine && submitted && last_name.errors && last_name.errors.required) || errorEntity.last_name}"
                                                pattern="^[a-zA-Z/]{2,}[\sa-zA-Z/]*" maxlength="100">
                                            <span class="error_class"
                                                *ngIf="(last_name.dirty && last_name.invalid) || (last_name.pristine && submitted) ||errorEntity.last_name">
                                                <span *ngIf="last_name.errors && last_name.errors.required">
                                                    {{globals.commonTranslationText.profilePage.personalInformationForm.lastName.required}}
                                                </span>
                                                <span *ngIf="last_name.errors && last_name.errors.pattern">
                                                    {{globals.commonTranslationText.profilePage.personalInformationForm.lastName.pattern}}
                                                </span>
                                                <span *ngIf="errorEntity.last_name != ''">
                                                    {{errorEntity.last_name}}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                        *ngIf="username_available == 0">
                                        <div class="form-group">
                                            <label><span class="mendatory_feild">*</span>
                                                {{globals.commonTranslationText.registerPage.form.userName.label}}</label>
                                            <input kendoTextBox type="text" class="form-control"
                                                pattern="[a-zA-Z]{1,}[a-zA-Z0-9]{1,}" #user_name="ngModel"
                                                [(ngModel)]="profileEntity.user_name" name="user_name" maxlength="100"
                                                [ngClass]="{error: (user_name.dirty && user_name.invalid) || (user_name.pristine && submitted) || errorEntity.user_name}"
                                                required id="user_name" />
                                            <span class="error_class"
                                                *ngIf="(user_name.dirty && user_name.invalid) || (user_name.pristine && submitted)">
                                                <span *ngIf="user_name.errors && user_name.errors.required">
                                                    {{globals.commonTranslationText.registerPage.form.userName.required}}
                                                </span>
                                                <span *ngIf="user_name.errors && user_name.errors.pattern">
                                                    {{globals.commonTranslationText.registerPage.form.userName.pattern}}
                                                </span>
                                            </span>
                                            <span class="error_class">
                                                <span>{{ errorEntity.user_name }}</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1"><span class="mendatory_feild">*</span> Email
                                                address</label>
                                            <input kendoTextBox type="email" class="form-control"
                                                pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
                                                #email="ngModel" [(ngModel)]="profileEntity.email" name="email"
                                                maxlength="100"
                                                [ngClass]="{error: (email.dirty && email.invalid) || (email.pristine && submitted && email.errors && email.errors.required)}"
                                                required id="email" />
                                            <span class="error_class"
                                                *ngIf="(email.dirty && email.invalid) || (email.pristine && submitted) || errorEntity.email">
                                                <span *ngIf="email.errors && email.errors.required">
                                                    {{globals.commonTranslationText.loginPage.form.emailAddress.required}}
                                                </span>
                                                <span *ngIf="email.errors && email.errors.pattern">
                                                    {{globals.commonTranslationText.loginPage.form.emailAddress.pattern}}
                                                </span>
                                                <span *ngIf="errorEntity.email">
                                                    <span>{{ errorEntity.email }}</span>
                                                </span>
                                            </span>

                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                        <div class="form-group">
                                            <label for="example-tel-input" class="">Phone Number</label>
                                            <input kendoTextBox class="form-control" type="text" name="mobile"
                                                id="mobile" #mobile="ngModel" [(ngModel)]="profileEntity.mobile"
                                                maxlength="14"  (keyup)="phoneNumberFormat(profileEntity.mobile)"
                                                [ngClass]="{error: (mobile.dirty && mobile.invalid) || phoneValid || errorEntity.mobile}" />
                                            <span class="error_class"
                                                *ngIf="(mobile.dirty && mobile.invalid) || errorEntity.mobile || phoneValid">
                                                <span *ngIf="mobile.errors && mobile.errors.required">
                                                    {{globals.commonTranslationText.profilePage.personalInformationForm.phoneNumber.required}}
                                                </span>
                                                <span *ngIf="phoneValid">
                                                    {{globals.commonTranslationText.profilePage.personalInformationForm.phoneNumber.pattern}}
                                                </span>
                                                <span *ngIf="errorEntity.mobile != ''">
                                                    {{errorEntity.mobile}}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div class="form-group">
                                            <label for="example-tel-input" class="col-form-label">
                                                {{globals.commonTranslationText.profilePage.personalInformationForm.about.label}}</label>
                                            <textarea kendoTextArea rows="4" id="user_profile" name="user_profile"
                                                #user_profile="ngModel" (keyup)="countChar()"
                                                [(ngModel)]="profileEntity.user_profile"></textarea>
                                            <div id="charNum"></div><span id="limit">500/500</span>
                                            <!--[ngClass]="{error: (user_profile.dirty && user_profile.invalid) || (user_profile.pristine && submitted && user_profile.errors && user_profile.errors.required) || errorEntity.user_profile}"-->
                                            <!-- <span class="error_class"
                                                *ngIf="(user_profile.dirty && user_profile.invalid) || (user_profile.pristine && submitted) || errorEntity.user_profile">
                                                <span *ngIf=" user_profile.errors && user_profile.errors.required">
                                                    {{globals.commonTranslationText.profilePage.personalInformationForm.about.required}}
                                                </span>
                                                <span *ngIf="errorEntity.user_profile != ''">
                                                    {{errorEntity.user_profile}}
                                                </span>
                                            </span> -->
                                        </div>
                                    </div>
                                    <h3 class="tabs_head">Get Daily Digest Mail</h3>
                                    <div class="">
                                        <kendo-switch class="mb-4" (valueChange)="getdailyDigest($event)" [(ngModel)]="profileEntity.daily_digest" #daily_digest="ngModel" 
                                        [checked]="profileEntity.daily_digest" name="daily_digest" [onLabel]="'On'"
                                            [offLabel]="'Off'" [disabled]="false">
                                        </kendo-switch>
                                    </div>
                                    <h3 class="tabs_head">Get All Mail Notification</h3>
                                    <div class="">
                                        <kendo-switch class="mb-4" (valueChange)="getNotification($event)" [(ngModel)]="profileEntity.get_email" #get_email="ngModel" 
                                        [checked]="profileEntity.get_email" name="get_email" [onLabel]="'On'"
                                            [offLabel]="'Off'" [disabled]="false">
                                        </kendo-switch>
                                    </div>

                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                                        <button kendoButton type="submit" class="theme_btn mt-5">Update</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="questions_boxs" *ngIf="profileLoading1">
                            <ngx-shimmer-loading [shape]="'circle'" [width]="'70px'"></ngx-shimmer-loading>
                            <ngx-shimmer-loading></ngx-shimmer-loading>
                        </div>
                        <div class="tab-pane fade" id="pills-account" role="tabpanel"
                            aria-labelledby="pills-account-tab">
                            <h3 class="tabs_head float-start"> Plan Information</h3>
                            <div class="float-end">Threads Remaining: {{planDetailEntity.question}} &nbsp;<i
                                    class="fa-solid fa-circle-info"
                                    title="Remaining threads are based on your plan and number of threads you have posted in current billing cycle. Visit plans details to find out more details."></i>
                            </div>
                            <div class="clearfix"></div>
                            <div class="plan_information current_plan" *ngIf="subscriptionDetails.length>0">
                                <div class="row d-flex align-items-center">
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                        <span class="current_plan_name">{{subscriptionDetails[0].plan.name}}</span>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 text-center" *ngIf="trancationList.length>0 && subscriptionDetails[0].plan.name!=globals.configurationText.plan.free">
                                        <span class="payment_date">Next Payment On:<br>
                                            {{trancationList[0].next_payment_date }}</span>
                                    </div>
                                    <div *ngIf="subscriptionDetails[0].plan.name!='Free'" class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 text-center">
                                        <span class="plan_price" *ngIf="planDetailEntity.downgradeDetail == null">${{subscriptionDetails[0].plan.amount}}</span>
                                        <span class="plan_price" *ngIf="planDetailEntity.downgradeDetail != null">${{planDetailEntity.downgradeDetail.plan.amount}}</span>
                                        <div class="clearfix"></div>
                                        <span *ngIf="planDetailEntity.downgradeDetail != null">changes to: {{planDetailEntity.downgradeDetail.plan.name}}</span>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 text-end">
                                        <button kendoButton type="submit" class="theme_btn_white" (click)="upgradePlan(subscriptionDetails[0].plan_id)">Change Plan</button>
                                    </div>
                                </div>
                            </div>
                            <div class="plan_information current_plan" *ngIf="subscriptionDetails.length==0">
                                <div class="row d-flex align-items-center">
                                    <div class="col-xl-4 col-lg-4 col-md-4 text-center">
                                        <span class="current_plan_name">There is no plan.</span>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 text-end">
                                        <button kendoButton type="submit" class="theme_btn_white" (click)="upgradePlan('')">Change Plan</button>
                                    </div>
                                </div>
                            </div>
                            <h3 class="tabs_head mt-5"> Payment History</h3>
                            <div class="plan_information expired_plan" *ngFor="let transaction of trancationList;">
                                <div class="row d-flex align-items-center">
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                                        <span class="current_plan_name">{{transaction.plan.name}}</span>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-center">
                                        <span class="payment_date">{{transaction.payment_date}}</span>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-4 text-end">
                                        <span class="plan_price">${{transaction.amount}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="plan_information expired_plan" *ngIf="trancationList.length==0">
                                No payment history found
                            </div>
                        </div>
                        <div class="tab-pane fade" id="pills-password" role="tabpanel"
                            aria-labelledby="pills-password-tab">
                            <h3 class="tabs_head"> Profile Privacy</h3>
                            <!--1: private 0: public-->
                            <div class="public_private_toggle">
                                <kendo-switch class="mb-4" (valueChange)="privacyChange()"
                                    [checked]="profileEntity.is_profile_private==1" [onLabel]="'Private'"
                                    [offLabel]="'Public'" [disabled]="false">
                                </kendo-switch>
                            </div>
                            <div class="clearfix"></div>
                            <h3 class="tabs_head"> Change Password</h3>
                            <form #changePasswordForm="ngForm" (ngSubmit)="changePassword(changePasswordForm)">
                                <div class="row">
                                    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                        *ngIf="profileEntity.password != null && profileEntity.password != ''">
                                        <div class="form-group">
                                            <label><span class="mendatory_feild">*</span>
                                                {{globals.commonTranslationText.profilePage.changePasswordForm.oldPassword.label}}</label>
                                            <input kendoTextBox class="form-control" type="password"
                                                (keyup)="confirmPassword()" #old_password="ngModel" maxlength="100"
                                                [(ngModel)]="passwordEntity.old_password" name="old_password"
                                                id="old_password" pattern="[a-zA-Z0-9.-_-@$*&!#]{8,}"
                                                [ngClass]="{error: (old_password.dirty && old_password.invalid) || (old_password.pristine && passwordSubmit)}"
                                                required />
                                                <!-- <span class="password_note">{{globals.commonTranslationText.registerPage.form.password_note}}</span> -->
                                            <i toggle="#password-field" class="fa-regular fa-fw fa-eye field-icon toggle-password icon_without_label"
                                                id="old_password-show"></i>
                                            <span class="error_class"
                                                *ngIf="(old_password.dirty && old_password.invalid) || (old_password.pristine && passwordSubmit)">
                                                <span *ngIf="old_password.errors && old_password.errors.required">
                                                    {{globals.commonTranslationText.profilePage.changePasswordForm.oldPassword.required}}
                                                </span>
                                                <span
                                                    *ngIf="old_password.errors && old_password.errors.pattern && !oldnewsame">
                                                    {{globals.commonTranslationText.profilePage.changePasswordForm.oldPassword.pattern}}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                        <div class="form-group">
                                            <label><span class="mendatory_feild">*</span>
                                                {{globals.commonTranslationText.profilePage.changePasswordForm.newPassword.label}}</label>
                                            <input kendoTextBox type="password" class="form-control"
                                                (keyup)="confirmPassword()" pattern="^([a-zA-Z0-9.-_-@$*&!#]{8,})$"
                                                #password="ngModel" maxlength="100"
                                                [(ngModel)]="passwordEntity.password" name="password" id="password"
                                                [ngClass]="{error: (password.dirty && password.invalid) || (password.pristine && passwordSubmit ||(oldnewsame && !(password.errors && password.errors.pattern && password.errors.required)))}"
                                                required />
                                                <!-- <span class="password_note">{{globals.commonTranslationText.registerPage.form.password_note}}</span> -->
                                            <i toggle="#password-field"
                                                class="fa-regular fa-fw fa-eye field-icon toggle-password icon_without_label"
                                                id="password-show"></i>
                                            <span class="error_class"
                                                *ngIf="(password.dirty && password.invalid) || (password.pristine && passwordSubmit) || oldnewsame">
                                                <span
                                                    *ngIf="password.errors && password.errors.required && !oldnewsame">
                                                    {{globals.commonTranslationText.profilePage.changePasswordForm.newPassword.required}}
                                                </span>
                                                <span *ngIf="password.errors && password.errors.pattern && !oldnewsame">
                                                    {{globals.commonTranslationText.profilePage.changePasswordForm.newPassword.pattern}}
                                                </span>
                                                <span
                                                    *ngIf="oldnewsame && !newconfsame && !(password.errors && password.errors.pattern && password.errors.required)">
                                                    {{globals.commonTranslationText.profilePage.changePasswordForm.newPassword.different}}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                        <div class="form-group">
                                            <label><span class="mendatory_feild">*</span>
                                                {{globals.commonTranslationText.profilePage.changePasswordForm.confirmPassword.label}}</label>
                                            <input kendoTextBox type="password" class="form-control"
                                                (keyup)="confirmPassword()" pattern="[a-zA-Z0-9.-_-@$*&!#]{8,}"
                                                #password_confirmation="ngModel" maxlength="100"
                                                [(ngModel)]="passwordEntity.password_confirmation"
                                                name="password_confirmation" id="confpassword"
                                                [ngClass]="{error: (password_confirmation.dirty && password_confirmation.invalid) || (password_confirmation.pristine && passwordSubmit||(newconfsame && !(password_confirmation.errors && password_confirmation.errors.pattern && password_confirmation.errors.required)))}"
                                                required />
                                                <!-- <span class="password_note">{{globals.commonTranslationText.registerPage.form.password_note}}</span> -->
                                            <i toggle="#password-field" class="fa-regular fa-fw fa-eye field-icon toggle-password icon_without_label"
                                                id="confpassword-show"></i>
                                            <span class="error_class"
                                                *ngIf="(password_confirmation.dirty && password_confirmation.invalid) || (password_confirmation.pristine && passwordSubmit) || newconfsame">
                                                <span
                                                    *ngIf="password_confirmation.errors && password_confirmation.errors.required && !newconfsame">
                                                    {{globals.commonTranslationText.profilePage.changePasswordForm.confirmPassword.required}}
                                                </span>
                                                <span
                                                    *ngIf="password_confirmation.errors && password_confirmation.errors.pattern && !newconfsame">
                                                    {{globals.commonTranslationText.profilePage.changePasswordForm.confirmPassword.pattern}}
                                                </span>
                                                <span
                                                    *ngIf="newconfsame && !(password_confirmation.errors && password_confirmation.errors.pattern && password_confirmation.errors.required)">
                                                    {{globals.commonTranslationText.profilePage.changePasswordForm.confirmPassword.match}}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <span class="password_hint">
                                        Password hint: Minimum 8 characters. Letters, numbers and these symbols (!@#$&*_-) can be used.
                                    </span>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                                        <button kendoButton type="submit" class="theme_btn mt-5">Update</button>
                                    </div>
                                </div>
                            </form>

                        </div>
                        <div *ngIf="!profileLoading2" class="tab-pane fade" id="pills-community" role="tabpanel"
                            aria-labelledby="pills-community-tab">
                            <h3 class="tabs_head">My Topics</h3>
                            <span *ngIf="userCommunities.length == 0"> You have no topics yet</span>
                            <div class="existing_communities">
                                <ul class="communities_lists">
                                    <li *ngFor="let community of userCommunities"><a><img
                                                src="{{globals.profileImgeapiUrl}}/community-image/{{community.community.community_thumbnail_image}}"
                                                alt="" class="img-fluid" /> {{community.community.name}}</a> <a
                                            (click)="removeCommunity(community)"><i class="fa-solid fa-xmark"></i></a></li>
                                    <li class="community_toggle_btn"><a (click)="addCommunityOpen()"><img
                                                src="assets/images/top_communities/plus.png" alt=""
                                                class="img-fluid p-2" /> Add Topic</a></li>
                                </ul>
                            </div>

                            <div class="newely_added_community">
                                <div class="row">
                                    <div class="col-xl-12 col-lg-12 col-md-12 mb-4">
                                        <span class="filter_head float-start">Select a Topic</span>
                                        <span class="close_filter float-end"><a (click)="closeCommunity()"><i
                                                    class="fa-solid fa-xmark"></i></a></span>
                                    </div>
                                    <div class="panelbar-wrapper">
                                        <input (keyup)="search()" id="searchTerm" placeholder="Search...">
                                        <div class="select_community_wrap">
                                            <div id="data" class="community_panel k-panelbar">
                                                <div class="k-panel k-group k-panelbar-group" *ngFor="let category of communities" [title]="category.name">
                                                    <div class="k-level-1 k-panelbar-item k-state-default" *ngFor="let community of category.communities">
                                                        <span class="k-link">
                                                            <a (click)="addCommunity(community,category)"> <img
                                                                    height="50" width="50"
                                                                    src="{{globals.profileImgeapiUrl}}/community-image/{{community.community_thumbnail_image}}">
                                                                <span style="flex:1">{{ community.name }}</span></a>
                                                            </span>
                                                    </div>
                                                    <div class="text-center" *ngIf="category.communities.length == 0"><strong>There are no topics found</strong></div>
                                                </div>
                                                <div class="text-center" *ngIf="communities.length == 0"><strong>There are no topics found</strong></div>
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div class="questions_boxs" *ngIf="profileLoading2">
                            <ngx-shimmer-loading [shape]="'circle'" [width]="'70px'"></ngx-shimmer-loading>
                            <ngx-shimmer-loading></ngx-shimmer-loading>
                        </div>
                    </div>

                
            </div>


            <!-- 
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 userdash_right">
                <div class="advertisement_box mb-5">
                    <p>IJCAHPO Eyedea Exchange is your key to fast, easy and clutter-free group decisions.</p>
                            <p>Send to up to 8 friends instantly, Now!</p>
                            <p>Questions Remain Live for 36 Hrs</p>
                </div>
                <div class="decideitstar_box">
                    <h3><span class="decide_head">Decide</span>It Stars</h3>
                    <ul class="que_indicator_lists float-end">
                        <li><span class="fiftyqa"></span> More than 50 Answers</li>
                        <li><span class="fifteenqa"></span> More than 15 Answers</li>
                        <li><span class="total_response"></span> Total Answers</li>
                        <li><span class="open_que"></span> Active Questions</li>
                        <div class="clearfix"></div>
                    </ul>
                    <div class="dis_respo_box">
                        <div class="dis_left">
                            <img src="assets/images/decideit_stars/ds2.jpg" alt="" class="img-fluid" />
                        </div>
                        <div class="dis_right">
                            <h3>Connor Nelson
                            </h3>
                            <span class="fiftyqa">
                            </span>
                            <div class="clearfix"></div>
                            <span class="total_response">67</span>
                            <span class="open_que">52</span>
                        </div>
                    </div>
                    <div class="dis_respo_box">
                        <div class="dis_left">
                            <img src="assets/images/top_responders/tr1.jpg" alt="" class="img-fluid" />
                        </div>
                        <div class="dis_right">
                            <h3>Wade Walker
                            </h3>
                            <span class="fifteenqa">
                            </span>
                            <div class="clearfix"></div>
                            <span class="total_response">42</span>
                            <span class="open_que">32</span>
                        </div>
                    </div>
                    <div class="dis_respo_box">
                        <div class="dis_left">
                            <img src="assets/images/top_responders/tr2.jpg" alt="" class="img-fluid" />
                        </div>
                        <div class="dis_right">
                            <h3>Dave Robinson
                            </h3>
                            <span class="fiftyqa">
                            </span>
                            <div class="clearfix"></div>
                            <span class="total_response">58</span>
                            <span class="open_que">34</span>
                        </div>
                    </div>
                    <div class="dis_respo_box">
                        <div class="dis_left">
                            <img src="assets/images/top_responders/tr3.jpg" alt="" class="img-fluid" />
                        </div>
                        <div class="dis_right">
                            <h3>Jorge Harris
                            </h3>
                            <span class="fifteenqa">
                            </span>
                            <div class="clearfix"></div>
                            <span class="total_response">36</span>
                            <span class="open_que">32</span>
                        </div>
                    </div>
                    <div class="dis_respo_box">
                        <div class="dis_left">
                            <img src="assets/images/top_responders/tr5.jpg" alt="" class="img-fluid" />
                        </div>
                        <div class="dis_right">
                            <h3>Roberto Williams
                            </h3>
                            <span class="fiftyqa">
                            </span>
                            <div class="clearfix"></div>
                            <span class="total_response">68</span>
                            <span class="open_que">57</span>
                        </div>
                    </div>
                    <div class="dis_respo_box">
                        <div class="dis_left">
                            <img src="assets/images/decideit_stars/ds2.jpg" alt="" class="img-fluid" />
                        </div>
                        <div class="dis_right">
                            <h3>Connor Nelson
                            </h3>
                            <span class="fiftyqa">
                            </span>
                            <div class="clearfix"></div>
                            <span class="total_response">67</span>
                            <span class="open_que">58</span>
                        </div>
                    </div>
                    <div class="dis_respo_box">
                        <div class="dis_left">
                            <img src="assets/images/top_responders/tr1.jpg" alt="" class="img-fluid" />
                        </div>
                        <div class="dis_right">
                            <h3>Wade Walker
                            </h3>
                            <span class="fifteenqa">
                            </span>
                            <div class="clearfix"></div>
                            <span class="total_response">16</span>
                            <span class="open_que">14</span>
                        </div>
                    </div>
                    <div class="top_que_box view_all_box float-end">
                        <button class="theme_btn mt-3">View All</button>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
    
    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
        <div class="home_wrap_right">
            <!-- <div class="advertisement_box"> -->
                <!-- <p>IJCAHPO Eyedea Exchange is your key to fast, easy and clutter-free group decisions.</p> -->
                <!-- <p>Send to up to 1000 friends instantly, Now!</p> -->
                <!--<p>Threads Remain Live for 99 Hrs</p>-->
            <!-- </div> -->
            <div class="decide_it_stars_wrap mt-0">
                <div class="post_que_header">
                    <h2 class="decideit_head"><span>EYEXchange Connect</span> Stars</h2>
                </div>

                <div class="dis_with_info pb-5">
                    <div *ngIf="decideItStarLoader">
                        <ngx-shimmer-loading [shape]="'circle'" [width]="'70px'"></ngx-shimmer-loading>
                        <ngx-shimmer-loading></ngx-shimmer-loading>
                    </div>
                    <div class="decideitstar_legend" *ngIf="!decideItStarLoader">
                        <ul>
                            <li>
                                <span class="fiftyqa"></span> Threads
                            </li>
                            <li>
                                <span class="fifteenqa"></span> Comments
                            </li>
                            <li>
                                <span class="open_que"></span> Likes
                            </li>
                        </ul>
                    </div>

                    <div *ngIf="decideItStarList.length==0 && !decideItStarLoader">

                        There are no IJCAHPO Eyedea Exchange Stars
                    </div>
                    <ng-container *ngFor="let star of decideItStarList;let last=last">
                        <div class="dis_box d-flex align-items-center" [ngClass]="{no_border:last}">
                            <div class="dis_photo">
                                <img *ngIf="star.profile_image_url ==null || star.profile_image_url == ''"
                                    id="user_img" src="assets/images/dummy.jpg" alt="" class="img-fluid" />
                                <img *ngIf="star.profile_image_url !=null && star.profile_image_url != ''"
                                    id="user_img" src="{{globals.profileImgeapiUrl}}/{{star.profile_image_url}}"
                                    alt="" class="img-fluid" />
                            </div>
                            <div class="dis_info">
                                <a (click)="publicProfile(star.id)">
                                    <!-- <span
                                        *ngIf="star.is_profile_private == 0 && ((star.first_name != '' || star.first_name != null) && (star.last_name != '' || star.last_name != null) )">{{star.first_name}}
                                        {{star.last_name}}</span> -->
                                    <span>{{ star.first_name }} {{ star.last_name }}</span>
                                    <!-- <span *ngIf="star.is_profile_private == 1">{{star.random_name}}</span> -->
                                </a> 
                                <br>
                                <span class="more_than_50">{{ star.active_questions_count }}</span>
                    <span class="more_than_15">{{ star.comments_count }}</span>
                    <span class="open_question">{{ star.question_likes_count }}</span>
                                <!-- <span class="more_than_50"
                                    [ngClass]="{more_than_15:star.comments_count>15,more_than_50:star.comments_count>50,less_than_15:star.comments_count<=15}">{{star.comments_count}}</span>
                                <span class="open_question">{{star.active_questions_count}}</span> -->
                            </div>
                            <!-- <div class="dis_rank">
                                <span>1</span>
                            </div> -->
                            <div class="clearfix"></div>
                        </div>
                    </ng-container>
                    <button kendoButton type="submit" (click)="decideItStars()"
                        class="mt-4 theme_btn theme_btn d-flex justify-content-center mx-auto">View All</button>
                </div>
            </div>
        <!-- </div> -->
    </div>