import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '../globals';
import { ActivatedRoute } from '@angular/router';
import { ProfileService } from '../services/profile.service';
import { PlanService } from '../app-admin/services/plan.service';
import { CookieService } from 'ngx-cookie-service';
declare var $: any, Swal, Stripe: any;

function _window(): any {
  //debugger
  // return the global native browser window object
  return window;
}
@Component({
  selector: 'app-upgrade-plan',
  templateUrl: './upgrade-plan.component.html',
  styleUrls: ['./upgrade-plan.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class UpgradePlanComponent implements OnInit {

  constructor(public globals: Globals,
    private router: Router,
    private route: ActivatedRoute,
    private PlanService: PlanService,
    private ProfileService: ProfileService,
    private cookieService: CookieService) { }

  planList;
  purchasePlanEntity;
  paginationEntity = {
    limit: this.globals.pageSize,
    offset: 1,
    searchData: {
      status: '',
      searchQuery: ''
    },
    sortOrder: [{
      field: "amount",
      dir: 'asc'
    }]
  };
  planId;
  clientSecret;
  paymentPopUp;
  // const stripe = Stripe('stripe-public-key');

  // const elements = stripe.elements();
  // const cardElement = elements.create('card');

  // cardElement.mount('#card-element');
  ngOnInit(): void {

    const Stipe = document.createElement('script');
    Stipe.src = 'https://js.stripe.com/v3/';

    document.body.appendChild(Stipe);
    this.planList = [];
    this.purchasePlanEntity = {};
    this.globals.isLoading = true;
    this.planId = this.route.snapshot.paramMap.get('planId');
    this.PlanService.getAllPlans(this.paginationEntity)
      .then((data: any) => {
        this.planList = data['data'];
        for(var i=0;i<this.planList.length;i++)
        {
          if(this.planList[i].id == this.globals.configurationText.plan.free)
          {
            this.purchasePlanEntity = this.planList[i];
            console.log(this.purchasePlanEntity);
          }
        }
        console.log(data);
        this.globals.isLoading = false;
      },
        (error) => {
          this.globals.isLoading = false;
          // this.globals.pageNotfound(error.error.code);
        });
    // this.clientSecret = 'pi_1Iw5f3BZvxpI65J85NM5gGOc_secret_gpqg6yXHow4z3lNNV0MNqDWii';
    // var stripe;
    //         const cardHolderName = $('#card-holder-name').val();
    // const cardButton = document.getElementById('card-button');
    // const stripe = Stripe('pk_test_51IIyVYBZvxpI65J8Fn74aREuSJpddySUmMOx6TR9KemniQNXI0M60uTuB0pq62JNMMTgkxYs8mXGei7BtrP1y7m700r6URgFCz');

    // const elements = stripe.elements();
    // const cardElement = elements.create('card', { style: {
    //   base: {
    //     iconColor: '#c4f0ff',
    //     color: '#fff',
    //     fontWeight: '500',
    //     fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
    //     fontSize: '16px',
    //     fontSmoothing: 'antialiased',
    //     ':-webkit-autofill': {
    //       color: '#fce883',
    //     },
    //     '::placeholder': {
    //       color: '#87BBFD',
    //     },
    //   },
    //   invalid: {
    //     iconColor: '#FFC7EE',
    //     color: '#FFC7EE',
    //   },}
    // });
    // const cardElement = elements.create('card');
    // cardElement.mount('#card-element');
    // const clientSecret = cardButton.dataset.secret;

    // cardButton.addEventListener('click', async (e) => {//debugger
    //     var a = await stripe
    //     .confirmCardPayment(this.clientSecret, {
    //       payment_method: {
    //         card: cardElement,
    //         billing_details: {
    //           name: 'Jenny Rosen',
    //         },
    //       },
    //     })
    //     .then(function(result) {
    //       console.log(result);
    //       // Handle result.error or result.setupIntent
    //     });
    // });
    // this.ProfileService.getProfileData('communities').then(
    //   (data) => {
    //     //debugger;
    //     this.globals.isLoading = false;
    //   },
    //   (error) => {
    //     if (error.status == 422) {
    //     } else {
    //       // this.globals.pageNotfound(error.error.code);
    //     }
    //     this.globals.isLoading = false;
    //   });
  }

  paymentPopUpClose() {
    this.paymentPopUp = false;
  }
  createPayment(plan) {
    var downgradeDetail = localStorage.getItem('plan_downgrade_status');
    if (downgradeDetail != '' && downgradeDetail != null && downgradeDetail == plan.id) {
      Swal.fire({
        type: this.globals.configurationText.sweetAlertTypeIconError,
        icon: this.globals.configurationText.sweetAlertTypeIconError,
        customClass: this.globals.configurationText.sweetAlertClassError,
        title: "You have already subscribed this plan",
        toast: true,
        animation: true,
        position: this.globals.configurationText.sweetAlertPosition,
        showConfirmButton: false,
        timer: this.globals.configurationText.sweetAlertTimer,
        timerProgressBar: true,
        showCancelButton: true,
        cancelButtonText: 'x',
      });
    }
    else if (plan.id == this.globals.configurationText.plan.free) {
      Swal.fire({
        title: this.globals.adminTranslationText.users.list.alerts.planChangeConfirm.title,
        text: 'Are you sure, you want to change the free plan?',
        icon: "warning",
        type: this.globals.adminTranslationText.users.list.alerts.planChangeConfirm.type,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonClass: 'theme_btn',
        confirmButtonText: 'Yes',
        cancelButtonClass: 'cancel_btn',
        cancelButtonText: "No",
        allowOutsideClick: false
      })
        .then((result) => {
          if (result.value) {
            this.globals.isLoading = true;
            var entity1 = {
              'returnPaymentIntent': 0,
              'amount': plan.amount,
              'stripe_plan': plan.stripe_plan,
              'plan_id': plan.id,
              'plan_name': plan.name,
            };
            this.ProfileService.planPayment(entity1)
              .then((data) => {
                this.globals.isLoading = false;
                console.log(data);
                this.cookieService.set('successMessage', 'Your plan has been changed successfully', this.globals.cookieMessageExpireTime, '/');
                window.location.href = '/profile/' + window.btoa('plan');
              },
                (error) => {
                  this.globals.isLoading = false;
                  console.log(error);
                  if (error.error.code == 409) {
                    Swal.fire({
                      type: this.globals.configurationText.sweetAlertTypeIconError,
                      icon: this.globals.configurationText.sweetAlertTypeIconError,
                      customClass: this.globals.configurationText.sweetAlertClassError,
                      title: "You have already subscribed this plan",
                      toast: true,
                      animation: true,
                      position: this.globals.configurationText.sweetAlertPosition,
                      showConfirmButton: false,
                      timer: this.globals.configurationText.sweetAlertTimer,
                      timerProgressBar: true,
                      showCancelButton: true,
                      cancelButtonText: 'x',
                    });
                  }

                  // this.globals.pageNotfound(error.error.code);
                });
          }
        });
    }
    else {
      this.globals.isLoading = true;
      var entity = { 'returnPaymentIntent': 1, 'amount': plan.amount };
      this.ProfileService.planPayment(entity)
        .then((data) => {
          this.globals.isLoading = false;
          console.log(data);
          this.paymentPopUp = true;
          setTimeout(() => {
            this.clientSecret = data['data'].client_secret;
            // const cardHolderName = this.purchasePlanEntity.cardHolderName;
            const cardButton = document.getElementById('card-button');
            //const stripe1 = Stripe('pk_live_51IIyVYBZvxpI65J8zXeLnLCjALGoK3muspwyyNocPAQRSf0EUrkBQtxNZmzIjVuVFHLqD4PXdwqX4ye8V0O7gD3o00BSFHKucX');
           //const stripe1 = Stripe('pk_test_51IIyVYBZvxpI65J8Fn74aREuSJpddySUmMOx6TR9KemniQNXI0M60uTuB0pq62JNMMTgkxYs8mXGei7BtrP1y7m700r6URgFCz');
           const stripe1 = Stripe('pk_test_51IIyVYBZvxpI65J8Fn74aREuSJpddySUmMOx6TR9KemniQNXI0M60uTuB0pq62JNMMTgkxYs8mXGei7BtrP1y7m700r6URgFCz');
            const elements = stripe1.elements({ locale: 'in' });
            const cardElement = elements.create('card', { hidePostalCode: true },
              {
                style: {
                  base: {
                    iconColor: '#c4f0ff',
                    color: '#fff',
                    fontWeight: '500',
                    fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                    fontSize: '16px',
                    fontSmoothing: 'antialiased',
                    ':-webkit-autofill': {
                      color: '#fce883',
                    },
                    '::placeholder': {
                      color: '#87BBFD',
                    },
                  },
                  invalid: {
                    iconColor: '#FFC7EE',
                    color: '#FFC7EE',
                  },
                }
              }

            );
            cardElement.mount('#card-element');
            const clientSecret = cardButton.dataset.secret;
            var self = this;
            cardButton.addEventListener('click', async (e) => {
              //debugger
              var a = await stripe1
                .confirmCardSetup(this.clientSecret, {
                  payment_method: {
                    card: cardElement,
                    // billing_details: {
                    //   name: cardHolderName,
                    // },
                  },
                })
                .then(function (result) {
                  console.log(result);
                  if (result.error) {
                    self.globals.isLoading = false;
                    Swal.fire({
                      type: self.globals.configurationText.sweetAlertTypeIconError,
                      icon: self.globals.configurationText.sweetAlertTypeIconError,
                      customClass: self.globals.configurationText.sweetAlertClassError,
                      title: result.error.message,
                      toast: true,
                      animation: true,
                      position: self.globals.configurationText.sweetAlertPosition,
                      showConfirmButton: false,
                      timer: self.globals.configurationText.sweetAlertTimer,
                      timerProgressBar: true,
                      showCancelButton: true,
                      cancelButtonText: 'x',
                    });
                  }
                  else {
                    self.globals.isLoading = true;
                    var paymentEntity = {
                      'returnPaymentIntent': 0,
                      'amount': plan.amount,
                      'stripe_plan': plan.stripe_plan,
                      'plan_id': plan.id,
                      'plan_name': plan.name,
                      'payment_method': result['setupIntent'].payment_method,
                    };
                    self.ProfileService.planPayment(paymentEntity)
                      .then((data) => {
                        self.globals.isLoading = false;
                        console.log(data);
                        self.paymentPopUp = false;
                        self.cookieService.set('successMessage', 'Your plan has been changed successfully', self.globals.cookieMessageExpireTime, '/');
                        window.location.href = '/profile/' + window.btoa('plan');
                      },
                        (error) => {
                          self.globals.isLoading = false;
                          console.log(error);
                          if (error.error.code == 409) {
                            Swal.fire({
                              type: this.globals.configurationText.sweetAlertTypeIconError,
                              icon: this.globals.configurationText.sweetAlertTypeIconError,
                              customClass: this.globals.configurationText.sweetAlertClassError,
                              title: "You have already subscribed the plan",
                              toast: true,
                              animation: true,
                              position: this.globals.configurationText.sweetAlertPosition,
                              showConfirmButton: false,
                              timer: this.globals.configurationText.sweetAlertTimer,
                              timerProgressBar: true,
                              showCancelButton: true,
                              cancelButtonText: 'x',
                            });
                          }

                          // this.globals.pageNotfound(error.error.code);
                        });
                    // Handle result.error or result.setupIntent
                  }
                });
            });
          }, 100);
        },
          (error) => {
            this.globals.isLoading = false;
            console.log(error);
            if (error.error.code == 409) {
              Swal.fire({
                type: this.globals.configurationText.sweetAlertTypeIconError,
                icon: this.globals.configurationText.sweetAlertTypeIconError,
                customClass: this.globals.configurationText.sweetAlertClassError,
                title: "You have already subscribed the plan",
                toast: true,
                animation: true,
                position: this.globals.configurationText.sweetAlertPosition,
                showConfirmButton: false,
                timer: this.globals.configurationText.sweetAlertTimer,
                timerProgressBar: true,
                showCancelButton: true,
                cancelButtonText: 'x',
              });
            }
          });
    }
    // var handler = (<any>window).StripeCheckout.configure({
    //   key: 'pk_test_51IIyVYBZvxpI65J8Fn74aREuSJpddySUmMOx6TR9KemniQNXI0M60uTuB0pq62JNMMTgkxYs8mXGei7BtrP1y7m700r6URgFCz',
    //   locale: 'auto',
    //   currency: this.globals.selectedCurrency,
    //   // panelLabel: "Subscribe",
    //   token: token => {
    //     this.globals.isLoading = true;
    //     var date = new Date();
    //     let postData = {};
    //     console.log(token);
    //     postData['stripe_plan'] = plan.stripe_plan;
    //     postData['stripeToken'] = token.id;
    //     postData['plan_id'] = plan.id;
    //     postData['plan_name'] = plan.name;
    //     postData['amount'] = plan.amount;
    //     postData['is_new'] = 0;
    //     // postData['UserId'] = this.globals.authData.UserId;
    //     // postData['RoleId'] = this.globals.authData.RoleId;
    //     // postData['UserName'] = this.globals.authData.FirstName + " " + this.globals.authData.LastName;
    //     // postData['CertificateName'] = scheduledata.CertificateName;
    //     // postData['LoginURL'] = '/login';
    //     // postData['LoginURL'] = '/login';
    //     this.ProfileService.createPayment(postData)
    //       .then((data) => {
    //         this.globals.isLoading = false;
    //         console.log(data);
    //         this.cookieService.set('successMessage', 'Your plan has been changed successfully',this.globals.cookieMessageExpireTime,'/');
    //         // Swal.fire({
    //         //   icon: this.globals.commonTranslationText.candidateDashboardPage.alerts.recertificationSuccess.type,
    //         //   type: this.globals.commonTranslationText.candidateDashboardPage.alerts.recertificationSuccess.type,
    //         //   title: this.globals.commonTranslationText.candidateDashboardPage.alerts.recertificationSuccess.title,
    //         //   text: this.globals.commonTranslationText.candidateDashboardPage.alerts.recertificationSuccess.text,
    //         //   showCancelButton: false,
    //         //   showConfirmButton: true,
    //         //   confirmButtonClass: 'theme_btn',
    //         //   confirmButtonText: 'Ok',
    //         // })
    //         window.location.href = '/profile/' + window.btoa('plan');
    //       },
    //         (error) => {
    //           this.globals.isLoading = false;
    //           console.log(error);
    //           if(error.error.code == 409)
    //           {
    //             Swal.fire({
    //               type: this.globals.configurationText.sweetAlertTypeIconError,
    //               icon: this.globals.configurationText.sweetAlertTypeIconError,
    //               customClass: this.globals.configurationText.sweetAlertClassError,
    //               title: "You have already subscribed the plan",
    //               toast: true,
    //               animation: true,
    //               position: this.globals.configurationText.sweetAlertPosition,
    //               showConfirmButton: false,
    //               timer: this.globals.configurationText.sweetAlertTimer,
    //               timerProgressBar: true,
    //               showCancelButton: true,
    //               cancelButtonText: 'x',
    //             });
    //           }

    //           // this.globals.pageNotfound(error.error.code);
    //         });
    //   }
    // });
    // console.log(handler);
    // handler.open({
    //   name: 'DecideIt',
    //   //description: this.globals.CartItemCount + ' Products',
    //   amount: plan.amount * 100,
    //   email: this.globals.authData.email
    // });
  }

}
