<!-- <script src="http://code.jquery.com/jquery-1.5.js"></script> -->
<div class="home_banner">
  <div class="home_banner_canvas"><canvas id="AnimationGradient"></canvas></div>
  <div class="container-fluid">
    <div class="banner-text">
      <h1>Welcome to <span>EYEXchange Connect</span></h1>
      <p>A platform where our members can ask questions, engage in conversation, get information, submit opinion polls, and have fun through posts and threads. EYEXchange Connect is as quick and easy as sending an email notification and you will have the ability to post images and video. Through EYEXchange Connect you will receive information specifically for allied ophthalmic personnel, news and announcements on continuing education, certification, special events, and more.</p>
    </div>  
    <img width="310" height="305" src="assets/images/discussion-image.png" alt="">
  </div>
</div>
<div class="home_wrap pt_50 fixed-head" id="home_page">
  <div class="container-fluid">
    <!-- <div class="post_que_header">
      <h2 class="decideit_head"><span>Welcome To</span> EYEXchange Connect</h2>
    </div>
    <div class="intro_info mb-5">
      <p>
        A platform where our members can ask questions, engage in conversation, get information, submit opinion polls, and have fun through posts and threads. EYEXchange Connect is as quick and easy as sending an email notification and you will have the ability to post images and video. Through EYEXchange Connect you will receive information specifically for allied ophthalmic personnel, news and announcements on continuing education, certification, special events, and more.
        </p>
    </div> -->
    <div class="row pb-5">
      <div class="col-xl-8 col-lg-9 col-md-12 col-sm-12 col-12">
        <!-- <div class="embed-responsive embed-responsive-16by9 mb-5">
                    <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0"
                        allowfullscreen></iframe>
                </div>
                <h2>Welcome to <span class="decide_head">Decide</span>It</h2>
                <p class="mb-3">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                    took a galley of type and scrambled it to make a type specimen book. It has survived not only
                    five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                </p>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer
                    took a galley of type and scrambled it to make a type specimen book. It has survived not only
                    five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                </p> -->

        <!-- <div class="post_que_header">
          <h2 class="decideit_head">
            <span>Welcome To</span> Eyedea Exchange
          </h2>
        </div>

        <div class="intro_info mb-5">
          <p>
            <strong>Congratulations, you found us!</strong> Soon DecideIt will
            be a routine part of your vocabulary, but a little introduction
            seems appropriate as we launch this new site. IJCAHPO Eyedea Exchange is our free,
            quick, and easy service that provides immediate feedback to your
            questions.
            <strong
              >We’re here to revolutionize the way group decisions are
              made.</strong
            >
            DecideIt provides Q&A for private use, and we're adding
            public-facing groups later in the year. Using IJCAHPO Eyedea Exchange is as speedy
            and simple as sending an email, but with a lot more benefit.
            <strong>All questions expire in 36 hours,</strong> encouraging users
            to respond with urgency, giving you fast,
            <strong>graphical,</strong> feedback through our cloud solution.
            This means no more crammed email boxes or DM storms. Try it for
            yourself. Welcome to the future!
          </p>
        </div> -->
        <div class="d-flex justify-content-between mb-4 post_que_header">
          <h2 class="mb-0 decideit_head">
            <span>{{ globals.commonTranslationText.home.post }}</span>
            Thread
          </h2>
          <div class="position-relative">
            <span class="info_toggle" (click)="mediaInfoOpen()"><i class="fa-solid fa-circle-info"></i> Upload Media Note</span>
          </div>
          <!-- <div class="post_que_steps float-end">
            <ul class="steps_lists">
              <li
                [ngClass]="{
                  active:
                    postQuestionEntity.question_text != '' &&
                    postQuestionEntity.question_text != undefined
                }"
              >
                <span>1</span>
              </li>
              <li
                [ngClass]="{
                  active:
                    postQuestionEntity.question_type_id != '' &&
                    postQuestionEntity.question_type_id != undefined
                }"
              >
                <span>2</span>
              </li>
              <li
                [ngClass]="{
                  active:
                    postQuestionEntity.community_id != '' &&
                    postQuestionEntity.community_id != undefined
                }"
              >
                <span>3</span>
              </li>
              <li
                *ngIf="userRoleId == 1"
                [ngClass]="{
                  active:
                    postQuestionEntity.postas == 1 ||
                    postQuestionEntity.postas == 0
                }"
              >
                <span>3</span>
              </li>
              <li>
                                <span>4</span>
                            </li>
            </ul>
          </div> -->
        </div>
        <div class="post_question_wrap home_post_box">
          <form
            #postQuestionForm="ngForm"
            *ngIf="globals.authData == undefined || defaultEntity.questions > 0"
          >
            
            <div class="clearfix"></div>
            <div class="post_que_step1">
              <div
                class="steps_indiactor"
                [ngClass]="{
                  active:
                    postQuestionEntity.question_text != '' &&
                    postQuestionEntity.question_text != undefined
                }"
              >
                <span>step</span>1
              </div>
              <div class="clearfix mb-5 row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="post_que_editor">
                    <!-- <textarea
                      kendoTextArea
                      rows="4"
                      id="question_text"
                      placeholder="Start your thread"
                      name="question_text"
                      required
                      (keyup)="countChar()"
                      #question_text="ngModel"
                      [(ngModel)]="postQuestionEntity.question_text"
                    ></textarea> -->
                    <kendo-editor
                    id="question_text"
                      placeholder="Start your thread"
                      name="question_text"
                      required
                      (valueChange)="countChar()"
                      #question_text="ngModel"
                      [(ngModel)]="postQuestionEntity.question_text">
                      <kendo-toolbar>
                        <kendo-toolbar-buttongroup>
                          <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                          <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                          <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                          <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
                        </kendo-toolbar-buttongroup>
                        <kendo-toolbar-buttongroup>
                          <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                          <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                        </kendo-toolbar-buttongroup>  
                        <kendo-toolbar-buttongroup>
                          <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                          <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
                        </kendo-toolbar-buttongroup>
                        <kendo-toolbar-buttongroup>
                          <kendo-toolbar-button text="Upload Media"></kendo-toolbar-button>
                        </kendo-toolbar-buttongroup>
                      </kendo-toolbar>
                    </kendo-editor>
                    <!-- <i class="fa-pencil fa-solid" aria-hidden="true"></i> -->
                    <div id="charNum" class="limitwitheditor"></div>
                    <span id="limit" class="limitwitheditor">1000/1000</span>
                    <span
                      class="error_class"
                      *ngIf="post_des_valid || errorEntity.question_text == ''"
                      >{{
                        globals.commonTranslationText.home.form.question
                          .required
                      }}</span
                    >
                    <span
                      class="error_class"
                      >{{errorMessage}}</span
                    >
                    <span
                      class="error_class"
                      >{{maxxxxxxLength}}</span
                    >
                    <!-- <span class="error_class"
                                        *ngIf="Description_valid">{{ globals.adminTranslationText.category.form.categoryDescription.pattern}}</span> -->
                  </div>

                  <!-- <div class="enter_que_box">
                                    <form>
                                        <div class="form-group">
                                            <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"
                                                placeholder="Enter your question"></textarea>
                                        </div>
                                    </form>
                                </div> -->
                </div>

                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12 d-none">
                  <div class="supporting_video">
                    <div class="form-group file_upload mediawitheditor">
                      <input type="text" disabled="" class="form-control" />
                      <input type='file' accept="image/*,video/*" (change)="imagechange($event)" name="fileToUpload"
                      id="fileToUpload"
                      class="form-control" multiple/>
                      <!-- <input
                        type="file"
                        (change)="imagechange($event)"
                        name="fileToUpload"
                        id="fileToUpload"
                        class="form-control"
                       
                        accept=".jpg, .jpeg, .png, .JPEG, .JPG, .PNG, .heic, .mp4,.MP4,.MKV,.mkv,.WEB',.web',.avi,.mov"
                        multiple
                      /> -->
                      <label>Supporting video or photo</label>
                      
                    </div>
                    <!-- <a href="">
                                        <img src="assets/images/camera.png" alt="" class="img-fluid" />
                                        <span>Supporting video or photo</span>
                                    </a> -->
                  </div>
                </div>
                
                <!-- Priview -->
                <div
                  class="col-xl-12 col-lg-12 col-md-12"
                  *ngIf="this.mydata.length > 0"
                  id="xyz"
                >
                  <div class="upload_preview">
                    <div class="row row-cols-sm-3 row-cols-1 upload_media">
                      <div class="col" *ngFor="let list of mydata; let i = index">
                        <div class="media_prev flex-wrap">
                          <button class="remove-prev" type="button"><i class="fa-solid fa-xmark" (click)="removeImageVideo(i,list.name)"></i></button>
                          <div class="media_prev_inner" *ngIf="list.type == 'img'">
                            <img
                              alt=""
                              [src]="list.url"
                            />
                          </div>
                          <!-- Progress Bar -->
                            <div class="upload_media_status" *ngIf="list.type == 'img'&& list.size < checkImgSizeKB">
                              <div class="first-progress">
                                <!-- <div
                                  [ngStyle]="{ 'width.%': list.FileProgress }"
                                  class="second-progress"
                                ></div> -->
                                <div class="second-progress"></div>
                              </div>
                              <!-- <div class="text-center d-none">
                                {{ list.FileProgessSize }} off {{ list.FileSize }}
                              </div> -->
                            </div>
                            <div id="error" *ngIf="list.type == 'img' && list.size >checkImgSizeKB" class="upload_media_error">Image Size is Not Proper</div>
                          <!-- Progress Bar -->
                          <div class="media_prev_inner" *ngIf="list.type == 'video'">
                          <div
                            class="ratio ratio-16x9"                            
                          >
                            <video controls>
                              <source [src]="list.url" />
                            </video>
                          </div>
                        </div>
                           <!-- Progress Bar -->
                          <div class="upload_media_status" *ngIf="list.type == 'video'&& list.size < checkVideoKB">
                            <div class="first-progress">
                              <!-- <div
                                [ngStyle]="{ 'width.100%': list.FileProgress }"
                                class="second-progress"
                              ></div> -->
                              <div class="second-progress"></div>
                            </div>
                            <!-- <div class="text-center d-none">
                              {{ list.FileProgessSize }} off {{ list.FileSize }}
                            </div> -->
                          </div>
                          <!-- Progress Bar -->
                          <div id="error" *ngIf="list.type == 'video' && list.size >checkVideoKB" class="upload_media_error">Video Size is Not Proper</div>
                          <!-- <p
                          *ngIf="list.type != 'img' || list.type != 'video'">document file</p> -->
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <!-- Priview -->
              </div>
            </div>
            <div
                  class="steps_indiactor"
                  [ngClass]="{
                    active:
                      postQuestionEntity.question_type_id != '' &&
                      postQuestionEntity.question_type_id != undefined
                  }"
                >
                  <span>step</span>2
                </div>
            <div class="que_post_expand_view">
              <div class="post_que_step2">
                
                <div class="answer_formate_wrap more_option_wrap">
                  <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <h3>Response Format</h3>
                      <kendo-dropdownlist
                        [data]="answerList"
                        [textField]="'display_text'"
                        [valueField]="'id'"
                        [valuePrimitive]="true"
                        name="question_type_id"
                        (valueChange)="answerTypeChange($event)"
                        #question_type_id="ngModel"
                        [(ngModel)]="postQuestionEntity.question_type_id"
                        [required]="globals.authData != undefined"
                        [ngClass]="{
                          error:
                            ((question_type_id.dirty &&
                              question_type_id.invalid) ||
                              (question_type_id.pristine &&
                                submitted &&
                                question_type_id.errors &&
                                question_type_id.errors.required)) &&
                            postQuestionEntity.required == true
                        }"
                      >
                        <ng-template kendoDropDownListItemTemplate let-dataItem>
                          <span class="template"
                            ><img
                              src="{{ dataItem.value }}"
                              alt=""
                              class="img-fluid"
                            />
                          </span>
                          {{ dataItem.display_text }}
                        </ng-template>
                      </kendo-dropdownlist>

                      <span
                        class="error_class"
                        *ngIf="
                          ((question_type_id.dirty &&
                            question_type_id.invalid) ||
                            (question_type_id.pristine &&
                              submitted &&
                              question_type_id.errors &&
                              question_type_id.errors.required) ||
                            errorEntity.question_type_id) &&
                          postQuestionEntity.required == true
                        "
                      >
                        <span
                          *ngIf="
                            question_type_id.errors &&
                            question_type_id.errors.required
                          "
                          >{{
                            globals.commonTranslationText.home.form.questionType
                              .required
                          }}</span
                        >
                        <span *ngIf="errorEntity.question_type_id != ''">
                          {{ errorEntity.question_type_id }}
                        </span>
                      </span>
                    </div>
                    <div
                      class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12"
                    ></div>
                    <div class="col-xl-12 col-lg-12 col-md-12">
                      <div class="post_que_editor">
                        <div
                          *ngIf="
                            postQuestionEntity.question_type_id ==
                            globals.configurationText.questionType.pickList
                          "
                        >
                          <!-- <div> -->
                          <div class="answer_formate_box mt-5">
                            <h6 class="float-start">Enter your options</h6>
                            <!-- <span class="optional_ans_formate float-end">Answer Format: [
                                                        Option List ]</span> -->
                            <div class="clearfix"></div>
                            <div class="row">
                              <div class="col-md-7 col-12 col-lg-7 col-sm-12">
                                <div
                                  class="row"
                                  *ngFor="
                                    let item of OptionsList;
                                    let i = index;
                                    let last = last
                                  "
                                >
                                  <div class="col-md-8 col-8 col-lg-8 col-sm-8">
                                    <div class="form-group" id="emails">
                                      <input
                                        kendoTextBox
                                        type="text"
                                        class="form-control"
                                        name="OptionValue{{ i }}"
                                        id="OptionValue{{ i }}"
                                        placeholder="Option {{ i + 1 }}"
                                        [ngClass]="{
                                          error:
                                            ((OptionValue.dirty &&
                                              OptionValue.invalid) ||
                                              (OptionValue.pristine &&
                                                submitted1 &&
                                                OptionValue.errors &&
                                                OptionValue.errors.required)) &&
                                            postQuestionEntity.required == true
                                        }"
                                        maxlength="100"
                                        [required]="
                                          globals.authData != undefined
                                        "
                                        #OptionValue="ngModel"
                                        [(ngModel)]="OptionsList[i].OptionValue"
                                      />
                                      <span
                                        class="error_class"
                                        *ngIf="
                                          ((OptionValue.dirty &&
                                            OptionValue.invalid) ||
                                            (OptionValue.pristine &&
                                              submitted1 &&
                                              OptionValue.errors &&
                                              OptionValue.errors.required)) &&
                                          postQuestionEntity.required == true
                                        "
                                      >
                                        <span
                                          *ngIf="
                                            OptionValue.errors &&
                                            OptionValue.errors.required
                                          "
                                          >{{
                                            globals.commonTranslationText.home
                                              .form.option.required
                                          }}
                                          {{ i + 1 }}</span
                                        >
                                      </span>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-4 col-4 col-lg-4 col-sm-4 pl_0"
                                  >
                                    <button
                                      *ngIf="OptionsList.length >= 4 || i == 2"
                                      class="cancel_btn"
                                      type="button"
                                      (click)="RemoveOption(item)"
                                    >
                                      <i class="fa-solid fa-xmark"></i>
                                    </button>
                                    <button
                                      *ngIf="last && i < 5"
                                      class="themeoption_btn"
                                      type="button"
                                      (click)="AddOption(i)"
                                    >
                                      <i class="fa-solid fa-plus"></i>
                                    </button>
                                  </div>
                                </div>
                                <div class="check_box">
                                  <input
                                    type="checkbox"
                                    name="multiSelection"
                                    [checked]="
                                      postQuestionEntity.question_type_multiSelect_id ==
                                      globals.configurationText.questionType
                                        .multiPickList
                                    "
                                    id="multiSelection"
                                    value="{{
                                      globals.configurationText.questionType
                                        .multiPickList
                                    }}"
                                  />
                                  <label
                                    for="multiSelection"
                                    class="checkboxbtn_label"
                                    >Allow Multiple Selections</label
                                  >
                                </div>
                              </div>
                              <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                                <div class="answer_formate_info">
                                  <p>
                                    "Shorter option lists generally lead to 18%
                                    more responses"
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <div *ngIf="selectedValue == 2">
                                                <span class="optional_ans_formate">Answer Format: [ Yes/No ]</span>
                                            </div> -->
                        <div
                          *ngIf="
                            postQuestionEntity.question_type_id ==
                            globals.configurationText.questionType.rankedList
                          "
                        >
                          <!-- <div> -->
                          <div class="answer_formate_box mt-5">
                            <h3 class="float-start">
                              Enter Your Options for Rank List
                            </h3>
                            <!-- <span class="optional_ans_formate float-end">Answer Format: [
                                                        Ranked List ]</span> -->
                            <div class="clearfix"></div>

                            <div class="row">
                              <div class="col-md-7 col-12 col-lg-7 col-sm-12">
                                <div
                                  class="row"
                                  *ngFor="
                                    let item of RankList;
                                    let l = index;
                                    let last = last
                                  "
                                >
                                  <div class="col-md-8 col-8 col-lg-8 col-sm-8">
                                    <div class="form-group" id="emails">
                                      <input
                                        kendoTextBox
                                        type="text"
                                        class="form-control"
                                        name="OptionValue{{ l }}"
                                        id="OptionValue{{ l }}"
                                        placeholder="Choice {{ l + 1 }}"
                                        [ngClass]="{
                                          error:
                                            ((OptionValue.dirty &&
                                              OptionValue.invalid) ||
                                              (OptionValue.pristine &&
                                                submitted1 &&
                                                OptionValue.errors &&
                                                OptionValue.errors.required)) &&
                                            postQuestionEntity.required == true
                                        }"
                                        maxlength="100"
                                        [required]="
                                          globals.authData != undefined
                                        "
                                        #OptionValue="ngModel"
                                        [(ngModel)]="RankList[l].OptionValue"
                                      />
                                      <span
                                        class="error_class"
                                        *ngIf="
                                          ((OptionValue.dirty &&
                                            OptionValue.invalid) ||
                                            (OptionValue.pristine &&
                                              submitted1 &&
                                              OptionValue.errors &&
                                              OptionValue.errors.required)) &&
                                          postQuestionEntity.required == true
                                        "
                                      >
                                        <span
                                          *ngIf="
                                            OptionValue.errors &&
                                            OptionValue.errors.required
                                          "
                                          >{{
                                            globals.commonTranslationText.home
                                              .form.option.required
                                          }}
                                          {{ l + 1 }}</span
                                        >
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    class="col-md-4 col-4 col-lg-4 col-sm-4 pl_0"
                                  >
                                    <button
                                      *ngIf="RankList.length >= 4 || l == 2"
                                      class="themeoption_btn"
                                      type="button"
                                      (click)="RemoveOptionrank(item)"
                                    >
                                      <i class="fa-solid fa-xmark"></i>
                                    </button>
                                    <button
                                      *ngIf="last && l < 5"
                                      class="theme_btn"
                                      type="button"
                                      (click)="AddOptionrank(l)"
                                    >
                                      <i class="fa-solid fa-plus"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                                <div class="answer_formate_info">
                                  <p>
                                    "Shorter option lists generally lead to 18%
                                    more responses"
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <div *ngIf="selectedValue == 4">
                                                <span class="optional_ans_formate">Answer Format: [ Express Yourself
                                                    ]</span>
                                            </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <div *ngIf="userRoleId == 1"
                  class="steps_indiactor"
                  [ngClass]="{
                    active:
                      postQuestionEntity.postas == 1 ||
                      postQuestionEntity.postas == 0 
                  }"
                >
                  <span>step</span>3
                </div>
               <div class="que_post_expand_view" *ngIf="userRoleId == 1" >
              <!-- <div class="post_que_step3">
                <div
                  class="steps_indiactor"
                  [ngClass]="{
                    active:
                      postQuestionEntity.community_id != '' &&
                      postQuestionEntity.community_id != undefined
                  }"
                >
                  <span>step</span>3
                </div>
                <div class="more_option_wrap">
                  <div class="row">
                    <div
                      class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3"
                    >
                      <h3>Select a Topic</h3>

                      <div class="radio_box">
                        
                        <input
                          type="radio"
                          name="selectlogintype"
                          id="community"
                          value="Role1"
                          [checked]="postQuestionEntity.questionType == 1"
                        />
                        <label for="community">Community</label>
                      </div>
                      <div *ngIf="postQuestionEntity.questionType == 1">
                        <div class="form-group mb-4">
                           <kendo-dropdownlist [data]="searchCommunityList"
                                                        [textField]="'name'" [valueField]="'id'" [valuePrimitive]="true"
                                                        [itemDisabled]="itemDisabled" [filterable]="true"
                                                        (filterChange)="handleFilter($event)"
                                                        [required]="globals.authData != undefined"
                                                        #community_id="ngModel"
                                                        [(ngModel)]="postQuestionEntity.community_id"
                                                        name="community_id"
                                                        [ngClass]="{error: (community_id.dirty && community_id.invalid) || (community_id.pristine && submitted && community_id.errors && community_id.errors.required) }">
                                                        <ng-template kendoDropDownListItemTemplate let-dataItem
                                                            let-rowIndex="rowIndex">

                                                            <span [ngClass]="dataItem.itemClass">{{ dataItem.name
                                                                }}</span>
                                                        </ng-template>
                                                    </kendo-dropdownlist>
                          <kendo-multiselect
                            [data]="communityList"
                            [(ngModel)]="value"
                            [textField]="'name'"
                            [valueField]="'id'"
                            [valuePrimitive]="true"
                            (valueChange)="onValueChange($event)"
                            [autoClose]="false"
                            [ngClass]="{ error: errorEntity.community_id }"
                            [placeholder]="'Select Topic'"
                          >
                            <ng-template
                              kendoMultiSelectItemTemplate
                              let-dataItem
                            >
                              <span>
                                <input
                                  *ngIf="
                                    dataItem.itemClass == 'second-level' ||
                                    dataItem.itemClass == 'parent'
                                  "
                                  type="checkbox"
                                  kendoCheckBox
                                  id="chk-{{ dataItem.id }}"
                                  class="k-checkbox"
                                />
                                &nbsp;<label
                                  class="k-multiselect-checkbox k-checkbox-label"
                                  for="chk-{{ dataItem.id }}"
                                ></label>
                              </span>
                              <span>{{ dataItem.name }}</span>
                            </ng-template>
                          </kendo-multiselect>
                          <span
                            class="error_class"
                            *ngIf="errorEntity.community_id"
                          >
                            <span *ngIf="errorEntity.community_id != ''">
                              Please select a Topic
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="post_que_step4">
                
                <div class="more_option_wrap">
                  <div class="row">
                    <div
                      class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                    >
                      <h3>Post as User Or Admin</h3>
                      <div class="radio_box mt-0">
                        <input
                          [(ngModel)]="postQuestionEntity.postas"
                          type="radio"
                          name="postas"
                          id="admin"
                          [value]="1"
                          [checked]="postQuestionEntity.postas == 1"
                        />
                        <label for="admin">Admin</label>
                      </div>
                      <div class="radio_box mt-0">
                        <input
                          [(ngModel)]="postQuestionEntity.postas"
                          type="radio"
                          name="postas"
                          id="user"
                          [value]="0"
                          [checked]="postQuestionEntity.postas == 0"
                        />
                        <label for="user">User</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
               <!-- <div class="post_que_step4">
                            <div class="steps_indiactor">
                                <span>step</span>4
                            </div>
                        </div>  -->
            </div> 

            <button
              (click)="
                postQuestion(
                  postQuestionForm,
                  globals.configurationText.questionStatus.open
                )
              "
              type="button"
              class="theme_btn float-end second"
              id="next_btn"
              [disabled]="btn_disabled"
            >
              Post
            </button>
            <button
              (click)="
                postQuestion(
                  postQuestionForm,
                  globals.configurationText.questionStatus.saveAsDraft
                )
              "
              class="themeoption_btn float-end third"
              id="next_btn"
              [disabled]="btn_disabled"
            >
              Save Draft
            </button>
            <div class="clearfix"></div>
          </form>
        </div>
        <div class="top_question_wrap">
          <div class="d-flex flex-wrap gap-4 align-items-center justify-content-between">
          <div class="post_que_header">
            <h2 class="decideit_head"><span>Popular</span> Threads</h2>
          </div>
          <!-- <div class="community_select float-start">
            <kendo-dropdownlist
              name="community_id"
              [data]="searchCommunityList"
              #communityId="ngModel"
              [(ngModel)]="postQuestionEntity.communityId"
              [textField]="'name'"
              [valueField]="'id'"
              [valuePrimitive]="true"
              [itemDisabled]="itemDisabled"
              [filterable]="true"
              (filterChange)="handleFilter($event)"
              (valueChange)="topQuestions($event)"
            >
              <ng-template
                kendoDropDownListItemTemplate
                let-dataItem
                let-rowIndex="rowIndex"
              >
                <span [ngClass]="dataItem.itemClass">{{ dataItem.name }}</span>
              </ng-template>
            </kendo-dropdownlist>

            <kendo-dropdownlist [data]="listItemscommunity" [(ngModel)]="selectedValuecommunity">
                        </kendo-dropdownlist>
          </div> -->
          <div
                      class="check_box check_fillbg m-0"
                      
                    >
                      <input
                        type="checkbox"
                        name="user_option"
                        id="user_option"
                        (change)="
                        adminFilter($event)
                        "
                      />
                      <label
                        for="user_option"
                        class="checkboxbtn_label pb-0 pe-0"
                        >Posted by Admin</label
                      >
                    </div>
                  </div>
          <div class="clearfix"></div>
          <!-- <ng-container *ngIf="questionLoader">
                        <div class="questions_boxs" *ngFor="let object of questionLoaderList">
                            <ngx-shimmer-loading></ngx-shimmer-loading>
                            <ngx-shimmer-loading></ngx-shimmer-loading>
                        </div>
                    </ng-container> -->

          <ng-container *ngIf="questionLoader">
            <div
              class="questions_boxs"
              *ngFor="let object of questionLoaderList"
            >
              <ngx-shimmer-loading [width]="'100%'"></ngx-shimmer-loading>
              <ngx-shimmer-loading [width]="'100%'"></ngx-shimmer-loading>
              <ngx-shimmer-loading [width]="'100%'"></ngx-shimmer-loading>

              <div class="row d-flex align-items-center">
                <div class="col-xl-2 col-lg-2 col-12">
                  <ngx-shimmer-loading
                    [shape]="'circle'"
                    [width]="'75px'"
                  ></ngx-shimmer-loading>
                </div>
                <div class="col-xl-10 col-lg-10 col-12">
                  <ngx-shimmer-loading [width]="'250px'"></ngx-shimmer-loading>
                  <ngx-shimmer-loading [width]="'250px'"></ngx-shimmer-loading>
                  <ngx-shimmer-loading [width]="'250px'"></ngx-shimmer-loading>
                </div>
              </div>
            </div>
          </ng-container>
          <!-- <div class="addthis_inline_share_toolbox"></div> -->

          <div *ngIf="topQuestionList.length == 0 && !questionLoader">
            <div class="no_que_found mt-5">
              <img src="assets/images/no_found.png" alt="" class="img-fluid" />
              NO THREADS POSTED YET
            </div>
          </div>
          <ng-container *ngFor="let question of topQuestionList; let i = index">
            <div class="questions_boxs" id="topquestion{{ i }}">
              <div class="padding_boxs">
                <div class="quebox_top">
                  <!-- <p [innerHtml]="question.question_text"></p> -->
                  <div class="editor_data">
                    <a (click)="questionDetail(question.id)"  [innerHtml]="question.question_text"></a>
                  </div>
                  <div class="quebox_media" *ngIf="question.filedata.length > 0">
                    <div
                      [attr.id]="'abc'+question.id"
                      class="carousel slide"
                      data-bs-ride="carousel"
                      data-bs-interval="false"
                      
                    >
                      <div
                        class="carousel-indicators"
                        *ngIf="question.filedata.length > 1"
                      >
                        <button
                          type="button"
                          [attr.data-bs-target]="'#abc'+question.id"
                          [attr.data-bs-slide-to]="i"
                          [attr.aria-label]="'Slide ' + i"
                          [ngClass]="{ active: i == 0 }"
                          *ngFor="let list of question.filedata; let i = index"
                          [attr.aria-current]="i == 0"
                        ></button>
                      </div>
                      <div class="carousel-inner">
                        <div
                          class="carousel-item"
                          [ngClass]="{ active: i == 0 }"
                          *ngFor="let list of question.filedata; let i = index"
                        >
                          <img
                            alt=""
                            class="d-block w-100"
                            *ngIf="list.type == 'img'"
                            src="{{ globals.profileImgeapiUrl }}/question/{{
                              question.id
                            }}/{{ list.name }}"
                          />
                          <div
                            class="ratio ratio-16x9"
                            *ngIf="list.type == 'video'"
                          >
                            <video controls>
                              <source
                                src="{{ globals.profileImgeapiUrl }}/question/{{
                                  question.id
                                }}/{{ list.name }}"
                              />
                            </video>
                          </div>
                        </div>
                      </div>
                      <button
                        *ngIf="question.filedata.length > 1"
                        class="carousel-control-prev"
                        type="button"
                        [attr.data-bs-target]="'#abc'+question.id"
                        data-bs-slide="prev"
                      >
                        <span
                          class="carousel-control-prev-icon"
                          aria-hidden="true"
                        ></span>
                        <span class="visually-hidden">Previous</span>
                      </button>
                      <button
                        *ngIf="question.filedata.length > 1"
                        class="carousel-control-next"
                        type="button"
                        [attr.data-bs-target]="'#abc'+question.id"
                        data-bs-slide="next"
                      >
                        <span
                          class="carousel-control-next-icon"
                          aria-hidden="true"
                        ></span>
                        <span class="visually-hidden">Next</span>
                      </button>
                    </div>
                  </div>
                </div>
                <a (click)="questionDetail(question.id)">
                  <div class="quebox_bottom d-flex align-items-center">
                    <div class="qt_photo">
                      <img
                        *ngIf="
                          question.profile_image_url == null ||
                          question.profile_image_url == ''
                        "
                        id="user_img"
                        src="assets/images/dummy.jpg"
                        alt=""
                        class="img-fluid"
                      />
                      <img
                        *ngIf="
                          question.profile_image_url != null &&
                          question.profile_image_url != ''
                        "
                        id="user_img"
                        src="{{ globals.profileImgeapiUrl }}/{{
                          question.profile_image_url
                        }}"
                        alt=""
                        class="img-fluid"
                      />
                    </div>
                    <div class="qt_desc">
                      <span class="user_name"
                        ><a
                          (click)="
                            publicProfile(
                              question.user_id,
                              question.send_anonymously
                            )
                          "
                          >{{ question.display_name }}</a
                        ></span
                      >
                      <ul class="queicon_lists">
                        <li
                          class="like_question"
                          *ngIf="
                            globals.authData == undefined ||
                            question.is_like == '' ||
                            question.is_like == null
                          "
                        >
                          <a
                            onclick="event.stopPropagation();"
                            (click)="likeDislikeQuestionAnswer(question, 1)" id="thumb{{question.id}}"
                            ><i class="fa-regular fa-thumbs-up"></i
                          ></a>
                          <span>{{ question.likes }}</span>
                        </li>
                        <li
                          class="like_question"
                          *ngIf="
                            question.is_like != '' && question.is_like != null
                          "
                        >
                          <a
                            onclick="event.stopPropagation();"
                            (click)="likeDislikeQuestionAnswer(question, 0)"  id="thumb{{question.id}}"
                            ><i class="fa-solid fa-thumbs-up"></i
                          ></a>
                          <span>{{ question.likes }}</span>
                        </li>
                        <li>
                          <i class="fa-regular fa-comment-dots"></i>
                          {{ question.comments }}
                        </li>
                        <li>
                          <i class="fa-regular fa-eye"></i> {{ question.views }}
                        </li>

                        <!-- <li class="community_lists">
                          <i class="fa-solid fa-bullhorn"></i>
                          <ng-container
                            *ngFor="let community of question.community"
                          >
                            <a (click)="communityQuestion(community.id)">{{
                              community.name
                            }}</a>
                          </ng-container>
                        </li> -->

                        <!-- <li><a  class="report"><i class="fa-solid fa-flag"></i> Report</a></li>
                                        <li *ngIf="question.invitee_can_invite_others != null"><a  class="report"><i class="fa-solid fa-share"></i> Share</a></li> -->
                      </ul>
                      <ul class="posted_expired_lists">
                        <li class="posted_date_time float-start">
                          <span>Posted:</span> {{ question.posted_time }}
                        </li>
                        <li class="expires_date_time float-start">
                          <span>{{ question.expire_title }}</span>
                          {{ question.Expire_time }}
                        </li>

                        <li
                          class="float-end"
                          id="reportQuestion{{ i }}"
                          *ngIf="
                            globals.authData == undefined ||
                            ((question.is_abused == '' ||
                              question.is_abused == null) &&
                              question.created_by != globals.authData.id &&
                              globals.reportQuestionAnswerEntity.question_id !=
                                question.id)
                          "
                        >
                          <a
                            onclick="event.stopPropagation();"
                            class="report"
                            (click)="
                              globals.reportPopUpOpen(
                                question.id,
                                '',
                                question.user_name
                              )
                            "
                            ><i class="fa-solid fa-flag"></i> Report</a
                          >
                        </li>
                        <li
                          class="float-end"
                          id="reportQuestion{{ i }}"
                          *ngIf="
                            (question.is_abused != '' &&
                              question.is_abused != null) ||
                            (globals.reportQuestionAnswerEntity.reportedTime !=
                              '' &&
                              globals.reportQuestionAnswerEntity.reportedTime !=
                                undefined &&
                              globals.reportQuestionAnswerEntity.question_id ==
                                question.id)
                          "
                        >
                          <a
                            onclick="event.stopPropagation();"
                            *ngIf="
                              globals.reportQuestionAnswerEntity.reportedTime !=
                                '' &&
                              globals.reportQuestionAnswerEntity.reportedTime !=
                                undefined &&
                              globals.reportQuestionAnswerEntity.question_id ==
                                question.id
                            "
                            class="report_question_answer"
                            title="{{
                              globals.commonTranslationText.questionDetail
                                .reportQuestionTooltip
                            }} {{
                              globals.reportQuestionAnswerEntity.reportedTime
                                | date : 'MMM d, y h:mm a'
                            }}"
                            ><i class="fa-solid fa-flag"></i> Reported
                          </a>
                          <a
                            onclick="event.stopPropagation();"
                            *ngIf="
                              globals.reportQuestionAnswerEntity.reportedTime ==
                                '' ||
                              globals.reportQuestionAnswerEntity.reportedTime ==
                                undefined ||
                              globals.reportQuestionAnswerEntity.question_id !=
                                question.id
                            "
                            class="report_question_answer"
                            title="{{
                              globals.commonTranslationText.questionDetail
                                .reportQuestionTooltip
                            }} {{
                              question.is_abused | date : 'MMM d, y h:mm a'
                            }}"
                            ><i class="fa-solid fa-flag"></i> Reported
                          </a>
                        </li>
                        <div class="clearfix"></div>
                      </ul>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </a>
              </div>
            </div>
          </ng-container>
        </div>

        <!-- <button class="first" (click)="postquestionbtn()">Post</button>
                <button class="second" (click)="draftquestionbtn()">Draft</button> -->
      </div>

      <div class="col-xl-4 col-lg-3 col-md-12 col-sm-12 col-12">
        <div class="home_wrap_right" id="stick-here">
          <!-- <div class="decideit_info">
            <div
              class="embed-responsive embed-responsive-16by9"
              *ngIf="auto_video_play == 1"
            >
              <iframe
                allow="autoplay; fullscreen;"
                class="embed-responsive-item"
                src="https://youtube.com/embed/u9NNeYTxKYQ?autoplay=1&mute=1"
                allowfullscreen
              ></iframe>
            </div>
            <div
              class="embed-responsive embed-responsive-16by9"
              *ngIf="auto_video_play == 0"
            >
              <iframe
                allow="autoplay; fullscreen;"
                class="embed-responsive-item"
                src="https://youtube.com/embed/u9NNeYTxKYQ"
                allowfullscreen
              ></iframe>
            </div>
          </div> -->
          <div id="amzn-assoc-ad-e6770c83-30be-4dff-bb83-71c43343179c"></div>

          <!-- <div class="top_communities">
            <div class="post_que_header">
              <h2 class="decideit_head"><span>Popular</span> Topics</h2>
            </div>
            <ul
              class="top_communities_lists"
              *ngIf="recentCommunityList.length > 0 && !topCommunityLoader"
            >
              <ng-container
                *ngFor="
                  let community of recentCommunityList;
                  let i = index;
                  let last = last
                "
              >
                <li [ngClass]="{ pt_0: i == 0, no_border: last }">
                  <img
                    src="{{ globals.profileImgeapiUrl }}/community-image/{{
                      community.community_thumbnail_image
                    }}"
                    alt=""
                    class="img-fluid"
                  />
                  <a (click)="communityQuestion(community.id)"
                    ><span>{{ community.name }}</span></a
                  >
                </li>
              </ng-container>
              <button
                (click)="topCommunityList()"
                type="submit"
                class="theme_btn theme_btn d-flex justify-content-center mx-auto"
              >
                View All
              </button>
            </ul>

            <ul class="top_communities_lists" *ngIf="topCommunityLoader">
              <div
                class="row d-flex align-items-center"
                *ngFor="let object of topCommunityLoaderList"
              >
                <div class="col-xl-3 col-lg-3 col-12">
                  <ngx-shimmer-loading [shape]="'circle'" [width]="'50px'">
                  </ngx-shimmer-loading>
                </div>
                <div class="col-xl-9 col-lg-9 col-12">
                  <ngx-shimmer-loading [width]="'175px'"></ngx-shimmer-loading>
                </div>
              </div>
            </ul>

            <div
              *ngIf="recentCommunityList.length == 0 && !topCommunityLoader"
              class="no_records_found d-flex justify-content-center align-items-center"
            >
              <span>There are no communities found</span>
            </div>
          </div> -->

          <div class="decide_it_stars_wrap mt-0">
            <div class="post_que_header">
              <h2 class="decideit_head"><span>EYEXchange Connect</span> Stars</h2>
            </div>
            <div
              class="dis_with_info pb-5"
              *ngIf="decideItStarList.length > 0 && !decideItStarLoader"
            >
              <div class="decideitstar_legend">
                <ul>
                  <li>
                    <span class="fiftyqa"></span> Threads
                  </li>
                  <li><span class="fifteenqa"></span> Comments</li>
                  <li><span class="open_que"></span> Likes</li>
                </ul>
              </div>
              <ng-container
                *ngFor="let star of decideItStarList; let last = last"
              >
                <div
                  class="dis_box d-flex align-items-center"
                  [ngClass]="{ no_border: last }"
                >
                  <div class="dis_photo">
                    <img
                      *ngIf="
                        star.profile_image_url == null ||
                        star.profile_image_url == ''
                      "
                      id="user_img"
                      src="assets/images/dummy.jpg"
                      alt=""
                      class="img-fluid"
                    />
                    <img
                      *ngIf="
                        star.profile_image_url != null &&
                        star.profile_image_url != ''
                      "
                      id="user_img"
                      src="{{ globals.profileImgeapiUrl }}/{{
                        star.profile_image_url
                      }}"
                      alt=""
                      class="img-fluid"
                    />
                  </div>
                  <div class="dis_info">
                    <a (click)="publicProfile(star.id, '')">
                      <!-- <span
                                                *ngIf="star.is_profile_private == 0 && ((star.first_name != '' || star.first_name != null) && (star.last_name != '' || star.last_name != null) )">{{star.first_name}}
                                                {{star.last_name}}</span> -->
                      <span>{{ star.first_name }} {{star.last_name}}</span>
                      <!-- <span *ngIf="star.is_profile_private == 1">{{
                        star.random_name
                      }}</span> -->
                    </a>
                    <br />
                    <span class="more_than_50">{{ star.active_questions_count }}</span>
                    <span class="more_than_15">{{ star.comments_count }}</span>
                    <span class="open_question">{{ star.question_likes_count }}</span>
                    <!-- <span class="open_question">{{
                      star.active_questions_count
                    }}</span> -->
                  </div>
                  <!-- <div class="dis_rank">
                                        <span>1</span>
                                    </div> -->
                  <div class="clearfix"></div>
                </div>
              </ng-container>
              <div class="mt-4 d-flex justify-content-center">
                <button
                  type="submit"
                  (click)="decideItStars()"
                  class="theme_btn"
                >
                  View All
                </button>
              </div>
            </div>
            <div class="dis_with_info pb-5" *ngIf="decideItStarLoader">
              <div
                class="row community_shimmer d-flex align-items-center"
                *ngFor="let object of topCommunityLoaderList"
              >
                <div class="col-xl-3 col-lg-3 col-12">
                  <ngx-shimmer-loading [shape]="'circle'" [width]="'50px'">
                  </ngx-shimmer-loading>
                </div>
                <div class="col-xl-9 col-lg-9 col-12">
                  <ngx-shimmer-loading [width]="'175px'"></ngx-shimmer-loading>
                  <ngx-shimmer-loading [width]="'175px'"></ngx-shimmer-loading>
                </div>
              </div>
            </div>

            <div
              *ngIf="decideItStarList.length == 0 && !decideItStarLoader"
              class="no_records_found d-flex justify-content-center align-items-center"
            >
              <span>There are no Eyedea Exchange Stars</span>
            </div>
          </div>

          <!-- <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center mb-4">
                        <h3 class="que_post_title">
                            Post Your Question
                        </h3>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <kendo-dropdownlist [defaultItem]="'Choose a community'" [valuePrimitive]="true"
                            [data]="groupedData" [valueField]="'id'" [textField]="'name'" [virtual]="virtual"
                            [filterable]="true" (filterChange)="handleFilter($event)">
                        </kendo-dropdownlist>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <kendo-dropdownlist [data]="listItems" [textField]="'text'" [valueField]="'index'"
                            [value]="selectedValue" [valuePrimitive]="true" (valueChange)="answerTypeChange($event)">
                            <ng-template kendoDropDownListItemTemplate let-dataItem>
                                <span class="template"><img src="{{ dataItem.value }}" alt="" class="img-fluid" />
                                </span> {{ dataItem.text }}
                            </ng-template>
                        </kendo-dropdownlist>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 post_que_editor">
                        <div *ngIf="selectedValue == 1">
                            <div class="answer_formate_box mt-3 mb-5">
                                <h3 class="float-start">Enter a list of answer options</h3>
                                <span class="optional_ans_formate float-end">Answer Format: [ Option List ]</span>
                                <div class="clearfix"></div>
                                <div class="row">
                                    <div class="col-md-12 col-12 col-lg-6 col-sm-12"
                                        *ngFor="let item of OptionsList; let i = index; let last = last;">
                                        <div class="row">
                                            <div class="col-md-12 col-12 col-lg-10 col-sm-10">
                                                <div class="form-group mb-3" id="emails">
                                                    <input kendoTextBox type="text" class="form-control"
                                                        name="OptionValue{{i}}" [value]="item.OptionValue + (i+1)"
                                                        id="OptionValue{{i}}" pattern="[a-zA-Z0-9\-.',\s]{2,}"
                                                        maxlength="100" required />
                                                </div>
                                            </div>
                                            <div class="col-md-12 col-12 col-lg-2 col-sm-2 pl_0">
                                                <button *ngIf="(i!=0 && last) || (i!=0 && !last) || (i==0 && !last)"
                                                    class="themeoption_btn" type="button"
                                                    (click)="RemoveOption(item)"><i class="fa-solid fa-xmark"></i></button>
                                                <button *ngIf="last && i<3" class="theme_btn" type="button"
                                                    (click)="AddOption(i)"><i class="fa-solid fa-plus"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="selectedValue == 2">
                            <span class="optional_ans_formate">Answer Format: [ Yes/No ]</span>
                        </div>
                        <div *ngIf="selectedValue == 3">
                            <div class="answer_formate_box mt-3 mb-5">
                                <h3 class="float-start">Enter my answer options as a Ranked list</h3>
                                <span class="optional_ans_formate float-end">Answer Format: [ Ranked List ]</span>
                                <div class="clearfix"></div>


                                <div class="row">
                                    <div class="col-md-12 col-12 col-lg-6 col-sm-12"
                                        *ngFor="let item of RankList; let i = index; let last = last;">
                                        <div class="row">
                                            <div class="col-md-12 col-12 col-lg-10 col-sm-10">
                                                <div class="form-group mb-3" id="emails">
                                                    <input kendoTextBox type="text" class="form-control"
                                                        name="OptionValue{{i}}" [value]="item.OptionValue + (i+1) "
                                                        id="OptionValue{{i}}" pattern="[a-zA-Z0-9\-.',\s]{2,}"
                                                        maxlength="100" required />
                                                </div>
                                            </div>
                                            <div class="col-md-12 col-12 col-lg-2 col-sm-2 pl_0">
                                                <button *ngIf="(i!=0 && last) || (i!=0 && !last) || (i==0 && !last)"
                                                    class="themeoption_btn" type="button"
                                                    (click)="RemoveOptionrank(item)"><i
                                                        class="fa-solid fa-xmark"></i></button>
                                                <button *ngIf="last && i<2" class="theme_btn" type="button"
                                                    (click)="AddOptionrank(i)"><i class="fa-solid fa-plus"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="selectedValue == 4">
                            <span class="optional_ans_formate">Answer Format: [ Express Yourself ]</span>
                        </div>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div class="post_que_editor">
                            <textarea id="field" placeholder="Enter Your Question Here" class="form-control"
                                (keyup)="countChar()"></textarea>
                            <div id="charNum"></div><span id="limit">0/255</span>
                            <div class="additional_video_option">
                                <div class="form-group file_upload">
                                    <input type="text" disabled class="form-control"
                                        placeholder="Upload Supporting Video" />
                                    <input type="file" name="fileToUpload" id="fileToUpload" class="form-control" />
                                </div>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>

                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 existing_group mt-4">
                        <h3 class="float-start">WHO WOULD YOU LIKE TO SEND THIS TO?</h3>
                        <kendo-dropdownlist class="mb-3 float-end" [defaultItem]="'Choose existing group'"
                            [valuePrimitive]="true" [data]="GroupItems">
                        </kendo-dropdownlist>
                        <textarea id="field" placeholder="Who would you like to send this?"
                            class="form-control"></textarea>
                        <small>Enter email addresses or telephone numbers separated by commas
                            (Example : bestfriend@url.com, 123-321-4565, sister@xyz.com)</small>
                        <button type="submit" class="theme_btn mt-2 float-end" id="next_btn">Save as Group</button>
                        <div class="clearfix"></div>
                    </div>

                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div class="more_send_options mt-4">
                            <h3>More send options</h3>
                            <div class="check_box">
                                <input type="checkbox" name="Check" id="Check1" value="Check1">
                                <label for="Check1" class="checkboxbtn_label">Show results only to me</label>
                            </div>
                            <div class="check_box">
                                <input type="checkbox" name="Check" id="Check2" value="Check2">
                                <label for="Check2" class="checkboxbtn_label">Recipients can invite others</label>
                            </div>
                            <div class="check_box">
                                <input type="checkbox" name="Check" id="Check3" value="Check3">
                                <label for="Check3" class="checkboxbtn_label">Show Invitee list only to me</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center mt-5">
                        <button type="submit" class="themeoption_btn" id="next_btn">Save
                            Draft</button>
                        <button type="submit" class="theme_btn" id="next_btn">Post</button>
                    </div> -->
        </div>
      </div>
    </div>
  </div>

  <!-- 
    <div class="home_top_sections top_questions_wrap">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12">
                    <div class="question_form">
                        <form>
                            <div class="row">
                                <div class="col-xl-8 col-lg-8 col-md-12">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Enter Your Question</label>
                                        <textarea class="form-control" rows="5" id="field"
                                            placeholder="Enter Your Question Here" (keyup)="countChar()"></textarea>
                <div id="charNum"></div>
                <span id="limit">255 Characters Remaining</span>
            </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-12">
            <div class="upload_video">
                <div class="form-group file_upload">
                    <label>Upload Video</label>
                    <input type="text" disabled class="form-control" />
                    <input type="file" name="fileToUpload" id="fileToUpload" class="form-control" />
                </div>
            </div>
        </div>
    </div>

    <button type="submit" class="theme_btn_white float-end" id="next_btn" (click)="next()">Next</button>

    <div id="next_div">
        <div class="que_post_answer_type">
            <h3 class="que_post_head">Select Your Answer Format</h3>
            <span class="sub_head">Select your answer format. Hover over each button to see your
                option formats.</span>
            <div class="ans_type_section">
                <div class="row">
                    <div class="col-xl-2 col-lg-2 mt-5">
                        <div class="ans_type_box d-flex align-items-center">
                            <span class="ans_type_title flex-fill">Option<br>List</span>
                            <div class="ans_type_onhover flex-fill">
                                <div class="radio_box">
                                    <input type="radio" id="option1" kendoRadioButton />
                                    <label class="k-radio-label" for="option1">Option 1</label>
                                </div>
                                <div class="radio_box">
                                    <input type="radio" id="option2" kendoRadioButton />
                                    <label class="k-radio-label" for="option2">Option 2</label>
                                </div>
                                <div class="radio_box">
                                    <input type="radio" id="option3" kendoRadioButton />
                                    <label class="k-radio-label" for="option3">Option 3</label>
                                </div>
                                <div class="radio_box">
                                    <input type="radio" id="option4" kendoRadioButton />
                                    <label class="k-radio-label" for="option4">Option 4</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-2 mt-5">
                        <div class="ans_type_box d-flex align-items-center">
                            <span class="ans_type_title flex-fill">Yes<br>Or No</span>
                            <div class="ans_type_onhover flex-fill">
                                <div class="radio_box">
                                    <input type="radio" id="yes" kendoRadioButton />
                                    <label class="k-radio-label" for="yes">Yes</label>
                                </div>
                                <div class="radio_box">
                                    <input type="radio" id="no" kendoRadioButton />
                                    <label class="k-radio-label" for="no">No</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-2 mt-5">
                        <div class="ans_type_box d-flex align-items-center">
                            <span class="ans_type_title flex-fill">Ranked<br>List</span>
                            <div class="ans_type_onhover flex-fill">
                                <ul>
                                    <li>
                                        1 - Choice A
                                    </li>
                                    <li>
                                        2 - Choice B
                                    </li>
                                    <li>
                                        3 - Choice C
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-2 mt-5">
                        <div class="ans_type_box d-flex align-items-center">
                            <span class="ans_type_title flex-fill">Express<br>Yourself</span>
                            <div class="ans_type_onhover flex-fill">
                                <span class="express">No decisions,<br>just discussion</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group mt-5">
            <label for="exampleInputEmail1">WHO WOULD YOU LIKE TO SEND THIS TO?</label>
            <small>Enter email addresses or telephone numbers separated by commas
                (Example : bestfriend@url.com, 123-321-4565, sister@xyz.com)</small>
            <textarea class="form-control"></textarea>
            <button type="submit" class="theme_btn_white float-end mt-3">Save as Group</button>
        </div>

        <div class="form-group mt-5">
            <label for="exampleInputEmail1">Invite the decideit community to answer <i class="fa-solid fa-caret-right"></i></label>
        </div>

        <div class="form-group mt-5">
            <label for="exampleInputEmail1">More send options <i class="fa-solid fa-caret-right"></i></label>
        </div>


        <div class="col-xl-12 col-lg-12 text-center mt-5">
            <button type="submit" class="theme_btn_white">Submit Your Question</button>
            <button type="submit" class="theme_btn_white">Preview</button>
            <button type="submit" class="theme_btn_white">Save as a Draft</button>
        </div>
    </div>


    <div class="clearfix"></div>
    </form>
</div>
</div>
</div>
</div>
</div> -->

  <!-- <div class="home_top_sections top_responders_wrpa">
        <div class="container-fluid">
            <div class="row">
            </div>
        </div>
    </div> -->
  <!-- <div class="home_top_sections decideit_stars_wrap">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="decideit_stars pt_50">
                        <h2 class="home_top_head text-center float-start">DecideIt Stars</h2>
                        <button class="theme_btn float-end">View All DecideIt Stars</button>
                        <div class="clearfix"></div>
                        <div class="row">
                            <div class="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-12">
                                <div class="top_que_box box_one">
                                    <img src="assets/images/decideit_stars/ds1.jpg" alt="" class="img-fluid" />
                                    <div class="box_overlay">
                                        <h3>Connor Nelson
                                        </h3>
                                        <span class="fiftyqa">
                                            <ul class="que_indicator_lists">
                                                <li><span class="fiftyqa"></span> = 50 Questions Answered</li>
                                                <li><span class="fifteenqa"></span> = 15 Questions Answered</li>
                                            </ul>
                                        </span>
                                        <span class="total_response_que"> Questions <strong
                                                class="fiftyqa">67</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-12">
                                <div class="top_que_box box_two">
                                    <img src="assets/images/decideit_stars/ds2.jpg" alt="" class="img-fluid" />
                                    <div class="box_overlay">
                                        <h3>Roberto Williams</h3>
                                        <span class="total_response_que">Total Responses Questions
                                            <strong>4</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-12">
                                <div class="top_que_box box_three">
                                    <img src="assets/images/decideit_stars/ds3.jpg" alt="" class="img-fluid" />
                                    <div class="box_overlay">
                                        <h3>Jorge Harris</h3>
                                        <span class="total_response_que">Total Responses Questions
                                            <strong>14</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-12">
                                <div class="top_que_box box_four">
                                    <img src="assets/images/decideit_stars/ds4.jpg" alt="" class="img-fluid" />
                                    <div class="box_overlay">
                                        <h3>Riley Thomasy</h3>
                                        <span class="fifteenqa">
                                            <ul class="que_indicator_lists">
                                                <li><span class="fiftyqa"></span> = 50 Questions Answered</li>
                                                <li><span class="fifteenqa"></span> = 15 Questions Answered</li>
                                            </ul>
                                        </span>
                                        <span class="total_response_que">Total Responses Questions <strong
                                                class="fifteenqa">12</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-12">
                                <div class="top_que_box box_five">
                                    <img src="assets/images/decideit_stars/ds5.jpg" alt="" class="img-fluid" />
                                    <div class="box_overlay">
                                        <h3>Dave Robinson</h3>
                                        <span class="fiftyqa">
                                            <ul class="que_indicator_lists">
                                                <li><span class="fiftyqa"></span> = 50 Questions Answered</li>
                                                <li><span class="fifteenqa"></span> = 15 Questions Answered</li>
                                            </ul>
                                        </span>
                                        <span class="total_response_que">Total Responses Questions <strong
                                                class="fiftyqa">51</strong></span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-12">
                                <div class="top_que_box box_five">
                                    <img src="assets/images/decideit_stars/ds1.jpg" alt="" class="img-fluid" />
                                    <div class="box_overlay">
                                        <h3>Wade Walker</h3>
                                        <span class="fiftyqa">
                                            <ul class="que_indicator_lists">
                                                <li><span class="fiftyqa"></span> = 50 Questions Answered</li>
                                                <li><span class="fifteenqa"></span> = 15 Questions Answered</li>
                                            </ul>
                                        </span>
                                        <span class="total_response_que">Total Responses Questions <strong
                                                class="fiftyqa">51</strong></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</div>
<!-- <kendo-dialog
  title="Add Group"
  *ngIf="addGroupPopup"
  (close)="closeaddGroupPopup()"
  [minWidth]="250"
  [width]="725"
>
  <div class="table_block">
    <form #addGroupForm="ngForm" (ngSubmit)="addGroup(addGroupForm)">
      <div class="form-group">
        <label
          ><span class="mendatory_feild">*</span>
          {{ globals.commonTranslationText.home.form.addGroup.label }}
        </label>
        <input
          kendoTextBox
          type="text"
          class="form-control"
          pattern="^[a-zA-Z0-9_/]{2,}[\sa-zA-Z0-9_/]*"
          #group_name="ngModel"
          data-toggle="tooltip"
          maxlength="100"
          data-placement="top"
          [(ngModel)]="addGroupEntity.group_name"
          name="group_name"
          id="group_name"
          [ngClass]="{
            error:
              (group_name.dirty && group_name.invalid) ||
              (group_name.pristine && group_submitted) ||
              errorEntity.group_name
          }"
          required
        />
        <span
          class="error_class"
          *ngIf="
            (group_name.dirty && group_name.invalid) ||
            (group_name.pristine && group_submitted) ||
            errorEntity.group_name
          "
        >
          <span *ngIf="group_name.errors && group_name.errors.required">
            {{ globals.commonTranslationText.home.form.addGroup.required }}
          </span>
          <span *ngIf="group_name.errors && group_name.errors.pattern">
            {{ globals.commonTranslationText.home.form.addGroup.pattern }}
          </span>
          <span *ngIf="errorEntity.group_name != ''">
            <span>{{ errorEntity.group_name }}</span>
          </span>
        </span>
      </div>
      <div class="clearfix"></div>

      <div class="col-xl-12 text-center">
        <button type="submit" class="theme_btn">Add</button>
      </div>

      <div class="clearfix"></div>
    </form>
  </div>
</kendo-dialog> -->

<kendo-dialog
  *ngIf="mediaInfoOpened" (close)="mediaInfoClose()"
  [minWidth]="250"
  [width]="725"
  class="mediaInfo_popup"
>
<button class="close_btn" (click)="mediaInfoClose()"><i class="fa-solid fa-xmark"></i></button>
<p>Should you wish to upload media, you have the option to select a maximum of three media files simultaneously. The valid image extensions encompass 'jpg', 'jpeg', 'png', 'JPEG', 'JPG', 'PNG', 'heic','BMP','bmp','webp','WEBP' and 'HEIC'. On the other hand, the acceptable video extensions consist of '.mp3', '.guv', 'mp4', 'MP4', 'MKV', 'mkv', 'WEBM', 'webm', 'avi', and 'mov'.</p>
</kendo-dialog>
