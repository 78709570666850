<div class="content_block fixed-head">
    <div class="container-fluid">
      <div class="row">
        <!-- Content -->
        <div class="col-md-12 col-sm-12">
          <div class="title_block">
            <h2 class="float-start">
              {{globals.commonTranslationText.notification.title}}</h2>
            <div class="breadcrumb_block float-start">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a routerLink="/home" *ngIf="globals.authData.roles_id == 2">
                    <i class="fa-solid fa-house-chimney"></i>
                  </a>
                  <!-- <a routerLink="/dashboard" *ngIf="globals.authData.RoleId == 3">
                    <i class="fa-solid fa-house-chimney"></i>
                  </a> -->
              </li>
              <li class="breadcrumb-item"><a
                  routerLink="/notifications">{{globals.commonTranslationText.notification.breadcrumbTitle}}</a>
              </li>
              <!-- <li class="breadcrumb-item active" aria-current="page">
                  {{globals.commonTranslationText.notification.breadcrumbSubTitle}}</li> -->
            </ol>
          </div>

          <div class="clearfix"></div>
        </div>
        <div class="row d-flex justify-content-center">
          <div class="col-md-12 col-sm-12">
            <div class="inner_content_block">
              <div class="table_block">
                <div class="alert alert-{{globals.type}}" role="alert" *ngIf="globals.msgflag">
                  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                  {{globals.message}}
                </div>
                <div class="clearfix"></div>
                <kendo-grid [kendoGridBinding]="notificationList" kendoGridSelectBy="id" [pageSize]="10"
                  [pageable]="true" [groupable]="false" [reorderable]="false" [resizable]="false" [height]="auto"
                  [sortable]="{
                      allowUnsort: true,
                      mode: 'single'
                      }" [sort]="sort" (pageChange)="globals.pageScroll()">
                  <kendo-grid-messages pagerItems="notifications" pagerItemsPerPage="notifications per page"
                    noRecords="No notifications available.">
                  </kendo-grid-messages>
                  <ng-template kendoGridToolbarTemplate>
                    <input placeholder="Search in all columns..." kendoTextBox
                      (input)="onFilter($event.target.value)" />
                    <!-- <button kendoGridExcelCommand type="button" icon="file-excel" style="float:right;"></button>
                              <button kendoGridPDFCommand icon="file-pdf" style="float:right;"></button>
                              <button type="button" class="k-button" icon="file-pdf" style="float:right;" (click)="excelexport.save()">Export To Excel</button> -->
                  </ng-template>
                  <!-- <kendo-grid-column title="{{globals.commonTranslationText.notification.collumns.srNo}}" [width]="50">
                      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        {{rowIndex+1}}
                      </ng-template>
                    </kendo-grid-column> -->
                  <kendo-grid-column width="700" field="notification_text"
                    title="{{globals.commonTranslationText.notification.collumns.notifications}}">
                  </kendo-grid-column>
                  <kendo-grid-column width="200"
                    title="{{globals.commonTranslationText.notification.collumns.dateTime}}">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <span
                        *ngIf="(currentDate | date : 'yyyy/MM/dd') == (dataItem.created_at | date : 'yyyy/MM/dd')">{{dataItem.created_at
                        | date : 'h:mm a'}}</span>
                      <span
                        *ngIf="(yesterdayDate | date : 'yyyy/MM/dd') == (dataItem.created_at | date : 'yyyy/MM/dd')">Yesterday,
                        {{dataItem.created_at | date : 'h:mm a'}}</span>
                      <span
                        *ngIf="((yesterdayDate | date : 'yyyy/MM/dd') != (dataItem.created_at | date : 'yyyy/MM/dd')) && ((currentDate | date : 'yyyy/MM/dd') != (dataItem.created_at | date : 'yyyy/MM/dd')) && ((beforeWeekDate | date : 'yyyy/MM/dd') <= (dataItem.created_at | date : 'yyyy/MM/dd'))">
                        {{dataItem.created_at | date : 'EEE, h:mm a'}}</span>
                      <span
                        *ngIf="((beforeWeekDate | date : 'yyyy/MM/dd') > (dataItem.created_at | date : 'yyyy/MM/dd'))">
                        {{dataItem.created_at | date : 'MMM dd, yyyy, h:mm a'}}</span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column [headerClass]="'text-center'" class="text-center" title="{{globals.commonTranslationText.notification.collumns.actions}}"
                    width="100">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                      <button kendoButton class="edit" target="_blank" (click)="redirect(dataItem.action_url)">
                        <i title="Redirect" class="fa-solid fa-up-right-from-square"></i>
                      </button>
                      <button kendoButton class="delete" (click)="dismissNotification(dataItem)">
                        <i title="Dismiss" class="fa-solid fa-xmark"> </i>
                      </button>
                    </ng-template>
                  </kendo-grid-column>

                </kendo-grid>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Content -->
      <div class="clearfix"></div>
    </div>
  </div>
</div>