import { Component, OnInit,ViewEncapsulation } from '@angular/core';
declare var $: any, Swal;

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AboutusComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    // $(window).on('load resize scroll', function () {
    //   var f = $('footer');
    //   f.css({ position: 'static' });
    //   if (f.offset().top + f.height() < $(window).height()) {
    //     f.css({ position: 'fixed', bottom: '0', width: '100%' });
    //   }
    // });
  }
}
