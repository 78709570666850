import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Globals } from '.././globals';
import { Router } from '@angular/router';
import { ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient } from "@angular/common/http";
import { CookieService } from 'ngx-cookie-service';

declare var $, IN, Swal: any, FB: any;

function _window(): any {
  //debugger
  // return the global native browser window object
  return window;
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {

  loginEntity;
  errorEntity;
  submitted;
  btn_disable;
  current_time;
  socialNetworkEntity;
  showEmailBlock;
  showPhoneNumberBlock;
  showUsernameBlock;
  loginSelectType = 'email';
  linkedInCredentials = {
    clientId: "77qvngpcnfhc0b",
    // redirectUrl: this.globals.baseUrl + '/login/'
    redirectUrl: "http://localhost:4200/login/"
  };
  linkedInToken;
  constructor(private http: HttpClient, private httpClient: HttpClient, private socialAuthService: AuthService, private router: Router, private route: ActivatedRoute, private AuthService: AuthService, public globals: Globals, private cookieService: CookieService) {
  }
  login1() {

    // $(window).on('load resize scroll', function () {
    //   var f = $('footer');
    //   f.css({ position: 'static' });
    //   if (f.offset().top + f.height() < $(window).height()) {
    //     f.css({ position: 'fixed', bottom: '0', width: '100%' });
    //   }
    // });
  }

  ngOnInit() {
    const oauthScript = document.createElement('script');
    oauthScript.src = 'https://cdn.rawgit.com/oauth-io/oauth-js/c5af4519/dist/oauth.js';

    document.body.appendChild(oauthScript);
    this.showEmailBlock = true;
    this.showPhoneNumberBlock = false;
    this.showUsernameBlock = false;
    this.loginEntity = {};
    this.errorEntity = {};
    this.socialNetworkEntity = [];
    var self = this;
    this.AuthService.getSocialNetworks()
      .then((data) => {
        console.log(data);
        this.socialNetworkEntity = data['data'];
      },
        (error) => {
          this.globals.isLoading = false;
          console.log(error);
        });

    let type = this.route.snapshot.paramMap.get('type');
    let token = this.route.snapshot.paramMap.get('token');
    let email = this.route.snapshot.paramMap.get('email');
    if ((type && token) || (type && token && email)) {
      this.globals.isLoading = true;
      var entity: any;
      var verifyEmailAPILink;
      if (type == 'profile-email-verify') {
        entity = {
          'id': token,
          'email': email
        }
        verifyEmailAPILink = 'verifyEmail';
      }
      else {
        entity = {
          'type': type,
          'token': token
        }
        verifyEmailAPILink = 'login/user-action';
      }
      this.AuthService.UserInviteAction(entity, verifyEmailAPILink)
        .then((data) => {
          this.globals.isLoading = false;
          Swal.fire({
            type: this.globals.configurationText.sweetAlertTypeIconSuccess,
            icon: this.globals.configurationText.sweetAlertTypeIconSuccess,
            customClass: this.globals.configurationText.sweetAlertClassSuccess,
            toast: true,
            iconColor: 'white',
            title: this.globals.commonTranslationText.loginPage.alerts.successActivateAccount.text,
            animation: true,
            position: this.globals.configurationText.sweetAlertPosition,
            showConfirmButton: false,
            timer: this.globals.configurationText.sweetAlertTimer,
            timerProgressBar: true,
            showCancelButton: true,
            cancelButtonText: 'x',
          });
          this.router.navigate(['/login']);
        },
          (error) => {
            this.globals.isLoading = false;
            if (error.status == 404) {
              Swal.fire({
                type: this.globals.configurationText.sweetAlertTypeIconError,
                icon: this.globals.configurationText.sweetAlertTypeIconError,
                customClass: this.globals.configurationText.sweetAlertClassError,
                toast: true,
                iconColor: 'white',
                title: this.globals.commonTranslationText.loginPage.alerts.alreadyUseLink.text,
                animation: true,
                position: this.globals.configurationText.sweetAlertPosition,
                showConfirmButton: false,
                timer: this.globals.configurationText.sweetAlertTimer,
                timerProgressBar: true,
                showCancelButton: true,
                cancelButtonText: 'x',
              });
            }
            else if (error.error.status == 'User Deleted') {
              Swal.fire({
                icon: this.globals.commonTranslationText.loginPage.alerts.userDeleted.type,
                type: this.globals.commonTranslationText.loginPage.alerts.userDeleted.type,
                title: this.globals.commonTranslationText.loginPage.alerts.userDeleted.title,
                text: this.globals.commonTranslationText.loginPage.alerts.userDeleted.text,
                iconColor: 'white',
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonClass: 'theme_btn',
                confirmButtonText: 'Ok'
              })
            }
          });
    }

    // $(document).ready(function () {
    //   const body = document.querySelector('body');
    //   body.style.setProperty('--screen-height', $(window).height() + "px");
    // });

    $("#password-show").click(function () {
      $(this).toggleClass("fa-eye-slash fa-eye")
      if ($("#password").attr("type") == "password") {
        $("#password").attr("type", "text");
      }
      else {
        $("#password").attr("type", "password");
      }
    });
  }
  login2(e) {
    //debugger
    e.preventDefault();
    _window().OAuth.initialize('-x8Fg4y9jV65ZHBUEHJ7IteZgi8');
    _window().OAuth.popup('twitter').then((twitter) => {
      twitter.me().then((data) => {
        console.log('data: ', data);
        alert('Welcome ' + data.name + '!');
      });

      twitter.get('/1.1/account/verify_credentials.json?include_email=true').then((data) => {
        console.log('self data:', data);
      });
    });
  }  
  aaa(self) {
    console.log(self);
    // let a = this.loginEntity.EmailAddress.trim();

    // console.log("sss"+a);
    // //alert(a);
    // //$(this).val().trim();
    // this.loginEntity.EmailAddress='sa'+a;
  }
  filter(s) {
    var ms = s % 1000;
    s = (s - ms) / 1000;
    var secs = s % 60;
    s = (s - secs) / 60;
    var mins = s % 60;
    var hrs = (s - mins) / 60;
    //return hrs + ':' + mins + ':' + secs + ':' + ms;
    return hrs + ':' + mins + ':' + secs;
  }

  validationCheck(value, loginForm) {
    this.loginEntity = {};
    loginForm.form.markAsPristine();
    loginForm.form.controls.Password.touched = false;
    this.loginSelectType = value;
    this.submitted = false;
    if (value == 'phone') {
      this.showPhoneNumberBlock = true;
      this.showEmailBlock = false;
      this.showUsernameBlock = false;
    }
    else if (value == 'username') {
      this.showUsernameBlock = true;
      this.showPhoneNumberBlock = false;
      this.showEmailBlock = false;
    }
    else {
      this.showEmailBlock = true;
      this.showPhoneNumberBlock = false;
      this.showUsernameBlock = false;
    }
  }
  checkEmailUsernameValid;
  checkvalidation() {
    //debugger
    var contact_details: any;
      if (this.loginEntity.EmailAddress != undefined && this.loginEntity.EmailAddress != '') {
        // contact_details = this.postQuestionEntity.contact_details.filter(function (v) { return $.trim(v) !== '' });
        console.log(contact_details);
        var count = 0;
          var emailformat = /^([a-zA-Z0-9_.-]{1,})+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z]{2,})$/;
          var usernameformat = /^([a-zA-Z]{1,})+([a-zA-Z0-9]{1,})$/;
            if (!($.trim(this.loginEntity.EmailAddress)).match(emailformat) && !($.trim(this.loginEntity.EmailAddress)).match(usernameformat)) {
              this.checkEmailUsernameValid = true;
            }
        else {
          this.checkEmailUsernameValid = false;
        }
      }
      else {
        this.checkEmailUsernameValid = false;
      }
    

    //^[0][1-9]\d{9}$|^[1-9]\d{9}$
    // var emailformat = '[a-zA-Z0-9.-_]{1,1}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,3}';
    // var emailformat = '[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}';

  }

  login(loginForm) {
    //debugger
    console.log(this.loginSelectType);
    this.submitted = true;
    if (loginForm.valid && !this.checkEmailUsernameValid) {
      this.btn_disable = true;
      this.loginEntity.user_email_mobile = (this.loginSelectType == 'email' ? this.loginEntity.EmailAddress : (this.loginSelectType == 'phone' ? this.loginEntity.PhoneNumber : this.loginEntity.Username));
      this.loginEntity.password = this.loginEntity.Password;
      this.loginEntity.social_network_id = 1;
      console.log(this.loginEntity);
      this.globals.isLoading = true;
      this.AuthService.checkLogin(this.loginEntity)
        .then((data) => {
          console.log(data);

          setTimeout(() => {
            this.globals.isLoading = false;
            var authData: any = JSON.parse(localStorage.getItem('authData'));
            if(authData.roles_id == 1){
              localStorage.setItem('isUser',"1");
            }else{
              localStorage.setItem('isUser',"2");
            }
            var isUser = localStorage.getItem('isUser');
            console.log(authData);
            var email_available = localStorage.getItem('email_available');
            var username_available = localStorage.getItem('username_available');
              if(email_available == "0" && username_available == "0")
              {
                this.cookieService.set('errorMessage', this.globals.commonTranslationText.loginPage.alerts.emailUsernameCompulsory.text, this.globals.cookieMessageExpireTime,'/');
                this.globals.isLoading = true;
                      window.location.href = '/profile';
              }
              else if(email_available == "0")
              {
                this.cookieService.set('errorMessage', this.globals.commonTranslationText.loginPage.alerts.emailCompulsory.text, this.globals.cookieMessageExpireTime,'/');
                this.globals.isLoading = true;
                      window.location.href = '/profile';
              }
              else if(username_available == "0")
              {
                this.cookieService.set('errorMessage', this.globals.commonTranslationText.loginPage.alerts.usernameCompulsory.text, this.globals.cookieMessageExpireTime,'/');
                this.globals.isLoading = true;
                      window.location.href = '/profile';
              }
            else if (this.cookieService.check('post_question')) {
              window.location.href = '/home';
            }
            else if (this.cookieService.check('URL')) {
               if (authData.roles_id == 1)
                window.location.href = '/admin/adminDashboard';
                // window.location.href = '/admin/community/list';
              else if (authData.roles_id == 2)
              {
                window.location.href = this.cookieService.get('URL');
              }
              this.cookieService.delete('URL');
            }
            else if (this.cookieService.check('communityQuestionURL')) {
              window.location.href = this.cookieService.get('communityQuestionURL');
              this.cookieService.delete('communityQuestionURL');
            }
            else {
              if (isUser=="1")
                window.location.href = '/admin/adminDashboard';
                // window.location.href = '/admin/community/list';
              else if (isUser=="2")
                window.location.href = '/userDashboard';
            }
          }, 2000);

        },
          (error) => {
            //debugger
            if (error.status == 404) {
              //debugger
              Swal.fire({
                type: this.globals.configurationText.sweetAlertTypeIconError,
                icon: this.globals.configurationText.sweetAlertTypeIconError,
                customClass: this.globals.configurationText.sweetAlertClassError,
                toast: true,
                iconColor: 'white',
                title: error.error.message,
                animation: true,
                position: this.globals.configurationText.sweetAlertPosition,
                showConfirmButton: false,
                timer: this.globals.configurationText.sweetAlertTimer,
                timerProgressBar: true,
                showCancelButton: true,
                cancelButtonText: 'x',
              });
            }
            else if (error.status == 402) {
             var text = (this.loginSelectType == 'email' ? 'Incorrect email address, username or password.' : (this.loginSelectType == 'phone' ? 'Phone or password is wrong' : 'Username or password is wrong'));
             Swal.fire({
              type: this.globals.configurationText.sweetAlertTypeIconError,
              icon: this.globals.configurationText.sweetAlertTypeIconError,
              customClass: this.globals.configurationText.sweetAlertClassError,
              toast: true,
               title: text,
               iconColor: 'white',
              animation: true,
              position: this.globals.configurationText.sweetAlertPosition,
              showConfirmButton: false,
              timer: this.globals.configurationText.sweetAlertTimer,
              timerProgressBar: true,
              showCancelButton: true,
              cancelButtonText: 'x',
            });
            }

            else if (error.status == 422) {
              this.errorEntity.email = (error.error.message.EmailAddress != "") ? error.error.message.EmailAddress : '';
              this.errorEntity.password = (error.error.message.Password != "") ? error.error.message.Password : '';
            } else if (error.status == 401) {
              Swal.fire({
                title: this.globals.commonTranslationText.loginPage.alerts.commonMessage.title,
                html: error.error.message,
                icon: "warning",
                iconColor: 'white',
                type: this.globals.commonTranslationText.loginPage.alerts.commonMessage.type,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonClass: 'theme_btn',
                confirmButtonText: 'Resend',
                cancelButtonClass: 'cancel_btn',
                cancelButtonText: "Close"
              })
                .then((result) => {
                  if (result.value) {
                    if (error.error.data.user_email_mobile_pending == 1) {
                      var entity = {
                        'user_id': error.error.data.user_id
                      }
                      this.AuthService.resendEmailVerification(entity)
                        .then((data) => {
                          this.globals.isLoading = false;
                          console.log(data);
                          Swal.fire({
                            type: this.globals.configurationText.sweetAlertTypeIconSuccess,
                            icon: this.globals.configurationText.sweetAlertTypeIconSuccess,
                            customClass: this.globals.configurationText.sweetAlertClassSuccess,
                            toast: true,
                            title: this.globals.commonTranslationText.loginPage.alerts.activeEmailResend.text,
                            animation: true,
                            iconColor: 'white',
                            position: this.globals.configurationText.sweetAlertPosition,
                            showConfirmButton: false,
                            timer: this.globals.configurationText.sweetAlertTimer,
                            timerProgressBar: true,
                            showCancelButton: true,
                            cancelButtonText: 'x',
                          });

                        },
                          (error) => {
                            console.log(error.error.code);
                            this.globals.isLoading = false;


                          });
                    }
                    else {
                      this.router.navigate(['/verifyOTP/' + window.btoa(error.error.data.user_id) + "/" + window.btoa(error.error.data.phone)]);
                    }
                    this.globals.isLoading = true;

                  }
                })
            }
            else {
              // this.globals.pageNotfound(error.error.code);
            }
            this.globals.isLoading = false;
            this.btn_disable = false;
            this.submitted = false;
          });
    }
  }
}
