<div class="container-fluid login_register_bg fixed-head">
  <div class="loginregister_white_block">
    <div class="login_logo_block">
      <div class="text-center">
        <a routerLink="/login">
          <img src="assets/images/loginbg.png" width="300" class="img-fluid" alt="">
        </a>
      </div>
    </div>
<div class="white_login_block">
          <div class="row d-flex align-items-center">
            <div class="col-12 col-xl-6 col-lg-6"></div>
            <div class="col-12 col-xl-6 col-lg-6">
              <div class="padding_login_block">
                <div class="login-head">
                <h3 class="float-start">Sign Up</h3>
                <div class="login_button_block float-end d-flex align-items-center">
                  <span class="alreadyreg">Already have an account?</span>
                  <a routerLink="/login" class="theme_btn" 
                   >{{globals.commonTranslationText.registerPage.buttons.haveAccount}}</a>
                </div>
                <div class="clearfix"></div>
              </div>
              <p class="padding_15">Enter your details to Sign Up:</p>
              <div class="clearfix"></div>
                <form #registerForm="ngForm" (ngSubmit)="register(registerForm)">
                  <div class="form-group mb-5">
                        <label ><span class="mendatory_feild">*</span>
                            First Name</label>
                        <input kendoTextBox class="form-control" type="text" required
                            #first_name="ngModel" [(ngModel)]="registerEntity.first_name"
                            name="first_name" id="first_name"  pattern="^[a-zA-Z/]{2,}[\sa-zA-Z/]*"
                            [ngClass]="{error: (first_name.dirty && first_name.invalid) || (first_name.pristine && submitted && first_name.errors && first_name.errors.required) || errorEntity.first_name}"
                            maxlength="100">
                        <span class="error_class"
                            *ngIf="(first_name.dirty && first_name.invalid) || (first_name.pristine && submitted) || errorEntity.first_name">
                            <span *ngIf="first_name.errors && first_name.errors.required">
                                {{globals.commonTranslationText.profilePage.personalInformationForm.firstName.required}}
                            </span>
                            <span *ngIf="first_name.errors && first_name.errors.pattern">
                                {{globals.commonTranslationText.profilePage.personalInformationForm.firstName.pattern}}
                            </span>
                            <span *ngIf="errorEntity.first_name != ''">
                                {{errorEntity.first_name}}
                            </span>
                        </span>
                    </div>
                    <div class="form-group mb-5">
                        <label><span class="mendatory_feild">*</span>
                            Last Name</label>
                        <input kendoTextBox class="form-control" type="text" required
                            name="LastName" #last_name="ngModel"
                            [(ngModel)]="registerEntity.last_name"
                            [ngClass]="{error: (last_name.dirty && last_name.invalid) || (last_name.pristine && submitted && last_name.errors && last_name.errors.required) || errorEntity.last_name}"
                            pattern="^[a-zA-Z/]{2,}[\sa-zA-Z/]*" maxlength="100">
                        <span class="error_class"
                            *ngIf="(last_name.dirty && last_name.invalid) || (last_name.pristine && submitted) ||errorEntity.last_name">
                            <span *ngIf="last_name.errors && last_name.errors.required">
                                {{globals.commonTranslationText.profilePage.personalInformationForm.lastName.required}}
                            </span>
                            <span *ngIf="last_name.errors && last_name.errors.pattern">
                                {{globals.commonTranslationText.profilePage.personalInformationForm.lastName.pattern}}
                            </span>
                            <span *ngIf="errorEntity.last_name != ''">
                                {{errorEntity.last_name}}
                            </span>
                        </span>
                    </div>
                  <div class="form-group mb-5">
                    <label><span class="mendatory_feild">*</span> {{globals.commonTranslationText.registerPage.form.userName.label}}</label>
                    <input kendoTextBox type="text" class="form-control" pattern="[a-zA-Z]{1,}[a-zA-Z0-9]{1,}" #name="ngModel"
                      [(ngModel)]="registerEntity.name" name="name" maxlength="100"
                      [ngClass]="{error: (name.dirty && name.invalid) || (name.pristine && submitted) || errorEntity.user_name}"
                      required id="name" />
                    <span class="error_class" *ngIf="(name.dirty && name.invalid) || (name.pristine && submitted)">
                      <span *ngIf="name.errors && name.errors.required">
                        {{globals.commonTranslationText.registerPage.form.userName.required}}
                      </span>
                      <span *ngIf="name.errors && name.errors.pattern">
                        {{globals.commonTranslationText.registerPage.form.userName.pattern}}
                      </span>
                    </span>
                    <span class="error_class">
                      <span>{{ errorEntity.user_name }}</span>
                    </span>
                  </div>

                  <!-- <div class="radio_box">
                    <input type="radio" name="selectlogintype" (change)="validationCheck('email',registerForm)" id="role2" value="Role2" checked>
                    <label for="role2">Email </label>
                  </div>
                  <div class="radio_box">
                    <input type="radio" name="selectlogintype" (change)="validationCheck('phone',registerForm)" id="role3" value="Role3">
                    <label for="role3">Phone</label>
                  </div> -->


                  <!-- <input type="radio" checked name="selectlogintype" (change)="validationCheck('email',registerForm)">Email
                  <input type="radio" name="selectlogintype" (change)="validationCheck('phone',registerForm)">Phone -->



                  <div class="form-group mb-5" id="email">
                    <label><span class="mendatory_feild">*</span> {{globals.commonTranslationText.registerPage.form.emailAddress.label}}</label>
                    <input kendoTextBox type="text" class="form-control" #user_email="ngModel"
                      [(ngModel)]="registerEntity.user_email" name="user_email" maxlength="100"
                      [ngClass]="{error: (user_email.dirty && user_email.invalid) || (user_email.pristine && submitted) || errorEntity.user_email}"
                      required id="user_email" pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"  />
                    <span class="error_class"
                      *ngIf="(user_email.dirty && user_email.invalid) || (user_email.pristine && submitted)">
                      <span *ngIf="user_email.errors && user_email.errors.required">
                        {{globals.commonTranslationText.registerPage.form.emailAddress.required}}
                      </span>
                      <span *ngIf="user_email.errors && user_email.errors.pattern">
                        {{globals.commonTranslationText.registerPage.form.emailAddress.pattern}}
                      </span>
                    </span>
                    <span class="error_class">
                      <span>{{ errorEntity.user_email }}</span>
                    </span>
                  </div>
                  <div class="form-group mb-5" id="phone" >
                    <label>{{globals.commonTranslationText.registerPage.form.phoneNumber.label}}</label>
                    <!-- <kendo-dropdownlist [data]="countryCodeList" [filterable]="false" [textField]="'name'"
                      [valueField]="'id'" [valuePrimitive]="true" #country_code_id="ngModel"
                      [(ngModel)]="registerEntity.country_code_id" name="country_code_id"
                      [ngClass]="{error: (country_code_id.dirty && country_code_id.invalid) || (country_code_id.pristine && submitted)}"
                      required (valueChange)="answerTypeChange()"></kendo-dropdownlist> -->
                     <input kendoTextBox type="text" class="form-control" #user_mobile="ngModel"
                      [(ngModel)]="registerEntity.user_mobile" name="user_mobile" maxlength="14"
                      [ngClass]="{error:errorEntity.user_mobile || phoneValid}"
                       id="user_mobile"  (keyup)="phoneNumberFormat(registerEntity.user_mobile)"/>
                       <!-- <span class="password_note">Standard text message rates apply</span> -->
                    <span class="error_class"
                      *ngIf="(user_mobile.dirty && user_mobile.invalid) || (user_mobile.pristine && submitted) || phoneValid">
                      <span *ngIf="user_mobile.errors && user_mobile.errors.required">
                        {{globals.commonTranslationText.registerPage.form.phoneNumber.required}}
                      </span>
                      <span *ngIf="phoneValid">
                        {{globals.commonTranslationText.registerPage.form.phoneNumber.pattern}}
                      </span>
                    </span>
                    <span class="error_class">
                      <span>{{ errorEntity.user_mobile }}</span>
                    </span>
                  </div>
                    
                  <div class="form-group mb-5">
                    <label><span class="mendatory_feild">*</span>
                      {{globals.commonTranslationText.registerPage.form.newPassword.label}}</label>
                    <input kendoTextBox (keyup)="checkpassword()" type="password" class="form-control" id="newpassword"
                      pattern="[a-zA-Z0-9_\-@$*&!#]{8,50}" #password="ngModel" [(ngModel)]="registerEntity.password"
                      name="password"
                      [ngClass]="{error: (password.dirty && password.invalid) || (password.pristine && submitted) || errorEntity.password}"
                      required />
                      <span class="password_note">{{globals.commonTranslationText.registerPage.form.password_note}}</span>
                    <span class="error_class"
                      *ngIf="(password.dirty && password.invalid) || (password.pristine && submitted) || errorEntity.password">
                      <span *ngIf="password.errors && password.errors.required">
                        {{globals.commonTranslationText.registerPage.form.newPassword.required}}
                      </span>
                      <span *ngIf="password.errors && password.errors.pattern">
                        {{globals.commonTranslationText.registerPage.form.newPassword.pattern}}
                      </span>
                      <span *ngIf="errorEntity.password != ''">
                        {{errorEntity.password}}
                      </span>
                    </span>
                    <i class="fa-regular fa-fw fa-eye toggle-password" id="newpassword-show"></i>
                  </div>

                  <div class="form-group mb-5">
                    <label><span class="mendatory_feild">*</span>
                      {{globals.commonTranslationText.registerPage.form.confirmPassword.label}}</label>
                    <input kendoTextBox type="password" class="form-control" id="confpassword" (keyup)="checkpassword()"
                      pattern="[a-zA-Z0-9_\-@$*&!#]{8,50}" #cPassword="ngModel" [(ngModel)]="registerEntity.cPassword"
                      name="cPassword"
                      [ngClass]="{error: ((cPassword.dirty && cPassword.invalid) || (cPassword.pristine && submitted)) || (same && !(cPassword.errors && cPassword.errors.pattern && cPassword.errors.required)) || errorEntity.cPassword}"
                      required />
                      <span class="password_note">{{globals.commonTranslationText.registerPage.form.password_note}}</span>
                    <span class="error_class"
                      *ngIf="(cPassword.dirty && cPassword.invalid) || (cPassword.pristine && submitted) || same || errorEntity.cPassword">
                      <span *ngIf="cPassword.errors && cPassword.errors.required && !same">
                        {{globals.commonTranslationText.registerPage.form.confirmPassword.required}}
                      </span>

                      <span *ngIf="cPassword.errors && cPassword.errors.pattern && !same">
                        {{globals.commonTranslationText.registerPage.form.confirmPassword.pattern}}
                      </span>
                      <span
                        *ngIf="same && !(cPassword.errors && cPassword.errors.pattern && cPassword.errors.required)">
                        {{globals.commonTranslationText.registerPage.form.confirmPassword.match}}
                      </span>
                      <span *ngIf="errorEntity.cPassword != ''">
                        {{errorEntity.cPassword}}
                      </span>
                    </span>
                    <i class="fa-regular fa-fw fa-eye toggle-password" id="confpassword-show"></i>
                  </div>
                  <div>
                    <div class="privacy_policy check_box float-start mb-4">
                      <input type="checkbox" checked="checked" name="is_checked"
                        [(ngModel)]="registerEntity.is_checked" id="is_checked" value="Check1">
                      <label
                        for="is_checked"> {{globals.commonTranslationText.registerPage.form.privacyPolicy.label_part1}}<a href="https://documents.jcahpo.org/documents/JCAHPOPrivacyPolicy.pdf" target="_blank">{{globals.commonTranslationText.registerPage.form.privacyPolicy.label_part2}}</a>and, <a href="https://documents.jcahpo.org/documents/JCAHPOTermsofUse.pdf" target="_blank">{{globals.commonTranslationText.registerPage.form.privacyPolicy.label_part3}}</a></label>
                    </div>
                    <button kendoButton type="submit" [disabled]="registerEntity.is_checked!=true"
                      class="all_btn theme_btn float-end">{{globals.commonTranslationText.registerPage.buttons.submit}}</button>
                    <div class="clearfix"></div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
  </div>
</div>