<div class="home_wrap dashboard_wrap community_lists fixed-head">
    <div class="community_list_banner">
        <h2 class="decideit_head float-start"><span>Popular</span> Topics</h2>
        <div class="clearfix"></div>
        <p>Find popular IJCAHPO Eyedea Exchange topics.</p>
    </div>
    <div class="container-fluid">
        <div class="row pb-5">
            <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                <div class="row">
                    <!-- <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                        <div class="community_lists_left">
                            <div class="latest_community_head">
                                Categories
                            </div>
                            <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist"
                                aria-orientation="vertical">
                                <ng-container *ngFor="let category of categoryList;let i=index;">
                                    <a *ngIf="i<categoryLimit" (click)="communities(category)"
                                        [ngClass]="{active:category.id==categoryId}" class="nav-link"
                                        id="v-pills-home-tab{{categoryId}}" data-bs-toggle="pill" href="#v-pills-home"
                                        role="tab" aria-controls="v-pills-home"
                                        aria-selected="true">{{category.name}}</a>
                                </ng-container>
                                <ng-container *ngIf="categoryLoader">
                                    <div class="row d-flex align-items-center">
                                        <div class="community_shimmer">
                                            <div class="col-xl-9 col-lg-9 col-12"
                                                *ngFor="let object of categoryLatestcommunityLoaderList">
                                                <ngx-shimmer-loading [height]="'20px'" [width]="'190px'">
                                                </ngx-shimmer-loading>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <div *ngIf="categoryList.length==0  && !categoryLoader"
                                    class="no_records_found norecordfound_inner d-flex justify-content-center align-items-center">
                                    <span>There are no categories found</span>
                                </div>
                                <button kendoButton type="submit"
                                    *ngIf="categoryLimit == 10 && categoryLimit != categoryList.length"
                                    class="theme_btn theme_btn d-flex justify-content-center mx-auto mt-3"
                                    (click)="categoryLimit = categoryList.length">Show More</button>
                                <button kendoButton type="submit" *ngIf="categoryLimit > 10 && categoryLimit == categoryList.length"
                                    class="theme_btn theme_btn d-flex justify-content-center mx-auto mt-3"
                                    (click)="categoryLimit = 10">Show Less</button>
                            </div>
                            <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist"
                                aria-orientation="vertical">
                                <a class="nav-link active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home"
                                    role="tab" aria-controls="v-pills-home" aria-selected="true">All Communities</a>
                                <a class="nav-link" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile"
                                    role="tab" aria-controls="v-pills-profile" aria-selected="false">Sports</a>
                                <a class="nav-link" id="v-pills-messages-tab" data-toggle="pill"
                                    href="#v-pills-messages" role="tab" aria-controls="v-pills-messages"
                                    aria-selected="false">Cars</a>
                                <a class="nav-link" id="v-pills-settings-tab" data-toggle="pill"
                                    href="#v-pills-settings" role="tab" aria-controls="v-pills-settings"
                                    aria-selected="false">Politics</a>
                                <button type="submit"
                                    class="theme_btn theme_btn d-flex justify-content-center mx-auto mt-3">Show More</button>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12"> -->
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">    
                        <div class="community_lists_right">
                            <div class="latest_community_head">
                                <div class="community_headings">
                                    <span class="w_100" *ngIf="categoryId == ''">
                                        <span>{{categoryName}}</span>
                                    </span>
                                    <span class="w_100" *ngIf="categoryId != ''">Topics in
                                        <span>{{categoryName}}</span>
                                    </span>
                                </div>
                                <div class="community_searchbar w_100">
                                    <div class="input-group">
                                        <input kendoTextBox type="text" (keyup)="search()" id="searchTerm" class="form-control"
                                            placeholder="Search">
                                        <i class="fa-solid fa-magnifying-glass"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-content home_wrap_right" id="v-pills-tabContent">
                                <div class="search-results" infinite-scroll [infiniteScrollDistance]="scrollDistance"
                                    [infiniteScrollUpDistance]="scrollUpDistance" [infiniteScrollThrottle]="throttle"
                                    (scrolled)="onScrollDown($event)">
                                    <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel"
                                        [attr.aria-labelledby]="'v-pills-home-tab'+categoryId">
                                        <div class="decide_it_stars_wrap top_responders">
                                            <div class="dis_with_info">
                                                <ng-container
                                                    *ngFor="let communities of communityList;let j=index;let last=last;">
                                                    <div class="dis_box d-flex align-items-center"
                                                        [ngClass]="{no_border:last}">
                                                        <div class="dis_photo">
                                                            <img src="{{globals.profileImgeapiUrl}}/community-image/{{communities.community_thumbnail_image}}"
                                                                alt="" class="img-fluid" />
                                                        </div>
                                                        <div class="dis_info">
                                                            <a
                                                                (click)="communityQuestion(communities.id)"><span>{{communities.name}}</span></a>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="communityLoader1 || communityLoader2">

                                        <div class="row community_shimmer d-flex align-items-center"
                                            *ngFor="let object of communityLoaderList;">
                                            
                                            <div class="col-xl-3 col-lg-3 col-12">
                                                <ngx-shimmer-loading [shape]="'circle'" [width]="'50px'">
                                                </ngx-shimmer-loading>
                                            </div>
                                            <div class="col-xl-9 col-lg-9 col-12">
                                                <ngx-shimmer-loading [width]="'175px'"></ngx-shimmer-loading>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                                <div *ngIf="communityList.length==0 && !communityLoader1 && !communityLoader2"
                                    class="no_records_found norecordfound_inner d-flex justify-content-center align-items-center">
                                    <span>No Topics found for your search</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                <div class="home_wrap_right">
                    <div class="decideit_info">
                        <div class="advertisement_box">
                            <p>IJCAHPO Eyedea Exchange is your key to fast, easy and clutter-free group decisions.</p>
                            <!-- <p>Send to up to 1000 friends instantly, Now!</p> -->
                            <!--<p>Threads Remain Live for 99 Hrs</p>-->
                        </div>
                    </div>

                    <div class="decide_it_stars_wrap top_responders">
                        <div class="dis_with_info">
                            <div class="latest_community_head">
                                Latest Topics
                            </div>
                            <ng-container *ngFor="let latestCommunity of latestCommunityList;let last=last;">
                                <div class="dis_box d-flex align-items-center" [ngClass]="{no_border:last}">
                                    <div class="dis_photo">
                                        <img src="{{globals.profileImgeapiUrl}}/community-image/{{latestCommunity.community_thumbnail_image}}"
                                            alt="" class="img-fluid" />
                                    </div>
                                    <div class="dis_info">

                                        <a
                                            (click)="communityQuestion(latestCommunity.id)"><span>{{latestCommunity.name}}</span></a>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="latestCommunityLoader">
                                <div class="row community_shimmer d-flex align-items-center"
                                    *ngFor="let object of categoryLatestcommunityLoaderList">
                                    <div class="col-xl-3 col-lg-3 col-12">
                                        <ngx-shimmer-loading [shape]="'circle'" [width]="'50px'">
                                        </ngx-shimmer-loading>
                                    </div>
                                    <div class="col-xl-9 col-lg-9 col-12">
                                        <ngx-shimmer-loading [width]="'175px'"></ngx-shimmer-loading>
                                    </div>
                                </div>
                            </ng-container>
                            <div *ngIf="latestCommunityList.length==0  && !latestCommunityLoader"
                                class="no_records_found norecordfound_inner d-flex justify-content-center align-items-center">
                                <span>No latest communities found for your search</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>