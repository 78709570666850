import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Globals } from '.././globals';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { RegisterService } from '../services/register.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie-service';
declare var $, Swal: any;

@Component({
  selector: 'app-otp-verify',
  templateUrl: './otp-verify.component.html',
  styleUrls: ['./otp-verify.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class OtpVerifyComponent implements OnInit {
  otpEntity;
  submitted;
  errorEntity;
  btn_disable;
  constructor(public globals: Globals, private router: Router, private route: ActivatedRoute, private RegisterService: RegisterService,
    private cookieService: CookieService) { }

  ngOnInit(): void {
    this.otpEntity = {};
    this.errorEntity = {};

    // $(document).ready(function () {
    //   const body = document.querySelector('body');
    //   body.style.setProperty('--screen-height', $(window).height() + "px");
    // });

  }
  verifyOTP(otpForm) {
    //debugger
    let id = window.atob(this.route.snapshot.paramMap.get('id'));
    let phone = window.atob(this.route.snapshot.paramMap.get('phone'));
    let type = this.route.snapshot.paramMap.get('type');
    console.log(type);
    if (id && phone) {
      var entity = {
        'user_id': id,
        'mobile': phone,
        'otp': this.otpEntity.otp
      }
      this.submitted = true;

      if (otpForm.valid) {
        this.btn_disable = true;
        this.globals.isLoading = true;
        this.RegisterService.otpVerify(entity)
          .then((data) => {
            this.globals.isLoading = false;
            this.btn_disable = false;
            this.submitted = false;
            this.otpEntity = {};
            var msg;
            if(this.globals.authData !=undefined)
            {
              msg = 'Your mobile number has been verified successfully.'
            }
            else
            {
              msg = this.globals.commonTranslationText.otpPage.alerts.success.text
            }
            otpForm.form.markAsPristine();
            this.cookieService.set('successMessage', msg, this.globals.cookieMessageExpireTime,'/');
            if(type)
            {//debugger
              window.location.href = '/resetPassword/' + type + '/' + window.btoa(id);
            }
            else
            {
              this.globals.isLoading = true;
              window.location.href = '/login';
            }

          },
            (error) => {
              console.log(error.error.code);
              this.globals.isLoading = false;
              this.btn_disable = false;
              if (error.error.code == 302) {
                Swal.fire({
                  type: this.globals.configurationText.sweetAlertTypeIconError,
                  icon: this.globals.configurationText.sweetAlertTypeIconError,
                  customClass: this.globals.configurationText.sweetAlertClassError,
                  toast: true,
                  title: error.error.message,
                  animation: true,
                  position: this.globals.configurationText.sweetAlertPosition,
                  showConfirmButton: false,
                  timer: this.globals.configurationText.sweetAlertTimer,
                  timerProgressBar: true,
                  showCancelButton: true,
                  cancelButtonText: 'x',
                });
              }
              else if (error.error.code == 422) {
                this.errorEntity.otp = (error.error.message.otp != "") ? error.error.message.otp : '';
                //this.errorEntity.Description = (error.error.message.description != "") ? error.error.message.description : '';
              }
              else {
                // this.globals.pageNotfound(error.error.code);
                //this.globals.isLoading = true;
              }

            });
      }
    }
  }
  resendOTP() {
    //debugger
    let id = window.atob(this.route.snapshot.paramMap.get('id'));
    let phone = window.atob(this.route.snapshot.paramMap.get('phone'));
    if (id && phone) {
      var entity = {
        'user_id': id,
        'mobile': phone
      }
      Swal.fire({
        title: this.globals.commonTranslationText.otpPage.alerts.resendConfirm.title,
        text: this.globals.commonTranslationText.otpPage.alerts.resendConfirm.text,
        icon: "warning",
        type: this.globals.commonTranslationText.otpPage.alerts.resendConfirm.type,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonClass: 'theme_btn',
      confirmButtonText: 'Yes',
      cancelButtonClass: 'cancel_btn',
      cancelButtonText: "No"
      })
        .then((result) => {
          if (result.value) {
            this.globals.isLoading = true;
            this.RegisterService.resendOTP(entity)
              .then((data) => {
                this.globals.isLoading = false;
                Swal.fire({
                  type: this.globals.configurationText.sweetAlertTypeIconSuccess,
                  icon: this.globals.configurationText.sweetAlertTypeIconSuccess,
                  customClass: this.globals.configurationText.sweetAlertClassSuccess,
                  toast: true,
                  title: this.globals.commonTranslationText.otpPage.alerts.resendSuccess.text,
                  animation: true,
                  position: this.globals.configurationText.sweetAlertPosition,
                  showConfirmButton: false,
                  timer: this.globals.configurationText.sweetAlertTimer,
                  timerProgressBar: true,
                  showCancelButton: true,
                  cancelButtonText: 'x',
                });
                // Swal.fire({
                //   icon: this.globals.commonTranslationText.otpPage.alerts.resendSuccess.type,
                //   type: this.globals.commonTranslationText.otpPage.alerts.resendSuccess.type,
                //   title: this.globals.commonTranslationText.otpPage.alerts.resendSuccess.title,
                //   text: this.globals.commonTranslationText.otpPage.alerts.resendSuccess.text,
                //   showCancelButton: false,
                //   showConfirmButton: true,
                //   confirmButtonClass: 'theme_btn',
                //   confirmButtonText: 'Ok',
                //   allowOutsideClick: false
                // }).then((result) => {
                //   if (result.value) {
                //     // this.globals.isLoading = true;
                //     // this.router.navigate(['/login']);
                //   }
                // })

              },
                (error) => {
                  console.log(error.error.code);
                  this.globals.isLoading = false;
                  this.btn_disable = false;
                  if (error.error.code == 302) {
                    Swal.fire({
                      type: this.globals.configurationText.sweetAlertTypeIconError,
                      icon: this.globals.configurationText.sweetAlertTypeIconError,
                      customClass: this.globals.configurationText.sweetAlertClassError,
                      toast: true,
                      title: error.error.message,
                      animation: true,
                      position: this.globals.configurationText.sweetAlertPosition,
                      showConfirmButton: false,
                      timer: this.globals.configurationText.sweetAlertTimer,
                      timerProgressBar: true,
                      showCancelButton: true,
                      cancelButtonText: 'x',
                    });
                  }
                  else if (error.error.code == 422) {
                    this.errorEntity.otp = (error.error.message.otp != "") ? error.error.message.otp : '';
                    //this.errorEntity.Description = (error.error.message.description != "") ? error.error.message.description : '';
                  }
                  else {
                    // this.globals.pageNotfound(error.error.code);
                    //this.globals.isLoading = true;
                  }

                });
          }
        })
    }
  }
}
