import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import  { FormsModule }  from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from "@angular/common";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import  { BrowserAnimationsModule }  from '@angular/platform-browser/animations';
import { TooltipModule } from '@progress/kendo-angular-tooltip';

import { SortableModule } from '@progress/kendo-angular-sortable';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';

import { LabelModule } from '@progress/kendo-angular-label';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { PopupModule } from '@progress/kendo-angular-popup';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { GridModule, PDFModule, ExcelModule } from '@progress/kendo-angular-grid';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ToolBarModule } from '@progress/kendo-angular-toolbar';
import { EditorModule } from '@progress/kendo-angular-editor';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxShimmerLoadingModule } from  'ngx-shimmer-loading';
import { CookieService } from 'ngx-cookie-service';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { LoginfacebookComponent } from './loginfacebook/loginfacebook.component';
import { HomeComponent } from './home/home.component';
import { UserDashboardComponent } from './user-dashboard/user-dashboard.component';
import { ProfileComponent } from './profile/profile.component';
import { PostQuestionComponent } from './post-question/post-question.component';
import { OtpVerifyComponent } from './otp-verify/otp-verify.component';
import { AddressbookComponent } from './addressbook/addressbook.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PubilcProfileComponent } from './pubilc-profile/pubilc-profile.component';
import { CommunityQuestionComponent } from './community-question/community-question.component';
import { QuestionDetailComponent } from './question-detail/question-detail.component';
import { TopQuestionsComponent } from './top-questions/top-questions.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { UpgradePlanComponent } from './upgrade-plan/upgrade-plan.component';
import { DecideitStarsComponent } from './decideit-stars/decideit-stars.component';
import { CommunityListComponent } from './community-list/community-list.component';
import { ContactusComponent } from './contactus/contactus.component';
import { GlobalSearchComponent } from './global-search/global-search.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';

// import { SocialLoginModule,AuthServiceConfig,GoogleLoginProvider,FacebookLoginProvider} from 'angular-6-social-login'

// export function getAuthServiceConfigs() {
//   const config = new AuthServiceConfig(
//   [
//   {
//       id: FacebookLoginProvider.PROVIDER_ID,
//       provider: new FacebookLoginProvider('585217322198235')
//   }
//   // ,
//   // {
//   //     id: GoogleLoginProvider.PROVIDER_ID,
//   //     provider: new GoogleLoginProvider('Your_Google_Client_ID')
//   // }
//   ]
//   );
//   return config;
//   }

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    LoginComponent,
    RegisterComponent,
    LoginfacebookComponent,
    HomeComponent,
    UserDashboardComponent,
    ProfileComponent,
    PostQuestionComponent,
    OtpVerifyComponent,
    AddressbookComponent,
    PagenotfoundComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    PubilcProfileComponent,
    CommunityQuestionComponent,
    QuestionDetailComponent,
    TopQuestionsComponent,
    AboutusComponent,
    UpgradePlanComponent,
    DecideitStarsComponent,
    CommunityListComponent,
    ContactusComponent,
    GlobalSearchComponent,
    NotificationsComponent,
    CookiePolicyComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    
    
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    LabelModule,
    InputsModule, DropDownsModule, DateInputsModule, TreeViewModule, PopupModule, GridModule, BrowserAnimationsModule, TooltipModule, SortableModule,
    PDFExportModule, ButtonsModule, ExcelExportModule, PDFModule, ExcelModule,DialogsModule,LayoutModule,ToolBarModule,EditorModule,
    InfiniteScrollModule,NgxShimmerLoadingModule,ShareButtonsModule,ShareIconsModule,
    // SocialLoginModule
  ],
  providers: [CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
