import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Globals } from '.././globals';
import { Router } from '@angular/router';
import { ActivatedRoute, Params } from '@angular/router';
import { EditorComponent } from '@progress/kendo-angular-editor';
import { QuestionDetailService } from '../services/question-detail.service';
import { Meta, MetaDefinition } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_dataviz from '@amcharts/amcharts4/themes/dataviz';
import am4themes_material from '@amcharts/amcharts4/themes/material';
import am4themes_kelly from '@amcharts/amcharts4/themes/kelly';
import am4themes_frozen from '@amcharts/amcharts4/themes/frozen';
import am4themes_moonrisekingdom from '@amcharts/amcharts4/themes/moonrisekingdom';
import { HomeService } from '../services/home.service';
import { AdminDashboardService } from '../app-admin/services/admin-dashboard.service';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
declare var $: any, Swal;
declare var $: any;
declare function myInput(): any;
@Component({
  selector: 'app-question-detail',
  templateUrl: './question-detail.component.html',
  styleUrls: ['./question-detail.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class QuestionDetailComponent implements OnInit,AfterViewInit {
  public palettes = {
    TeamA: ['Peter Franken', 'Simon Crowther', 'Catherine Dewey'],
  };

  questionId;
  questionDetailEntity;
  questionCommentsEntity;
  communityDetailEntity;
  relatedCommunityList;
  questionDetailLoading1;
  questionDetailLoading2;
  questionDetailLoading3;
  questionDetailLoading4;
  postAnswerEntity;
  videourl;
  btn_disabled;
  postAnswerHide;
  postEditAnswerEntity;
  uploadImageData;
  random_theme: any;
  userAnswerOptions;
  answerSendOption;
  invitePopUp;
  inviteOthersEntity;
  invitesubmitted;
  contactDetailsValidation;
  errorEntity;
  invitationLimit;
  urlMeta: string = 'og:url';
  descriptionMeta: string = 'og:description';
  imageMeta: string = 'og:image';
  site_name: string = 'og:site_name';
  currentLink;
  imageSize: any;
  videoSize: any;
  checkImgSizeKB;
  checkVideoKB;
  files;
  mydata;
  push_Image_video;
  mydataToUpload;
  errorMessage;
  maxxxxxxLength;
  len;
 // answer_image;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public globals: Globals,
    private QuestionDetailService: QuestionDetailService,
    private cookieService: CookieService,
    private HomeService: HomeService,
    private metaService: Meta,
    private AdminDashboardService: AdminDashboardService
  ) {}

  //   public value = `
  //   <p>
  //       The Kendo UI Angular Editor allows your users to edit HTML in a familiar, user-friendly way.<br />
  //       In this version, the Editor provides the core HTML editing engine which includes basic text formatting, hyperlinks, and lists.
  //       The widget <strong>outputs identical HTML</strong> across all major browsers, follows
  //       accessibility standards, and provides API for content manipulation.
  //   </p>
  //   <p>Features include:</p>
  //   <ul>
  //       <li>Text formatting</li>
  //       <li>Bulleted and numbered lists</li>
  //       <li>Hyperlinks</li>
  //       <li>Cross-browser support</li>
  //       <li>Identical HTML output across browsers</li>
  //   </ul>
  // `;
  
  public mediaInfoOpened = false;
  public mediaInfoClose(): void {
    this.mediaInfoOpened = false;
  }
  public mediaInfoOpen(): void {
    this.mediaInfoOpened = true;
  }
  
  public listsortby: Array<string> = ['Top', 'New', 'Old'];
  public selectedValuesortby = 'Top';
  public value: any = [];

  ngOnInit(): void {
    // $('body').on('click', '#info_toggle_detailpage', function () {
    //   $(this).toggleClass('info_open');
    //   $('.info_message').slideToggle();
    // });
    if (this.globals.isUser == '1') {
      $('#question_detail_page').addClass(
        'col-md-10 col-sm-10 offset-md-2 main_block'
      );
    }
    setTimeout(() => {
      var euButton = $('.k-editor .k-editor-toolbar span').find( ":contains('Media')").parents('.k-button-solid-base');
      euButton.addClass('editor_upload');
      var euText = euButton.text();
      euButton.attr("title", euText);
      //alert(euText);
      euButton.find('span.k-button-text').html( "<label for='fileToUpload'>" + euText + "</lable>" );
    }, 2500);
    // $(window).on('load resize scroll', function () {
    //   var f = $('footer');
    //   f.css({ position: 'static' });
    //   if (f.offset().top + f.height() < $(window).height()) {
    //     f.css({ position: 'fixed', bottom: '0', width: '100%' });
    //   }
    // });

    this.currentLink = window.location.href;
    console.log(this.currentLink);
    this.questionDetailEntity = {};
    this.questionCommentsEntity = {};
    this.communityDetailEntity = {};
    this.relatedCommunityList = [];
    this.postEditAnswerEntity = {};
    this.postEntity = {};
    this.questionDetailLoading1 = true;
    this.questionDetailLoading2 = true;
    this.questionDetailLoading3 = true;
    this.questionDetailLoading4 = true;
    this.postAnswerHide = true;
    this.postAnswerEntity = {};
    this.answerSendOption = {};
    this.invitePopUp = false;
    this.inviteOthersEntity = {};
    this.errorEntity = {};
    this.files = [];
    this.mydata = [];
    this.mydataToUpload = [];
    //this.answer_image = [];
    this.push_Image_video = [];
    this.uploadImageData = [];
    this.AnswerEntity = {};
    this.AnswerEntity.Check = false;
    if (this.cookieService.check('post_answer')) {
      this.postAnswerEntity = JSON.parse(this.cookieService.get('post_answer'));
      this.questionDetailEntity.options = this.postAnswerEntity.user_option_id;
      console.log(this.postAnswerEntity);
      if (
        this.postAnswerEntity.send_anonymously != '0' &&
        this.postAnswerEntity.send_anonymously != undefined
      ) {
        $('#Check').attr('checked', true);
      } else {
        delete this.postAnswerEntity.send_anonymously;
      }

      console.log(this.postAnswerEntity);
    }
    $('#btnshowdiscussion').click(function () {
      //debugger;
      if ($(this).val() == 'View Response by other users ▼') {
        $('#showdiscussion').show();
        $(this).val('Hide Response by other users ▲');
      } else {
        $('#showdiscussion').hide();
        $(this).val('View Response by other users ▼');
      }
    });
    // var questionType = this.route.snapshot.paramMap.get('questionType');
    // if(questionType != '' && questionType != null)
    // {
    //   this.questionId = this.route.snapshot.paramMap.get('id');
    // }
    // else
    // {
    //   this.questionId = window.atob(this.route.snapshot.paramMap.get('id'));
    // }
    this.globals.isLoading = true;
    this.questionId = this.route.snapshot.paramMap.get('id');
    if (this.globals.viewCount == 1) {
      this.QuestionDetailService.getQuestionDetail(
        this.questionId,
        'question_view',
        ''
      ).then(
        (data) => {
          this.globals.viewCount = 0;
        },
        (error) => {
          console.log(error);
        }
      );
    }
    this.getQuestionDetails();
    this.HomeService.getImageVideoSize().then((data) => {
      // console.log(data['data'])
      this.imageSize = data['data'].ImageVideo[0].value;
      this.videoSize = data['data'].ImageVideo[1].value;
    });
  }

  sharebtn() {
    $('.share_options').toggleClass('active');
  }


  getQuestionDetails() {
    this.QuestionDetailService.getQuestionDetail(
      this.questionId,
      'question_detail',
      ''
    ).then(
      (data) => {
        this.globals.isLoading = false;
        this.questionDetailLoading1 = false;
        console.log(data);
        this.questionDetailEntity = data;
        this.getQuestionComments();
        var check = sessionStorage.getItem('get_reported_response')
        // if(check == 'checked'){
        //   this.AnswerEntity.Check = true;
        //   this.getAnswersreported()
        // }
        // else{
        //   // this.getAnswers();
        // }
        var imageArrayExtensions = [
          'jpg',
          'jpeg',
          'png',
          'JPEG',
          'JPG',
          'PNG',
          'heic',
        ];
        var videoArrayExtensions = [
          'mp4',
          'MP4',
          ' MKV',
          'mkv',
          'WEBM',
          'webm',
          'avi',
          'mov',
        ];
        if (this.questionDetailEntity.file_extention != '') {
          if (
            $.inArray(
              this.questionDetailEntity.file_extention.toLowerCase(),
              imageArrayExtensions
            ) != -1
          ) {
            this.questionDetailEntity.imageExtension = true;
          } else if (
            $.inArray(
              this.questionDetailEntity.file_extention.toLowerCase(),
              videoArrayExtensions
            ) != -1
          ) {
            this.questionDetailEntity.videoExtension = true;
          }
        }
        if (this.questionDetailEntity.community.length > 0) {
          this.invitationLimit =
            this.questionDetailEntity.plan.community_question_number_of_invitations;
        } else {
          this.invitationLimit =
            this.questionDetailEntity.plan.number_of_invitations;
        }
        if (this.questionDetailEntity.expire_title == 'Expired:' || this.questionDetailEntity.question_type_id!='3a536800-1a9b-11eb-b17b-c85b767b9463') {
          this.postAnswerHide = true;
        }
        this.videourl =
          this.globals.profileImgeapiUrl +
          '/question/' +
          this.questionDetailEntity.id +
          '/' +
          this.questionDetailEntity.image_video_url;
        if (
          this.globals.authData != undefined &&
          this.globals.authData.is_profile_private == 1
        ) {
          this.postAnswerEntity.send_anonymously =
            this.globals.configurationText.postQuestionSendOption.sendAnonymously;
        }
        var url =
          this.globals.profileImgeapiUrl +
          '/question/' +
          this.questionDetailEntity.id +
          '/' +
          this.questionDetailEntity.image_video_url;
        this.urlMeta = this.globals.baseUrl + this.globals.currentLink;
        this.descriptionMeta = this.questionDetailEntity.question_text;
        this.imageMeta = url;
        this.site_name = 'DecideIt – Your Decision Engine!';
        this.metaService.updateTag(
          { name: 'image', content: this.imageMeta },
          "property='og:image'"
        );
        this.metaService.updateTag(
          { name: 'url', content: this.currentLink },
          "property='og:url'"
        );
        this.metaService.updateTag(
          { name: 'description', content: this.descriptionMeta },
          "property='og:description'"
        );
        this.metaService.updateTag(
          { name: 'site_name', content: this.site_name },
          "property='og:site_name'"
        );
      },
      (error) => {
        this.questionDetailLoading1 = false;
        this.globals.isLoading = false;
        console.log(error);
      }
    );
  }
  ngAfterViewInit() {
    this.getAnswerSendOption();
    setTimeout(() => {
      console.log('hello');
      console.log(this.questionDetailEntity);
      //this.globals.isLoading = true;
      //this.getQuestionComments();
    }, 4000);

    if (!this.route.snapshot.paramMap.get('questionType')) {
      // this.getCommunityDetails();
      this.getRelatedCommunity();
    }

    // this.getMonthlyProctoringData();
  }

  getAnswerSendOption() {
    this.QuestionDetailService.getQuestionDetail(
      this.questionId,
      'answer_send_options',
      ''
    ).then(
      (data) => {
        this.questionDetailLoading3 = false;
        console.log(data);
        this.answerSendOption = data['data'];
      },
      (error) => {
        this.questionDetailLoading3 = false;
        console.log(error);
      }
    );
  }

  getQuestionComments() {
    console.log('hesadh');
    var limit;
    if (!this.route.snapshot.paramMap.get('questionType')) {
      limit =
        this.questionDetailEntity.plan.community_question_number_of_responses;
    } else {
      limit = this.questionDetailEntity.plan.number_of_responses;
    }
    this.QuestionDetailService.getQuestionDetail(
      this.questionId,
      'question_comments',
      limit
    ).then(
      (data) => {
        this.globals.isLoading = false;
        this.questionDetailLoading2 = false;
        console.log(data);
        this.questionCommentsEntity = data['questionComments'];
        for (var i = 0; i < this.questionCommentsEntity.length; i++) {
          if (
            this.globals.authData != undefined &&
            this.questionCommentsEntity[i].user.id == this.globals.authData.id && this.questionDetailEntity.question_type_id!='3a536800-1a9b-11eb-b17b-c85b767b9463'
          ) {
            this.postAnswerHide = false;
          }
          if (
            this.questionDetailEntity.question_type_id ==
            this.globals.configurationText.questionType.expressYourself
          ) {
            this.postEditAnswerEntity.answer_text =
              this.questionCommentsEntity[i].answer_text;
              this.recursiveConfig(this.questionCommentsEntity);
          }
        }
        // if(this.questionCommentsEntity.length == 0)
        // {
        //   this.postAnswerHide = true;
        // }
        if (this.questionCommentsEntity.length > 0) {
          var chartArray = this.questionCommentsEntity[0].chart_data;
          console.log('chartArray', chartArray);
          this.random_theme = Math.floor(Math.random() * 5) + 1;
          // this.random_theme = 1;
          switch (this.random_theme) {
            case 1:
              // am4core.useTheme(am4themes_dataviz);
              this.theme = am4themes_dataviz;
              break;
            case 2:
              // am4core.useTheme(am4themes_material);
              this.theme = am4themes_material;
              break;
            case 3:
              // am4core.useTheme(am4themes_kelly);
              this.theme = am4themes_kelly;
              break;
            case 4:
              // am4core.useTheme(am4themes_frozen);
              this.theme = am4themes_frozen;
              break;
            case 5:
              // am4core.useTheme(am4themes_moonrisekingdom);
              this.theme = am4themes_moonrisekingdom;
              break;
            default:
              this.theme = am4themes_dataviz;
              break;
          }
          // am4core.ready(function () {
          console.log(
            'question_type_id',
            this.questionCommentsEntity[0].question_type_id
          );
          if (
            this.questionCommentsEntity[0].question_type_id ==
              this.globals.configurationText.questionType.pickList ||
            this.questionCommentsEntity[0].question_type_id ==
              this.globals.configurationText.questionType.multiPickList
          ) {
            setTimeout(() => {
              am4core.addLicense('CH236795548');

              am4core.useTheme(this.theme);

              // Create chart instance
              var chart = am4core.create('barchart1', am4charts.XYChart3D);

              // Add data
              chart.data = chartArray;
              console.log('chart.data', chart.data);

              // Create axes
              let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
              categoryAxis.dataFields.category = 'country';
              categoryAxis.renderer.labels.template.rotation = 0;
              categoryAxis.renderer.labels.template.hideOversized = false;
              categoryAxis.renderer.minGridDistance = 20;
              categoryAxis.renderer.labels.template.horizontalCenter = 'middle';
              categoryAxis.renderer.labels.template.verticalCenter = 'middle';
              categoryAxis.tooltip.label.rotation = 0;
              categoryAxis.tooltip.label.horizontalCenter = 'middle';
              categoryAxis.tooltip.label.verticalCenter = 'none';
              categoryAxis.renderer.grid.template.strokeWidth = 0;
              categoryAxis.renderer.labels.template.wrap = true;
              categoryAxis.renderer.labels.template.maxWidth = 120;

              let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
              // valueAxis.title.text = 'Countries';
              valueAxis.title.fontWeight = 'bold';
              valueAxis.renderer.grid.template.strokeWidth = 0;

              // Create series
              // function createSeries(field, name) {
              var series = chart.series.push(new am4charts.ColumnSeries3D());
              series.dataFields.valueY = 'visits';
              series.dataFields.categoryX = 'country';
              series.name = 'Visits';
              series.calculatePercent = true;
              // series.columns.template.tooltipText = "[bold]{name}[/]\n[font-size:14px]{categoryX}: [bold]{valueY.percent}%[/] ({valueY}M)";
              series.tooltipText =
                '{categoryX}: [bold]{valueY.percent}%[/] ({valueY})';
              series.columns.template.fillOpacity = 0.8;

              var columnTemplate = series.columns.template;
              columnTemplate.strokeWidth = 2;
              columnTemplate.strokeOpacity = 1;
              columnTemplate.stroke = am4core.color('#FFFFFF');

              columnTemplate.adapter.add('fill', function (fill, target) {
                return chart.colors.getIndex(target.dataItem.index);
              });

              columnTemplate.adapter.add('stroke', function (stroke, target) {
                return chart.colors.getIndex(target.dataItem.index);
              });
              // }
              chart.cursor = new am4charts.XYCursor();
              chart.cursor.lineX.strokeOpacity = 0;
              chart.cursor.lineY.strokeOpacity = 0;

              // createSeries("europe", "Europe");
              chart.legend = new am4charts.Legend();
            }, 100);
          }
          if (
            this.questionCommentsEntity[0].question_type_id ==
            this.globals.configurationText.questionType.yesOrNo
          ) {
            setTimeout(() => {
              // am4core.ready(function () {

              am4core.addLicense('CH236795548');
              // Create chart instance
              am4core.useTheme(this.theme);
              var chart = am4core.create('piechart', am4charts.PieChart3D);
              chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
              chart.data = this.questionCommentsEntity[0].chart_data;
              // chart.data = [
              //   {
              //     country: 'Yes',
              //     litres: 50,
              //   },
              //   {
              //     country: 'No',
              //     litres: 30,
              //   },
              // ];

              chart.innerRadius = am4core.percent(40);
              chart.depth = 30;
              chart.legend = new am4charts.Legend();
              var series = chart.series.push(new am4charts.PieSeries3D());
              series.dataFields.value = 'litres';
              series.dataFields.depthValue = 'litres';
              series.dataFields.category = 'country';
              series.slices.template.cornerRadius = 5;
              series.colors.step = 3; // end am4core.ready()
              // });
            }, 100);
          }
          if (
            this.questionCommentsEntity[0].question_type_id ==
            this.globals.configurationText.questionType.rankedList
          ) {
            setTimeout(() => {
              am4core.addLicense('CH236795548');

              am4core.useTheme(this.theme);

              // Create chart instance
              var chart = am4core.create(
                'barchart_ranked',
                am4charts.XYChart3D
              );

              // Add data
              // chart.data = [
              //       {
              //         country: 'Option 1',
              //         visits: 98,
              //         rank:1
              //       },
              //       {
              //         country: 'Option 2',
              //         visits: 75,
              //         rank:2
              //       },
              //       {
              //         country: 'Option 3',
              //         visits: 50,
              //         rank:3
              //       },
              //       {
              //         country: 'Option 4',
              //         visits: 35,
              //         rank:4
              //       },
              // {
              //   country: 'Option 5',
              //   visits: 20,
              // },
              // {
              //   country: 'Option 6',
              //   visits: 5,
              // },
              // ];
              chart.data = chartArray;
              console.log('chart.data', chart.data);

              // Create axes
              let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
              categoryAxis.dataFields.category = 'country';
              categoryAxis.renderer.labels.template.rotation = 0;
              categoryAxis.renderer.labels.template.hideOversized = false;
              categoryAxis.renderer.minGridDistance = 20;
              categoryAxis.renderer.labels.template.horizontalCenter = 'middle';
              categoryAxis.renderer.labels.template.verticalCenter = 'middle';
              categoryAxis.tooltip.label.rotation = 0;
              categoryAxis.tooltip.label.horizontalCenter = 'middle';
              categoryAxis.tooltip.label.verticalCenter = 'none';
              categoryAxis.renderer.grid.template.strokeWidth = 0;
              categoryAxis.renderer.labels.template.wrap = true;
              categoryAxis.renderer.labels.template.maxWidth = 120;

              let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
              // valueAxis.title.text = 'Countries';
              valueAxis.title.fontWeight = 'bold';
              valueAxis.renderer.grid.template.strokeWidth = 0;

              // Create series
              var series = chart.series.push(new am4charts.ColumnSeries3D());
              series.dataFields.valueY = 'visits';
              series.dataFields.categoryX = 'country';
              series.dataFields.openValueX = 'rank';
              series.name = 'name1';
              series.calculatePercent = true;
              // series.columns.template.tooltipText = "[bold]{name}[/]\n[font-size:14px]{categoryX}: [bold]{valueY.percent}%[/] ({valueY}M)";
              series.tooltipText =
                'Rank {openValueX}: [bold]{valueY.percent}%[/] (Score: {valueY})';
              // series.tooltipText = '[bold]{valueY.percent}%[/] (Score: {valueY})';
              series.columns.template.fillOpacity = 0.8;

              var columnTemplate = series.columns.template;
              columnTemplate.strokeWidth = 2;
              columnTemplate.strokeOpacity = 1;
              columnTemplate.stroke = am4core.color('#FFFFFF');

              columnTemplate.adapter.add('fill', function (fill, target) {
                return chart.colors.getIndex(target.dataItem.index);
              });

              columnTemplate.adapter.add('stroke', function (stroke, target) {
                return chart.colors.getIndex(target.dataItem.index);
              });

              chart.cursor = new am4charts.XYCursor();
              chart.cursor.lineX.strokeOpacity = 0;
              chart.cursor.lineY.strokeOpacity = 0;
            }, 100);
            // setTimeout(() => {
            //   // Funnel Chart
            //     am4core.addLicense('CH236795548');
            // am4core.useTheme(this.theme);
            //     var chart = am4core.create('funnelchart', am4charts.SlicedChart);
            //     chart.hiddenState.properties.opacity = 0; // this makes initial fade in effect

            //     chart.data = [
            //       {
            //         name: 'The first',
            //         value: 600,
            //       },
            //       {
            //         name: 'The second',
            //         value: 300,
            //       },
            //       {
            //         name: 'The third',
            //         value: 200,
            //       },
            //       {
            //         name: 'The fourth',
            //         value: 180,
            //       },
            //       {
            //         name: 'The fifth',
            //         value: 50,
            //       },
            //       {
            //         name: 'The sixth',
            //         value: 20,
            //       },
            //     ];

            //     var series = chart.series.push(new am4charts.FunnelSeries());
            //     series.colors.step = 2;
            //     series.dataFields.value = 'value';
            //     series.dataFields.category = 'name';
            //     series.alignLabels = true;

            //     series.labelsContainer.paddingLeft = 15;
            //     series.labelsContainer.width = 200;

            //     //series.orientation = "horizontal";
            //     //series.bottomRatio = 1;

            //     chart.legend = new am4charts.Legend();
            //     chart.legend.position = 'left';
            //     chart.legend.valign = 'bottom';
            //     chart.legend.margin(5, 5, 20, 5);
            //   // end am4core.ready()
            // }, 500);
          }
        }
        // }); // end am4core.ready()

        if (
          this.questionCommentsEntity.length > 0 &&
          this.questionCommentsEntity[0].question_type_id ==
            this.globals.configurationText.questionType.rankedList &&
          data['userOptions'].length > 0
        ) {
          this.userAnswerOptions = [];
          this.userAnswerOptions = data['userOptions'];
        }
        let retrievedObject1 = data['userOptions'];
        if (
          this.questionDetailEntity.question_type_id ==
            this.globals.configurationText.questionType.multiPickList ||
          this.questionDetailEntity.question_type_id ==
            this.globals.configurationText.questionType.pickList
        ) {
          for (var i = 0; i < this.questionDetailEntity.options.length; i++) {
            this.questionDetailEntity.options[i].checked = 0;
            for (var j = 0; j < retrievedObject1.length; j++) {
              if (
                retrievedObject1[j].id ==
                this.questionDetailEntity.options[i].id
              ) {
                this.questionDetailEntity.options[i].checked = 1;
                this.postEditAnswerEntity.user_option_id =
                  this.questionDetailEntity.options[i].id;
                this.UserOptions.push(this.questionDetailEntity.options[i].id);
              }
            }
          }

          if (
            this.questionDetailEntity.question_type_id ==
            this.globals.configurationText.questionType.multiPickList
          ) {
            this.postEditAnswerEntity.user_option_id = this.UserOptions;
          }
        }
        console.log(this.questionDetailEntity.options);
        console.log(this.questionCommentsEntity);
      },
      (error) => {
        this.questionDetailLoading2 = false;
        console.log(error);
      }
    );
  }

  editAnswer() {
    $('#editAnswer').toggle('slide');
    console.log(
      'question_type_id',
      this.questionCommentsEntity[0].question_type_id
    );
    if (
      this.questionCommentsEntity[0].question_type_id ==
      this.globals.configurationText.questionType.expressYourself
    ) {
      this.countChar(1);
    }
  }

  imageVideoChange(event) {
    console.log(event);
    const extensionsImage = ['jpg', 'jpeg', 'png', 'JPEG', 'JPG', 'PNG', 'heic', 'HEIC', 'BMP', 'bmp','webp','WEBP'];
    const extensionsVideo = ['mp4', 'MP4', 'MKV', 'mkv', 'WEBM', 'webm', 'avi', 'mov','mp3','guv'];
    this.checkImgSizeKB = this.imageSize * 1024 * 1024;
    this.checkVideoKB = this.videoSize * 1024 * 1024;

    const files = event.target.files;
    const maxLength = this.mydata.length + files.length;

    if ($('#preview').is(':visible')) {
      $('#preview').hide();
      $('#xyz').show();
      // this.mydata = [];
         
    }
    if (files) {
      if (maxLength > 2) {
        Swal.fire({
          type: this.globals.configurationText.sweetAlertTypeIconError,
          icon: this.globals.configurationText.sweetAlertTypeIconError,
          customClass: this.globals.configurationText.sweetAlertClassError,
          toast: true,
          title: 'You are allowed to upload up to 2 media.',
          animation: true,
          position: this.globals.configurationText.sweetAlertPosition,
          showConfirmButton: false,
          timer: this.globals.configurationText.sweetAlertTimer,
          timerProgressBar: true,
          showCancelButton: true,
          cancelButtonText: 'x',
        });
        // this.postQuestionEntity.image_video = this.mydata;
        return;
      }
      for (const file of files) {
        const extension = file.name.split('.').pop();
        const isImage = extensionsImage.includes(extension);
        const isVideo = extensionsVideo.includes(extension);

        // this.mydataToUpload = [];
        const isImageSizeValid = isImage && file.size < this.checkImgSizeKB;
        const isVideoSizeValid = isVideo && file.size < this.checkVideoKB;
        const filename = Date.now() + '_' + file.name.replace(/ /g, '_')
        const fileType = isImage ? 'img' : 'video';
        if (isImageSizeValid || isVideoSizeValid) {
          this.mydataToUpload.push({
            fileUpload:file,
            name :filename,
            type: fileType,
          });
        }
        if (isImage || isVideo) {
          

          const reader = new FileReader();
          reader.onload = (e) => {
            this.mydata.push({
              url: e.target.result,
              type: fileType,
              size: file.size,
              isValid: (fileType == 'img' && file.size < this.checkImgSizeKB) || (fileType == 'video' && file.size < this.checkVideoKB),
              name:filename
            });
            console.log(this.mydata);
            
            // this.fileUploadLength = this.mydata.length;
          };

          reader.readAsDataURL(file);
          console.log(this.mydata)
      }
      else{
        Swal.fire({
          type: this.globals.configurationText.sweetAlertTypeIconError,
          icon: this.globals.configurationText.sweetAlertTypeIconError,
          customClass: this.globals.configurationText.sweetAlertClassError,
          toast: true,
          title:'you can able to upload a file Extension with ' + extensionsImage + extensionsVideo,
          animation: true,
          position: this.globals.configurationText.sweetAlertPosition,
          showConfirmButton: false,
          timer: this.globals.configurationText.sweetAlertTimer,
          timerProgressBar: true,
          showCancelButton: true,
          cancelButtonText: 'x',
        });
        // this.postQuestionEntity.image_video = this.mydata;
      }

      }
    }

  }

  removeImageVideo(idx: number, list:any) {
    this.mydata = this.mydata.filter((u, index) => index !== idx);
    var removeFile =  this.push_Image_video.filter(function(name) {
      return name.name == list;
    });
    this.mydataToUpload = this.mydataToUpload.filter(item => item.name !== list);
    // this.mydataToUpload = this.mydataToUpload.filter((u, index) => index !== idx);
  }
  // uploadImageVideo(){
  //   console.log(this.mydataToUpload)
  //   this.startProgress(1, 2 - 1);
  // }
  async startProgress(file, index) {
    if (file != null) {
      $('.second-progress').addClass('sucess');
    }
  }

  theme(theme: any) {
    throw new Error('Method not implemented.');
  }
  getCommunityDetails() {
    this.QuestionDetailService.getQuestionDetail(
      this.questionId,
      'community_details',
      ''
    ).then(
      (data) => {
        this.questionDetailLoading3 = false;
        console.log(data);
        this.communityDetailEntity = data;
      },
      (error) => {
        this.questionDetailLoading3 = false;
        console.log(error);
      }
    );
  }
  getRelatedCommunity() {
    this.QuestionDetailService.getQuestionDetail(
      this.questionId,
      'related_communities',
      ''
    ).then(
      (data) => {
        this.questionDetailLoading4 = false;
        console.log(data);
        this.relatedCommunityList = data;
      },
      (error) => {
        this.questionDetailLoading4 = false;
        console.log(error);
      }
    );
  }
  communityQuestion(id) {
    window.location.href = '/communityQuestions/' + window.btoa(id);
  }
  sendOption(value) {
    if ($('#Check').is(':checked')) {
      this.postAnswerEntity.send_anonymously = value;
    } else {
      this.postAnswerEntity.send_anonymously = '0';
    }
  }
  sendOptionEdit(value) {
    if ($('#Check1').is(':checked')) {
      this.postEditAnswerEntity.send_anonymously = value;
    } else {
      this.postEditAnswerEntity.send_anonymously = '0';
    }
  }
  UserOptions = [];
  answerSelect(object, i, questionTypeId, value) {
    //debugger;
    this.errorMessage =''
    if (
      questionTypeId ==
      this.globals.configurationText.questionType.multiPickList
    ) {
      if ($('#user_option_id' + i).is(':checked')) {
        this.UserOptions.push(object.id);
        if (value == 0) {
          this.postAnswerEntity.user_option_id = this.UserOptions;
        } else {
          this.postEditAnswerEntity.user_option_id = this.UserOptions;
        }
      } else {
        for (var b = 0; b < this.UserOptions.length; b++) {
          if (this.UserOptions[b] == object.id) {
            this.UserOptions.splice(b, 1);
          }
        }
      }
    }
    else{
      this.UserOptions =[]
      this.UserOptions.push(object.id);
      this.postAnswerEntity.user_option_id = this.UserOptions;
    }
  }
  public items: string[][] = [
    ['Item 1', 'Item 2', 'Item 3', 'Item 4'],
    ['Item 5', 'Item 6', 'Item 7', 'Item 8'],
  ];
  public events: string[][] = [[], []];
  showComments() {
    if ($('#btnshowdiscussion').val() == 'View Response by other users ▼') {
      $('#showdiscussion').show();
      $('#btnshowdiscussion').val('Hide Response by other users ▲');
    } else {
      $('#showdiscussion').hide();
      $('#btnshowdiscussion').val('View Response by other users ▼');
    }
  }

  communityList() {
    window.location.href = '/communityList';
  }

  publicProfile(id, value) {
    window.location.href =
      '/publicProfile/' + window.btoa(id) + '/' + window.btoa(value);
  }
  countChar(value) {
    //debugger
    if(value == 0){
      var val = this.postAnswerEntity.answer_text.replace(/<[^>]+>/g, '').trim();
    }
    else{
      var val = this.postEditAnswerEntity.answer_text.replace(/<[^>]+>/g, '').trim();
    }
    if (val.length === 0) 
    {
      if(value == 0){
       this.postAnswerEntity.answer_text = val;
      }
      else{
        this.postEditAnswerEntity.answer_text = val;
      }
    }
    else
    {
      this.len = val.length;
      console.log(this.len);
      if (this.len > 1000) {
        // val.value = val.substring(0, 255);
        // $('#answer_text').val(val.substring(0, 1000));
        // var answertext = val.substring(0, 1000);
        // console.log(answertext);
        this.maxxxxxxLength = 'You are not allowed to enter more than 1000 characters.'
        if (value == 1) {
          this.postEditAnswerEntity.answer_text = val;
        } else {
          this.postAnswerEntity.answer_text = val;
        }
        console.log(this.postAnswerEntity.answer_text);
      } else {
        this.errorMessage = '';
        this.maxxxxxxLength = '';
        $('#limit').hide();
        $('#charNum').text(1000 - this.len + ' /1000');
      }
    }
  }
  postAnswer() {
    //debugger;
    if (this.globals.authData == undefined) {
      this.cookieService.set(
        'errorMessage',
        'Please login. Answer you have provided will be retained.',
        this.globals.cookieMessageExpireTime,
        '/'
      );
      if (
        this.questionDetailEntity.question_type_id ==
        this.globals.configurationText.questionType.rankedList
      ) {
        this.postAnswerEntity.user_option_id = [];
        for (var i = 0; i < this.questionDetailEntity.options.length; i++) {
          this.postAnswerEntity.user_option_id.push(
            this.questionDetailEntity.options[i].id
          );
        }
      }
      var d1 = new Date(),
        d2 = new Date(d1);
      d2.setMinutes(d1.getMinutes() + 30);
      var entity = {
        is_true: this.postAnswerEntity.is_true
          ? this.postAnswerEntity.is_true
          : '',
        user_option_id: this.postAnswerEntity.user_option_id
          ? this.postAnswerEntity.user_option_id
          : '',
        answer_text: this.postAnswerEntity.answer_text
          ? this.postAnswerEntity.answer_text
          : '',
        send_anonymously: this.postAnswerEntity.send_anonymously
          ? this.postAnswerEntity.send_anonymously
          : '0',
      };
      console.log(entity);
      this.cookieService.set('post_answer', JSON.stringify(entity), d2); // To Set Cookie
      this.cookieService.set('URL', this.globals.currentLink, d2, '/');
      window.location.href = '/login';
    } else {
        this.maxxxxxxLength = '';
        
      // this.globals.isLoading = true;
      console.log(this.questionDetailEntity.options);
      if (
        this.questionDetailEntity.question_type_id ==
        this.globals.configurationText.questionType.rankedList
      ) {
        this.postAnswerEntity.user_option_id = [];
        for (var i = 0; i < this.questionDetailEntity.options.length; i++) {
          this.postAnswerEntity.user_option_id.push(
            this.questionDetailEntity.options[i].id
          );
        }
      }
      this.postAnswerEntity.question_id = this.questionDetailEntity.id;
      this.postAnswerEntity.question_type_id =
        this.questionDetailEntity.question_type_id;
      console.log(this.postAnswerEntity);
      if (
        (this.questionDetailEntity.question_type_id ==
          this.globals.configurationText.questionType.yesOrNo &&
          (this.postAnswerEntity.is_true == '' ||
            this.postAnswerEntity.is_true == undefined)) ||
        ((this.questionDetailEntity.question_type_id ==
          this.globals.configurationText.questionType.pickList ||
          this.questionDetailEntity.question_type_id ==
            this.globals.configurationText.questionType.multiPickList) &&
          (this.postAnswerEntity.user_option_id == '' ||
            this.postAnswerEntity.user_option_id == undefined)) ||
        (this.questionDetailEntity.question_type_id ==
          this.globals.configurationText.questionType.expressYourself &&
          ($.trim(this.postAnswerEntity.answer_text) == '' ||
            this.postAnswerEntity.answer_text == undefined || this.len > 1000))
      ) {
        var text =
          this.questionDetailEntity.question_type_id ==
          this.globals.configurationText.questionType.expressYourself
            ? this.len > 1000 ? 'You are not allowed to enter more than 1000 characters.' : 'Please enter the Response!'
            : 'Please select the Response!';
            this.errorMessage = text
        // Swal.fire({
        //   type: this.globals.configurationText.sweetAlertTypeIconError,
        //   icon: this.globals.configurationText.sweetAlertTypeIconError,
        //   customClass: this.globals.configurationText.sweetAlertClassError,
        //   toast: true,
        //   title: text,
        //   animation: true,
        //   position: this.globals.configurationText.sweetAlertPosition,
        //   showConfirmButton: false,
        //   timer: this.globals.configurationText.sweetAlertTimer,
        //   timerProgressBar: true,
        //   showCancelButton: true,
        //   cancelButtonText: 'x',
        // });
      } else {
        // if(this.len <= 1000){
          this.maxxxxxxLength = '';

          this.btn_disabled = true;
          this.globals.isLoading = true;
          if ($('#Check').is(':checked')) {
            this.postAnswerEntity.send_anonymously = this.answerSendOption.id;
          } else {
            this.postAnswerEntity.send_anonymously = '0';
          }
          this.postAnswerEntity.owner_role_id =
            this.questionDetailEntity.owner_role_id;
          this.postAnswerEntity.owner_user_id =
            this.questionDetailEntity.owner_user_id;
          if ($('#xyz').is(':visible') == true) {
            if ($('#error').is(':visible') == true) {
              this.globals.isLoading = false;
              // Swal.fire({
              //   type: this.globals.configurationText.sweetAlertTypeIconError,
              //   icon: this.globals.configurationText.sweetAlertTypeIconError,
              //   customClass: this.globals.configurationText.sweetAlertClassError,
              //   toast: true,
              //   title: "Kindly upload a valid image.",
              //   animation: true,
              //   position: this.globals.configurationText.sweetAlertPosition,
              //   showConfirmButton: false,
              //   timer: this.globals.configurationText.sweetAlertTimer,
              //   timerProgressBar: true,
              //   showCancelButton: true,
              //   cancelButtonText: 'x',
              // });
            } else {
              console.log(this.mydataToUpload);
              var fd = new FormData();
              if (this.mydataToUpload.length > 0) {
                // alert("API")
                for (let i = 0; i < this.mydataToUpload.length; i++) {
                  var Images = this.mydataToUpload[i].name;
                  //  this.uploadImageData.push(Images)
                  fd.append(
                    'answer_image[]',
                    this.mydataToUpload[i].fileUpload,
                    Images
                  );
                  this.uploadImageData.push({
                    Images: Images,
                    type: this.mydataToUpload[i].type,
                  });
                }
  
                fd.append('is_true', '');
                fd.append('user_option_id', '');
                fd.append('answer_text', this.postAnswerEntity.answer_text);
                fd.append(
                  'answer_imageName',
                  JSON.stringify(this.uploadImageData)
                );
                fd.append('owner_role_id', this.postAnswerEntity.owner_role_id);
                fd.append('owner_user_id', this.postAnswerEntity.owner_user_id);
                fd.append('question_id', this.postAnswerEntity.question_id);
                fd.append(
                  'question_type_id',
                  this.globals.configurationText.questionType.expressYourself
                );
                fd.append(
                  'send_anonymously',
                  this.postAnswerEntity.send_anonymously
                );
                console.log(this.postAnswerEntity);
                this.QuestionDetailService.addAnswer(fd).then(
                  (data) => {
                    this.btn_disabled = false;
                    this.globals.isLoading = false;
                     $('#abc').val('');
                    //answer_text.value = null;
                    // this.postAnswerEntity.answer_text='';
                    //editor.value = null;
                    Swal.fire({
                      type: this.globals.configurationText
                        .sweetAlertTypeIconSuccess,
                      icon: this.globals.configurationText
                        .sweetAlertTypeIconSuccess,
                      customClass:
                        this.globals.configurationText.sweetAlertClassSuccess,
                      toast: true,
                      title: 'Your Response has been posted successfully',
                      animation: true,
                      position: this.globals.configurationText.sweetAlertPosition,
                      showConfirmButton: false,
                      timer: this.globals.configurationText.sweetAlertTimer,
                      timerProgressBar: true,
                      showCancelButton: true,
                      cancelButtonText: 'x',
                    });
                    this.globals.isLoading = false;
                    this.questionDetailEntity.commentsCount =
                      this.questionDetailEntity.commentsCount + 1;
                    this.getQuestionComments();
                    this.cookieService.delete('post_answer');
                  },
                  (error) => {
                    this.globals.isLoading = false;
                    console.log(error);
                  }
                );
              }
            }
          } else {
            var fd = new FormData();
            if (
              this.postAnswerEntity.is_true == undefined ||
              this.postAnswerEntity.is_true == ''
            ) {
              fd.append('is_true', '');
            } else {
              fd.append('is_true', this.postAnswerEntity.is_true);
            }
            if (
              this.postAnswerEntity.user_option_id == '' ||
              this.postAnswerEntity.user_option_id == undefined
            ) {
              fd.append('user_option_id', '');
            } else {
              fd.append('user_option_id', JSON.stringify(this.postAnswerEntity.user_option_id));
            }
            if (
              this.postAnswerEntity.answer_text == undefined ||
              this.postAnswerEntity.answer_text == ''
            ) {
              fd.append('answer_text', '');
            } else {
              fd.append('answer_text', this.postAnswerEntity.answer_text);
            }
            fd.append('answer_image[]', '[]');
            fd.append('answer_imageName', '[]');
            fd.append('owner_role_id', this.postAnswerEntity.owner_role_id);
            fd.append('owner_user_id', this.postAnswerEntity.owner_user_id);
            fd.append('question_id', this.postAnswerEntity.question_id);
            fd.append(
              'question_type_id',
              this.questionDetailEntity.question_type_id
            );
            fd.append('send_anonymously', this.postAnswerEntity.send_anonymously);
  
            this.QuestionDetailService.addAnswer(fd).then(
              (data) => {
                this.btn_disabled = false;
                this.globals.isLoading = false;
                // this.postAnswerEntity.answer_text='';
                 $('#abc').val('');
                Swal.fire({
                  type: this.globals.configurationText.sweetAlertTypeIconSuccess,
                  icon: this.globals.configurationText.sweetAlertTypeIconSuccess,
                  customClass:
                    this.globals.configurationText.sweetAlertClassSuccess,
                  toast: true,
                  title: 'Your Response has been posted successfully',
                  animation: true,
                  position: this.globals.configurationText.sweetAlertPosition,
                  showConfirmButton: false,
                  timer: this.globals.configurationText.sweetAlertTimer,
                  timerProgressBar: true,
                  showCancelButton: true,
                  cancelButtonText: 'x',
                });
                this.globals.isLoading = false;
                this.questionDetailEntity.commentsCount =
                  this.questionDetailEntity.commentsCount + 1;
                this.getQuestionComments();
                this.cookieService.delete('post_answer');
              },
              (error) => {
                this.globals.isLoading = false;
                console.log(error);
              }
            );
          }
        // }
        // else{
        //   this.maxxxxxxLength = 'You are not allowed to enter more than 1000 characters.';
        // }
      }
      //

     
    }
  }
  optionChange(i, options) {
    if ($('#user_option_id' + i).is(':checked')) {
      this.postEditAnswerEntity.user_option_id = options.id;
    }
  }
  updateAnswer(answerid) {
    if (
      this.questionDetailEntity.question_type_id ==
      this.globals.configurationText.questionType.yesOrNo
    ) {
      if ($('#is_true1').is(':checked')) {
        this.postEditAnswerEntity.is_true = 1;
      } else if ($('#is_true2').is(':checked')) {
        this.postEditAnswerEntity.is_true = 0;
      }
    }
    if (
      this.questionDetailEntity.question_type_id ==
      this.globals.configurationText.questionType.rankedList
    ) {
      this.postEditAnswerEntity.user_option_id = [];
      for (var i = 0; i < this.userAnswerOptions.length; i++) {
        this.postEditAnswerEntity.user_option_id.push(
          this.userAnswerOptions[i].id
        );
      }
    }
    this.postEditAnswerEntity.id = answerid;
    this.postEditAnswerEntity.question_id = this.questionDetailEntity.id;
    this.postEditAnswerEntity.question_type_id =
      this.questionDetailEntity.question_type_id;
    if ($('#Check1').is(':checked')) {
      this.postEditAnswerEntity.send_anonymously = this.answerSendOption.id;
    } else {
      this.postEditAnswerEntity.send_anonymously = '0';
    }
    console.log(this.postEditAnswerEntity);
    if (
      this.questionDetailEntity.question_type_id ==
        this.globals.configurationText.questionType.expressYourself &&
      ($.trim(this.postEditAnswerEntity.answer_text) == '' ||
        this.postEditAnswerEntity.answer_text == undefined)
    ) {
      Swal.fire({
        type: this.globals.configurationText.sweetAlertTypeIconError,
        icon: this.globals.configurationText.sweetAlertTypeIconError,
        customClass: this.globals.configurationText.sweetAlertClassError,
        toast: true,
        title: 'Please enter the answer!',
        animation: true,
        position: this.globals.configurationText.sweetAlertPosition,
        showConfirmButton: false,
        timer: this.globals.configurationText.sweetAlertTimer,
        timerProgressBar: true,
        showCancelButton: true,
        cancelButtonText: 'x',
      });
    } else {
      this.btn_disabled = true;
      this.globals.isLoading = true;
      this.QuestionDetailService.editAnswer(this.postEditAnswerEntity).then(
        (data) => {
          this.btn_disabled = false;
          this.globals.isLoading = false;
          Swal.fire({
            type: this.globals.configurationText.sweetAlertTypeIconSuccess,
            icon: this.globals.configurationText.sweetAlertTypeIconSuccess,
            customClass: this.globals.configurationText.sweetAlertClassSuccess,
            toast: true,
            title: 'Your answer has been updated successfully',
            animation: true,
            position: this.globals.configurationText.sweetAlertPosition,
            showConfirmButton: false,
            timer: this.globals.configurationText.sweetAlertTimer,
            timerProgressBar: true,
            showCancelButton: true,
            cancelButtonText: 'x',
          });
          this.globals.isLoading = false;
          this.btn_disabled = false;
          this.UserOptions = [];
          this.getQuestionComments();
        },
        (error) => {
          this.globals.isLoading = false;
          console.log(error);
        }
      );
    }
  }

  likeDislikeQuestionAnswer(question, is_like) {
    var id = question.id

    $('li.like_question a#thumb' + id).addClass('disabled_btn').parents('.padding_boxs').addClass('disabled_post');
    this.globals.likeDislikeQuestionAnswer(question.id, is_like, '').then(
      (data) => {
        console.log(data);
        if (is_like == 1) {
          question.likesCount = question.likesCount + 1;
          question.is_like = 1;
          $('li.like_question a#thumb' + id).parents('.padding_boxs').removeClass('disabled_post');
        } else {
          question.likesCount = question.likesCount - 1;
          question.is_like = null;
          $('li.like_question a#thumb' + id).parents('.padding_boxs').removeClass('disabled_post');
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  likeDislikeAnswer(comment, is_like) {
    var id = comment.id

    $('#thumb' + id).addClass('disabled_btn').parents('.padding_boxs').addClass('disabled_post');
    
    this.globals
      .likeDislikeQuestionAnswer(comment.id, is_like, comment.id)
      .then(
        (data) => {
          console.log(data);
          if (is_like == 1) {
            comment.likesCount = comment.likesCount + 1;
            comment.is_like = 1;
            $('#thumb' + id).parents('.padding_boxs').removeClass('disabled_post');
          } else {
            comment.likesCount = comment.likesCount - 1;
            comment.is_like = null;
            $('#thumb' + id).parents('.padding_boxs').removeClass('disabled_post');
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  openInvitePopUp() {
    this.invitePopUp = true;
    this.invitesubmitted = false;
    this.errorEntity.InvitationError = '';
    this.contactDetailsValidation = false;
    $('#contact_details').removeClass('error');
    if (this.inviteOthersEntity.contact_details == '') {
      this.inviteOthersEntity = {};
    }
  }
  closeInvitePopUp() {
    this.invitePopUp = false;
  }
  unique_contact = [];
  invited_unique_contact = [];
  checkvalidation() {
    //debugger
    var contact_details: any;
    if (this.globals.authData != undefined) {
      if (
        this.inviteOthersEntity.contact_details != undefined &&
        this.inviteOthersEntity.contact_details != ''
      ) {
        contact_details = this.inviteOthersEntity.contact_details.filter(
          function (v) {
            return v !== '';
          }
        );
        var count = 0;
        for (var i = 0; i < contact_details.length; i++) {
          var emailformat =
            /^([a-zA-Z0-9_.-]{1,})+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z]{2,3})$/;
          var phoneformat = /^([0-9()_ ]{10,14})+$/;
          if (contact_details[i] != '') {
            if (
              !contact_details[i].match(emailformat) &&
              !contact_details[i].match(phoneformat)
            ) {
              count++;
            }
          }
        }
        if (this.questionDetailEntity.invitees_temp.length > 0) {
          var invitedContact = [];
          for (
            var j = 0;
            j < this.questionDetailEntity.invitees_temp.length;
            j++
          ) {
            if (
              this.questionDetailEntity.invitees_temp[j].address
                .contact_email != null &&
              this.questionDetailEntity.invitees_temp[j].address
                .contact_email != ''
            ) {
              invitedContact.push(
                this.questionDetailEntity.invitees_temp[j].address.contact_email
              );
            } else {
              invitedContact.push(
                this.questionDetailEntity.invitees_temp[j].address.contact_phone
              );
            }
          }
          console.log(invitedContact);
          for (var a = 0; a < contact_details.length; a++) {
            if ($.inArray(contact_details[a], invitedContact) != -1) {
              this.invited_unique_contact.push(contact_details[a]);
            } else {
              this.invited_unique_contact = [];
            }
          }
          if (this.invited_unique_contact.length != 0) {
            Swal.fire({
              type: this.globals.configurationText.sweetAlertTypeIconError,
              icon: this.globals.configurationText.sweetAlertTypeIconError,
              customClass: this.globals.configurationText.sweetAlertClassError,
              toast: true,
              title:
                'You have already invited some of these people. Please remove them from list.',
              animation: true,
              position: this.globals.configurationText.sweetAlertPosition,
              showConfirmButton: false,
              timer: this.globals.configurationText.sweetAlertTimer,
              timerProgressBar: true,
              showCancelButton: true,
              cancelButtonText: 'x',
            });
          }
          console.log(this.invited_unique_contact);
        }
        this.unique_contact = contact_details.filter(function (item, pos) {
          return contact_details.indexOf(item) != pos;
        });
        console.log(this.unique_contact);
        if (
          this.unique_contact.length != 0 &&
          this.invited_unique_contact.length == 0
        ) {
          Swal.fire({
            type: this.globals.configurationText.sweetAlertTypeIconError,
            icon: this.globals.configurationText.sweetAlertTypeIconError,
            customClass: this.globals.configurationText.sweetAlertClassError,
            toast: true,
            title:
              'You have one or more duplicate email or phone numbers in invitations. Please remove duplicate email or phone.',
            animation: true,
            position: this.globals.configurationText.sweetAlertPosition,
            showConfirmButton: false,
            timer: this.globals.configurationText.sweetAlertTimer,
            timerProgressBar: true,
            showCancelButton: true,
            cancelButtonText: 'x',
          });
        }
        if (
          (count > 0 &&
            (this.errorEntity.InvitationError == '' ||
              this.errorEntity.InvitationError == undefined)) ||
          contact_details.length == 0
        ) {
          this.contactDetailsValidation = true;
          $('#contact_details').addClass('error');
        } else {
          this.contactDetailsValidation = false;
          if (
            this.errorEntity.InvitationError == '' ||
            this.errorEntity.InvitationError == undefined
          )
            $('#contact_details').removeClass('error');
        }
      } else {
        this.contactDetailsValidation = false;
      }
    }

    //^[0][1-9]\d{9}$|^[1-9]\d{9}$
    // var emailformat = '[a-zA-Z0-9.-_]{1,1}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,3}';
    // var emailformat = '[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}';
  }
  checkNumberofInvitations() {
    console.log(JSON.stringify(this.inviteOthersEntity.contact_details));
    if (
      this.inviteOthersEntity.contact_details != undefined &&
      this.inviteOthersEntity.contact_details != '' &&
      $.type(this.inviteOthersEntity.contact_details) != 'array'
    ) {
      this.inviteOthersEntity.contact_details =
        this.inviteOthersEntity.contact_details.split(',');
    }
    if (
      this.inviteOthersEntity.contact_details != undefined &&
      this.inviteOthersEntity.contact_details != ''
    ) {
      var newArray = this.inviteOthersEntity.contact_details.filter(function (
        v
      ) {
        return v !== '';
      });
      console.log(newArray);
    }
    if (this.globals.authData != undefined) {
      var number_of_invitations;
      if (
        this.inviteOthersEntity.contact_details != undefined &&
        this.inviteOthersEntity.contact_details != ''
      ) {
        if (this.questionDetailEntity.community.length == 0) {
          //for private
          if (this.questionDetailEntity.invitees_temp.length > 0) {
            number_of_invitations = Math.max(
              0,
              this.questionDetailEntity.plan.number_of_invitations -
                this.questionDetailEntity.invitees_temp.length
            );
          } else {
            number_of_invitations =
              this.questionDetailEntity.plan.number_of_invitations;
          }
          if (newArray.length > number_of_invitations) {
            this.errorEntity.InvitationError =
              this.globals.commonTranslationText.home.form.contactDetail
                .privateQuestionInvitationErrorMessage + number_of_invitations;
            $('#contact_details').addClass('error');
            this.contactDetailsValidation = false;
          } else {
            this.errorEntity.InvitationError = '';
            if (
              this.inviteOthersEntity.contact_details != undefined &&
              this.inviteOthersEntity.contact_details != '' &&
              this.contactDetailsValidation == false
            ) {
              $('#contact_details').removeClass('error');
            }
          }
        } //for community
        else {
          if (this.questionDetailEntity.invitees_temp.length > 0) {
            number_of_invitations = Math.max(
              0,
              this.questionDetailEntity.plan
                .community_question_number_of_invitations -
                this.questionDetailEntity.invitees_temp.length
            );
          } else {
            number_of_invitations =
              this.questionDetailEntity.plan
                .community_question_number_of_invitations;
          }
          if (newArray.length > number_of_invitations) {
            this.errorEntity.InvitationError =
              this.globals.commonTranslationText.home.form.contactDetail
                .communityQuestionInvitationErrorMessage +
              number_of_invitations;
            $('#contact_details').addClass('error');
            this.contactDetailsValidation = false;
          } else {
            this.errorEntity.InvitationError = '';
            if (
              this.inviteOthersEntity.contact_details != undefined &&
              this.inviteOthersEntity.contact_details != '' &&
              this.contactDetailsValidation == false
            ) {
              $('#contact_details').removeClass('error');
            }
          }
        }
        console.log(this.inviteOthersEntity.contact_details.length);
      } else {
        this.errorEntity.InvitationError = '';
        this.contactDetailsValidation = false;
        if (this.inviteOthersEntity.questionType == 1)
          $('#contact_details').removeClass('error');
      }
    }
  }
  inviteOthers(inviteForm) {
    this.invitesubmitted = true;
    this.checkNumberofInvitations();
    this.checkvalidation();
    if (
      inviteForm.valid &&
      (this.errorEntity.InvitationError == '' ||
        this.errorEntity.InvitationError == undefined) &&
      this.contactDetailsValidation == false &&
      this.unique_contact.length == 0 &&
      this.invited_unique_contact.length == 0
    ) {
      var fd = new FormData();
      //   {
      //     "contact_details": ["testdemo@demo.com"],
      //     "question_status_id" : "07e20fda-233a-11eb-b17b-c85b767b9463",
      //     "question_text" : "private question by jaxi chawda 28-04-2021",
      //     "is_private" :1,
      //     "question_id":"934d10fe-d6d2-495c-9694-ef9cf9e86fa3",
      //     "owner_id":"933f8858-ae05-4f7a-a3ef-e9325cd646b2",
      //     "name":"Nisha Parmar"
      // }
      var phoneformat = /^([0-9()_ ]{10,14})+$/;
      for (var a = 0; a < this.inviteOthersEntity.contact_details.length; a++) {
        if (this.inviteOthersEntity.contact_details[a].match(phoneformat)) {
          this.inviteOthersEntity.contact_details[a] =
            this.inviteOthersEntity.contact_details[a].replace(/\D/g, '');
        }
      }
      fd.append(
        'contact_details',
        JSON.stringify(
          this.inviteOthersEntity.contact_details.filter(function (v) {
            return v !== '';
          })
        )
      );
      fd.append(
        'question_status_id',
        this.globals.configurationText.questionStatus.open
      );
      fd.append('question_text', this.questionDetailEntity.question_text);
      fd.append(
        'is_private',
        this.questionDetailEntity.community.length > 0 ? '0' : '1'
      );
      fd.append('question_id', this.questionDetailEntity.id);
      fd.append('owner_id', this.questionDetailEntity.owner_user_id);
      fd.append('name', this.questionDetailEntity.display_name);
      this.globals.isLoading = true;
      this.QuestionDetailService.inviteOthers(fd).then(
        (data) => {
          console.log(data);
          this.globals.isLoading = false;
          Swal.fire({
            type: this.globals.configurationText.sweetAlertTypeIconSuccess,
            icon: this.globals.configurationText.sweetAlertTypeIconSuccess,
            customClass: this.globals.configurationText.sweetAlertClassSuccess,
            toast: true,
            title: 'Invitations sent successfully',
            animation: true,
            position: this.globals.configurationText.sweetAlertPosition,
            showConfirmButton: false,
            timer: this.globals.configurationText.sweetAlertTimer,
            timerProgressBar: true,
            showCancelButton: true,
            cancelButtonText: 'x',
          });
          this.inviteOthersEntity = {};
          this.invitePopUp = false;
          this.getQuestionDetails();
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  isActiveChange(changeEntity, i) {
    var text = '';
    if (i) {
      changeEntity.is_active = 1;
      text = this.globals.adminTranslationText.AnswerDetail.list.alerts
      .confirmationResponseStatus.activeText
    } else {
      changeEntity.is_active = 0;
      text = this.globals.adminTranslationText.AnswerDetail.list.alerts
      .confirmationResponseStatus.deactiveText
    }
    changeEntity.id = changeEntity.id;

    Swal.fire({
      title: this.globals.adminTranslationText.AnswerDetail.list.alerts
        .confirmationResponseStatus.title,
      text: text,
      icon: 'warning',
      type: this.globals.adminTranslationText.AnswerDetail.list.alerts
        .confirmationResponseStatus.type,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonClass: 'theme_btn',
      confirmButtonText: 'Yes',
      cancelButtonClass: 'themeoption_btn',
      cancelButtonText: 'No',
    }).then((result) =>{
      if(result.value){
        this.globals.isLoading = true;
        this.AdminDashboardService.activeInactiveAnswer(changeEntity)
      .then((data) => {
        this.globals.isLoading = false;
        if (changeEntity.is_active == 0) {
          Swal.fire({
            icon: this.globals.adminTranslationText.AnswerDetail.list.alerts.deactiveSuccess.type,
            type: this.globals.adminTranslationText.AnswerDetail.list.alerts.deactiveSuccess.type,
            title: this.globals.adminTranslationText.AnswerDetail.list.alerts.deactiveSuccess.title,
            text: this.globals.adminTranslationText.AnswerDetail.list.alerts.deactiveSuccess.text,
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonClass: 'theme_btn',
            confirmButtonText: 'Ok',
          })
        }
        else {
          Swal.fire({
            //position: 'top-end',
            icon: this.globals.adminTranslationText.AnswerDetail.list.alerts.activeSuccess.type,
            type: this.globals.adminTranslationText.AnswerDetail.list.alerts.activeSuccess.type,
            title: this.globals.adminTranslationText.AnswerDetail.list.alerts.activeSuccess.title,
            text: this.globals.adminTranslationText.AnswerDetail.list.alerts.activeSuccess.text,
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonClass: 'theme_btn',
            confirmButtonText: 'Ok',
          })
        }
      },
        (error) => {
          this.globals.isLoading = false;
          this.globals.pageNotfound(error.error.code);
        });
      }
      else{
        if(i == false){
          changeEntity.is_active = true
        }
        else{
          changeEntity.is_active = false
        }
      }

    })
    
  }
  reportedResponseListDialog = false;
  AnswerObj;
  reportedanswersList;
  pageSize = this.globals.pageSize;;
  paginationEntity = {
    limit: this.pageSize,
    offset: 1,
    flag: '',
    id: '',
    searchData: {
      status: '',
      searchQuery: ''
    },
    sortOrder: [{
      field: "id",
      dir: 'asc'
    }]
  };
  sort: SortDescriptor[] = [{
    field: 'id',
    dir: 'asc'
  }];
  skip = 0;
  allowUnsort = true;
  state = {
    skip: 0,
    take: this.pageSize
  };
  AnswerEntity;
  ReportAnswer(obj) {
    this.AnswerObj = obj;
    if(obj.reportedResponseCount > 0)
    {
      this.globals.isLoading = true;
      this.reportedResponseListDialog = true;
      this.reportedanswersList = [];
      this.paginationEntity.flag = 'Reportanswer_detailList';
      this.paginationEntity.id = obj.id;
      this.AdminDashboardService.getAnswerDetail(this.paginationEntity)
        .then((data: any) => {
          this.reportedanswersList = {
            data: (data.count > 0) ? orderBy(data.data, this.sort) : '',
            total: data.count,
          }
          this.globals.isLoading = false;
        },
        (error) => {
          this.globals.isLoading = false;
          window.location.href = 'pagenotfound/' + window.btoa(error.error.code);
          this.globals.isLoading = true;
        });
    }
    else{
      Swal.fire({
        type: this.globals.configurationText.sweetAlertTypeIconError,
        icon: this.globals.configurationText.sweetAlertTypeIconError,
        customClass: this.globals.configurationText.sweetAlertClassError,
        toast: true,
        title: 'There is no reported response yet!',
        animation: true,
        position: this.globals.configurationText.sweetAlertPosition,
        showConfirmButton: false,
        timer: this.globals.configurationText.sweetAlertTimer,
        timerProgressBar: true,
        showCancelButton: true,
        cancelButtonText: 'x',
      });
    }
  }

  public close(status) {
    console.log(`Dialog result: ${status}`);
    this.reportedResponseListDialog = false;
  }

  getAnswersreported() {
    this.globals.isLoading = true;
    // this.answersList = [];
    let id = this.route.snapshot.paramMap.get('id');
    this.paginationEntity.flag = 'Reportanswer_List';
    this.paginationEntity.id = id;
    this.AdminDashboardService.getAnswerDetail(this.paginationEntity)
      .then((data: any) => {
        this.questionCommentsEntity = data.data
        // this.answersList = data;
        // this.answersList = {
        //   data: (data.count > 0) ? orderBy(data.data, this.sort) : '',
        //   total: data.count,
        // }
        this.globals.isLoading = false;
      },
        (error) => {
          this.globals.isLoading = false;
          window.location.href = 'pagenotfound/' + window.btoa(error.error.code);
          this.globals.isLoading = true;
        });
  }
  CheckChange() {
    this.globals.isLoading = true;
    if ($('#Check').is(":checked")) {
      this.AnswerEntity.Check = true;
      sessionStorage.setItem('get_reported_response' ,'checked')
      this.getAnswersreported()
    } else {
      sessionStorage.setItem('get_reported_response' ,'')
      this.AnswerEntity.Check = false;
      this.getQuestionComments();
    }
  }

  public pageChange(event: PageChangeEvent): void {
    this.globals.isLoading = true;
    this.skip = event.skip;
    this.paginationEntity.offset = (event.skip / this.pageSize) + 1;
    this.globals.pageScroll();
    if (this.reportedResponseListDialog) {
      this.ReportAnswer(this.AnswerObj);
    }
    //  else {
    //   if (this.AnswerEntity.Check == true) {
    //     this.getAnswersreported()
    //   } else {
    //     this.getAnswers();
    //   }
    // }

  }

  // sortOrder change Event
  public sortChange(sort: any): void {
    if (sort.dir != "undefined") {
      this.sort = sort;
      this.paginationEntity.sortOrder = [];
      this.paginationEntity.sortOrder = sort;
      if (this.reportedResponseListDialog) {
        this.ReportAnswer(this.AnswerObj);
      } 
      // else {
      //   if (this.AnswerEntity.Check == true) {
      //     this.getAnswersreported()
      //   } else {
      //     this.getAnswers();
      //   }
      // }
    }
  }

  // Filter event
  public onFilter(inputValue: string): void {
    this.globals.isLoading = true;
    if (inputValue != "") {
      this.paginationEntity.offset = 0;
      this.paginationEntity.searchData.searchQuery = inputValue;
      if (this.reportedResponseListDialog) {
        this.ReportAnswer(this.AnswerObj);
      } 
      // else {
      //   if (this.AnswerEntity.Check == true) {
      //     this.getAnswersreported()
      //   } else {
      //     this.getAnswers();
      //   }
      // }
    } else {
      this.paginationEntity.searchData.searchQuery = '';
      this.pageChange(this.state);
    }
  }
  postEntity;
  submitted;
  btn_disable
  replycount: number = 0;
  openCommentBox(discussionObj,replyForm) {
    this.postEntity = {};
    this.postEntity.answer_text = '';
    replyForm.form.markAsPristine();
    // setTimeout(function () {
    //   myInput();
    // }, 100);
    if ($('#write_reply' + discussionObj.id).is(':visible')) {
      var check = true;
    } else {
      var check = false;
    }
    $('#limitReply'+ discussionObj.id).hide();
    $('#charNumReply'+ discussionObj.id).text('1000/1000');
    $('.write_reply').hide(100);
    $('.edit_reply').hide(100);
    $('.content_name .content').show(100);

    if (check) {
      $('#write_reply' + discussionObj.id).hide(100);
    } else {
      $('#write_reply' + discussionObj.id).show(100);
    }

  }

  countCharReply(comment,e)
  {
    var val = this.postEntity.answer_text.trim();
    
    if (val.length === 0 && e.which === 32) 
    {
      this.postEntity.answer_text = val
      e.preventDefault();
    }
    else
    {
      var len = val.length;
      if (len > 1000) {
        // val.value = val.substring(0, 255);
        $('#reply_answer_text'+ comment.id).val(val.substring(0, 1000));
        var answertext = val.substring(0, 1000);      
        this.postEntity.answer_text = answertext;
        console.log(this.postEntity.answer_text);
      } else {
        $('#limitReply'+ comment.id).hide();
        $('#charNumReply'+ comment.id).text(1000 - len + ' /1000');
      }
    }
  }
  adddynamicCommnetReply(replyForm, comment) {
    // $inviteeAnswer = new InviteeAnswer;
    // $inviteeAnswer->parent_id = $request->parent_id;
    // $inviteeAnswer->question_id = $request->question_id;
    // $inviteeAnswer->question_invitee_id = $request->question_invitee_id;
    // $inviteeAnswer->is_true = ($request->is_true?$request->is_true:0);
    // $inviteeAnswer->is_active =  config('constants.Is_active.Active');
    // $inviteeAnswer->created_by = Auth::guard('api')->user()->id;
    // $inviteeAnswer->answer_text =$request->answer_text;
    this.submitted = true;
    this.postEntity.UpdatedBy = this.globals.authData.UserId;
    this.postEntity.UserId = this.globals.authData.UserId;
    this.postEntity.question_id = this.questionDetailEntity.id;
    this.postEntity.question_id = this.questionDetailEntity.id;
    this.postEntity.question_invitee_id = null;
    if (comment != undefined && comment.id != '') {
      this.postEntity.count = 0;
      this.postEntity.parent_id = comment.id;
      this.postEntity.IsFlag = false;
      //this.postEntity.CreatedBy = this.globals.authData.UserId;
    }
    if (replyForm.valid) {
      this.globals.isLoading = true;
      this.QuestionDetailService.addCommentReply(this.postEntity)
        .then((data) => {
          this.replycount = 0;
          this.getQuestionComments();
          this.openCommentBox(comment,replyForm);
          Swal.fire({
            type: this.globals.configurationText.sweetAlertTypeIconSuccess,
            icon: this.globals.configurationText.sweetAlertTypeIconSuccess,
            customClass:
              this.globals.configurationText.sweetAlertClassSuccess,
            toast: true,
            title: 'Your Comments has been added successfully',
            animation: true,
            position: this.globals.configurationText.sweetAlertPosition,
            showConfirmButton: false,
            timer: this.globals.configurationText.sweetAlertTimer,
            timerProgressBar: true,
            showCancelButton: true,
            cancelButtonText: 'x',
          });
          this.globals.isLoading = false;
          // this.postEntity.DiscussionId = data['DiscussionId'];
          // this.postEntity.PostTime = data['PostTime'];
          // this.postEntity.Name = this.UserDetail.Name;
          // this.postEntity.ProfileImage = this.UserDetail.ProfileImage;
          // this.postEntity.Reply = this.postEntity.CommentReply;
          // if (Discussion != undefined) {

          //   this.AllCommon(this.CourseDiscussionList, Discussion, 'Reply', this.postEntity);

            this.btn_disable = false;
            this.submitted = false;
            // this.disabled = false;
            this.postEntity = {};
            replyForm.form.markAsPristine();


            // let index = this.CourseDiscussionList.findIndex(a => a.DiscussionId === Discussion.ParentId);
            // if (this.CourseDiscussionList[index].child == undefined) {
            //   this.CourseDiscussionList[index].child = [];
            // }
            // this.CourseDiscussionList[index].child.unshift(this.postEntity);
            // this.CourseDiscussionList[index].count++;
            // $('#write_reply' + index).hide(100);
          // }
        });
    }
  }
  recursiveConfig(arrayObj) {
    
    for (let i = 0; i < arrayObj.length; ++i) {
      this.replycount++;
      //if (this.replycount >= this.CourseDetailList.ReplyConfig) {
      //arrayObj[i].hidereply = true;
      //} else {
      arrayObj[i].hidecount = this.replycount;
      //}
      if (arrayObj[i].child?.length > 0) {
        for (let k = 0; k < arrayObj[i].child.length; k++) {
          arrayObj[i].child[k].hidecount = this.replycount + 1;
        }
        for (let l = 0; l < arrayObj[i].child.length; l++) {
          if (arrayObj[i].child[l]) {
            this.recursiveConfig(arrayObj[i].child[l]);
          }
        }
      }
      if (arrayObj[i].child) {
        this.recursiveConfig(arrayObj[i].child);
      } else {
        this.replycount = 0;
        // return;
      }
    }
    
  }
  Cancel_replay(i) {
    this.submitted = false;
    $('#content' + i + ' .content').show(100);
    $('#write_reply' + i).hide(100);
  }
  handleChange(){
    // alert("yes")
    this.errorMessage = ''
  }
}