import { Component, OnInit, ElementRef, ViewEncapsulation } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { Globals } from '../globals';
import { ActivatedRoute } from '@angular/router';
import { ProfileService } from '../services/profile.service';
import { HttpClient,HttpHeaders } from "@angular/common/http";
import {
  PanelBarExpandMode,
  PanelBarItemModel,
} from '@progress/kendo-angular-layout';
declare var $: any, PerfectScrollbar, Swal;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ProfileComponent implements OnInit {
  profileEntity;
  userCommunities;
  profileImageEntity;
  communities;
  default_communities;
  btn_disable;
  errorEntity;
  submitted;
  message;
  searchArray;
  passwordEntity;
  passwordSubmit;
  oldnewsame;
  newconfsame;
  profileLoading1;
  profileLoading2;
  profileLoading3;
  profileLoading4;
  post_des_valid;
  decideItStarList;
  decideItStarLoader;
  planDetailEntity;
  subscriptionDetails;
  trancationList;
  username_available;
  phoneValid;

  public expandMode: number = PanelBarExpandMode.Multiple;
  // Adding search to dynamically generated PanelBarItem
  public items = [
    {
      categoryname: 'Sports',
      Community: [
        {
          icon: 'assets/images/top_communities/minton.jpg',
          name: 'Badminton',
        },
        {
          icon: 'assets/images/top_communities/carrom.jpg',
          name: 'Carrom',
        },
        {
          icon: 'assets/images/top_communities/chess.jpg',
          name: 'Chess',
        },
        {
          icon: 'assets/images/top_communities/cricket.jpg',
          name: 'Cricket',
        },
        {
          icon: 'assets/images/top_communities/football.png',
          name: 'Football',
        },
        {
          icon: 'assets/images/top_communities/hockey.png',
          name: 'Hockey',
        },
        {
          icon: 'assets/images/top_communities/tennis.png',
          name: 'Tennis',
        },
      ],
    },
    {
      categoryname: 'Music',
      Community: [
        {
          icon: 'assets/images/top_communities/disco.png',
          name: 'Disco',
        },
        {
          icon: 'assets/images/top_communities/folk.png',
          name: 'Folk',
        },
        {
          icon: 'assets/images/top_communities/hiphop.png',
          name: 'Hip hop',
        },
        {
          icon: 'assets/images/top_communities/jazz.png',
          name: 'Jazz',
        },
      ],
    },
    {
      categoryname: 'Technology',
      Community: [
        {
          icon: 'assets/images/top_communities/electro.jpg',
          name: 'Electronics',
        },
        {
          icon: 'assets/images/top_communities/mechanical.jpg',
          name: 'Mechanical',
        },
      ],
    },
  ];
  search() {
    var name = $('#searchTerm').val();
    var searchFor = $('#searchTerm').val().toLowerCase();
    if (searchFor.length >= 3) {
      this.ProfileService.getProfileData(
        'communities?search=' + searchFor
      ).then(
        (data) => {
          //debugger;
          this.communities = data;
          console.log(this.communities);
        },
        (error) => {
          if (error.status == 422) {
          } else {
            // this.globals.pageNotfound(error.error.code);
          }
          this.globals.isLoading = false;
          this.btn_disable = false;
        }
      );
    } else {
      this.communities = this.default_communities;
    }
    // var results = [];
    // let retrievedObject1, index;
    // this.searchArray.forEach(function (menu, key) {
    //   retrievedObject1 = menu['Community'];
    //   index = menu['Community'].findIndex(
    //     (retrievedObject1) =>
    //       retrievedObject1.name.toLowerCase() === name.toLowerCase()
    //   );
    //   if (index != -1) {
    //     // alert(index);
    //     var a = {
    //       categoryname: menu.categoryname,
    //       Community: [retrievedObject1[index]],
    //     };
    //     results.push(a);
    //   } else {
    //     // alert("k");
    //   }
    //   console.log(results);
    // });
    // if (results.length == 0 && name != '') {
    //   $('#data').hide();
    //   $('#no_data').show();
    // } else if (results.length == 0 && name == '') {
    //   $('#data').show();
    //   $('#no_data').hide();
    //   this.items = this.searchArray;
    // } else {
    //   $('#data').show();
    //   $('#no_data').hide();
    //   this.items = results;
    // }

    console.log(this.items);
  }
  public baseSportsIconUrl =
    'https://demos.telerik.com/kendo-ui/content/shared/icons/sports/';
  public baseIconUrl =
    'https://demos.telerik.com/kendo-ui/content/shared/icons/16/';

  public sportsIconUrl(imageName: string): string {
    return this.baseSportsIconUrl + imageName + '.png';
  }

  public iconUrl(imageName: string): string {
    return this.baseIconUrl + imageName + '.png';
  }
  constructor(
    public globals: Globals,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private elem: ElementRef,private http: HttpClient,
    private ProfileService: ProfileService
  ) { }

  ngOnInit(): void {
    //debugger;
    var flag = window.atob(this.route.snapshot.paramMap.get('flag'));
    // console.log(window.atob(this.route.snapshot.paramMap.get('flag')));
    this.username_available = localStorage.getItem('username_available');
    
    $('#no_data').hide();
    if (this.globals.isUser == '1') {
      $("#profile").addClass('col-md-10 col-sm-10 offset-md-2 main_block');
    }
    // Add New Community Toggle
    // $('.community_toggle_btn').click(function () {
    //   alert()
    //   $('.newely_added_community').toggleClass('active_filter');
    // });

    // // Close filter
    // $('.newely_added_community .fa-xmark').click(function () {
    //   $('.newely_added_community').removeClass('active_filter');
    // });

    this.searchArray = this.items;

    setTimeout(() => {
      $('body').on('click', '#old_password-show', function () {
          $(this).toggleClass('fa-eye-slash fa-eye');
          if ($('#old_password').attr('type') == 'password') {
            $('#old_password').attr('type', 'text');
          } else {
            $('#old_password').attr('type', 'password');
          }
        });
      $('body').on('click', '#password-show', function () {  
          $(this).toggleClass('fa-eye-slash fa-eye');
          if ($('#password').attr('type') == 'password') {
            $('#password').attr('type', 'text');
          } else {
            $('#password').attr('type', 'password');
          }
        });
      $('body').on('click', '#confpassword-show', function () {
          $(this).toggleClass('fa-eye-slash fa-eye');
          if ($('#confpassword').attr('type') == 'password') {
            $('#confpassword').attr('type', 'text');
          } else {
            $('#confpassword').attr('type', 'password');
          }
        });
    }, 1000);
        
        
    this.profileEntity = {};
    this.userCommunities = [];
    this.communities = [];
    this.default_communities = [];
    this.profileImageEntity = {};
    this.errorEntity = {};
    this.profileLoading1 = true;
    this.profileLoading2 = true;
    this.passwordEntity = {};
    this.decideItStarList = [];
    this.decideItStarLoader = true;
    this.planDetailEntity = {};
    this.subscriptionDetails = [];
    this.trancationList = [];
    this.ProfileService.getProfileData('personal_details').then(
      (data) => {
        //debugger;
        this.profileLoading1 = false;
        console.log(data);
        this.profileEntity = data;
        this.profileEntity.about = this.profileEntity.user_profile;
        // daily_digest
        if(this.profileEntity.daily_digest == 0){
          this.profileEntity.daily_digest = false
        }
        else{
          this.profileEntity.daily_digest = true
        }
        // get_email
        if(this.profileEntity.get_email == 0){
          this.profileEntity.get_email = false
        }
        else{
          this.profileEntity.get_email = true
        }
        setTimeout(() => {
          if(this.profileEntity.user_profile != null)
          {
            this.countChar();
          }
        }, 100);
        console.log(this.profileEntity);
        if(flag == 'plan')
        {
          console.log(this.globals.authData)
          let promise = new Promise((resolve, reject) => {
            this.http.get(this.globals.baseAPIUrl + 'user')
              .toPromise()
              .then(
                res => { // Success 
                  localStorage.setItem('authData',JSON.stringify(res));
                  resolve(res);
                },
                msg => { // Error
                  reject(msg);
                }
              );
          });
          setTimeout(() => {
            
            $("#pills-personal-tab").removeClass('active');
            $("#pills-account-tab").addClass('active');
            $("#pills-personal").removeClass('show active');
            $("#pills-account").addClass('show active');
          }, 100);
          
        }
      },
      (error) => {
        if (error.status == 422) {
        } else {
          // this.globals.pageNotfound(error.error.code);
        }
        this.globals.isLoading = false;
        this.btn_disable = false;
      }
    );

    setTimeout(function () {
      $(document).ready(function () {
        new PerfectScrollbar('.community_panel');
      });
    }, 100);

    // $(document).ready(function () {
    //   const body = document.querySelector('body');
    //   body.style.setProperty('--screen-height', $(window).height() + "px");
    // });


  }



  ngAfterViewInit() {
    this.getUserCommunities();
    this.getCommunities();
    this.topDecideItStars();
    this.getUserPlanDetails();
    // this.getMonthlyProctoringData();
  }
  getUserCommunities() {
    this.ProfileService.getProfileData('user_communities').then(
      (data) => {
        //debugger;
        this.profileLoading2 = false;
        console.log(data);
        this.userCommunities = data;
        console.log(this.userCommunities);
      },
      (error) => {
        if (error.status == 422) {
        } else {
          // this.globals.pageNotfound(error.error.code);
        }
        this.globals.isLoading = false;
        this.btn_disable = false;
      }
    );
  }
  getUserPlanDetails() {
    this.ProfileService.getProfileData('plan_details').then(
      (data) => {
        //debugger;
        this.profileLoading2 = false;
        console.log(data);
        this.planDetailEntity = data['user_plans'];
        this.planDetailEntity.question = data['questions'];
        this.planDetailEntity.downgradeDetail = data['plan_downgrade_status'];
        if(this.planDetailEntity.downgradeDetail != null && this.planDetailEntity.downgradeDetail != '')
        {
          localStorage.setItem('plan_downgrade_status',this.planDetailEntity.downgradeDetail.plan.id);
        }
        else
        {
          localStorage.setItem('plan_downgrade_status','');
        }
        this.subscriptionDetails = [];
        // if(this.planDetailEntity.subscription_history[0])
        this.subscriptionDetails = this.planDetailEntity.subscription_history;
        console.log(this.subscriptionDetails);
        this.trancationList = this.planDetailEntity.transactions;
      },
      (error) => {
        if (error.status == 422) {
        } else {
          // this.globals.pageNotfound(error.error.code);
        }
        this.globals.isLoading = false;
        this.btn_disable = false;
      }
    );
  }
  getCommunities() {
    this.ProfileService.getProfileData('communities').then(
      (data) => {
        //debugger;
        this.globals.isLoading = false;
        console.log(data);
        this.communities = data;
        this.default_communities = data;
        console.log(this.profileEntity);
      },
      (error) => {
        if (error.status == 422) {
        } else {
          // this.globals.pageNotfound(error.error.code);
        }
        this.globals.isLoading = false;
        this.btn_disable = false;
      }
    );
  }

  topDecideItStars() {
    this.ProfileService.getProfileData('decideit_stars').then(
      (data) => {
        console.log(data);
        this.decideItStarList = data['data'];
        this.decideItStarLoader = false;
      },
      (error) => {
        this.decideItStarLoader = false;
        console.log(error);
      }
    );
  }
  confirmPassword() {
    if (this.passwordEntity.old_password == this.passwordEntity.password) {
      this.oldnewsame = true;
      this.newconfsame = false;
    } else {
      this.oldnewsame = false;
      if (
        this.passwordEntity.password_confirmation !=
        this.passwordEntity.password
      ) {
        if (this.passwordEntity.password_confirmation != null) {
          this.newconfsame = true;
        } else {
          this.newconfsame = false;
        }
      } else {
        this.newconfsame = false;
      }
    }
    if (this.passwordEntity.old_password == '') {
      this.oldnewsame = false;
      this.newconfsame = false;
    }
    if (
      this.passwordEntity.password == '' ||
      this.passwordEntity.password_confirmation == ''
    ) {
      this.newconfsame = false;
    }
  }
  replaceImage() {
    //
    var input = this.elem.nativeElement.querySelector('#UsereditImageId');
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      var filename = $('#UsereditImageId').val();
      // this.RegisterEntity.ProfileImage = Date.now() + '_' + filename;
      // console.log('ProfileImage', ProfileImage);
      filename = filename.substring(filename.lastIndexOf('\\') + 1);
      reader.onload = (e: Event) => {
        $('#ProfilePhotoPreview').attr('src', e.target['result']);
        $('#ProfilePhotoPreview').hide();
        $('#ProfilePhotoPreview').fadeIn(500);
        $('.custom-file-label').text(filename);
      };
      reader.readAsDataURL(input.files[0]);
    }
  }
  phoneNumberFormat(value) {
    var phoneformat = /^[0-9()_ ]+$/;
    if(value.match(phoneformat))
    {
      this.phoneValid = false;
    }
    else
    {
      this.phoneValid = true;
    }

    // if (event.code == 'Backspace' && newVal.length <= 6) {
    //   newVal = newVal.substring(0, newVal.length - 1);
    // }

    // if (newVal.length === 0) {
    //   newVal = '';
    // } else if (newVal.length <= 3) {
    //   newVal = newVal.replace(/^(\d{0,3})/, '($1)');
    // } else if (newVal.length <= 6) {
    //   newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
    // } else if (newVal.length <= 10) {
    //   newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    // } else {
    //   newVal = newVal.substring(0, 10);
    //   newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    // }
    // this.profileEntity.phone = newVal;
  }
  profilePictureUpload(value) {
    //debugger;
    if (value == 1) {
      this.profileImageEntity.profile_image = '';
      this.profileImageEntity.profileimage = '';
    }
    if (
      this.profileImageEntity.profile_image != '' &&
      this.profileImageEntity.profile_image != null
    ) {
      var ext = this.profileImageEntity.profile_image.split('.');
      ext = ext[ext.length - 1].toLowerCase();
      var arrayExtensions = ['jpg', 'jpeg', 'png', 'JPEG', 'JPG', 'PNG'];

      if (arrayExtensions.lastIndexOf(ext) == -1) {
        Swal.fire({
          type: this.globals.configurationText.sweetAlertTypeIconError,
          icon: this.globals.configurationText.sweetAlertTypeIconError,
          customClass: this.globals.configurationText.sweetAlertClassError,
          toast: true,
          title: 'you can able to upload a file Extension with ' + arrayExtensions,
          animation: true,
          position: this.globals.configurationText.sweetAlertPosition,
          showConfirmButton: false,
          timer: this.globals.configurationText.sweetAlertTimer,
          timerProgressBar: true,
          showCancelButton: true,
          cancelButtonText: 'x',
        });
        this.profileImageEntity.profile_image = '';
        setTimeout(function () {
          $('#editimage').val('');
          $('#user_img').attr('src', 'assets/images/dummy.png');
          $('#editprofile_img').hide();
        }, 200);
      } else {
        //debugger;
        let file1;
        file1 = this.elem.nativeElement.querySelector('#editimage').files[0];
        if(Math.floor(file1.size/2048000) > this.globals.configurationText.imageFileSize)
        {
          Swal.fire({
            type: this.globals.configurationText.sweetAlertTypeIconError,
            icon: this.globals.configurationText.sweetAlertTypeIconError,
            customClass: this.globals.configurationText.sweetAlertClassError,
            toast: true,
            title: 'Maximum file size is ' + this.globals.configurationText.imageFileSize + ' MB',
            animation: true,
            position: this.globals.configurationText.sweetAlertPosition,
            showConfirmButton: false,
            timer: this.globals.configurationText.sweetAlertTimer,
            timerProgressBar: true,
            showCancelButton: true,
            cancelButtonText: 'x',
          });
          this.profileImageEntity.profile_image = '';
        }
        else
        {
          var count = 0;
          
          var fd = new FormData();
          var total = 0;
          if (
            this.profileImageEntity.profile_image != '' &&
            this.profileImageEntity.profile_image != null
          ) {
            
            // this.profileImageEntity.profile_image_file = file1;
            if (file1 && file1.length != 0) {
              total = file1.length;
              var Images = Date.now() + '_' + file1['name'].replace(/ /g, '_');
              fd.append('profile_image_file', file1, Images);
              this.profileImageEntity.profile_image = Images;
              this.profileImageEntity.profileimage = Images;
              fd.append('profile_image', this.profileImageEntity.profile_image);
            } else {
              fd.append('profile_image_file', '');
              fd.append('profile_image', '');
            }
          }
          fd.append('user_id', this.globals.authData.id);
          this.globals.isLoading = true;
          this.ProfileService.addProfileImage(fd).then(
            (data) => {
              this.globals.isLoading = false;
              // $("#editimage").val('');
              if (value == 0) {
                Swal.fire({
                  type: this.globals.configurationText.sweetAlertTypeIconSuccess,
                  icon: this.globals.configurationText.sweetAlertTypeIconSuccess,
                  customClass: this.globals.configurationText.sweetAlertClassSuccess,
                  toast: true,
                  title: this.globals.commonTranslationText.profilePage.alerts.profileImage.text,
                  animation: true,
                  position: this.globals.configurationText.sweetAlertPosition,
                  showConfirmButton: false,
                  timer: this.globals.configurationText.sweetAlertTimer,
                  timerProgressBar: true,
                  showCancelButton: true,
                  cancelButtonText: 'x',
                });
                this.profileEntity.profile_image_url =
                  'profile-image/' +
                  this.globals.authData.id +
                  '/' +
                  this.profileImageEntity.profile_image;
                  let promise = new Promise((resolve, reject) => {
                    this.http.get(this.globals.baseAPIUrl + 'user')
                      .toPromise()
                      .then(
                        res => { // Success 
                          localStorage.setItem('authData',JSON.stringify(res));
                          resolve(res);
                        },
                        msg => { // Error
                          reject(msg);
                        }
                      );
                  });
              } else {
                Swal.fire({
                  type: this.globals.configurationText.sweetAlertTypeIconSuccess,
                  icon: this.globals.configurationText.sweetAlertTypeIconSuccess,
                  customClass: this.globals.configurationText.sweetAlertClassSuccess,
                  toast: true,
                  title:this.globals.commonTranslationText.profilePage.alerts.deleteProfileImage.text,
                  animation: true,
                  position: this.globals.configurationText.sweetAlertPosition,
                  showConfirmButton: false,
                  timer: this.globals.configurationText.sweetAlertTimer,
                  timerProgressBar: true,
                  showCancelButton: true,
                  cancelButtonText: 'x',
                });
                this.profileEntity.profile_image_url = '';
                let promise = new Promise((resolve, reject) => {
                  this.http.get(this.globals.baseAPIUrl + 'user')
                    .toPromise()
                    .then(
                      res => { // Success 
                        localStorage.setItem('authData',JSON.stringify(res));
                        resolve(res);
                      },
                      msg => { // Error
                        reject(msg);
                      }
                    );
                });
              }
            },
            (error) => {
              this.globals.isLoading = false;
              // this.globals.pageNotfound(error.error.code);
            }
          );
        }
      }
    } else if(this.profileImageEntity.profileimage =='') {
      var fd = new FormData();
      fd.append('user_id', this.globals.authData.id);
      fd.append('profile_image', '');
      fd.append('profile_image_file', '');
      this.globals.isLoading = true;
      this.ProfileService.addProfileImage(fd).then(
        (data) => {
          this.globals.isLoading = false;
          Swal.fire({
            type: this.globals.configurationText.sweetAlertTypeIconSuccess,
            icon: this.globals.configurationText.sweetAlertTypeIconSuccess,
            customClass: this.globals.configurationText.sweetAlertClassSuccess,
            toast: true,
            title:this.globals.commonTranslationText.profilePage.alerts.deleteProfileImage.text,
            animation: true,
            position: this.globals.configurationText.sweetAlertPosition,
            showConfirmButton: false,
            timer: this.globals.configurationText.sweetAlertTimer,
            timerProgressBar: true,
            showCancelButton: true,
            cancelButtonText: 'x',
          });
          this.profileEntity.profile_image_url = '';
          let promise = new Promise((resolve, reject) => {
            this.http.get(this.globals.baseAPIUrl + 'user')
              .toPromise()
              .then(
                res => { // Success 
                  localStorage.setItem('authData',JSON.stringify(res));
                  resolve(res);
                },
                msg => { // Error
                  reject(msg);
                }
              );
          });
        },
        (error) => {
          this.globals.isLoading = false;
          // this.globals.pageNotfound(error.error.code);
        }
      );
    }
    
  }
  onValueChange(event){
    console.log(event)
  }
  editProfile(profileForm) {
    //debugger;
    if (profileForm.valid) {
      this.btn_disable = true;
      this.globals.isLoading = true;
      var entity = {
        user_id: this.globals.authData.id,
        first_name: this.profileEntity.first_name,
        last_name: this.profileEntity.last_name,
        
        user_name: this.username_available == "0" ? this.profileEntity.user_name : null,
        email: this.profileEntity.email,
        phone: (this.profileEntity.mobile ? this.profileEntity.mobile.replace(/\D/g, '') : null),
        user_profile: this.profileEntity.user_profile,
        // daily_digest:daily_digest,
        // get_email:get_email
      };
      console.log(entity);
      this.ProfileService.editprofile(entity).then(
        (data) => {
          this.globals.isLoading = false;
          this.btn_disable = false;
          this.submitted = false;
          if(this.profileEntity.user_name != '' && this.profileEntity.user_name != undefined && this.username_available == "0")
          {
            localStorage.setItem('username_available',"1");
            this.username_available = 1;
          }
          // var message = data[]
          if (
            data['data']['email_update'] == true &&
            data['data']['mobile_update'] == true
          ) {
            this.message =
              'Your Personal details were updated successfully. Kindly check your email and phone for email and phone verification.';
          } else if (data['data']['email_update'] == true) {
            this.message =
              'Your Personal details were updated successfully. Kindly check your email for email verification.';
          } else if (data['data']['mobile_update'] == true) {
            this.message =
              'Your Personal details were updated successfully. Kindly check your sms for mobile verification.';
          } else if (data['data'].length == 0) {
            this.message = 'Your Personal details were updated successfully.';
          }

          Swal.fire({
            type: this.globals.configurationText.sweetAlertTypeIconSuccess,
            icon: this.globals.configurationText.sweetAlertTypeIconSuccess,
            title: this.message,
            customClass: this.globals.configurationText.sweetAlertClassSuccess,
            toast: true,
            animation: true,
            position: this.globals.configurationText.sweetAlertPosition,
            showConfirmButton: false,
            timer: this.globals.configurationText.sweetAlertTimer,
            timerProgressBar: true,
            showCancelButton: true,
            cancelButtonText: 'x',
          });
          this.profileEntity.about = this.profileEntity.user_profile;
          let promise = new Promise((resolve, reject) => {
            this.http.get(this.globals.baseAPIUrl + 'user')
              .toPromise()
              .then(
                res => { // Success 
                  localStorage.setItem('authData',JSON.stringify(res));
                  resolve(res);
                },
                msg => { // Error
                  reject(msg);
                }
              );
          });
          this.globals.authData.first_name = this.profileEntity.first_name;
          if (data['data']['mobile_update'] == true) {
            this.router.navigate([
              '/verifyOTP/' +
              window.btoa(this.globals.authData.id) +
              '/' +
              window.btoa(data['data']['phone']),
            ]);
          }
        },
        (error) => {
          this.btn_disable = false;
          this.submitted = false;
          this.globals.isLoading = false;
          if (error.error.code == 422) {
            this.errorEntity.first_name =
              error.error.message.first_name[0] != ''
                ? error.error.message.first_name[0]
                : '';
            this.errorEntity.last_name =
              error.error.message.last_name[0] != ''
                ? error.error.message.last_name[0]
                : '';
            this.errorEntity.phone =
              error.error.message.phone[0] != ''
                ? error.error.message.phone[0]
                : '';
          } else if (error.error.code == 401) {
            Swal.fire({
              type: this.globals.configurationText.sweetAlertTypeIconError,
              icon: this.globals.configurationText.sweetAlertTypeIconError,
              customClass: this.globals.configurationText.sweetAlertClassError,
              title: this.globals.commonTranslationText.profilePage.alerts.emailMobileExist.text,
              toast: true,
              animation: true,
              position: this.globals.configurationText.sweetAlertPosition,
              showConfirmButton: false,
              timer: this.globals.configurationText.sweetAlertTimer,
              timerProgressBar: true,
              showCancelButton: true,
              cancelButtonText: 'x',
            });
          } else {
            // this.globals.pageNotfound(error.error.code);
          }
        }
      );
    }
  }
  getNotification(event: any){
    // get_email
    if(event == true){
      var get_email = 1
    }
    else{
      var get_email = 0
    }
    var entity = {
      user_id: this.globals.authData.id,
      get_email:get_email
    };
    Swal.fire({
      title: this.globals.commonTranslationText.profilePage.alerts
        .conformationtabemailtofication.title,
      text: this.globals.commonTranslationText.profilePage.alerts
        .conformationtabemailtofication.text,
      icon: 'warning',
      type: this.globals.commonTranslationText.profilePage.alerts
        .conformationtabemailtofication.type,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonClass: 'theme_btn',
      confirmButtonText: 'Yes',
      cancelButtonClass: 'cancel_btn',
      cancelButtonText: 'No',
    }).then((result) =>{
      if(result.value){
        this.ProfileService.get_email(entity).then(
          (data) => {
            this.globals.isLoading = false;
              this.btn_disable = false;
              this.submitted = false;
              // alert("Update")
              if(event == true){
                Swal.fire({
                  type: this.globals.configurationText.sweetAlertTypeIconSuccess,
                  icon: this.globals.configurationText.sweetAlertTypeIconSuccess,
                  customClass: this.globals.configurationText.sweetAlertClassSuccess,
                  toast: true,
                  title: 'Now, you will receive every email notification.',
                  animation: true,
                  position: this.globals.configurationText.sweetAlertPosition,
                  showConfirmButton: false,
                  timer: this.globals.configurationText.sweetAlertTimer,
                  timerProgressBar: true,
                  showCancelButton: true,
                  cancelButtonText: 'x',
                });
              }
              else{
                Swal.fire({
                  type: this.globals.configurationText.sweetAlertTypeIconSuccess,
                  icon: this.globals.configurationText.sweetAlertTypeIconSuccess,
                  customClass: this.globals.configurationText.sweetAlertClassSuccess,
                  toast: true,
                  title: 'Now, you will not receive any email notifications.',
                  animation: true,
                  position: this.globals.configurationText.sweetAlertPosition,
                  showConfirmButton: false,
                  timer: this.globals.configurationText.sweetAlertTimer,
                  timerProgressBar: true,
                  showCancelButton: true,
                  cancelButtonText: 'x',
                });
              }
          }
        )
      }
      else{
        if(event == false){
          this.profileEntity.get_email = true
        }
        else{
          this.profileEntity.get_email = false
        }
      }
    })
    
  }
  getdailyDigest(event:any){
    // daily_digest
    if(event == true){
      var daily_digest = 1
    }
    else{
      var daily_digest = 0
    }
    var entity = {
      user_id: this.globals.authData.id,
      daily_digest:daily_digest
    };
    Swal.fire({
      title: this.globals.commonTranslationText.profilePage.alerts
        .conformationtabdailydigest.title,
      text: this.globals.commonTranslationText.profilePage.alerts
        .conformationtabdailydigest.text,
      icon: 'warning',
      type: this.globals.commonTranslationText.profilePage.alerts
        .conformationtabdailydigest.type,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonClass: 'theme_btn',
      confirmButtonText: 'Yes',
      cancelButtonClass: 'cancel_btn',
      cancelButtonText: 'No',
    }).then((result) =>{
      if(result.value){
        this.ProfileService.dailydigestStatus(entity).then(
          (data) => {
            this.globals.isLoading = false;
              this.btn_disable = false;
              this.submitted = false;
              // alert("Update")
              if(event == true){
                Swal.fire({
                  type: this.globals.configurationText.sweetAlertTypeIconSuccess,
                  icon: this.globals.configurationText.sweetAlertTypeIconSuccess,
                  customClass: this.globals.configurationText.sweetAlertClassSuccess,
                  toast: true,
                  title: 'You will receive a daily digest email once a day instead of getting individual emails for each thread post.',
                  animation: true,
                  position: this.globals.configurationText.sweetAlertPosition,
                  showConfirmButton: false,
                  timer: this.globals.configurationText.sweetAlertTimer,
                  timerProgressBar: true,
                  showCancelButton: true,
                  cancelButtonText: 'x',
                });
              }
              else{
                Swal.fire({
                  type: this.globals.configurationText.sweetAlertTypeIconSuccess,
                  icon: this.globals.configurationText.sweetAlertTypeIconSuccess,
                  customClass: this.globals.configurationText.sweetAlertClassSuccess,
                  toast: true,
                  title: 'You will get an email for each thread posted by anyone.',
                  animation: true,
                  position: this.globals.configurationText.sweetAlertPosition,
                  showConfirmButton: false,
                  timer: this.globals.configurationText.sweetAlertTimer,
                  timerProgressBar: true,
                  showCancelButton: true,
                  cancelButtonText: 'x',
                });
              }
          }
        )
      }
      else{
        if(event == false){
          this.profileEntity.daily_digest = true
        }
        else{
          this.profileEntity.daily_digest = false
        }
      }

    })
    
  }
  changePassword(changePasswordForm) {
    this.passwordSubmit = true;
    if (changePasswordForm.valid && !this.newconfsame && !this.oldnewsame) {
      this.passwordEntity.UserId = this.globals.authData.UserId;
      this.btn_disable = true;
      this.globals.isLoading = true;
      this.ProfileService.changePassword(this.passwordEntity).then(
        (data) => {
          this.globals.isLoading = false;
          this.btn_disable = false;
          this.passwordSubmit = false;
          this.profileEntity.password = this.passwordEntity.password;
          this.passwordEntity = {};
          console.log(this.profileEntity);
          changePasswordForm.form.markAsPristine();
          changePasswordForm.reset();
          Swal.fire({
            type: this.globals.configurationText.sweetAlertTypeIconSuccess,
            icon: this.globals.configurationText.sweetAlertTypeIconSuccess,
            customClass: this.globals.configurationText.sweetAlertClassSuccess,
            toast: true,
            title: this.globals.commonTranslationText.profilePage.alerts
              .changePasswordSuccess.text,
            animation: true,
            position: this.globals.configurationText.sweetAlertPosition,
            showConfirmButton: false,
            timer: this.globals.configurationText.sweetAlertTimer,
            timerProgressBar: true,
            showCancelButton: true,
            cancelButtonText: 'x',
          });
        },
        (error) => {
          if (error.status == 400) {
            Swal.fire({
              type: this.globals.configurationText.sweetAlertTypeIconError,
            icon: this.globals.configurationText.sweetAlertTypeIconError,
            customClass: this.globals.configurationText.sweetAlertClassError,
            toast: true,
            title: error.error.message,
            animation: true,
            position: this.globals.configurationText.sweetAlertPosition,
            showConfirmButton: false,
            timer: this.globals.configurationText.sweetAlertTimer,
            timerProgressBar: true,
            showCancelButton: true,
            cancelButtonText: 'x',
            });
          } else {
            // this.globals.pageNotfound(error.error.code);
          }
          this.globals.isLoading = false;
          this.btn_disable = false;
          this.passwordSubmit = false;
        }
      );
    }
  }
  addCommunityOpen() {
    // new PerfectScrollbar('.community_panel');
    $('.newely_added_community').toggleClass('active_filter');
  }
  closeCommunity() {
    $('.newely_added_community').removeClass('active_filter');
  }
  addCommunity(community, category) {
    Swal.fire({
      title: this.globals.commonTranslationText.profilePage.alerts
        .addCommunityConfirm.title,
      text: this.globals.commonTranslationText.profilePage.alerts
        .addCommunityConfirm.text,
      icon: 'warning',
      type: this.globals.commonTranslationText.profilePage.alerts
        .addCommunityConfirm.type,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonClass: 'theme_btn',
      confirmButtonText: 'Yes',
      cancelButtonClass: 'cancel_btn',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        // community:
        //   category: {id: "6481a6b2-4130-11eb-bd3a-c85b767b9463", name: "Sports", description: "Sport includes all forms of competitive physical a… and in some cases, entertainment for spectators.", is_active: 1, created_by: null, …}
        //   category_id: "6481a6b2-4130-11eb-bd3a-c85b767b9463"
        //   community_banner_image: "1612260994509_TechnologyCommunity.jpg"
        //   community_thumbnail_image: "1612260994509_minton.jpg"
        //   created_at: "2020-12-16T20:31:05.000000Z"
        //   created_by: null
        //   description: "<p>This is the dummy text.</p>"
        //   id: "4abe0482-49bf-11eb-bd3a-c85b767b9463"
        //   is_active: 1
        //   name: "Badminton"
        //   updated_at: "2021-02-02T20:46:35.000000Z"
        //   updated_by: "719e4f0c-a5c6-47d4-be8e-3d2e1d63c949"
        //   __proto__: Object
        //   community_id: "4abe0482-49bf-11eb-bd3a-c85b767b9463"
        //   created_at: "2021-02-09T13:34:40.000000Z"
        //   created_by: "bc5613d6-a5a8-44ec-97e8-376c3fff8e3a"
        //   id: "92b0bc1e-b4e9-4425-9c38-b264dd11ff2d"
        //   is_active: 1
        //   updated_at: "2021-02-09T13:34:40.000000Z"
        //   updated_by: "bc5613d6-a5a8-44ec-97e8-376c3fff8e3a"
        //   user_id: "bc5613d6-a5a8-44ec-97e8-376c3fff8e3a"

        this.globals.isLoading = true;
        //debugger;
        this.ProfileService.addCommunity(community.id).then(
          (data) => {
            // this.getCategoryData();
            console.log(data);
            this.globals.isLoading = false;
            Swal.fire({
              type: this.globals.configurationText.sweetAlertTypeIconSuccess,
              icon: this.globals.configurationText.sweetAlertTypeIconSuccess,
              customClass: this.globals.configurationText.sweetAlertClassSuccess,
              title: this.globals.commonTranslationText.profilePage.alerts
                .addCommunity.text,
              toast: true,
              animation: true,
              position: this.globals.configurationText.sweetAlertPosition,
              showConfirmButton: false,
              timer: this.globals.configurationText.sweetAlertTimer,
              timerProgressBar: true,
              showCancelButton: true,
              cancelButtonText: 'x',
            });
            //debugger;
            var index = category.communities.indexOf(community);
            if (index != -1) {
              category.communities.splice(index, 1);
            }
            var ob = {
              community: {
                name: community.name,
                id: community.id,
                community_thumbnail_image: community.community_thumbnail_image,
                category: {
                  name: category.name,
                  id: category.id,
                },
              },
              id: data['data'],
            };
            this.userCommunities.push(ob);
            console.log(this.userCommunities);
            console.log(this.communities);
            $('.newely_added_community').removeClass('active_filter');
          },
          (error) => {
            this.globals.isLoading = false;
            // this.globals.pageNotfound(error.error.code);
          }
        );
      }
    });
  }
  removeCommunity(community) {
    Swal.fire({
      title: this.globals.commonTranslationText.profilePage.alerts
        .removeCommunityConfirm.title,
      text: this.globals.commonTranslationText.profilePage.alerts
        .removeCommunityConfirm.text,
      icon: 'warning',
      type: this.globals.commonTranslationText.profilePage.alerts
        .removeCommunityConfirm.type,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonClass: 'theme_btn',
      confirmButtonText: 'Yes',
      cancelButtonClass: 'cancel_btn',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.globals.isLoading = true;
        //debugger;
        this.ProfileService.removeCommunity(community.id).then(
          (data) => {
            // this.getCategoryData();
            this.globals.isLoading = false;
            Swal.fire({
              type: this.globals.configurationText.sweetAlertTypeIconSuccess,
              icon: this.globals.configurationText.sweetAlertTypeIconSuccess,
              customClass: this.globals.configurationText.sweetAlertClassSuccess,
              title: this.globals.commonTranslationText.profilePage.alerts
                .removeCommunity.text,
              toast: true,
              animation: true,
              position: this.globals.configurationText.sweetAlertPosition,
              showConfirmButton: false,
              timer: this.globals.configurationText.sweetAlertTimer,
              timerProgressBar: true,
              showCancelButton: true,
              cancelButtonText: 'x',
            });
            var index = this.userCommunities.indexOf(community);
            if (index != -1) {
              this.userCommunities.splice(index, 1);
            }
            for (var i = 0; i < this.communities.length; i++) {
              if (this.communities[i].id == community.community.category.id) {
                var ob = {
                  name: community.community.name,
                  id: community.community.id,
                  community_thumbnail_image:
                    community.community.community_thumbnail_image,
                };
                this.communities[i].communities.push(ob);
                break;
              }
            }
          },
          (error) => {
            this.globals.isLoading = false;
            this.globals.pageNotfound(error.error.code);
          }
        );
      }
    });
  }
  countChar() {
    //debugger
    var val;
    if(this.profileEntity.user_profile != '')
    {
      val = this.profileEntity.user_profile;
    }
    else
    {
      val = $('#user_profile').val();
    }
    var len = val.length;
    console.log(len);
    if (len > 500) {
      // val.value = val.substring(0, 255);
      $('#user_profile').val(val.substring(0, 500));
    } else {
      $('#limit').hide();
      $('#charNum').text(500 - len + ' /500');
    }
  }
  addressBook() {
    window.location.href = 'addressBook';
  }
  publicProfile(id) {
    window.location.href = '/publicProfile/' + window.btoa(id);
  }
  decideItStars() {
    window.location.href = '/decideItStars/' + window.btoa('star');;
  }

  upgradePlan(plan_id)
  {
    window.location.href = '/upgradePlan/' + plan_id ;
  }

  privacyChange()
  {
    this.globals.isLoading = true;
      this.ProfileService.privacyChange().then(
        (data) => {
          console.log(data);
          this.globals.isLoading = false;
          Swal.fire({
            type: this.globals.configurationText.sweetAlertTypeIconSuccess,
            icon: this.globals.configurationText.sweetAlertTypeIconSuccess,
            customClass: this.globals.configurationText.sweetAlertClassSuccess,
            toast: true,
            title: data['message'],
            animation: true,
            position: this.globals.configurationText.sweetAlertPosition,
            showConfirmButton: false,
            timer: this.globals.configurationText.sweetAlertTimer,
            timerProgressBar: true,
            showCancelButton: true,
            cancelButtonText: 'x',
          });
          let promise = new Promise((resolve, reject) => {
            this.http.get(this.globals.baseAPIUrl + 'user')
              .toPromise()
              .then(
                res => { // Success 
                  localStorage.setItem('authData',JSON.stringify(res));
                  resolve(res);
                },
                msg => { // Error
                  reject(msg);
                }
              );
          });
        },
        (error) => {
          if (error.status == 400) {
            Swal.fire({
              type: this.globals.configurationText.sweetAlertTypeIconError,
            icon: this.globals.configurationText.sweetAlertTypeIconError,
            customClass: this.globals.configurationText.sweetAlertClassError,
            toast: true,
            title: error.error.message,
            animation: true,
            position: this.globals.configurationText.sweetAlertPosition,
            showConfirmButton: false,
            timer: this.globals.configurationText.sweetAlertTimer,
            timerProgressBar: true,
            showCancelButton: true,
            cancelButtonText: 'x',
            });
          } else {
            // this.globals.pageNotfound(error.error.code);
          }
          this.globals.isLoading = false;
          this.btn_disable = false;
          this.passwordSubmit = false;
        }
      );
  }




}
