import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '.././globals';
import { HttpClient,HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private http: HttpClient, private globals: Globals, private router: Router) { }

  getDefaultData(id) {
    //debugger
    let promise = new Promise((resolve, reject) => {
      this.http.get(this.globals.baseAPIUrl + 'question/getDefaultData/'+id)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getDraftQuestionData(id) {
    //debugger
    let promise = new Promise((resolve, reject) => {
      this.http.get(this.globals.baseAPIUrl + 'question/getEditData/'+id)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getRecentCommunities() {
    //debugger
    let promise = new Promise((resolve, reject) => {
      this.http.get(this.globals.baseAPIUrl + 'question/getRecentCommunities')
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getTopQuestion(flag,id) {
    //debugger
    var entity = {
      'community_id' : '',
      'flag' : flag,
      'post_by_admin' : id,
    }
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.globals.baseAPIUrl + 'getHomeData',entity)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }
  postQuestion(entity)
  {
    let headers = new HttpHeaders();
    headers.set('Accept', "multipart/form-data");
    var url;
    console.log(entity.get('question_id'));
    if((entity.get('question_id') == '') || (entity.get('question_id') == null) || (entity.get('question_id')) == undefined)
    {
      url = 'question/add';
    }
    else
    {
      url = 'question/UpdateQuestion'
    }
    //debugger
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.globals.baseAPIUrl + url,entity)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  addGroup(entity)
  {
    //debugger
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.globals.baseAPIUrl + 'question/add-group',entity)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }


  getGlobalSearchData (entity){
    //debugger
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.globals.baseAPIUrl + 'globalSearch',entity)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  fileUpload(entity)
  {
    //debugger
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.globals.baseAPIUrl + 'question/fileUpload',entity)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  checkImageVideoSize(entity)
  {
    //debugger
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.globals.baseAPIUrl + 'ImageAudioUpload',entity)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getImageVideoExtensions()
    {
      let promise = new Promise((resolve, reject) => {
          this.http.get(this.globals.baseAPIUrl + 'getImageVideoExtensions')
            .toPromise()
            .then(
              res => { // Success
                resolve(res);
                // this.imageArrayExtensions = res['data']['ImageExtensions'];
                // this.cookieService.set('successMessage', 'Question has been reported successfully',d2,'/');
              },
              msg => { // Error
                reject(msg);
              }
            );
        });
        return promise;
    }

    // Get Image and Video Size
     getImageVideoSize() {
       //debugger
       let promise = new Promise((resolve, reject) => {
         this.http.get(this.globals.baseAPIUrl + 'getImageVideoExtensions')
           .toPromise()
           .then(
             res => { // Success
               resolve(res);
             },
             msg => { // Error
               reject(msg);
             }
           );
       });
       return promise;
     }

     removeImageVideo(entity){
      let promise = new Promise((resolve, reject) => {
        this.http.post(this.globals.baseAPIUrl + 'removefiles',entity)
          .toPromise()
          .then(
            res => { // Success
              resolve(res);
            },
            msg => { // Error
              reject(msg);
            }
          );
      });
      return promise;
     }
     removeImageVideoDraft(entity){
      let promise = new Promise((resolve, reject) => {
        this.http.post(this.globals.baseAPIUrl + 'removefilesdraft',entity)
          .toPromise()
          .then(
            res => { // Success
              resolve(res);
            },
            msg => { // Error
              reject(msg);
            }
          );
      });
      return promise;
     }
     RemoveImage(entity){
      let promise = new Promise((resolve, reject) => {
        this.http.post(this.globals.baseAPIUrl + 'removefiles',entity)
          .toPromise()
          .then(
            res => { // Success
              resolve(res);
            },
            msg => { // Error
              reject(msg);
            }
          );
      });
      return promise;
     }
}
