import { Component } from '@angular/core';
import { Globals } from './globals';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(private route: ActivatedRoute, public globals: Globals) { }
  title = 'DecideIt_Frontend';
}
