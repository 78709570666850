import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Globals } from './globals';
import {HTTP_INTERCEPTORS}  from '@angular/common/http';
import { HttpInterceptorClassService } from './http-interceptor-class.service';

import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { LoginfacebookComponent } from './loginfacebook/loginfacebook.component';
import { HomeComponent } from './home/home.component';
import { UserDashboardComponent } from './user-dashboard/user-dashboard.component';
import { ProfileComponent } from './profile/profile.component';
import { PostQuestionComponent } from './post-question/post-question.component';
import { OtpVerifyComponent } from './otp-verify/otp-verify.component';
import { AddressbookComponent } from './addressbook/addressbook.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PubilcProfileComponent } from './pubilc-profile/pubilc-profile.component';
import { CommunityQuestionComponent } from './community-question/community-question.component';
import { QuestionDetailComponent } from './question-detail/question-detail.component';
import { TopQuestionsComponent } from './top-questions/top-questions.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { DecideitStarsComponent } from './decideit-stars/decideit-stars.component';
// import { CommunityListComponent } from './community-list/community-list.component';
import { ContactusComponent } from './contactus/contactus.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { GlobalSearchComponent } from './global-search/global-search.component';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';

//services
import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/auth-guard.service';
import { RegisterService } from './services/register.service';
import { UserService } from './services/user.service';
import { ProfileService } from './services/profile.service';
import { HomeService } from './services/home.service';
import { CommunityQuestionService } from './services/community-question.service';
import { QuestionDetailService } from './services/question-detail.service';
import { CommunityListService } from './services/community-list.service';
import { ContactusService } from './services/contactus.service';
import { UserDashboardService } from './services/user-dashboard.service';
import { NotificationService } from './services/notification.service';


const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () => import('./app-admin/app-admin.module').then(m => m.AppAdminModule)
  },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'addressBook', component: AddressbookComponent, canActivate: [AuthGuard], data: {permission: 'address-book'}  },
  { path: 'login', component: LoginComponent, canActivate: [AuthGuard] },
  { path: 'login/:type/:token', component: LoginComponent, canActivate: [AuthGuard] },
  { path: 'login/:type/:token/:email', component: LoginComponent, canActivate: [AuthGuard] },
  { path: 'forgotPassword', component: ForgotPasswordComponent, canActivate: [AuthGuard] },
  { path: 'resetPassword/:type/:token', component: ResetPasswordComponent, canActivate: [AuthGuard]},
  { path: 'userDashboard', component: UserDashboardComponent, canActivate: [AuthGuard], data: {permission: 'user-Dashboard'}  },//, canActivate: [AuthGuard]
  { path: 'userDashboard/:type', component: UserDashboardComponent, canActivate: [AuthGuard], data: {permission: 'user-Dashboard'}  },//, canActivate: [AuthGuard]
  { path: 'profile', component: ProfileComponent , canActivate: [AuthGuard]},
  { path: 'profile/:flag', component: ProfileComponent , canActivate: [AuthGuard]},
  { path: 'postQuestion', component: PostQuestionComponent, canActivate: [AuthGuard], data: {permission: 'post-question'}},
  { path: 'postQuestion/:id', component: PostQuestionComponent, canActivate: [AuthGuard], data: {permission: 'post-question'}},
  { path: 'topQuestion', component: TopQuestionsComponent, canActivate: [AuthGuard], data: {permission: 'top-question'}},
  // { path: 'aboutUs', component: AboutusComponent},
  { path: 'contactUs', component: ContactusComponent},
  { path: 'decideItStars', component: DecideitStarsComponent, canActivate: [AuthGuard], data: {permission: 'decideIt-stars'}},
  { path: 'decideItStars/:flag', component: DecideitStarsComponent, canActivate: [AuthGuard], data: {permission: 'decideIt-stars'}},
  // { path: 'communityList', component: CommunityListComponent, canActivate: [AuthGuard]},
  // { path: 'communityQuestions/:id', component: CommunityQuestionComponent, canActivate: [AuthGuard], data: {permission: 'community-questions'}},
  { path: 'questionDetails/:id/:questionType', component: QuestionDetailComponent, canActivate: [AuthGuard], data: {permission: 'question-details'}},
  { path: 'questionDetails/:id', component: QuestionDetailComponent, canActivate: [AuthGuard], data: {permission: 'question-details'}},
  { path: 'facebookLogin', component: LoginfacebookComponent },
  { path: 'pagenotfound/:code', component: PagenotfoundComponent },
  { path: 'pagenotfound', component: PagenotfoundComponent },
  { path: 'register', component: RegisterComponent, canActivate: [AuthGuard] },
  { path: 'notifications', component: NotificationsComponent, canActivate: [AuthGuard] },
  { path: 'publicProfile/:id', component: PubilcProfileComponent},
  { path: 'publicProfile/:id/:sendAnonymously', component: PubilcProfileComponent},
  { path: 'verifyOTP/:id/:phone', component: OtpVerifyComponent, canActivate: [AuthGuard] },
  { path: 'verifyOTP/:id/:phone/:type', component: OtpVerifyComponent, canActivate: [AuthGuard] },
  { path: 'globalSearch', component: GlobalSearchComponent },
  { path: 'cookiePolicy', component: CookiePolicyComponent },
  { path: 'privacyPolicy', component: PrivacyPolicyComponent },
  { path: 'termsConditions', component: TermsConditionsComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
  providers: [Globals,AuthService,AuthGuard,RegisterService,UserService,ProfileService,HomeService,CommunityQuestionService,QuestionDetailService,CommunityListService,ContactusService,UserDashboardService,NotificationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorClassService,
      multi: true
    }
  ]
})
export class AppRoutingModule { }
