import { Component, OnInit, ViewEncapsulation} from '@angular/core';
declare var $: any, Swal;

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class TermsConditionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    // $(window).on('load resize scroll', function () {
    //   var f = $('footer');
    //   f.css({ position: 'static' });
    //   if (f.offset().top + f.height() < $(window).height()) {
    //     f.css({ position: 'fixed', bottom: '0', width: '100%' });
    //   }
    // });


    // $(document).ready(function () {
    //   const body = document.querySelector('body');
    //   body.style.setProperty('--screen-height', $(window).height() + "px");
    // });


  }

}
