<div class="sidebar_wrap sidebar_scroll">

    <div class="sidebar_toggle"><button></button></div>

    <ul id="accordion" class="sidebar_box">

          <li class="sidebar_box_label"><span>Dashboard</span></li>

        <li id="selected" [ngClass]="{'active':currentLink=='/admin/adminDashboard'}" (click)="closecollapse()"><a
                [ngClass]="{'active':globals.currentLink=='/admin/adminDashboard'}"
                (click)="redirect('/admin/adminDashboard')" data-toggle="tooltip" data-placement="right" title="Admin Dashboard"><i
                    class="fa-solid fa-gauge-high"></i><span class="text">Admin Dashboard</span></a></li>  

        <li class="sidebar_box_label"><span>Manage</span></li>

        <li [ngClass]="{'active':currentLink=='/postQuestion'}"><a (click)="redirect('/postQuestion')" title="New Thread"
            data-toggle="tooltip" data-placement="right"><i class="fa fa-pencil"></i><span
               class="text">New Thread</span></a></li>
        
        <li [ngClass]="{'active':currentLink=='/admin/users/list'}"><a (click)="redirect('/admin/users/list')" title="Users"
        data-toggle="tooltip" data-placement="right"><i class="fa fa-users"></i><span
            class="text">Users</span></a></li>
        
        <!-- <li [ngClass]="{'active':currentLink=='/admin/community/list'}"><a (click)="redirect('/admin/community/list')"
                 data-toggle="tooltip" data-placement="right" title="Topics"><i class="fa fa-bullhorn"></i><span
                    class="text">Topics</span></a></li> -->
        <!-- <li [ngClass]="{'active':currentLink=='/admin/category/list'}"><a (click)="redirect('/admin/category/list')"
                data-toggle="tooltip" data-placement="right" title="Categories"><i class="fa-solid fa-sitemap"></i><span
                    class="text">Categories</span></a></li> -->

      <li [ngClass]="{'active':currentLink=='/admin/manageQuestion'}"><a (click)="redirect('/admin/manageQuestion')"
                data-toggle="tooltip" data-placement="right" title="Threads"><i class="fa-solid fa-rectangle-list"></i><span
                    class="text">Threads</span></a></li>

        <li [ngClass]="{'active':currentLink=='/admin/configuration'}"><a (click)="redirect('/admin/configuration')"
            data-toggle="tooltip" data-placement="right" title="System Settings"><i class="fa fa-cogs"></i><span
                class="text">System Settings</span></a></li>
        <!-- <li [ngClass]="{'active':currentLink=='/admin/configuration'}">
            <div id="heading4">
                <a data-bs-toggle="collapse" class="collapsed" data-bs-target="#collapse4" aria-expanded="true"
                    aria-controls="collapse4" title="Settings">
                    <i class="fa fa-cogs"></i><span class="text">Settings</span><span class="minus_plus"></span>
                </a>
            </div>
            <div id="collapse4" class="collapse" aria-labelledby="heading4" data-parent="#accordion">
                <ul class="sub_menu" id="child1">
                    <li [ngClass]="{'active':currentLink=='/admin/configuration'}" (click)="closecollapse()">
                        <a (click)="redirect('/admin/configuration')" title="System Settings" data-toggle="tooltip"
                            data-placement="right"><i class="fa fa-server"></i><span class="text">System
                                Settings</span></a>
                    </li>
                     <li (click)="closecollapse()">
                        <a title="Manage Advertise" data-toggle="tooltip" data-placement="right"><i class="fa fa-sliders"></i><span
                                class="text">Manage Advertise</span></a>
                    </li>
                    <li (click)="closecollapse()">
                        <a title="Manage Bad Words" data-toggle="tooltip" data-placement="right"><i class="fa fa-sliders"></i><span
                                class="text">Manage Bad Words</span></a>
                    </li> 
                </ul>
            </div>
        </li> -->
    </ul>
</div>