import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '.././globals';
import { HttpClient,HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class QuestionDetailService {

  constructor(private http: HttpClient, private globals: Globals, private router: Router) { }
  getQuestionDetail(id,flag,limit) {//debugger
    //debugger
    var entity = {
      'id' : id,
      'flag' : flag,
      'limit' : limit
    }
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.globals.baseAPIUrl + 'questionDetail',entity)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  addAnswer(entity) {
    //debugger
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.globals.baseAPIUrl + 'questionDetail/addAnswer',entity)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  editAnswer(entity) {
    //debugger
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.globals.baseAPIUrl + 'questionDetail/editAnswer',entity)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  inviteOthers(entity) {
    //debugger
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.globals.baseAPIUrl + 'question/addMoreInvitees',entity)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  addCommentReply(entity) {
    //debugger
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.globals.baseAPIUrl + 'questionDetail/addComments',entity)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }
}
