<div class="home_wrap dashboard_wrap pt_50 fixed-head" id="userdashboard_page">
  <div class="container-fluid">
    <div class="row pb-5">
      <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
        <div class="dashboard_filter_wrap">
          <div class="post_que_header">
            <h2 class="decideit_head float-start mb-0 mt-2">
              <span>Dash</span>board
            </h2>
            <div class="searchbar float-end">
              <form
                role="form"
                #globalSearchForm="ngForm"
                (ngSubmit)="getQuestion(selectedValue)"
              >
                <div class="input-group">
                  <input
                    type="text"
                    #search="ngModel"
                    [(ngModel)]="dashboardSearchEntity.search"
                    name="search"
                    id="search"
                    class="form-control"
                    placeholder="Search in dashboard"
                  />
                  <button type="submit">
                    <i class="fa-solid fa-magnifying-glass"></i>
                  </button>
                </div>
              </form>
            </div>
            <div class="clearfix"></div>
          </div>

          <div class="dashboard_filter_tabs mt-5">
            <ul class="nav nav-pills float-start" id="pills-tab" role="tablist">
              <li class="nav-item" *ngIf="selectedValue == 'My threads'">
                <a
                  class="nav-link active"
                  id="pills-open-tab"
                  data-bs-toggle="pill"
                  href="#pills-open"
                  role="tab"
                  aria-controls="pills-open"
                  aria-selected="true"
                  (click)="tabChange('open')"
                  ><i class="fa-regular fa-eye"></i> Active Threads</a
                >
              </li>
              <li class="nav-item" *ngIf="selectedValue == 'My threads'">
                <a
                  class="nav-link"
                  id="pills-inactive-tab"
                  data-bs-toggle="pill"
                  href="#pills-inactive"
                  role="tab"
                  aria-controls="pills-inactive"
                  aria-selected="false"
                  (click)="tabChange('inactive')"
                  ><i class="fa-solid fa-eye-slash"></i> Inactive Threads</a
                >
              </li>
              <li class="nav-item" *ngIf="selectedValue == 'My threads'">
                <a
                  class="nav-link"
                  id="pills-draft-tab"
                  data-bs-toggle="pill"
                  href="#pills-draft"
                  role="tab"
                  aria-controls="pills-draft"
                  aria-selected="false"
                  (click)="tabChange('draft')"
                  ><i class="fa-pencil fa-sm fa-solid"></i> Draft</a
                >
              </li>
            </ul>
            <div class="dashboard_filter_sorting float-end">
              <kendo-dropdownlist
                [data]="listItems"
                (valueChange)="getQuestion(selectedValue)"
                [(ngModel)]="selectedValue"
              ></kendo-dropdownlist>
            </div>
            <div class="clearfix"></div>
          </div>
          <div
            class="search-results"
            infinite-scroll
            [infiniteScrollDistance]="scrollDistance"
            [infiniteScrollUpDistance]="scrollUpDistance"
            [infiniteScrollThrottle]="throttle"
            (scrolled)="onScrollDown($event)"
          >
            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-open"
                role="tabpanel"
                aria-labelledby="pills-open-tab"
                *ngIf="!openCompletedDraftQuestionLoader1"
              >
                <div
                  class="questions_boxs"
                  *ngFor="
                    let question of openCompletedDraftQuestionList;
                    let i = index
                  "
                >
                  <div class="padding_boxs not_count_padding">
                    <div class="quebox_top">
                      <!-- <p><a (click)="questionDetail(question)" [innerHtml]="question.question_text"></a></p> -->
                      <div class="editor_data">
                        <a (click)="questionDetail(question)" [innerHtml]="question.question_text"></a>
                      </div>
                      <div class="quebox_media" *ngIf="question.filedata && question.image_video_url!='[]'">
                        <div
                        [attr.id]="'abc'+question.id"
                      class="carousel slide"
                      data-bs-ride="carousel"
                      data-bs-interval="false"
                    >
                      <div
                        class="carousel-indicators"
                        *ngIf="question.filedata.length > 1"
                      >
                        <button
                          type="button"
                          [attr.data-bs-target]="'#abc'+question.id"
                          [attr.data-bs-slide-to]="i"
                          [attr.aria-label]="'Slide ' + i"
                          [ngClass]="{ active: i == 0 }"
                          *ngFor="let list of question.filedata; let i = index"
                          [attr.aria-current]="i == 0"
                        ></button>
                      </div>
                      <div class="carousel-inner">
                        <div
                          class="carousel-item"
                          [ngClass]="{ active: i == 0 }"
                          *ngFor="let list of question.filedata; let i = index"
                        >
                          <img
                            alt=""
                            class="d-block w-100"
                            *ngIf="list.type == 'img'"
                            src="{{ globals.profileImgeapiUrl }}/question/{{
                              question.id
                            }}/{{ list.name }}"
                          />
                          <div
                            class="ratio ratio-16x9"
                            *ngIf="list.type == 'video'"
                          >
                            <video controls>
                              <source
                                src="{{ globals.profileImgeapiUrl }}/question/{{
                                  question.id
                                }}/{{ list.name }}"
                              />
                            </video>
                          </div>
                        </div>
                      </div>
                      <button
                      *ngIf="question.filedata.length > 1"
                        class="carousel-control-prev"
                        type="button"
                        [attr.data-bs-target]="'#abc'+question.id"
                        data-bs-slide="prev"
                      >
                        <span
                          class="carousel-control-prev-icon"
                          aria-hidden="true"
                        ></span>
                        <span class="visually-hidden">Previous</span>
                      </button>
                      <button
                      *ngIf="question.filedata.length > 1"
                        class="carousel-control-next"
                        type="button"
                        [attr.data-bs-target]="'#abc'+question.id"
                        data-bs-slide="next"
                      >
                        <span
                          class="carousel-control-next-icon"
                          aria-hidden="true"
                        ></span>
                        <span class="visually-hidden">Next</span>
                      </button>
                    </div>
                      </div>
                    </div>
                    <a (click)="questionDetail(question)">
                    <div class="quebox_bottom d-flex align-items-center">
                      <div class="qt_photo">
                        <img
                          *ngIf="
                            question.profile_image_url == null ||
                            question.profile_image_url == ''
                          "
                          id="user_img"
                          src="assets/images/dummy.jpg"
                          alt=""
                          class="img-fluid"
                        />
                        <img
                          *ngIf="
                            question.profile_image_url != null &&
                            question.profile_image_url != ''
                          "
                          id="user_img"
                          src="{{ globals.profileImgeapiUrl }}/{{
                            question.profile_image_url
                          }}"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                      <div class="qt_desc">
                        <!-- <span class="user_name" *ngIf="question.community_name != ''"><a
                                                    (click)="publicProfile(question.user_id)">{{question.user_name}}</a></span>
                                            <span class="user_name"
                                                *ngIf="question.community_name == '' && question.user != ' '"><a
                                                    (click)="publicProfile(question.user_id)">{{question.user}}</a></span>
                                            <span class="user_name"
                                                *ngIf="question.community_name == '' && question.user == ' '"><a
                                                    (click)="publicProfile(question.user_id)">{{question.user_name}}</a></span> -->
                        <span class="user_name"
                          ><a
                            (click)="
                              publicProfile(
                                question.user_id,
                                question.send_anonymously
                              )
                            "
                            >{{ question.display_name }}</a
                          ></span
                        >
                        <ul class="queicon_lists">
                          <li
                            class="like_question"
                            *ngIf="
                              globals.authData == undefined ||
                              question.is_like == '' ||
                              question.is_like == null
                            "
                          >
                            <a
                              onclick="event.stopPropagation();"
                              (click)="likeDislikeQuestionAnswer(question, 1)" id="thumb{{question.id}}"
                              ><i class="fa-regular fa-thumbs-up"></i
                            ></a>
                            <span>{{ question.likes }}</span>
                          </li>
                          <li
                            class="like_question"
                            *ngIf="
                              question.is_like != '' && question.is_like != null
                            "
                          >
                            <a
                              onclick="event.stopPropagation();"
                              (click)="likeDislikeQuestionAnswer(question, 0)" id="thumb{{question.id}}"
                              ><i class="fa-solid fa-thumbs-up"></i
                            ></a>
                            <span>{{ question.likes }}</span>
                          </li>
                          <li>
                            <i class="fa-regular fa-comment-dots"></i>
                            {{ question.comments }}
                          </li>
                          <li>
                            <i class="fa-regular fa-eye"></i>
                            {{ question.views }}
                          </li>
                          <!-- <li
                            class="community_lists"
                            *ngIf="question.community.length > 0"
                          >
                            <i class="fa-solid fa-bullhorn"></i>
                            <ng-container
                              *ngFor="let community of question.community"
                            >
                              <a (click)="communityQuestion(community.id)">{{
                                community.name
                              }}</a>
                            </ng-container>
                          </li> -->
                          <!-- <li *ngIf="question.community_name != ''"><a
                                                        (click)="communityQuestion(question.community_id)"><i
                                                            class="fa-solid fa-bullhorn"></i> {{question.community_name}}</a>
                                                </li> -->
                          <li *ngIf="question.community.length == 0">
                            <!-- <img
                              src="assets/images/private.png"
                              alt=""
                              class="img-fluid"
                            /> -->
                            <i class="fa-solid fa-lock"></i>
                            Private
                          </li>

                          <!-- <li><a  class="report"><i class="fa-solid fa-flag"></i> Report</a></li>
                                                <li *ngIf="question.invitee_can_invite_others != null"><a  class="report"><i class="fa-solid fa-share"></i> Share</a></li> -->
                        </ul>
                        <ul class="posted_expired_lists">
                          <li class="posted_date_time">
                            <span>Posted:</span> {{ question.posted_time }}
                          </li>
                          <li class="expires_date_time">
                            <span>{{ question.expire_title }}</span>
                            {{ question.Expire_time }}
                          </li>
                          <li
                            class="float-end"
                            *ngIf="
                              globals.authData == undefined ||
                              ((question.is_abused == '' ||
                                question.is_abused == null) &&
                                question.created_by != globals.authData.id &&
                                globals.reportQuestionAnswerEntity
                                  .question_id != question.id)
                            "
                          >
                            <a
                              class="report"
                              onclick="event.stopPropagation();"
                              (click)="
                                globals.reportPopUpOpen(
                                  question.id,
                                  '',
                                  question.user_name
                                )
                              "
                              ><i class="fa-solid fa-flag"></i> Report</a
                            >
                          </li>
                          <li
                            class="float-end"
                            *ngIf="
                              (question.is_abused != '' &&
                                question.is_abused != null) ||
                              (globals.reportQuestionAnswerEntity
                                .reportedTime != '' &&
                                globals.reportQuestionAnswerEntity
                                  .reportedTime != undefined &&
                                globals.reportQuestionAnswerEntity
                                  .question_id == question.id)
                            "
                          >
                            <a
                              onclick="event.stopPropagation();"
                              *ngIf="
                                globals.reportQuestionAnswerEntity
                                  .reportedTime != '' &&
                                globals.reportQuestionAnswerEntity
                                  .reportedTime != undefined &&
                                globals.reportQuestionAnswerEntity
                                  .question_id == question.id
                              "
                              class="report_question_answer"
                              title="{{
                                globals.commonTranslationText.questionDetail
                                  .reportQuestionTooltip
                              }} {{
                                globals.reportQuestionAnswerEntity.reportedTime
                                  | date : 'MMM d, y h:mm a'
                              }}"
                              ><i class="fa-solid fa-flag"></i> Reported
                            </a>
                            <a
                              onclick="event.stopPropagation();"
                              *ngIf="
                                globals.reportQuestionAnswerEntity
                                  .reportedTime == '' ||
                                globals.reportQuestionAnswerEntity
                                  .reportedTime == undefined ||
                                globals.reportQuestionAnswerEntity
                                  .question_id != question.id
                              "
                              class="report_question_answer"
                              title="{{
                                globals.commonTranslationText.questionDetail
                                  .reportQuestionTooltip
                              }} {{
                                question.is_abused | date : 'MMM d, y h:mm a'
                              }}"
                              ><i class="fa-solid fa-flag"></i> Reported
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div class="clearfix"></div>
                    </div>
                  </a>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="pills-inactive"
                role="tabpanel"
                aria-labelledby="pills-inactive-tab"
                *ngIf="!openCompletedDraftQuestionLoader1"
              >
                <div
                  class="questions_boxs"
                  *ngFor="
                    let question of questionList;
                    let i = index
                  "
                >
                  <div class="padding_boxs not_count_padding" >
                    <div class="quebox_top">
                      <!-- <p [innerHtml]="question.question_text"></p> -->
                      <div class="editor_data" ><a (click)="questionDetail(question)" [innerHtml]="question.question_text"></a></div>
                      <div class="quebox_media" *ngIf="question.filedata && question.image_video_url!='[]'">
                        <div
                        [attr.id]="'abc'+question.id"
                          class="carousel slide"
                          data-bs-ride="carousel"
                          data-bs-interval="false"
                        >
                          <div
                            class="carousel-indicators"
                            *ngIf="question.filedata.length > 1"
                          >
                            <button
                              type="button"
                              [attr.data-bs-target]="'#abc'+question.id"
                              [attr.data-bs-slide-to]="i"
                              [attr.aria-label]="'Slide ' + i"
                              [ngClass]="{ active: i == 0 }"
                              *ngFor="
                                let list of question.filedata;
                                let i = index
                              "
                              [attr.aria-current]="i == 0"
                            ></button>
                          </div>
                          <div class="carousel-inner">
                            <div
                              class="carousel-item"
                              [ngClass]="{ active: i == 0 }"
                              *ngFor="
                                let list of question.filedata;
                                let i = index
                              "
                            >
                              <img
                                alt=""
                                class="d-block w-100"
                                *ngIf="list.type == 'img'"
                                src="{{ globals.profileImgeapiUrl }}/question/{{
                                  question.id
                                }}/{{ list.name }}"
                              />
                              <div
                                class="ratio ratio-16x9"
                                *ngIf="list.type == 'video'"
                              >
                                <video controls>
                                  <source
                                    src="{{
                                      globals.profileImgeapiUrl
                                    }}/question/{{ question.id }}/{{
                                      list.name
                                    }}"
                                  />
                                </video>
                              </div>
                            </div>
                          </div>
                          <button
                            *ngIf="question.filedata.length > 1"
                            class="carousel-control-prev"
                            type="button"
                            [attr.data-bs-target]="'#abc'+question.id"
                            data-bs-slide="prev"
                          >
                            <span
                              class="carousel-control-prev-icon"
                              aria-hidden="true"
                            ></span>
                            <span class="visually-hidden">Previous</span>
                          </button>
                          <button
                            *ngIf="question.filedata.length > 1"
                            class="carousel-control-next"
                            type="button"
                            [attr.data-bs-target]="'#abc'+question.id"
                            data-bs-slide="next"
                          >
                            <span
                              class="carousel-control-next-icon"
                              aria-hidden="true"
                            ></span>
                            <span class="visually-hidden">Next</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <a (click)="questionDetail(question)">
                    <div class="quebox_bottom d-flex align-items-center">
                      <div class="qt_photo">
                        <img
                          *ngIf="
                            question.profile_image_url == null ||
                            question.profile_image_url == ''
                          "
                          id="user_img"
                          src="assets/images/dummy.jpg"
                          alt=""
                          class="img-fluid"
                        />
                        <img
                          *ngIf="
                            question.profile_image_url != null &&
                            question.profile_image_url != ''
                          "
                          id="user_img"
                          src="{{ globals.profileImgeapiUrl }}/{{
                            question.profile_image_url
                          }}"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                      <div class="qt_desc">
                        <!-- <span class="user_name" *ngIf="question.community_name != ''"><a
                                                    (click)="publicProfile(question.user_id)">{{question.user_name}}</a></span>
                                            <span class="user_name"
                                                *ngIf="question.community_name == '' && question.user != ' '"><a
                                                    (click)="publicProfile(question.user_id)">{{question.user}}</a></span>
                                            <span class="user_name"
                                                *ngIf="question.community_name == '' && question.user == ' '"><a
                                                    (click)="publicProfile(question.user_id)">{{question.user_name}}</a></span> -->
                        <span class="user_name"
                          ><a
                            (click)="
                              publicProfile(
                                question.user_id,
                                question.send_anonymously
                              )
                            "
                            >{{ question.display_name }}</a
                          ></span
                        >
                        <ul class="queicon_lists">
                          <li
                            class="like_question"
                            *ngIf="
                              globals.authData == undefined ||
                              question.is_like == '' ||
                              question.is_like == null
                            "
                          >
                            <a
                              onclick="event.stopPropagation();"
                              (click)="likeDislikeQuestionAnswer(question, 1)" id="thumb{{question.id}}"
                              ><i class="fa-regular fa-thumbs-up"></i
                            ></a>
                            <span>{{ question.likes }}</span>
                          </li>
                          <li
                            class="like_question"
                            *ngIf="
                              question.is_like != '' && question.is_like != null
                            "
                          >
                            <a
                              onclick="event.stopPropagation();"
                              (click)="likeDislikeQuestionAnswer(question, 0)" id="thumb{{question.id}}"
                              ><i class="fa-solid fa-thumbs-up"></i
                            ></a>
                            <span>{{ question.likes }}</span>
                          </li>
                          <li>
                            <i class="fa-regular fa-comment-dots"></i>
                            {{ question.comments }}
                          </li>
                          <li>
                            <i class="fa-regular fa-eye"></i>
                            {{ question.views }}
                          </li>
                          <!-- <li
                            class="community_lists"
                            *ngIf="question.community.length > 0"
                          >
                            <i class="fa-solid fa-bullhorn"></i>
                            <ng-container
                              *ngFor="let community of question.community"
                            >
                              <a (click)="communityQuestion(community.id)">{{
                                community.name
                              }}</a>
                            </ng-container>
                          </li> -->
                          <!-- <li *ngIf="question.community_name != ''"><a
                                                        (click)="communityQuestion(question.community_id)"><i
                                                            class="fa-solid fa-bullhorn"></i> {{question.community_name}}</a>
                                                </li> -->
                          <li *ngIf="question.community.length == 0">
                            <!-- <img
                              src="assets/images/private.png"
                              alt=""
                              class="img-fluid"
                            /> -->
                            <i class="fa-solid fa-lock"></i>
                            Private
                          </li>

                          <!-- <li><a  class="report"><i class="fa-solid fa-flag"></i> Report</a></li>
                                                <li *ngIf="question.invitee_can_invite_others != null"><a  class="report"><i class="fa-solid fa-share"></i> Share</a></li> -->
                        </ul>
                        <ul class="posted_expired_lists">
                          <li class="posted_date_time">
                            <span>Posted:</span> {{ question.posted_time }}
                          </li>
                          <li class="expires_date_time">
                            <span>{{ question.expire_title }}</span>
                            {{ question.Expire_time }}
                          </li>
                          <li
                            class="float-end"
                            *ngIf="
                              globals.authData == undefined ||
                              ((question.is_abused == '' ||
                                question.is_abused == null) &&
                                question.created_by != globals.authData.id &&
                                globals.reportQuestionAnswerEntity
                                  .question_id != question.id)
                            "
                          >
                            <a
                              class="report"
                              onclick="event.stopPropagation();"
                              (click)="
                                globals.reportPopUpOpen(
                                  question.id,
                                  '',
                                  question.user_name
                                )
                              "
                              ><i class="fa-solid fa-flag"></i> Report</a
                            >
                          </li>
                          <li
                            class="float-end"
                            *ngIf="
                              (question.is_abused != '' &&
                                question.is_abused != null) ||
                              (globals.reportQuestionAnswerEntity
                                .reportedTime != '' &&
                                globals.reportQuestionAnswerEntity
                                  .reportedTime != undefined &&
                                globals.reportQuestionAnswerEntity
                                  .question_id == question.id)
                            "
                          >
                            <a
                              onclick="event.stopPropagation();"
                              *ngIf="
                                globals.reportQuestionAnswerEntity
                                  .reportedTime != '' &&
                                globals.reportQuestionAnswerEntity
                                  .reportedTime != undefined &&
                                globals.reportQuestionAnswerEntity
                                  .question_id == question.id
                              "
                              class="report_question_answer"
                              title="{{
                                globals.commonTranslationText.questionDetail
                                  .reportQuestionTooltip
                              }} {{
                                globals.reportQuestionAnswerEntity.reportedTime
                                  | date : 'MMM d, y h:mm a'
                              }}"
                              ><i class="fa-solid fa-flag"></i> Reported
                            </a>
                            <a
                              onclick="event.stopPropagation();"
                              *ngIf="
                                globals.reportQuestionAnswerEntity
                                  .reportedTime == '' ||
                                globals.reportQuestionAnswerEntity
                                  .reportedTime == undefined ||
                                globals.reportQuestionAnswerEntity
                                  .question_id != question.id
                              "
                              class="report_question_answer"
                              title="{{
                                globals.commonTranslationText.questionDetail
                                  .reportQuestionTooltip
                              }} {{
                                question.is_abused | date : 'MMM d, y h:mm a'
                              }}"
                              ><i class="fa-solid fa-flag"></i> Reported
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div class="clearfix"></div>
                    </div>
                  </a>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="pills-draft"
                role="tabpanel"
                aria-labelledby="pills-draft-tab"
                *ngIf="!openCompletedDraftQuestionLoader1"
              >
                <div
                  class="questions_boxs"
                  *ngFor="
                    let question of openCompletedDraftQuestionList;
                    let i = index
                  "
                >
                  <div class="padding_boxs not_count_padding" >
                    <div class="quebox_top">
                      <!-- <p><a (click)="questionEdit(question.id)" [innerHtml]="question.question_text"></a></p> -->
                      <div class="editor_data">
                        <a (click)="questionEdit(question.id)" [innerHtml]="question.question_text"></a>
                      </div>
                      <div class="quebox_media" *ngIf="question.filedata && question.image_video_url!='[]'">
                        <div
                        [attr.id]="'drf'+question.id"
                          class="carousel slide"
                          data-bs-ride="carousel"
                          data-bs-interval="false"
                        >
                          <div
                            class="carousel-indicators"
                            *ngIf="question.filedata.length > 1"
                          >
                            <button
                              type="button"
                              [attr.data-bs-target]="'#drf'+question.id"
                              [attr.data-bs-slide-to]="i"
                              [attr.aria-label]="'Slide ' + i"
                              [ngClass]="{ active: i == 0 }"
                              *ngFor="
                                let list of question.filedata;
                                let i = index
                              "
                              [attr.aria-current]="i == 0"
                            ></button>
                          </div>
                          <div class="carousel-inner">
                            <div
                              class="carousel-item"
                              [ngClass]="{ active: i == 0 }"
                              *ngFor="
                                let list of question.filedata;
                                let i = index
                              "
                            >
                              <img
                                alt=""
                                class="d-block w-100"
                                *ngIf="list.type == 'img'"
                                src="{{ globals.profileImgeapiUrl }}/question/{{
                                  question.id
                                }}/{{ list.name }}"
                              />
                              <div
                                class="ratio ratio-16x9"
                                *ngIf="list.type == 'video'"
                              >
                                <video controls>
                                  <source
                                    src="{{
                                      globals.profileImgeapiUrl
                                    }}/question/{{ question.id }}/{{
                                      list.name
                                    }}"
                                  />
                                </video>
                              </div>
                            </div>
                          </div>
                          <button
                            *ngIf="question.filedata.length > 1"
                            class="carousel-control-prev"
                            type="button"
                            [attr.data-bs-target]="'#drf'+question.id"
                            data-bs-slide="prev"
                          >
                            <span
                              class="carousel-control-prev-icon"
                              aria-hidden="true"
                            ></span>
                            <span class="visually-hidden">Previous</span>
                          </button>
                          <button
                            *ngIf="question.filedata.length > 1"
                            class="carousel-control-next"
                            type="button"
                            [attr.data-bs-target]="'#drf'+question.id"
                            data-bs-slide="next"
                          >
                            <span
                              class="carousel-control-next-icon"
                              aria-hidden="true"
                            ></span>
                            <span class="visually-hidden">Next</span>
                          </button>
                        </div>
                        <img
                          *ngIf="
                            question.file_extention != '' &&
                            question.imageExtension
                          "
                          src="{{ globals.profileImgeapiUrl }}/question/{{
                            question.id
                          }}/{{ question.image_video_url }}"
                          alt=""
                          class="img-fluid"
                        />
                        <!-- <div class="embed-responsive embed-responsive-16by9 mb-5"
                                                *ngIf="question.file_extention != '' && (question.file_extention == 'mp4' || question.file_extention == 'MP4' || question.file_extention == 'MKV' || question.file_extention == 'mkv' || question.file_extention == 'WEBM' || question.file_extention == 'webm')">
                                                <video id="video_tab" width="400" controls>
                                                    <source id="video"
                                                        src="{{globals.profileImgeapiUrl}}/question/{{question.id}}/{{question.image_video_url}}">
                                                </video>
                                            </div>
                                            <img *ngIf="question.file_extention != '' && (question.file_extention == 'jpg' || question.file_extention == 'png' || question.file_extention == 'jpeg' || question.file_extention == 'JPG' || question.file_extention == 'PNG' || question.file_extention == 'JPEG')"
                                                src="{{globals.profileImgeapiUrl}}/question/{{question.id}}/{{question.image_video_url}}"
                                                alt="" class="img-fluid" /> -->
                      </div>
                    </div>
                    <a (click)="questionEdit(question.id)">
                    <div class="quebox_bottom d-flex align-items-center">
                      <ul class="queicon_lists">
                        <!-- <li
                          class="community_lists"
                          *ngIf="question.community.length > 0"
                        >
                          <i class="fa-solid fa-bullhorn"></i>
                          <ng-container
                            *ngFor="let community of question.community"
                          >
                            <a (click)="communityQuestion(community.id)">{{
                              community.name
                            }}</a>
                          </ng-container>
                        </li> -->
                        <!-- <li *ngIf="question.community_name != ''"><a><i class="fa-solid fa-bullhorn"></i>
                                                    {{question.community_name}}</a></li> -->
                        <li *ngIf="question.community.length == 0">
                          <!-- <img
                            src="assets/images/private.png"
                            alt=""
                            class="img-fluid"
                          /> -->
                          <i class="fa-solid fa-lock"></i>
                          Private
                        </li>
                        <li class="save_date_time">
                          <span>Saved:</span>
                          {{ question.created_at | date : "MMM d, y h:mm a" }}
                        </li>
                      </ul>
                      <div class="clearfix"></div>
                    </div>
                  </a>
                  </div>
                </div>
              </div>

              <ng-container
                *ngIf="
                  openCompletedDraftQuestionLoader1 ||
                  openCompletedDraftQuestionLoader2
                "
              >
                <div
                  class="questions_boxs"
                  *ngFor="let object of openCompletedDraftQuestionLoaderList"
                >
                  <ngx-shimmer-loading [width]="'100%'"></ngx-shimmer-loading>
                  <ngx-shimmer-loading [width]="'100%'"></ngx-shimmer-loading>
                  <ngx-shimmer-loading [width]="'100%'"></ngx-shimmer-loading>

                  <div class="row d-flex align-items-center">
                    <div class="col-xl-2 col-lg-2 col-12">
                      <ngx-shimmer-loading [shape]="'circle'" [width]="'75px'">
                      </ngx-shimmer-loading>
                    </div>
                    <div class="col-xl-10 col-lg-10 col-12">
                      <ngx-shimmer-loading
                        [width]="'250px'"
                      ></ngx-shimmer-loading>
                      <ngx-shimmer-loading
                        [width]="'250px'"
                      ></ngx-shimmer-loading>
                      <ngx-shimmer-loading
                        [width]="'250px'"
                      ></ngx-shimmer-loading>
                    </div>
                  </div>
                </div>
              </ng-container>

              <div
                *ngIf="
                  openCompletedDraftQuestionList.length == 0 &&
                  !openCompletedDraftQuestionLoader1
                "
              >
                <div class="no_que_found mt-5">
                  <img
                    src="assets/images/no_found.png"
                    alt=""
                    class="img-fluid"
                  />
                  No Threads found for your selection
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
        <div class="home_wrap_right">
          <!-- <div class="decideit_info">
            <div class="post_que_header">
              <h2 class="decideit_head">
                <span>Welcome To</span> Eyedea Exchange
              </h2>
            </div>
            <div class="advertisement_box">
              <p>
                IJCAHPO Eyedea Exchange is your key to fast, easy and
                clutter-free group decisions.
              </p> -->
              <!-- <p>Send to up to 1000 friends instantly, Now!</p> -->
              <!-- <p>Threads Remain Live for 99 Hrs</p> -->
            <!-- </div>
          </div> -->

          <!-- <div class="top_communities">
            <div class="post_que_header">
              <h2 class="decideit_head"><span>Popular</span> Topics</h2>
            </div>
            <ul
              class="top_communities_lists"
              *ngIf="topCommunitiesList.length > 0 && !topCommunitiesLoader"
            >
              <ng-container
                *ngFor="
                  let community of topCommunitiesList;
                  let i = index;
                  let last = last
                "
              >
                <li [ngClass]="{ pt_0: i == 0, no_border: last }">
                  <img
                    src="{{ globals.profileImgeapiUrl }}/community-image/{{
                      community.community_thumbnail_image
                    }}"
                    alt=""
                    class="img-fluid"
                  />
                  <a (click)="communityQuestion(community.id)"
                    ><span>{{ community.name }}</span></a
                  >
                </li>
              </ng-container>
              <button
                kendoButton
                (click)="topCommunityList()"
                type="submit"
                class="theme_btn theme_btn d-flex justify-content-center mx-auto"
              >
                View All
              </button>
            </ul>

            <ul class="top_communities_lists" *ngIf="topCommunitiesLoader">
              <div
                class="row d-flex align-items-center"
                *ngFor="let object of topCommunitiesDecideItStarLoaderList"
              >
                <div class="col-xl-3 col-lg-3 col-12">
                  <ngx-shimmer-loading [shape]="'circle'" [width]="'50px'">
                  </ngx-shimmer-loading>
                </div>
                <div class="col-xl-9 col-lg-9 col-12">
                  <ngx-shimmer-loading [width]="'175px'"></ngx-shimmer-loading>
                </div>
              </div>
            </ul>

            <div
              *ngIf="topCommunitiesList.length == 0 && !topCommunitiesLoader"
              class="no_records_found d-flex justify-content-center align-items-center"
            >
              <span>There are no communities found</span>
            </div>
          </div> -->

          <div class="decide_it_stars_wrap mt-0">
            <div class="post_que_header">
              <h2 class="decideit_head"><span>EYEXchange Connect</span> Stars</h2>
            </div>
            <div
              class="dis_with_info pb-5"
              *ngIf="decideItStarList.length > 0 && !decideItStarLoader"
            >
              <div class="decideitstar_legend">
                <ul>
                  <li>
                    <span class="fiftyqa"></span> Threads
                  </li>
                  <li><span class="fifteenqa"></span> Comments</li>
                  <li><span class="open_que"></span> Likes</li>
                </ul>
              </div>
              <ng-container
                *ngFor="let star of decideItStarList; let last = last"
              >
                <div
                  class="dis_box d-flex align-items-center"
                  [ngClass]="{ no_border: last }"
                >
                  <div class="dis_photo">
                    <img
                      *ngIf="
                        star.profile_image_url == null ||
                        star.profile_image_url == ''
                      "
                      id="user_img"
                      src="assets/images/dummy.jpg"
                      alt=""
                      class="img-fluid"
                    />
                    <img
                      *ngIf="
                        star.profile_image_url != null &&
                        star.profile_image_url != ''
                      "
                      id="user_img"
                      src="{{ globals.profileImgeapiUrl }}/{{
                        star.profile_image_url
                      }}"
                      alt=""
                      class="img-fluid"
                    />
                  </div>
                  <div class="dis_info">
                    <a (click)="publicProfile(star.id, '')">
                      <!-- <span
                                                *ngIf="star.is_profile_private == 0 && ((star.first_name != '' || star.first_name != null) && (star.last_name != '' || star.last_name != null) )">{{star.first_name}}
                                                {{star.last_name}}</span> -->
                      <span>{{ star.first_name }} {{star.last_name}}</span>
                      <!-- <span *ngIf="star.is_profile_private == 1">{{star.random_name}}</span> -->
                    </a>
                    <br />
                    <span class="more_than_50">{{ star.active_questions_count }}</span>
                    <span class="more_than_15">{{ star.comments_count }}</span>
                    <span class="open_question">{{ star.question_likes_count }}</span>
                  </div>
                  <!-- <div class="dis_rank">
                                                <span>1</span>
                                            </div> -->
                  <div class="clearfix"></div>
                </div>
              </ng-container>
              <button
                kendoButton
                type="submit"
                (click)="decideItStars()"
                class="mt-4 theme_btn theme_btn d-flex justify-content-center mx-auto"
              >
                View All
              </button>
            </div>
            <div class="dis_with_info pb-5" *ngIf="decideItStarLoader">
              <div
                class="row community_shimmer d-flex align-items-center"
                *ngFor="let object of topCommunitiesDecideItStarLoaderList"
              >
                <div class="col-xl-3 col-lg-3 col-12">
                  <ngx-shimmer-loading [shape]="'circle'" [width]="'50px'">
                  </ngx-shimmer-loading>
                </div>
                <div class="col-xl-9 col-lg-9 col-12">
                  <ngx-shimmer-loading [width]="'175px'"></ngx-shimmer-loading>
                  <ngx-shimmer-loading [width]="'175px'"></ngx-shimmer-loading>
                </div>
              </div>
            </div>

            <div
              *ngIf="decideItStarList.length == 0 && !decideItStarLoader"
              class="no_records_found d-flex justify-content-center align-items-center"
            >
              <span>There are no IJCAHPO EYEXchange Connect Stars</span>
            </div>
          </div>

          <!-- <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center mb-4">
                        <h3 class="que_post_title">
                            Post Your Question
                        </h3>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <kendo-dropdownlist [defaultItem]="'Choose a community'" [valuePrimitive]="true"
                            [data]="groupedData" [valueField]="'id'" [textField]="'name'" [virtual]="virtual"
                            [filterable]="true" (filterChange)="handleFilter($event)">
                        </kendo-dropdownlist>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <kendo-dropdownlist [data]="listItems" [textField]="'text'" [valueField]="'index'"
                            [value]="selectedValue" [valuePrimitive]="true" (valueChange)="answerTypeChange($event)">
                            <ng-template kendoDropDownListItemTemplate let-dataItem>
                                <span class="template"><img src="{{ dataItem.value }}" alt="" class="img-fluid" />
                                </span> {{ dataItem.text }}
                            </ng-template>
                        </kendo-dropdownlist>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 post_que_editor">
                        <div *ngIf="selectedValue == 1">
                            <div class="answer_formate_box mt-3 mb-5">
                                <h3 class="float-start">Enter a list of answer options</h3>
                                <span class="optional_ans_formate float-end">Answer Format: [ Option List ]</span>
                                <div class="clearfix"></div>
                                <div class="row">
                                    <div class="col-md-12 col-12 col-lg-6 col-sm-12"
                                        *ngFor="let item of OptionsList; let i = index; let last = last;">
                                        <div class="row">
                                            <div class="col-md-12 col-12 col-lg-10 col-sm-10">
                                                <div class="form-group mb-3" id="emails">
                                                    <input kendoTextBox type="text" class="form-control"
                                                        name="OptionValue{{i}}" [value]="item.OptionValue + (i+1)"
                                                        id="OptionValue{{i}}" pattern="[a-zA-Z0-9\-.',\s]{2,}"
                                                        maxlength="100" required />
                                                </div>
                                            </div>
                                            <div class="col-md-12 col-12 col-lg-2 col-sm-2 pl_0">
                                                <button *ngIf="(i!=0 && last) || (i!=0 && !last) || (i==0 && !last)"
                                                    class="themeoption_btn" type="button"
                                                    (click)="RemoveOption(item)"><i class="fa-solid fa-xmark"></i></button>
                                                <button *ngIf="last && i<3" class="theme_btn" type="button"
                                                    (click)="AddOption(i)"><i class="fa-solid fa-plus"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="selectedValue == 2">
                            <span class="optional_ans_formate">Answer Format: [ Yes/No ]</span>
                        </div>
                        <div *ngIf="selectedValue == 3">
                            <div class="answer_formate_box mt-3 mb-5">
                                <h3 class="float-start">Enter my answer options as a Ranked list</h3>
                                <span class="optional_ans_formate float-end">Answer Format: [ Ranked List ]</span>
                                <div class="clearfix"></div>


                                <div class="row">
                                    <div class="col-md-12 col-12 col-lg-6 col-sm-12"
                                        *ngFor="let item of RankList; let i = index; let last = last;">
                                        <div class="row">
                                            <div class="col-md-12 col-12 col-lg-10 col-sm-10">
                                                <div class="form-group mb-3" id="emails">
                                                    <input kendoTextBox type="text" class="form-control"
                                                        name="OptionValue{{i}}" [value]="item.OptionValue + (i+1) "
                                                        id="OptionValue{{i}}" pattern="[a-zA-Z0-9\-.',\s]{2,}"
                                                        maxlength="100" required />
                                                </div>
                                            </div>
                                            <div class="col-md-12 col-12 col-lg-2 col-sm-2 pl_0">
                                                <button *ngIf="(i!=0 && last) || (i!=0 && !last) || (i==0 && !last)"
                                                    class="themeoption_btn" type="button"
                                                    (click)="RemoveOptionrank(item)"><i
                                                        class="fa-solid fa-xmark"></i></button>
                                                <button *ngIf="last && i<2" class="theme_btn" type="button"
                                                    (click)="AddOptionrank(i)"><i class="fa-solid fa-plus"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="selectedValue == 4">
                            <span class="optional_ans_formate">Answer Format: [ Express Yourself ]</span>
                        </div>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div class="post_que_editor">
                            <textarea id="field" placeholder="Enter Your Question Here" class="form-control"
                                (keyup)="countChar()"></textarea>
                            <div id="charNum"></div><span id="limit">0/255</span>
                            <div class="additional_video_option">
                                <div class="form-group file_upload">
                                    <input type="text" disabled class="form-control"
                                        placeholder="Upload Supporting Video" />
                                    <input type="file" name="fileToUpload" id="fileToUpload" class="form-control" />
                                </div>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>

                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 existing_group mt-4">
                        <h3 class="float-start">WHO WOULD YOU LIKE TO SEND THIS TO?</h3>
                        <kendo-dropdownlist class="mb-3 float-end" [defaultItem]="'Choose existing group'"
                            [valuePrimitive]="true" [data]="GroupItems">
                        </kendo-dropdownlist>
                        <textarea id="field" placeholder="Who would you like to send this?"
                            class="form-control"></textarea>
                        <small>Enter email addresses or telephone numbers separated by commas
                            (Example : bestfriend@url.com, 123-321-4565, sister@xyz.com)</small>
                        <button type="submit" class="theme_btn mt-2 float-end" id="next_btn">Save as Group</button>
                        <div class="clearfix"></div>
                    </div>

                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div class="more_send_options mt-4">
                            <h3>More send options</h3>
                            <div class="check_box">
                                <input type="checkbox" name="Check" id="Check1" value="Check1">
                                <label for="Check1" class="checkboxbtn_label">Show results only to me</label>
                            </div>
                            <div class="check_box">
                                <input type="checkbox" name="Check" id="Check2" value="Check2">
                                <label for="Check2" class="checkboxbtn_label">Recipients can invite others</label>
                            </div>
                            <div class="check_box">
                                <input type="checkbox" name="Check" id="Check3" value="Check3">
                                <label for="Check3" class="checkboxbtn_label">Show Invitee list only to me</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center mt-5">
                        <button type="submit" class="themeoption_btn" id="next_btn">Save
                            Draft</button>
                        <button type="submit" class="theme_btn" id="next_btn">Post</button>
                    </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
