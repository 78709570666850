import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Globals } from '.././globals';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { ActivatedRoute, Params } from '@angular/router';
import { UserDashboardService } from '../services/user-dashboard.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
declare var $: any, Swal;

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class UserDashboardComponent implements OnInit {

  // public onTabSelect(e) {
  //   console.log(e);
  // }
  openCompletedDraftQuestionList;
  questionList;
  openCompletedDraftQuestionLoaderList;
  openCompletedDraftQuestionLoader1;
  openCompletedDraftQuestionLoader2;
  openQuestionList;
  // completedQuestionList;
  inactiveQuestionList;
  draftQuestionList;
  topCommunitiesList;
  topCommunitiesLoader;
  decideItStarList;
  decideItStarLoader;
  topCommunitiesDecideItStarLoaderList;
  startpostion = 0;
  endpostion = 5;
  throttle = 10;
  scrollDistance = 1.5;
  scrollUpDistance = 2;
  tabType;
  dashboardSearchEntity;
   public listItems: Array<string> = ['My threads', 'Liked by me','Commented by me'];
  // public listItems: Array<string> = ['My threads'];
    public selectedValue = 'My threads';

  constructor(public globals: Globals,
    private cookieService: CookieService,private router: Router,
    private route: ActivatedRoute,
    private UserDashboardService:UserDashboardService) { }

  ngOnInit(): void {
    this.tabType = window.atob(this.route.snapshot.paramMap.get('type'));
    this.openCompletedDraftQuestionList = [];
    this.questionList = [];
    this.openCompletedDraftQuestionLoaderList = [];
    this.openCompletedDraftQuestionLoader1 = true;
    this.openQuestionList = [];
    // this.completedQuestionList = [];
    this.inactiveQuestionList = [];
    this.draftQuestionList = [];
    this.topCommunitiesList = [];
    this.topCommunitiesDecideItStarLoaderList = [];
    this.topCommunitiesLoader = true;
    this.decideItStarList = [];
    this.decideItStarLoader = true;
    this.dashboardSearchEntity = {};

    for (var i = 0; i < 5; i++) {
      this.openCompletedDraftQuestionLoaderList.push(i);
    }
    for (var i = 0; i < 10; i++) {
      this.topCommunitiesDecideItStarLoaderList.push(i);
    }
    $(".list_grid_view li a").click(function () {
      $(".section-link").removeClass("active");
      $(this).addClass("active");
    });

    // if (this.globals.authData && this.globals.authData.roles_id == 1) {
    //   $('#userdashboard_page').addClass(
    //     'col-md-10 col-sm-10 offset-md-2 main_block'
    //   );
    // }

    $('.section-link').click(function () {//debugger
      var cur = $('.section-link').index($(this)); // get the index of the clicked link
      $('.section-display').removeClass('active'); // hide all of the sections
      $('.section-display').eq(cur).addClass('active'); // show the section at the same index of the clicked link 
    });
    this.getQuestion(this.selectedValue);

    

    // let currentLink = this.globals.currentLink;
    // setTimeout(function () {
    //   // $('#v-pills-tab a').click(function (e) {
    //   //   e.preventDefault();
    //   //   $(this).tab('show');
    //   // });

    //   // // store the currently selected tab in the hash value
    //   // $(".nav-pills > a").on("shown.bs.tab", function (e) {
    //   //   var id = $(e.target).attr("href").substr(1);
    //   //   window.location.hash = id;
    //   // });

    //   // on load of the page: switch to the currently selected tab
    //   var hash = window.location.hash;
    //   $('#v-pills-tab a[href="' + hash + '"]').tab('show');

    // }, 100);

  }

  ngAfterViewInit() {
    this.getTopCommunities('');
    this.getTopDecideItStar('');
    // this.getMonthlyProctoringData();
  }
  addItems(startIndex, endIndex) {
    for (let i = startIndex; i < endIndex; ++i) {
      if (i < this.questionList.length) {
        if ($.inArray(this.questionList[i], this.openCompletedDraftQuestionList) < 0) {
          this.openCompletedDraftQuestionList.push(this.questionList[i]);
        }
      }
    }
    console.log(this.openCompletedDraftQuestionList);
    this.openCompletedDraftQuestionList = this.globals.extensionCheck(this.openCompletedDraftQuestionList)
    
    // this.questionLength = this.communityQuestionsList.length;
    setTimeout(() => {
      
    // if(this.tabType == 'draft')
    // {
    //   $("#pills-draft-tab").addClass('active');
    //   $("#pills-open-tab").removeClass('active');
    //   // $("#pills-draft-tab").attr('aria-selected',true);

    //   $("#pills-draft").addClass('show active');
    //   // $("#pills-open-tab").attr('aria-selected',false);
    //   $("#pills-open").removeClass('show active');

    // }
      this.openCompletedDraftQuestionLoaderList = [];
      this.openCompletedDraftQuestionLoader1 = false;
      this.openCompletedDraftQuestionLoader2 = false;
      // console.log(this.questionLoaderList);
    }, 2000);
  }
  onScrollDown(ev) {
    console.log(ev);
    if (this.openCompletedDraftQuestionList.length > 0) {
      if (ev != '' && !this.openCompletedDraftQuestionLoader1) {
        this.openCompletedDraftQuestionLoaderList = [];
        this.openCompletedDraftQuestionLoader2 = true;
        for (var j = 0; j < 1; j++) {
          this.openCompletedDraftQuestionLoaderList.push(j);
        }
      }
      setTimeout(() => {
        if (ev != '') {
          this.startpostion = this.endpostion;
          this.endpostion = this.endpostion + 5;
          this.addItems(this.startpostion, this.endpostion);
        }
      }, 3000);
    }
    // console.log("scrolled down!!", ev);

    // // add another 20 items
    // const start = this.sum;
    // // this.sum += 20;
    // this.appendItems(start, this.sum);

    // this.direction = "down";
  }
  tabChange(filterValue)
  {//debugger
    // alert(filterValue)
    console.log(this.selectedValue);
    this.questionList = [];
    if(filterValue == 'open')
    {
      this.questionList = this.openQuestionList;
      // if($.inArray('Posted by me', this.listItems) < 0)
      //  this.listItems.push('Posted by me');      
    }
    else if(filterValue == 'inactive')
    {
      this.questionList = this.inactiveQuestionList;
      // if($.inArray('Posted by me', this.listItems) < 0)
      //  this.listItems.push('Posted by me');
    }
    else if(filterValue == 'draft')
    {
      this.questionList = this.draftQuestionList;
      // if($.inArray('Posted by me', this.listItems) < 0)
      //  this.listItems.push('Posted by me');
    }
    else
    {
      this.questionList = this.openQuestionList
      // if(this.selectedValue == 'Posted by me')
      // { 
      //   this.selectedValue = 'All';
      // }
      this.listItems.splice(3, 1); // remove Posted by me
      // this.questionList = this.draftQuestionList;
    }
    console.log(this.questionList);
    this.openCompletedDraftQuestionList = [];
    this.startpostion = 0;
    this.endpostion = 10;
    this.addItems(this.startpostion, this.endpostion);

  }
  filterChange()
  {
    // alert(this.selectedValue.toLowerCase());
  }
  getQuestion(filter) {
    if(filter.toLowerCase() == 'my threads')
     { 
       filter = 'all';
     }
     else if(filter.toLowerCase() == 'liked by me'){
      filter = 'liked_by_me';
     }
     else if(filter.toLowerCase() == 'commented by me'){
      filter = 'commented_by_me';
     }
     var search = '';
     if(this.dashboardSearchEntity.search != '' && this.dashboardSearchEntity.search != undefined && this.dashboardSearchEntity.search.length>=3)
     {
       search = this.dashboardSearchEntity.search;
     }
    this.UserDashboardService.getQuestionCommunityStarData(filter.toLowerCase(),'dashboard_data',search).then(
      (data) => {
        console.log(data);
        this.openQuestionList = data['open_questions'];
        // this.completedQuestionList = data['completed_questions'];
        this.inactiveQuestionList = data['inactive_questions'];
        this.draftQuestionList = data['draft_questions'];
        this.openCompletedDraftQuestionLoader1 = false;
        // this.addItems(this.startpostion, this.endpostion);
        // if(id != '')
        // {
          if($("#pills-open-tab").attr("aria-selected") == 'true')
          {
            var filterValue = 'open';
          }
          else if($("#pills-inactive-tab").attr("aria-selected") == 'true')
          {
            var filterValue = 'inactive';
          }
          else if($("#pills-draft-tab").attr("aria-selected") == 'true')
          {
            var filterValue = 'draft';
          }
          else{
            var filterValue = '';
          }
          console.log($("#pills-open-tab").attr("aria-selected"));
          console.log($('#pills-completed-tab').attr('aria-selected'));
          this.tabChange(filterValue);
        // }
      },
      (error) => {
        this.openCompletedDraftQuestionLoader1 = false;
        console.log(error);
      }
    );
  }

  getTopCommunities(filter)
  {
    this.UserDashboardService.getQuestionCommunityStarData(filter,'top_communities','').then(
      (data) => {
        console.log(data);
        // var community: any;
        // community = data['data'];
        // var communitySelect = {
        //   id: '',
        //   name: 'Select Community',
        //   value: '',
        // };
        // this.communityList.push(communitySelect);
        // this.communityList = [...this.communityList, ...community];
        this.topCommunitiesList = data['data'];
        this.topCommunitiesLoader = false;
      },
      (error) => {
        this.topCommunitiesLoader = false;
        console.log(error);
      }
    );
  }
  getTopDecideItStar(filter) {
    this.globals.topDecideItStars('decideit_stars', '',10).then(
      (data) => {
        console.log(data);
        this.decideItStarList = data['data'];
        this.decideItStarLoader = false;
      },
      (error) => {
        this.decideItStarLoader = false;
        console.log(error);
      }
    );
  }
  click(e)
  {
    console.log(e);
  }
  
  likeDislikeQuestionAnswer(question,is_like)
  {
    
    var id = question.id
    $('li.like_question a#thumb' + id).addClass('disabled_btn').parents('.padding_boxs').addClass('disabled_post');
    
    this.globals.likeDislikeQuestionAnswer(question.id,is_like,'').then(
      (data) => {
        console.log(data);
        if(is_like == 1)
        {
          question.likes = question.likes + 1;
          question.is_like = 1;
          $('li.like_question a#thumb' + id).parents('.padding_boxs').removeClass('disabled_post');
        }
        else
        {
          question.likes = question.likes - 1;
          question.is_like = null;
          $('li.like_question a#thumb' + id).parents('.padding_boxs').removeClass('disabled_post');
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  questionDetail(questionObject) {
    console.log(questionObject);
    this.globals.viewCount = 1;
    if(questionObject.community.length == 0 )
      this.router.navigate(['/questionDetails/' + questionObject.id + '/' + window.btoa('private')]);
    else
      this.router.navigate(['/questionDetails/' + questionObject.id]);
  }
  questionEdit(id) {
    this.router.navigate(['/postQuestion/' + id]);
  }
  topCommunityList()
  {
    window.location.href = '/communityList';
  }
  communityQuestion(id)
  {
    window.location.href = '/communityQuestions/' + window.btoa(id);
  }
  publicProfile(id,value)
  {
    if(value == '')
    {
      window.location.href = '/publicProfile/' + window.btoa(id);
    }
    else
    {
      window.location.href = '/publicProfile/' + window.btoa(id) + '/' + window.btoa(value);
    }
  }
  decideItStars()
  {
    window.location.href = '/decideItStars/'+window.btoa('star');;
  }

}
