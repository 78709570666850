import { Component, OnInit, ViewEncapsulation } from '@angular/core';
declare var FB: any;
@Component({
  selector: 'app-loginfacebook',
  templateUrl: './loginfacebook.component.html',
  styleUrls: ['./loginfacebook.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginfacebookComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    (window as any).fbAsyncInit = function() {
      FB.init({
        appId      : '272702324005144',
        cookie     : true,
        xfbml      : true,
        version    : 'v8.0'
      });
      FB.AppEvents.logPageView();
    };
  
    (function(d, s, id){
       var js, fjs = d.getElementsByTagName(s)[0];
       if (d.getElementById(id)) {return;}
       js = d.createElement(s); js.id = id;
       js.src = "https://connect.facebook.net/en_US/sdk.js";
       fjs.parentNode.insertBefore(js, fjs);
     }(document, 'script', 'facebook-jssdk'));
  }
  submitLogin(){
    console.log("submit login to facebook");
    // FB.login();
    FB.login((response)=>
        {
          //debugger
          console.log('submitLogin',response);
          if (response.authResponse)
          {
            alert('login successful');
            var self = {};// = this;
            FB.api(
              "/" + response.authResponse.userID + '?fields=name,accounts,email',
              (result) => {
                if (result && !result.error) {
                  // self.userName = result.name;
                  // self.userEmail = result.email;
                  // self.pageaccountDetails = result.accounts.data;
                  console.log(result);

                }
                else {
                  // this.isValidCredentials = false;
                }
              });
          }
           else
           {
           console.log('User login failed');
         }
      });

  }
  signout()
  {//debugger
    FB.getLoginStatus(function(response) {
      FB.logout(function(response){
        console.log("Logged Out!");
        // window.location = "/";
      });
    });
  }

}
