import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Globals } from '.././globals';
import { Router } from '@angular/router';
import { ActivatedRoute, Params } from '@angular/router';
import { CommunityQuestionService } from '../services/community-question.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
declare var $: any, Swal;
@Component({
  selector: 'app-top-questions',
  templateUrl: './top-questions.component.html',
  styleUrls: ['./top-questions.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class TopQuestionsComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public globals: Globals,
    private CommunityQuestionService: CommunityQuestionService) { }

  communityId;
  communityList;
  filterCommunityList;
  communityQuestionLoading1;
  communityQuestionLoading2;
  responderLoader;
  responderLoaderList;
  responderList;
  todaysdate;
  array = [];
  sum = 100;
  throttle = 10;
  scrollDistance = 1.5;
  scrollUpDistance = 2;
  direction = '';
  modalOpen = false;
  questionList;
  startpostion = 0;
  endpostion = 5;
  communityQuestionsList;
  questionLoaderList;
  newQuestionList;
  topQuestionList;
  user_id = '';
  // defaultSelect;
  public defaultSelect: { name: string, id: string } = { name: 'Select Topic', id: '' };
  ngOnInit(): void {
    // if (this.globals.authData && this.globals.authData.roles_id == 1) {
    //   $('#popular_page').addClass(
    //     'col-md-10 col-sm-10 offset-md-2 main_block'
    //   );
    // }

    this.communityQuestionLoading1 = true;
    this.responderLoader = true;
    this.communityQuestionsList = [];
    this.communityList = [];
    this.filterCommunityList = [];
    this.questionLoaderList = [];
    this.topQuestionList = [];
    this.newQuestionList = [];
    this.responderLoaderList = [];
    this.responderList = [];
    this.questionList = [];
    for (var i = 0; i < 5; i++) {
      this.questionLoaderList.push(i);
    }
    for (var i = 0; i < 10; i++) {
      this.responderLoaderList.push(i);
    }
    this.CommunityQuestionService.getTopQuestion('','categories',this.user_id).then(
      (data) => {
        console.log(data);
        // var community: any;
        // community = data['data'];
        // var communitySelect = {
        //   id: '',
        //   name: 'Select Community',
        //   value: '',
        // };
        // this.communityList.push(communitySelect);
        // this.communityList = [...this.communityList, ...community];
        this.communityList = data['data'];
      },
      (error) => {
        this.communityQuestionLoading1 = false;
        console.log(error);
      }
    );
  }
  
  public itemDisabled(itemArgs: { dataItem: any; index: number }) {
    const dataItem = itemArgs.dataItem;
    if (
      dataItem.itemClass === 'parent' ||
      dataItem.itemClass === 'first-level'
    ) {
      return true;
    }
  }

  ngAfterViewInit() {
    this.getCommunityQuestion('');
    this.getTopResponders('');
    // this.getMonthlyProctoringData();
  }
  adminFilter(event){
    // console.log(event.target.checked)
    if(event.target.checked == true){
      var id = 1
    }
    else{
      var id = 0
    }
    this.CommunityQuestionService.getTopQuestion(id,'top_questions',this.user_id).then(
      (data) => {
        console.log(data);
        // this.questionList = data['top_questions'];
        this.topQuestionList = data['top_questions'];
        this.newQuestionList = data['new_questions'];
        this.communityQuestionLoading1 = false;
        // this.addItems(this.startpostion, this.endpostion);
        // if(id != '')
        // {
          if($("#pills-open-tab").attr("aria-selected") == 'true')
          {
            var filterValue = 'top';
          }
          else
          {
            var filterValue = 'new';
          }
          console.log($("#pills-open-tab").attr("aria-selected"));
          console.log($('#pills-completed-tab').attr('aria-selected'));
          this.filterChange(filterValue);
        // }
      },
      (error) => {
        this.communityQuestionLoading1 = false;
        console.log(error);
      }
    );
  }
  addItems(startIndex, endIndex) {
    for (let i = startIndex; i < endIndex; ++i) {
      if (i < this.questionList.length) {
        if ($.inArray(this.questionList[i], this.communityQuestionsList) < 0) {
          this.communityQuestionsList.push(this.questionList[i]);
        }
      }
    }
    console.log(this.communityQuestionsList);
    this.communityQuestionsList = this.globals.extensionCheck(this.communityQuestionsList);
    // this.questionLength = this.communityQuestionsList.length;
    setTimeout(() => {
      this.questionLoaderList = [];
      this.communityQuestionLoading1 = false;
      this.communityQuestionLoading2 = false;
      console.log(this.questionLoaderList);
    }, 2000);
  }
  onScrollDown(ev) {
    console.log(ev);
    if (this.communityQuestionsList.length > 0) {
      if (ev != '' && !this.communityQuestionLoading1) {
        this.questionLoaderList = [];
        this.communityQuestionLoading2 = true;
        for (var j = 0; j < 1; j++) {
          this.questionLoaderList.push(j);
        }
      }
      setTimeout(() => {
        if (ev != '') {
          this.startpostion = this.endpostion;
          this.endpostion = this.endpostion + 5;
          this.addItems(this.startpostion, this.endpostion);
        }
      }, 3000);
    }
    // console.log("scrolled down!!", ev);

    // // add another 20 items
    // const start = this.sum;
    // // this.sum += 20;
    // this.appendItems(start, this.sum);

    // this.direction = "down";
  }
  filterChange(filterValue)
  {
    this.questionList = [];
    if(filterValue == 'top')
    {
      this.questionList = this.topQuestionList;
    }
    else
    {
      this.questionList = this.newQuestionList;
    }
    console.log(this.questionList);
    this.communityQuestionsList = [];
    this.startpostion = 0;
    this.endpostion = 10;
    this.addItems(this.startpostion, this.endpostion);

  }
  getCommunityQuestion(id) {
    this.CommunityQuestionService.getTopQuestion(id,'top_questions',this.user_id).then(
      (data) => {
        console.log(data);
        // this.questionList = data['top_questions'];
        this.topQuestionList = data['top_questions'];
        this.newQuestionList = data['new_questions'];
        this.communityQuestionLoading1 = false;
        // this.addItems(this.startpostion, this.endpostion);
        // if(id != '')
        // {
          if($("#pills-open-tab").attr("aria-selected") == 'true')
          {
            var filterValue = 'top';
          }
          else
          {
            var filterValue = 'new';
          }
          console.log($("#pills-open-tab").attr("aria-selected"));
          console.log($('#pills-completed-tab').attr('aria-selected'));
          this.filterChange(filterValue);
        // }
      },
      (error) => {
        this.communityQuestionLoading1 = false;
        console.log(error);
      }
    );
  }

  // getTopResponders(id) {
  //   this.CommunityQuestionService.getTopQuestion(id,'decideit_stars',this.user_id).then(
  //     (data) => {
  //       this.responderLoader = false;
  //       console.log(data);
  //       this.responderList = data['data'];
  //     },
  //     (error) => {
  //       this.responderLoader = false;
  //       console.log(error);
  //     }
  //   );
  // }
  getTopResponders(id) {
    this.globals.topDecideItStars('decideit_stars', '', 100).then(
      (data) => {
        this.responderLoader = false;
        console.log(data);
        this.responderList = data['data'];
      },
      (error) => {
        this.responderLoader = false;
        console.log(error);
      }
    );
  }
  handleFilter(value) {
    var entity = this.filterCommunityList;
    this.communityList = entity.filter(
      (s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  questionDetail(id) {
    this.globals.viewCount = 1;
    this.router.navigate(['/questionDetails/' + id]);
  }
  communityQuestion(id) {
    window.location.href = '/communityQuestions/' + window.btoa(id);
  }
  publicProfile(id,value)
  {
    if(value == '')
    {
      window.location.href = '/publicProfile/' + window.btoa(id);
    }
    else
    {
      window.location.href = '/publicProfile/' + window.btoa(id) + '/' + window.btoa(value);
    }
  }
  decideItStars()
  {
    window.location.href = '/decideItStars/' + window.btoa('star');
  }

  likeDislikeQuestionAnswer(question,is_like,tab)
  {
    var id = question.id

    $('li.like_question a#thumb' + id).addClass('disabled_btn').parents('.padding_boxs').addClass('disabled_post');
    
    this.globals.likeDislikeQuestionAnswer(question.id,is_like,'').then(
      (data) => {
        console.log(data);
        if(is_like == 1)
        {
          question.likes = question.likes + 1;
           question.is_like = 1;
          $('li.like_question a#thumb' + id).parents('.padding_boxs').removeClass('disabled_post');
          if(tab == 'top'){
            // console.log(this.newQuestionList)
          
          var questionId = question.id
          var objIndex = this.newQuestionList.findIndex((obj => obj.id == questionId));
          this.newQuestionList[objIndex].likes = this.newQuestionList[objIndex].likes + 1;
          this.newQuestionList[objIndex].is_like = 1
          // console.log(this.newQuestionList)
          }
          else if(tab == 'new'){
            // console.log(this.topQuestionList)
          
          var questionId = question.id
          var objIndex = this.topQuestionList.findIndex((obj => obj.id == questionId));
          this.topQuestionList[objIndex].likes = this.topQuestionList[objIndex].likes + 1;
          this.topQuestionList[objIndex].is_like = 1
          // console.log(this.topQuestionList)
          }
        }
        else
        {

          question.likes = question.likes - 1;
          question.is_like = null;
          $('li.like_question a#thumb' + id).parents('.padding_boxs').removeClass('disabled_post');
          if(tab == 'top'){
          // console.log(this.newQuestionList)

          var questionId = question.id
          var objIndex = this.newQuestionList.findIndex((obj => obj.id == questionId));
          this.newQuestionList[objIndex].likes = this.newQuestionList[objIndex].likes - 1;
          this.newQuestionList[objIndex].is_like = ''
          // console.log(this.newQuestionList)
          }
          else if(tab == 'new'){
            // console.log(this.topQuestionList)

          var questionId = question.id
          var objIndex = this.topQuestionList.findIndex((obj => obj.id == questionId));
          this.topQuestionList[objIndex].likes = this.topQuestionList[objIndex].likes - 1;
          this.topQuestionList[objIndex].is_like = ''
          // console.log(this.topQuestionList)
          }

        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
