export const environment = {
    production: true,
    baseUrl: 'https://eyexchangeconnect.uatbyopeneyes.com',
    apiUrl: 'https://api.eyexchangeconnect.uatbyopeneyes.com/api/',
    profileImgeapiUrl: 'https://api.eyexchangeconnect.uatbyopeneyes.com',

  env : 'prod'
    // baseUrl: 'https://eyedeaexchange.uatbyopeneyes.com',
    // apiUrl: 'https://api.eyedeaexchange.uatbyopeneyes.com/api/',
    // profileImgeapiUrl: 'https://api.eyedeaexchange.uatbyopeneyes.com',
    // env: 'dev'

  };