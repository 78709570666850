import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Globals } from '.././globals';
import { Router } from '@angular/router';
import { ActivatedRoute, Params } from '@angular/router';
import { GroupResult, groupBy } from '@progress/kendo-data-query';
import { HomeService } from '../services/home.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
declare var $: any, PerfectScrollbar, Swal;

@Component({
  selector: 'app-decideit-stars',
  templateUrl: './decideit-stars.component.html',
  styleUrls: ['./decideit-stars.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class DecideitStarsComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public globals: Globals,
    private HomeService: HomeService
  ) {}
  decideItStarResponderLoaderList;
  decideItStarResponderList;
  starResponderList;
  decideItStarResponderLoader1;
  decideItStarResponderLoader2;
  decideItStarResponderLoader3;
  startpostion = 0;
  endpostion = 10;
  throttle = 10;
  scrollDistance = 1.5;
  scrollUpDistance = 2;
  responderStarList;
  responderStarLoaderList;
  starList;
  flag;
  ngOnInit(): void {
    // $(window).on('load resize scroll', function () {
    //   var f = $('footer');
    //   f.css({ position: 'static' });
    //   if (f.offset().top + f.height() < $(window).height()) {
    //     f.css({ position: 'fixed', bottom: '0', width: '100%' });
    //   }
    // });
    if (this.globals.isUser == '1') {
      $("#star_page").addClass('col-md-10 col-sm-10 offset-md-2 main_block');
    }
    this.decideItStarResponderList = [];
    this.decideItStarResponderLoaderList = [];
    this.starResponderList = [];
    this.responderStarList = [];
    this.responderStarLoaderList = [];
    this.starList = [];
    this.decideItStarResponderLoader1 = true;
    this.decideItStarResponderLoader3 = true;
    this.flag = window.atob(this.route.snapshot.paramMap.get('flag'));
    for (var i = 0; i < 12; i++) {
      this.decideItStarResponderLoaderList.push(i);
    }
    for (var i = 0; i < 10; i++) {
      this.responderStarLoaderList.push(i);
    }
    // if (this.flag == 'star') {
    //   var flag = 'decideit_stars';
    // } else {
    //   var flag = 'top_responders';
    // }
    if (this.flag == 'star') {
      var flag = 'decideit_stars';
    }
    this.globals.topDecideItStars(flag, '', 100).then(
      (data) => {
        console.log(data);
        this.starResponderList = data['data'];
        this.decideItStarResponderLoader1 = false;
        this.addItems(this.startpostion, this.endpostion);
      },
      (error) => {
        this.decideItStarResponderLoader1 = false;
        console.log(error);
      }
    );
  }
  ngAfterViewInit() {
    // $(function(){
    //   $(window).scroll(function(){
    //     alert('scrolled')
    //   });
    // });
    this.topResponders();
    // this.getMonthlyProctoringData();
  }
  topResponders() {
    // if (this.flag == 'star') {
    //   var flag = 'top_responders';
    // } else {
    //   var flag = 'decideit_stars';
    // }
    if (this.flag == 'star') {
      var flag = 'decideit_stars';
    }
    this.globals.topDecideItStars(flag, '', 10).then(
      (data) => {
        console.log(data);
        this.responderStarList = data['data'];
        this.decideItStarResponderLoader3 = false;
        this.addItems(this.startpostion, this.endpostion);
      },
      (error) => {
        this.decideItStarResponderLoader3 = false;
        console.log(error);
      }
    );
  }
  publicProfile(id) {
    window.location.href = '/publicProfile/' + window.btoa(id);
  }

  addItems(startIndex, endIndex) {
    for (let i = startIndex; i < endIndex; ++i) {
      if (i < this.starResponderList.length) {
        if (
          $.inArray(this.starResponderList[i], this.decideItStarResponderList) <
          0
        ) {
          this.decideItStarResponderList.push(this.starResponderList[i]);
        }
      }
    }
    // this.questionLength = this.communityQuestionsList.length;
    setTimeout(() => {
      this.decideItStarResponderLoaderList = [];
      this.decideItStarResponderLoader1 = false;
      this.decideItStarResponderLoader2 = false;
      console.log(this.decideItStarResponderLoaderList);
    }, 500);
  }
  onScrollDown(ev) {
    console.log(ev);
    if (this.decideItStarResponderList.length > 0) {
      if (ev != '' && !this.decideItStarResponderLoader1) {
        this.decideItStarResponderLoaderList = [];
        this.decideItStarResponderLoader2 = true;
        for (var j = 0; j < 10; j++) {
          this.decideItStarResponderLoaderList.push(j);
        }
      }
      setTimeout(() => {
        if (ev != '') {
          this.startpostion = this.endpostion;
          this.endpostion = this.endpostion + 10;
          this.addItems(this.startpostion, this.endpostion);
        }
      }, 3000);
    }
    // console.log("scrolled down!!", ev);

    // // add another 20 items
    // const start = this.sum;
    // // this.sum += 20;
    // this.appendItems(start, this.sum);

    // this.direction = "down";
  }

  starResponders(flag) {
    window.location.href = '/decideItStars/' + window.btoa(flag);
  }
}
