import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '.././globals';
import { HttpClient,HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class UserDashboardService {

  constructor(private http: HttpClient, private globals: Globals, private router: Router) { }
  getQuestionCommunityStarData(filter,flag,search)
  {
    //debugger
    var entity = {
      'filter' : filter,
      'flag' : flag,
      'search':search
    }
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.globals.baseAPIUrl + 'getHomeData',entity)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }
}
