import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '.././globals';
import { HttpClient,HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class CommunityQuestionService {

  constructor(private http: HttpClient, private globals: Globals, private router: Router) { }
  
  getCommunityQuestions(id,flag) {
    //debugger
    var entity = {
      'id' : id,
      'flag' : flag
    }
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.globals.baseAPIUrl + 'communityQuestions',entity)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getTopQuestion(id,flag,user_id)
  {
    //debugger
    var entity = {
      'community_id' : '',
      'flag' : flag,
      'user_id':user_id,
      'post_by_admin':id
    }
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.globals.baseAPIUrl + 'getTopQuestionData',entity)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }
}
