<div class="home_wrap fixed-head" id="contact_page">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="contact_us_wrap">
                    <h2 class="decideit_head">We Welcome<span> Your Observations</span></h2>
                    <p>If you believe you’ve discovered an error or something vital that’s missing in EYEXchange Connect, let us know! We’re open to learning and adapting to support our community. Please share your thoughts with us as we build a database of user feedback that will help fuel our future improvements. Just leave your comments below.</p>
                    <form #contactUsForm="ngForm" (ngSubmit)="contactUs(contactUsForm)">
                        <div class="row mt-5">
                            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                *ngIf="globals.authData == undefined">
                                <div class="form-group">
                                    <label><span class="mendatory_feild">*</span>
                                        {{globals.commonTranslationText.inquirePage.form.firstName.label}}</label>
                                    <input kendoTextBox class="form-control" type="text" required #first_name="ngModel"
                                        [(ngModel)]="contactUsEntity.first_name" name="first_name" id="first_name"
                                        pattern="^[a-zA-Z/]{2,}[\sa-zA-Z/]*"
                                        [ngClass]="{error: (first_name.dirty && first_name.invalid) || (first_name.pristine && submitted) || errorEntity.first_name}"
                                        maxlength="100">
                                    <span class="error_class"
                                        *ngIf="(first_name.dirty && first_name.invalid) || (first_name.pristine && submitted) || errorEntity.first_name">
                                        <span *ngIf="first_name.errors && first_name.errors.required">
                                            {{globals.commonTranslationText.inquirePage.form.firstName.required}}
                                        </span>
                                        <span *ngIf="first_name.errors && first_name.errors.pattern">
                                            {{globals.commonTranslationText.inquirePage.form.firstName.pattern}}
                                        </span>
                                        <span *ngIf="errorEntity.first_name != ''">
                                            {{errorEntity.first_name}}
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                *ngIf="globals.authData == undefined">
                                <div class="form-group">
                                    <label><span class="mendatory_feild">*</span>
                                        {{globals.commonTranslationText.inquirePage.form.lastName.label}}</label>
                                    <input kendoTextBox class="form-control" type="text" required #last_name="ngModel"
                                        [(ngModel)]="contactUsEntity.last_name" name="last_name" id="last_name"
                                        pattern="^[a-zA-Z/]{2,}[\sa-zA-Z/]*"
                                        [ngClass]="{error: (last_name.dirty && last_name.invalid) || (last_name.pristine && submitted ) || errorEntity.last_name}"
                                        maxlength="100">
                                    <span class="error_class"
                                        *ngIf="(last_name.dirty && last_name.invalid) || (last_name.pristine && submitted) ||errorEntity.last_name">
                                        <span *ngIf="last_name.errors && last_name.errors.required">
                                            {{globals.commonTranslationText.inquirePage.form.lastName.required}}
                                        </span>
                                        <span *ngIf="last_name.errors && last_name.errors.pattern">
                                            {{globals.commonTranslationText.inquirePage.form.lastName.pattern}}
                                        </span>
                                        <span *ngIf="errorEntity.last_name != ''">
                                            {{errorEntity.last_name}}
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                *ngIf="globals.authData == undefined || globals.authData.email == null || globals.authData.email == ''">
                                <div class="form-group">
                                    <label><span class="mendatory_feild">*</span>
                                        {{globals.commonTranslationText.inquirePage.form.emailAddress.label}}</label>
                                    <input kendoTextBox type="email" class="form-control"
                                        pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}" #email="ngModel"
                                        [(ngModel)]="contactUsEntity.email" name="email" maxlength="100"
                                        [ngClass]="{error: (email.dirty && email.invalid) || (email.pristine && submitted)}"
                                        required id="email" />
                                    <span class="error_class"
                                        *ngIf="(email.dirty && email.invalid) || (email.pristine && submitted) || errorEntity.email">
                                        <span *ngIf="email.errors && email.errors.required">
                                            {{globals.commonTranslationText.inquirePage.form.emailAddress.required}}
                                        </span>
                                        <span *ngIf="email.errors && email.errors.pattern">
                                            {{globals.commonTranslationText.inquirePage.form.emailAddress.pattern}}
                                        </span>
                                        <span *ngIf="errorEntity.email">
                                            <span>{{ errorEntity.email }}</span>
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label><span class="mendatory_feild">*</span>
                                        {{globals.commonTranslationText.inquirePage.form.message.label}}</label>
                                    <textarea kendoTextArea rows="3" cols="60" id="message" name="message"
                                        #message="ngModel" (keyup)="countChar()" required
                                        [(ngModel)]="contactUsEntity.message"
                                        [ngClass]="{error: (message.dirty && message.invalid) || (message.pristine && submitted && message.errors && message.errors.required) || errorEntity.message}"></textarea>
                                    <div id="charNum"></div><span id="limit">500/500</span>
                                    <span class="error_class"
                                        *ngIf="(message.dirty && message.invalid) || (message.pristine && submitted) || errorEntity.message">
                                        <span *ngIf=" message.errors && message.errors.required">
                                            {{globals.commonTranslationText.inquirePage.form.message.required}}
                                        </span>
                                        <span *ngIf="errorEntity.message != ''">
                                            {{errorEntity.message}}
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <button kendoButton type="submit" [disabled]="btn_disable"
                                class="mt-4 theme_btn theme_btn d-flex justify-content-center mx-auto">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>