<div class="about_wrap inner_padding fixed-head">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="about_intro">
                    <h2>About <span>EYEXchange Connect</span></h2>
                    <p>Make DecideIt.net your easy and convenient destination for group decisions and discussion.</p>
                    <p>With DecideIt, it takes less than 1 minute to distribute a thread to friends, family,
                        co-workers, customers or the entire DecideIt community. Whether across your company, Facebook,
                        LinkedIn, or Twitter – DecideIt.net simplifies decision making and delivers fast results.</p>
                    <p>No more long email chains or playing phone tag to reach a simple decision!</p>
                    <p>DecideIt streamlines that process, while providing ample opportunity for useful feedback. Whether
                        from your desktop or any web-enabled mobile device, a quick decision is always within reach.
                        It’s so easy that you’ll wish that you had thought of it!</p>
                    <span class="additional_head">So, DecideIt:</span>
                    <ul>
                        <li>
                            Try free <a href="">DecideIt.net</a> now
                        </li>
                        <li>
                            Keep doing things the old way
                        </li>
                    </ul>
                    <strong>The Choice is Your...</strong>
                    <p>We welcome your comments! Please <a href="mailto:jcahpo@jcahpo.org">email us</a> and tell us what you think DecideIt. After all, we built it for you!</p>
                </div>
            </div>
        </div>
    </div>
</div>