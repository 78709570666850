<!-- <div class="loader_new" *ngIf="globals.isLoading">
  <div class="img_loader"><img src="../assets/images/loader.gif" alt="" /></div>
</div> -->


<div class="loader_new" *ngIf="globals.isLoading">
  <div class="img_loader">
    <svg viewBox="0 0 600 300">
      <!-- Symbol -->
      <symbol id="s-text">
        <text text-anchor="middle" x="50%" y="50%" dy=".35em">
          IJCAHPO
        </text>
      </symbol>
      <!-- Duplicate symbols -->
      <use xlink:href="#s-text" class="text"></use>
      <use xlink:href="#s-text" class="text"></use>
      <use xlink:href="#s-text" class="text"></use>
      <use xlink:href="#s-text" class="text"></use>
      <use xlink:href="#s-text" class="text"></use>
    </svg>
  </div>
</div>

<div class="loader_new" *ngIf="globals.postQuestionLoading">
  <div class="img_loader">
    <svg viewBox="0 0 600 300">
      <!-- Symbol -->
      <symbol id="s-text">
        <text text-anchor="middle" x="50%" y="50%" dy=".35em">
          IJCAHPO
        </text>
      </symbol> 

      <!-- Duplicate symbols -->
      <use xlink:href="#s-text" class="text"></use>
      <use xlink:href="#s-text" class="text"></use>
      <use xlink:href="#s-text" class="text"></use>
      <use xlink:href="#s-text" class="text"></use>
      <use xlink:href="#s-text" class="text"></use>
    </svg> 
  </div>  
  <span class="loader_alt_text"> {{globals.postQuestionText}}…</span>
</div> 

<app-header></app-header>
<router-outlet></router-outlet>
<app-sidebar *ngIf="globals.authData!=undefined && this.globals.isUser == '1'"></app-sidebar>
<app-footer></app-footer>