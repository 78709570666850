import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Location } from "@angular/common";
import { Globals } from "../globals";
import { Router } from "@angular/router";
declare var $: any, PerfectScrollbar, Swal;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent implements OnInit {

  constructor(location: Location, private router: Router, public globals: Globals) {
    console.log(window.location.pathname);
    console.log(this.router.routerState.snapshot.url);
  }
  currentLink;

  ngOnInit(): void {
    //debugger
    this.currentLink = window.location.pathname;
    console.log(this.globals.currentLink);
    console.log(location.href);
    // new PerfectScrollbar('.sidebar_box');
    $('.sidebar_toggle button').click(function () {
      $('.sidebar_toggle').toggleClass("active");
      $('.sidebar_wrap').toggleClass("small_menu");
      $('.main_block').toggleClass("large_content");
      $('header').toggleClass("active_menu_right_block");
      $('footer').toggleClass("active_menu_right_block");
    });

    setTimeout(function () {
      new PerfectScrollbar('.sidebar_box');
    }, 200);
  }

  redirect(path) {
    window.location.href = path;
  }
  closecollapse() {
    $(".dropdown_menu").addClass("collapsed");
    $(".dropdown_menu").attr("aria-expanded", "false");
    $(".collapse").removeClass("in");
  }

}
