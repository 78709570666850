import {
  Component,
  OnInit,
  ElementRef,
  ViewEncapsulation,
} from '@angular/core';
import { Globals } from '.././globals';
import { Router } from '@angular/router';
import { ActivatedRoute, Params } from '@angular/router';
import { HomeService } from '../services/home.service';
import { CookieService } from 'ngx-cookie-service';
import { CommunityQuestionService } from '../services/community-question.service';
import { array } from '@amcharts/amcharts4/core';
declare var $: any, PerfectScrollbar, Swal;

@Component({
  selector: 'app-post-question',
  templateUrl: './post-question.component.html',
  styleUrls: ['./post-question.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class PostQuestionComponent implements OnInit {
  imageSize: any;
  videoSize: any;
  OptionsList;
  RankList;
  defaultEntity;
  groupList;
  communityList;
  answerList;
  questionSendOptionsList;
  postQuestionEntity;
  expandSteps;
  questionSubmitted;
  answerFormatSubmitted;
  answeFormatTab;
  optionSubmitted;
  postAsSubmitted;
  contactSubmitted;
  communitySubmitted;
  post_des_valid;
  errorEntity;
  addGroupPopup;
  addGroupEntity;
  group_submitted;
  contact_details;
  questiontypeid;
  recentCommunityList;
  sendOptionsList;
  filterCommunityList;
  communityEntity;
  questionId;
  contactDetailsValidation;
  communityHide;
  communityEmails;
  invitationLimit;
  authData: any;
  userRoleId: any;
  mydata;
  mydataToUpload;
  fileUploadLength;
  files;
  filesData;
  uploadImageData;
  displayImage_Video;
  imageVideo;
  checkImgSizeKB;
  checkVideoKB;
  image_video;
  push_Image_video;
  UserCommunity;
  className = 'myClass';
  errorTitle;
  maxxxxxxLength;
  len;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public globals: Globals,
    private HomeService: HomeService,
    private elem: ElementRef,
    private CommunityQuestionService: CommunityQuestionService,
    private cookieService: CookieService
  ) {}
  
  public mediaInfoOpened = false;
  public mediaInfoClose(): void {
    this.mediaInfoOpened = false;
  }
  public mediaInfoOpen(): void {
    this.mediaInfoOpened = true;
  }

  public listItems: Array<{ text: string; value: number }> = [
    { text: 'Small', value: 1 },
    { text: 'Medium', value: 2 },
    { text: 'Large', value: 3 },
  ];
  public value: any = [];

  public isChecked = false;
  public get isIndet() {
    return (
      this.value.length !== 0 && this.value.length !== this.listItems.length
    );
  }

  public isItemSelected(item) {
    return this.value.some((x) => x === item.id);
  }

  public onValueChange(selected) {
    if (this.globals.authData == undefined) {
      for (var i = 0; i < this.communityList.length; i++) {
        if (
          this.communityList[i].id != undefined &&
          this.communityList[i].id != selected &&
          selected.length == 1
        ) {
          this.communityList[i].itemClass = 'parent';
        } else if (this.communityList[i].id != undefined) {
          this.communityList[i].itemClass = 'second-level';
        }
      }
      console.log(this.communityList);
    } else {
      var index;
      let array = [];
      var numofcommunities =
        this.postQuestionEntity.questionType == 0
          ? this.globals.authData.plan.number_of_communities
          : this.globals.authData.plan.community_question_number_of_communities;
      if (selected.length >= numofcommunities) {
        this.communityList.forEach(function (menu, key) {
          index = selected.findIndex((selected) => selected === menu.id);
          if (index != -1) {
            var ob = {
              id: menu.id,
              itemClass: 'second-level',
              name: menu.name,
            };
            array[key] = ob;
          } else if (menu.id == undefined) {
            var ob1 = {
              itemClass: 'first-level',
              name: menu.name,
            };
            array[key] = ob1;
          } else {
            var ob = {
              id: menu.id,
              itemClass: 'parent',
              name: menu.name,
            };
            array[key] = ob;
          }
        });
        this.communityList = array;
      } else {
        for (var i = 0; i < this.communityList.length; i++) {
          if (this.communityList[i].id != undefined) {
            this.communityList[i].itemClass = 'second-level';
          }
        }
      }
      this.errorEntity.community_id = false;
      console.log(array);
    }

    this.postQuestionEntity.community_id = selected;
    this.communityChange(this.postQuestionEntity.community_id);
    this.isChecked = this.value.length === this.communityList.length;
  }
  public data;
  public source = [
    { name: 'Sports', itemClass: 'first-level' },
    { name: 'Badminton', itemClass: 'second-level' },
    { name: 'Cricket', itemClass: 'second-level' },
    { name: 'Tennis', itemClass: 'second-level' },
    { name: 'Music', itemClass: 'first-level' },
    { name: 'Disco', itemClass: 'second-level' },
    { name: 'Folk', itemClass: 'second-level' },
  ];

  public itemDisabled(itemArgs: { dataItem: any; index: number }) {
    const dataItem = itemArgs.dataItem;
    if (
      dataItem.itemClass === 'parent' ||
      dataItem.itemClass === 'first-level'
    ) {
      return true;
    }
  }
  async ngOnInit() {
    $(document).click(function (event) {
      $('body').find('.second-progress').addClass('sucess');
    });
    setTimeout(() => {
      var euButton = $('.k-editor .k-editor-toolbar span').find( ":contains('Media')").parents('.k-button-solid-base');
      euButton.addClass('editor_upload');
      var euText = euButton.text();
      euButton.attr("title", euText);
      //alert(euText);
      euButton.find('span.k-button-text').html( "<label for='fileToUpload'>" + euText + "</lable>" );
    }, 2500);
    // $(document).click(function (event) {
    //   var clickover = $(event.target);
    //   if (clickover.hasClass("back_thread_btn")) {
    //     alert("1")
    //     $('body').find('.second-progress').addClass('sucess');
    //   }
    // });

    // $('body').on('click', '#info_toggle_newpost', function () {
    //   $(this).toggleClass('info_open');
    //   $('.info_message').slideToggle();
    // });
    if (this.globals.isUser == '1') {
      $('#postQuestion_page').addClass(
        'col-md-10 col-sm-10 offset-md-2 main_block'
      );
    }
    // $(window).on('load resize scroll', function () {
    //   var f = $('footer');
    //   f.css({ position: 'static' });
    //   if (f.offset().top + f.height() < $(window).height()) {
    //     f.css({ position: 'fixed', bottom: '0', width: '100%' });
    //   }
    // });
    this.data = this.source.slice();

    this.groupList = [];
    this.communityList = [];
    this.filterCommunityList = [];
    this.answerList = [];
    this.postQuestionEntity = {};
    this.errorEntity = {};
    this.expandSteps = false;
    this.addGroupPopup = false;
    this.addGroupEntity = {};
    this.contact_details = [];
    this.questionSendOptionsList = [];
    this.recentCommunityList = [];
    this.sendOptionsList = [];
    this.communityEntity = {};
    this.defaultEntity = {};
    this.communityEmails = [];
    this.mydata = [];
    this.files = [];
    this.filesData = [];
    this.uploadImageData = [];
    this.displayImage_Video = [];
    this.imageVideo = [];
    this.mydataToUpload = [];
    this.push_Image_video = [];
    this.UserCommunity = [];
    this.invitationLimit = this.globals.authData.plan.number_of_invitations;
    if (this.globals.authData != undefined) {
      this.authData = JSON.parse(localStorage.getItem('authData'));
      var id = this.globals.authData.id;
    } else {
      var id = null;
    }
    this.globals.isLoading = true;
    await this.getImageVideoExtensions();

    this.HomeService.getDefaultData(id).then(
      (data) => {
        this.globals.isLoading = false;
        this.defaultEntity = data['data'];
        // this.groupList = data['data']['groups'];
        this.questionSendOptionsList = data['data']['questionSendOptions'];
        this.sendOptionsList = data['data']['questionSendOptions'];
        this.postQuestionEntity.community_id = '';
        this.postQuestionEntity.questionType = 1;

        if (this.authData.roles_id == 1) {
          this.postQuestionEntity.postas = 1;
        } else {
          this.postQuestionEntity.postas = 0;
        }
        console.log(this.defaultEntity);
        if (
          this.postQuestionEntity.question_type_id == '' ||
          this.postQuestionEntity.question_type_id == undefined
        ) {
          this.postQuestionEntity.question_type_id = '';
        }

        var answerType: any;
        answerType = data['data']['questionTypes'];
        // var answerTypeSelect = {
        //   id: '',
        //   display_text: 'Select Your Response Format',
        //   value: '',
        // };
        // this.answerList.push(answerTypeSelect);
        this.answerList = [...this.answerList, ...answerType];
        this.postQuestionEntity.question_type_id =
          this.globals.configurationText.questionType.expressYourself;
        var community: any;
        community = data['data']['categories'];
        var communitySelect = {
          id: '',
          name: 'Select Topic',
          value: '',
        };
        this.communityList.push(communitySelect);
        this.communityList = [...this.communityList, ...community];
        this.communityList = data['data']['categories'];
        if (
          this.cookieService.check('post_question') &&
          this.postQuestionEntity.community_id != ''
        ) {
          for (var a = 0; a < this.communityList.length; a++) {
            if (
              this.postQuestionEntity.community_id == this.communityList[a].id
            ) {
              this.isItemSelected(this.communityList[a]);
            }
          }
        }
        this.filterCommunityList = this.communityList;
        for (var i = 0; i < this.answerList.length; i++) {
          if (
            this.answerList[i].id ==
              this.globals.configurationText.questionType.pickList ||
            this.answerList[i].id ==
              this.globals.configurationText.questionType.multiPickList
          ) {
            this.answerList[i].value = 'assets/images/list.png';
          } else if (
            this.answerList[i].id ==
            this.globals.configurationText.questionType.yesOrNo
          ) {
            this.answerList[i].value = 'assets/images/toggle.png';
          } else if (
            this.answerList[i].id ==
            this.globals.configurationText.questionType.rankedList
          ) {
            this.answerList[i].value = 'assets/images/choices.png';
          } else if (
            this.answerList[i].id ==
            this.globals.configurationText.questionType.expressYourself
          ) {
            this.answerList[i].value = 'assets/images/chat.png';
          }
        }
        this.answerList = this.answerList.filter((answer) => {
          return (
            answer.id !=
            this.globals.configurationText.questionType.multiPickList
          );
        });
        this.userRoleId = this.authData.roles_id;
      },
      (error) => {
        this.globals.isLoading = false;
      }
    );
    this.questionId = this.route.snapshot.paramMap.get('id');
    if (this.questionId) {
      this.globals.isLoading = true;
      setTimeout(() => {
        this.HomeService.getDraftQuestionData(this.questionId).then(
          (data) => {
            this.globals.isLoading = false;
            // $('#xyz').hide();
            this.postQuestionEntity = data['data'];
            // alert(this.postQuestionEntity.id)
            this.imageVideo = data['data'].image_video_url;
            // const reader = new FileReader();
            // const arrayString = localStorage.getItem('anshuman');
            this.postQuestionEntity.image_video = JSON.parse(this.imageVideo);
            //  console.log(languages)
            for (const file of this.postQuestionEntity.image_video) {
              if (file.type == 'img') {
                this.mydata.push({
                  url:
                    this.globals.profileImgeapiUrl +
                    '/question/' +
                    this.postQuestionEntity.id +
                    '/' +
                    file.name,
                  type: 'img',
                  isUploaded: true,
                  name: file.name,
                });
                // console.log(this.mydata);
              } else if (file.type == 'video') {
                this.mydata.push({
                  url:
                    this.globals.profileImgeapiUrl +
                    '/question/' +
                    this.postQuestionEntity.id +
                    '/' +
                    file.name,
                  type: 'video',
                  isUploaded: true,
                  name: file.name,
                });
              } else {
                alert('Invalid');
              }
              // reader.readAsDataURL(file);
            }
            console.log(this.mydata);

            var imageArrayExtensions =
              this.imagevideoExtensionEntity['ImageExtensions'];
            var videoArrayExtensions =
              this.imagevideoExtensionEntity['VideoExtensions'];

            if (
              this.postQuestionEntity.file_extention != undefined &&
              this.postQuestionEntity.file_extention != ''
            ) {
              if (
                $.inArray(
                  this.postQuestionEntity.file_extention.toLowerCase(),
                  imageArrayExtensions
                ) != -1
              ) {
                this.postQuestionEntity.imageExtension = true;
              } else if (
                $.inArray(
                  this.postQuestionEntity.file_extention.toLowerCase(),
                  videoArrayExtensions
                ) != -1
              ) {
                this.postQuestionEntity.videoExtension = true;
              }
            }

            this.postQuestionEntity.contact_details =
              this.postQuestionEntity.invitee;

            this.countChar();
            if (
              this.postQuestionEntity.question_type_id ==
              this.globals.configurationText.questionType.multiPickList
            ) {
              $('#multiSelection').attr('checked', true);
              this.questiontypeid =
                this.globals.configurationText.questionType.pickList;
              this.postQuestionEntity.question_type_id =
                this.globals.configurationText.questionType.pickList;
              this.postQuestionEntity.question_option =
                this.postQuestionEntity.option;
              this.postQuestionEntity.question_type_multiSelect_id =
                this.globals.configurationText.questionType.multiPickList;
            } else {
              this.questiontypeid = this.postQuestionEntity.question_type_id;
              if (this.postQuestionEntity.option.length != 0) {
                this.postQuestionEntity.question_option =
                  this.postQuestionEntity.option;
              }
            }
            this.answerTypeChange(this.postQuestionEntity.question_type_id);
            // this.questionSendOptionsList = data['data']['questionSendOptions'];
            // this.sendOptionsList = data['data']['questionSendOptions'];

            setTimeout(() => {
              if (
                this.postQuestionEntity.community != null &&
                this.postQuestionEntity.community.length > 0
              ) {
                this.postQuestionEntity.questionType = 1;

                this.postQuestionEntity.community_id =
                  this.postQuestionEntity.community.map((s) => s.community_id);

                this.value = this.postQuestionEntity.community_id;
                this.onValueChange(this.postQuestionEntity.community_id);
              } else {
                this.postQuestionEntity.community_id = '';
                this.postQuestionEntity.questionType = 0;
              }
              this.postQuestionEntity.groupid = '';
            }, 100);

            if (
              this.postQuestionEntity.question_type_id == '' ||
              this.postQuestionEntity.question_type_id == undefined ||
              this.postQuestionEntity.question_type_id == '0'
            ) {
              this.postQuestionEntity.question_type_id = '';
            }
            console.log(this.postQuestionEntity.question_type_id);
          },
          (error) => {
            this.globals.isLoading = false;
            console.log(error);
          }
        );
      }, 100);
    }
    this.HomeService.getImageVideoSize().then((data) => {
      // console.log(data['data'])
      this.imageSize = data['data'].ImageVideo[0].value;
      this.videoSize = data['data'].ImageVideo[1].value;
    });
  }

  AddOption(index) {
    var item = { OptionValue: '', IsCorrectOption: 0, IsActive: 0 };
    this.OptionsList.push(item);
  }
  RemoveOption(item) {
    var index = this.OptionsList.indexOf(item);
    this.OptionsList.splice(index, 1);
  }

  AddOptionrank(index) {
    var item = { OptionValue: '', IsCorrectOption: 0, IsActive: 0 };
    this.RankList.push(item);
  }
  RemoveOptionrank(item) {
    var index = this.RankList.indexOf(item);
    this.RankList.splice(index, 1);
  }

  answerTypeChange(value) {
    // this.selectedValue = value;
    this.optionSubmitted = false;
    if (
      this.postQuestionEntity.question_type_id ==
      this.globals.configurationText.questionType.pickList
    ) {
      var item = { OptionValue: '', IsCorrectOption: 0, IsActive: 0 };
      var item1 = { OptionValue: '', IsCorrectOption: 0, IsActive: 0 };
      var item2 = { OptionValue: '', IsCorrectOption: 0, IsActive: 0 };
      this.OptionsList = [];
      if (
        this.postQuestionEntity.question_option != '' &&
        this.postQuestionEntity.question_option != undefined &&
        this.questiontypeid ==
          this.globals.configurationText.questionType.pickList
      ) {
        // this.OptionsList = this.postQuestionEntity.question_option;
        this.OptionsList = this.postQuestionEntity.question_option.map((s) => ({
          OptionValue: s.value,
        }));
        if (this.OptionsList.length == 1) {
          this.OptionsList.push(item);
          this.OptionsList.push(item1);
        }
        if (this.OptionsList.length == 2) {
          this.OptionsList.push(item);
        }

        console.log(
          this.postQuestionEntity.question_option.map((s) => ({
            OptionValue: s.value,
          }))
        );
        // this.OptionsList.push({ OptionValue: ''})
      } else {
        this.OptionsList.push(item);
        this.OptionsList.push(item1);
        this.OptionsList.push(item2);
      }
    } else if (
      this.postQuestionEntity.question_type_id ==
      this.globals.configurationText.questionType.rankedList
    ) {
      var rankListOption = { OptionValue: '', IsCorrectOption: 0, IsActive: 0 };
      var rankListOption1 = {
        OptionValue: '',
        IsCorrectOption: 0,
        IsActive: 0,
      };
      var rankListOption2 = {
        OptionValue: '',
        IsCorrectOption: 0,
        IsActive: 0,
      };
      this.RankList = [];
      if (
        this.postQuestionEntity.question_option != '' &&
        this.postQuestionEntity.question_option != undefined &&
        this.questiontypeid ==
          this.globals.configurationText.questionType.rankedList
      ) {
        this.RankList = this.postQuestionEntity.question_option.map((s) => ({
          OptionValue: s.value,
        }));
        if (this.RankList.length == 1) {
          this.RankList.push(rankListOption);
          this.RankList.push(rankListOption1);
        }
        if (this.RankList.length == 2) {
          this.RankList.push(rankListOption);
        }
      } else {
        this.RankList.push(rankListOption);
        this.RankList.push(rankListOption1);
        this.RankList.push(rankListOption2);
      }
    }
  }
  countChar() {
    if (this.questionId == '') {
      var val = $('#question_text').val();
    } else {
      var val = this.postQuestionEntity.question_text;
    }
    var text = this.postQuestionEntity.question_text.replace(/<[^>]+>/g, '');
    this.len = text.length;
    console.log(this.len);
    if (this.len > 1000) {
      // val.value = val.substring(0, 255);
      // $('#question_text').val(val.substring(0, 1000));
      this.maxxxxxxLength = 'You are not allowed to enter more than 1000 characters.';
    } else {
      this.maxxxxxxLength = '';
      this.errorTitle = '';
      $('#limit').hide();
      $('#charNum').text(1000 - this.len + ' /1000');
    }
    if (
      this.postQuestionEntity.question_text != '' &&
      this.postQuestionEntity.question_text != undefined
    ) {
      this.expandSteps = true;
    }
    // else{
    //   this.expandSteps = false;
    // }
  }
  imagevideoExtensionEntity;
  async getImageVideoExtensions() {
    await this.HomeService.getImageVideoExtensions().then(
      (data) => {
        console.log(data);
        this.imagevideoExtensionEntity = data['data'];
      },
      (error) => {
        console.log(error);
      }
    );
  }
  imagechange() {
    var input;
    input = this.elem.nativeElement.querySelector('#fileToUpload');
    const extensionsImage = [
      'jpg',
      'jpeg',
      'png',
      'JPEG',
      'JPG',
      'PNG',
      'heic',
      'HEIC',
      'BMP',
      'bmp',
      'webp',
      'WEBP',
    ];
    const extensionsVideo = [
      'mp4',
      'MP4',
      'MKV',
      'mkv',
      'WEBM',
      'webm',
      'avi',
      'mov',
      'mp3',
      'guv',
    ];

    this.checkImgSizeKB = this.imageSize * 1024 * 1024;
    this.checkVideoKB = this.videoSize * 1024 * 1024;

    const files = input.files;
    const maxLength = this.mydata.length + input.files.length;
    if ($('#preview').is(':visible') == true) {
      $('#preview').hide();
      $('#xyz').show();
      this.mydata = [];
    }
    // var fileUploadType = input.files[0].type.split('/');
    // this.files = input.files.length;
    // this.filesData = input.files;
    if (files) {
      if (maxLength > 3) {
        Swal.fire({
          type: this.globals.configurationText.sweetAlertTypeIconError,
          icon: this.globals.configurationText.sweetAlertTypeIconError,
          customClass: this.globals.configurationText.sweetAlertClassError,
          toast: true,
          title: 'You are allowed to upload up to 3 media.',
          animation: true,
          position: this.globals.configurationText.sweetAlertPosition,
          showConfirmButton: false,
          timer: this.globals.configurationText.sweetAlertTimer,
          timerProgressBar: true,
          showCancelButton: true,
          cancelButtonText: 'x',
        });
        this.postQuestionEntity.image_video = this.mydata;
        return;
      }
      //  this.mydataToUpload = [];
      // for (let i = 0; i < files.length; i++) {
      //   const extension = files[i].name.split('.').pop();
      //   const isImage = extensionsImage.includes(extension);
      //   const isVideo = extensionsVideo.includes(extension);

      // }

      for (const file of files) {
        const extension = file.name.split('.').pop();
        const isImage = extensionsImage.includes(extension);
        const isVideo = extensionsVideo.includes(extension);

        this.mydataToUpload = [];
        const isImageSizeValid = isImage && file.size < this.checkImgSizeKB;
        const isVideoSizeValid = isVideo && file.size < this.checkVideoKB;
        const filename = Date.now() + '_' + file.name.replace(/ /g, '_');
        if (isImageSizeValid || isVideoSizeValid) {
          this.mydataToUpload.push({
            fileUpload: file,
            name: filename,
          });
        }
        this.uploadImageVideo();
        if (isImage || isVideo) {
          const fileType = isImage ? 'img' : 'video';

          const reader = new FileReader();
          reader.onload = (e) => {
            this.mydata.push({
              url: e.target.result,
              type: fileType,
              size: file.size,
              isValid:
                (fileType == 'img' && file.size < this.checkImgSizeKB) ||
                (fileType == 'video' && file.size < this.checkVideoKB),
              name: filename,
            });
            console.log(this.mydata);

            this.fileUploadLength = this.mydata.length;
          };

          reader.readAsDataURL(file);
          console.log(this.mydata);
        } else {
          Swal.fire({
            type: this.globals.configurationText.sweetAlertTypeIconError,
            icon: this.globals.configurationText.sweetAlertTypeIconError,
            customClass: this.globals.configurationText.sweetAlertClassError,
            toast: true,
            title:
              'You can able to upload a file Extension with ' +
              extensionsImage +
              extensionsVideo,
            animation: true,
            position: this.globals.configurationText.sweetAlertPosition,
            showConfirmButton: false,
            timer: this.globals.configurationText.sweetAlertTimer,
            timerProgressBar: true,
            showCancelButton: true,
            cancelButtonText: 'x',
          });
          this.postQuestionEntity.image_video = this.mydata;
        }
      }
    }
  }
  removeImage(idx: number, list: any) {
    console.log(this.mydata);
    console.log(this.push_Image_video);
    console.log(this.postQuestionEntity.image_video);
    // list = '1694746460690_img11.jpg'
    if (this.questionId) {
      if (this.mydata[idx].isUploaded) {
        var removeFile = this.postQuestionEntity.image_video.filter(function (
          name
        ) {
          return name.name == list;
        });
        var path = 'question/' + this.postQuestionEntity.id + '/';
        var fd = new FormData();
        fd.append('name', removeFile[0].name);
        fd.append('path', path);
        fd.append('question_id', this.questionId);
        this.HomeService.removeImageVideoDraft(fd).then((data) => {
          console.log(data);
          this.mydata = this.mydata.filter((u, index) => index !== idx);
          var push_Image_video = this.postQuestionEntity.image_video.filter(
            (item) => item.name !== list
          );
          this.postQuestionEntity.image_video = push_Image_video;
          console.log(this.postQuestionEntity.image_video);
        });
      } else if (this.mydata[idx].isValid) {
        var removeFile = this.push_Image_video.filter(function (name) {
          return name.name == list;
        });

        var fd = new FormData();
        fd.append('name', removeFile[0].name);
        fd.append('path', removeFile[0].path);
        this.HomeService.removeImageVideo(fd).then((data) => {
          console.log(data);
          this.mydata = this.mydata.filter((u, index) => index !== idx);
          this.push_Image_video = this.push_Image_video.filter(
            (item) => item.name !== list
          );
          this.postQuestionEntity.image_video = this.push_Image_video;
          console.log(this.postQuestionEntity.image_video);
        });
      } else {
        this.mydata = this.mydata.filter((u, index) => index !== idx);
      }
    } else {
      if (this.mydata[idx].isValid == true) {
        var removeFile = this.push_Image_video.filter(function (name) {
          return name.name == list;
        });

        var fd = new FormData();
        fd.append('name', removeFile[0].name);
        fd.append('path', removeFile[0].path);
        this.HomeService.removeImageVideo(fd).then((data) => {
          console.log(data);
          this.mydata = this.mydata.filter((u, index) => index !== idx);
          this.push_Image_video = this.push_Image_video.filter(
            (item) => item.name !== list
          );
          this.postQuestionEntity.image_video = this.push_Image_video;
          console.log(this.postQuestionEntity.image_video);
        });
      } else {
        this.mydata = this.mydata.filter((u, index) => index !== idx);
      }
    }
  }

  uploadImageVideo() {
    // this.globals.isLoading = true;
    if (this.mydataToUpload.length > 0) {
      var fd = new FormData();
      // this.push_Image_video = [];
      for (let i = 0; i < this.mydataToUpload.length; i++) {
        console.log(this.filesData[i]);
        console.log(this.mydataToUpload[i].name);
        var Images = this.mydataToUpload[i].name;
        this.uploadImageData.push(Images);
        fd.append(
          'image_video_file[]',
          this.mydataToUpload[i].fileUpload,
          Images
        );
        fd.append('image_video', Images);
      }
      this.HomeService.fileUpload(fd).then(
        (data) => {
          console.log(data);
          this.globals.isLoading = false;

          // this.postQuestionEntity.image_video_file = input.files[0];
          // $('#video_tab').hide();
          // $('#img').show();
          // this.push_Image_video = [];
          this.postQuestionEntity.video = '';
          this.image_video = data;
          // this.postQuestionEntity.image_video = data['Files'];
          // this.postQuestionEntity.image_video =[]
          for (let i = 0; i < this.image_video.length; i++) {
            this.push_Image_video.push({
              name: this.image_video[i].name,
              path: this.image_video[i].path,
              type: this.image_video[i].type,
            });
          }
          // var newImg = JSON.stringify(this.push_Image_video)
          // this.postQuestionEntity.image_video = this.postQuestionEntity.image_video_url.concat(newImg)
          this.postQuestionEntity.image_video = this.push_Image_video;
          console.log(this.postQuestionEntity.image_video);

          console.log('Upload');
          this.startProgress(
            this.postQuestionEntity.image_video,
            this.postQuestionEntity.image_video.length - 1
          );
        },
        (error) => {
          this.globals.isLoading = false;
          console.log(error);
          if (error.error.code == 422) {
            Swal.fire({
              type: this.globals.configurationText.sweetAlertTypeIconError,
              icon: this.globals.configurationText.sweetAlertTypeIconError,
              customClass: this.globals.configurationText.sweetAlertClassError,
              toast: true,
              title: error.error.message,
              animation: true,
              position: this.globals.configurationText.sweetAlertPosition,
              showConfirmButton: false,
              timer: this.globals.configurationText.sweetAlertTimer,
              timerProgressBar: true,
              showCancelButton: true,
              cancelButtonText: 'x',
            });
          }
        }
      );
    }
  }

  async startProgress(file, index) {
    if (file != null) {
      $('.second-progress').addClass('sucess');
    }
  }

  contactAdd() {
    // if(this.postQuestionEntity.contact_details != '' && this.postQuestionEntity.contact_details != undefined)
    // {
    //   this.contact_details.push(this.postQuestionEntity.contact_details);
    // }
    var b = [];
    b = this.postQuestionEntity.contact_details.split(',');
    var a = this.postQuestionEntity.contact_details.concat(
      b.filter(
        (seccondArrayItem) =>
          !this.postQuestionEntity.contact_details.includes(seccondArrayItem)
      )
    );
    this.postQuestionEntity.contact_details = '';
    $('#contact_details').val(a);
    this.postQuestionEntity.contact_details = a;
  }

  addGroupPopUp() {
    this.checkNumberofInvitations();
    this.checkvalidation();
    if (
      this.errorEntity.InvitationError == '' &&
      this.contactDetailsValidation == false &&
      this.unique_contact.length == 0
    ) {
      this.contactSubmitted = true;
      this.group_submitted = false;
      this.addGroupEntity = {};
      if (
        this.postQuestionEntity.contact_details != undefined &&
        this.postQuestionEntity.contact_details != ''
      ) {
        this.contactSubmitted = false;
        this.addGroupPopup = true;
      }
    }

    console.log(this.postQuestionEntity.contact_details);
  }
  closeaddGroupPopup() {
    this.addGroupPopup = false;
  }
  addGroup(addGroupForm) {
    this.group_submitted = true;
    if (addGroupForm.valid) {
      this.globals.isLoading = true;
      this.group_submitted = false;
      var users_details: any;
      users_details = this.postQuestionEntity.contact_details.filter(function (
        v
      ) {
        return $.trim(v) !== '';
      });
      this.addGroupEntity.group_details = {
        group_name: this.addGroupEntity.group_name,
        users_details: users_details,
      };
      console.log(this.addGroupEntity);
      this.HomeService.addGroup(this.addGroupEntity).then(
        (data) => {
          console.log(data);
          this.globals.isLoading = false;
          Swal.fire({
            type: this.globals.configurationText.sweetAlertTypeIconSuccess,
            icon: this.globals.configurationText.sweetAlertTypeIconSuccess,
            customClass: this.globals.configurationText.sweetAlertClassSuccess,
            toast: true,
            title:
              this.globals.commonTranslationText.home.form.alerts.addGroup
                .title,
            animation: true,
            position: this.globals.configurationText.sweetAlertPosition,
            showConfirmButton: false,
            timer: this.globals.configurationText.sweetAlertTimer,
            timerProgressBar: true,
            showCancelButton: true,
            cancelButtonText: 'x',
          });
          this.addGroupPopup = false;
        },
        (error) => {
          this.globals.isLoading = false;
          console.log(error);
          if (error.error.code == 422) {
            Swal.fire({
              type: this.globals.configurationText.sweetAlertTypeIconError,
              icon: this.globals.configurationText.sweetAlertTypeIconError,
              customClass: this.globals.configurationText.sweetAlertClassError,
              toast: true,
              title: error.error.message,
              animation: true,
              position: this.globals.configurationText.sweetAlertPosition,
              showConfirmButton: false,
              timer: this.globals.configurationText.sweetAlertTimer,
              timerProgressBar: true,
              showCancelButton: true,
              cancelButtonText: 'x',
            });
          }
        }
      );
    }
  }
  handleFilter(value) {
    var entity = this.filterCommunityList;
    this.communityList = entity.filter(
      (s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
  nextAnswerformat(postQuestionForm, tabstrip, QuestionTab, AnswerFormatTab) {
    this.questionSubmitted = true;
    this.answerFormatSubmitted = false;
    this.optionSubmitted = false;
    const isWhitespace =
      (
        this.postQuestionEntity.question_text.replace(/<[^>]+>/g, '') || ''
      ).trim().length === 0;
    if (postQuestionForm.valid) {
      if (this.len <= 1000) {
        if (isWhitespace) {
          // alert("No")
          this.errorTitle = 'Please enter a thread.';
          // Swal.fire({
          //   type: this.globals.configurationText.sweetAlertTypeIconError,
          //   icon: this.globals.configurationText.sweetAlertTypeIconError,
          //   customClass: this.globals.configurationText.sweetAlertClassError,
          //   toast: true,
          //   title: 'Please enter a thread.',
          //   animation: true,
          //   position: this.globals.configurationText.sweetAlertPosition,
          //   showConfirmButton: false,
          //   timer: this.globals.configurationText.sweetAlertTimer,
          //   timerProgressBar: true,
          //   showCancelButton: true,
          //   cancelButtonText: 'x',
          // });
        } else {
          this.errorTitle = '';
          if (
            $('#xyz').is(':visible') == true &&
            $('#error').is(':visible') == true
          ) {
            // Swal.fire({
            //   type: this.globals.configurationText.sweetAlertTypeIconError,
            //   icon: this.globals.configurationText.sweetAlertTypeIconError,
            //   customClass: this.globals.configurationText.sweetAlertClassError,
            //   toast: true,
            //   title: 'The size of some of the uploaded files is not valid.',
            //   animation: true,
            //   position: this.globals.configurationText.sweetAlertPosition,
            //   showConfirmButton: false,
            //   timer: this.globals.configurationText.sweetAlertTimer,
            //   timerProgressBar: true,
            //   showCancelButton: true,
            //   cancelButtonText: 'x',
            // });
          } else {
            this.questionSubmitted = false;
            this.answerFormatSubmitted = false;
            this.optionSubmitted = false;
            QuestionTab.disabled = true; // QuestionTab disable
            AnswerFormatTab.disabled = false; //AnswerFormatTab open
            tabstrip.selectTab(1);
          }
        }
      }
    }
  }
  backToQuestion(tabstrip, QuestionTab, AnswerFormatTab) {
    QuestionTab.disabled = false; // QuestionTab open
    AnswerFormatTab.disabled = true; //AnswerFormatTab disable
    setTimeout(() => {
      var euButton = $('.k-editor .k-editor-toolbar span').find( ":contains('Media')").parents('.k-button-solid-base');
      euButton.addClass('editor_upload');
      var euText = euButton.text();
      euButton.attr("title", euText);
      //alert(euText);
      euButton.find('span.k-button-text').html( "<label for='fileToUpload'>" + euText + "</lable>" );
    }, 500);
    tabstrip.selectTab(0);
    this.imagechange();
  }
  
  backToMoreSendOptions(tabstrip, AnswerFormatTab, PostAsTab) {
    if (
      this.postQuestionEntity.question_type_id ==
      this.globals.configurationText.questionType.multiPickList
    ) {
      this.postQuestionEntity.question_type_id =
        this.globals.configurationText.questionType.pickList;
    }
    AnswerFormatTab.disabled = false; // AnswerFormatTab open
    PostAsTab.disabled = true; //MoreSendOptionTab disable
    tabstrip.selectTab(1);
  }
  unique_contact = [];
  checkvalidation() {
    var contact_details: any;
    if (this.globals.authData != undefined) {
      if (
        this.postQuestionEntity.contact_details != undefined &&
        this.postQuestionEntity.contact_details != ''
      ) {
        contact_details = this.postQuestionEntity.contact_details.filter(
          function (v) {
            return $.trim(v) !== '';
          }
        );
        var count = 0;
        for (var i = 0; i < contact_details.length; i++) {
          var emailformat =
            /^([a-zA-Z0-9_.-]{1,})+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z]{2,3})$/;
          var phoneformat = /^([0-9()_ ]{10,14})+$/;
          if (contact_details[i] != '') {
            if (
              !$.trim(contact_details[i]).match(emailformat) &&
              !$.trim(contact_details[i]).match(phoneformat)
            ) {
              count++;
            }
          }
        }
        this.unique_contact = contact_details.filter(function (item, pos) {
          return contact_details.indexOf(item) != pos;
        });
        console.log(this.unique_contact);
        if (this.unique_contact.length != 0) {
          Swal.fire({
            type: this.globals.configurationText.sweetAlertTypeIconError,
            icon: this.globals.configurationText.sweetAlertTypeIconError,
            customClass: this.globals.configurationText.sweetAlertClassError,
            toast: true,
            title:
              'You have one or more duplicate email or phone numbers in invitations. Please remove duplicate email or phone.',
            animation: true,
            position: this.globals.configurationText.sweetAlertPosition,
            showConfirmButton: false,
            timer: this.globals.configurationText.sweetAlertTimer,
            timerProgressBar: true,
            showCancelButton: true,
            cancelButtonText: 'x',
          });
        }
        if (
          (count > 0 &&
            (this.errorEntity.InvitationError == '' ||
              this.errorEntity.InvitationError == undefined)) ||
          contact_details.length == 0
        ) {
          this.contactDetailsValidation = true;
          $('#contact_details').addClass('error');
        } else {
          this.contactDetailsValidation = false;
          if (
            this.errorEntity.InvitationError == '' ||
            this.errorEntity.InvitationError == undefined
          )
            $('#contact_details').removeClass('error');
        }
      } else {
        this.contactDetailsValidation = false;
      }
    }

    //^[0][1-9]\d{9}$|^[1-9]\d{9}$
    // var emailformat = '[a-zA-Z0-9.-_]{1,1}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,3}';
    // var emailformat = '[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}';
  }
  checkNumberofInvitations() {
    console.log(JSON.stringify(this.postQuestionEntity.contact_details));
    if (
      this.postQuestionEntity.contact_details != undefined &&
      this.postQuestionEntity.contact_details != '' &&
      $.type(this.postQuestionEntity.contact_details) != 'array'
    ) {
      this.postQuestionEntity.contact_details =
        this.postQuestionEntity.contact_details.split(',');
    }
    if (
      this.postQuestionEntity.contact_details != undefined &&
      this.postQuestionEntity.contact_details != ''
    ) {
      var newArray = this.postQuestionEntity.contact_details.filter(function (
        v
      ) {
        return $.trim(v) !== '';
      });
      console.log(newArray);
    }
    if (this.globals.authData != undefined) {
      if (
        this.postQuestionEntity.contact_details != undefined &&
        this.postQuestionEntity.contact_details != ''
      ) {
        if (
          newArray.length >
          this.globals.authData.plan.community_question_number_of_invitations
        ) {
          this.errorEntity.InvitationError =
            this.globals.commonTranslationText.home.form.contactDetail
              .communityQuestionInvitationErrorMessage +
            this.globals.authData.plan.community_question_number_of_invitations;
          $('#contact_details').addClass('error');
          this.contactDetailsValidation = false;
        } else {
          this.errorEntity.InvitationError = '';
          if (
            this.postQuestionEntity.contact_details != undefined &&
            this.postQuestionEntity.contact_details != '' &&
            this.contactDetailsValidation == false
          ) {
            $('#contact_details').removeClass('error');
          }
        }
        console.log(this.postQuestionEntity.contact_details.length);
      } else {
        this.errorEntity.InvitationError = '';
        this.contactDetailsValidation = false;
        this.postQuestionEntity.contact_details = [];
        if (this.postQuestionEntity.required == false)
          $('#contact_details').removeClass('error');
        if (this.postQuestionEntity.questionType == 1)
          $('#contact_details').removeClass('error');
      }
    }

    // var userInvitationDetails = this.postQuestionEntity.contact_details.sp
  }
  nextPostAs(postQuestionForm2, tabstrip, MoreSendOptionTab) {
    this.postQuestionEntity.required = true;
    this.answerFormatSubmitted = true;
    this.optionSubmitted = true;
    this.contactSubmitted = false;
    this.communitySubmitted = false;
    if (postQuestionForm2.valid) {
      this.answerFormatSubmitted = false;
      this.optionSubmitted = false;
      if (
        postQuestionForm2.form.value.question_type_id ==
        '176c9480-1a9b-11eb-b17b-c85b767b9463'
      ) {
        if (this.OptionsList.length == 2) {
          if (
            postQuestionForm2.form.value.OptionValue0 ==
            postQuestionForm2.form.value.OptionValue1
          ) {
            Swal.fire({
              icon: 'warning',
              type: 'warning',
              title: 'Oops..',
              text: 'Options Should be Unique',
              showCancelButton: false,
              showConfirmButton: true,
              confirmButtonClass: 'theme_btn',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
            });
          } else {
            if ($('input[name="multiSelection"]').is(':checked')) {
              this.postQuestionEntity.question_type_id =
                $('#multiSelection').val();
              this.postQuestionEntity.question_type_multiSelect_id =
                this.globals.configurationText.questionType.multiPickList;
            } else {
              this.postQuestionEntity.question_type_multiSelect_id =
                this.postQuestionEntity.question_type_id;
            }
            if (
              this.postQuestionEntity.question_type_id ==
                this.globals.configurationText.questionType.pickList ||
              this.postQuestionEntity.question_type_id ==
                this.globals.configurationText.questionType.multiPickList
            ) {
              this.postQuestionEntity.question_option = this.OptionsList;
            } else if (
              this.postQuestionEntity.question_type_id ==
              this.globals.configurationText.questionType.rankedList
            ) {
              this.postQuestionEntity.question_option = this.RankList;
            }
            console.log(this.postQuestionEntity.question_option);
            // AnswerFormatTab.disabled = true; //AnswerFormatTab disable
            MoreSendOptionTab.disabled = false; //MoreSendOptionTab open
            tabstrip.selectTab(2);
            this.optionSubmitted = false;
          }
        } else if (this.OptionsList.length == 3) {
          if (
            postQuestionForm2.form.value.OptionValue0 ==
              postQuestionForm2.form.value.OptionValue1 ||
            postQuestionForm2.form.value.OptionValue0 ==
              postQuestionForm2.form.value.OptionValue2 ||
            postQuestionForm2.form.value.OptionValue1 ==
              postQuestionForm2.form.value.OptionValue2
          ) {
            Swal.fire({
              icon: 'warning',
              type: 'warning',
              title: 'Oops..',
              text: 'Options Should be Unique',
              showCancelButton: false,
              showConfirmButton: true,
              confirmButtonClass: 'theme_btn',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
            });
          } else {
            if ($('input[name="multiSelection"]').is(':checked')) {
              this.postQuestionEntity.question_type_id =
                $('#multiSelection').val();
              this.postQuestionEntity.question_type_multiSelect_id =
                this.globals.configurationText.questionType.multiPickList;
            } else {
              this.postQuestionEntity.question_type_multiSelect_id =
                this.postQuestionEntity.question_type_id;
            }
            if (
              this.postQuestionEntity.question_type_id ==
                this.globals.configurationText.questionType.pickList ||
              this.postQuestionEntity.question_type_id ==
                this.globals.configurationText.questionType.multiPickList
            ) {
              this.postQuestionEntity.question_option = this.OptionsList;
            } else if (
              this.postQuestionEntity.question_type_id ==
              this.globals.configurationText.questionType.rankedList
            ) {
              this.postQuestionEntity.question_option = this.RankList;
            }
            console.log(this.postQuestionEntity.question_option);
            // AnswerFormatTab.disabled = true; //AnswerFormatTab disable
            MoreSendOptionTab.disabled = false; //MoreSendOptionTab open
            tabstrip.selectTab(2);
            this.optionSubmitted = false;
          }
        } else if (this.OptionsList.length == 4) {
          if (
            postQuestionForm2.form.value.OptionValue0 ==
              postQuestionForm2.form.value.OptionValue1 ||
            postQuestionForm2.form.value.OptionValue0 ==
              postQuestionForm2.form.value.OptionValue2 ||
            postQuestionForm2.form.value.OptionValue0 ==
              postQuestionForm2.form.value.OptionValue3 ||
            postQuestionForm2.form.value.OptionValue1 ==
              postQuestionForm2.form.value.OptionValue2 ||
            postQuestionForm2.form.value.OptionValue1 ==
              postQuestionForm2.form.value.OptionValue3 ||
            postQuestionForm2.form.value.OptionValue2 ==
              postQuestionForm2.form.value.OptionValue3
          ) {
            Swal.fire({
              icon: 'warning',
              type: 'warning',
              title: 'Oops..',
              text: 'Options Should be Unique',
              showCancelButton: false,
              showConfirmButton: true,
              confirmButtonClass: 'theme_btn',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
            });
          } else {
            if ($('input[name="multiSelection"]').is(':checked')) {
              this.postQuestionEntity.question_type_id =
                $('#multiSelection').val();
              this.postQuestionEntity.question_type_multiSelect_id =
                this.globals.configurationText.questionType.multiPickList;
            } else {
              this.postQuestionEntity.question_type_multiSelect_id =
                this.postQuestionEntity.question_type_id;
            }
            if (
              this.postQuestionEntity.question_type_id ==
                this.globals.configurationText.questionType.pickList ||
              this.postQuestionEntity.question_type_id ==
                this.globals.configurationText.questionType.multiPickList
            ) {
              this.postQuestionEntity.question_option = this.OptionsList;
            } else if (
              this.postQuestionEntity.question_type_id ==
              this.globals.configurationText.questionType.rankedList
            ) {
              this.postQuestionEntity.question_option = this.RankList;
            }
            console.log(this.postQuestionEntity.question_option);
            // AnswerFormatTab.disabled = true; //AnswerFormatTab disable
            MoreSendOptionTab.disabled = false; //MoreSendOptionTab open
            tabstrip.selectTab(2);
            this.optionSubmitted = false;
          }
        } else if (this.OptionsList.length == 5) {
          if (
            postQuestionForm2.form.value.OptionValue0 ==
              postQuestionForm2.form.value.OptionValue1 ||
            postQuestionForm2.form.value.OptionValue0 ==
              postQuestionForm2.form.value.OptionValue2 ||
            postQuestionForm2.form.value.OptionValue0 ==
              postQuestionForm2.form.value.OptionValue3 ||
            postQuestionForm2.form.value.OptionValue0 ==
              postQuestionForm2.form.value.OptionValue4 ||
            postQuestionForm2.form.value.OptionValue1 ==
              postQuestionForm2.form.value.OptionValue2 ||
            postQuestionForm2.form.value.OptionValue1 ==
              postQuestionForm2.form.value.OptionValue3 ||
            postQuestionForm2.form.value.OptionValue1 ==
              postQuestionForm2.form.value.OptionValue4 ||
            postQuestionForm2.form.value.OptionValue2 ==
              postQuestionForm2.form.value.OptionValue3 ||
            postQuestionForm2.form.value.OptionValue2 ==
              postQuestionForm2.form.value.OptionValue4 ||
            postQuestionForm2.form.value.OptionValue3 ==
              postQuestionForm2.form.value.OptionValue4
          ) {
            Swal.fire({
              icon: 'warning',
              type: 'warning',
              title: 'Oops..',
              text: 'Options Should be Unique',
              showCancelButton: false,
              showConfirmButton: true,
              confirmButtonClass: 'theme_btn',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
            });
          } else {
            if ($('input[name="multiSelection"]').is(':checked')) {
              this.postQuestionEntity.question_type_id =
                $('#multiSelection').val();
              this.postQuestionEntity.question_type_multiSelect_id =
                this.globals.configurationText.questionType.multiPickList;
            } else {
              this.postQuestionEntity.question_type_multiSelect_id =
                this.postQuestionEntity.question_type_id;
            }
            if (
              this.postQuestionEntity.question_type_id ==
                this.globals.configurationText.questionType.pickList ||
              this.postQuestionEntity.question_type_id ==
                this.globals.configurationText.questionType.multiPickList
            ) {
              this.postQuestionEntity.question_option = this.OptionsList;
            } else if (
              this.postQuestionEntity.question_type_id ==
              this.globals.configurationText.questionType.rankedList
            ) {
              this.postQuestionEntity.question_option = this.RankList;
            }
            console.log(this.postQuestionEntity.question_option);
            // AnswerFormatTab.disabled = true; //AnswerFormatTab disable
            MoreSendOptionTab.disabled = false; //MoreSendOptionTab open
            tabstrip.selectTab(2);
            this.optionSubmitted = false;
          }
        } else if (this.OptionsList.length == 6) {
          if (
            postQuestionForm2.form.value.OptionValue0 ==
              postQuestionForm2.form.value.OptionValue1 ||
            postQuestionForm2.form.value.OptionValue0 ==
              postQuestionForm2.form.value.OptionValue2 ||
            postQuestionForm2.form.value.OptionValue0 ==
              postQuestionForm2.form.value.OptionValue3 ||
            postQuestionForm2.form.value.OptionValue0 ==
              postQuestionForm2.form.value.OptionValue4 ||
            postQuestionForm2.form.value.OptionValue0 ==
              postQuestionForm2.form.value.OptionValue5 ||
            postQuestionForm2.form.value.OptionValue1 ==
              postQuestionForm2.form.value.OptionValue2 ||
            postQuestionForm2.form.value.OptionValue1 ==
              postQuestionForm2.form.value.OptionValue3 ||
            postQuestionForm2.form.value.OptionValue1 ==
              postQuestionForm2.form.value.OptionValue4 ||
            postQuestionForm2.form.value.OptionValue1 ==
              postQuestionForm2.form.value.OptionValue5 ||
            postQuestionForm2.form.value.OptionValue2 ==
              postQuestionForm2.form.value.OptionValue3 ||
            postQuestionForm2.form.value.OptionValue2 == 4 ||
            postQuestionForm2.form.value.OptionValue2 ==
              postQuestionForm2.form.value.OptionValue5 ||
            postQuestionForm2.form.value.OptionValue3 ==
              postQuestionForm2.form.value.OptionValue4 ||
            postQuestionForm2.form.value.OptionValue3 ==
              postQuestionForm2.form.value.OptionValue5 ||
            postQuestionForm2.form.value.OptionValue4 ==
              postQuestionForm2.form.value.OptionValue5
          ) {
            Swal.fire({
              icon: 'warning',
              type: 'warning',
              title: 'Oops..',
              text: 'Options Should be Unique',
              showCancelButton: false,
              showConfirmButton: true,
              confirmButtonClass: 'theme_btn',
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
            });
          } else {
            if ($('input[name="multiSelection"]').is(':checked')) {
              this.postQuestionEntity.question_type_id =
                $('#multiSelection').val();
              this.postQuestionEntity.question_type_multiSelect_id =
                this.globals.configurationText.questionType.multiPickList;
            } else {
              this.postQuestionEntity.question_type_multiSelect_id =
                this.postQuestionEntity.question_type_id;
            }
            if (
              this.postQuestionEntity.question_type_id ==
                this.globals.configurationText.questionType.pickList ||
              this.postQuestionEntity.question_type_id ==
                this.globals.configurationText.questionType.multiPickList
            ) {
              this.postQuestionEntity.question_option = this.OptionsList;
            } else if (
              this.postQuestionEntity.question_type_id ==
              this.globals.configurationText.questionType.rankedList
            ) {
              this.postQuestionEntity.question_option = this.RankList;
            }
            console.log(this.postQuestionEntity.question_option);
            // AnswerFormatTab.disabled = true; //AnswerFormatTab disable
            MoreSendOptionTab.disabled = false; //MoreSendOptionTab open
            tabstrip.selectTab(2);
            this.optionSubmitted = false;
          }
        }
      } else {
        if ($('input[name="multiSelection"]').is(':checked')) {
          this.postQuestionEntity.question_type_id = $('#multiSelection').val();
          this.postQuestionEntity.question_type_multiSelect_id =
            this.globals.configurationText.questionType.multiPickList;
        } else {
          this.postQuestionEntity.question_type_multiSelect_id =
            this.postQuestionEntity.question_type_id;
        }
        if (
          this.postQuestionEntity.question_type_id ==
            this.globals.configurationText.questionType.pickList ||
          this.postQuestionEntity.question_type_id ==
            this.globals.configurationText.questionType.multiPickList
        ) {
          this.postQuestionEntity.question_option = this.OptionsList;
        } else if (
          this.postQuestionEntity.question_type_id ==
          this.globals.configurationText.questionType.rankedList
        ) {
          this.postQuestionEntity.question_option = this.RankList;
        }
        console.log(this.postQuestionEntity.question_option);
        // AnswerFormatTab.disabled = true; //AnswerFormatTab disable
        MoreSendOptionTab.disabled = false; //MoreSendOptionTab open
        tabstrip.selectTab(2);
        this.optionSubmitted = false;
      }
    }
  }

  // nextPostAs(postQuestionForm3, tabstrip, MoreSendOptionTab, PostAsTab) {
  //   this.optionSubmitted = true;
  //   this.postAsSubmitted = false;
  //   if (postQuestionForm3.form.value.value.length > 0) {
  //     this.optionSubmitted = false;
  //     this.postAsSubmitted = false;
  //     MoreSendOptionTab.disabled = true; // QuestionTab disable
  //     PostAsTab.disabled = false; //PostAsTab open
  //     tabstrip.selectTab(3);
  //   } else {
  //     this.errorEntity.community_id = true;
  //   }
  // }

  backToAnswerFormat(tabstrip, AnswerFormatTab, MoreSendOptionTab) {
    if (
      this.postQuestionEntity.question_type_id ==
      this.globals.configurationText.questionType.multiPickList
    ) {
      this.postQuestionEntity.question_type_id =
        this.globals.configurationText.questionType.pickList;
    }
    AnswerFormatTab.disabled = false; // AnswerFormatTab open
    MoreSendOptionTab.disabled = true; //MoreSendOptionTab disable
    tabstrip.selectTab(1);
  }

  communityChange(value) {
    this.CommunityQuestionService.getCommunityQuestions(
      value,
      'community_details'
    ).then(
      (data) => {
        console.log(data);
        this.communityEntity = data;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  btn_disabled;
  postQuestion(postQuestionForm3, value) {
    this.postQuestionEntity.required = true;
    this.answerFormatSubmitted = true;
    this.optionSubmitted = true;
    this.contactSubmitted = false;
    this.communitySubmitted = false;
    this.postQuestionEntity.question_status_id = value;
    const isWhitespace =
      (
        this.postQuestionEntity.question_text.replace(/<[^>]+>/g, '') || ''
      ).trim().length === 0;
    if (this.len <= 1000 && isWhitespace == false) {
      if (
        $('#xyz').is(':visible') == true &&
        $('#error').is(':visible') == true
      ) {
      } else {
        if (
          this.postQuestionEntity.question_text == '' ||
          this.postQuestionEntity.question_text == undefined
        ) {
          this.questionSubmitted = true;
        } else {
          this.questionSubmitted = false;
        }
        if (
          value == this.globals.configurationText.questionStatus.saveAsDraft
        ) {
          this.postQuestionEntity.required = false;
          this.answerFormatSubmitted = false;
          this.optionSubmitted = false;
          if (
            this.postQuestionEntity.question_type_id ==
              this.globals.configurationText.questionType.pickList ||
            this.postQuestionEntity.question_type_id ==
              this.globals.configurationText.questionType.multiPickList
          ) {
            // this.OptionsList.filter(function (v) { return $.trim(v.OptionValue) !== '' });
            this.postQuestionEntity.question_option = this.OptionsList.filter(
              function (v) {
                return $.trim(v.OptionValue) !== '';
              }
            );
          } else if (
            this.postQuestionEntity.question_type_id ==
            this.globals.configurationText.questionType.rankedList
          ) {
            this.postQuestionEntity.question_option = this.RankList.filter(
              function (v) {
                return $.trim(v.OptionValue) !== '';
              }
            );
          }
        } else {
          this.postQuestionEntity.required = true;
        }
        console.log(this.postQuestionEntity.required);
        console.log(this.errorEntity);
        this.contactSubmitted =
          this.postQuestionEntity.questionType == 0 ? true : false;
        this.communitySubmitted = true;
        this.checkNumberofInvitations();
        this.checkvalidation();
        // if (
        //   this.postQuestionEntity.questionType == 1 &&
        //   this.postQuestionEntity.community_id.length == 0
        // ) {
        //   this.errorEntity.community_id = true;
        // } else {
        //   this.errorEntity.community_id = false;
        // }
        if (
          (postQuestionForm3.valid ||
            (value ==
              this.globals.configurationText.questionStatus.saveAsDraft &&
              this.postQuestionEntity.question_text != '' &&
              this.postQuestionEntity.question_text != undefined)) &&
          (this.errorEntity.InvitationError == '' ||
            this.errorEntity.InvitationError == undefined) &&
          this.contactDetailsValidation == false &&
          this.unique_contact.length == 0
        ) {
          // &&this.errorEntity.community_id == false

          if (
            postQuestionForm3.form.value.question_type_id ==
            '176c9480-1a9b-11eb-b17b-c85b767b9463'
          ) {
            if (this.OptionsList.length == 2) {
              if (
                postQuestionForm3.form.value.OptionValue0 ==
                postQuestionForm3.form.value.OptionValue1
              ) {
                Swal.fire({
                  icon: 'warning',
                  type: 'warning',
                  title: 'Oops..',
                  text: 'Options Should be Unique',
                  showCancelButton: false,
                  showConfirmButton: true,
                  confirmButtonClass: 'theme_btn',
                  confirmButtonText: 'Ok',
                  allowOutsideClick: false,
                });
              } else {
                if ($('input[name="multiSelection"]').is(':checked')) {
                  this.postQuestionEntity.question_type_id =
                    $('#multiSelection').val();
                  this.postQuestionEntity.question_type_multiSelect_id =
                    this.globals.configurationText.questionType.multiPickList;
                } else {
                  this.postQuestionEntity.question_type_multiSelect_id =
                    this.postQuestionEntity.question_type_id;
                }
                if (
                  this.postQuestionEntity.question_type_id ==
                    this.globals.configurationText.questionType.pickList ||
                  this.postQuestionEntity.question_type_id ==
                    this.globals.configurationText.questionType.multiPickList
                ) {
                  this.postQuestionEntity.question_option = this.OptionsList;
                } else if (
                  this.postQuestionEntity.question_type_id ==
                  this.globals.configurationText.questionType.rankedList
                ) {
                  this.postQuestionEntity.question_option = this.RankList;
                }

                var fd = new FormData();
                if (
                  this.postQuestionEntity.image_video_input_file != '' &&
                  this.postQuestionEntity.image_video_input_file != undefined
                ) {
                  var input = this.postQuestionEntity.image_video_input_file;
                  if (input.files && input.files[0]) {
                    var Images =
                      Date.now() + '_' + input.files[0].name.replace(/ /g, '_');
                    fd.append('image_video_file', input.files[0], Images);
                    fd.append('image_video', Images);
                  }
                }
                if (
                  this.postQuestionEntity.image_video_path != '' &&
                  this.postQuestionEntity.image_video_path != undefined
                ) {
                  fd.append('image_video', this.postQuestionEntity.image_video);
                  fd.append('path', this.postQuestionEntity.path);
                  fd.append('name', this.postQuestionEntity.image_video);
                }
                if (
                  this.postQuestionEntity.image_video == undefined ||
                  this.push_Image_video.length == 0
                ) {
                  fd.append('image_video_url', '[]');
                } else {
                  fd.append(
                    'image_video_url',
                    JSON.stringify(this.postQuestionEntity.image_video)
                  );
                }
                console.log($.type(this.postQuestionEntity.contact_details));
                if (
                  this.postQuestionEntity.contact_details != undefined &&
                  $.type(this.postQuestionEntity.contact_details) != 'array'
                ) {
                  this.postQuestionEntity.contact_details =
                    this.postQuestionEntity.contact_details.split(',');
                }
                // else {
                //   this.postQuestionEntity.contact_details = '';
                // }
                console.log(this.postQuestionEntity.contact_details);
                console.log(this.postQuestionEntity.contactDetails);
                console.log(
                  JSON.stringify(this.postQuestionEntity.contact_details)
                );
                if (this.questionId) {
                  fd.append('question_id', this.questionId);
                }
                fd.append(
                  'question_text',
                  this.postQuestionEntity.question_text
                );
                fd.append('post_as', this.postQuestionEntity.postas);
                fd.append(
                  'question_type_id',
                  this.postQuestionEntity.question_type_id
                );
                if (
                  this.postQuestionEntity.question_option &&
                  (this.postQuestionEntity.question_type_id ==
                    this.globals.configurationText.questionType.pickList ||
                    this.postQuestionEntity.question_type_id ==
                      this.globals.configurationText.questionType
                        .multiPickList ||
                    this.postQuestionEntity.question_type_id ==
                      this.globals.configurationText.questionType.rankedList)
                ) {
                  fd.append(
                    'question_option',
                    JSON.stringify(this.postQuestionEntity.question_option)
                  );
                }
                if (
                  this.postQuestionEntity.contact_details != undefined &&
                  this.postQuestionEntity.contact_details != ''
                ) {
                  var phoneformat = /^([0-9()_ ]{10,14})+$/;
                  for (
                    var a = 0;
                    a < this.postQuestionEntity.contact_details.length;
                    a++
                  ) {
                    if (
                      this.postQuestionEntity.contact_details[a].match(
                        phoneformat
                      )
                    ) {
                      this.postQuestionEntity.contact_details[a] =
                        this.postQuestionEntity.contact_details[a].replace(
                          /\D/g,
                          ''
                        );
                    }
                  }
                  fd.append(
                    'contact_details',
                    JSON.stringify(
                      this.postQuestionEntity.contact_details.filter(function (
                        v
                      ) {
                        return $.trim(v) !== '';
                      })
                    )
                  );
                }
                var community_id = '183c0d0d-508d-11ee-b584-9840bb1952c8';
                this.UserCommunity.push(community_id);
                this.postQuestionEntity.community_id = this.UserCommunity;
                fd.append(
                  'community_id',
                  JSON.stringify(this.postQuestionEntity.community_id)
                );
                fd.append(
                  'answer_only_to_me',
                  this.postQuestionEntity.answer_only_to_me
                    ? this.postQuestionEntity.answer_only_to_me
                    : ''
                );
                fd.append(
                  'invitee_can_invite_others',
                  this.postQuestionEntity.invitee_can_invite_others
                    ? this.postQuestionEntity.invitee_can_invite_others
                    : ''
                );
                fd.append(
                  'invitees_only_to_me',
                  this.postQuestionEntity.invitees_only_to_me
                    ? this.postQuestionEntity.invitees_only_to_me
                    : ''
                );
                fd.append(
                  'send_anonymously',
                  this.postQuestionEntity.send_anonymously
                    ? this.postQuestionEntity.send_anonymously
                    : ''
                );
                fd.append('question_status_id', value);
                fd.append('user_id', this.globals.authData.id);
                this.btn_disabled = true;
                if (
                  value ==
                  this.globals.configurationText.questionStatus.saveAsDraft
                ) {
                  setTimeout(() => {
                    this.globals.postQuestionText =
                      'Please wait, saving Thread as draft…';
                    this.globals.postQuestionLoading = true;
                  }, 300);
                } else {
                  this.globals.postQuestionText =
                    'Please wait, publishing your Thread';
                  this.globals.postQuestionLoading = true;
                }
                this.HomeService.postQuestion(fd).then(
                  (data) => {
                    this.btn_disabled = false;
                    console.log(data);
                    if (
                      value ==
                      this.globals.configurationText.questionStatus.saveAsDraft
                    ) {
                      var title = 'Save As Draft';
                      var text = 'Thread has been drafted successfully';
                      var type = 'draft';
                    } else {
                      var title = 'Post Thread';
                      var text = 'Thread has been posted successfully';
                      var type = 'open';
                    }
                    var d1 = new Date(),
                      d2 = new Date(d1);
                    d2.setMinutes(
                      d1.getMinutes() + this.globals.cookieMessageExpireTime
                    );
                    this.cookieService.set(
                      'successMessage',
                      text,
                      this.globals.cookieMessageExpireTime,
                      '/'
                    );
                    this.postQuestionEntity = {};
                    this.contactSubmitted = false;
                    postQuestionForm3.form.markAsPristine();
                    // window.location.href = '/userDashboard/' + window.btoa(type);
                    window.location.href = '/userDashboard';

                    this.postQuestionEntity.question_type_id = '';
                    this.postQuestionEntity.community_id = '';
                    this.questionSendOptionsList = this.sendOptionsList;
                    this.globals.postQuestionLoading = false;
                    $('#question_text').val('');
                    this.countChar();
                    console.log(this.postQuestionEntity.question_text);
                  },
                  (error) => {
                    this.btn_disabled = false;
                    this.globals.postQuestionLoading = false;
                    console.log(error);
                    if (error.status == 422) {
                      Swal.fire({
                        icon: 'warning',
                        type: 'warning',
                        title: 'Oops..',
                        text: error.error.message,
                        showCancelButton: false,
                        showConfirmButton: true,
                        confirmButtonClass: 'theme_btn',
                        confirmButtonText: 'Ok',
                        allowOutsideClick: false,
                      });
                    }
                    this.btn_disabled = false;
                  }
                );
                console.log(this.postQuestionEntity);
              }
            } else if (this.OptionsList.length == 3) {
              if (
                postQuestionForm3.form.value.OptionValue0 ==
                  postQuestionForm3.form.value.OptionValue1 ||
                postQuestionForm3.form.value.OptionValue0 ==
                  postQuestionForm3.form.value.OptionValue2 ||
                postQuestionForm3.form.value.OptionValue1 ==
                  postQuestionForm3.form.value.OptionValue2
              ) {
                Swal.fire({
                  icon: 'warning',
                  type: 'warning',
                  title: 'Oops..',
                  text: 'Options Should be Unique',
                  showCancelButton: false,
                  showConfirmButton: true,
                  confirmButtonClass: 'theme_btn',
                  confirmButtonText: 'Ok',
                  allowOutsideClick: false,
                });
              } else {
                if ($('input[name="multiSelection"]').is(':checked')) {
                  this.postQuestionEntity.question_type_id =
                    $('#multiSelection').val();
                  this.postQuestionEntity.question_type_multiSelect_id =
                    this.globals.configurationText.questionType.multiPickList;
                } else {
                  this.postQuestionEntity.question_type_multiSelect_id =
                    this.postQuestionEntity.question_type_id;
                }
                if (
                  this.postQuestionEntity.question_type_id ==
                    this.globals.configurationText.questionType.pickList ||
                  this.postQuestionEntity.question_type_id ==
                    this.globals.configurationText.questionType.multiPickList
                ) {
                  this.postQuestionEntity.question_option = this.OptionsList;
                } else if (
                  this.postQuestionEntity.question_type_id ==
                  this.globals.configurationText.questionType.rankedList
                ) {
                  this.postQuestionEntity.question_option = this.RankList;
                }

                var fd = new FormData();
                if (
                  this.postQuestionEntity.image_video_input_file != '' &&
                  this.postQuestionEntity.image_video_input_file != undefined
                ) {
                  var input = this.postQuestionEntity.image_video_input_file;
                  if (input.files && input.files[0]) {
                    var Images =
                      Date.now() + '_' + input.files[0].name.replace(/ /g, '_');
                    fd.append('image_video_file', input.files[0], Images);
                    fd.append('image_video', Images);
                  }
                }
                if (
                  this.postQuestionEntity.image_video_path != '' &&
                  this.postQuestionEntity.image_video_path != undefined
                ) {
                  fd.append('image_video', this.postQuestionEntity.image_video);
                  fd.append('path', this.postQuestionEntity.path);
                  fd.append('name', this.postQuestionEntity.image_video);
                }
                if (
                  this.postQuestionEntity.image_video == undefined ||
                  this.push_Image_video.length == 0
                ) {
                  fd.append('image_video_url', '[]');
                } else {
                  fd.append(
                    'image_video_url',
                    JSON.stringify(this.postQuestionEntity.image_video)
                  );
                }
                console.log($.type(this.postQuestionEntity.contact_details));
                if (
                  this.postQuestionEntity.contact_details != undefined &&
                  $.type(this.postQuestionEntity.contact_details) != 'array'
                ) {
                  this.postQuestionEntity.contact_details =
                    this.postQuestionEntity.contact_details.split(',');
                }
                // else {
                //   this.postQuestionEntity.contact_details = '';
                // }
                console.log(this.postQuestionEntity.contact_details);
                console.log(this.postQuestionEntity.contactDetails);
                console.log(
                  JSON.stringify(this.postQuestionEntity.contact_details)
                );
                if (this.questionId) {
                  fd.append('question_id', this.questionId);
                }
                fd.append(
                  'question_text',
                  this.postQuestionEntity.question_text
                );
                fd.append('post_as', this.postQuestionEntity.postas);
                fd.append(
                  'question_type_id',
                  this.postQuestionEntity.question_type_id
                );
                if (
                  this.postQuestionEntity.question_option &&
                  (this.postQuestionEntity.question_type_id ==
                    this.globals.configurationText.questionType.pickList ||
                    this.postQuestionEntity.question_type_id ==
                      this.globals.configurationText.questionType
                        .multiPickList ||
                    this.postQuestionEntity.question_type_id ==
                      this.globals.configurationText.questionType.rankedList)
                ) {
                  fd.append(
                    'question_option',
                    JSON.stringify(this.postQuestionEntity.question_option)
                  );
                }
                if (
                  this.postQuestionEntity.contact_details != undefined &&
                  this.postQuestionEntity.contact_details != ''
                ) {
                  var phoneformat = /^([0-9()_ ]{10,14})+$/;
                  for (
                    var a = 0;
                    a < this.postQuestionEntity.contact_details.length;
                    a++
                  ) {
                    if (
                      this.postQuestionEntity.contact_details[a].match(
                        phoneformat
                      )
                    ) {
                      this.postQuestionEntity.contact_details[a] =
                        this.postQuestionEntity.contact_details[a].replace(
                          /\D/g,
                          ''
                        );
                    }
                  }
                  fd.append(
                    'contact_details',
                    JSON.stringify(
                      this.postQuestionEntity.contact_details.filter(function (
                        v
                      ) {
                        return $.trim(v) !== '';
                      })
                    )
                  );
                }
                var community_id = '183c0d0d-508d-11ee-b584-9840bb1952c8';
                this.UserCommunity.push(community_id);
                this.postQuestionEntity.community_id = this.UserCommunity;
                fd.append(
                  'community_id',
                  JSON.stringify(this.postQuestionEntity.community_id)
                );
                fd.append(
                  'answer_only_to_me',
                  this.postQuestionEntity.answer_only_to_me
                    ? this.postQuestionEntity.answer_only_to_me
                    : ''
                );
                fd.append(
                  'invitee_can_invite_others',
                  this.postQuestionEntity.invitee_can_invite_others
                    ? this.postQuestionEntity.invitee_can_invite_others
                    : ''
                );
                fd.append(
                  'invitees_only_to_me',
                  this.postQuestionEntity.invitees_only_to_me
                    ? this.postQuestionEntity.invitees_only_to_me
                    : ''
                );
                fd.append(
                  'send_anonymously',
                  this.postQuestionEntity.send_anonymously
                    ? this.postQuestionEntity.send_anonymously
                    : ''
                );
                fd.append('question_status_id', value);
                fd.append('user_id', this.globals.authData.id);
                this.btn_disabled = true;
                if (
                  value ==
                  this.globals.configurationText.questionStatus.saveAsDraft
                ) {
                  setTimeout(() => {
                    this.globals.postQuestionText =
                      'Please wait, saving Thread as draft…';
                    this.globals.postQuestionLoading = true;
                  }, 300);
                } else {
                  this.globals.postQuestionText =
                    'Please wait, publishing your Thread';
                  this.globals.postQuestionLoading = true;
                }
                this.HomeService.postQuestion(fd).then(
                  (data) => {
                    this.btn_disabled = false;
                    console.log(data);
                    if (
                      value ==
                      this.globals.configurationText.questionStatus.saveAsDraft
                    ) {
                      var title = 'Save As Draft';
                      var text = 'Thread has been drafted successfully';
                      var type = 'draft';
                    } else {
                      var title = 'Post Thread';
                      var text = 'Thread has been posted successfully';
                      var type = 'open';
                    }
                    var d1 = new Date(),
                      d2 = new Date(d1);
                    d2.setMinutes(
                      d1.getMinutes() + this.globals.cookieMessageExpireTime
                    );
                    this.cookieService.set(
                      'successMessage',
                      text,
                      this.globals.cookieMessageExpireTime,
                      '/'
                    );
                    this.postQuestionEntity = {};
                    this.contactSubmitted = false;
                    postQuestionForm3.form.markAsPristine();
                    // window.location.href = '/userDashboard/' + window.btoa(type);
                    window.location.href = '/userDashboard';

                    this.postQuestionEntity.question_type_id = '';
                    this.postQuestionEntity.community_id = '';
                    this.questionSendOptionsList = this.sendOptionsList;
                    this.globals.postQuestionLoading = false;
                    $('#question_text').val('');
                    this.countChar();
                    console.log(this.postQuestionEntity.question_text);
                  },
                  (error) => {
                    this.btn_disabled = false;
                    this.globals.postQuestionLoading = false;
                    console.log(error);
                    if (error.status == 422) {
                      Swal.fire({
                        icon: 'warning',
                        type: 'warning',
                        title: 'Oops..',
                        text: error.error.message,
                        showCancelButton: false,
                        showConfirmButton: true,
                        confirmButtonClass: 'theme_btn',
                        confirmButtonText: 'Ok',
                        allowOutsideClick: false,
                      });
                    }
                    this.btn_disabled = false;
                  }
                );
                console.log(this.postQuestionEntity);
              }
            } else if (this.OptionsList.length == 4) {
              if (
                postQuestionForm3.form.value.OptionValue0 ==
                  postQuestionForm3.form.value.OptionValue1 ||
                postQuestionForm3.form.value.OptionValue0 ==
                  postQuestionForm3.form.value.OptionValue2 ||
                postQuestionForm3.form.value.OptionValue0 ==
                  postQuestionForm3.form.value.OptionValue3 ||
                postQuestionForm3.form.value.OptionValue1 ==
                  postQuestionForm3.form.value.OptionValue2 ||
                postQuestionForm3.form.value.OptionValue1 ==
                  postQuestionForm3.form.value.OptionValue3 ||
                postQuestionForm3.form.value.OptionValue2 ==
                  postQuestionForm3.form.value.OptionValue3
              ) {
                Swal.fire({
                  icon: 'warning',
                  type: 'warning',
                  title: 'Oops..',
                  text: 'Options Should be Unique',
                  showCancelButton: false,
                  showConfirmButton: true,
                  confirmButtonClass: 'theme_btn',
                  confirmButtonText: 'Ok',
                  allowOutsideClick: false,
                });
              } else {
                if ($('input[name="multiSelection"]').is(':checked')) {
                  this.postQuestionEntity.question_type_id =
                    $('#multiSelection').val();
                  this.postQuestionEntity.question_type_multiSelect_id =
                    this.globals.configurationText.questionType.multiPickList;
                } else {
                  this.postQuestionEntity.question_type_multiSelect_id =
                    this.postQuestionEntity.question_type_id;
                }
                if (
                  this.postQuestionEntity.question_type_id ==
                    this.globals.configurationText.questionType.pickList ||
                  this.postQuestionEntity.question_type_id ==
                    this.globals.configurationText.questionType.multiPickList
                ) {
                  this.postQuestionEntity.question_option = this.OptionsList;
                } else if (
                  this.postQuestionEntity.question_type_id ==
                  this.globals.configurationText.questionType.rankedList
                ) {
                  this.postQuestionEntity.question_option = this.RankList;
                }

                var fd = new FormData();
                if (
                  this.postQuestionEntity.image_video_input_file != '' &&
                  this.postQuestionEntity.image_video_input_file != undefined
                ) {
                  var input = this.postQuestionEntity.image_video_input_file;
                  if (input.files && input.files[0]) {
                    var Images =
                      Date.now() + '_' + input.files[0].name.replace(/ /g, '_');
                    fd.append('image_video_file', input.files[0], Images);
                    fd.append('image_video', Images);
                  }
                }
                if (
                  this.postQuestionEntity.image_video_path != '' &&
                  this.postQuestionEntity.image_video_path != undefined
                ) {
                  fd.append('image_video', this.postQuestionEntity.image_video);
                  fd.append('path', this.postQuestionEntity.path);
                  fd.append('name', this.postQuestionEntity.image_video);
                }
                if (
                  this.postQuestionEntity.image_video == undefined ||
                  this.push_Image_video.length == 0
                ) {
                  fd.append('image_video_url', '[]');
                } else {
                  fd.append(
                    'image_video_url',
                    JSON.stringify(this.postQuestionEntity.image_video)
                  );
                }
                console.log($.type(this.postQuestionEntity.contact_details));
                if (
                  this.postQuestionEntity.contact_details != undefined &&
                  $.type(this.postQuestionEntity.contact_details) != 'array'
                ) {
                  this.postQuestionEntity.contact_details =
                    this.postQuestionEntity.contact_details.split(',');
                }
                // else {
                //   this.postQuestionEntity.contact_details = '';
                // }
                console.log(this.postQuestionEntity.contact_details);
                console.log(this.postQuestionEntity.contactDetails);
                console.log(
                  JSON.stringify(this.postQuestionEntity.contact_details)
                );
                if (this.questionId) {
                  fd.append('question_id', this.questionId);
                }
                fd.append(
                  'question_text',
                  this.postQuestionEntity.question_text
                );
                fd.append('post_as', this.postQuestionEntity.postas);
                fd.append(
                  'question_type_id',
                  this.postQuestionEntity.question_type_id
                );
                if (
                  this.postQuestionEntity.question_option &&
                  (this.postQuestionEntity.question_type_id ==
                    this.globals.configurationText.questionType.pickList ||
                    this.postQuestionEntity.question_type_id ==
                      this.globals.configurationText.questionType
                        .multiPickList ||
                    this.postQuestionEntity.question_type_id ==
                      this.globals.configurationText.questionType.rankedList)
                ) {
                  fd.append(
                    'question_option',
                    JSON.stringify(this.postQuestionEntity.question_option)
                  );
                }
                if (
                  this.postQuestionEntity.contact_details != undefined &&
                  this.postQuestionEntity.contact_details != ''
                ) {
                  var phoneformat = /^([0-9()_ ]{10,14})+$/;
                  for (
                    var a = 0;
                    a < this.postQuestionEntity.contact_details.length;
                    a++
                  ) {
                    if (
                      this.postQuestionEntity.contact_details[a].match(
                        phoneformat
                      )
                    ) {
                      this.postQuestionEntity.contact_details[a] =
                        this.postQuestionEntity.contact_details[a].replace(
                          /\D/g,
                          ''
                        );
                    }
                  }
                  fd.append(
                    'contact_details',
                    JSON.stringify(
                      this.postQuestionEntity.contact_details.filter(function (
                        v
                      ) {
                        return $.trim(v) !== '';
                      })
                    )
                  );
                }
                var community_id = '183c0d0d-508d-11ee-b584-9840bb1952c8';
                this.UserCommunity.push(community_id);
                this.postQuestionEntity.community_id = this.UserCommunity;
                fd.append(
                  'community_id',
                  JSON.stringify(this.postQuestionEntity.community_id)
                );
                fd.append(
                  'answer_only_to_me',
                  this.postQuestionEntity.answer_only_to_me
                    ? this.postQuestionEntity.answer_only_to_me
                    : ''
                );
                fd.append(
                  'invitee_can_invite_others',
                  this.postQuestionEntity.invitee_can_invite_others
                    ? this.postQuestionEntity.invitee_can_invite_others
                    : ''
                );
                fd.append(
                  'invitees_only_to_me',
                  this.postQuestionEntity.invitees_only_to_me
                    ? this.postQuestionEntity.invitees_only_to_me
                    : ''
                );
                fd.append(
                  'send_anonymously',
                  this.postQuestionEntity.send_anonymously
                    ? this.postQuestionEntity.send_anonymously
                    : ''
                );
                fd.append('question_status_id', value);
                fd.append('user_id', this.globals.authData.id);
                this.btn_disabled = true;
                if (
                  value ==
                  this.globals.configurationText.questionStatus.saveAsDraft
                ) {
                  setTimeout(() => {
                    this.globals.postQuestionText =
                      'Please wait, saving Thread as draft…';
                    this.globals.postQuestionLoading = true;
                  }, 300);
                } else {
                  this.globals.postQuestionText =
                    'Please wait, publishing your Thread';
                  this.globals.postQuestionLoading = true;
                }
                this.HomeService.postQuestion(fd).then(
                  (data) => {
                    this.btn_disabled = false;
                    console.log(data);
                    if (
                      value ==
                      this.globals.configurationText.questionStatus.saveAsDraft
                    ) {
                      var title = 'Save As Draft';
                      var text = 'Thread has been drafted successfully';
                      var type = 'draft';
                    } else {
                      var title = 'Post Thread';
                      var text = 'Thread has been posted successfully';
                      var type = 'open';
                    }
                    var d1 = new Date(),
                      d2 = new Date(d1);
                    d2.setMinutes(
                      d1.getMinutes() + this.globals.cookieMessageExpireTime
                    );
                    this.cookieService.set(
                      'successMessage',
                      text,
                      this.globals.cookieMessageExpireTime,
                      '/'
                    );
                    this.postQuestionEntity = {};
                    this.contactSubmitted = false;
                    postQuestionForm3.form.markAsPristine();
                    // window.location.href = '/userDashboard/' + window.btoa(type);
                    window.location.href = '/userDashboard';

                    this.postQuestionEntity.question_type_id = '';
                    this.postQuestionEntity.community_id = '';
                    this.questionSendOptionsList = this.sendOptionsList;
                    this.globals.postQuestionLoading = false;
                    $('#question_text').val('');
                    this.countChar();
                    console.log(this.postQuestionEntity.question_text);
                  },
                  (error) => {
                    this.btn_disabled = false;
                    this.globals.postQuestionLoading = false;
                    console.log(error);
                    if (error.status == 422) {
                      Swal.fire({
                        icon: 'warning',
                        type: 'warning',
                        title: 'Oops..',
                        text: error.error.message,
                        showCancelButton: false,
                        showConfirmButton: true,
                        confirmButtonClass: 'theme_btn',
                        confirmButtonText: 'Ok',
                        allowOutsideClick: false,
                      });
                    }
                    this.btn_disabled = false;
                  }
                );
                console.log(this.postQuestionEntity);
              }
            } else if (this.OptionsList.length == 5) {
              if (
                postQuestionForm3.form.value.OptionValue0 ==
                  postQuestionForm3.form.value.OptionValue1 ||
                postQuestionForm3.form.value.OptionValue0 ==
                  postQuestionForm3.form.value.OptionValue2 ||
                postQuestionForm3.form.value.OptionValue0 ==
                  postQuestionForm3.form.value.OptionValue3 ||
                postQuestionForm3.form.value.OptionValue0 ==
                  postQuestionForm3.form.value.OptionValue4 ||
                postQuestionForm3.form.value.OptionValue1 ==
                  postQuestionForm3.form.value.OptionValue2 ||
                postQuestionForm3.form.value.OptionValue1 ==
                  postQuestionForm3.form.value.OptionValue3 ||
                postQuestionForm3.form.value.OptionValue1 ==
                  postQuestionForm3.form.value.OptionValue4 ||
                postQuestionForm3.form.value.OptionValue2 ==
                  postQuestionForm3.form.value.OptionValue3 ||
                postQuestionForm3.form.value.OptionValue2 ==
                  postQuestionForm3.form.value.OptionValue4 ||
                postQuestionForm3.form.value.OptionValue3 ==
                  postQuestionForm3.form.value.OptionValue4
              ) {
                Swal.fire({
                  icon: 'warning',
                  type: 'warning',
                  title: 'Oops..',
                  text: 'Options Should be Unique',
                  showCancelButton: false,
                  showConfirmButton: true,
                  confirmButtonClass: 'theme_btn',
                  confirmButtonText: 'Ok',
                  allowOutsideClick: false,
                });
              } else {
                if ($('input[name="multiSelection"]').is(':checked')) {
                  this.postQuestionEntity.question_type_id =
                    $('#multiSelection').val();
                  this.postQuestionEntity.question_type_multiSelect_id =
                    this.globals.configurationText.questionType.multiPickList;
                } else {
                  this.postQuestionEntity.question_type_multiSelect_id =
                    this.postQuestionEntity.question_type_id;
                }
                if (
                  this.postQuestionEntity.question_type_id ==
                    this.globals.configurationText.questionType.pickList ||
                  this.postQuestionEntity.question_type_id ==
                    this.globals.configurationText.questionType.multiPickList
                ) {
                  this.postQuestionEntity.question_option = this.OptionsList;
                } else if (
                  this.postQuestionEntity.question_type_id ==
                  this.globals.configurationText.questionType.rankedList
                ) {
                  this.postQuestionEntity.question_option = this.RankList;
                }

                var fd = new FormData();
                if (
                  this.postQuestionEntity.image_video_input_file != '' &&
                  this.postQuestionEntity.image_video_input_file != undefined
                ) {
                  var input = this.postQuestionEntity.image_video_input_file;
                  if (input.files && input.files[0]) {
                    var Images =
                      Date.now() + '_' + input.files[0].name.replace(/ /g, '_');
                    fd.append('image_video_file', input.files[0], Images);
                    fd.append('image_video', Images);
                  }
                }
                if (
                  this.postQuestionEntity.image_video_path != '' &&
                  this.postQuestionEntity.image_video_path != undefined
                ) {
                  fd.append('image_video', this.postQuestionEntity.image_video);
                  fd.append('path', this.postQuestionEntity.path);
                  fd.append('name', this.postQuestionEntity.image_video);
                }
                if (
                  this.postQuestionEntity.image_video == undefined ||
                  this.push_Image_video.length == 0
                ) {
                  fd.append('image_video_url', '[]');
                } else {
                  fd.append(
                    'image_video_url',
                    JSON.stringify(this.postQuestionEntity.image_video)
                  );
                }
                console.log($.type(this.postQuestionEntity.contact_details));
                if (
                  this.postQuestionEntity.contact_details != undefined &&
                  $.type(this.postQuestionEntity.contact_details) != 'array'
                ) {
                  this.postQuestionEntity.contact_details =
                    this.postQuestionEntity.contact_details.split(',');
                }
                // else {
                //   this.postQuestionEntity.contact_details = '';
                // }
                console.log(this.postQuestionEntity.contact_details);
                console.log(this.postQuestionEntity.contactDetails);
                console.log(
                  JSON.stringify(this.postQuestionEntity.contact_details)
                );
                if (this.questionId) {
                  fd.append('question_id', this.questionId);
                }
                fd.append(
                  'question_text',
                  this.postQuestionEntity.question_text
                );
                fd.append('post_as', this.postQuestionEntity.postas);
                fd.append(
                  'question_type_id',
                  this.postQuestionEntity.question_type_id
                );
                if (
                  this.postQuestionEntity.question_option &&
                  (this.postQuestionEntity.question_type_id ==
                    this.globals.configurationText.questionType.pickList ||
                    this.postQuestionEntity.question_type_id ==
                      this.globals.configurationText.questionType
                        .multiPickList ||
                    this.postQuestionEntity.question_type_id ==
                      this.globals.configurationText.questionType.rankedList)
                ) {
                  fd.append(
                    'question_option',
                    JSON.stringify(this.postQuestionEntity.question_option)
                  );
                }
                if (
                  this.postQuestionEntity.contact_details != undefined &&
                  this.postQuestionEntity.contact_details != ''
                ) {
                  var phoneformat = /^([0-9()_ ]{10,14})+$/;
                  for (
                    var a = 0;
                    a < this.postQuestionEntity.contact_details.length;
                    a++
                  ) {
                    if (
                      this.postQuestionEntity.contact_details[a].match(
                        phoneformat
                      )
                    ) {
                      this.postQuestionEntity.contact_details[a] =
                        this.postQuestionEntity.contact_details[a].replace(
                          /\D/g,
                          ''
                        );
                    }
                  }
                  fd.append(
                    'contact_details',
                    JSON.stringify(
                      this.postQuestionEntity.contact_details.filter(function (
                        v
                      ) {
                        return $.trim(v) !== '';
                      })
                    )
                  );
                }
                var community_id = '183c0d0d-508d-11ee-b584-9840bb1952c8';
                this.UserCommunity.push(community_id);
                this.postQuestionEntity.community_id = this.UserCommunity;
                fd.append(
                  'community_id',
                  JSON.stringify(this.postQuestionEntity.community_id)
                );
                fd.append(
                  'answer_only_to_me',
                  this.postQuestionEntity.answer_only_to_me
                    ? this.postQuestionEntity.answer_only_to_me
                    : ''
                );
                fd.append(
                  'invitee_can_invite_others',
                  this.postQuestionEntity.invitee_can_invite_others
                    ? this.postQuestionEntity.invitee_can_invite_others
                    : ''
                );
                fd.append(
                  'invitees_only_to_me',
                  this.postQuestionEntity.invitees_only_to_me
                    ? this.postQuestionEntity.invitees_only_to_me
                    : ''
                );
                fd.append(
                  'send_anonymously',
                  this.postQuestionEntity.send_anonymously
                    ? this.postQuestionEntity.send_anonymously
                    : ''
                );
                fd.append('question_status_id', value);
                fd.append('user_id', this.globals.authData.id);
                this.btn_disabled = true;
                if (
                  value ==
                  this.globals.configurationText.questionStatus.saveAsDraft
                ) {
                  setTimeout(() => {
                    this.globals.postQuestionText =
                      'Please wait, saving Thread as draft…';
                    this.globals.postQuestionLoading = true;
                  }, 300);
                } else {
                  this.globals.postQuestionText =
                    'Please wait, publishing your Thread';
                  this.globals.postQuestionLoading = true;
                }
                this.HomeService.postQuestion(fd).then(
                  (data) => {
                    this.btn_disabled = false;
                    console.log(data);
                    if (
                      value ==
                      this.globals.configurationText.questionStatus.saveAsDraft
                    ) {
                      var title = 'Save As Draft';
                      var text = 'Thread has been drafted successfully';
                      var type = 'draft';
                    } else {
                      var title = 'Post Thread';
                      var text = 'Thread has been posted successfully';
                      var type = 'open';
                    }
                    var d1 = new Date(),
                      d2 = new Date(d1);
                    d2.setMinutes(
                      d1.getMinutes() + this.globals.cookieMessageExpireTime
                    );
                    this.cookieService.set(
                      'successMessage',
                      text,
                      this.globals.cookieMessageExpireTime,
                      '/'
                    );
                    this.postQuestionEntity = {};
                    this.contactSubmitted = false;
                    postQuestionForm3.form.markAsPristine();
                    // window.location.href = '/userDashboard/' + window.btoa(type);
                    window.location.href = '/userDashboard';

                    this.postQuestionEntity.question_type_id = '';
                    this.postQuestionEntity.community_id = '';
                    this.questionSendOptionsList = this.sendOptionsList;
                    this.globals.postQuestionLoading = false;
                    $('#question_text').val('');
                    this.countChar();
                    console.log(this.postQuestionEntity.question_text);
                  },
                  (error) => {
                    this.btn_disabled = false;
                    this.globals.postQuestionLoading = false;
                    console.log(error);
                    if (error.status == 422) {
                      Swal.fire({
                        icon: 'warning',
                        type: 'warning',
                        title: 'Oops..',
                        text: error.error.message,
                        showCancelButton: false,
                        showConfirmButton: true,
                        confirmButtonClass: 'theme_btn',
                        confirmButtonText: 'Ok',
                        allowOutsideClick: false,
                      });
                    }
                    this.btn_disabled = false;
                  }
                );
                console.log(this.postQuestionEntity);
              }
            } else if (this.OptionsList.length == 6) {
              if (
                postQuestionForm3.form.value.OptionValue0 ==
                  postQuestionForm3.form.value.OptionValue1 ||
                postQuestionForm3.form.value.OptionValue0 ==
                  postQuestionForm3.form.value.OptionValue2 ||
                postQuestionForm3.form.value.OptionValue0 ==
                  postQuestionForm3.form.value.OptionValue3 ||
                postQuestionForm3.form.value.OptionValue0 ==
                  postQuestionForm3.form.value.OptionValue4 ||
                postQuestionForm3.form.value.OptionValue0 ==
                  postQuestionForm3.form.value.OptionValue5 ||
                postQuestionForm3.form.value.OptionValue1 ==
                  postQuestionForm3.form.value.OptionValue2 ||
                postQuestionForm3.form.value.OptionValue1 ==
                  postQuestionForm3.form.value.OptionValue3 ||
                postQuestionForm3.form.value.OptionValue1 ==
                  postQuestionForm3.form.value.OptionValue4 ||
                postQuestionForm3.form.value.OptionValue1 ==
                  postQuestionForm3.form.value.OptionValue5 ||
                postQuestionForm3.form.value.OptionValue2 ==
                  postQuestionForm3.form.value.OptionValue3 ||
                postQuestionForm3.form.value.OptionValue2 == 4 ||
                postQuestionForm3.form.value.OptionValue2 ==
                  postQuestionForm3.form.value.OptionValue5 ||
                postQuestionForm3.form.value.OptionValue3 ==
                  postQuestionForm3.form.value.OptionValue4 ||
                postQuestionForm3.form.value.OptionValue3 ==
                  postQuestionForm3.form.value.OptionValue5 ||
                postQuestionForm3.form.value.OptionValue4 ==
                  postQuestionForm3.form.value.OptionValue5
              ) {
                Swal.fire({
                  icon: 'warning',
                  type: 'warning',
                  title: 'Oops..',
                  text: 'Options Should be Unique',
                  showCancelButton: false,
                  showConfirmButton: true,
                  confirmButtonClass: 'theme_btn',
                  confirmButtonText: 'Ok',
                  allowOutsideClick: false,
                });
              } else {
                if ($('input[name="multiSelection"]').is(':checked')) {
                  this.postQuestionEntity.question_type_id =
                    $('#multiSelection').val();
                  this.postQuestionEntity.question_type_multiSelect_id =
                    this.globals.configurationText.questionType.multiPickList;
                } else {
                  this.postQuestionEntity.question_type_multiSelect_id =
                    this.postQuestionEntity.question_type_id;
                }
                if (
                  this.postQuestionEntity.question_type_id ==
                    this.globals.configurationText.questionType.pickList ||
                  this.postQuestionEntity.question_type_id ==
                    this.globals.configurationText.questionType.multiPickList
                ) {
                  this.postQuestionEntity.question_option = this.OptionsList;
                } else if (
                  this.postQuestionEntity.question_type_id ==
                  this.globals.configurationText.questionType.rankedList
                ) {
                  this.postQuestionEntity.question_option = this.RankList;
                }

                var fd = new FormData();
                if (
                  this.postQuestionEntity.image_video_input_file != '' &&
                  this.postQuestionEntity.image_video_input_file != undefined
                ) {
                  var input = this.postQuestionEntity.image_video_input_file;
                  if (input.files && input.files[0]) {
                    var Images =
                      Date.now() + '_' + input.files[0].name.replace(/ /g, '_');
                    fd.append('image_video_file', input.files[0], Images);
                    fd.append('image_video', Images);
                  }
                }
                if (
                  this.postQuestionEntity.image_video_path != '' &&
                  this.postQuestionEntity.image_video_path != undefined
                ) {
                  fd.append('image_video', this.postQuestionEntity.image_video);
                  fd.append('path', this.postQuestionEntity.path);
                  fd.append('name', this.postQuestionEntity.image_video);
                }
                if (
                  this.postQuestionEntity.image_video == undefined ||
                  this.push_Image_video.length == 0
                ) {
                  fd.append('image_video_url', '[]');
                } else {
                  fd.append(
                    'image_video_url',
                    JSON.stringify(this.postQuestionEntity.image_video)
                  );
                }
                console.log($.type(this.postQuestionEntity.contact_details));
                if (
                  this.postQuestionEntity.contact_details != undefined &&
                  $.type(this.postQuestionEntity.contact_details) != 'array'
                ) {
                  this.postQuestionEntity.contact_details =
                    this.postQuestionEntity.contact_details.split(',');
                }
                // else {
                //   this.postQuestionEntity.contact_details = '';
                // }
                console.log(this.postQuestionEntity.contact_details);
                console.log(this.postQuestionEntity.contactDetails);
                console.log(
                  JSON.stringify(this.postQuestionEntity.contact_details)
                );
                if (this.questionId) {
                  fd.append('question_id', this.questionId);
                }
                fd.append(
                  'question_text',
                  this.postQuestionEntity.question_text
                );
                fd.append('post_as', this.postQuestionEntity.postas);
                fd.append(
                  'question_type_id',
                  this.postQuestionEntity.question_type_id
                );
                if (
                  this.postQuestionEntity.question_option &&
                  (this.postQuestionEntity.question_type_id ==
                    this.globals.configurationText.questionType.pickList ||
                    this.postQuestionEntity.question_type_id ==
                      this.globals.configurationText.questionType
                        .multiPickList ||
                    this.postQuestionEntity.question_type_id ==
                      this.globals.configurationText.questionType.rankedList)
                ) {
                  fd.append(
                    'question_option',
                    JSON.stringify(this.postQuestionEntity.question_option)
                  );
                }
                if (
                  this.postQuestionEntity.contact_details != undefined &&
                  this.postQuestionEntity.contact_details != ''
                ) {
                  var phoneformat = /^([0-9()_ ]{10,14})+$/;
                  for (
                    var a = 0;
                    a < this.postQuestionEntity.contact_details.length;
                    a++
                  ) {
                    if (
                      this.postQuestionEntity.contact_details[a].match(
                        phoneformat
                      )
                    ) {
                      this.postQuestionEntity.contact_details[a] =
                        this.postQuestionEntity.contact_details[a].replace(
                          /\D/g,
                          ''
                        );
                    }
                  }
                  fd.append(
                    'contact_details',
                    JSON.stringify(
                      this.postQuestionEntity.contact_details.filter(function (
                        v
                      ) {
                        return $.trim(v) !== '';
                      })
                    )
                  );
                }
                var community_id = '183c0d0d-508d-11ee-b584-9840bb1952c8';
                this.UserCommunity.push(community_id);
                this.postQuestionEntity.community_id = this.UserCommunity;
                fd.append(
                  'community_id',
                  JSON.stringify(this.postQuestionEntity.community_id)
                );
                fd.append(
                  'answer_only_to_me',
                  this.postQuestionEntity.answer_only_to_me
                    ? this.postQuestionEntity.answer_only_to_me
                    : ''
                );
                fd.append(
                  'invitee_can_invite_others',
                  this.postQuestionEntity.invitee_can_invite_others
                    ? this.postQuestionEntity.invitee_can_invite_others
                    : ''
                );
                fd.append(
                  'invitees_only_to_me',
                  this.postQuestionEntity.invitees_only_to_me
                    ? this.postQuestionEntity.invitees_only_to_me
                    : ''
                );
                fd.append(
                  'send_anonymously',
                  this.postQuestionEntity.send_anonymously
                    ? this.postQuestionEntity.send_anonymously
                    : ''
                );
                fd.append('question_status_id', value);
                fd.append('user_id', this.globals.authData.id);
                this.btn_disabled = true;
                if (
                  value ==
                  this.globals.configurationText.questionStatus.saveAsDraft
                ) {
                  setTimeout(() => {
                    this.globals.postQuestionText =
                      'Please wait, saving Thread as draft…';
                    this.globals.postQuestionLoading = true;
                  }, 300);
                } else {
                  this.globals.postQuestionText =
                    'Please wait, publishing your Thread';
                  this.globals.postQuestionLoading = true;
                }
                this.HomeService.postQuestion(fd).then(
                  (data) => {
                    this.btn_disabled = false;
                    console.log(data);
                    if (
                      value ==
                      this.globals.configurationText.questionStatus.saveAsDraft
                    ) {
                      var title = 'Save As Draft';
                      var text = 'Thread has been drafted successfully';
                      var type = 'draft';
                    } else {
                      var title = 'Post Thread';
                      var text = 'Thread has been posted successfully';
                      var type = 'open';
                    }
                    var d1 = new Date(),
                      d2 = new Date(d1);
                    d2.setMinutes(
                      d1.getMinutes() + this.globals.cookieMessageExpireTime
                    );
                    this.cookieService.set(
                      'successMessage',
                      text,
                      this.globals.cookieMessageExpireTime,
                      '/'
                    );
                    this.postQuestionEntity = {};
                    this.contactSubmitted = false;
                    postQuestionForm3.form.markAsPristine();
                    // window.location.href = '/userDashboard/' + window.btoa(type);
                    window.location.href = '/userDashboard';

                    this.postQuestionEntity.question_type_id = '';
                    this.postQuestionEntity.community_id = '';
                    this.questionSendOptionsList = this.sendOptionsList;
                    this.globals.postQuestionLoading = false;
                    $('#question_text').val('');
                    this.countChar();
                    console.log(this.postQuestionEntity.question_text);
                  },
                  (error) => {
                    this.btn_disabled = false;
                    this.globals.postQuestionLoading = false;
                    console.log(error);
                    if (error.status == 422) {
                      Swal.fire({
                        icon: 'warning',
                        type: 'warning',
                        title: 'Oops..',
                        text: error.error.message,
                        showCancelButton: false,
                        showConfirmButton: true,
                        confirmButtonClass: 'theme_btn',
                        confirmButtonText: 'Ok',
                        allowOutsideClick: false,
                      });
                    }
                    this.btn_disabled = false;
                  }
                );
                console.log(this.postQuestionEntity);
              }
            }
          } else {
            var fd = new FormData();
            if (
              this.postQuestionEntity.image_video_input_file != '' &&
              this.postQuestionEntity.image_video_input_file != undefined
            ) {
              var input = this.postQuestionEntity.image_video_input_file;
              if (input.files && input.files[0]) {
                var Images =
                  Date.now() + '_' + input.files[0].name.replace(/ /g, '_');
                fd.append('image_video_file', input.files[0], Images);
                fd.append('image_video', Images);
              }
            }
            if (
              this.postQuestionEntity.image_video_path != '' &&
              this.postQuestionEntity.image_video_path != undefined
            ) {
              fd.append('image_video', this.postQuestionEntity.image_video);
              fd.append('path', this.postQuestionEntity.path);
              fd.append('name', this.postQuestionEntity.image_video);
            }
            if (
              this.postQuestionEntity.image_video == undefined ||
              this.push_Image_video.length == 0
            ) {
              fd.append('image_video_url', '[]');
            } else {
              fd.append(
                'image_video_url',
                JSON.stringify(this.postQuestionEntity.image_video)
              );
            }
            console.log(this.push_Image_video);
            console.log($.type(this.postQuestionEntity.contact_details));
            if (
              this.postQuestionEntity.contact_details != undefined &&
              $.type(this.postQuestionEntity.contact_details) != 'array'
            ) {
              this.postQuestionEntity.contact_details =
                this.postQuestionEntity.contact_details.split(',');
            }
            // else {
            //   this.postQuestionEntity.contact_details = '';
            // }
            console.log(this.postQuestionEntity.contact_details);
            console.log(this.postQuestionEntity.contactDetails);
            console.log(
              JSON.stringify(this.postQuestionEntity.contact_details)
            );
            if (this.questionId) {
              fd.append('question_id', this.questionId);
            }
            fd.append('question_text', this.postQuestionEntity.question_text);
            fd.append('post_as', this.postQuestionEntity.postas);
            fd.append(
              'question_type_id',
              this.postQuestionEntity.question_type_id
            );
            if (
              this.postQuestionEntity.question_option &&
              (this.postQuestionEntity.question_type_id ==
                this.globals.configurationText.questionType.pickList ||
                this.postQuestionEntity.question_type_id ==
                  this.globals.configurationText.questionType.multiPickList ||
                this.postQuestionEntity.question_type_id ==
                  this.globals.configurationText.questionType.rankedList)
            ) {
              fd.append(
                'question_option',
                JSON.stringify(this.postQuestionEntity.question_option)
              );
            }
            if (
              this.postQuestionEntity.contact_details != undefined &&
              this.postQuestionEntity.contact_details != ''
            ) {
              var phoneformat = /^([0-9()_ ]{10,14})+$/;
              for (
                var a = 0;
                a < this.postQuestionEntity.contact_details.length;
                a++
              ) {
                if (
                  this.postQuestionEntity.contact_details[a].match(phoneformat)
                ) {
                  this.postQuestionEntity.contact_details[a] =
                    this.postQuestionEntity.contact_details[a].replace(
                      /\D/g,
                      ''
                    );
                }
              }
              fd.append(
                'contact_details',
                JSON.stringify(
                  this.postQuestionEntity.contact_details.filter(function (v) {
                    return $.trim(v) !== '';
                  })
                )
              );
            }
            var community_id = '183c0d0d-508d-11ee-b584-9840bb1952c8';
            this.UserCommunity.push(community_id);
            this.postQuestionEntity.community_id = this.UserCommunity;
            fd.append(
              'community_id',
              JSON.stringify(this.postQuestionEntity.community_id)
            );
            fd.append(
              'answer_only_to_me',
              this.postQuestionEntity.answer_only_to_me
                ? this.postQuestionEntity.answer_only_to_me
                : ''
            );
            fd.append(
              'invitee_can_invite_others',
              this.postQuestionEntity.invitee_can_invite_others
                ? this.postQuestionEntity.invitee_can_invite_others
                : ''
            );
            fd.append(
              'invitees_only_to_me',
              this.postQuestionEntity.invitees_only_to_me
                ? this.postQuestionEntity.invitees_only_to_me
                : ''
            );
            fd.append(
              'send_anonymously',
              this.postQuestionEntity.send_anonymously
                ? this.postQuestionEntity.send_anonymously
                : ''
            );
            fd.append('question_status_id', value);
            fd.append('user_id', this.globals.authData.id);
            this.btn_disabled = true;
            if (
              value == this.globals.configurationText.questionStatus.saveAsDraft
            ) {
              setTimeout(() => {
                this.globals.postQuestionText =
                  'Please wait, saving Thread as draft…';
                this.globals.postQuestionLoading = true;
              }, 300);
            } else {
              this.globals.postQuestionText =
                'Please wait, publishing your Thread';
              this.globals.postQuestionLoading = true;
            }
            this.HomeService.postQuestion(fd).then(
              (data) => {
                this.btn_disabled = false;
                console.log(data);
                if (
                  value ==
                  this.globals.configurationText.questionStatus.saveAsDraft
                ) {
                  Swal.fire({
                    type: this.globals.configurationText.sweetAlertTypeIconSuccess,
                    icon: this.globals.configurationText.sweetAlertTypeIconSuccess,
                    customClass: this.globals.configurationText.sweetAlertClassSuccess,
                    toast: true,
                    title: 'Thread has been drafted successfully',
                    animation: true,
                    position: this.globals.configurationText.sweetAlertPosition,
                    showConfirmButton: false,
                    timer: this.globals.configurationText.sweetAlertTimer,
                    timerProgressBar: true,
                    showCancelButton: true,
                    cancelButtonText: 'x',
                  });
                } else {
                  Swal.fire({
                    type: this.globals.configurationText.sweetAlertTypeIconSuccess,
                    icon: this.globals.configurationText.sweetAlertTypeIconSuccess,
                    customClass: this.globals.configurationText.sweetAlertClassSuccess,
                    toast: true,
                    title: 'Thread has been posted successfully',
                    animation: true,
                    position: this.globals.configurationText.sweetAlertPosition,
                    showConfirmButton: false,
                    timer: this.globals.configurationText.sweetAlertTimer,
                    timerProgressBar: true,
                    showCancelButton: true,
                    cancelButtonText: 'x',
                  });
                }
                var d1 = new Date(),
                  d2 = new Date(d1);
                d2.setMinutes(
                  d1.getMinutes() + this.globals.cookieMessageExpireTime
                );
                // this.cookieService.set(
                //   'successMessage',
                //   '1111111111111',
                //   this.globals.cookieMessageExpireTime,
                //   '/'
                // );
                this.postQuestionEntity = {};
                this.contactSubmitted = false;
                postQuestionForm3.form.markAsPristine();
                // window.location.href = '/userDashboard/' + window.btoa(type);

                if (this.globals.isUser == '1') {
                  localStorage.setItem('isUser', '2');
                  this.globals.isUser = localStorage.getItem('isUser');
                }
                this.router.navigate(['/userDashboard']);
                //window.location.href = '/userDashboard';

                this.postQuestionEntity.question_type_id = '';
                this.postQuestionEntity.community_id = '';
                this.questionSendOptionsList = this.sendOptionsList;
                this.globals.postQuestionLoading = false;
                $('#question_text').val('');
                this.countChar();
                console.log(this.postQuestionEntity.question_text);
              },
              (error) => {
                this.btn_disabled = false;
                this.globals.postQuestionLoading = false;
                console.log(error);
                if (error.status == 422) {
                  Swal.fire({
                    icon: 'warning',
                    type: 'warning',
                    title: 'Oops..',
                    text: error.error.message,
                    showCancelButton: false,
                    showConfirmButton: true,
                    confirmButtonClass: 'theme_btn',
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                  });
                }
                this.btn_disabled = false;
              }
            );
            console.log(this.postQuestionEntity);
          }
        }
      }
    } else {
      this.errorTitle = 'Please enter a thread.';
    }
  }
}
