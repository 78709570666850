<div class="container-fluid login_register_bg fixed-head">
  <div class="loginregister_white_block">
    <div class="login_logo_block">
      <div class="text-center">
        <a routerLink="/login">
          <img src="assets/images/loginbg.png" width="300" class="img-fluid" alt="">
        </a>
      </div>
    </div>
        <div class="white_login_block">
          <div class="row d-flex align-items-center">
            <div class="col-12 col-xl-6 col-lg-6"></div>
            <div class="col-12 col-xl-6 col-lg-6">
              <div class="padding_login_block">
                <div class="login-head">
                  <h3 class="float-start">Sign In</h3>
                  <!-- <div class="login_button_block float-end">
                    <a routerLink="/register"
                      class="theme_btn">{{globals.commonTranslationText.loginPage.buttons.newAccount}}</a>
                  </div> -->
                  <div class="clearfix"></div>
                </div>
                <p class="padding_15">Enter your details to Sign In:</p>
                <div class="clearfix"></div>
                <form #loginForm="ngForm" (ngSubmit)="login(loginForm)">

                  <!-- <div class="radio_box">
                    <input type="radio" name="selectlogintype" (change)="validationCheck('email',loginForm)" id="role1"
                      value="Role1" checked>
                    <label for="role1">Email</label>
                  </div> -->
                  <!-- <div class="radio_box">
                    <input type="radio" name="selectlogintype" (change)="validationCheck('phone',loginForm)" id="role2"
                      value="Role2">
                    <label for="role2">Phone </label>
                  </div> -->
                  <!-- <div class="radio_box">
                    <input type="radio" name="selectlogintype" (change)="validationCheck('username',loginForm)"
                      id="role3" value="Role3">
                    <label for="role3">Username</label>
                  </div> -->


                  <!-- <input type="radio" checked name="selectlogintype" (change)="validationCheck('email',loginForm)">Email
                    <input type="radio" name="selectlogintype" (change)="validationCheck('phone',loginForm)">Phone
                    <input type="radio" name="selectlogintype" (change)="validationCheck('username',loginForm)">Username -->


                  <div class="form-group mb-5" id="email" *ngIf="showEmailBlock">
                    <label><span class="mendatory_feild">*</span>
                      {{globals.commonTranslationText.loginPage.form.emailAddress.label}}</label>
                    <input kendoTextBox type="text" class="form-control" #EmailAddress="ngModel"
                      [(ngModel)]="loginEntity.EmailAddress" name="EmailAddress" maxlength="100"
                      [ngClass]="{error: (EmailAddress.dirty && EmailAddress.invalid) || (EmailAddress.pristine && submitted) || errorEntity.email || checkEmailUsernameValid}"
                      required id="Emailaddress" (keyup)="checkvalidation()"
                      />
                    <span class="error_class"
                      *ngIf="(EmailAddress.dirty && EmailAddress.invalid) || (EmailAddress.pristine && submitted) || checkEmailUsernameValid">
                      <span *ngIf="EmailAddress.errors && EmailAddress.errors.required">
                        {{globals.commonTranslationText.loginPage.form.emailAddress.required}}
                      </span>
                      <span *ngIf="checkEmailUsernameValid">
                        {{globals.commonTranslationText.loginPage.form.emailAddress.pattern}}
                      </span>
                    </span>
                    <span class="error_class">
                      <span>{{ errorEntity.email }}</span>
                    </span>
                  </div>
                  <div class="form-group mb-5" id="phone" *ngIf="showPhoneNumberBlock">
                    <label><span class="mendatory_feild">*</span>
                      {{globals.commonTranslationText.loginPage.form.phoneNumber.label}}</label>
                    <input kendoTextBox type="text" class="form-control" #PhoneNumber="ngModel"
                      [(ngModel)]="loginEntity.PhoneNumber" name="PhoneNumber" maxlength="10"
                      [ngClass]="{error: (PhoneNumber.dirty && PhoneNumber.invalid) || (PhoneNumber.pristine && submitted) || errorEntity.email}"
                      required id="PhoneNumber" pattern="[0-9]{10}" />
                    <span class="error_class"
                      *ngIf="(PhoneNumber.dirty && PhoneNumber.invalid) || (PhoneNumber.pristine && submitted)">
                      <span *ngIf="PhoneNumber.errors && PhoneNumber.errors.required">
                        {{globals.commonTranslationText.loginPage.form.phoneNumber.required}}
                      </span>
                      <span *ngIf="PhoneNumber.errors && PhoneNumber.errors.pattern">
                        {{globals.commonTranslationText.loginPage.form.phoneNumber.pattern}}
                      </span>
                    </span>
                    <span class="error_class">
                      <span>{{ errorEntity.email }}</span>
                    </span>
                  </div>
                  <div class="form-group mb-5" id="username" *ngIf="showUsernameBlock">
                    <label><span class="mendatory_feild">*</span>
                      {{globals.commonTranslationText.loginPage.form.userName.label}}</label>
                    <input kendoTextBox type="text" class="form-control" #Username="ngModel"
                      [(ngModel)]="loginEntity.Username" name="Username" maxlength="100"
                      [ngClass]="{error: (Username.dirty && Username.invalid) || (Username.pristine && submitted) || errorEntity.email}"
                      required id="Username" pattern="[a-zA-Z]{1,}[a-zA-Z0-9]{1,}" />
                    <span class="error_class"
                      *ngIf="(Username.dirty && Username.invalid) || (Username.pristine && submitted)">
                      <span *ngIf="Username.errors && Username.errors.required">
                        {{globals.commonTranslationText.loginPage.form.userName.required}}
                      </span>
                      <span *ngIf="Username.errors && Username.errors.pattern">
                        {{globals.commonTranslationText.loginPage.form.userName.pattern}}
                      </span>
                    </span>
                    <span class="error_class">
                      <span>{{ errorEntity.email }}</span>
                    </span>
                  </div>
                  <div class="form-group mb-5">
                    <label><span class="mendatory_feild">*</span>
                      {{globals.commonTranslationText.loginPage.form.password.label}} </label>
                    <input kendoTextBox type="password" class="form-control" #Password="ngModel"
                      [(ngModel)]="loginEntity.Password" name="Password" maxlength="100" id="password"
                      pattern="[a-zA-Z0-9.-_-@$*&!#]{8,}"
                      [ngClass]="{error: (Password.dirty && Password.invalid) || (Password.pristine && submitted) || errorEntity.password}"
                      required />
                    <i class="fa-regular fa-fw fa-eye toggle-password icon_without_label" id="password-show"></i>
                    <span class="error_class"
                      *ngIf="(Password.dirty && Password.invalid) || (Password.pristine && submitted)">
                      <span *ngIf="Password.errors && Password.errors.required">
                        {{globals.commonTranslationText.loginPage.form.password.required}}
                      </span>
                      <span *ngIf="Password.errors && Password.errors.pattern">
                        {{globals.commonTranslationText.loginPage.form.password.pattern}}
                      </span>
                    </span>
                    <span class="error_class">
                      <span>{{ errorEntity.password }}</span>
                    </span>
                  </div>
                  <div>
                    <button kendoButton type="submit" [disabled]="btn_disable"
                      class="theme_btn float-end">{{globals.commonTranslationText.loginPage.buttons.submit}}</button>
                    <a routerLink="/forgotPassword"
                      class="float-start forgotpswd">{{globals.commonTranslationText.loginPage.buttons.forgotPassword}}</a>
                    <div class="clearfix"></div>
                  </div>
                </form>
              </div>

            </div>
          </div>
        </div>
  </div>
</div>