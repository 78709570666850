import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Globals } from '../globals';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
declare var $, PerfectScrollbar, Swal: any, Bloodhound: any;

@Component({
  selector: 'app-pagenotfound',
  templateUrl: './pagenotfound.component.html',
  styleUrls: ['./pagenotfound.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class PagenotfoundComponent implements OnInit {

  constructor(public globals: Globals, private router: Router, private route: ActivatedRoute) { }
  code;

  ngOnInit(): void {
    if (this.globals.isUser == '1') {
      $("#notfound_page").addClass('col-md-10 col-sm-10 offset-md-2 main_block');
    }
    setTimeout(function () {

      // $(document).ready(function () {
      //   const body = document.querySelector('body');
      //   body.style.setProperty('--screen-height', $(window).height() + "px");
      // });
      // new PerfectScrollbar('.content_height');

    }, 100);

    this.globals.isLoading = true;
    this.code = window.atob(this.route.snapshot.paramMap.get('code'));
    if (this.code) {
      this.globals.isLoading = false;
    }
  }

}
