import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../environments/environment';
import { HttpClient } from "@angular/common/http";

import { CookieService } from 'ngx-cookie-service';
import * as commonTranslationText from "../assets/data/commonTranslationText.json";
import * as adminTranslationText from "../assets/data/adminTranslationText.json";
import * as permissionText from "../assets/data/permissionTranslationText.json";
import * as configurationText from "../assets/data/configuration.json";
import { trim } from '@amcharts/amcharts4/.internal/core/utils/Utils';
declare var Swal:any,$;
// console.log(commonTranslationText);
// console.log(adminTranslationText);
@Injectable()
export class Globals {
    constructor(private http: HttpClient,
      private cookieService: CookieService) { }
      
    baseAPIUrl: string = environment.apiUrl;
    baseUrl: string = environment.baseUrl;
    profileImgeapiUrl: string = environment.profileImgeapiUrl;
    headerpath: string = "{'Content-Type': 'application/json','Accept': 'application/json'}";
    IsLoggedIn: boolean = false;
    isLoading: boolean = false;
    postQuestionLoading: boolean = false;
    postQuestionText:string = '';
    currentLink: string = '';
    currentLinkURL: string = '';
    currentModule: string = '';
    authData = localStorage.getItem('authData') ? JSON.parse(localStorage.getItem('authData')) : null;
    msgflag = false;
    message = '';
    type = '';
	viewCount= 0;
    check_login = false;
    todaysdate: string = '';
    commonTranslationText = commonTranslationText['default'];
    adminTranslationText = adminTranslationText['default'];
    permissionText = permissionText['default'];
    configurationText = configurationText['default'];
    pageSize = 15;
    isUser=localStorage.getItem('isUser') ? localStorage.getItem('isUser') : null;
    selectedCurrency = 'usd';
    permissions;
    cookieMessageExpireTime;
    reportPopUp;
    reportQuestionAnswerSubmitted;
    reportQuestionAnswerEntity:any = {};
    likeDislikeEntity:any = {};
    imageArrayExtensions;
    videoArrayExtensions;
    pageNotfound(code) {
        window.location.href = 'pagenotfound/' + window.btoa(code);
    }
    
    reportPopUpOpen(question_id,invitee_answer_id,postedBy)
    {//debugger
      if(this.authData == undefined || this.authData == '')
      {
        window.location.href = '/login';
        var d1 = new Date(),
        d2 = new Date(d1);
      d2.setMinutes(d1.getMinutes() + this.configurationText.cookieMessageExpireTime);
        this.cookieService.set('URL', this.currentLink, d2,'/');
        this.cookieService.set('errorMessage', 'Please login.',d2,'/');
      }
      else
      {
        this.reportQuestionAnswerSubmitted = false;
        this.reportQuestionAnswerEntity = {};
        this.reportPopUp = true;
        this.reportQuestionAnswerEntity.question_id = question_id;
        this.reportQuestionAnswerEntity.invitee_answer_id = invitee_answer_id;
        this.reportQuestionAnswerEntity.postedBy = postedBy;
        if(this.reportQuestionAnswerEntity.invitee_answer_id != '')
        {
          this.reportQuestionAnswerEntity.title = 'response';
        }
        else
        {
          this.reportQuestionAnswerEntity.title = 'thread';
        }
      }
      
    }
    closeReportPopUpOpen()
    {
      this.reportPopUp = false;
      this.reportQuestionAnswerEntity.question_id = '';
      this.reportQuestionAnswerEntity.invitee_answer_id = '';
    }
    reportQuestionAnswer(reportQuestionAnswerForm)
    {//debugger
      this.reportQuestionAnswerSubmitted = true;
      if(trim(this.reportQuestionAnswerEntity.comment) == '')
      {
        this.reportQuestionAnswerEntity.error = true;
      }
      else
      {
        this.reportQuestionAnswerEntity.error = false;
      }
      if(reportQuestionAnswerForm.valid && this.reportQuestionAnswerEntity.error == false)
      {
        this.isLoading = true;
        var url,text;
        console.log(this.reportQuestionAnswerEntity);
        if(this.reportQuestionAnswerEntity.invitee_answer_id == '')
        {
          url = 'questionDetail/reportQuestion';
          text = 'Thread has been reported successfully';
        }
        else
        {
          url = 'questionDetail/reportAnswer';
          text = 'Response has been reported successfully';
        }
      
        let promise = new Promise((resolve, reject) => {
          this.http.post(this.baseAPIUrl + url,this.reportQuestionAnswerEntity)
            .toPromise()
            .then(
              res => { // Success
                resolve(res);
                this.reportPopUp = false;
                this.isLoading = false;
                this.reportQuestionAnswerEntity.reportedTime = res['data'];
                var d1 = new Date(),
                d2 = new Date(d1);
                d2.setMinutes(d1.getMinutes() + this.configurationText.cookieMessageExpireTime);
                Swal.fire({
                  type: this.configurationText.sweetAlertTypeIconSuccess,
                  icon: this.configurationText.sweetAlertTypeIconSuccess,
                  customClass: this.configurationText.sweetAlertClassSuccess,
                  toast: true,
                  title: text,
                  animation: true,
                  position: this.configurationText.sweetAlertPosition,
                  showConfirmButton: false,
                  timer: this.configurationText.sweetAlertTimer,
                  timerProgressBar: true,
                  showCancelButton: true,
                  cancelButtonText: 'x',
                });
                // this.cookieService.set('successMessage', 'Question has been reported successfully',d2,'/');
              },
              msg => { // Error
                reject(msg);
              }
            );
        });
        return promise;
      }
      
    }

    likeDislikeQuestionAnswer(question_id,is_like,invitee_answer_id)
    {
        var url,text;
        console.log(this.likeDislikeEntity);
        if(this.authData == undefined || this.authData == '')
        {
          window.location.href = '/login';
          var d1 = new Date(),
          d2 = new Date(d1);
        d2.setMinutes(d1.getMinutes() + this.configurationText.cookieMessageExpireTime);
          this.cookieService.set('URL', this.currentLink, d2,'/');
          this.cookieService.set('errorMessage', 'Please login.',d2,'/');
        }
        else{
          if(invitee_answer_id == '')
          {
            url = 'questionDetail/likeQuestion';
            this.likeDislikeEntity = {
              'question_id' : question_id,
              'is_like' : is_like
            }
          }
          else
          {
            url = 'questionDetail/likeAnswer';
            this.likeDislikeEntity = {
              'invitee_answer_id' : invitee_answer_id,
              'question_id' : question_id,
              'is_like' : is_like
            }
          }
        
          let promise = new Promise((resolve, reject) => {
            this.http.post(this.baseAPIUrl + url,this.likeDislikeEntity)
              .toPromise()
              .then(
                res => { // Success
                  //debugger
                  resolve(res);
                },
                msg => { // Error
                  reject(msg);
                }
              );
          });
          return promise;
        }
        
    }
    checkPermission() {
      let promise = new Promise((resolve, reject) => {
        if (localStorage.getItem('getUserPermission')) {
          let retrievedObject = JSON.parse(localStorage.getItem('getUserPermission'));
          resolve(retrievedObject);
        }
        else
        {
          this.http.get(this.baseAPIUrl + 'permission/' + this.authData.id)
            .toPromise()
            .then(
              res => { // Success 
                // if(this.authData.roles_id == 1)
                // {
                //   let permissionEnity1 = [];
                //   res["data"].forEach(function (menu, key) {
                //     //alert(key);
                //     let obj = {"slug":menu.slug, "has_access":1}
                //     permissionEnity1.push(obj);
                //   });
                //   localStorage.setItem('getUserPermission', JSON.stringify(permissionEnity1));
                //   resolve(permissionEnity1);
                // }
                // else
                // {
                  localStorage.setItem('getUserPermission', JSON.stringify(res["data"]));
                  resolve(res["data"]);
                // }
                
                
                
              },
              msg => { // Error
                reject(msg);
              }
            );
        }
      });

      return promise;
    }
    
    checkRoutePermission() {//debugger
      let retrievedObject1, index;
      let permissionEnity1 = {};
      retrievedObject1 = JSON.parse(localStorage.getItem('getUserPermission'));
      
      if (this.authData != null && retrievedObject1 != "undefined" && retrievedObject1 != null) {
        this.permissionText.forEach(function (menu, key) {
          index = retrievedObject1.findIndex(retrievedObject1 => (retrievedObject1.slug === menu.key && retrievedObject1.has_access == 1))
          if (index != -1) {
            permissionEnity1[menu.key] = true;
          } else {
            permissionEnity1[menu.key] = false;
          }
        });
        this.permissions = permissionEnity1;
        console.log(this.permissions);
        return this.permissions;
      }
    }

    topDecideItStars(flag,id,star_limit) {
      //debugger
      var entity;
      if(flag == "top_responders")
      {
         entity = {
          'community_id' : id,
          'flag' : flag,
          'responder_limit':star_limit
        }
      }
      else
      {
         entity = {
          'community_id' : id,
          'flag' : flag,
          'star_limit':star_limit
        }
      }
    
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.baseAPIUrl + 'getHomeData',entity)
        .toPromise()
        .then(
          res => { // Success
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
    }

    pageScroll()
    {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    getImageVideoExtensions()
    {
      let promise = new Promise((resolve, reject) => {
          this.http.get(this.baseAPIUrl + 'getImageVideoExtensions')
            .toPromise()
            .then(
              res => { // Success
                resolve(res);
                this.imageArrayExtensions = res['data']['ImageExtensions'];
                // this.cookieService.set('successMessage', 'Question has been reported successfully',d2,'/');
              },
              msg => { // Error
                reject(msg);
              }
            );
        });
        return promise;
    }
    extensionCheck(list)
    {
      let promise = new Promise((resolve, reject) => {
        this.http.get(this.baseAPIUrl + 'getImageVideoExtensions')
          .toPromise()
          .then(
            res => { // Success
              resolve(res);
              var imageArrayExtensions = res['data']['ImageExtensions']; 
              var videoArrayExtensions = res['data']['VideoExtensions'];
              for(var i=0;i<list.length;i++)
              {
                console.log(imageArrayExtensions);
                
                if(list[i].file_extention != '')
                {
                  if($.inArray(list[i].file_extention.toLowerCase(), imageArrayExtensions) != -1)
                  {
                    list[i].imageExtension = true;
                  }
                  else if($.inArray(list[i].file_extention.toLowerCase(), videoArrayExtensions) != -1)
                  {
                    list[i].videoExtension = true;
                  }
                }
              }
              // this.imageArrayExtensions = 
              console.log(res);
              // this.cookieService.set('successMessage', 'Question has been reported successfully',d2,'/');
            },
            msg => { // Error
              reject(msg);
            }
          );
      });
      return list;
      // this.getImageVideoExtensions();
      // console.log(list.length);
      // var imageArrayExtensions =[]; 
      // imageArrayExtensions.push(localStorage.getItem('imageArrayExtensions'));
      // //  JSON.stringify(localStorage.getItem('imageArrayExtensions'));
      // for(var i=0;i<list.length;i++)
      // {
      //   console.log(imageArrayExtensions);
      //   var videoArrayExtensions = ['mp4', 'MP4',' MKV', 'mkv', 'WEBM', 'webm', 'avi','mov'];
      //   if(list[i].file_extention != '')
      //   {
      //     if($.inArray(list[i].file_extention.toLowerCase(), imageArrayExtensions) != -1)
      //     {
      //       list[i].imageExtension = true;
      //     }
      //     else if($.inArray(list[i].file_extention.toLowerCase(), videoArrayExtensions) != -1)
      //     {
      //       list[i].videoExtension = true;
      //     }
      //   }
      // }
      // return list;
    }
}