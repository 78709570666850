<div class="home_wrap dashboard_wrap decideit_stars pt_50 fixed-head" id="star_page">
  <div class="container-fluid">
    <div class="post_que_header mb-5">
      <!-- <h2 *ngIf="flag == 'star'" class="decideit_head float-start"><span>Eyedea Exchange</span> Star</h2>
                  <h2 *ngIf="flag == 'responder'" class="decideit_head float-start"><span>Popular </span>Stars
                  </h2> -->
      <h2 class="decideit_head float-start">
        <span>EYEXchange Connect</span> Stars
      </h2>
      <div class="clearfix"></div>
      <p>
        IJCAHPO EYEXchange Connect Stars is our recognition board for users
        that help drive the conversations and engagement in the public
        community forums of DecideIt. The Community features will go live
        in the coming months, and those who most actively contribute and
        respond will find themselves on our daily Stars leader boards.
        These members are also eligible for invitation to the IJCAHPO
        EYEXchange Connect leadership forums, so keep an eye out for the
        launch of this great new feature.
      </p>
      <!-- <p *ngIf="flag == 'responder'">Leaderboard of active public users
                  </p> -->
    </div>
    <div class="row pb-5">
      <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
        <!-- <div class="dashboard_filter_wrap"> -->
          <!-- <div class="post_que_header"> -->
            <!-- <h2 *ngIf="flag == 'star'" class="decideit_head float-start"><span>Eyedea Exchange</span> Star</h2>
                        <h2 *ngIf="flag == 'responder'" class="decideit_head float-start"><span>Popular </span>Stars
                        </h2> -->
            <!-- <h2 class="decideit_head float-start">
              <span>Eyedea Exchange</span> Star
            </h2>
            <div class="clearfix"></div>
            <p>
              IJCAHPO Eyedea Exchange Stars is our recognition board for users
              that help drive the conversations and engagement in the public
              community forums of DecideIt. The Community features will go live
              in the coming months, and those who most actively contribute and
              respond will find themselves on our daily Stars leader boards.
              These members are also eligible for invitation to the IJCAHPO
              Eyedea Exchange leadership forums, so keep an eye out for the
              launch of this great new feature.
            </p> -->
            <!-- <p *ngIf="flag == 'responder'">Leaderboard of active public users
                        </p> -->
          <!-- </div> -->
        <!-- </div> -->
        <div class="decideit_stars_wrap">
          <div
            class="decideitstar_legend"
            *ngIf="decideItStarResponderList.length > 0"
          >
            <ul>
              <li>
                <span class="fiftyqa"></span> Threads
              </li>
              <li><span class="fifteenqa"></span> Comments</li>
              <!-- <li *ngIf="flag == 'star'">
                                <span class="open_que"></span> Active Threads
                            </li> -->
              <li><span class="open_que"></span>Likes</li>
            </ul>
          </div>
          <div
            class="search-results"
            infinite-scroll
            [infiniteScrollDistance]="scrollDistance"
            [infiniteScrollUpDistance]="scrollUpDistance"
            [infiniteScrollThrottle]="throttle"
            (scrolled)="onScrollDown($event)"
          >
            <div class="row">
              <div
                class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 d-flex"
                *ngFor="let star of decideItStarResponderList"
              >
                <div class="distar_box">
                  <div class="dib_top">
                    <img
                      *ngIf="
                        star.profile_image_url == null ||
                        star.profile_image_url == ''
                      "
                      id="user_img"
                      src="assets/images/dummy.jpg"
                      alt=""
                      class="img-fluid"
                    />
                    <img
                      *ngIf="
                        star.profile_image_url != null &&
                        star.profile_image_url != ''
                      "
                      id="user_img"
                      src="{{ globals.profileImgeapiUrl }}/{{
                        star.profile_image_url
                      }}"
                      alt=""
                      class="img-fluid"
                    />
                    <div class="dis_overlay" *ngIf="star.is_profile_private == 1" >
                      <p>This Profile is Private</p>
                    </div>
                    <div class="dis_overlay" *ngIf="star.is_profile_private == ''">
                      <a (click)="publicProfile(star.id)">View Full Profile</a>
                    </div>
                  </div>
                  <div class="dib_bottom">
                    <!-- <span class="dis_name"
                                            *ngIf="star.is_profile_private == 0 && ((star.first_name != '' || star.first_name != null) && (star.last_name != '' || star.last_name != null) )">{{star.first_name}}
                                            {{star.last_name}}</span> -->
                    <!-- <span class="dis_name">{{star.user_name}}</span> -->
                    <span class="dis_name"
                      >{{ star.first_name }} {{ star.last_name }}</span
                    >
                    <!-- <span class="dis_name" *ngIf="star.is_profile_private == 1">{{star.random_name}}</span> -->
                    <div class="clearfix"></div>
                    <span class="float-start label">Threads</span>
                    <span
                      class="float-end threads_num"
                      >{{ star.active_questions_count}}</span>
                    <div class="clearfix"></div>
                    <!-- <span *ngIf="flag == 'star'" class="float-start label">Active Threads</span>
                                        <span *ngIf="flag == 'star'"
                                            class="float-end active_que_label">{{star.active_questions_count}}</span> -->
                    <span class="float-start label">Comments</span>
                    <span class="float-end comments_num">{{ star.comments_count }}</span>
                    <div class="clearfix"></div>
                    <span class="float-start label">Likes</span>
                    <span
                      class="float-end likes_num"
                      >{{ star.question_likes_count}}</span>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
              <ng-container
                *ngIf="
                  decideItStarResponderLoader1 || decideItStarResponderLoader2
                "
              >
                <div
                  class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 d-flex"
                  *ngFor="let object of decideItStarResponderLoaderList"
                >
                  <div class="distar_box">
                    <div class="dib_bottom">
                      <ngx-shimmer-loading [height]="'120px'" [width]="'100%'">
                      </ngx-shimmer-loading>
                      <ngx-shimmer-loading [height]="'20px'" [width]="'100%'">
                      </ngx-shimmer-loading>
                      <div class="row">
                        <div class="col-xl-8 col-lg-8 col-12">
                          <ngx-shimmer-loading
                            [width]="'85px'"
                          ></ngx-shimmer-loading>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-12">
                          <ngx-shimmer-loading
                            [shape]="'circle'"
                            [height]="'18px'"
                            [width]="'18px'"
                          ></ngx-shimmer-loading>
                        </div>
                        <div class="col-xl-8 col-lg-8 col-12">
                          <ngx-shimmer-loading
                            [width]="'85px'"
                          ></ngx-shimmer-loading>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-12">
                          <ngx-shimmer-loading
                            [shape]="'circle'"
                            [height]="'18px'"
                            [width]="'18px'"
                          ></ngx-shimmer-loading>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <div
            class="no_records_found"
            *ngIf="
              decideItStarResponderList.length == 0 &&
              !decideItStarResponderLoader1
            "
          >
            <div class="no_que_found">
              <img src="assets/images/no_found.png" alt="" class="img-fluid" />
              There are no IJCAHPO EYEXchange Connect Stars
            </div>
          </div>
          <!-- <div class="no_records_found d-flex justify-content-center align-items-center"
                        *ngIf="decideItStarResponderList.length==0 && !decideItStarResponderLoader1 && flag == 'responder'">
                        There are no top responders
                    </div> -->
        </div>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
        <div class="home_wrap_right">
          <!-- <div class="decideit_info">
            <div class="post_que_header">
              <h2 class="decideit_head">
                <span>Welcome To</span> Eyedea Exchange
              </h2>
            </div>
            <div class="advertisement_box">
              <p>
                IJCAHPO Eyedea Exchange is your key to fast, easy and
                clutter-free group decisions.
              </p> -->
              <!-- <p>Send to up to 1000 friends instantly, Now!</p> -->
              <!--<p>Threads Remain Live for 99 Hrs</p>-->
            <!-- </div>
          </div> -->

          <div class="decide_it_stars_wrap mt-0">
            <div class="post_que_header">
              <h2 class="decideit_head"><span>Popular</span> Stars</h2>
            </div>
            <div
              class="dis_with_info pb-5"
              *ngIf="
                responderStarList.length > 0 && !decideItStarResponderLoader3
              "
            >
              <div class="decideitstar_legend">
                <ul>
                  <li>
                    <span class="fiftyqa"></span> Threads
                  </li>
                  <li><span class="fifteenqa"></span> Comments</li>
                  <li><span class="open_que"></span> Likes</li>
                </ul>
              </div>

              <ng-container
                *ngFor="let star of responderStarList; let last = last"
              >
                <div
                  class="dis_box d-flex align-items-center"
                  [ngClass]="{ no_border: last }"
                >
                  <div class="dis_photo">
                    <img
                      *ngIf="
                        star.profile_image_url == null ||
                        star.profile_image_url == ''
                      "
                      id="user_img"
                      src="assets/images/dummy.jpg"
                      alt=""
                      class="img-fluid"
                    />
                    <img
                      *ngIf="
                        star.profile_image_url != null &&
                        star.profile_image_url != ''
                      "
                      id="user_img"
                      src="{{ globals.profileImgeapiUrl }}/{{
                        star.profile_image_url
                      }}"
                      alt=""
                      class="img-fluid"
                    />
                  </div>
                  <div class="dis_info">
                    <a (click)="publicProfile(star.id)">
                      <!-- <span
                                                *ngIf="star.is_profile_private == 0 && ((star.first_name != '' || star.first_name != null) && (star.last_name != '' || star.last_name != null) )">{{star.first_name}}
                                                {{star.last_name}}</span> -->
                      <span>{{ star.first_name }} {{ star.last_name }}</span>
                      <!-- <span class="dis_name" *ngIf="star.is_profile_private == 1">{{star.random_name}}</span> -->
                    </a>
                    <br />
                    <span class="more_than_50">{{star.active_questions_count}}</span>
                    <span class="more_than_15">{{ star.comments_count }}</span>
                    <span class="open_question">{{star.question_likes_count}}</span>
                  </div>
                  <!-- <div class="dis_rank">
                                        <span>1</span>
                                    </div> -->
                  <div class="clearfix"></div>
                </div>
              </ng-container>
              <!-- <button kendoButton type="submit" (click)="starResponders('star')"
                                class="mt-4 theme_btn theme_btn d-flex justify-content-center mx-auto">View All</button> -->
            </div>
            <div
              class="dis_with_info pb-5"
              *ngIf="decideItStarResponderLoader3"
            >
              <div
                class="row community_shimmer d-flex align-items-center"
                *ngFor="let object of responderStarLoaderList"
              >
                <div class="col-xl-3 col-lg-3 col-12">
                  <ngx-shimmer-loading [shape]="'circle'" [width]="'50px'">
                  </ngx-shimmer-loading>
                </div>
                <div class="col-xl-9 col-lg-9 col-12">
                  <ngx-shimmer-loading [width]="'175px'"></ngx-shimmer-loading>
                  <ngx-shimmer-loading [width]="'175px'"></ngx-shimmer-loading>
                </div>
              </div>
            </div>

            <div
              *ngIf="
                responderStarList.length == 0 && !decideItStarResponderLoader3
              "
              class="no_records_found d-flex justify-content-center align-items-center"
            >
              <span>There are no IJCAHPO EYEXchange Connect Stars</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
