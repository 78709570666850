<header [ngClass]="{admin_header : (globals.authData!=undefined && this.globals.isUser=='1')}">
    <div class="container-fluid">
        <div class="row"
            *ngIf="globals.authData == undefined || (globals.authData!=undefined && this.globals.isUser=='2')">
            <!--Logo -->
            <div class="col-xl-auto col-lg-auto col-md-4 col-sm-6 col-8">
                <div class="header_logo"><a href="#"><img src="assets/images/logo-white.png" alt=""
                            class="img-fluid" /></a></div>
            </div>
            <!--End Logo -->
            <!-- Start Search -->
            <div class="col-xl col-lg d-flex align-items-center searchbar_desktop">
                <form #globalSearchForm="ngForm" (ngSubmit)="globalSearch()" class="flex-fill">
                    <div class="searchbar">
                        <div class="input-group">
                            <input #search="ngModel" [(ngModel)]="searchEntity.search" type="text" class="form-control"
                                placeholder="Search" name="search" id="search">
                            <button type="submit"><i class="fa-solid fa-magnifying-glass"></i></button>
                        </div>
                    </div>
                </form>

            </div>
            <!-- End Search -->
            <!-- Navigation -->
            <!--class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 d-flex align-items-center"-->
            <!--class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 d-flex align-items-center justify-content-end" *ngIf="globals.currentLink != '/login'"-->
            <div class="col-xl-auto col-lg-auto col-md-8 col-sm-6 col-4">
                <div class="d-flex h-100 justify-content-end">

                    <!-- <div class="post_quest_mobile_show">
                        <a (click)="redirect('postQuestion')">
                            <i class="fa-pencil fa-sm fa-solid"></i>
                        </a>
                    </div> -->
                    <div class="searchbar_mobile_show">
                        <!-- <input type="text" class="search_input" placeholder="Search"> -->
                        <input class="search_input" #search="ngModel" [(ngModel)]="searchEntity.search" type="text"
                            placeholder="Search" name="search" id="search">

                        <div class="search_icon" (click)="serach_expand()">
                            <i class="fa-solid fa-magnifying-glass" aria-hidden="true"></i>
                        </div>
                        <div class="search_close_icon" (click)="close_expand()">
                            <i class="fa-solid fa-xmark" aria-hidden="true"></i>
                            <i class="fa-solid fa-magnifying-glass" aria-hidden="true"></i>
                        </div>
                    </div>

                    <nav class="navbar navbar-expand-lg navbar-light p-0">
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon">
                                <!-- <i class="fa-solid fa-bars" aria-hidden="true"></i> -->
                            </span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav me-auto mx-auto">
                                <li class="nav-item active" [ngClass]="{active : globals.currentLink == '/home'}">
                                    <a class="nav-link margin_l0" [ngClass]="{active : globals.currentLink == '/home'}"
                                        (click)="redirect('home')" title="Home"><i class="fa-solid fa-house-chimney"></i> <span
                                            class="sr-only">(current)</span></a>
                                </li>
                                <li class="nav-item" [ngClass]="{active : globals.currentLink == '/userDashboard'}">
                                    <a class="nav-link" [ngClass]="{active : globals.currentLink == '/userDashboard'}"
                                        (click)="redirect('dashboard')" title="Dashboard"><i class="fa-solid fa-gauge-high"></i></a>
                                </li>
                                <li class="nav-item" [ngClass]="{active : globals.currentLink == '/admin/adminDashboard'}" *ngIf="globals.authData!=undefined && this.globals.authData.roles_id==1">
                                    <a class="nav-link" title="Admin Dashboard" (click)="redirect('admin')"
                                        [ngClass]="{active : globals.currentLink == '/admin/adminDashboard'}">
                                        <i class="fa-solid fa-user-tie"></i></a>
                                    </li>
                                <li class="nav-item" [ngClass]="{active : globals.currentLink == '/topQuestion'}"
                                    >
                                    <a class="nav-link" [ngClass]="{active : globals.currentLink == '/topQuestion'}"
                                        (click)="redirect('topquestion')" title="Popular Threads"><i
                                            class="fa-solid fa-rectangle-list"></i></a>
                                </li>

                                <!-- <li class="nav-item">
                                    <a class="nav-link">About Us</a>
                                </li> -->
                                <!-- <li class="nav-item">
                                    <a class="nav-link" title="Help"><i class="fa-regular fa-comments"></i></a>
                                </li> -->
                                <li class="nav-item" [ngClass]="{active : globals.currentLink == '/profile'}">
                                    <a class="nav-link" title="Profile" (click)="redirect('profile')"
                                        [ngClass]="{active : globals.currentLink == '/profile'}">
                                        <i class="fa-solid fa-user-gear"></i></a>
                                </li>
                                <!-- <li>
                                    <div class="user_dropdown notification_block dropdown">
                                        <button *ngIf="globals.authData == undefined || globals.authData == ''"
                                            class="dropdown-toggle" title="Notification" id="notification" aria-haspopup="true"
                                            aria-expanded="false" (click)="redirect('notification');">
                                            <i class="fa-regular fa-bell"></i>
                                            <span *ngIf="PendingNotification>0"
                                                class="blink_me">{{PendingNotification}}</span>
                                        </button>
                                        <button *ngIf="globals.authData != undefined && globals.authData != ''"
                                            class="dropdown-toggle" data-bs-toggle="dropdown" id="notification"
                                            aria-haspopup="true" aria-expanded="false" (click)="notificationshow();">
                                            <i class="fa-regular fa-bell"></i>
                                            <span *ngIf="PendingNotification>0"
                                                class="blink_me">{{PendingNotification}}</span>
                                        </button>


                                        <ul class="dropdown-menu notification_width" aria-labelledby="notification">
                                            <li *ngIf="recentNotifications.length>0" class="email_name">
                                                <span>{{globals.commonTranslationText.header.notification.title}}</span>
                                            </li>
                                            <li *ngIf="recentNotifications.length==0" class="email_name">
                                                {{globals.commonTranslationText.header.notification.noRecentNotification}}
                                            </li>
                                            <li class="height_notification">
                                                <ul class="notification_list" *ngIf="recentNotifications.length>0">
                                                    <li *ngFor="let notifications of recentNotifications">
                                                        <a class="float-start" (click)="clearNotification(notifications)"  href="{{notifications.action_url}}"><p>{{notifications.notification_text}}</p></a>
                                                        <a class="float-end" (click)="clearNotification(notifications)">&nbsp;&nbsp;<i class="fa-solid fa-xmark"></i></a>
                                                        <span class="float-end"
                                                            *ngIf="(currentDate | date : 'yyyy/MM/dd') == (notifications.created_at | date : 'yyyy/MM/dd')">{{notifications.created_at | date : 'h:mm a'}}</span>
                                                        <span class="float-end"
                                                            *ngIf="(yesterdayDate | date : 'yyyy/MM/dd') == (notifications.created_at | date : 'yyyy/MM/dd')">{{globals.commonTranslationText.common.label.yesterday}},
                                                            {{notifications.created_at | date : 'h:mm a'}}</span>
                                                        <span class="float-end"
                                                            *ngIf="((yesterdayDate | date : 'yyyy/MM/dd') != (notifications.created_at | date : 'yyyy/MM/dd')) && ((currentDate | date : 'yyyy/MM/dd') != (notifications.created_at | date : 'yyyy/MM/dd')) && ((beforeWeekDate | date : 'yyyy/MM/dd') <= (notifications.created_at | date : 'yyyy/MM/dd'))">
                                                            {{notifications.created_at | date : 'EEE, h:mm a'}}</span>
                                                        <span class="float-end"
                                                            *ngIf="((beforeWeekDate | date : 'yyyy/MM/dd') > (notifications.created_at | date : 'yyyy/MM/dd'))">
                                                            {{notifications.created_at | date : 'MMM dd, yyyy, h:mm
                                                            a'}}</span>
                                                        <div class="clearfix"></div>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li class="notification_btn_footer">
                                                <a class="view_older_notification float-start" routerLink="/notifications"><i class="fa-regular fa-eye"></i>
                                                  {{globals.commonTranslationText.header.notification.viewOlderNotification}}</a>
                                                <a *ngIf="recentNotifications.length>0" (click)="clearAllNotification()"
                                                  class="view_all_notification float-end">{{globals.commonTranslationText.header.notification.clearAllNotification}}</a>
                                              </li>
                                        </ul>
                                    </div>
                                </li> -->
                                <li class="d-flex">
                                    <div class="d-flex justify-content-end align-items-center text-end"
                                        *ngIf="globals.authData == undefined && (globals.currentLink != '/login' && globals.currentLink != '/register' )">
                                        <button class="theme_btn_white me-0" (click)="login()">Login</button>
                                    </div>

                                    <div class="d-flex justify-content-end align-items-center text-end"
                                        *ngIf="globals.authData != undefined">
                                        <button class="theme_btn_white me-0" type="submit" (click)="logout()">
                                            Logout
                                        </button>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </nav>



                </div>
            </div>
            <!-- End Navigation -->






            <!-- Navigation -->
            <!-- <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 d-flex align-items-center justify-content-end" *ngIf="globals.currentLink == '/login'">
                <div class="">
                    <nav class="navbar navbar-expand-lg navbar-light justify-content-center">
                        <button class="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"><i class="fa-solid fa-bars" aria-hidden="true"></i></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav me-auto mx-auto">
                                <li class="nav-item active" [ngClass]="{active : globals.currentLink == '/home'}">
                                    <a class="nav-link margin_l0" [ngClass]="{active : globals.currentLink == '/home'}"
                                        (click)="redirect('home')" title="Home"><i class="fa-solid fa-house-chimney"></i> <span
                                            class="sr-only">(current)</span></a>
                                </li>
                                <li class="nav-item" [ngClass]="{active : globals.currentLink == '/userDashboard'}">
                                    <a class="nav-link" [ngClass]="{active : globals.currentLink == '/userDashboard'}"
                                        (click)="redirect('dashboard')" title="Dashboard"><i
                                            class="fa-solid fa-gauge-high"></i></a>
                                </li>
                                <li class="nav-item" [ngClass]="{active : globals.currentLink == '/topQuestion'}"
                                    *ngIf="globals.authData == undefined || (globals.authData != undefined && globals.authData.roles_id == 2)">
                                    <a class="nav-link" [ngClass]="{active : globals.currentLink == '/topQuestion'}"
                                        (click)="redirect('topquestion')" title="Top Questions"><i
                                            class="fa-solid fa-question"></i></a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" title="Help"><i class="fa-regular fa-comments"></i></a>
                                </li>
                                <li class="nav-item" [ngClass]="{active : globals.currentLink == '/upgradePlan'}"
                                    *ngIf="globals.authData == undefined || (globals.authData != undefined && globals.authData.roles_id == 2)">
                                    <a class="nav-link" [ngClass]="{active : globals.currentLink == '/upgradePlan'}"
                                        (click)="redirect('upgradeplan')" title="Upgrade Plan"><i class="fa-solid fa-turn-up"></i></a>
                                </li>
                                <li class="nav-item" [ngClass]="{active : globals.currentLink == '/profile'}">
                                    <a class="nav-link" title="Profile" (click)="redirect('profile')"
                                        [ngClass]="{active : globals.currentLink == '/profile'}">
                                        <i class="fa-solid fa-user"></i></a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div> -->
            <!-- End Navigation -->





            <!-- <div class="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 d-flex justify-content-end align-items-center text-end"
                *ngIf="globals.authData != undefined">
            </div> -->
            <!-- <div class="col-xl-1 col-lg-1 col-md-12 col-sm-12 col-12 d-flex justify-content-end align-items-center text-end"
                *ngIf="globals.authData == undefined && (globals.currentLink != '/login' && globals.currentLink != '/register' )">
                <button class="theme_btn" (click)="login()">Login</button>
            </div>

            <div class="col-xl-1 col-lg-1 col-md-12 col-sm-12 col-12 d-flex justify-content-end align-items-center text-end"
                *ngIf="globals.authData != undefined">
                <button class="theme_btn" type="submit" (click)="logout()">
                    Logout
                </button>
            </div> -->
            <div class="clearfix"></div>
        </div>
        <div class="row" *ngIf="(globals.authData!=undefined && this.globals.isUser=='1')">
            <div class="col-sm-5 col-8 d-flex align-items-center">
                <div class="header_logo"><a routerLink="/admin/adminDashboard">
                    <img src="assets/images/logo-white.png" alt="" class="img-fluid" /></a>
                </div>
            </div>
            <!-- End Navigation -->
            <div
                class="col-sm-7 col-4 d-flex justify-content-end align-items-center text-end">
                <div class="user_profile_indication flex-fill">
                    <div class="user_dropdown float-end dropdown">
                        <button class="menu_dropdown" id="menu_header" (click)="headermenu()" aria-haspopup="true">
                        </button>
                        <button class="dropdown-toggle user_button" id="dropdownMenuButton" data-bs-toggle="dropdown"
                            aria-haspopup="true">
                            <!--<img src="assets/images/user.jpg" alt="" class="img-fluid" /> -->
                            <span class="name"
                                *ngIf="globals.authData.first_name != null">{{globals.authData.first_name}}
                                {{globals.authData.last_name}}</span>
                            <span class="name"
                                *ngIf="globals.authData.first_name == null">{{globals.authData.user_name}}</span>
                            <span class="letters"
                                *ngIf="globals.authData.first_name != null">{{globals.authData.first_name.charAt(0)}}{{globals.authData.last_name.charAt(0)}}</span>
                            <span class="letters"
                                *ngIf="globals.authData.first_name == null">{{globals.authData.user_name.charAt(0)}}</span>
                            <i class="fa-solid fa-angle-down"></i>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <li class="email_name">
                                <div class="intials">
                                    <!-- <span class="letters">AC</span> -->
                                    <span class="letters"
                                        *ngIf="globals.authData.first_name != null">{{globals.authData.first_name.charAt(0)}}{{globals.authData.last_name.charAt(0)}}</span>
                                    <span class="letters"
                                        *ngIf="globals.authData.first_name == null">{{globals.authData.user_name.charAt(0)}}</span>
                                </div>
                                <div class="name_with_email">
                                    <span *ngIf="globals.authData.first_name != null">
                                        {{globals.authData.first_name}} {{globals.authData.last_name}}
                                    </span>
                                    <span *ngIf="globals.authData.first_name == null">
                                        {{globals.authData.user_name}}
                                    </span>
                                    {{globals.authData.email}}
                                </div>

                            </li>
                            <li class="d-flex justify-content-between">
                                <a (click)="frontLogin()" target="_blank" class="ps-0">
                                    <i class="fa-solid fa-user"></i> User Dashboard</a>

                            <a routerLink="/profile" target="_blank" class="ps-0 pe-0">
                                    <i class="fa-regular fa-pen-to-square"></i> Edit Profile</a>
                            </li>
                            <!-- <li>
                                <a routerLink="/addressBook">
                                    <i class="fa-solid fa-up-right-from-square"></i> Address Book</a>
                            </li> -->
                            <li class="sign_btn">
                                <button class="" type="submit" (click)="logout()">
                                    <i class="fa-solid fa-right-from-bracket"></i> Logout
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>

<kendo-dialog title="Report {{globals.reportQuestionAnswerEntity.title}}" *ngIf="globals.reportPopUp"
    (close)="globals.closeReportPopUpOpen()" [minWidth]="250" [width]="725">
    <div class="table_block">

        <form #reportQuestionAnswerForm="ngForm"
            (ngSubmit)="globals.reportQuestionAnswer(reportQuestionAnswerForm)">

            
            <div class="form-group mb-5" id="group_name_new">
                <textarea kendoTextArea id="comment" name="comment" #comment="ngModel"
                    [(ngModel)]="globals.reportQuestionAnswerEntity.comment" class="form-control" rows="4"
                    placeholder="Enter your comment" required
                    [ngClass]="{error: (comment.dirty && comment.invalid) || (comment.pristine && globals.reportQuestionAnswerSubmitted && comment.errors && comment.errors.required) || (globals.reportQuestionAnswerEntity.error) }"></textarea>
                    
                <span class="error_class"
                    *ngIf="(comment.dirty && comment.invalid) || (comment.pristine && globals.reportQuestionAnswerSubmitted) || (globals.reportQuestionAnswerEntity.error)">
                    <!---  || errorEntity.comment-->
                    <span *ngIf="comment.errors && comment.errors.required && !globals.reportQuestionAnswerEntity.error">
                        Please enter a comment
                    </span>
                    <span *ngIf="globals.reportQuestionAnswerEntity.error">
                        Please enter a valid comment
                    </span>
                </span>
            </div>

            <div class="col-xl-12 text-center">
                <button type="submit" class="theme_btn">Add</button>
            </div>



        </form>
    </div>
</kendo-dialog>