import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from "@angular/router";
import { Globals } from '../globals';
import { CookieService } from 'ngx-cookie-service';
declare var $: any,  Swal;
declare var $: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class FooterComponent implements OnInit {
  constructor(public globals: Globals, private router: Router,private cookieService: CookieService) {}

  currentYear;
  ngOnInit(): void {
    
      this.currentYear = (new Date()).getFullYear();
      console.log(this.currentYear);
   
    $(document).ready(function () {
      $(window).scroll(function () {
        $(this).scrollTop() > 50
          ? $('#back-to-top').fadeIn()
          : $('#back-to-top').fadeOut();
      }),
        $('#back-to-top').click(function () {
          return (
            
            $('body,html').animate(
              {
                scrollTop: 0,
              },
              800
            ),
            !1
          );
        });
    });
  }

  postQuestion()
  {
    var d1 = new Date(),
    d2 = new Date(d1);
    d2.setMinutes(d1.getMinutes() + 30);
    if(this.globals.authData == undefined)
      {
        this.cookieService.set('URL', 'postQuestion', d2);
        this.router.navigate(["/login"]);    
      }
      else
      { 
        if(localStorage.getItem('email_available') == "0" && localStorage.getItem('username_available') == "0" )
            {
              this.cookieService.set('errorMessage', this.globals.commonTranslationText.loginPage.alerts.emailUsernameCompulsory.text, d2,'/');
              window.location.href = '/profile';
            }
            else if(localStorage.getItem('email_available') == "0")
            {
              this.cookieService.set('errorMessage', this.globals.commonTranslationText.loginPage.alerts.emailCompulsory.text, d2,'/');
              window.location.href = '/profile';
            }
            else if(localStorage.getItem('username_available') == "0")
            {
              this.cookieService.set('errorMessage', this.globals.commonTranslationText.loginPage.alerts.usernameCompulsory.text, d2,'/');
              window.location.href = '/profile';
            }
        // if(localStorage.getItem('email_available') == "0")
        // {
        //   Swal.fire({
        //     icon: this.globals.commonTranslationText.loginPage.alerts.emailCompulsory.type,
        //     type: this.globals.commonTranslationText.loginPage.alerts.emailCompulsory.type,
        //     title: this.globals.commonTranslationText.loginPage.alerts.emailCompulsory.title,
        //     text: this.globals.commonTranslationText.loginPage.alerts.emailCompulsory.text,
        //     showCancelButton: false,
        //     showConfirmButton: true,
        //     confirmButtonClass: 'theme_btn',
        //     confirmButtonText: 'Ok',
        //     allowOutsideClick: false
        //     }).then((result) => {
        //       if (result.value) {
        //         this.globals.isLoading = true;
        //         window.location.href = '/profile';
        //       }
        //     })
        // }
        else{
            window.location.href = '/postQuestion';
        }
      }
  }
}
