<footer [ngClass]="{admin_footer : (globals.authData!=undefined && this.globals.isUser=='1')}">
    <div class="footer_bottom">
        <div class="container-fluid">
            <div class="row d-flex align-items-center">
                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 text-start">© {{currentYear}}
                    <!-- <a href="#" target="_blank">IJCAHPO Eyedea Exchange</a>   -->
                   
                    <a href="https://www.jcahpo.org/" target="_blank" class="decideit_logo_footer">
                        <img src="assets/images/logo-white.png" alt=""
                            class="img-fluid" />
                        </a>
                        <div class="clearfix mt-2"></div>
                        <!-- <span class="px-2">|</span> -->
                        Powered by: <a href="https://theopeneyes.com/" target="_blank">OpenEyes Technologies Inc.</a>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 text-center">
                    <div class="social_icons">
                        <ul>
                            <li><a href="https://www.facebook.com/jcahpo" target="_blank"><i class="fa-brands fa-facebook-f"></i></a></li>
                            <li><a href="https://www.instagram.com/ijcahpo/" target="_blank"><i class="fa-brands fa-square-instagram"></i></a></li>
                            <li><a href="https://www.linkedin.com/company/jcahpo" target="_blank"><i class="fa-brands fa-linkedin-in"></i></a></li>
                            <li><a href="https://twitter.com/jcahpo" target="_blank"><i class="fa-brands fa-twitter"></i></a></li>
                            <li><a href="https://www.youtube.com/user/JointCommission" target="_blank"><i class="fa-brands fa-youtube"></i></a></li>
                            <!-- <li><a href="mailto:jcahpo@jcahpo.org" target="_blank"><i class="fa-solid fa-envelope"></i></a></li> -->
                        </ul>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 text-end">
                    <ul class="ftr_links">
                        <li><a routerLink="/contactUs" target="_blank">Contact Us</a></li> 
                        <li><a href="https://documents.jcahpo.org/documents/JCAHPOTermsofUse.pdf" target="_blank">Terms of Use</a></li> 
                        <li><a class="no_border" href="https://documents.jcahpo.org/documents/JCAHPOPrivacyPolicy.pdf" target="_blank">Privacy Policy</a></li> 
                        <!-- <li><a  class="no_border" href="#" target="_blank">Cookie Statement</a></li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>


    <a id="back-to-top" href="#" class="theme_btn back-to-top left" role="button" title="Scroll to Top" kendoTooltip position="left" tooltipClass="my-class">
        <span class="fa-solid fa-arrow-up"></span>
    </a>
    <div class="post_que_alternate left" kendoTooltip position="left" tooltipClass="my-class">
        <a id="postque" (click)="postQuestion()" *ngIf="globals.currentLink != '/login' && globals.currentLink != '/forgotPassword' && globals.currentLinkURL != '/resetPassword' && globals.currentLinkURL != '/verifyOTP' && globals.currentLink != '/register' && globals.currentLink != '/postQuestion' && !globals.authData" class="theme_btn" role="button" title="New Thread">
            <span class="fa-pencil fa-solid"></span>
        </a>
        <a id="postque" (click)="postQuestion()" *ngIf="globals.currentLink != '/login' && globals.currentLink != '/forgotPassword' && globals.currentLinkURL != '/resetPassword' && globals.currentLinkURL != '/verifyOTP' && globals.currentLink != '/register' && globals.currentLink != '/postQuestion' && globals.authData" class="theme_btn" role="button" title="New Thread">
            <span class="fa-pencil fa-solid"></span>
        </a>
    </div>

</footer>