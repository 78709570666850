import { Component, OnInit, ViewEncapsulation} from '@angular/core';
import { Router } from "@angular/router";
import { Globals } from "../globals";
import { CookieService } from 'ngx-cookie-service';
import { HomeService } from "../services/home.service";
declare var $: any, PerfectScrollbar, Swal;

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class GlobalSearchComponent implements OnInit {

  constructor(private router: Router, public globals: Globals,private cookieService: CookieService,
    private HomeService: HomeService,) { }

  globalSearchEntity;
  communityId;
  communityQuestionEntity;
  communityQuestionLoading1;
  communityQuestionLoading2;
  communityQuestionLoading3;
  communityQuestionLoading4;
  communityQuestionsList;
  relatedCommunityList;
  todaysdate;
  array = [];
  sum = 100;
  throttle = 10;
  scrollDistance = 1.5;
  scrollUpDistance = 2;
  direction = '';
  modalOpen = false;
  questionList;
  startpostion = 0;
  endpostion = 5;
  questionLoaderList;
  relatedCommunityLoaderList;
  newQuestionList;
  topQuestionList;
  ngOnInit(): void {
    // $(window).on('load resize scroll', function () {
    //   var f = $('footer');
    //   f.css({ position: 'static' });
    //   if (f.offset().top + f.height() < $(window).height()) {
    //     f.css({ position: 'fixed', bottom: '0', width: '100%' });
    //   }
    // });
    this.globalSearchEntity = {}
    if(this.cookieService.check('globalSearch'))
    {
      this.globalSearchEntity = JSON.parse(this.cookieService.get('globalSearch'));
      console.log(this.globalSearchEntity)
    }
    this.communityQuestionLoading1 = true;
    this.communityQuestionLoading2 = true;
    this.communityQuestionLoading3 = true;
    this.communityQuestionEntity = {};
    this.communityQuestionsList = [];
    this.relatedCommunityList = [];
    this.questionLoaderList = [];
    this.topQuestionList = [];
    this.newQuestionList = [];
    this.relatedCommunityLoaderList = [];
    this.questionList = [];
    for (var i = 0; i < 5; i++) {
      this.questionLoaderList.push(i);
    }
    this.getGlobalSearchData();
  }

  addItems(startIndex, endIndex) {
    for (let i = startIndex; i < endIndex; ++i) {
      if (i < this.questionList.length) {
        if ($.inArray(this.questionList[i], this.communityQuestionsList) < 0) {
          this.communityQuestionsList.push(this.questionList[i]);
        }
      }
    }
    this.communityQuestionsList = this.globals.extensionCheck(this.communityQuestionsList);
    // this.questionLength = this.communityQuestionsList.length;
    setTimeout(() => {
      this.questionLoaderList = [];
      this.communityQuestionLoading2 = false;
      this.communityQuestionLoading4 = false;
      console.log(this.questionLoaderList);
    }, 2000);
  }
  onScrollDown(ev) {
    console.log(ev);
    if (this.communityQuestionsList.length > 0) {
      if (ev != '' && !this.communityQuestionLoading2) {
        this.questionLoaderList = [];
        this.communityQuestionLoading4 = true;
        for (var j = 0; j < 1; j++) {
          this.questionLoaderList.push(j);
        }
      }
      setTimeout(() => {
        if (ev != '') {
          this.startpostion = this.endpostion;
          this.endpostion = this.endpostion + 5;
          this.addItems(this.startpostion, this.endpostion);
        }
      }, 2000);
    }
    // console.log("scrolled down!!", ev);

    // // add another 20 items
    // const start = this.sum;
    // // this.sum += 20;
    // this.appendItems(start, this.sum);

    // this.direction = "down";
  }

  getGlobalSearchData() {
    if(this.globalSearchEntity.search != '' && this.globalSearchEntity.search != undefined &&  this.globalSearchEntity.search.length>=3)
    {
      // this.globalSearchEntity.search = this.globalSearchEntity.search.replace(/\/$/, "");
      // console.log(this.globalSearchEntity)
      this.HomeService.getGlobalSearchData(this.globalSearchEntity).then(
        (data) => {
          console.log(data);
          this.questionList = data;
          // this.topQuestionList = data['top_questions'];
          // this.newQuestionList = data['new_questions'];
          this.addItems(this.startpostion, this.endpostion);
          localStorage.setItem('searchComplete' ,'yes')

        },
        (error) => {
          this.communityQuestionLoading1 = false;
          console.log(error);
        }
      );
    }
    else
    {
      this.communityQuestionsList = [];
      this.communityQuestionLoading2 = false;
    }
  }

  communityQuestion(id) {
    window.location.href = '/communityQuestions/' + window.btoa(id);
  }

  questionDetail(id) {
    this.globals.viewCount = 1;
    this.router.navigate(['/questionDetails/' + id]);
  }
  publicProfile(id,value) {
    window.location.href = '/publicProfile/' + window.btoa(id) + '/' + window.btoa(value);
  }

  decideItStars(value)
  {
    window.location.href = '/decideItStars/' + window.btoa(value);
  }

  likeDislikeQuestionAnswer(question,is_like)
  {
    var id = question.id

    $('#thumb' + id).addClass('disabled_btn').parents('.padding_boxs').addClass('disabled_post');
    
    this.globals.likeDislikeQuestionAnswer(question.id,is_like,'').then(
      (data) => {
        console.log(data);
        if(is_like == 1)
        {
          question.likes = question.likes + 1;
          question.is_like = 1;
          $('#thumb' + id).parents('.padding_boxs').removeClass('disabled_post');
        }
        else
        {
          question.likes = question.likes - 1;
          question.is_like = null;
          $('#thumb' + id).parents('.padding_boxs').removeClass('disabled_post');
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
