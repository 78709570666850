<div class="home_wrap pt_50 fixed-head" id="notfound_page">
  <div class="container-fluid">
    <div class="row d-flex align-item-center justify-content-center">
      <!-- Content -->
      <!-- <div class="col-md-12 col-sm-12"> -->
      <!-- <div class="row d-flex justify-content-center"> -->
      <!-- <div class="col-md-12 col-sm-12"> -->
      <!-- <div class="inner_content_block"> -->
      <!-- <div class="table_block"> -->
      <!-- <div class="container-fluid">
                    <div class=""> -->
      <!-- <div class="row justify-content-around"> -->
      <div class="col-12 col-lg-12 col-md-12 col-sm-12">
        <div class="white_login_block">
          <div class="page_found_block text-center">
            <div>
            <!-- <h1>We are sorry!</h1> -->
            <!-- <h5>The page you were looking for can't be found.</h5> -->
            <img src="assets/images/nofound.png" alt="" class="img-fluid" />
            <div *ngIf="code == 404">
              <h5>The page you were looking for can't be found.</h5>
              <!-- <img src="assets/images/nofound.png" alt="" class="img-fluid" /> -->
            </div>
            <div *ngIf="code == 400">
              <h5>The request cannot be fulfilled due to bad reuest. We are working on this.</h5>
            </div>
            <!-- <div *ngIf = "(code == 400 || code == 404) && (globals.authData.RoleId != 1)">
                                <h5>We are working on this we will get back to soon.</h5>
                              </div> -->
            <div *ngIf="code == 403">
              <h5>You do not have permission for these.</h5>
            </div>
            <div class="w-100 clearfix"></div>
            <a href="/admin/adminDashboard" *ngIf="globals.authData.roles_id == 1" class="all_btn theme_btn">Go back
              Home</a>
            <a href="/userDashboard" *ngIf="globals.authData.roles_id == 2" class="all_btn theme_btn">Go back
              Home</a>

            <!-- <a *ngIf="globals.authData.RoleId == 2 || globals.authData.RoleId == 4" href="/admin/proctorDashboard" class="all_btn theme_btn">Go back Home</a>
                <a *ngIf="globals.authData.RoleId == 3" href="/admin/dashboard" class="all_btn theme_btn">Go back Home</a> -->
          </div>
        </div>
        </div>

      </div>
      <!-- </div> -->
      <!-- </div>
                  </div> -->
      <!-- </div> -->
      <!-- End Content -->
      <div class="clearfix"></div>
      <!-- </div> -->
      <!-- </div> -->
      <!-- </div> -->
      <!-- </div> -->
    </div>
  </div>
</div>