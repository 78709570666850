import { Component, OnInit, ViewEncapsulation} from '@angular/core';
import { Globals } from '.././globals';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { RegisterService } from '../services/register.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie-service';
declare var $, Swal: any;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class RegisterComponent implements OnInit {

  constructor(public globals: Globals, private router: Router, private route: ActivatedRoute, private RegisterService: RegisterService,
    private cookieService: CookieService) { }
  registerEntity;
  submitted;
  errorEntity;
  same;
  btn_disable;
  countryCodeList;
  showEmailBlock;
  showPhoneNumberBlock;
  loginSelectType = 'email';
  phoneValid;
  ngOnInit(): void {
    
    this.registerEntity = {};
    this.errorEntity = {};
    this.countryCodeList = [];
    this.showEmailBlock = true;
    this.showPhoneNumberBlock = false;

    this.RegisterService.getCountryCode()
      .then((data) => {
        console.log(data);
        this.countryCodeList = data['data'];
      },
      (error) => {
        this.globals.isLoading = false; 
        console.log(error);
      });
    // $(window).on('load resize scroll', function () {
    //   var f = $('footer');
    //   f.css({ position: 'static' });
    //   if (f.offset().top + f.height() < $(window).height()) {
    //     f.css({ position: 'fixed', bottom: '0', width: '100%' });
    //   }
    // });


    // $(document).ready(function () {
    //   const body = document.querySelector('body');
    //   body.style.setProperty('--screen-height', $(window).height() + "px");
    // });

    $("#newpassword-show").click(function () {
      $(this).toggleClass("fa-eye-slash fa-eye")
      if ($("#newpassword").attr("type") == "password") {
        $("#newpassword").attr("type", "text");

      }
      else {
        $("#newpassword").attr("type", "password");
      }
    });
    $("#confpassword-show").click(function () {
      $(this).toggleClass("fa-eye-slash fa-eye")
      if ($("#confpassword").attr("type") == "password") {
        $("#confpassword").attr("type", "text");

      }
      else {
        $("#confpassword").attr("type", "password");
      }
    });
  }
  checkpassword() {
    if (this.registerEntity.cPassword != this.registerEntity.password) {
      this.same = true;
    } else {
      this.same = false;
    }
  }

  validationCheck(value,registerForm)
  {
    console.log(registerForm);
    // this.loginEntity = {};
    registerForm.form.markAsPristine();
    registerForm.form.controls.cPassword.touched = false;
    registerForm.form.controls.name.touched = false;
    registerForm.form.controls.password.touched = false;
    this.loginSelectType = value;
    this.submitted = false;
    if(value == 'phone')
    {
      this.showPhoneNumberBlock = true;
      this.showEmailBlock = false;
    }
    else
    {
      this.showEmailBlock = true;
      this.showPhoneNumberBlock = false;
    }
  }
  phoneNumberFormat(value) {
    var phoneformat = /^[0-9()_ ]+$/;
    if(value.match(phoneformat))
    {
      this.phoneValid = false;
    }
    else
    {
      this.phoneValid = true;
    }
  }
  register(registerForm)
  {
    //debugger
    this.submitted =true;
    if(registerForm.valid)
    {
      // this.registerEntity.user_email_mobile = (this.loginSelectType == 'email' ? this.registerEntity.EmailAddress :  this.registerEntity.PhoneNumber);
      this.registerEntity.user_name = this.registerEntity.name;
      this.registerEntity.social_network_id = 1;
      this.registerEntity.country_code_id = null;
      if(this.registerEntity.user_mobile != undefined)
      {
        this.registerEntity.user_mobile = this.registerEntity.user_mobile.replace(/\D/g, '');
      }
      this.globals.isLoading = true;
      this.btn_disable = true;
      this.RegisterService.register(this.registerEntity)
        .then((data) => {
          this.globals.isLoading = false;
          if(data["data"] == ""){
            this.cookieService.set('successMessage', this.globals.commonTranslationText.registerPage.alerts.successEmail.text, this.globals.cookieMessageExpireTime,'/');
            window.location.href = '/login';
            }
            else{
              console.log(data['data']['user_id']);
              this.cookieService.set('successMessage', this.globals.commonTranslationText.registerPage.alerts.successPhone.text, this.globals.cookieMessageExpireTime,'/');
              window.location.href = '/verifyOTP/' + window.btoa(data['data']['user_id']) + "/" + window.btoa(data['data']['phone']);
            }
        },
        (error) => {
          this.globals.isLoading = false; 
          this.btn_disable = false;
          console.log(error.error.errors);
          if(error.status == 422)
          {
            this.errorEntity.first_name = (error.error.errors.first_name && error.error.errors.first_name[0] != "") ? error.error.errors.first_name[0] : '';
            this.errorEntity.last_name = (error.error.errors.last_name && error.error.errors.last_name[0] != "") ? error.error.errors.last_name[0] : '';
            this.errorEntity.user_email = (error.error.errors.user_email && error.error.errors.user_email[0] != "") ? error.error.errors.user_email[0] : '';
            this.errorEntity.user_name = (error.error.errors.user_name && error.error.errors.user_name[0] != "") ? error.error.errors.user_name[0] : '';
            this.errorEntity.user_mobile = (error.error.errors.user_mobile && error.error.errors.user_mobile[0] != "") ? error.error.errors.user_mobile[0] : '';
            this.errorEntity.social_network_id = (error.error.errors.social_network_id && error.error.errors.social_network_id[0] != "") ? error.error.errors.social_network_id[0] : '';
            this.errorEntity.password = (error.error.errors.password && error.error.errors.password[0] != "") ? error.error.errors.password[0] : '';
          }
          else if(error.status == 409)
          {
            Swal.fire({
              type: this.globals.configurationText.sweetAlertTypeIconError,
              icon: this.globals.configurationText.sweetAlertTypeIconError,
              customClass: this.globals.configurationText.sweetAlertClassError,
              toast: true,
              title: error.error.message,
              animation: true,
              position: this.globals.configurationText.sweetAlertPosition,
              showConfirmButton: false,
              timer: this.globals.configurationText.sweetAlertTimer,
              timerProgressBar: true,
              showCancelButton: true,
              cancelButtonText: 'x',
            });
          }
          else
          {
            // this.globals.pageNotfound(error.error.code);
          }
        });
    }
  }

}
