<div class="container-fluid login_register_bg fixed-head">
  <div class="loginregister_white_block">
    <div class="row">
      <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div class="white_login_block">
          <div class="row d-flex justify-content-center">
            <!-- <div class="col-12 col-xl-5 col-lg-5 col-md-12 col-sm-12">
                <div class="login_logo_block">
                  <div class="logo_center">
                    <a routerLink="/login">
                    </a>
                    <h1>Decide It</h1>
                    <p class="padding_15">Need Help? <a
                        routerLink="/contactUs">Contact Us Here</a></p>
                    <p><a routerLink="/privacyPolicy">Privacy Policy</a> | <a routerLink="/cookiePolicy">Cookie Policy</a>
                      | <a routerLink="/termconditions">Terms of use</a></p>
                  </div>
                </div>
              </div> -->
            <div class="col-12 col-xl-5 col-lg-5 col-md-12 col-sm-12">
              <div class="padding_login_block">
                <h3>{{globals.commonTranslationText.otpPage.title}}</h3>
                <p class="padding_15">{{globals.commonTranslationText.otpPage.subTitle}}</p>
                <div class="clearfix"></div>
                <form #otpForm="ngForm" (ngSubmit)="verifyOTP(otpForm)">
                  <div class="row">
                    <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <div class="form-group">
                        <label><span class="mendatory_feild">*</span> {{globals.commonTranslationText.otpPage.form.otp.label}}</label>
                        <input kendoTextBox type="text" class="form-control" #otp="ngModel" [(ngModel)]="otpEntity.otp"
                          [ngClass]="{error: (otp.dirty && otp.invalid) || (otp.pristine && submitted)}" name="otp"
                          id="otp" maxlength="4" required>
                        <span class="error_class"
                          *ngIf="(otp.dirty && otp.invalid) || (otp.pristine && submitted) || errorEntity.otp">
                          <span
                            *ngIf="otp.errors.required || (otp.pristine && submitted)">{{globals.commonTranslationText.otpPage.form.otp.required}}</span>
                          <span
                            *ngIf="otp.errors && otp.errors.pattern">{{globals.commonTranslationText.otpPage.form.otp.pattern}}</span>
                          <span *ngIf="errorEntity.otp != ''">
                            {{errorEntity.otp}}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-md-12 col-12 col-lg-12 col-sm-12 text-center">
                      <button kendoButton class="all_btn theme_btn" type="submit" [disabled]="btn_disable">
                        {{globals.commonTranslationText.otpPage.buttons.submit}}
                      </button>
                      <button kendoButton class="all_btn themeoption_btn" type="button" (click)="resendOTP()"
                        [disabled]="btn_disable">
                        {{globals.commonTranslationText.otpPage.buttons.resend}}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>