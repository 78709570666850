import { Component, OnInit, ViewChild, TemplateRef, ViewEncapsulation } from '@angular/core';
import { Globals } from '.././globals';
import { Router } from '@angular/router';
import { ActivatedRoute, Params } from '@angular/router';
import { ContactusService } from '../services/contactus.service';
import { CookieService } from 'ngx-cookie-service';
declare var $: any, PerfectScrollbar, Swal;
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ContactusComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public globals: Globals,
    private ContactusService: ContactusService,
    private cookieService: CookieService
  ) { }

  contactUsEntity;
  submitted;
  errorEntity;
  btn_disable;
  ngOnInit(): void {
    this.contactUsEntity = {};
    this.errorEntity = {};
    // if (this.globals.authData.roles_id == 1) {
    //   $("#contact_page").addClass('col-md-10 col-sm-10 offset-md-2 main_block');
    // }

    // $(window).on('load resize scroll', function () {
    //   var f = $('footer');
    //   f.css({ position: 'static' });
    //   if (f.offset().top + f.height() < $(window).height()) {
    //     f.css({ position: 'fixed', bottom: '0', width: '100%' });
    //   }
    // });
  }

  countChar() {
    var val = $('#message').val();
    var len = val.length;
    console.log(len);
    if (len > 500) {
      // val.value = val.substring(0, 255);
      $('#message').val(val.substring(0, 500));
    } else {
      $('#limit').hide();
      $('#charNum').text(500 - len + ' /500');
    }
  }
  contactUs(contactUsForm) {
    //debugger;
    console.log(this.globals.authData);
    if (this.globals.authData != undefined && this.globals.authData.email != '' && this.globals.authData.email != null) {
      this.contactUsEntity.first_name = this.globals.authData.first_name
      this.contactUsEntity.last_name = this.globals.authData.last_name
      this.contactUsEntity.email = this.globals.authData.email
    }
    if (this.globals.authData != undefined && this.globals.authData.user_name != '') {
      this.contactUsEntity.user_name = this.globals.authData.user_name;
    }
    this.submitted = true;
    if (contactUsForm.valid) {
      this.btn_disable = true;
      this.globals.isLoading = true;
      this.ContactusService.contactUs(this.contactUsEntity).then(
        (data) => {
          this.globals.isLoading = false;
          this.btn_disable = false;
          this.submitted = false;
          this.contactUsEntity = {};
          this.errorEntity = {};
          contactUsForm.form.markAsPristine();
          $('#charNum').text('500/500');
          Swal.fire({
            type: this.globals.configurationText.sweetAlertTypeIconSuccess,
            icon: this.globals.configurationText.sweetAlertTypeIconSuccess,
            title: this.globals.commonTranslationText.inquirePage.alerts.success.text,
            customClass: this.globals.configurationText.sweetAlertClassSuccess,
            toast: true,
            animation: true,
            position: this.globals.configurationText.sweetAlertPosition,
            showConfirmButton: false,
            timer: this.globals.configurationText.sweetAlertTimer,
            timerProgressBar: true,
            showCancelButton: true,
            cancelButtonText: 'x',
          });
        },
        (error) => {
          this.btn_disable = false;
          this.submitted = false;
          this.globals.isLoading = false;
          // if (error.error.code == 422) {
          //   this.errorEntity.first_name =
          //     error.error.message.first_name[0] != ''
          //       ? error.error.message.first_name[0]
          //       : '';
          //   this.errorEntity.last_name =
          //     error.error.message.last_name[0] != ''
          //       ? error.error.message.last_name[0]
          //       : '';
          //   this.errorEntity.phone =
          //     error.error.message.phone[0] != ''
          //       ? error.error.message.phone[0]
          //       : '';
          // } else 
          if (error.error.code == 422) {
            Swal.fire({
              type: this.globals.configurationText.sweetAlertTypeIconError,
              icon: this.globals.configurationText.sweetAlertTypeIconError,
              customClass: this.globals.configurationText.sweetAlertClassError,
              toast: true,
              title: error.error.message.message[0],
              animation: true,
              position: this.globals.configurationText.sweetAlertPosition,
              showConfirmButton: false,
              timer: this.globals.configurationText.sweetAlertTimer,
              timerProgressBar: true,
              showCancelButton: true,
              cancelButtonText: 'x',
            });
          } else {
            // this.globals.pageNotfound(error.error.code);
          }
        }
      );
    }
  }
}
