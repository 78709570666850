import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Globals } from '.././globals';
import { Router } from '@angular/router';
import { ActivatedRoute, Params } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { CommunityListService } from '../services/community-list.service';
declare var $: any, PerfectScrollbar, Swal;

@Component({
  selector: 'app-community-list',
  templateUrl: './community-list.component.html',
  styleUrls: ['./community-list.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class CommunityListComponent implements OnInit {

  constructor(private router: Router,
    private route: ActivatedRoute,
    public globals: Globals,
    private cookieService: CookieService,
    private CommunityListService: CommunityListService) { }

  categoryList;
  communityList;
  latestCommunityList;
  categoryId;
  defaultCommunities;
  categoryLimit;
  categoryLoader;
  communityLoader1;
  communityLoader2;
  latestCommunityLoader;
  categoryLatestcommunityLoaderList;
  communityLoaderList;
  startpostion = 0;
  endpostion = 10;
  throttle = 10;
  scrollDistance = 1.5;
  scrollUpDistance = 2;
  categoryName;
  ngOnInit(): void {



    // $(window).on('load resize scroll', function () {
    //   var f = $('footer');
    //   f.css({ position: 'static' });
    //   if (f.offset().top + f.height() < $(window).height()) {
    //     f.css({ position: 'fixed', bottom: '0', width: '100%' });
    //   }
    // });




    this.categoryList = [];
    this.communityList = [];
    this.defaultCommunities = [];
    this.latestCommunityList = [];
    this.categoryLatestcommunityLoaderList = [];
    this.communityLoaderList = [];
    for (var i = 0; i < 10; i++) {
      this.categoryLatestcommunityLoaderList.push(i);
    }
    for (var i = 0; i < 10; i++) {
      this.communityLoaderList.push(i);
    }
    this.categoryLoader = true;
    this.communityLoader1 = true;
    this.latestCommunityLoader = true;
    this.CommunityListService.getTopCommunity('categories', '', '').then(
      (data) => {
        console.log(data);
        var category: any;
        category = data['data'];
        var categorySelect = {
          id: '',
          name: 'All Topics',
          value: '',
        };
        this.categoryList.push(categorySelect);
        this.categoryList = [...this.categoryList, ...category];
        console.log(this.categoryList);
        this.categoryId = categorySelect.id;
        this.categoryName = categorySelect.name;
        this.categoryLoader = false;
        if (this.categoryList.length < 10) {
          this.categoryLimit = this.categoryList.length;
        }
        else {
          this.categoryLimit = 10;
        }
      },
      (error) => {
        this.categoryLoader = false;
        console.log(error);
      }
    );
  }

  ngAfterViewInit() {
    this.topCommunities('');
    this.latestCommunity('');
  }

  addItems(startIndex, endIndex) {
    for (let i = startIndex; i < endIndex; ++i) {
      if (i < this.defaultCommunities.length) {
        if (
          $.inArray(this.defaultCommunities[i], this.communityList) <
          0
        ) {
          this.communityList.push(this.defaultCommunities[i]);
        }
      }
    }
    // this.questionLength = this.communityQuestionsList.length;
    setTimeout(() => {
      this.communityLoaderList = [];
      this.communityLoader1 = false;
      this.communityLoader2 = false;
      // console.log(this.decideItStarResponderLoaderList);
    }, 500);
  }
  onScrollDown(ev) {
    console.log(ev);
    if (this.communityList.length > 0) {
      if (ev != '' && !this.communityLoader1) {
        this.communityLoaderList = [];
        this.communityLoader2 = true;
        for (var j = 0; j < 10; j++) {
          this.communityLoaderList.push(j);
        }
      }
      setTimeout(() => {
        if (ev != '') {
          this.startpostion = this.endpostion;
          this.endpostion = this.endpostion + 10;
          this.addItems(this.startpostion, this.endpostion);
        }
      }, 3000);
    }
    // console.log("scrolled down!!", ev);

    // // add another 20 items
    // const start = this.sum;
    // // this.sum += 20;
    // this.appendItems(start, this.sum);

    // this.direction = "down";
  }
  topCommunities(id) {
    this.CommunityListService.getTopCommunity('top_communities', id, '').then(
      (data) => {
        console.log(data);
        // this.communityList = data['data'];
        this.defaultCommunities = data['data'];
        this.addItems(this.startpostion, this.endpostion);
        this.communityLoader1 = false;
      },
      (error) => {
        this.communityLoader1 = false;
        console.log(error);
      }
    );
  }
  latestCommunity(id) {
    this.CommunityListService.getTopCommunity('recent_communities', id, '').then(
      (data) => {
        console.log(data);
        this.latestCommunityList = data['data'];
        this.latestCommunityLoader = false;
      },
      (error) => {
        this.latestCommunityLoader = false;
        console.log(error);
      }
    );
  }

  communities(category) {
    $('#searchTerm').val('');
    this.topCommunities(category.id);
    this.latestCommunity(category.id);
    this.categoryId = category.id;
    this.categoryName = category.name;
    this.startpostion = 0;
    this.endpostion = 10;
    this.communityList = [];
    this.communityLoaderList = [];
    this.latestCommunityList = [];
    this.communityLoader2 = true;
    for (var j = 0; j < 10; j++) {
      this.communityLoaderList.push(j);
    }
    this.latestCommunityLoader = true;
    return (

      $('body,html').animate(
        {
          scrollTop: 0,
        },
        800
      ),
      !1
    );
  }

  search() {
    var name = $('#searchTerm').val();
    var searchFor = $('#searchTerm').val().toLowerCase();
    if (searchFor.length >= 3) {
      this.CommunityListService.getTopCommunity('top_communities', this.categoryId, searchFor).then(
        (data) => {
          console.log(data);
          this.communityList = data['data'];
        },
        (error) => {
          this.globals.isLoading = false;
          console.log(error);
        }
      );
    } else {
      this.communityList = this.defaultCommunities;
    }
  }

  communityQuestion(id) {
    window.location.href = '/communityQuestions/' + window.btoa(id);
  }
}
