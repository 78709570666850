<div class="home_wrap dashboard_wrap pt_50 fixed-head" id="postQuestion_page">
  <div class="container-fluid">
    <div class="row pb-5">
      <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 m-auto">
        <div class="d-flex justify-content-between mb-4 post_que_header">
          <h2 class="mb-0 decideit_head"><span>New</span> Thread</h2>
          <div class="position-relative">
            <span class="info_toggle" (click)="mediaInfoOpen()"><i class="fa-solid fa-circle-info"></i> Upload Media Note</span>
          </div>
        </div>
        <div class="clearfix"></div>
        <div
          *ngIf="globals.authData != undefined && defaultEntity.questions == 0"
        >
          You do not have any Threads remaining in this month.
        </div>
        <div
          class="post_question_tabs mt-3"
          *ngIf="globals.authData == undefined || defaultEntity.questions > 0"
        >
          <kendo-tabstrip #tabstrip *ngIf="userRoleId == 1">
            <kendo-tabstrip-tab
              #QuestionTab
              [title]="'Thread'"
              [selected]="true"
            >
              <ng-template kendoTabContent>
                <form #postQuestionForm="ngForm">
                  <div class="post_que_editor">
                    <!-- <textarea
                      kendoTextArea
                      id="question_text"
                      #question_text="ngModel"
                      [(ngModel)]="postQuestionEntity.question_text"
                      name="question_text"
                      rows="4"
                      (keyup)="countChar()"
                      placeholder="Start your thread"
                      required
                      [ngClass]="{
                        error:
                          (question_text.dirty && question_text.invalid) ||
                          (question_text.pristine && questionSubmitted) ||
                          errorEntity.question_text
                      }"
                    ></textarea> -->
                    <kendo-editor
                    id="question_text"
                      placeholder="Start your thread"
                      name="question_text"
                      required
                      (valueChange)="countChar()"
                      #question_text="ngModel"
                      [(ngModel)]="postQuestionEntity.question_text"  [ngClass]="{
                        error:
                          (question_text.dirty && question_text.invalid) ||
                          (question_text.pristine && questionSubmitted) ||
                          errorEntity.question_text
                      }">
                      <kendo-toolbar>
                        <kendo-toolbar-buttongroup>
                          <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                          <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                          <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                          <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
                        </kendo-toolbar-buttongroup>
                        <kendo-toolbar-buttongroup>
                          <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                          <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                        </kendo-toolbar-buttongroup>  
                        <kendo-toolbar-buttongroup>
                          <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                          <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
                        </kendo-toolbar-buttongroup>
                        <kendo-toolbar-buttongroup>
                          <kendo-toolbar-button text="Upload Media"></kendo-toolbar-button>
                        </kendo-toolbar-buttongroup>
                      </kendo-toolbar>
                    </kendo-editor>
                    <span
                      class="error_class"
                      *ngIf="
                        (question_text.dirty && question_text.invalid) ||
                        (question_text.pristine && questionSubmitted) ||
                        errorEntity.question_text
                      "
                    >
                      <span
                        *ngIf="
                          question_text.errors && question_text.errors.required
                        "
                      >
                        {{
                          globals.commonTranslationText.home.form.question
                            .required
                        }}
                      </span>
                      <span *ngIf="errorEntity.question_text != ''">
                        {{ errorEntity.question_text }}
                      </span>
                    </span>
                    <span class="error_class">
                      {{errorTitle}}
                    </span>
                    <span class="error_class">
                      {{maxxxxxxLength}}
                    </span>
                    <!-- <i class="fa-pencil fa-sm fa-solid" aria-hidden="true"></i> -->
                    <div id="charNum" class="limitwitheditor"></div>
                    <span id="limit" class="limitwitheditor">1000/1000</span>
                  </div>
                  <div class="supporting_video d-flex align-items-center d-none">
                    <div class="form-group file_upload">
                      <input type="text" disabled="" class="form-control" />
                      <input
                        type="file"
                        (change)="imagechange()"
                        name="fileToUpload"
                        id="fileToUpload"
                        class="form-control"
                        #image_video="ngModel"
                        [(ngModel)]="postQuestionEntity.image_video"
                        accept="image/*,video/*"
                        multiple
                      />
                      <label>Supporting video or photo</label>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                  <!-- Priview -->
                  <div
                    id="xyz"
                    class="col-xl-12 col-lg-12 col-md-12"
                    *ngIf="this.mydata.length > 0"
                  >
                    <div class="upload_preview">

                      <div class="row row-cols-sm-3 row-cols-1 upload_media">
                        <div class="col" *ngFor="let list of mydata; let i = index">
                          <div class="media_prev" >
                            <button class="remove-prev" type="button"><i class="fa-solid fa-xmark" (click)="removeImage(i, list.name)"></i></button>
                            <div class="media_prev_inner" *ngIf="list.type == 'img'">
                              <img
                                alt=""                              
                                [src]="list.url"
                              />
                            </div>  
                             <!-- Progress Bar -->
                            <div class="upload_media_status" *ngIf="list.type == 'img'&& list.size < checkImgSizeKB">
                              <div class="first-progress">
                                <!-- <div
                                  [ngStyle]="{ 'width.100%': list.FileProgress }"
                                  class="second-progress"
                                ></div> -->
                                <div class="second-progress"></div>
                              </div>
                              <div class="text-center d-none">
                                {{ list.FileProgessSize }} off {{ list.FileSize }}
                              </div>
                            </div>
                          <!-- Progress Bar -->
                            <div id="error" *ngIf="list.invalid == false" class="upload_media_error">Image Size is Not Proper</div>
                            <div class="media_prev_inner" *ngIf="list.type == 'video'">
                              <div
                                class="ratio ratio-16x9"
                              >
                                <video controls>
                                  <source [src]="list.url" />
                                </video>
                              </div>
                            </div>
                            <!-- Progress Bar -->
                            <div class="upload_media_status" *ngIf="list.type == 'video'&& list.size < checkVideoKB">
                              <div class="first-progress">
                                <!-- <div
                                  [ngStyle]="{ 'width.100%': list.FileProgress }"
                                  class="second-progress"
                                ></div> -->
                                <div class="second-progress"></div>
                              </div>
                              <div class="text-center d-none">
                                {{ list.FileProgessSize }} off {{ list.FileSize }}
                              </div>
                            </div>
                          <!-- Progress Bar -->
                          <div id="error" *ngIf="list.isValid == false" class="upload_media_error">Video Size is Not Proper</div>
                          </div>
                        </div>
                      </div>
                      <!-- <div
                        id="sliderid"
                        class="carousel slide"
                        data-bs-ride="carousel"
                        data-bs-interval="false"
                      >
                        <div
                          class="carousel-indicators"
                          *ngIf="fileUploadLength > 1"
                        >
                          <button
                            type="button"
                            data-bs-target="#sliderid"
                            [attr.data-bs-slide-to]="i"
                            [attr.aria-label]="'Slide ' + i"
                            [ngClass]="{ active: i == 0 }"
                            *ngFor="let list of mydata; let i = index"
                            [attr.aria-current]="i == 0"
                          ></button>
                        </div>
                        <div class="carousel-inner">
                          <div
                            class="carousel-item"
                            [ngClass]="{ active: i == 0 }"
                            *ngFor="let list of mydata; let i = index"
                          >
                            <img
                              alt=""
                              class="d-block w-100"
                              *ngIf="list.type == 'img'"
                              [src]="list.url"
                            />
                            <div
                              class="ratio ratio-16x9"
                              *ngIf="list.type == 'video'"
                            >
                              <video controls>
                                <source [src]="list.url" />
                              </video>
                            </div>
                          </div>
                        </div>
                        <button
                          *ngIf="fileUploadLength > 1"
                          class="carousel-control-prev"
                          type="button"
                          data-bs-target="#sliderid"
                          data-bs-slide="prev"
                        >
                          <span
                            class="carousel-control-prev-icon"
                            aria-hidden="true"
                          ></span>
                          <span class="visually-hidden">Previous</span>
                        </button>
                        <button
                          *ngIf="fileUploadLength > 1"
                          class="carousel-control-next"
                          type="button"
                          data-bs-target="#sliderid"
                          data-bs-slide="next"
                        >
                          <span
                            class="carousel-control-next-icon"
                            aria-hidden="true"
                          ></span>
                          <span class="visually-hidden">Next</span>
                        </button>
                      </div> -->
                      <!-- <button class="theme_btn mt-4" (click)="uploadImage()">
                        Upload Image
                      </button> -->
                    </div>
                  </div>
                  <!-- Priview -->


                  <div class="clearfix"></div>
                  <div class="next_prev_btn tabs_footer mt-3">
                    <button
                      kendoButton
                      type="submit"
                      class="theme_btn float-end"
                      (click)="
                        nextAnswerformat(
                          postQuestionForm,
                          tabstrip,
                          QuestionTab,
                          AnswerFormatTab
                        )
                      "
                      id="next_btn"
                      [disabled]="btn_disabled"
                    >
                      Next - Response Format
                    </button>
                    <button
                      kendoButton
                      (click)="
                        postQuestion(
                          postQuestionForm,
                          globals.configurationText.questionStatus.saveAsDraft
                        )
                      "
                      class="themeoption_btn float-end third"
                      id="next_btn"
                      [disabled]="btn_disabled"
                    >
                      Save Draft
                    </button>
                    <div class="clearfix"></div>
                  </div>
                </form>
              </ng-template>
            </kendo-tabstrip-tab>

            <kendo-tabstrip-tab
              #AnswerFormatTab
              [title]="'Response Format'"
              [disabled]="true"
            >
              <ng-template kendoTabContent>
                <form #postQuestionForm2="ngForm">
                  <div class="form-group mb-5">
                    <kendo-dropdownlist
                      [data]="answerList"
                      [textField]="'display_text'"
                      [valueField]="'id'"
                      [valuePrimitive]="true"
                      name="question_type_id"
                      (valueChange)="answerTypeChange($event)"
                      #question_type_id="ngModel"
                      [(ngModel)]="postQuestionEntity.question_type_id"
                      required
                      [ngClass]="{
                        error:
                          ((question_type_id.dirty &&
                            question_type_id.invalid) ||
                            (question_type_id.pristine &&
                              answerFormatSubmitted &&
                              question_type_id.errors &&
                              question_type_id.errors.required)) &&
                          postQuestionEntity.required == true
                      }"
                    >
                      <ng-template kendoDropDownListItemTemplate let-dataItem>
                        <span class="template"
                          ><img
                            src="{{ dataItem.value }}"
                            alt=""
                            class="img-fluid"
                          />
                        </span>
                        {{ dataItem.display_text }}
                      </ng-template>
                    </kendo-dropdownlist>
                    <span
                      class="error_class"
                      *ngIf="
                        ((question_type_id.dirty && question_type_id.invalid) ||
                          (question_type_id.pristine &&
                            answerFormatSubmitted) ||
                          errorEntity.question_type_id) &&
                        postQuestionEntity.required == true
                      "
                    >
                      <span
                        *ngIf="
                          question_type_id.errors &&
                          question_type_id.errors.required
                        "
                        >{{
                          globals.commonTranslationText.home.form.questionType
                            .required
                        }}</span
                      >
                      <span *ngIf="errorEntity.question_type_id != ''">
                        {{ errorEntity.question_type_id }}
                      </span>
                    </span>
                  </div>
                  <div class="post_que_editor">
                    <div
                      *ngIf="
                        postQuestionEntity.question_type_id ==
                        globals.configurationText.questionType.pickList
                      "
                    >
                      <div class="answer_formate_box mt-5">
                        <h3 class="float-start">Enter your options</h3>
                        <div class="clearfix"></div>
                        <div class="row">
                          <div class="col-md-12 col-12 col-lg-7 col-sm-12">
                            <div
                              class="row"
                              *ngFor="
                                let item of OptionsList;
                                let i = index;
                                let last = last
                              "
                            >
                              <div class="col-md-8 col-8 col-lg-8 col-sm-8">
                                <div class="form-group mb-4" id="emails">
                                  <input
                                    kendoTextBox
                                    type="text"
                                    class="form-control"
                                    name="OptionValue{{ i }}"
                                    id="OptionValue{{ i }}"
                                    placeholder="Option {{ i + 1 }}"
                                    [ngClass]="{
                                      error:
                                        ((OptionValue.dirty &&
                                          OptionValue.invalid) ||
                                          (OptionValue.pristine &&
                                            optionSubmitted &&
                                            OptionValue.errors &&
                                            OptionValue.errors.required)) &&
                                        postQuestionEntity.required == true
                                    }"
                                    maxlength="100"
                                    required
                                    #OptionValue="ngModel"
                                    [(ngModel)]="OptionsList[i].OptionValue"
                                  />
                                  <span
                                    class="error_class"
                                    *ngIf="
                                      ((OptionValue.dirty &&
                                        OptionValue.invalid) ||
                                        (OptionValue.pristine &&
                                          optionSubmitted)) &&
                                      postQuestionEntity.required == true
                                    "
                                  >
                                    <span
                                      *ngIf="
                                        OptionValue.errors &&
                                        OptionValue.errors.required
                                      "
                                      >{{
                                        globals.commonTranslationText.home.form
                                          .option.required
                                      }}
                                      {{ i + 1 }}</span
                                    >
                                  </span>
                                </div>
                              </div>

                              <div
                                class="col-md-4 col-4 col-lg-4 col-sm-4 pl_0"
                              >
                                <button
                                  kendoButton
                                  *ngIf="OptionsList.length >= 4 || i == 2"
                                  class="themeoption_btn"
                                  type="button"
                                  (click)="RemoveOption(item)"
                                >
                                  <i class="fa-solid fa-xmark"></i>
                                </button>
                                <button
                                  kendoButton
                                  *ngIf="last && i < 5"
                                  class="theme_btn"
                                  type="button"
                                  (click)="AddOption(i)"
                                >
                                  <i class="fa-solid fa-plus"></i>
                                </button>
                              </div>
                            </div>
                            <div class="check_box">
                              <input
                                type="checkbox"
                                name="multiSelection"
                                id="multiSelection"
                                [checked]="
                                  postQuestionEntity.question_type_multiSelect_id ==
                                  globals.configurationText.questionType
                                    .multiPickList
                                "
                                value="{{
                                  globals.configurationText.questionType
                                    .multiPickList
                                }}"
                              />
                              <label
                                for="multiSelection"
                                class="checkboxbtn_label"
                                >Allow Multiple Selections</label
                              >
                            </div>
                          </div>
                          <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                            <div class="answer_formate_info">
                              <p>
                                "Shorter option lists generally lead to 18% more
                                responses"
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      *ngIf="
                        postQuestionEntity.question_type_id ==
                        globals.configurationText.questionType.rankedList
                      "
                    >
                      <div class="answer_formate_box mt-5">
                        <h3 class="float-start">
                          Enter Your Options for Rank List
                        </h3>
                        <div class="clearfix"></div>
                        <div class="row">
                          <div class="col-md-7 col-12 col-lg-7 col-sm-12">
                            <div
                              class="row"
                              *ngFor="
                                let item of RankList;
                                let l = index;
                                let last = last
                              "
                            >
                              <div class="col-md-8 col-8 col-lg-8 col-sm-8">
                                <div class="form-group mb-4" id="emails">
                                  <input
                                    kendoTextBox
                                    type="text"
                                    class="form-control"
                                    name="OptionValue{{ l }}"
                                    id="OptionValue{{ l }}"
                                    placeholder="Choice {{ l + 1 }}"
                                    [ngClass]="{
                                      error:
                                        ((OptionValue.dirty &&
                                          OptionValue.invalid) ||
                                          (OptionValue.pristine &&
                                            optionSubmitted &&
                                            OptionValue.errors &&
                                            OptionValue.errors.required)) &&
                                        postQuestionEntity.required == true
                                    }"
                                    maxlength="100"
                                    required
                                    #OptionValue="ngModel"
                                    [(ngModel)]="RankList[l].OptionValue"
                                  />
                                  <span
                                    class="error_class"
                                    *ngIf="
                                      ((OptionValue.dirty &&
                                        OptionValue.invalid) ||
                                        (OptionValue.pristine &&
                                          optionSubmitted)) &&
                                      postQuestionEntity.required == true
                                    "
                                  >
                                    <span
                                      *ngIf="
                                        OptionValue.errors &&
                                        OptionValue.errors.required
                                      "
                                      >{{
                                        globals.commonTranslationText.home.form
                                          .option.required
                                      }}
                                      {{ l + 1 }}</span
                                    >
                                  </span>
                                </div>
                              </div>
                              <div
                                class="col-md-4 col-4 col-lg-4 col-sm-4 pl_0"
                              >
                                <button
                                  kendoButton
                                  *ngIf="RankList.length >= 4 || l == 2"
                                  class="themeoption_btn"
                                  type="button"
                                  (click)="RemoveOptionrank(item)"
                                >
                                  <i class="fa-solid fa-xmark"></i>
                                </button>
                                <button
                                  kendoButton
                                  *ngIf="last && l < 5"
                                  class="theme_btn"
                                  type="button"
                                  (click)="AddOptionrank(l)"
                                >
                                  <i class="fa-solid fa-plus"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                            <div class="answer_formate_info">
                              <p>
                                "Shorter option lists generally lead to 18% more
                                responses"
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                  <div class="next_prev_btn tabs_footer mt-3">
                    <button
                      kendoButton
                      (click)="
                        backToQuestion(tabstrip, QuestionTab, AnswerFormatTab)
                      "
                      class="back_thread_btn themeoption_btn float-start"
                      id="prev_btn"
                    >
                      Back to Thread
                    </button>
                    <button
                      kendoButton
                      (click)="
                      nextPostAs(
                          postQuestionForm2,
                          tabstrip,
                          PostAsTab
                        )
                      "
                      type="button"
                      class="theme_btn float-end"
                      id="next_btn"
                      [disabled]="btn_disabled"
                    >
                      Next - Post As
                    </button>
                    <button
                      kendoButton
                      (click)="
                        postQuestion(
                          postQuestionForm2,
                          globals.configurationText.questionStatus.saveAsDraft
                        )
                      "
                      class="themeoption_btn float-end third"
                      id="next_btn"
                      [disabled]="btn_disabled"
                    >
                      Save Draft
                    </button>
                    <div class="clearfix"></div>
                  </div>
                </form>
              </ng-template>
            </kendo-tabstrip-tab>

           

            <kendo-tabstrip-tab
              [title]="'Post As'"
              [disabled]="true"
              #PostAsTab
            >
              <ng-template kendoTabContent>
                <form #postQuestionForm4="ngForm">
                  <div class="more_option_wrap">
                    <div class="row">
                      <div
                        class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                      >
                        <h3>Post as User Or Admin</h3>
                        <div class="radio_box">
                          <input
                            [(ngModel)]="postQuestionEntity.postas"
                            type="radio"
                            name="admin"
                            id="admin"
                            [value]="1"
                            [checked]="postQuestionEntity.postas == 1"
                          />
                          <label for="admin">Admin</label>
                        </div>
                        <div class="radio_box">
                          <input
                            [(ngModel)]="postQuestionEntity.postas"
                            type="radio"
                            name="user"
                            id="user"
                            [value]="0"
                            [checked]="postQuestionEntity.postas == 0"
                          />
                          <label for="user">User</label>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                  <div class="next_prev_btn tabs_footer mt-3">
                    <button
                      kendoButton
                      (click)="
                        backToMoreSendOptions(
                          tabstrip,
                          AnswerFormatTab,
                          PostAsTab
                        )
                      "
                      class="themeoption_btn float-start"
                      id="prev_btn"
                    >
                    Back to Response Format
                    </button>
                    <button
                      kendoButton
                      (click)="
                        postQuestion(
                          postQuestionForm4,
                          globals.configurationText.questionStatus.open
                        )
                      "
                      type="button"
                      class="theme_btn float-end second"
                      id="next_btn"
                      [disabled]="btn_disabled"
                    >
                      Post
                    </button>
                    <button
                      kendoButton
                      (click)="
                        postQuestion(
                          postQuestionForm4,
                          globals.configurationText.questionStatus.saveAsDraft
                        )
                      "
                      class="themeoption_btn float-end third"
                      id="next_btn"
                      [disabled]="btn_disabled"
                    >
                      Save Draft
                    </button>
                    <div class="clearfix"></div>
                  </div>
                </form>
              </ng-template>
            </kendo-tabstrip-tab>
          </kendo-tabstrip>



          <kendo-tabstrip #tabstrip *ngIf="userRoleId == 2">
            <kendo-tabstrip-tab
              #QuestionTab
              [title]="'Thread'"
              [selected]="true"
            >
              <ng-template kendoTabContent>
                <form #postQuestionForm="ngForm">
                  <div class="post_que_editor">
                    <!-- <textarea
                      kendoTextArea
                      id="question_text"
                      #question_text="ngModel"
                      [(ngModel)]="postQuestionEntity.question_text"
                      name="question_text"
                      rows="4"
                      (keyup)="countChar()"
                      placeholder="Start your thread"
                      required
                      [ngClass]="{
                        error:
                          (question_text.dirty && question_text.invalid) ||
                          (question_text.pristine && questionSubmitted) ||
                          errorEntity.question_text
                      }"
                    ></textarea> -->
                    <kendo-editor 
                    id="question_text"
                      placeholder="Start your thread"
                      name="question_text"
                      required
                      (valueChange)="countChar()"
                      #question_text="ngModel"
                      [(ngModel)]="postQuestionEntity.question_text">
                      <kendo-toolbar>
                        <kendo-toolbar-buttongroup>
                          <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                          <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                          <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                          <kendo-toolbar-button kendoEditorStrikethroughButton></kendo-toolbar-button>
                        </kendo-toolbar-buttongroup>
                        <kendo-toolbar-buttongroup>
                          <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                          <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                        </kendo-toolbar-buttongroup>  
                        <kendo-toolbar-buttongroup>
                          <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                          <kendo-toolbar-button kendoEditorUnlinkButton></kendo-toolbar-button>
                        </kendo-toolbar-buttongroup>
                        <kendo-toolbar-buttongroup>
                          <kendo-toolbar-button text="Upload Media"></kendo-toolbar-button>
                        </kendo-toolbar-buttongroup>
                      </kendo-toolbar>
                    </kendo-editor>
                    <span
                      class="error_class"
                      *ngIf="
                        (question_text.dirty && question_text.invalid) ||
                        (question_text.pristine && questionSubmitted) ||
                        errorEntity.question_text
                      "
                    >
                      <span
                        *ngIf="
                          question_text.errors && question_text.errors.required
                        "
                      >
                        {{
                          globals.commonTranslationText.home.form.question
                            .required
                        }}
                      </span>
                      <span *ngIf="errorEntity.question_text != ''">
                        {{ errorEntity.question_text }}
                      </span>
                    </span>
                    <span class="error_class">
                      {{errorTitle}}
                    </span>
                    <span class="error_class">
                      {{maxxxxxxLength}}
                    </span>
                    <!-- <i class="fa-pencil fa-sm fa-solid" aria-hidden="true"></i> -->
                    <div id="charNum" class="limitwitheditor"></div>
                    <span id="limit" class="limitwitheditor">1000/1000</span>
                  </div>
                  <div class="supporting_video d-flex align-items-center d-none">
                    <div class="form-group file_upload">
                      <input type="text" disabled="" class="form-control" />
                      <input
                        type="file"
                        (change)="imagechange()"
                        name="fileToUpload"
                        id="fileToUpload"
                        class="form-control"
                        #image_video="ngModel"
                        [(ngModel)]="postQuestionEntity.image_video"
                        accept=".jpg, .jpeg, .png, .JPEG, .JPG, .PNG, .heic, .mp4,.MP4,.MKV,.mkv,.WEB',.web',.avi,.mov"
                        multiple
                      />
                      <label>Supporting video or photo</label>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                  <!-- Priview -->
                  <div
                  id="xyz"
                  class="col-xl-12 col-lg-12 col-md-12"
                  *ngIf="this.mydata.length > 0"
                >
                  <div class="upload_preview">

                    <div class="row row-cols-sm-3 row-cols-1 upload_media">
                      <div class="col" *ngFor="let list of mydata; let i = index">
                        <div class="media_prev" >
                          <button class="remove-prev" type="button"><i class="fa-solid fa-xmark" (click)="removeImage(i,list.name)"></i></button>
                          <div class="media_prev_inner" *ngIf="list.type == 'img'">
                            <img
                              alt=""                              
                              [src]="list.url"
                            />
                          </div>  
                           <!-- Progress Bar -->
                          <div class="upload_media_status" *ngIf="list.type == 'img'&& list.size < checkImgSizeKB">
                            <div class="first-progress">
                              <!-- <div
                                [ngStyle]="{ 'width.100%': list.FileProgress }"
                                class="second-progress"
                              ></div> -->
                              <div class="second-progress"></div>
                            </div>
                            <div class="text-center d-none">
                              {{ list.FileProgessSize }} off {{ list.FileSize }}
                            </div>
                          </div>
                        <!-- Progress Bar -->
                          <div id="error" *ngIf="list.type == 'img' && list.size >checkImgSizeKB" class="upload_media_error">Image Size is Not Proper</div>
                          <div class="media_prev_inner" *ngIf="list.type == 'video'">
                            <div
                              class="ratio ratio-16x9"
                            >
                              <video controls>
                                <source [src]="list.url" />
                              </video>
                            </div>
                          </div>
                          <!-- Progress Bar -->
                          <div class="upload_media_status" *ngIf="list.type == 'video'&& list.size < checkVideoKB">
                            <div class="first-progress">
                              <!-- <div
                                [ngStyle]="{ 'width.100%': list.FileProgress }"
                                class="second-progress"
                              ></div> -->
                              <div class="second-progress"></div>
                            </div>
                            <div class="text-center d-none">
                              {{ list.FileProgessSize }} off {{ list.FileSize }}
                            </div>
                          </div>
                        <!-- Progress Bar -->
                        <div id="error" *ngIf="list.type == 'video' && list.size >checkVideoKB" class="upload_media_error">Video Size is Not Proper</div>
                        </div>
                      </div>
                    </div>
                    <!-- <div
                      id="sliderid"
                      class="carousel slide"
                      data-bs-ride="carousel"
                      data-bs-interval="false"
                    >
                      <div
                        class="carousel-indicators"
                        *ngIf="fileUploadLength > 1"
                      >
                        <button
                          type="button"
                          data-bs-target="#sliderid"
                          [attr.data-bs-slide-to]="i"
                          [attr.aria-label]="'Slide ' + i"
                          [ngClass]="{ active: i == 0 }"
                          *ngFor="let list of mydata; let i = index"
                          [attr.aria-current]="i == 0"
                        ></button>
                      </div>
                      <div class="carousel-inner">
                        <div
                          class="carousel-item"
                          [ngClass]="{ active: i == 0 }"
                          *ngFor="let list of mydata; let i = index"
                        >
                          <img
                            alt=""
                            class="d-block w-100"
                            *ngIf="list.type == 'img'"
                            [src]="list.url"
                          />
                          <div
                            class="ratio ratio-16x9"
                            *ngIf="list.type == 'video'"
                          >
                            <video controls>
                              <source [src]="list.url" />
                            </video>
                          </div>
                        </div>
                      </div>
                      <button
                        *ngIf="fileUploadLength > 1"
                        class="carousel-control-prev"
                        type="button"
                        data-bs-target="#sliderid"
                        data-bs-slide="prev"
                      >
                        <span
                          class="carousel-control-prev-icon"
                          aria-hidden="true"
                        ></span>
                        <span class="visually-hidden">Previous</span>
                      </button>
                      <button
                        *ngIf="fileUploadLength > 1"
                        class="carousel-control-next"
                        type="button"
                        data-bs-target="#sliderid"
                        data-bs-slide="next"
                      >
                        <span
                          class="carousel-control-next-icon"
                          aria-hidden="true"
                        ></span>
                        <span class="visually-hidden">Next</span>
                      </button>
                    </div> -->
                    <!-- <button class="theme_btn mt-4" (click)="uploadImage()">
                      Upload Image
                    </button> -->
                  </div>
                </div>
                  <!-- Priview -->


                  <div class="clearfix"></div>
                  <div class="next_prev_btn tabs_footer mt-3">
                    <button
                      kendoButton
                      type="submit"
                      class="theme_btn float-end"
                      (click)="
                        nextAnswerformat(
                          postQuestionForm,
                          tabstrip,
                          QuestionTab,
                          AnswerFormatTab
                        )
                      "
                      id="next_btn"
                      [disabled]="btn_disabled"
                    >
                      Next - Response Format
                    </button>
                    <button
                      kendoButton
                      (click)="
                        postQuestion(
                          postQuestionForm,
                          globals.configurationText.questionStatus.saveAsDraft
                        )
                      "
                      class="themeoption_btn float-end third"
                      id="next_btn"
                      [disabled]="btn_disabled"
                    >
                      Save Draft
                    </button>
                    <div class="clearfix"></div>
                  </div>
                </form>
              </ng-template>
            </kendo-tabstrip-tab>
            
            <kendo-tabstrip-tab
              #AnswerFormatTab
              [title]="'Response Format'"
              [disabled]="true"
            >
              <ng-template kendoTabContent>
                <form #postQuestionForm2="ngForm">
                  <div class="form-group mb-5">
                    <kendo-dropdownlist
                      [data]="answerList"
                      [textField]="'display_text'"
                      [valueField]="'id'"
                      [valuePrimitive]="true"
                      name="question_type_id"
                      (valueChange)="answerTypeChange($event)"
                      #question_type_id="ngModel"
                      [(ngModel)]="postQuestionEntity.question_type_id"
                      required
                      [ngClass]="{
                        error:
                          ((question_type_id.dirty &&
                            question_type_id.invalid) ||
                            (question_type_id.pristine &&
                              answerFormatSubmitted &&
                              question_type_id.errors &&
                              question_type_id.errors.required)) &&
                          postQuestionEntity.required == true
                      }"
                    >
                      <ng-template kendoDropDownListItemTemplate let-dataItem>
                        <span class="template"
                          ><img
                            src="{{ dataItem.value }}"
                            alt=""
                            class="img-fluid"
                          />
                        </span>
                        {{ dataItem.display_text }}
                      </ng-template>
                    </kendo-dropdownlist>
                    <span
                      class="error_class"
                      *ngIf="
                        ((question_type_id.dirty && question_type_id.invalid) ||
                          (question_type_id.pristine &&
                            answerFormatSubmitted) ||
                          errorEntity.question_type_id) &&
                        postQuestionEntity.required == true
                      "
                    >
                      <span
                        *ngIf="
                          question_type_id.errors &&
                          question_type_id.errors.required
                        "
                        >{{
                          globals.commonTranslationText.home.form.questionType
                            .required
                        }}</span
                      >
                      <span *ngIf="errorEntity.question_type_id != ''">
                        {{ errorEntity.question_type_id }}
                      </span>
                    </span>
                  </div>
                  <div class="post_que_editor">
                    <div
                      *ngIf="
                        postQuestionEntity.question_type_id ==
                        globals.configurationText.questionType.pickList
                      "
                    >
                      <div class="answer_formate_box mt-5">
                        <h3 class="float-start">Enter your options</h3>
                        <div class="clearfix"></div>
                        <div class="row">
                          <div class="col-md-12 col-12 col-lg-7 col-sm-12">
                            <div
                              class="row"
                              *ngFor="
                                let item of OptionsList;
                                let i = index;
                                let last = last
                              "
                            >
                              <div class="col-md-8 col-8 col-lg-8 col-sm-8">
                                <div class="form-group mb-4" id="emails">
                                  <input
                                    kendoTextBox
                                    type="text"
                                    class="form-control"
                                    name="OptionValue{{ i }}"
                                    id="OptionValue{{ i }}"
                                    placeholder="Option {{ i + 1 }}"
                                    [ngClass]="{
                                      error:
                                        ((OptionValue.dirty &&
                                          OptionValue.invalid) ||
                                          (OptionValue.pristine &&
                                            optionSubmitted &&
                                            OptionValue.errors &&
                                            OptionValue.errors.required)) &&
                                        postQuestionEntity.required == true
                                    }"
                                    maxlength="100"
                                    required
                                    #OptionValue="ngModel"
                                    [(ngModel)]="OptionsList[i].OptionValue"
                                  />
                                  <span
                                    class="error_class"
                                    *ngIf="
                                      ((OptionValue.dirty &&
                                        OptionValue.invalid) ||
                                        (OptionValue.pristine &&
                                          optionSubmitted)) &&
                                      postQuestionEntity.required == true
                                    "
                                  >
                                    <span
                                      *ngIf="
                                        OptionValue.errors &&
                                        OptionValue.errors.required
                                      "
                                      >{{
                                        globals.commonTranslationText.home.form
                                          .option.required
                                      }}
                                      {{ i + 1 }}</span
                                    >
                                  </span>
                                </div>
                              </div>

                              <div
                                class="col-md-4 col-4 col-lg-4 col-sm-4 pl_0"
                              >
                                <button
                                  kendoButton
                                  *ngIf="OptionsList.length >= 4 || i == 2"
                                  class="themeoption_btn"
                                  type="button"
                                  (click)="RemoveOption(item)"
                                >
                                  <i class="fa-solid fa-xmark"></i>
                                </button>
                                <button
                                  kendoButton
                                  *ngIf="last && i < 5"
                                  class="theme_btn"
                                  type="button"
                                  (click)="AddOption(i)"
                                >
                                  <i class="fa-solid fa-plus"></i>
                                </button>
                              </div>
                            </div>
                            <div class="check_box">
                              <input
                                type="checkbox"
                                name="multiSelection"
                                id="multiSelection"
                                [checked]="
                                  postQuestionEntity.question_type_multiSelect_id ==
                                  globals.configurationText.questionType
                                    .multiPickList
                                "
                                value="{{
                                  globals.configurationText.questionType
                                    .multiPickList
                                }}"
                              />
                              <label
                                for="multiSelection"
                                class="checkboxbtn_label"
                                >Allow Multiple Selections</label
                              >
                            </div>
                          </div>
                          <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                            <div class="answer_formate_info">
                              <p>
                                "Shorter option lists generally lead to 18% more
                                responses"
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      *ngIf="
                        postQuestionEntity.question_type_id ==
                        globals.configurationText.questionType.rankedList
                      "
                    >
                      <div class="answer_formate_box mt-5">
                        <h3 class="float-start">
                          Enter Your Options for Rank List
                        </h3>
                        <div class="clearfix"></div>
                        <div class="row">
                          <div class="col-md-7 col-12 col-lg-7 col-sm-12">
                            <div
                              class="row"
                              *ngFor="
                                let item of RankList;
                                let l = index;
                                let last = last
                              "
                            >
                              <div class="col-md-8 col-8 col-lg-8 col-sm-8">
                                <div class="form-group mb-4" id="emails">
                                  <input
                                    kendoTextBox
                                    type="text"
                                    class="form-control"
                                    name="OptionValue{{ l }}"
                                    id="OptionValue{{ l }}"
                                    placeholder="Choice {{ l + 1 }}"
                                    [ngClass]="{
                                      error:
                                        ((OptionValue.dirty &&
                                          OptionValue.invalid) ||
                                          (OptionValue.pristine &&
                                            optionSubmitted &&
                                            OptionValue.errors &&
                                            OptionValue.errors.required)) &&
                                        postQuestionEntity.required == true
                                    }"
                                    maxlength="100"
                                    required
                                    #OptionValue="ngModel"
                                    [(ngModel)]="RankList[l].OptionValue"
                                  />
                                  <span
                                    class="error_class"
                                    *ngIf="
                                      ((OptionValue.dirty &&
                                        OptionValue.invalid) ||
                                        (OptionValue.pristine &&
                                          optionSubmitted)) &&
                                      postQuestionEntity.required == true
                                    "
                                  >
                                    <span
                                      *ngIf="
                                        OptionValue.errors &&
                                        OptionValue.errors.required
                                      "
                                      >{{
                                        globals.commonTranslationText.home.form
                                          .option.required
                                      }}
                                      {{ l + 1 }}</span
                                    >
                                  </span>
                                </div>
                              </div>
                              <div
                                class="col-md-4 col-4 col-lg-4 col-sm-4 pl_0"
                              >
                                <button
                                  kendoButton
                                  *ngIf="RankList.length >= 4 || l == 2"
                                  class="themeoption_btn"
                                  type="button"
                                  (click)="RemoveOptionrank(item)"
                                >
                                  <i class="fa-solid fa-xmark"></i>
                                </button>
                                <button
                                  kendoButton
                                  *ngIf="last && l < 5"
                                  class="theme_btn"
                                  type="button"
                                  (click)="AddOptionrank(l)"
                                >
                                  <i class="fa-solid fa-plus"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                            <div class="answer_formate_info">
                              <p>
                                "Shorter option lists generally lead to 18% more
                                responses"
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                  <div class="next_prev_btn tabs_footer mt-3">
                    <button
                      kendoButton
                      (click)="
                        backToQuestion(tabstrip, QuestionTab, AnswerFormatTab)
                      "
                      class="back_thread_btn themeoption_btn float-start"
                      id="prev_btn"
                    >
                      Back to Thread
                    </button>
                    <button
                      *ngIf="userRoleId == 2"
                      kendoButton
                      (click)="
                        postQuestion(
                          postQuestionForm2,
                          globals.configurationText.questionStatus.open
                        )
                      "
                      type="button"
                      class="theme_btn float-end second"
                      id="next_btn"
                      [disabled]="btn_disabled"
                    >
                      Post
                    </button>
                    <button
                      kendoButton
                      (click)="
                        postQuestion(
                          postQuestionForm2,
                          globals.configurationText.questionStatus.saveAsDraft
                        )
                      "
                      class="themeoption_btn float-end third"
                      id="next_btn"
                      [disabled]="btn_disabled"
                    >
                      Save Draft
                    </button>
                    <div class="clearfix"></div>
                  </div>
                </form>
              </ng-template>
            </kendo-tabstrip-tab>

            
          </kendo-tabstrip>
        </div>
      </div>
      <!-- <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
        <div class="home_wrap_right post_quest_right"> -->
          <!-- <div class="decideit_info">
            <div class="post_que_header">
              <h2 class="decideit_head">
                <span>Welcome To</span> Eyedea Exchange
              </h2>
            </div>
            <div class="advertisement_box mt-5">
              <p>
                IJCAHPO Eyedea Exchange is your key to fast, easy and
                clutter-free group decisions.
              </p> -->
              <!-- <p>Send to up to 1000 friends instantly, Now!</p> -->
              <!--<p>Threads Remain Live for 99 Hrs</p>-->
            <!-- </div>
          </div> -->

          <!-- <div class="about_community mt-5"
                        *ngIf="postQuestionEntity.community_id != '' && postQuestionEntity.community_id != undefined && postQuestionEntity.questionType == 1">
                        <h3 class="about_comm_head">
                            About Community
                        </h3>



                        <!-- <div class="about_community_wrap">
                            <div class="community_photo">
                                <img src="https://api.decideit.uatbyopeneyes.com/public/community-image/1617336223199_noun_Electronics_1567417.png"
                                    alt="" class="img-fluid">
                            </div>
                            <div class="community_name">
                                <a><strong>Electronics</strong></a>
                                <img src="assets/images/favicon.png" alt="" class="img-fluid">
                                <span>/ Technology</span>
                            </div>
                            <div class="clearfix"></div>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>

                        <div class="about_community_wrap">
                            <div class="community_photo">
                                <img src="https://api.decideit.uatbyopeneyes.com/public/community-image/1617337003138_noun_Dancing_858441.png"
                                    alt="" class="img-fluid">
                            </div>
                            <div class="community_name">
                                <a><strong>Ballroom</strong></a>
                                <img src="assets/images/favicon.png" alt="" class="img-fluid">
                                <span>/ Music</span>
                            </div>
                            <div class="clearfix"></div>
                            <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                        </div>

                        <div class="about_community_wrap">
                            <div class="community_photo">
                                <img src="https://api.decideit.uatbyopeneyes.com/public/community-image/1617336619167_noun_Carrom_2931617.png"
                                    alt="" class="img-fluid">
                            </div>
                            <div class="community_name">
                                <a><strong>Carrom</strong></a>
                                <img src="assets/images/favicon.png" alt="" class="img-fluid">
                                <span>/ Sports</span>
                            </div>
                            <div class="clearfix"></div>
                            <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't
                                anything embarrassing hidden in the middle of text.</p>
                        </div>

                        <div class="about_community_wrap">
                            <div class="community_photo">
                                <img src="https://api.decideit.uatbyopeneyes.com/public/community-image/1617336737598_noun_soccer_1671461.png"
                                    alt="" class="img-fluid">
                            </div>
                            <div class="community_name">
                                <a><strong>Soccer</strong></a>
                                <img src="assets/images/favicon.png" alt="" class="img-fluid">
                                <span>/ Sports</span>
                            </div>
                            <div class="clearfix"></div>
                            <p>Contrary to popular belief, Lorem Ipsum is not simply random text.</p>
                        </div> -->

          <!-- <div class="community_photo">
                            <img src="{{globals.profileImgeapiUrl}}/community-image/{{communityEntity.community_thumbnail_image}}"
                                alt="" class="img-fluid">
                        </div>
                        <div class="community_name">
                            <strong>{{communityEntity.name}}</strong>
                            <img src="assets/images/favicon.png" alt="" class="img-fluid">
                            <span>/ {{communityEntity.category.name}}</span>
                        </div>
                        <div class="clearfix"></div>
                        <p [innerHtml]="communityEntity.description"></p> --




                        <!-- <div class="created_on">
                            <span> <i class="fa-solid fa-calendar-days"></i> Created On Oct 21, 2020</span>
                        </div> --
                    </div> -->
        <!-- </div>
      </div> -->
    </div>
  </div>
</div>

<!-- <kendo-dialog title="Add Group" *ngIf="addGroupPopup" (close)="closeaddGroupPopup()" [minWidth]="250" [width]="725">
    <div class="table_block">
        <form #addGroupForm="ngForm" (ngSubmit)="addGroup(addGroupForm)">
            <div class="form-group">
                <label><span class="mendatory_feild">*</span> {{globals.commonTranslationText.home.form.addGroup.label}}
                </label>
                <input kendoTextBox type="text" class="form-control" pattern="^[a-zA-Z0-9_/]{2,}[\sa-zA-Z0-9_/]*"
                    #group_name="ngModel" data-toggle="tooltip" maxlength="100" data-placement="top"
                    [(ngModel)]="addGroupEntity.group_name" name="group_name" id="group_name"
                    [ngClass]="{error: (group_name.dirty && group_name.invalid) || (group_name.pristine && group_submitted) || errorEntity.group_name}"
                    required />
                <span class="error_class"
                    *ngIf="(group_name.dirty && group_name.invalid) || (group_name.pristine && group_submitted) || errorEntity.group_name">
                    <span *ngIf="group_name.errors && group_name.errors.required">
                        {{globals.commonTranslationText.home.form.addGroup.required}}
                    </span>
                    <span *ngIf="group_name.errors && group_name.errors.pattern">
                        {{globals.commonTranslationText.home.form.addGroup.pattern}}
                    </span>
                    <span *ngIf="errorEntity.group_name != ''">
                        <span>{{ errorEntity.group_name }}</span>
                    </span>
                </span>
            </div>
            <div class="clearfix"></div>

            <div class="col-xl-12 text-center">
                <button kendoButton type="submit" class="theme_btn">Add</button>
            </div>

            <div class="clearfix"></div>
        </form>
    </div>
</kendo-dialog> -->

<kendo-dialog
  *ngIf="mediaInfoOpened" (close)="mediaInfoClose()"
  [minWidth]="250"
  [width]="725"
  class="mediaInfo_popup"
>
<button class="close_btn" (click)="mediaInfoClose()"><i class="fa-solid fa-xmark"></i></button>
<p>Should you wish to upload media, you have the option to select a maximum of three media files simultaneously. The valid image extensions encompass 'jpg', 'jpeg', 'png', 'JPEG', 'JPG', 'PNG', 'heic','BMP','bmp','webp','WEBP' and 'HEIC'. On the other hand, the acceptable video extensions consist of '.mp3', '.guv', 'mp4', 'MP4', 'MKV', 'mkv', 'WEBM', 'webm', 'avi', and 'mov'.</p>
</kendo-dialog>
