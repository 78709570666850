<div class="privacy_wrap fixed-head">
    <div class="container-fluid">
        <div class="row pb-5">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="privacy_policy_intro">
                    <h2>Cookie<span> Statement</span></h2>
                    <p class="revised_date">Last Modified June 25, 2020</p>
                    <p>This Cookie Statement explains how IJCAHPO EYEXchange Connect (“IJCAHPO EYEXchange Connect,” “we,” “us,” or “our”) uses cookies on
                        our websites, applications, and online services, and the choices you have. </p>
                    <h3>Definition Of "Cookies"</h3>
                    <p>Cookies are small pieces of text used to store information on web browsers. Cookies are widely
                        used to store and receive identifiers and other information on computers, phones, and other
                        devices. We also use other technologies, including data we store on your web browser or device,
                        identifiers associated with your device, and other software, including web beacons and pixel
                        tags, for similar purposes. In this Cookie Statement, we refer to all of these technologies as
                        “cookies.”</p>
                    <h3>Types Of Cookies</h3>
                    <p>We use cookies to provide, protect, and improve our products and services, such as by
                        personalizing content, offering and measuring advertisements, understanding user behavior, and
                        providing a safer experience. We describe below the various types of cookies we use and the
                        purposes they perform. Please note that the specific cookies we may use vary depending on the
                        specific websites and services you use.</p>
                    <ul class="policy_list">
                        <li><strong>Essential cookies:</strong> These cookies are strictly necessary to provide you with
                            our websites and services and to enable essential features, such as providing shopping cart
                            or
                            product application functionality. If you disable these cookies, we will not be able to
                            fulfill
                            your requests.</li>
                        <li><strong>Performance and functionality cookies:</strong> These cookies collect information
                            about how you use our websites and services and allow us to remember the choices you make
                            while
                            browsing. The information these cookies collect allows us to optimize our websites and make
                            them
                            easier for you to use, and it does not personally identify you. If you disable or opt out of
                            these cookies, you may not be able to use certain features of our websites, applications,
                            and
                            services, and it may reduce the support or information that we can provide you.</li>
                        <li><strong>Analytics and customization cookies:</strong> These cookies collect information we
                            use
                            in aggregate form to help us understand how our websites, applications and services are
                            being
                            used and how effective our marketing campaigns are, and to help us customize our websites.
                            If
                            you disable or opt out of these cookies, you may not be able to use certain features of our
                            websites, applications and services, and it may reduce the support or information that we
                            can
                            provide you.</li>
                        <li><strong>Advertising cookies:</strong> These cookies collect information about your browsing
                            or shopping history and are used to make advertising messages more relevant to you. They
                            perform
                            functions like preventing the same advertisement from continuously reappearing, ensuring
                            that
                            advertisements are properly displayed, and in some cases selecting advertisements that are
                            based
                            on your interests. We may share this information with third parties to help create and
                            deliver
                            advertising personalized to you and your interests. If you disable or opt out of these
                            cookies,
                            you may not be able to use certain features of our websites, applications and services, and
                            it
                            may reduce the support or information that we can provide you.</li>
                        <li><strong>Social networking cookies:</strong> These cookies are used to enable you to share
                            pages and content on our websites and services through third-party social networking and
                            other
                            websites. These cookies may also be used for advertising purposes.</li>
                    </ul>
                    <h3>Cookies Placed By Third Parties</h3>
                    <p>You may also encounter cookies on our websites, applications and services that are placed by
                        third parties. For example, when you buy our products online, our e-commerce vendor may use
                        cookies and other technologies. We may also allow third parties to place cookies on our websites
                        to track information about your online activities and/or across third-party sites or online
                        services, including to send you targeted advertisements based on that information, which may
                        include the remarketing of our products and services that you have viewed on our websites and on
                        third-party websites.</p>
                    <p>This Cookie Statement does not apply to the cookies, applications, technologies, or websites that
                        are owned and/or operated by third parties, or such third parties’ practices, even if they use
                        or access our technology to store or collect information. To understand how third parties use
                        cookies, please review their privacy and cookie policies.</p>
                    <h3>Interest-Based Advertising</h3>
                    <p>We utilize third-party service providers to deliver advertising on our sites and to serve ads on
                        third-party sites. This enables us to direct advertisements to you for products and services in
                        which you might be interested (interest-based ads). We respect the ad industries’
                        Self-Regulatory Principles for Online Behavioral Advertising. </p>
                    <p>To learn more or to opt out of interest-based advertisements, you may visit:</p>
                    <ul class="policy_list">
                        <li><a href="http://www.aboutads.info/choices/">Digital Advertising Alliance (DAA)</a></li>
                        <li><a href="http://optout.networkadvertising.org/">Network Advertising Initiative (NAI)</a>
                        </li>
                    </ul>
                    <h3>Controlling And Opting Out Of Cookies</h3>
                    <p>Your browser or device may offer settings that allow you to choose whether browser cookies are
                        set and to delete them. For more information about these controls and to exercise your cookie
                        preferences, visit your browser or device’s help material. If you choose to reject cookies, as
                        noted above, you may not be able to use certain features of our websites and services.</p>
                    <h3>Do Not Track</h3>
                    <p>Some browsers include the ability to transmit “Do Not Track” signals. We do not process or
                        respond to “Do Not Track” signals. Instead, we adhere to the standards described in our <a
                            href="https://www.westerndigital.com/legal/privacy-statement">Privacy
                            Statement</a> and this <a
                            href="https://www.westerndigital.com/legal/cookie-statement">Cookie Statement</a>.</p>
                </div>
            </div>
        </div>
    </div>
</div>