import { Injectable } from '@angular/core';
// import {Http, Headers} from '@angular/http';
import { Router } from '@angular/router';
import { Globals } from '.././globals';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient,HttpHeaders } from "@angular/common/http";
import { HttpParams } from "@angular/common/http";
declare var FB: any;
// const apiUrl ="http://localhost:8080/socialapi/";
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // -x8Fg4y9jV65ZHBUEHJ7IteZgi8
  constructor(private http: HttpClient, private globals: Globals, private router: Router) { 
    (window as any).fbAsyncInit = function() {
      FB.init({
        appId      : '272702324005144',
        cookie     : true,
        xfbml      : true,
        version    : 'v8.0'
      });
      FB.AppEvents.logPageView();
    };
  
    (function(d, s, id){
       var js, fjs = d.getElementsByTagName(s)[0];
       if (d.getElementById(id)) {return;}
       js = d.createElement(s); js.id = id;
       js.src = "https://connect.facebook.net/en_US/sdk.js";
       fjs.parentNode.insertBefore(js, fjs);
     }(document, 'script', 'facebook-jssdk'));
  }

//   postData(credentials, type) {
//     return new Promise((resolve, reject) => {
//     const headers = new Headers();
//     this.http.post(apiUrl + type, JSON.stringify(credentials))
//     .subscribe(res => {
//          resolve(res);
//      }, (err) => {
//      reject(err);
//  });

// });

// }
login(entity)
{
  let promise = new Promise((resolve, reject) => {
    let headers = new HttpHeaders().set('access-control-allow-origin',`http://localhost:4300/login?code=${entity.code}`);
    const httpHeaders: HttpHeaders = new HttpHeaders({
      'Access-Control-Allow-Origin' : `*`,
      'Access-Control-Allow-Credentials' : `true`,
      'Access-Control-Allow-Methods' : `GET, POST, OPTIONS`,
      'Access-Control-Allow-Headers' : `Origin, Content-Type, Accept`
  });
    // return this.httpClient.post<any>(`https://www.linkedin.com/oauth/v2/accessToken`, entity);
    // this.http.post(`https://www.linkedin.com/oauth/v2/accessToken?grant_type=authorization_code&code=${entity.code}&redirect_uri=${this.globals.baseUrl}/login/&client_id=77luhum7k34pkd&client_secret=Zt0jtIwkk2bGDbRb`, entity,{ headers: headers })
    this.http.post(`https://www.linkedin.com/oauth/v2/accessToken?grant_type=authorization_code&code=${entity.code}&redirect_uri=http://localhost:4200/login/&client_id=77luhum7k34pkd&client_secret=Zt0jtIwkk2bGDbRb`,{ headers: httpHeaders })
    // this.httpClient.post<any>(`https://www.linkedin.com/oauth/v2/accessToken`, entity)
      .toPromise()
      .then(
        res => { // Success 
          //debugger
          let result = res;
          resolve(res);
        },
        msg => { // Error
          //debugger
          reject(msg);
        }
      );
  });
  return promise;
}

getSocialNetworks() {
  ////debugger
  let jwtHelper = new JwtHelperService();
  let promise = new Promise((resolve, reject) => {
    this.http.get(this.globals.baseAPIUrl + 'social-network')
      .toPromise()
      .then(
        res => { // Success 
          let result = res;
          resolve(res);
        },
        msg => { // Error
          reject(msg);
        }
      );
  });
  return promise;
}
  UserInviteAction(entity,verifyEmailAPILink) {
    ////debugger
    let jwtHelper = new JwtHelperService();
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.globals.baseAPIUrl + verifyEmailAPILink, entity)
        .toPromise()
        .then(
          res => { // Success 
            let result = res;
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }
  
  resendEmailVerification(entity) {
    ////debugger
    let jwtHelper = new JwtHelperService();
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.globals.baseAPIUrl + 'email-verification-resend', entity)
        .toPromise()
        .then(
          res => { // Success 
            let result = res;
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  checkLogin(loginEntity) {
    //debugger
    let jwtHelper = new JwtHelperService();
    let promise = new Promise((resolve, reject) => {
      this.http.post(this.globals.baseAPIUrl + 'login', loginEntity)
        .toPromise()
        .then(
          res => { // Success 
            let result = res;
            if (result && result['access_token']) {
              localStorage.setItem('token', result['access_token']);
              localStorage.setItem('token_type', result['token_type']);
              localStorage.setItem('email_available',result['email_available']);
              localStorage.setItem('username_available',result['username_available']);
              localStorage.setItem('force_change_password',result['force_change_password']);
              if(result['redirect_profile_msg'])
              {
                localStorage.setItem('redirect_profile_msg',result['redirect_profile_msg']);
              }
              //get the user details
              let promise = new Promise((resolve, reject) => {
                this.http.get(this.globals.baseAPIUrl + 'user')
                  .toPromise()
                  .then(
                    res => { // Success 
                      localStorage.setItem('authData',JSON.stringify(res));
                      //localStorage.setItem('isUser',);
                      resolve(res);
                    },
                    msg => { // Error
                      reject(msg);
                    }
                  );
              });
                // this.getUser();
            }
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  isLoggedIn() {
    ////debugger
    let jwtHelper = new JwtHelperService();
    let token = localStorage.getItem('token');
    let isExpired = jwtHelper.isTokenExpired(token) ? true : false;
    if (isExpired) {
      this.globals.authData = undefined;
    }
    return !isExpired;
  }

  logout() {
    // if(this.globals.authData.social_network_id == 2)
    // {
    //   console.log(sessionStorage.getItem("fbssls_272702324005144"));
    //   var s = sessionStorage.getItem("fbssls_272702324005144");
    //   // FB.getLoginStatus(function(response) {
    //     FB.logout(function(s){
    //       console.log("Logged Out!");
    //       // window.location = "/";
    //     });
    //   // });
    // }
    let promise = new Promise((resolve, reject) => {
      this.http.get(this.globals.baseAPIUrl + 'logout')
        .toPromise()
        .then(
          res => { // Success
            this.globals.authData = '';
            // localStorage.removeItem('token');
            // localStorage.removeItem('token_type');
            // localStorage.removeItem('authData');
            // localStorage.removeItem('getUserPermission');
            localStorage.clear();
            sessionStorage.clear();
            resolve(res);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }
}
