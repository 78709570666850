<div class="container-fluid login_register_bg fixed-head">
    <div class="loginregister_white_block">
      <div class="login_logo_block">
        <div class="text-center">
          <a routerLink="/login">
            <img src="assets/images/resetpassword.png" width="350" class="img-fluid" alt="">
          </a>
        </div>
      </div>
          <div class="white_login_block">
            <div class="row d-flex align-items-center">
              <div class="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12">                
              </div>
              <div class="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <div class="padding_login_block">
                  <div class="login-head">
                  <h3>{{globals.commonTranslationText.resetPasswordPage.title}}</h3>
                </div>
                <p class="pb-2">{{globals.commonTranslationText.resetPasswordPage.welcomeTitle}}</p>              
                <p class="padding_15">{{globals.commonTranslationText.resetPasswordPage.subTitle}}</p>
                <div class="clearfix"></div>
                  <form #resetPasswordForm="ngForm" (ngSubmit)="resetPassword(resetPasswordForm)">
                    <div class="form-group mb-5">
                      <label><span class="mendatory_feild">*</span> {{globals.commonTranslationText.resetPasswordPage.form.newPassword.label}}
                      </label>
                      <input kendoTextBox type="password" class="form-control" (keyup)="confirmPassword()"
                        pattern="^([a-zA-Z0-9.-_-@$*&!#]{8,})$" #Password="ngModel" data-toggle="tooltip" maxlength="100"
                        data-placement="top"
                        title="Use 8 or more characters with a mix of letters, numbers & symbols(! @ # $ & * _ - )"
                        [(ngModel)]="passwordEntity.Password" name="Password" id="newpassword"
                        [ngClass]="{error: (Password.dirty && Password.invalid) || (Password.pristine && submitted) || errorEntity.Password}"
                        required />
                      <i toggle="#password-field" class="fa-regular fa-fw fa-eye toggle-password icon_without_label" id="newpassword-show"></i>
                      <span class="error_class"
                        *ngIf="(Password.dirty && Password.invalid) || (Password.pristine && submitted) || errorEntity.Password">
                        <span *ngIf="Password.errors && Password.errors.required">
                          {{globals.commonTranslationText.resetPasswordPage.form.newPassword.required}}
                        </span>
                        <span *ngIf="Password.errors && Password.errors.pattern">
                          {{globals.commonTranslationText.resetPasswordPage.form.newPassword.pattern}}
                        </span>
                        <span *ngIf="errorEntity.Password != ''">
                          <span>{{ errorEntity.Password }}</span>
                        </span>
                      </span>
                    </div>
                    <div class="form-group mb-5">
                      <label><span class="mendatory_feild">*</span> {{globals.commonTranslationText.resetPasswordPage.form.confirmPassword.label}}
                      </label>
                      <input kendoTextBox type="password" class="form-control" (keyup)="confirmPassword()"
                        pattern="[a-zA-Z0-9.-_-@$*&!#]{8,}" #ConfirmPassword="ngModel" maxlength="100"
                        [(ngModel)]="passwordEntity.ConfirmPassword" name="ConfirmPassword" id="confpassword"
                        [ngClass]="{error: (ConfirmPassword.dirty && ConfirmPassword.invalid) || (ConfirmPassword.pristine && submitted||(newconfsame && !(ConfirmPassword.errors && ConfirmPassword.errors.pattern && ConfirmPassword.errors.required)))  || errorEntity.ConfirmPassword}"
                        required />
                      <i toggle="#password-field" class="fa-regular fa-fw fa-eye toggle-password icon_without_label" id="confpassword-show"></i>
                      <span class="error_class"
                        *ngIf="(ConfirmPassword.dirty && ConfirmPassword.invalid) || (ConfirmPassword.pristine && submitted) || newconfsame || errorEntity.ConfirmPassword">
                        <span *ngIf="Password.errors && Password.errors.required  && !newconfsame">
                          {{globals.commonTranslationText.resetPasswordPage.form.confirmPassword.required}}
                        </span>
                        <span *ngIf="ConfirmPassword.errors && ConfirmPassword.errors.pattern && !newconfsame">
                          {{globals.commonTranslationText.resetPasswordPage.form.confirmPassword.pattern}}
                        </span>
                        <span
                          *ngIf="newconfsame && !(ConfirmPassword.errors && ConfirmPassword.errors.pattern && ConfirmPassword.errors.required)">
                          {{globals.commonTranslationText.resetPasswordPage.form.confirmPassword.match}}
                        </span>
                        <span *ngIf="errorEntity.ConfirmPassword != ''">
                          <span>{{ errorEntity.ConfirmPassword }}</span>
                        </span>
                      </span>
                    </div>
                    <div class="clearfix"></div>
                    <button kendoButton type="submit"
                      class="all_btn theme_btn float-end">{{globals.commonTranslationText.resetPasswordPage.buttons.submit}}</button>
                    <div class="clearfix"></div>
                  </form>
                </div>
              </div>
            </div>
          </div>
    </div>
  </div>