<div class="privacy_wrap fixed-head">
    <div class="container-fluid">
        <div class="row pb-5">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="privacy_policy_intro">
                    <h2>Privacy<span> Statement</span></h2>
                    <p>This Privacy Statement explains how DecideIt (“DecideIt,” “we,” “us,” or “our”) collects, uses,
                        and discloses your information. The Privacy Statement applies to all DecideIt products and
                        services, except those products and services that have a separate privacy statement.</p>
                    <p>Where applicable, you consent to the use of your information as described in this Privacy
                        Statement each time you use our products or services, so we urge you to read this document in
                        full and <a href="mailto:privacy@decideit.com">Contact Us</a> if you have any threads.</p>
                    <h3>Collection And Use Of Personal Information</h3>
                    <p>Personal information is information that can be used to directly or indirectly identify you.
                        Personal information also includes anonymous information that is linked to information that can
                        be used to directly or indirectly identify you. Personal information does not include
                        information that has been irreversibly anonymized or aggregated so that it can no longer enable
                        us, whether in combination with other information or otherwise, to identify you.</p>
                    <p>Here is a description of the types of personal information we may collect and how we may use it:
                    </p>
                    <h3>What Personal Information We Collect</h3>
                    <p>Depending on the products and services you use, we may collect and have collected within the
                        preceding 12 months different kinds of personal information from or about you.</p>
                    <ul class="policy_list">
                        <li><strong>Information you provide:</strong> We may collect the personal information you
                            provide—such
                            as your name, username, email address, phone number, or mailing address—when you use our
                            products and services, create an account, contact us, participate in an online survey,
                            participate in community discussions or chat rooms, apply for employment, sign-up to
                            receive our marketing communications, interact with us at in-person events, or otherwise
                            interact with us.</li>
                        <li><strong>Information about payments:</strong> If you make a purchase, we may collect
                            personal information in connection with the purchase. This information includes payment
                            information, such as your credit or debit card number and other card information; other
                            account and authentication information; and billing, shipping, and contact details.</li>
                        <li><strong>Information about use of our products and services:</strong> When you use our
                            products and services, including our websites and web, mobile, and desktop applications, we
                            may collect information about the type of device you use, your device configuration, your
                            device’s unique identifier, the IP address of your device, your operating system, the type
                            of Internet browser that you use, usage information, diagnostic information, browsing
                            information, session summary information, CPU and other usage statistics, API calls, service
                            crash information, network status, storage utilization, search terms, file attributes
                            (including attributes for photos, videos, music, and documents), and location information
                            from or about the computers, phones, or other devices on which you install or access our
                            products or services. We may gather some of this information automatically. Where available,
                            our services may use GPS, your IP address, and other technologies to determine a device’s
                            approximate location to allow us to improve our products and services.</li>
                        <li><strong>Information from third parties:</strong> We may collect information from third
                            parties, including business partners and marketing agencies. This includes your contact
                            information from marketing partners when we engage in joint marketing or co-branding
                            activities, your IP address or location information from service providers to offer certain
                            products and services relevant to your location, and data from your social networks to
                            authenticate your product use with us, or that you grant permission to our products or
                            services to access.</li>
                    </ul>
                    <h3>How We Use Your Personal Information</h3>
                    <p>We may use personal information for the purposes described in this Privacy Statement. Generally
                        speaking, we use personal information to provide, improve, develop, and personalize your
                        experience with our products and services, to communicate with you, to offer you targeted
                        advertisements and services, and to protect us and our users. We may do so with your consent,
                        for compliance with a legal obligation, for the performance of a contract with you, or for our
                        legitimate interests. DecideIt LLC collects, processes, and determines how to process your
                        personal information as data controller for the following purposes:</p>
                    <ul class="policy_list">
                        <li><strong>Providing, improving, developing, and personalizing your experience with our
                                products and services:</strong> We may use personal information to help us provide,
                            improve, develop, and personalize your experience with our products and services. This
                            includes using personal information for purposes such as data analysis, research, and
                            audits. We may perform such processing based on our legitimate interest in offering you
                            products and services and for business continuity. If you enter a sweepstakes, contest, or
                            other promotion, we may use the personal information you provide to administer those
                            programs. Some of these activities have additional rules, which may contain further
                            information about how we use personal information, so we encourage you to read those rules
                            carefully before participating.</li>
                        <li><strong>Communicating with you:</strong> We may use personal information to communicate with
                            you about important notices, including notices related to your account, products, services,
                            transactions, or changes to our policies and terms. Such processing is based on our
                            legitimate interest in interacting with you to provide you with our products and services.
                            We may also use personal information to communicate with you about your employment requests.
                            Our privacy notices to applicants and employees provide additional information concerning
                            the personal information we collect and how we use personal information in connection with
                            your employment requests. Subject to your prior express consent, we may use personal
                            information to send you marketing communications in relation to DecideIt’s own products and
                            services, and we may share your personal information with third-party partners who may send
                            you marketing communications in relation to their products and services. If you no longer
                            wish to receive email communications for marketing purposes, please <a
                                href="mailto:privacy@decideit.com">Contact
                                Us</a> to opt out.
                            We may use your information to process and respond to your requests when you contact us.
                        </li>
                        <li><strong>Offering and measuring targeted advertisements and services:</strong> Subject to
                            your prior express consent, we may use personal information to provide you with personalized
                            advertisements, including on third-party websites and applications, and to determine the
                            effectiveness of our promotional campaigns.</li>
                        <li><strong>Promoting safety and security:</strong> We may use personal information to help
                            verify accounts and user activity, as well as to promote safety and security, such as by
                            monitoring fraud and investigating suspicious or potentially illegal activity or violations
                            of our terms or policies. Such processing is based on our legitimate interest in helping to
                            ensure the safety of our products and services.</li>
                    </ul>
                    <p>For any of the uses of your data described above that require your prior express consent, note
                        that you may withdraw your consent by <a href="mailto:privacy@decideit.com">Contacting Us</a>.
                    </p>
                    <h3>Cookies And Similar Technologies</h3>
                    <p>We use and have used within the preceding 12 months cookies and similar technologies to provide,
                        protect, and improve our products and services, such as by personalizing content, offering and
                        measuring advertisements, understanding user behavior, and providing a safer experience.</p>
                    <p>You can remove or reject cookies using your browser or device settings, but in some cases doing
                        so may affect your ability to use our products and services.</p>
                    <p>Please read our <a href="https://www.decideit.com/legal/cookie-statement">Cookie Statement</a> to
                        learn more about
                        how we use cookies and the
                        choices you have.
                    </p>
                    <h3>Disclosure Of Personal Information</h3>
                    <p>We make certain personal information available to strategic partners that work with us to provide
                        our products and services or help us market to customers. Personal information will only be
                        shared and has been shared within the preceding 12 months by us with these companies in order to
                        provide or improve our products, services, and advertising; it will not be shared and has not
                        been shared within the preceding 12 months with third parties for their own marketing purposes.
                    </p>
                    <h3>Service Providers</h3>
                    <p>We may share personal information with companies that provide services on our behalf, such as
                        website hosting; email services; marketing; advertising; sponsoring of sweepstakes, contests,
                        and other promotions; auditing; payment processing; fulfilling customer orders; data analytics;
                        providing customer support; conducting customer research and satisfaction surveys; cloud storage
                        services; and other services that assist in providing or selling our products and services.
                        These companies are obligated to protect your information and may be located wherever we
                        operate.</p>
                    <h3>Corporate Affiliates and Corporate Business Transactions</h3>
                    <p>We may share personal information with all DecideIt Corporation-affiliated companies. In the
                        event of a merger, reorganization, acquisition, joint venture, assignment, spin-off, transfer,
                        or sale or disposition of all or any portion of our business, including in connection with any
                        bankruptcy or similar proceedings, we may transfer any and all personal information to the
                        relevant third party.</p>
                    <h3>Legal Compliance and Security</h3>
                    <p>It may be necessary—by law or as a result of legal process, litigation, or requests from public
                        or governmental authorities within or outside your country of residence—for us to disclose
                        personal information. We may also disclose personal information if we determine that for
                        purposes of national security, law enforcement, or other issues of public importance, disclosure
                        is necessary or appropriate.</p>
                    <p>We may also disclose personal information if we determine in good faith that disclosure is
                        reasonably necessary to protect our rights and pursue available remedies, enforce our terms and
                        conditions, investigate fraud, or protect our operations or users.</p>
                    <h3>Your Rights</h3>
                    <p>We take reasonable steps to ensure that your personal information is accurate, complete, and
                        up-to-date. Depending on where you live, you may have the rights described below.</p>
                    <p>You have the right to access, correct, or delete the personal information that we collect. You
                        are also entitled to object to or restrict, at any time, the further processing of your personal
                        information. You have the right to receive your personal information in a structured and
                        standard format. You may lodge a complaint with the competent data protection authority
                        regarding the processing of your personal information.</p>
                    <p>To protect the privacy and the security of your personal information, we may request information
                        from you to enable us to confirm your identity and right to access such information, as well as
                        to search for and provide you with the personal information we maintain. There are instances
                        where applicable laws or regulatory requirements allow or require us to refuse to provide or
                        delete some or all of the personal information that we maintain.</p>
                    <p>You may <a href="mailto:privacy@decideit.com">Contact Us</a> to exercise your rights. We will
                        respond to your request in a
                        reasonable
                        timeframe, and in any event in less than 30 days.</p>
                    <h3>California Privacy Rights</h3>
                    <p>If you are a California resident, California law permits you to request information regarding the
                        disclosure of your personal information to third parties for the third parties’ direct marketing
                        purposes. To make such a request, please <a href="mailto:privacy@decideit.com">Contact Us</a>
                        and include “California
                        Privacy Rights
                        Request” in the first line of the request.</p>
                    <p>The California Consumer Privacy Act of 2018, California Civil Code Sections 1798.100 et seq.
                        (“CCPA”) affords certain rights to you if you are a California resident. Please refer to our
                        <a href="https://www.decideit.com/legal/california-consumer-privacy-act-notice">California
                            Consumer Privacy Act Notice</a> for additional disclosures and information
                        relating to
                        those rights.
                    </p>
                    <h3>Closing Accounts</h3>
                    <p>Please <a href="mailto:privacy@decideit.com">Contact Us</a> to close your account in relation to
                        the following uses of your
                        personal
                        information:</p>
                    <ul class="policy_list">
                        <li>providing, improving, developing, and personalizing your experience with our products and
                            services (for such requests, DecideIt Technologies, Inc. will act on behalf of its local
                            affiliates);</li>
                        <li>communicating with you; </li>
                        <li>sharing your personal information with third-party partners for marketing communications;
                        </li>
                        <li>offering and measuring targeted advertisements and services; or</li>
                        <li>promoting safety and security.</li>
                    </ul>
                    <p>If you close your account, we have no obligation to retain your information, and we may delete
                        any or all of your information without liability. We may retain, however, information related to
                        you if we believe it may be necessary to prevent fraud or future abuse, if required by law, or
                        for legitimate purposes, such as analysis of non-personal information, account recovery,
                        maintaining the functionality or security of our systems, auditing our records, or enforcing our
                        rights and obligations under our agreements.</p>
                    <h3>Third-Party Websites And Services</h3>
                    <p>Our products and services, including our websites and digital media properties, may contain links
                        to or the ability for you to access third-party websites, products, and services. We are not
                        responsible for the privacy practices employed by those third parties, nor are we responsible
                        for the information or content their products and services contain. This Privacy Statement
                        applies solely to information collected by us. We encourage you to read the privacy policies of
                        any third parties before proceeding to use their websites, products, or services.</p>
                    <h3>Information Security, Integrity, And Retention</h3>
                    <p>It is important that you take precautions to protect against unauthorized access to your DecideIt
                        products, account credentials, and computers or other devices. If you feel that the security of
                        your account or personal information has been compromised, please immediately <a
                            href="mailto:privacy@decideit.com">Contact
                            Us</a>. Please
                        be aware that, despite our efforts, no security system is impenetrable. In the event of a
                        security breach, we will promptly notify you and the proper authorities if required by law.</p>
                    <p>We will retain your personal information for as long as it is necessary to fulfill the purposes
                        outlined in this Privacy Statement, unless a longer retention period is required or permitted by
                        law.</p>
                    <h3>Children</h3>
                    <p>We do not knowingly collect, use, or disclose information from children under the age of 16. If
                        we learn that we have collected the personal information of a child under the age of 16—or the
                        equivalent minimum age depending on the jurisdiction, such as 13 in the United States—we will
                        take steps to delete the information as soon as possible. Please immediately <a
                            href="mailto:privacy@decideit.com">Contact
                            Us</a> if you
                        become aware that a child under the age of 16 has provided us with personal information.</p>
                    <p>If you are a California resident under the age of 18, and you are a registered user of our
                        products or services, California law permits you to request and obtain removal of content or
                        information you have publicly posted. To make such a request, please <a
                            href="mailto:privacy@decideit.com">Contact Us</a>
                        with specific
                        information about where the content or information is posted and attesting that you posted it.
                        We will then make reasonable, good faith efforts to remove the post from prospective public
                        view, or anonymize it so you cannot be individually identified, to the extent required by
                        applicable law. Please be aware that such a request does not ensure complete or comprehensive
                        removal of the content or information you have posted and that there may be circumstances in
                        which the law does not require or allow removal even if requested.</p>
                    <h3>Information Transfers, Storage, And Processing Globally</h3>
                    <p>When we share your personal information with our partners, DecideIt-affiliated companies, and
                        service providers, your personal information may be transferred to and/or made accessible from
                        countries out of the European Economic Area. In such circumstances, we will enter into model
                        contractual clauses as adopted by the European Commission, or rely on alternative legal bases
                        such as the Privacy Shield, where applicable, or binding corporate rules where our partners or
                        service providers have adopted such internal policies approved by European data protection
                        authorities. For some of our services, your personal information may be transferred to and
                        processed on servers located outside of the country where you reside for the purpose of
                        providing the services to you.</p>
                    <h3>Changes To This Privacy Statement</h3>
                    <p>We may periodically change this Privacy Statement to keep pace with new technologies, industry
                        practices, and regulatory requirements, among other reasons. We expect most such changes to be
                        minor. Any non-material changes will take effect immediately upon posting of an updated Privacy
                        Statement, and we will indicate the Last Modified date at the top of this document. There may,
                        however, be cases where changes to this Privacy Statement may be more significant. In such
                        cases, we will provide you with a prominent notice of such changes before they take effect or
                        will directly send you a notification.</p>
                    <p>Your continued use of our products and services after the effective date of the Privacy Statement
                        means that you accept the revised Privacy Statement. If you do not agree to the revised Privacy
                        Statement, please refrain from using our products and services and <a
                            href="mailto:privacy@decideit.com">Contact Us</a> to
                        close any
                        account you may have created.</p>
                    <h3>Contact Us</h3>
                    <p>If you have any threads regarding this Privacy Statement or its implementation, you may email
                        our Data Protection Officer (DPO) at <a href="mailto:DPO@decideit.com">DPO@decideit.com</a>.</p>
                </div>
            </div>
        </div>
    </div>
</div>