import { CommunityService } from './../app-admin/services/community.service';
import { AddressbookService } from './../services/addressbook.service';
import { AuthService } from '../services/auth.service';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Globals } from '../globals';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import {
  DataBindingDirective,
  PageChangeEvent,
  GridDataResult,
  DataStateChangeEvent,
} from '@progress/kendo-angular-grid';
import {
  SortDescriptor,
  orderBy,
  process,
  State,
} from '@progress/kendo-data-query';
import { empty } from 'rxjs';
declare var $, Swal: any, PerfectScrollbar;
function _window(): any {
  //debugger;
  // return the global native browser window object
  return window;
}
@Component({
  selector: 'app-addressbook',
  templateUrl: './addressbook.component.html',
  styleUrls: ['./addressbook.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AddressbookComponent implements OnInit {
  public gridView: GridDataResult;
  deleteEntity;
  todaysdate;
  exportName;
  submitted;
  submitted2;
  addressBookEntity;
  errorEntity;
  message;
  groupName;
  communityList: any;
  grouplist: any;
  addressbooklist: any;
  editContactDetails: any;
  pageSize = this.globals.pageSize;
  allowUnsort = true;
  ContactPopUp;
  GroupPopUp;
  groupSelectType = 'new';
  groupEntity;
  socialNetworkEntity;
  newGroup;
  oldGroup;

  skip = 0;
  paginationEntity = {
    user_id: [this.globals.authData.id],
    limit: this.pageSize,
    offset: 0,
    searchData: {
      status: '',
      searchQuery: '',
    },
    sortOrder: [
      {
        field: 'id',
        dir: 'asc',
      },
    ],
  };
  state = {
    skip: 0,
    take: this.pageSize,
  };
  sort: SortDescriptor[] = [
    {
      field: 'id',
      dir: 'asc',
    },
  ];
  phoneValid;
  public opened = false;

  public close(status) {
    console.log(`Dialog result: ${status}`);
    this.opened = false;
  }

  // public open() {
  //   this.opened = true;
  // }

  public selectGroup = {
    select: null,
  };
  constructor(
    public globals: Globals,
    private router: Router,
    private route: ActivatedRoute,
    private AddressbookService: AddressbookService,
    private AuthService: AuthService,
    private CommunityService: CommunityService
  ) { }

  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  ngOnInit() {
    const oauthScript = document.createElement('script');
    oauthScript.src =
      'https://cdn.rawgit.com/oauth-io/oauth-js/c5af4519/dist/oauth.js';
    document.body.appendChild(oauthScript);
    // setTimeout(function () {
    //   $(document).ready(function () {
    //     const body = document.querySelector('body');
    //     body.style.setProperty('--screen-height', $(window).height() + 'px');
    //   });
    // }, 100);
    this.addressBookEntity = {};

    this.errorEntity = {};
    this.getAddressBook();
    this.socialNetworkEntity = [];
    this.AuthService.getSocialNetworks().then(
      (data) => {
        console.log(data);
        this.socialNetworkEntity = data['data'];
      },
      (error) => {
        this.globals.isLoading = false;
        console.log(error);
      }
    );

    this.globals.isLoading = true;
  }


  confirmpopup(e, provider, social_network_id) {
    //debugger
    Swal.fire({
      title: '',
      text: 'You have chosen to import contacts from Google. If any of the contacts imported form Google already exists in your address book, what do you want to do?',
      type: 'warning',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#a40b33',
      cancelButtonColor: '#666666',
      confirmButtonClass: 'theme_btn',
      confirmButtonText: 'Replace Address Book Contact',
      cancelButtonClass: 'cancel_btn',
      cancelButtonText: 'Add Duplicate Address Book Contact',
    })
      .then((willDelete) => {
        this.globals.isLoading = true;
        if (willDelete['value']) {
          this.submitSocialLogin(e, provider, social_network_id, 1);
          this.opened = false;
        } else {
          if (willDelete['dismiss'] == 'cancel') {
            this.submitSocialLogin(e, provider, social_network_id, 0);
            this.opened = false;
          }
        }
        this.globals.isLoading = false;
      });
  }
  getAddressBook() {
    //debugger;
    let id = this.globals.authData.id;
    this.globals.isLoading = true;
    if (id) {
      var entity = {
        user_id: id,
        limit: 10,
        offset: 0,
      };
      this.submitted = true;
    }
    this.AddressbookService.getuserAddressBook(this.paginationEntity).then(
      (data: any) => {
        console.log(data);
        this.addressbooklist = {
          data: data.count > 0 ? orderBy(data.data, this.sort) : '',
          total: data.count,
        };
        console.log(this.addressbooklist);
        this.globals.isLoading = false;
      },
      (error) => {
        console.log(error);

        this.globals.isLoading = false;
      }
    );
    this.globals.isLoading = false;
  }

  addressbookPopup() {
    this.addressBookEntity = {};
    this.ContactPopUp = true;
    this.submitted = false;
    this.addressBookEntity.is_active = 1;
  }

  addressbookPopupClose() {
    this.ContactPopUp = false;
  }
  addGroupPopup() {
    let id = this.globals.authData.id;
    this.newGroup = true;
    if (id) {
      var entity = { user_id: id };
      this.submitted = true;
    }
    this.submitted2 = false;
    let a = [];
    for (var j = 0; j < this.addressbooklist.data.length; j++) {
      if (this.addressbooklist.data[j].certificateCheck == true) {
        this.addressbooklist.data[j].certificateCheck = true;
        a.push({ address_book_id: this.addressbooklist.data[j].id });
      } else {
        this.addressbooklist.data[j].certificateCheck = false;
      }
    }
    // $(".checkboxes:checked").each(function() {
    //   // this.address_book_id = this.value;
    //  a.push({"address_book_id":this.value});
    // });

    if (a.length == 0) {
      Swal.fire({
        type: this.globals.configurationText.sweetAlertTypeIconError,
        icon: this.globals.configurationText.sweetAlertTypeIconError,
        customClass: this.globals.configurationText.sweetAlertClassError,
        toast: true,
        title: this.globals.commonTranslationText.addressbook.alerts
          .notSelectedGroup.text,
        animation: true,
        position: this.globals.configurationText.sweetAlertPosition,
        showConfirmButton: false,
        timer: this.globals.configurationText.sweetAlertTimer,
        timerProgressBar: true,
        showCancelButton: true,
        cancelButtonText: 'x',
      });
      return false;
    }
    this.groupEntity = {};
    this.grouplist = [];
    this.groupEntity.grouptype = 1;
    this.groupEntity.details = a;
    this.GroupPopUp = true;
    this.oldGroup = false;
    this.AddressbookService.getusergroup(entity).then(
      (data: any) => {
        // this.grouplist.details = [];
        this.grouplist = {
          details: data.data,
        };
        // var selectGroup: any;
        // selectGroup = data.data;
        // var answerTypeSelect = {
        //   id: '',
        //   name: 'Select Group',
        //   value: '',
        // };
        // this.grouplist.details.push(answerTypeSelect);
        // this.grouplist = [...this.grouplist.details, ...selectGroup];
        console.log(this.grouplist.details);
        this.globals.isLoading = false;
      },
      (error) => {
        console.log(error);

        this.globals.isLoading = false;
      }
    );
    this.globals.isLoading = false;
  }
  submitSocialLogin(e, provider, social_network_id, status) {
    e.preventDefault();
    _window().OAuth.initialize(
      this.globals.configurationText.OAuth.oauthPublicKey
    );
    _window()
      .OAuth.popup(provider)
      .then((provider) => {
        provider
          .get(
            '/m8/feeds/contacts/default/thin?alt=json&access_token=' +
            provider.access_token +
            '&max-results=1000&v=3.0'
          )
          .then((data) => {
            //debugger
            this.globals.isLoading = true;
            console.log('Contacts:', data.feed.entry);
            // console.log(JSON.stringify(data));
            // console.log(JSON.parse(data));
            var arr = new Array();
            data['feed']['entry'].forEach(function (contact, index) {
              //debugger
              if (contact['gd$name']) {
                if (contact['gd$name']['gd$givenName']) {
                  var fname = contact['gd$name']['gd$givenName']['$t'];
                } else {
                  var fname = null;
                }
                if (contact['gd$name']['gd$familyName']) {
                  var lname = contact['gd$name']['gd$familyName']['$t'];
                } else {
                  var lname = null;
                }

              } else {
                var fname = null;
                var lname = null;
              }
              if (contact['gd$email']) {
                contact['gd$email'].forEach(function (email) {
                  arr.push({
                    contact_first_name: fname,
                    contact_last_name: lname,
                    contact_email: email['address'],
                    contact_phone: null,
                  });
                });
              } else {

              }
              if (contact['gd$phoneNumber']) {
                contact['gd$phoneNumber'].forEach(function (phoneNumber) {
                  arr.push({
                    contact_first_name: fname,
                    contact_last_name: lname,
                    contact_phone: phoneNumber['$t'],
                    contact_email: null,
                  });
                });
              }
            });
            var addt = { importContact: arr, UserId: this.globals.authData.id, 'status': status };
            this.AddressbookService.addImportContact(addt).then(
              (data) => {
                Swal.fire({
                  type: this.globals.configurationText.sweetAlertTypeIconSuccess,
                  icon: this.globals.configurationText.sweetAlertTypeIconSuccess,
                  customClass: this.globals.configurationText.sweetAlertClassSuccess,
                  toast: true,
                  title: 'Your contacts have been imported successfully',
                  animation: true,
                  position: this.globals.configurationText.sweetAlertPosition,
                  showConfirmButton: false,
                  timer: this.globals.configurationText.sweetAlertTimer,
                  timerProgressBar: true,
                  showCancelButton: true,
                  cancelButtonText: 'x',
                });
                this.getAddressBook();
              },
              (error) => {
                this.globals.isLoading = false;
                console.log(error);
              }
            );
          });
      });
  }
  validationCheck(value) {
    this.submitted = false;
    if (value == 'new') {
      this.newGroup = true;
      this.oldGroup = false;
      this.groupEntity.grouptype = 1;
    } else if (value == 'old') {
      this.oldGroup = true;
      this.newGroup = false;
      this.groupEntity.grouptype = 0;
    }
  }
  addGroupPopupClose() {
    this.GroupPopUp = false;
  }

  insertupdateGroup(details) {
    // console.warn(this.addressBookEntity);
    this.submitted2 = true;
    if (details.valid) {
      this.submitted2 = false;
      this.globals.isLoading = true;
      let entity = {
        user_id: this.globals.authData.id,
        group_name_available: this.groupEntity.grouptype,
        group_name: this.groupEntity.group_name,
        details: this.groupEntity.details,
        group_name_id: this.groupEntity.group_id,
      };
      console.log(entity);

      this.AddressbookService.addContactGroup(entity).then(
        (data) => {
          this.globals.isLoading = false;
          this.submitted = false;
          this.getAddressBook();
          Swal.fire({
            type: this.globals.configurationText.sweetAlertTypeIconSuccess,
            icon: this.globals.configurationText.sweetAlertTypeIconSuccess,
            customClass: this.globals.configurationText.sweetAlertClassSuccess,
            toast: true,
            title: this.globals.commonTranslationText.addressbook.alerts
              .addGroup.text,
            animation: true,
            position: this.globals.configurationText.sweetAlertPosition,
            showConfirmButton: false,
            timer: this.globals.configurationText.sweetAlertTimer,
            timerProgressBar: true,
            showCancelButton: true,
            cancelButtonText: 'x',
          });
          this.groupEntity.details = [];
          this.GroupPopUp = false;
          this.oldGroup = false;
        },
        (error) => {
          console.log(error.error);
          this.submitted = false;
          this.globals.isLoading = false;
          if (error.error.code == 422) {
            Swal.fire({
              type: this.globals.configurationText.sweetAlertTypeIconError,
              icon: this.globals.configurationText.sweetAlertTypeIconError,
              customClass: this.globals.configurationText.sweetAlertClassError,
              toast: true,
              title: this.globals.commonTranslationText.addressbook.alerts
                .exitsGroup.text,
              animation: true,
              position: this.globals.configurationText.sweetAlertPosition,
              showConfirmButton: false,
              timer: this.globals.configurationText.sweetAlertTimer,
              timerProgressBar: true,
              showCancelButton: true,
              cancelButtonText: 'x',
            });
          } else {
            // this.globals.pageNotfound(error.error.code);
          }
        }
      );
    }
  }
  phoneNumberFormat(value) {
    if(value != '' && value != undefined)
    {  
      var phoneformat = /^[0-9()_ ]+$/;
      if(value.match(phoneformat))
      {
        this.phoneValid = false;
      }
      else
      {
        this.phoneValid = true;
      }
    }
    else
    {
      this.phoneValid = false;
    }
  }
  insertupdateAddressBook(details) {
    //debugger;
    console.warn(this.addressBookEntity);
    this.submitted = true;
    
    if (details.valid && !this.phoneValid) {
      if((this.addressBookEntity.email == '' || this.addressBookEntity.email == undefined) && (this.addressBookEntity.phone == '' || this.addressBookEntity.phone == undefined))
      {
        Swal.fire({
          type: this.globals.configurationText.sweetAlertTypeIconError,
          icon: this.globals.configurationText.sweetAlertTypeIconError,
          customClass: this.globals.configurationText.sweetAlertClassError,
          toast: true,
          title: 'Please enter email address or phone',
          animation: true,
          position: this.globals.configurationText.sweetAlertPosition,
          showConfirmButton: false,
          timer: this.globals.configurationText.sweetAlertTimer,
          timerProgressBar: true,
          showCancelButton: true,
          cancelButtonText: 'x',
        });
      }
      else{
        this.globals.isLoading = true;

        this.globals.isLoading = true;
        let id = this.addressBookEntity.id;
        if (id == undefined) {
          let entity = {
            user_id: this.globals.authData.id,
            first_name: this.addressBookEntity.first_name,
            last_name: this.addressBookEntity.last_name,
            email: this.addressBookEntity.email,
            phone: this.addressBookEntity.phone.replace(/\D/g, ''),
            is_active: this.addressBookEntity.is_active,
          };
          this.AddressbookService.addAddressBook(entity).then(
            (data) => {
              this.globals.isLoading = false;
              this.submitted = false;
              this.getAddressBook();
              this.addressbookPopupClose();

              Swal.fire({
                type: this.globals.configurationText.sweetAlertTypeIconSuccess,
                icon: this.globals.configurationText.sweetAlertTypeIconSuccess,
                customClass: this.globals.configurationText.sweetAlertClassSuccess,
                toast: true,
                title: this.globals.commonTranslationText.addressbook.alerts
                  .addressBookAdd.text,
                animation: true,
                position: this.globals.configurationText.sweetAlertPosition,
                showConfirmButton: false,
                timer: this.globals.configurationText.sweetAlertTimer,
                timerProgressBar: true,
                showCancelButton: true,
                cancelButtonText: 'x',
              });
            },
            (error) => {
              this.submitted = false;
              this.globals.isLoading = false;
              if (error.error.code == 422) {
                this.errorEntity.first_name =
                  error.error.message.first_name[0] != ''
                    ? error.error.message.first_name[0]
                    : '';
                this.errorEntity.email =
                  error.error.message.email[0] != ''
                    ? error.error.message.email[0]
                    : '';
                this.errorEntity.phone =
                  error.error.message.phone[0] != ''
                    ? error.error.message.phone[0]
                    : '';
              } else if (error.error.code == 404) {
                Swal.fire({
                  type: this.globals.configurationText.sweetAlertTypeIconError,
                  icon: this.globals.configurationText.sweetAlertTypeIconError,
                  customClass: this.globals.configurationText.sweetAlertClassError,
                  toast: true,
                  title: error.error.message,
                  animation: true,
                  position: this.globals.configurationText.sweetAlertPosition,
                  showConfirmButton: false,
                  timer: this.globals.configurationText.sweetAlertTimer,
                  timerProgressBar: true,
                  showCancelButton: true,
                  cancelButtonText: 'x',
                });
              } else {
                // this.globals.pageNotfound(error.error.code);
              }
            }
          );
        } else {
          let entity = {
            id: this.addressBookEntity.id,
            first_name: this.addressBookEntity.first_name,
            last_name: this.addressBookEntity.last_name,
            email: this.addressBookEntity.email,
            phone: this.addressBookEntity.phone.replace(/\D/g, ''),
            is_active: this.addressBookEntity.is_active,
          };
          this.AddressbookService.editAddressBook(entity).then(
            (data) => {
              this.globals.isLoading = false;
              this.submitted = false;
              this.getAddressBook();
              this.addressbookPopupClose();

              Swal.fire({
                type: this.globals.configurationText.sweetAlertTypeIconSuccess,
                icon: this.globals.configurationText.sweetAlertTypeIconSuccess,
                customClass: this.globals.configurationText.sweetAlertClassSuccess,
                toast: true,
                title: this.globals.commonTranslationText.addressbook.alerts
                  .addressBookEdit.text,
                animation: true,
                position: this.globals.configurationText.sweetAlertPosition,
                showConfirmButton: false,
                timer: this.globals.configurationText.sweetAlertTimer,
                timerProgressBar: true,
                showCancelButton: true,
                cancelButtonText: 'x',
              });
            },
            (error) => {
              this.submitted = false;
              this.globals.isLoading = false;
              if (error.error.code == 422) {
                this.errorEntity.first_name =
                  error.error.message.first_name[0] != ''
                    ? error.error.message.first_name[0]
                    : '';
                this.errorEntity.email =
                  error.error.message.email[0] != ''
                    ? error.error.message.email[0]
                    : '';
                this.errorEntity.phone =
                  error.error.message.phone[0] != ''
                    ? error.error.message.phone[0]
                    : '';
              } else if (error.error.code == 404) {
                Swal.fire({
                  type: this.globals.configurationText.sweetAlertTypeIconError,
                  icon: this.globals.configurationText.sweetAlertTypeIconError,
                  customClass: this.globals.configurationText.sweetAlertClassError,
                  toast: true,
                  title: error.error.message,
                  animation: true,
                  position: this.globals.configurationText.sweetAlertPosition,
                  showConfirmButton: false,
                  timer: this.globals.configurationText.sweetAlertTimer,
                  timerProgressBar: true,
                  showCancelButton: true,
                  cancelButtonText: 'x',
                });
              } else {
                // this.globals.pageNotfound(error.error.code);
              }
            }
          );
        }
      }
    }
  }

  // pageChange Event
  public pageChange(event: PageChangeEvent): void {
    this.globals.isLoading = true;
    this.skip = event.skip;
    this.paginationEntity.offset = event.skip / this.pageSize;
    this.getAddressBook();
    this.globals.pageScroll();
  }

  // sortOrder change Event
  public sortChange(sort: any): void {
    if (sort.dir != 'undefined') {
      this.sort = sort;
      this.paginationEntity.sortOrder = [];
      this.paginationEntity.sortOrder = sort;
      this.getAddressBook();
    }
  }

  // Filter event
  public onFilter(inputValue: string): void {
    this.globals.isLoading = true;
    if (inputValue != '') {
      this.paginationEntity.offset = 0;
      this.paginationEntity.searchData.searchQuery = inputValue;
      this.getAddressBook();
    } else {
      this.paginationEntity.searchData.searchQuery = '';
      this.pageChange(this.state);
      //debugger;
    }
  }

  edit(details) {
    this.ContactPopUp = true;
    console.warn(details);
    this.submitted = false;
    this.addressBookEntity = {
      id: details.id,
      first_name: details.contact_first_name,
      last_name: details.contact_last_name,
      email: details.contact_email,
      phone: details.contact_phone,
      is_active: details.is_active,
    };
  }

  deleteContact(addressbook) {
    Swal.fire({
      title: this.globals.commonTranslationText.addressbook.alerts.deleteConfirm
        .title,
      text: this.globals.commonTranslationText.addressbook.alerts.deleteConfirm
        .text,
      icon: 'warning',
      type: this.globals.commonTranslationText.addressbook.alerts.deleteConfirm
        .type,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonClass: 'theme_btn',
      confirmButtonText: 'Yes',
      cancelButtonClass: 'cancel_btn',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.globals.isLoading = true;
        //debugger;
        this.AddressbookService.deleteItem(addressbook.id).then(
          (data) => {
            this.globals.isLoading = false;
            this.getAddressBook();
            Swal.fire({
              type: this.globals.configurationText.sweetAlertTypeIconSuccess,
              icon: this.globals.configurationText.sweetAlertTypeIconSuccess,
              customClass: this.globals.configurationText.sweetAlertClassSuccess,
              toast: true,
              title: this.globals.commonTranslationText.addressbook.alerts
                .deleteContact.text,
              animation: true,
              position: this.globals.configurationText.sweetAlertPosition,
              showConfirmButton: false,
              timer: this.globals.configurationText.sweetAlertTimer,
              timerProgressBar: true,
              showCancelButton: true,
              cancelButtonText: 'x',
            });
          },
          (error) => {
            this.globals.isLoading = false;
            this.globals.pageNotfound(error.error.code);
          }
        );
      }
    });
  }

  isActiveChange(changeEntity, i) {
    //debugger;
    if (i) {
      changeEntity.is_active = 1;
    } else {
      changeEntity.is_active = 0;
    }
    this.globals.isLoading = true;
    changeEntity.id = changeEntity.id;
    if (changeEntity.is_active == 1) {
      changeEntity.ActivityText = 'Community Activated';
    } else {
      changeEntity.ActivityText = 'Community Deactivated';
    }
    this.AddressbookService.isActiveChange(changeEntity).then(
      (data) => {
        this.globals.isLoading = false;
        if (changeEntity.is_active == 0) {
          Swal.fire({
            type: this.globals.configurationText.sweetAlertTypeIconSuccess,
            icon: this.globals.configurationText.sweetAlertTypeIconSuccess,
            customClass: this.globals.configurationText.sweetAlertClassSuccess,
            toast: true,
            title: this.globals.commonTranslationText.addressbook.alerts
              .deactiveSuccess.text,
            animation: true,
            position: this.globals.configurationText.sweetAlertPosition,
            showConfirmButton: false,
            timer: this.globals.configurationText.sweetAlertTimer,
            timerProgressBar: true,
            showCancelButton: true,
            cancelButtonText: 'x',
          });
        } else {
          Swal.fire({
            type: this.globals.configurationText.sweetAlertTypeIconSuccess,
            icon: this.globals.configurationText.sweetAlertTypeIconSuccess,
            customClass: this.globals.configurationText.sweetAlertClassSuccess,
            toast: true,
            title: this.globals.commonTranslationText.addressbook.alerts
              .activeSuccess.text,
            animation: true,
            position: this.globals.configurationText.sweetAlertPosition,
            showConfirmButton: false,
            timer: this.globals.configurationText.sweetAlertTimer,
            timerProgressBar: true,
            showCancelButton: true,
            cancelButtonText: 'x',
          });
        }
      },
      (error) => {
        this.globals.isLoading = false;
        //  this.globals.pageNotfound(error.error.code);
      }
    );
  }
}
