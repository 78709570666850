<div class="home_wrap community_question question_detail pt_50 fixed-head">
    <div class="container-fluid">
        <div class="row pb-5">
            <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                <div class="community_question_wrap top_question_wrap que_detail_left">
                    <div class="community_info_wrap" *ngIf="!communityQuestionLoading1">
                        <div class="community_info_top">
                            <img src="{{globals.profileImgeapiUrl}}/community-image/{{communityQuestionEntity.community_banner_image}}"
                                alt="" class="img-fluid" />
                        </div>
                        <div class="community_info_bottom">
                            <div class="community_photo">
                                <img src="{{globals.profileImgeapiUrl}}/community-image/{{communityQuestionEntity.community_thumbnail_image}}"
                                    alt="" class="img-fluid">
                            </div>
                            <div class="community_name float-start">
                                <strong>{{communityQuestionEntity.name}}</strong>
                                <img src="assets/images/favicon.png" alt="" class="img-fluid">
                                <span>/ {{communityQuestionEntity.category.name}}</span>
                            </div>
                            <div class="clearfix d-block d-sm-none"></div>
                            <div class="join_community_btn float-sm-end pt-4 mt-3">
                                <button kendoButton type="submit" *ngIf="communityQuestionEntity.users.length == 0"
                                    class="theme_btn d-flex justify-content-center mx-0"
                                    (click)="joinCommunity()" [disabled]="disableJoin">Join</button>
                                <button kendoButton type="button" *ngIf="communityQuestionEntity.users.length > 0"
                                    class="theme_btn d-flex justify-content-center mx-0">Joined</button>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                    <!-- <div class="block_loader" *ngIf="communityQuestionLoading1">
                        <div class="img_loader"><img src="../assets/images/loader.gif" alt="" /></div>
                      </div> -->
                    <div class="community_question_tabs">
                        <ul class="nav nav-pills" id="pills-tab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" href="#pills-rated" id="pills-rated-tab" data-bs-toggle="pill"
                                    role="tab" aria-controls="pills-rated" (click)="filterChange('top')"
                                    aria-selected="true"><i class="fa-solid fa-chart-area"></i>
                                    Popular</a><!-- -->
                            </li>
                            <li class="nav-item">
                                <a class="nav-link " id="pills-popular-tab" data-bs-toggle="pill" href="#pills-popular"
                                    (click)="filterChange('new')" role="tab" aria-controls="pills-popular"
                                    aria-selected="false"><i class="fa-solid fa-bullhorn"></i> New</a>
                            </li>

                        </ul>
                    </div>
                    <div class="search-results" infinite-scroll [infiniteScrollDistance]="scrollDistance"
                        [infiniteScrollUpDistance]="scrollUpDistance" [infiniteScrollThrottle]="throttle"
                        (scrolled)="onScrollDown($event)">
                        <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-rated" role="tabpanel"
                                aria-labelledby="pills-rated-tab" *ngIf="!communityQuestionLoading2">
                                <div class="questions_boxs"
                                    *ngFor="let question of communityQuestionsList;let i =index;">
                                    <a class="padding_boxs not_count_padding" (click)="questionDetail(question.id)">
                                    <div class="quebox_top">
                                        <!-- <p>{{question.question_text}}</p> -->
                                        <div class="editor_data" [innerHtml]="question.question_text"></div>
                                        <div class="quebox_media">
                                            <div class="embed-responsive embed-responsive-16by9 mb-5"
                                                *ngIf="question.file_extention != '' && question.videoExtension">
                                                <video id="video_tab" width="400" controls>
                                                    <source id="video"
                                                        src="{{globals.profileImgeapiUrl}}/question/{{question.id}}/{{question.image_video_url}}">
                                                </video>
                                            </div>
                                            <img *ngIf="question.file_extention != '' && question.imageExtension"
                                                src="{{globals.profileImgeapiUrl}}/question/{{question.id}}/{{question.image_video_url}}"
                                                alt="" class="img-fluid" />
                                            <!-- <div class="embed-responsive embed-responsive-16by9 mb-5"
                                                *ngIf="question.file_extention != '' && (question.file_extention == 'mp4' || question.file_extention == 'MP4' || question.file_extention == 'MKV' || question.file_extention == 'mkv' || question.file_extention == 'WEBM' || question.file_extention == 'webm')">
                                                <video id="video_tab" width="400" controls>
                                                    <source id="video"
                                                        src="{{globals.profileImgeapiUrl}}/question/{{question.id}}/{{question.image_video_url}}">
                                                </video>
                                            </div>
                                            <img *ngIf="question.file_extention != '' && (question.file_extention == 'jpg' || question.file_extention == 'png' || question.file_extention == 'jpeg' || question.file_extention == 'JPG' || question.file_extention == 'PNG' || question.file_extention == 'JPEG')"
                                                src="{{globals.profileImgeapiUrl}}/question/{{question.id}}/{{question.image_video_url}}"
                                                alt="" class="img-fluid" /> -->
                                        </div>
                                    </div>
                                    <div class="quebox_bottom d-flex align-items-center">
                                        <div class="qt_photo">
                                            <img *ngIf="question.profile_image_url ==null || question.profile_image_url == ''"
                                                id="user_img" src="assets/images/dummy.jpg" alt="" class="img-fluid" />
                                            <img *ngIf="question.profile_image_url !=null && question.profile_image_url != ''"
                                                id="user_img"
                                                src="{{globals.profileImgeapiUrl}}/{{question.profile_image_url}}"
                                                alt="" class="img-fluid" />
                                        </div>
                                        <div class="qt_desc">
                                            <span class="user_name"><a
                                                    (click)="publicProfile(question.user_id,question.send_anonymously)">{{question.display_name}}</a></span>
                                            <!-- <span class="que_time">48 minutes ago</span> -->
                                            <!-- <span class="que_time">{{question.ExpiredOn}}</span> -->
                                            <ul class="queicon_lists">
                                                <li class="like_question"  *ngIf="(globals.authData == undefined) || (question.is_like == '' || question.is_like == null) ">
                                                    <a onclick='event.stopPropagation();' (click)="likeDislikeQuestionAnswer(question,1)" ><i class="fa-regular fa-thumbs-up"></i></a> 
                                                    <span >{{question.likes}}</span>
                                                </li>
                                                <li class="like_question" *ngIf="(question.is_like != '' && question.is_like != null) "> 
                                                    <a onclick='event.stopPropagation();' (click)="likeDislikeQuestionAnswer(question,0)" ><i class="fa-solid fa-thumbs-up"></i></a> 
                                                    <span >{{question.likes}}</span>
                                                </li>
                                                <li><i class="fa-regular fa-comment-dots"></i>
                                                    {{question.comments}}</li>
                                                <li><i class="fa-regular fa-eye"></i> {{question.views}}</li>
                                                <li
                                                    *ngIf="(globals.authData == undefined) || ((question.is_abused == '' || question.is_abused == null) && question.created_by != globals.authData.id  && globals.reportQuestionAnswerEntity.question_id != question.id)">
                                                    <a class="report"
                                                        onclick='event.stopPropagation();' (click)="globals.reportPopUpOpen(question.id,'',question.user_name)"><i
                                                            class="fa-solid fa-flag"></i> Report</a>
                                                </li>
                                                <li
                                                    *ngIf="(question.is_abused != '' &&  question.is_abused != null ) || (globals.reportQuestionAnswerEntity.reportedTime != '' && globals.reportQuestionAnswerEntity.reportedTime != undefined && globals.reportQuestionAnswerEntity.question_id == question.id)">
                                                    <a onclick='event.stopPropagation();' *ngIf="globals.reportQuestionAnswerEntity.reportedTime != '' && globals.reportQuestionAnswerEntity.reportedTime != undefined && globals.reportQuestionAnswerEntity.question_id == question.id"
                                                        class="report_question_answer"
                                                        title="{{globals.commonTranslationText.questionDetail.reportQuestionTooltip}} {{globals.reportQuestionAnswerEntity.reportedTime | date:'MMM d, y h:mm a'}}"><i
                                                            class="fa-solid fa-flag"></i> Reported </a>
                                                    <a onclick='event.stopPropagation();' *ngIf="globals.reportQuestionAnswerEntity.reportedTime == '' || globals.reportQuestionAnswerEntity.reportedTime == undefined || globals.reportQuestionAnswerEntity.question_id != question.id"
                                                        class="report_question_answer"
                                                        title="{{globals.commonTranslationText.questionDetail.reportQuestionTooltip}} {{question.is_abused | date:'MMM d, y h:mm a'}}"><i
                                                            class="fa-solid fa-flag"></i> Reported </a>
                                                </li>
                                                <!-- <li><a  class="report"><i class="fa-solid fa-flag"></i> Report</a></li>
                                                <li *ngIf="question.invitee_can_invite_others != null"><a  class="report"><i class="fa-solid fa-share"></i> Share</a></li> -->
                                                <!-- <li><a href=""><i class="fa-solid fa-bullhorn"></i> Health & Nutrition</a></li> -->
                                            </ul>
                                            <ul class="posted_expired_lists">
                                                <li class="posted_date_time"><span>Posted:</span>
                                                    {{question.posted_time}} </li>
                                                <!-- <li class="expires_date_time">
                                                    <span
                                                        *ngIf="(todaysdate | date:'MMM d, y h:mm a') > (question.ExpiredOn | date:'MMM d, y h:mm a')">Expired:</span>
                                                    <span
                                                        *ngIf="(todaysdate | date:'MMM d, y h:mm a') < (question.ExpiredOn | date:'MMM d, y h:mm a')">Expiring:</span>
                                                    {{question.ExpiredOn | date:'MMM d, y h:mm a'}}
                                                </li> -->
                                                <li class="expires_date_time"><span>{{question.expire_title}}</span>
                                                    {{question.Expire_time}}</li>
                                                <!-- <li class="posted_date_time"><span>Posted:</span> {{todaysdate | date:'full'}}  </li> -->
                                            </ul>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    </a>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="pills-popular" role="tabpanel"
                                aria-labelledby="pills-popular-tab" *ngIf="!communityQuestionLoading2">
                                <div class="questions_boxs"
                                    *ngFor="let question of communityQuestionsList;let i =index;">
                                    <a class="padding_boxs not_count_padding" (click)="questionDetail(question.id)">
                                    <div class="quebox_top">
                                        <!-- <p>{{question.question_text}}</p> -->
                                        <div class="editor_data" [innerHtml]="question.question_text"></div>
                                        <div class="quebox_media">
                                            <div class="embed-responsive embed-responsive-16by9 mb-5"
                                                *ngIf="question.file_extention != '' && question.videoExtension">
                                                <video id="video_tab" width="400" controls>
                                                    <source id="video"
                                                        src="{{globals.profileImgeapiUrl}}/question/{{question.id}}/{{question.image_video_url}}">
                                                </video>
                                            </div>
                                            <img *ngIf="question.file_extention != '' && question.imageExtension"
                                                src="{{globals.profileImgeapiUrl}}/question/{{question.id}}/{{question.image_video_url}}"
                                                alt="" class="img-fluid" />
                                            <!-- <div class="embed-responsive embed-responsive-16by9 mb-5"
                                                *ngIf="question.file_extention != '' && (question.file_extention == 'mp4' || question.file_extention == 'MP4' || question.file_extention == 'MKV' || question.file_extention == 'mkv' || question.file_extention == 'WEBM' || question.file_extention == 'webm')">
                                                <video id="video_tab" width="400" controls>
                                                    <source id="video"
                                                        src="{{globals.profileImgeapiUrl}}/question/{{question.id}}/{{question.image_video_url}}">
                                                </video>
                                            </div>
                                            <img *ngIf="question.file_extention != '' && (question.file_extention == 'jpg' || question.file_extention == 'png' || question.file_extention == 'jpeg' || question.file_extention == 'JPG' || question.file_extention == 'PNG' || question.file_extention == 'JPEG')"
                                                src="{{globals.profileImgeapiUrl}}/question/{{question.id}}/{{question.image_video_url}}"
                                                alt="" class="img-fluid" /> -->
                                        </div>
                                    </div>
                                    <div class="quebox_bottom d-flex align-items-center">
                                        <div class="qt_photo">
                                            <img *ngIf="question.profile_image_url ==null || question.profile_image_url == ''"
                                                id="user_img" src="assets/images/dummy.jpg" alt="" class="img-fluid" />
                                            <img *ngIf="question.profile_image_url !=null && question.profile_image_url != ''"
                                                id="user_img"
                                                src="{{globals.profileImgeapiUrl}}/{{question.profile_image_url}}"
                                                alt="" class="img-fluid" />
                                        </div>
                                        <div class="qt_desc">
                                            <span class="user_name"><a
                                                    (click)="publicProfile(question.user_id,question.send_anonymously)">{{question.display_name}}</a></span>
                                            <!-- <span class="que_time">48 minutes ago</span> -->
                                            <!-- <span class="que_time">{{question.ExpiredOn}}</span> -->
                                            <ul class="queicon_lists">
                                                <li class="like_question"  *ngIf="(globals.authData == undefined) || (question.is_like == '' || question.is_like == null) ">
                                                    <a onclick='event.stopPropagation();' (click)="likeDislikeQuestionAnswer(question,1)" ><i class="fa-regular fa-thumbs-up"></i></a> 
                                                    <span >{{question.likes}}</span>
                                                </li>
                                                <li onclick='event.stopPropagation();' class="like_question" *ngIf="(question.is_like != '' && question.is_like != null) "> 
                                                    <a (click)="likeDislikeQuestionAnswer(question,0)" ><i class="fa-solid fa-thumbs-up"></i></a> 
                                                    <span >{{question.likes}}</span>
                                                </li>
                                                <li><i class="fa-regular fa-comment-dots"></i>
                                                    {{question.comments}}</li>
                                                <li><i class="fa-regular fa-eye"></i> {{question.views}}</li>
                                                <li
                                                    *ngIf="(globals.authData == undefined) || ((question.is_abused == '' || question.is_abused == null) && question.created_by != globals.authData.id  && globals.reportQuestionAnswerEntity.question_id != question.id)">
                                                    <a class="report"
                                                        onclick='event.stopPropagation();' (click)="globals.reportPopUpOpen(question.id,'',question.user_name)"><i
                                                            class="fa-solid fa-flag"></i> Report</a>
                                                </li>
                                                <li
                                                    *ngIf="(question.is_abused != '' &&  question.is_abused != null ) || (globals.reportQuestionAnswerEntity.reportedTime != '' && globals.reportQuestionAnswerEntity.reportedTime != undefined && globals.reportQuestionAnswerEntity.question_id == question.id)">
                                                    <a onclick='event.stopPropagation();' *ngIf="globals.reportQuestionAnswerEntity.reportedTime != '' && globals.reportQuestionAnswerEntity.reportedTime != undefined && globals.reportQuestionAnswerEntity.question_id == question.id"
                                                        class="report_question_answer"
                                                        title="{{globals.commonTranslationText.questionDetail.reportQuestionTooltip}} {{globals.reportQuestionAnswerEntity.reportedTime | date:'MMM d, y h:mm a'}}"><i
                                                            class="fa-solid fa-flag"></i> Reported </a>
                                                    <a onclick='event.stopPropagation();' *ngIf="globals.reportQuestionAnswerEntity.reportedTime == '' || globals.reportQuestionAnswerEntity.reportedTime == undefined || globals.reportQuestionAnswerEntity.question_id != question.id"
                                                        class="report_question_answer"
                                                        title="{{globals.commonTranslationText.questionDetail.reportQuestionTooltip}} {{question.is_abused | date:'MMM d, y h:mm a'}}"><i
                                                            class="fa-solid fa-flag"></i> Reported </a>
                                                </li>
                                                <!-- <li><a  class="report"><i class="fa-solid fa-flag"></i> Report</a></li>
                                                <li *ngIf="question.invitee_can_invite_others != null"><a  class="report"><i class="fa-solid fa-share"></i> Share</a></li> -->
                                                <!-- <li><a href=""><i class="fa-solid fa-bullhorn"></i> Health & Nutrition</a></li> -->
                                            </ul>
                                            <ul class="posted_expired_lists">
                                                <li class="posted_date_time"><span>Posted:</span>
                                                    {{question.posted_time}} </li>
                                                <!-- <li class="expires_date_time">
                                                    <span
                                                        *ngIf="(todaysdate | date:'MMM d, y h:mm a') > (question.ExpiredOn | date:'MMM d, y h:mm a')">Expired:</span>
                                                    <span
                                                        *ngIf="(todaysdate | date:'MMM d, y h:mm a') < (question.ExpiredOn | date:'MMM d, y h:mm a')">Expiring:</span>
                                                    {{question.ExpiredOn | date:'MMM d, y h:mm a'}}
                                                </li> -->
                                                <li class="expires_date_time"><span>{{question.expire_title}}</span>
                                                    {{question.Expire_time}}</li>
                                                <!-- <li class="posted_date_time"><span>Posted:</span> {{todaysdate | date:'full'}}  </li> -->
                                            </ul>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                    </a>
                                </div>
                            </div>
                            <ng-container *ngIf="communityQuestionLoading2 || communityQuestionLoading4">
                                <div class="questions_boxs" *ngFor="let object of questionLoaderList">
                                    <ngx-shimmer-loading></ngx-shimmer-loading>
                                    <ngx-shimmer-loading></ngx-shimmer-loading>
                                </div>
                            </ng-container>


                            <div *ngIf="communityQuestionsList.length==0 && !communityQuestionLoading2">
                                <div class="no_que_found mt-5">
                                    <img src="assets/images/no_found.png" alt="" class="img-fluid" />
                                    NO THREADS POSTED YET
                                </div>
                            </div>
                            <!-- <div class="tab-pane fade" id="pills-popular" role="tabpanel"
                                aria-labelledby="pills-popular-tab">
                                <div class="questions_boxs">
                                    <div class="quebox_top">
                                        <p>Living a healthier life might seem like a tall order — the nutrition, the
                                            exercise, the inner happiness! But having some friendly advice at your disposal,
                                            whenever and wherever you need it, makes it easier and more fun. With just a
                                            click, these awesome blogs filled with tips, tricks, and personal stories will
                                            inspire you on your journey to wellness.
                                        </p>
                                    </div>
                                    <div class="quebox_bottom d-flex align-items-center">
                                        <div class="qt_photo">
                                            <img src="assets/images/decideit_stars/ds1.jpg" alt="" class="img-fluid" />
                                        </div>
                                        <div class="qt_desc">
                                            <span class="user_name">Collor Nenson</span>
                                            <span class="que_time">48 minutes ago</span>
                                            <ul class="queicon_lists">
                                                <li><a href=""><i class="fa-regular fa-comment-dots"></i> 83</a></li>
                                                <li><a href=""><i class="fa-regular fa-eye"></i> 236</a></li>
                                                <!-- <li><a href=""><i class="fa-solid fa-bullhorn"></i> Health & Nutrition</a></li> --
                                            </ul>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                                <div class="questions_boxs">
                                    <div class="quebox_top">
                                        <p>Sport is one of those things that you’ll have to go a long way to find somebody
                                            that has no interest in. Whether it’s football, cricket, rugby, swimming or
                                            athletics, most people will have some sort of interest in a sport, be it
                                            watching or taking part.</p>
                                        <div class="quebox_media">
                                            <div class="embed-responsive embed-responsive-16by9 mb-5">
                                                <iframe class="embed-responsive-item"
                                                    src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0"
                                                    allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="quebox_bottom d-flex align-items-center">
                                        <div class="qt_photo">
                                            <img src="assets/images/decideit_stars/ds2.jpg" alt="" class="img-fluid" />
                                        </div>
                                        <div class="qt_desc">
                                            <span class="user_name">Mathew Haiden</span>
                                            <span class="que_time">1 hour ago</span>
                                            <ul class="queicon_lists">
                                                <li><a href=""><i class="fa-regular fa-comment-dots"></i> 62</a></li>
                                                <li><a href=""><i class="fa-regular fa-eye"></i> 178</a></li>
                                                <!-- <li><a href=""><i class="fa-solid fa-bullhorn"></i> Sports</a></li> --
                                            </ul>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                                <div class="questions_boxs">
                                    <div class="quebox_top">
                                        <p>Motor Trend Magazine, the world's automotive authority. Covers more cars, trucks
                                            & SUVs than any other magazine. Provides extensive automobile buying guide for
                                            consumers. The official Motor Trend magazine web site featuring the latest new
                                            cars, car reviews and news, concept cars and auto show coverage, awards, and
                                            much more.</p>
                                        <div class="quebox_media">
                                            <img src="assets/images/car.jpg" alt="" class="img-fluid" />
                                        </div>
                                    </div>
                                    <div class="quebox_bottom d-flex align-items-center">
                                        <div class="qt_photo">
                                            <img src="assets/images/decideit_stars/ds4.jpg" alt="" class="img-fluid" />
                                        </div>
                                        <div class="qt_desc">
                                            <span class="user_name">James Anderson</span>
                                            <span class="que_time">3 hour ago</span>
                                            <ul class="queicon_lists">
                                                <li><a href=""><i class="fa-regular fa-comment-dots"></i> 25</a></li>
                                                <li><a href=""><i class="fa-regular fa-eye"></i> 36</a></li>
                                                <!-- <li><a href=""><i class="fa-solid fa-bullhorn"></i> Cars</a></li> --
                                            </ul>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                <div class="community_question_right question_detail_right">
                    <div class="advertisement_box">
                        <p>IJCAHPO Eyedea Exchange is your key to fast, easy and clutter-free group decisions.</p>
                        <!-- <p>Send to up to 1000 friends instantly, Now!</p> -->
                        <!--<p>Threads Remain Live for 99 Hrs</p>-->
                    </div>
                    <div class="about_community mt-5" *ngIf="!communityQuestionLoading1">
                        <h3 class="about_comm_head">
                            About Topic
                        </h3>

                        <div class="about_community_wrap">
                            <div class="community_photo">
                                <img src="{{globals.profileImgeapiUrl}}/community-image/{{communityQuestionEntity.community_thumbnail_image}}"
                                    alt="" class="img-fluid">
                            </div>
                            <div class="community_name">
                                <strong>{{communityQuestionEntity.name}}</strong>
                                <img src="assets/images/favicon.png" alt="" class="img-fluid">
                                <span>/ {{communityQuestionEntity.category.name}}</span>
                            </div>
                            <div class="clearfix"></div>
                            <div [innerHtml]="communityQuestionEntity.description"></div>
                        </div>

                        <!-- <div class="created_on">
                            <span> <i class="fa-solid fa-calendar-days"></i> Created On Oct 21, 2020</span>
                        </div> -->
                    </div>
                    <!-- <div class="block_loader  " *ngIf="communityQuestionLoading1">
                        <div class="img_loader"><img src="../assets/images/loader.gif" alt="" /></div>
                      </div> -->
                    <div class="about_community related_community mt-5" *ngIf="!communityQuestionLoading3">
                        <h3 class="about_comm_head">
                            Related Topics
                        </h3>
                        <div *ngIf="relatedCommunityList.length==0 && !communityQuestionLoading3"
                            class="no_records_found d-flex justify-content-center align-items-center">
                            <span>There are no related topics found</span>
                        </div>
                        <ul class="related_comm_lists" *ngIf="relatedCommunityList.length>0 && !communityQuestionLoading3">
                            <ng-container *ngFor="let community of relatedCommunityList;let i=index;let last=last;">
                                <li [ngClass]="{no_border:last}">
                                    <img src="{{globals.profileImgeapiUrl}}/community-image/{{community.community_thumbnail_image}}"
                                        alt="" class="img-fluid">
                                    <a (click)="communityQuestion(community.id)"><span>{{community.name}}</span></a>
                                </li>
                            </ng-container>
                            <button kendoButton type="submit" (click)="communityList()"
                                class="theme_btn theme_btn d-flex justify-content-center mx-auto">View All</button>
                        </ul>
                    </div>
                    <!-- <div class="block_loader  " *ngIf="communityQuestionLoading3">
                        <div class="img_loader"><img src="../assets/images/loader.gif" alt="" /></div>
                      </div> -->
                </div>
            </div>
        </div>
    </div>
</div>