import { Component, OnInit, HostListener, ViewEncapsulation } from '@angular/core';
import { Globals } from '.././globals';
import { Router } from '@angular/router';
import { ActivatedRoute, Params } from '@angular/router';
import { CommunityQuestionService } from '../services/community-question.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ProfileService } from '../services/profile.service';
import { CookieService } from 'ngx-cookie-service';

declare var $: any, Swal;
@Component({
  selector: 'app-community-question',
  templateUrl: './community-question.component.html',
  styleUrls: ['./community-question.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class CommunityQuestionComponent implements OnInit {
  //   @HostListener('window:scroll', ['$event']) getScrollHeight(event) {
  //     console.log(window.pageYOffset, event);
  //  }
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public globals: Globals,
    private CommunityQuestionService: CommunityQuestionService,
    private ProfileService: ProfileService,
    private cookieService: CookieService
  ) {}

  communityId;
  communityQuestionEntity;
  communityQuestionLoading1;
  communityQuestionLoading2;
  communityQuestionLoading3;
  communityQuestionLoading4;
  communityQuestionsList;
  relatedCommunityList;
  todaysdate;
  array = [];
  sum = 100;
  throttle = 10;
  scrollDistance = 1.5;
  scrollUpDistance = 2;
  direction = '';
  modalOpen = false;
  disableJoin = false;
  questionList;
  startpostion = 0;
  endpostion = 5;
  questionLoaderList;
  relatedCommunityLoaderList;
  newQuestionList;
  topQuestionList;
  ngOnInit(): void {
    // $(window).on('load resize scroll', function () {
    //   var f = $('footer');
    //   f.css({ position: 'static' });
    //   if (f.offset().top + f.height() < $(window).height()) {
    //     f.css({ position: 'fixed', bottom: '0', width: '100%' });
    //   }
    // });

    var lastScrollTop = 0;
    var st;

    // $(window).on('scroll', function() {
    //     st = $(this).scrollTop();
    //     if(st < lastScrollTop) {
    //         console.log('up 1');
    //     }
    //     else {
    //         console.log('down 1');
    //     }
    //     lastScrollTop = st;
    // });
    // $(window).addEventListener('mousewheel', function () {
    //   // enter code here
    // };
    //   $(window).bind('scroll', function(event) {
    //     if (event.originalEvent.wheelDelta >= 0) {
    //         console.log('Scroll up');
    //     }
    //     else {
    //       console.log('Scroll down');
    //     }
    // });
    // this.globals.isLoading = true;
    this.communityQuestionLoading1 = true;
    this.communityQuestionLoading2 = true;
    this.communityQuestionLoading3 = true;
    this.communityQuestionEntity = {};
    this.communityQuestionsList = [];
    this.relatedCommunityList = [];
    this.questionLoaderList = [];
    this.topQuestionList = [];
    this.newQuestionList = [];
    this.relatedCommunityLoaderList = [];
    this.questionList = [];
    for (var i = 0; i < 5; i++) {
      this.questionLoaderList.push(i);
    }
    for (var i = 0; i < 7; i++) {
      this.relatedCommunityLoaderList.push(i);
    }
    console.log(this.questionLoaderList);
    this.todaysdate = new Date();
    this.communityId = this.route.snapshot.paramMap.get('id');
    this.CommunityQuestionService.getCommunityQuestions(
      window.atob(this.communityId),
      'community_details'
    ).then(
      (data) => {
        this.communityQuestionLoading1 = false;
        console.log(data);
        this.communityQuestionEntity = data;
      },
      (error) => {
        this.communityQuestionLoading1 = false;
        console.log(error);
      }
    );
  }

  ngAfterViewInit() {
    // $(function(){
    //   $(window).scroll(function(){
    //     alert('scrolled')
    //   });
    // });
    this.getCommunityQuestion();
    this.getRelatedCommunity();
    // this.getMonthlyProctoringData();
  }
  addItems(startIndex, endIndex) {
    for (let i = startIndex; i < endIndex; ++i) {
      if (i < this.questionList.length) {
        if ($.inArray(this.questionList[i], this.communityQuestionsList) < 0) {
          this.communityQuestionsList.push(this.questionList[i]);
        }
      }
    }
    this.communityQuestionsList = this.globals.extensionCheck(this.communityQuestionsList);
    // this.questionLength = this.communityQuestionsList.length;
    setTimeout(() => {
      this.questionLoaderList = [];
      this.communityQuestionLoading2 = false;
      this.communityQuestionLoading4 = false;
      console.log(this.questionLoaderList);
    }, 2000);
  }
  onScrollDown(ev) {
    console.log(ev);
    if (this.communityQuestionsList.length > 0) {
      if (ev != '' && !this.communityQuestionLoading2) {
        this.questionLoaderList = [];
        this.communityQuestionLoading4 = true;
        for (var j = 0; j < 1; j++) {
          this.questionLoaderList.push(j);
        }
      }
      setTimeout(() => {
        if (ev != '') {
          this.startpostion = this.endpostion;
          this.endpostion = this.endpostion + 5;
          this.addItems(this.startpostion, this.endpostion);
        }
      }, 3000);
    }
    // console.log("scrolled down!!", ev);

    // // add another 20 items
    // const start = this.sum;
    // // this.sum += 20;
    // this.appendItems(start, this.sum);

    // this.direction = "down";
  }
  filterChange(filterValue)
  {
    this.questionList = [];
    if(filterValue == 'top')
    {
      this.questionList = this.topQuestionList;
    }
    else
    {
      this.questionList = this.newQuestionList;
    }
    console.log(this.questionList);
    this.communityQuestionsList = [];
    this.startpostion = 0;
    this.endpostion = 10;
    this.addItems(this.startpostion, this.endpostion);

  }
  getCommunityQuestion() {
    this.CommunityQuestionService.getCommunityQuestions(
      window.atob(this.communityId),
      'community_questions'
    ).then(
      (data) => {
        console.log(data);
        this.questionList = data['top_questions'];
        this.topQuestionList = data['top_questions'];
        this.newQuestionList = data['new_questions'];
        this.addItems(this.startpostion, this.endpostion);
      },
      (error) => {
        this.communityQuestionLoading1 = false;
        console.log(error);
      }
    );
  }

  getRelatedCommunity() {
    this.CommunityQuestionService.getCommunityQuestions(
      window.atob(this.communityId),
      'related_communities'
    ).then(
      (data) => {
        this.communityQuestionLoading3 = false;
        console.log(data);
        this.relatedCommunityList = data;
      },
      (error) => {
        this.communityQuestionLoading1 = false;
        console.log(error);
      }
    );
  }
  communityList() {
    window.location.href = '/communityList';
  }
  communityQuestion(id) {
    window.location.href = '/communityQuestions/' + window.btoa(id);
  }

  questionDetail(id) {
    this.globals.viewCount = 1;
    this.router.navigate(['/questionDetails/' + id]);
  }
  joinCommunity() {
    if (this.globals.authData == undefined) {
      this.cookieService.set('errorMessage', 'Please login to join community.', this.globals.cookieMessageExpireTime,'/');
      var d1 = new Date(),
          d2 = new Date(d1);
        d2.setMinutes(d1.getMinutes() + this.globals.configurationText.cookieExpireTime);
        this.cookieService.set('communityQuestionURL', this.globals.currentLink, d2,'/');
        window.location.href = '/login';
    }
    else
    {
      this.disableJoin = true;
      this.ProfileService.addCommunity(this.communityQuestionEntity.id)
        .then((data) => {
          // this.getCategoryData();
          console.log(data);
          this.globals.isLoading = false;
          this.communityQuestionEntity.users = [{id:this.globals.authData.id}];
        },
          (error) => {
            this.globals.isLoading = false;
            // this.globals.pageNotfound(error.error.code);
          });
       
 
    }
  }
  publicProfile(id,value) {
    window.location.href = '/publicProfile/' + window.btoa(id) + '/' + window.btoa(value);
  }

  likeDislikeQuestionAnswer(question,is_like)
  {
    this.globals.likeDislikeQuestionAnswer(question.id,is_like,'').then(
      (data) => {
        console.log(data);
        if(is_like == 1)
        {
          question.likes = question.likes + 1;
          question.is_like = 1;
        }
        else
        {
          question.likes = question.likes - 1;
          question.is_like = null;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
