<div class="content_block home_wrap address_book fixed-head">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 main_block">
        <div class="row">
          <!-- Content -->
          <div class="col-md-12 col-sm-12">
            <div class="title_block">
              <h2 class="float-start">
                Address Book</h2>
              <div class="breadcrumb_block float-start">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a routerLink="">
                      <i class="fa-solid fa-house-chimney"></i>
                    </a>
                  </li>
                  <li class="breadcrumb-item">User Address Book
                  </li>

                  <!-- <li class="breadcrumb-item active" aria-current="page">
                    List</li> -->
                </ol>
              </div>


              <button kendoButton class="theme_btn float-end" (click)="addGroupPopup()" title="Group">
                {{globals.commonTranslationText.addressbook.addGroup}}
              </button><!-- All Reviewers History-->
              <button kendoButton class="theme_btn float-end" (click)="addressbookPopup()" title="Contact">
                {{globals.commonTranslationText.addressbook.addNew}}
              </button><!-- All Reviewers History-->
               <!-- <button kendoButton class="theme_btn float-end" *ngIf="!opened" (click)="open()" title="Import Contact">
                Import Contact
              </button> -->
              <!-- <button class="btn_lnkdin float-end" kendoButton
              (click)="submitLogin($event,globals.configurationText.google.provider)"
              [disabled]="btn_disable"><i class="fa-brands fa-google" aria-hidden="true"></i> Google</button>         -->
              <!-- <div class="other_login_option mt-5">
                <ng-container *ngFor="let socialNetwork of socialNetworkEntity;">
                  <div *ngIf="globals.configurationText.google.provider==socialNetwork.name">
                    <button class="btn_fb"
                      [ngClass]="{btn_fb:socialNetwork.name == globals.configurationText.facebook.provider, btn_twtr:socialNetwork.name == globals.configurationText.twitter.provider,
                    btn_lnkdin:socialNetwork.name == globals.configurationText.linkedin.provider,btn_insta:socialNetwork.name == globals.configurationText.instagram.provider}"
                      kendoButton (click)="submitSocialLogin($event,socialNetwork.name,socialNetwork.id)">
                      <i class="{{socialNetwork.class_code}}" aria-hidden="true"></i> {{socialNetwork.name}}</button>
                  </div>
                </ng-container>
              </div> -->
              <div class="clearfix"></div>
            </div>
            <div class="row d-flex justify-content-center">
              <div class="col-md-12 col-sm-12">
                <div class="inner_content_block">
                  <div class="table_block">
                    <kendo-grid name="abc" [data]="addressbooklist" [pageSize]="pageSize" [skip]="skip"
                      [pageable]="true" (pageChange)="pageChange($event)" [sort]="sort" [sortable]="{
                  allowUnsort: allowUnsort,
                  mode: 'single'
                  }" (sortChange)="sortChange($event)">

                      <kendo-grid-messages pagerItems="contacts" pagerItemsPerPage="addressbook per page"
                        noRecords="No Contact available.">
                      </kendo-grid-messages>

                      <ng-template kendoGridToolbarTemplate>
                        <input placeholder="Search in all columns..." kendoTextBox
                          (input)="onFilter($event.target.value)" />
                      </ng-template>
                      <!-- <kendo-grid-checkbox-column showSelectAll="true"></kendo-grid-checkbox-column> -->
                      <kendo-grid-column width="30" title="">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                          <!-- <div class="check_box">
                            <input type="checkbox" name="selectcheckbox[dataItem.id]" id="{{dataItem.id}}"
                              value="{{dataItem.id}}" class="checkboxes">
                            <label for="{{dataItem.id}}" class="checkboxbtn_label"></label>
                          </div> -->
                          <div class="check_box me-0 table_list_checkbox">
                            <input class="checkboxes" type="checkbox" [(ngModel)]="dataItem.certificateCheck"
                              [checked]="dataItem.Checksub" id="certificateCheck{{rowIndex}}">
                            <label for="certificateCheck{{rowIndex}}" class="checkboxbtn_label"></label>
                          </div>
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column width="200" title="{{globals.commonTranslationText.addressbook.name}}">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <span
                            *ngIf="dataItem.contact_first_name!=null || dataItem.contact_first_name==''">{{dataItem.contact_first_name}}
                            {{dataItem.contact_last_name}}</span>
                          <span *ngIf="dataItem.contact_first_name==null || dataItem.contact_first_name==''">-</span>
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column width="250" title="{{globals.commonTranslationText.addressbook.email}}">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <span
                            *ngIf="dataItem.contact_email!=null || dataItem.contact_email!=''">{{dataItem.contact_email}}</span>
                          <span *ngIf="dataItem.contact_email==null || dataItem.contact_email==''">-</span>
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column width="150" title="{{globals.commonTranslationText.addressbook.number}}">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <span
                            *ngIf="dataItem.contact_phone!=null || dataItem.contact_phone!=''">{{dataItem.contact_phone}}</span>
                          <span *ngIf="dataItem.contact_phone==null || dataItem.contact_phone==''">-</span>
                        </ng-template>
                      </kendo-grid-column>
                      <!-- <kendo-grid-column field="name"
                          title="{{globals.adminTranslationText.community.list.collumns.communityName}}">
                          <ng-template kendoGridCellTemplate let-dataItem>
                            <span > {{dataItem.name}} {{dataItem.id}}</span>
                            
                          </ng-template>
                        </kendo-grid-column> -->
                      <!-- <kendo-grid-column field="description"
                          title="{{globals.adminTranslationText.community.list.collumns.description}}">
                          <ng-template kendoGridCellTemplate let-dataItem>
                            <span [innerHTML]="dataItem.description"></span>
                          </ng-template>
                        </kendo-grid-column> -->

                      <kendo-grid-column title="{{globals.adminTranslationText.community.list.collumns.isActive}}"
                        [headerClass]="'text-center'" class="text-center" width="100">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                          <div class="activeyes_box">
                            <kendo-switch (valueChange)="isActiveChange(dataItem,$event)"
                              [checked]="dataItem.is_active==1" [onLabel]="'Y'" [offLabel]="'N'" [disabled]="false">
                            </kendo-switch>
                          </div>
                        </ng-template>
                      </kendo-grid-column>

                      <kendo-grid-column [headerClass]="'text-center'" class="text-center"
                        title="{{globals.adminTranslationText.community.list.collumns.actions}}" width="100">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                          <button kendoButton kendoTooltip position="top" class="edit top" (click)="edit(dataItem)">

                            <i
                              title="{{globals.adminTranslationText.community.list.buttons.edit}}"
                              class="fa-regular fa-pen-to-square"></i>
                          </button>
                          <button kendoButton kendoTooltip position="top" class="delete top" (click)="deleteContact(dataItem)">
                            <i class="fa-regular fa-trash-can"></i>
                            <!-- <i data-toggle="tooltip" data-placement="top" class="fa-regular fa-trash-can"
                              [attr.title]="dataItem.flag > 0 ? 'Community can not be deleted as used in item.': globals.adminTranslationText.community.list.buttons.delete"></i> -->
                          </button>
                        </ng-template>
                      </kendo-grid-column>
                    </kendo-grid>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- End Content -->
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</div>
<kendo-dialog title="Import Contacts From" *ngIf="opened" (close)="close('cancel')" [minWidth]="250" [width]="725">
  <div class="table_block d-flex justify-content-center">
    <!-- <button kendobutton="" class="btn_google k-button" role="button" (click)="confirmpopup()"><i class="fa-brands fa-google"></i> Google</button>
    <button kendobutton="" class="btn_hotmail k-button" role="button"><i class="fa-solid fa-envelope"></i> Hotmail</button> -->
    <ng-container *ngFor="let socialNetwork of socialNetworkEntity;">
      <div
        *ngIf="globals.configurationText.google_contact.provider==socialNetwork.name || globals.configurationText.hotmail.provider==socialNetwork.name">
        <button class="btn_fb"
          [ngClass]="{btn_google:socialNetwork.name == globals.configurationText.google_contact.provider,btn_hotmail:socialNetwork.name == globals.configurationText.hotmail.provider}"
          kendoButton (click)="confirmpopup($event,socialNetwork.name,socialNetwork.id)">
          <i class="{{socialNetwork.class_code}}" aria-hidden="true"></i>
          <span *ngIf="socialNetwork.name=='google_contact'">google</span>
          <span *ngIf="socialNetwork.name!='google_contact'">{{socialNetwork.name}}</span></button>
      </div>
    </ng-container>
  </div>
</kendo-dialog>
<kendo-dialog title="Add Contact In Address Book" *ngIf="ContactPopUp" (close)="addressbookPopupClose()"
[minWidth]="250" [width]="725">
  <div class="table_block">

    <form #details="ngForm" (ngSubmit)="insertupdateAddressBook(details)">
      <div class="form-group">
        <label for="formGroupExampleInput2"><span class="mendatory_feild">*</span> First Name</label>
        <input kendoTextBox class="form-control" type="text" required #first_name="ngModel"
          [(ngModel)]="addressBookEntity.first_name" name="first_name" id="first_name"  pattern="^[a-zA-Z/]{2,}[\sa-zA-Z/]*"
          maxlength="100"
          [ngClass]="{error: (first_name.dirty && first_name.invalid) || (first_name.pristine && submitted)}">
        <span class="error_class"
          *ngIf="(first_name.dirty && first_name.invalid) || (first_name.pristine && submitted) || errorEntity.first_name">
          <span *ngIf="first_name.errors && first_name.errors.required">
            {{globals.commonTranslationText.profilePage.personalInformationForm.firstName.required}}
          </span>
          <span *ngIf="first_name.errors && first_name.errors.pattern">
            {{globals.commonTranslationText.profilePage.personalInformationForm.firstName.pattern}}
          </span>
          <span *ngIf="errorEntity.first_name != ''">
            {{errorEntity.first_name}}
          </span>
        </span>
      </div>
      <div class="form-group">
        <label for="formGroupExampleInput2"><span class="mendatory_feild">*</span> Last Name</label>
        <input kendoTextBox class="form-control" type="text" required #last_name="ngModel"
          [(ngModel)]="addressBookEntity.last_name" name="last_name" id="last_name" pattern="^[a-zA-Z/]{2,}[\sa-zA-Z/]*"
          maxlength="100"
          [ngClass]="{error: (last_name.dirty && last_name.invalid) || (last_name.pristine && submitted)}">
        <span class="error_class"
          *ngIf="(last_name.dirty && last_name.invalid) || (last_name.pristine && submitted) || errorEntity.last_name">
          <span *ngIf="last_name.errors && last_name.errors.required">
            {{globals.commonTranslationText.profilePage.personalInformationForm.lastName.required}}
          </span>
          <span *ngIf="last_name.errors && last_name.errors.pattern">
            {{globals.commonTranslationText.profilePage.personalInformationForm.lastName.pattern}}
          </span>
          <span *ngIf="errorEntity.last_name != ''">
            {{errorEntity.last_name}}
          </span>
        </span>
      </div>
      <div class="form-group">
        <label for="exampleInputEmail1"> Email address</label>
        <input kendoTextBox type="email" class="form-control"
          pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}" #email="ngModel"
          [(ngModel)]="addressBookEntity.email" name="email" maxlength="100"  id="email"
          [ngClass]="{error: (email.dirty && email.invalid) || (email.pristine && submitted && email.errors && email.errors.required) }" />
        <span class="error_class" *ngIf="(email.dirty && email.invalid) || (email.pristine && submitted)">
          <!-- <span *ngIf="email.errors && email.errors.required">
            {{globals.commonTranslationText.loginPage.form.emailAddress.required}}
          </span> -->
          <span *ngIf="email.errors && email.errors.pattern">
            Please enter your valid email address
          </span>
        </span>
        <span class="error_class">
          <span>{{ errorEntity.email }}</span>
        </span>
      </div>
      <div class="form-group">
        <label for="example-tel-input" class="col-form-label">Phone Number</label>
        <input kendoTextBox class="form-control" type="text" name="phone" id="phone" #phone="ngModel"
          [(ngModel)]="addressBookEntity.phone" maxlength="14" (keyup)="phoneNumberFormat(addressBookEntity.phone)" 
          [ngClass]="{error:phoneValid}"/>
        <span class="error_class" *ngIf="(phone.errors && phone.errors.pattern) || errorEntity.phone || phoneValid">
          <!-- <span *ngIf="phone.errors && phone.errors.required">
            {{globals.commonTranslationText.profilePage.personalInformationForm.phoneNumber.required}}
          </span> -->
          <span *ngIf="phoneValid">
            {{globals.commonTranslationText.profilePage.personalInformationForm.phoneNumber.pattern}}
          </span>
          <span *ngIf="errorEntity.phone != ''">
            {{errorEntity.phone}}
          </span>
        </span>
      </div>

      <div class="form-group">
        <label class="mb-1"><span class="mendatory_feild">*</span>
          {{globals.adminTranslationText.category.form.active.label}}</label>
        <div class="activeyes_box">
          <!-- <input type="checkbox" name="is_active" id="is_active" value="yes" #is_active="ngModel"
            [(ngModel)]="categoryEntity.is_active">
          <label for="IsActive"></label> -->
          <kendo-switch [(ngModel)]="addressBookEntity.is_active" value="yes" #is_active="ngModel" name="IsActive"
            [onLabel]="'Y'" [offLabel]="'N'"></kendo-switch>
        </div>
      </div>


      <div class="col-xl-12 text-center">
        <button kendoButton type="submit" class="themeoption_btn" *ngIf="addressBookEntity.id != null">Update</button>
        <button kendoButton type="submit" class="theme_btn" *ngIf="addressBookEntity.id == null">Add</button>
      </div>


    </form>
  </div>
</kendo-dialog>

<kendo-dialog title="Add Contact to Group" *ngIf="GroupPopUp" (close)="addGroupPopupClose()" [minWidth]="250" [width]="725">
  <div class="table_block">

    <form #details="ngForm" (ngSubmit)="insertupdateGroup(details)">
      <div class="radio_box">
        <input type="radio" name="grouptype" id="newGroup" (change)="validationCheck('new')" checked>
        <label for="newGroup" title="Role2"> New Group </label>
      </div>
      <div class="radio_box">
        <input type="radio" name="grouptype" id="oldGroup" (change)="validationCheck('old')">&nbsp;
        <label for="oldGroup">Existing Group</label>
      </div>
      <br><br>

      <div class="form-group mb-5" id="group_name_new" *ngIf="newGroup">
        <input kendoTextBox type="group_name" class="form-control" #group_name="ngModel"
          [(ngModel)]="groupEntity.group_name" name="group_name" maxlength="100" placeholder="Enter new group name"
          required id="group_name" pattern="^[a-zA-Z0-9_/]{2,}[\sa-zA-Z0-9_/]*"
          [ngClass]="{error: (group_name.dirty && group_name.invalid) || (group_name.pristine && submitted2 && group_name.errors && group_name.errors.required) }" />
        <span class="error_class"
          *ngIf="(group_name.dirty && group_name.invalid) || (group_name.pristine && submitted2) || errorEntity.group_name">
          <span *ngIf="group_name.errors && group_name.errors.required">
            Please enter a group name
          </span>
          <span *ngIf="group_name.errors && group_name.errors.pattern">
            Please enter a valid group name
          </span>
          <span *ngIf="errorEntity.group_name != ''">
            {{errorEntity.group_name}}
          </span>
        </span>
      </div>

      <div class="form-group mb-5" id="group_name_old" *ngIf="oldGroup">
        <kendo-dropdownlist [data]="grouplist.details" [filterable]="false" [textField]="'name'" [valueField]="'id'"
          [valuePrimitive]="true" #groupId="ngModel" [(ngModel)]="groupEntity.group_id" name="id" required
          [ngClass]="{error: (groupId.dirty && groupId.invalid) || (groupId.pristine && submitted2 && groupId.errors && groupId.errors.required) }">
        </kendo-dropdownlist>
        <span class="error_class"
          *ngIf="(groupId.dirty && groupId.invalid) || (groupId.pristine && submitted2) || errorEntity.groupId">
          <span *ngIf="groupId.errors && groupId.errors.required">
            Please select group
          </span>
          <span *ngIf="errorEntity.groupId != ''">
            {{errorEntity.groupId}}
          </span>
        </span>
      </div>

      <div class="col-xl-12 text-center">
        <button kendoButton type="submit" class="theme_btn">Add</button>
      </div>



    </form>
  </div>
</kendo-dialog>