<div class="upgrade_plan inner_padding fixed-head">
    <div class="container-fluid">
        <div class="row d-flex align-items-center pb-5">
            <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                <div class="upgrade_plan_intro">
                    <h2>Upgrade<span> Membership</span></h2>
                    <p>IJCAHPO EYEXchange Connect is flexible by design. We provide pricing models to give you;</p>
                    <ul class="upgrade_list">
                        <li>More “live” time for your question beyond the current {{purchasePlanEntity.question_validity}} hour
                            limit</li>
                        <li>More invitees allowed beyond the {{purchasePlanEntity.number_of_invitations}} user limit for the
                            basic account</li>
                        <li>More than the {{purchasePlanEntity.number_of_responses}} allowed responses added to your output
                            graphs</li>
                    </ul>
                    <p>Larger organizations with more complex needs should <a routerLink="/contactUs">contact our
                            support staff</a> to discuss custom solutions.</p>

                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                <div class="upgrade_plan_thumbnail">
                    <img src="assets/images/upgradeplan.png" alt="" class="img-fluid" />
                </div>
            </div>
        </div>
        <!-- <input id="card-holder-name" type="text"> -->
        <div class="row d-flex justify-content-center my-5 py-5">
            <ng-container *ngFor="let plan of planList;">
                <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12" *ngIf="plan.is_admin == 0 && plan.is_active == 1">
                    <div class="upgrade_plan_box"
                        [ngClass]="{active:plan.id==planId || plan.id == globals.authData.account_type}">
                        <div class="plan_name">
                            <span>{{plan.name}}</span>
                        </div>
                        <div class="plan_price">
                            <span class="initial">$</span>{{plan.amount}} <small>/ Month</small>
                            <!-- <span class="middle">2</span>
                            <span class="last">35<br><small>Month</small></span> -->
                        </div>
                        <div class="plan_desc">
                            <ul class="plans_list">
                                <li [innerHtml]="plan.description"></li>
                                <!-- <li [innerHtml]="plan.description.length > 20 ? plan.description.substring(0,20)+'...' : plan.description" title="{{plan.description}}"></li> -->
                            </ul>
                        </div>
                        <div class="plan_features">
                            <label>Private Threads</label>
                            <div class="for_private">
                                <div class="float-start">Thread Validity</div>
                                <div class="float-end">{{plan.question_validity}} hours</div>
                                <div class="clearfix"></div>
                                <div class="float-start">Number of invitations</div>
                                <div class="float-end">{{plan.number_of_invitations}}</div>
                                <div class="clearfix"></div>
                                <div class="float-start">Number of communities</div>
                                <div class="float-end">{{plan.community_question_number_of_communities}}</div>
                                <div class="clearfix"></div>
                                <div class="float-start">Number of responses</div>
                                <div class="float-end">{{plan.number_of_responses}}</div>
                                <div class="clearfix"></div>
                                <div class="float-start">Thread Limit</div>
                                <div class="float-end">{{plan.question_limitation}}</div>
                                <div class="clearfix"></div>
                            </div>
                            <!-- <label>Community Questions</label>
                            <div class="for_private">
                                <div class="float-left">Question Validity</div>
                                <div class="float-right">{{plan.community_question_validity}} hours</div>
                                <div class="clearfix"></div>
                                <div class="float-left">Number of invitations</div>
                                <div class="float-right">{{plan.community_question_number_of_invitations}}</div>
                                <div class="clearfix"></div>
                                <div class="float-left">Number of communities</div>
                                <div class="float-right">{{plan.community_question_number_of_communities}}</div>
                                <div class="clearfix"></div>
                                <div class="float-left">Number of responses</div>
                                <div class="float-right">{{plan.community_question_number_of_responses}}</div>
                                <div class="clearfix"></div>
                                <div class="float-left">Question Limit</div>
                                <div class="float-right">{{plan.question_limitation}}</div>
                                <div class="clearfix"></div>
                            </div> -->
                        </div>
                        <div class="plan_purchase_btn mt-4">
                            <button kendoButton type="submit" *ngIf="plan.id!=planId && plan.id != globals.authData.account_type"
                                (click)="createPayment(plan)"
                                class="theme_btn d-flex justify-content-center mx-auto">Purchase
                                Plan</button>
                </div>
            </div>
            <!-- <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                <div class="upgrade_plan_box active">
                    <div class="plan_name">
                        <span>Basic</span>
                    </div>
                    <div class="plan_price">
                        <span class="initial">$</span>22.50 <small>/ Month</small>
                    </div>
                    <div class="plan_desc">
                        <ul class="plans_list">
                            <li innerHtml="Lorem Ipsum is simply dummy">dsfds</li>
                        </ul>
                    </div>
                    <div class="plan_features">
                        <label>Private</label>
                        <div class="for_private">
                            <div class="float-start">Question Validity</div>
                            <div class="float-end">34 hours</div>
                            <div class="clearfix"></div>
                            <div class="float-start">Number of invitations</div>
                            <div class="float-end">10</div>
                            <div class="clearfix"></div>
                        </div>
                        <label>Public</label>
                        <div class="for_private">
                            <div class="float-start">Question Validity</div>
                            <div class="float-end">22 hours</div>
                            <div class="clearfix"></div>
                            <div class="float-start">Number of invitations</div>
                            <div class="float-end">25</div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                    <div class="plan_purchase_btn mt-4">
                        <button type="submit" class="theme_btn d-flex justify-content-center mx-auto">Purchase
                            Plan</button>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                <div class="upgrade_plan_box">
                    <div class="plan_name">
                        <span>Basic</span>
                    </div>
                    <div class="plan_price">
                        <span class="initial">$</span>22.50 <small>/ Month</small>
                    </div>
                    <div class="plan_desc">
                        <ul class="plans_list">
                            <li innerHtml="Lorem Ipsum is simply dummy">dsfds</li>
                        </ul>
                    </div>
                    <div class="plan_features">
                        <label>Private</label>
                        <div class="for_private">
                            <div class="float-start">Question Validity</div>
                            <div class="float-end">34 hours</div>
                            <div class="clearfix"></div>
                            <div class="float-start">Number of invitations</div>
                            <div class="float-end">10</div>
                            <div class="clearfix"></div>
                        </div>
                        <label>Public</label>
                        <div class="for_private">
                            <div class="float-start">Question Validity</div>
                            <div class="float-end">22 hours</div>
                            <div class="clearfix"></div>
                            <div class="float-start">Number of invitations</div>
                            <div class="float-end">25</div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                    <div class="plan_purchase_btn mt-4">
                        <button type="submit" class="theme_btn d-flex justify-content-center mx-auto">Purchase
                            Plan</button>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                <div class="upgrade_plan_box">
                    <div class="plan_name">
                        <span>Basic</span>
                    </div>
                    <div class="plan_price">
                        <span class="initial">$</span>22.50 <small>/ Month</small>
                    </div>
                    <div class="plan_desc">
                        <ul class="plans_list">
                            <li innerHtml="Lorem Ipsum is simply dummy">dsfds</li>
                        </ul>
                    </div>
                    <div class="plan_features">
                        <label>Private</label>
                        <div class="for_private">
                            <div class="float-start">Question Validity</div>
                            <div class="float-end">34 hours</div>
                            <div class="clearfix"></div>
                            <div class="float-start">Number of invitations</div>
                            <div class="float-end">10</div>
                            <div class="clearfix"></div>
                        </div>
                        <label>Public</label>
                        <div class="for_private">
                            <div class="float-start">Question Validity</div>
                            <div class="float-end">22 hours</div>
                            <div class="clearfix"></div>
                            <div class="float-start">Number of invitations</div>
                            <div class="float-end">25</div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                    <div class="plan_purchase_btn mt-4">
                        <button type="submit" class="theme_btn d-flex justify-content-center mx-auto">Purchase
                            Plan</button>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                <div class="upgrade_plan_box">
                    <div class="plan_name">
                        <span>Basic</span>
                    </div>
                    <div class="plan_price">
                        <span class="initial">$</span>22.50 <small>/ Month</small>
                    </div>
                    <div class="plan_desc">
                        <ul class="plans_list">
                            <li innerHtml="Lorem Ipsum is simply dummy">dsfds</li>
                        </ul>
                    </div>
                    <div class="plan_features">
                        <label>Private</label>
                        <div class="for_private">
                            <div class="float-start">Question Validity</div>
                            <div class="float-end">34 hours</div>
                            <div class="clearfix"></div>
                            <div class="float-start">Number of invitations</div>
                            <div class="float-end">10</div>
                            <div class="clearfix"></div>
                        </div>
                        <label>Public</label>
                        <div class="for_private">
                            <div class="float-start">Question Validity</div>
                            <div class="float-end">22 hours</div>
                            <div class="clearfix"></div>
                            <div class="float-start">Number of invitations</div>
                            <div class="float-end">25</div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                    <div class="plan_purchase_btn mt-4">
                        <button type="submit" class="theme_btn d-flex justify-content-center mx-auto">Purchase
                            Plan</button>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                <div class="upgrade_plan_box">
                    <div class="plan_name">
                        <span>Basic</span>
                    </div>
                    <div class="plan_price">
                        <span class="initial">$</span>22.50 <small>/ Month</small>
                    </div>
                    <div class="plan_desc">
                        <ul class="plans_list">
                            <li innerHtml="Lorem Ipsum is simply dummy">dsfds</li>
                        </ul>
                    </div>
                    <div class="plan_features">
                        <label>Private</label>
                        <div class="for_private">
                            <div class="float-start">Question Validity</div>
                            <div class="float-end">34 hours</div>
                            <div class="clearfix"></div>
                            <div class="float-start">Number of invitations</div>
                            <div class="float-end">10</div>
                            <div class="clearfix"></div>
                        </div>
                        <label>Public</label>
                        <div class="for_private">
                            <div class="float-start">Question Validity</div>
                            <div class="float-end">22 hours</div>
                            <div class="clearfix"></div>
                            <div class="float-start">Number of invitations</div>
                            <div class="float-end">25</div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <!-- <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="faq_wrap mt-5">
                    <h2>Have Questions?<span> Look Here.</span></h2>
                    <p>An magnis nulla dolor sapien augue erat iaculis purus tempor magna ipsum
                        vitae purus<br>primis pretium ligula rutrum luctus blandit porta justo integer.</p>
                    <div class="accordion mt-5" id="accordionExample">
                        <div class="card">
                            <div class="card-header" id="headingOne">
                                <h2 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse"
                                        data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        An magnis nulla dolor sapien augue erat iaculis purus tempor
                                    </button>
                                </h2>
                            </div>

                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                                data-parent="#accordionExample">
                                <div class="card-body">
                                    Eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                    quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                                    irure dolor in reprehenderit in
                                    voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingTwo">
                                <h2 class="mb-0">
                                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse"
                                        data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        Nemo ipsam egestas volute turpis dolores ut aliquam quaerat sodales

                                    </button>
                                </h2>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                                data-parent="#accordionExample">
                                <div class="card-body">
                                    Eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                    quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                                    irure dolor in reprehenderit in
                                    voluptate velit esse cillum dolore eu fugiat nulla pariatur.


                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingThree">
                                <h2 class="mb-0">
                                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse"
                                        data-target="#collapseThree" aria-expanded="false"
                                        aria-controls="collapseThree">
                                        Ipsum undo auctor a mauris lectus ipsum blandit porta justo integer
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                                data-parent="#accordionExample">
                                <div class="card-body">
                                    Eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                    quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                                    irure dolor in reprehenderit in
                                    voluptate velit esse cillum dolore eu fugiat nulla pariatur.


                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingFour">
                                <h2 class="mb-0">
                                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse"
                                        data-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                                        Purus tempor magna ipsum vitae purus primis pretium ligula rutrum luctus

                                    </button>
                                </h2>
                            </div>

                            <div id="collapseFour" class="collapse" aria-labelledby="headingFour"
                                data-parent="#accordionExample">
                                <div class="card-body">
                                    Eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                    quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                                    irure dolor in reprehenderit in
                                    voluptate velit esse cillum dolore eu fugiat nulla pariatur.


                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header" id="headingFive">
                                <h2 class="mb-0">
                                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse"
                                        data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                        Feugiat a primis ultrice ligula risus auctor rhoncus purus ipsum primis
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseFive" class="collapse" aria-labelledby="headingFive"
                                data-parent="#accordionExample">
                                <div class="card-body">
                                    Eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                    quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                                    irure dolor in reprehenderit in
                                    voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
                </div>
            </ng-container>
</div>

<kendo-dialog title="Credit Card Details" *ngIf="paymentPopUp" (close)="paymentPopUpClose()" [minWidth]="250" [width]="725">
    <div class="table_block">
        <!-- Stripe Elements Placeholder -->
        <div class="abc" id="card-element"></div>
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 text-center">
                <button kendoButton class="theme_btn mt-5" id="card-button">
                    Pay
                </button>
            </div>
        </div>
    </div>
</kendo-dialog>