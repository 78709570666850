<div class="home_wrap dashboard_wrap search_wrap fixed-head">
    <div class="container-fluid">
        <div class="row pb-5">
            <div class="col-xl-9 col-lg-8 col-md-12 col-sm-12 col-12">
                <div class="search_head mb-4">
                    <h2 class="decideit_head">{{globalSearchEntity.search}}</h2>
                    <small>Search results</small>
                </div>
                <div class="search-results" infinite-scroll [infiniteScrollDistance]="scrollDistance"
                        [infiniteScrollUpDistance]="scrollUpDistance" [infiniteScrollThrottle]="throttle"
                        (scrolled)="onScrollDown($event)">
                            <div class="top_question_wrap" *ngIf="!communityQuestionLoading2">
                                <div class="questions_boxs" *ngFor="let question of communityQuestionsList;let i =index;">
                                   <div class="padding_boxs not_count_padding" >
                                    <div class="row">
                                        <div class="col-xl-2 col-lg-2 col-md-2">
                                            <!-- <div class="quebox_media" *ngIf="question.file_extention != '' && question.imageExtension">
                                                <img *ngIf="question.file_extention != '' && question.imageExtension"
                                                src="{{globals.profileImgeapiUrl}}/question/{{question.id}}/{{question.image_video_url}}"
                                                alt="" class="img-fluid" />
                                            </div> -->
                                            <!-- Display Media -->
                                            <div class="quebox_media" *ngIf="question.filedata.length > 0">
                                                <div
                                                  [attr.id]="'abc'+question.id"
                                                  class="carousel slide"
                                                  data-bs-ride="carousel"
                                                  data-bs-interval="false"
                                                  
                                                >
                                                  <!-- <div
                                                    class="carousel-indicators"
                                                    *ngIf="question.filedata.length > 1"
                                                  >
                                                    <button
                                                      type="button"
                                                      [attr.data-bs-target]="'#abc'+question.id"
                                                      [attr.data-bs-slide-to]="i"
                                                      [attr.aria-label]="'Slide ' + i"
                                                      [ngClass]="{ active: i == 0 }"
                                                      *ngFor="let list of question.filedata; let i = index"
                                                      [attr.aria-current]="i == 0"
                                                    ></button>
                                                  </div> -->
                                                  <div class="carousel-inner">
                                                    <div
                                                      class="carousel-item"
                                                      [ngClass]="{ active: i == 0 }"
                                                      *ngFor="let list of question.filedata; let i = index"
                                                    >
                                                      <img
                                                        alt=""
                                                        class="d-block w-100"
                                                        *ngIf="list.type == 'img'"
                                                        src="{{ globals.profileImgeapiUrl }}/question/{{
                                                          question.id
                                                        }}/{{ list.name }}"
                                                      />
                                                      <div
                                                        class="ratio ratio-16x9"
                                                        *ngIf="list.type == 'video'"
                                                      >
                                                        <video controls>
                                                          <source
                                                            src="{{ globals.profileImgeapiUrl }}/question/{{
                                                              question.id
                                                            }}/{{ list.name }}"
                                                          />
                                                        </video>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <button
                                                    *ngIf="question.filedata.length > 1"
                                                    class="carousel-control-prev"
                                                    type="button"
                                                    [attr.data-bs-target]="'#abc'+question.id"
                                                    data-bs-slide="prev"
                                                  >
                                                    <span
                                                      class="carousel-control-prev-icon"
                                                      aria-hidden="true"
                                                    ></span>
                                                    <span class="visually-hidden">Previous</span>
                                                  </button>
                                                  <button
                                                    *ngIf="question.filedata.length > 1"
                                                    class="carousel-control-next"
                                                    type="button"
                                                    [attr.data-bs-target]="'#abc'+question.id"
                                                    data-bs-slide="next"
                                                  >
                                                    <span
                                                      class="carousel-control-next-icon"
                                                      aria-hidden="true"
                                                    ></span>
                                                    <span class="visually-hidden">Next</span>
                                                  </button>
                                                </div>
                                              </div>
                                              <div class="quebox_media" *ngIf="question.filedata.length == 0">
                                                <img src="/assets/images/no_media.svg" alt=""/>
                                              </div>
                                            <!-- Display Media -->
                                            <div class="quebox_media d-flex justify-content-center align-items-center" *ngIf="question.file_extention != '' && question.videoExtension">
                                                <a ><i class="fa-solid fa-video"></i></a>
                                            </div>
                                            <!-- <div class="quebox_media" *ngIf="question.file_extention != '' && (question.file_extention == 'jpg' || question.file_extention == 'png' || question.file_extention == 'jpeg' || question.file_extention == 'JPG' || question.file_extention == 'PNG' || question.file_extention == 'JPEG')">
                                                <img *ngIf="question.file_extention != '' && (question.file_extention == 'jpg' || question.file_extention == 'png' || question.file_extention == 'jpeg' || question.file_extention == 'JPG' || question.file_extention == 'PNG' || question.file_extention == 'JPEG')"
                                                src="{{globals.profileImgeapiUrl}}/question/{{question.id}}/{{question.image_video_url}}"
                                                alt="" class="img-fluid" />
                                            </div>
                                            <div class="quebox_media d-flex justify-content-center align-items-center" *ngIf="question.file_extention != '' && (question.file_extention == 'mp4' || question.file_extention == 'MP4' || question.file_extention == 'MKV' || question.file_extention == 'mkv' || question.file_extention == 'WEBM' || question.file_extention == 'webm')">
                                                <a ><i class="fa-solid fa-video"></i></a>
                                            </div> -->
                                            <div *ngIf="question.image_video_url == null || question.image_video_url == ''" class="quebox_media d-flex justify-content-center align-items-center">
                                                <a ><i class="fa-solid fa-file-lines"></i></a>
                                            </div>
                                        </div>
                                        <div class="col-xl-10 col-lg-10 col-md-10">
                                            <div class="quebox_top">
                                                <!-- <p>{{question.question_text}}</p> -->
                                                <div class="editor_data" >
                                                    <a (click)="questionDetail(question.id)" [innerHtml]="question.question_text" ></a>
                                                </div>
                                            </div>
                                            <a (click)="questionDetail(question.id)">
                                            <div class="quebox_bottom d-flex align-items-center">
                                                <div class="qt_desc">
                                                    <span class="user_name"><a
                                                        (click)="publicProfile(question.user_id,question.send_anonymously)">{{question.display_name}}</a></span>
                                                    <ul class="queicon_lists">
                                                        <li class="like_question"  *ngIf="(globals.authData == undefined) || (question.is_like == '' || question.is_like == null) ">
                                                            <a onclick="event.stopPropagation();" (click)="likeDislikeQuestionAnswer(question,1)" id="thumb{{question.id}}"><i class="fa-regular fa-thumbs-up"></i></a> 
                                                            <span >{{question.likes}}</span>
                                                        </li>
                                                        <li class="like_question" *ngIf="(question.is_like != '' && question.is_like != null) "> 
                                                            <a onclick="event.stopPropagation();" (click)="likeDislikeQuestionAnswer(question,0)" id="thumb{{question.id}}"><i class="fa-solid fa-thumbs-up"></i></a> 
                                                            <span >{{question.likes}}</span>
                                                        </li>
                                                        <li><i class="fa-regular fa-comment-dots"></i> {{question.comments}}</li>
                                                        <li><i class="fa-regular fa-eye"></i> {{question.views}}</li>
                                                        <!-- <li class="community_lists"><i class="fa-solid fa-bullhorn"></i>
                                                            <ng-container *ngFor="let community of question.community" >
                                                                <a (click)="communityQuestion(community.id)">{{community.name}}</a>
                                                            </ng-container>
                                                        </li> -->
                                                        <!-- <li><a (click)="communityQuestion(question.community_id)"><i class="fa-solid fa-bullhorn"></i> {{question.community_name}}</a></li> -->
                                                        
                                                    </ul>
                                                    <ul class="posted_expired_lists">
                                                        <li class="posted_date_time"><span>Posted:</span>
                                                            {{question.posted_time}} </li>
                                                        <!-- <li class="expires_date_time"><span>{{question.expire_title}}</span>
                                                            {{question.Expire_time}}</li> -->
                                                        <li class="float-end" *ngIf="(globals.authData == undefined) || ((question.is_abused == '' || question.is_abused == null) && question.created_by != globals.authData.id  && globals.reportQuestionAnswerEntity.question_id != question.id )">
                                                            <a onclick='event.stopPropagation();' class="report" (click)="globals.reportPopUpOpen(question.id,'',question.user_name)"><i class="fa-solid fa-flag"></i> Report</a>
                                                       </li>
                                                       <li class="float-end" *ngIf="(question.is_abused != '' &&  question.is_abused != null ) || (globals.reportQuestionAnswerEntity.reportedTime != '' && globals.reportQuestionAnswerEntity.reportedTime != undefined && globals.reportQuestionAnswerEntity.question_id == question.id)">
                                                           <a onclick='event.stopPropagation();' *ngIf="globals.reportQuestionAnswerEntity.reportedTime != '' && globals.reportQuestionAnswerEntity.reportedTime != undefined && globals.reportQuestionAnswerEntity.question_id == question.id" class="report_question_answer" title="{{globals.commonTranslationText.questionDetail.reportQuestionTooltip}} {{globals.reportQuestionAnswerEntity.reportedTime | date:'MMM d, y h:mm a'}}"><i class="fa-solid fa-flag"></i> Reported </a>
                                                           <a onclick='event.stopPropagation();' *ngIf="globals.reportQuestionAnswerEntity.reportedTime == '' || globals.reportQuestionAnswerEntity.reportedTime == undefined || globals.reportQuestionAnswerEntity.question_id != question.id" class="report_question_answer" title="{{globals.commonTranslationText.questionDetail.reportQuestionTooltip}} {{question.is_abused | date:'MMM d, y h:mm a'}}"><i class="fa-solid fa-flag"></i> Reported </a>
                                                       </li>
                                                    </ul>
                                                </div>
                                                <div class="clearfix"></div>
                                            </div>
                                            </a>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="communityQuestionLoading2 || communityQuestionLoading4">
                                <div class="questions_boxs" *ngFor="let object of questionLoaderList">
                                    <ngx-shimmer-loading [width]="'100%'"></ngx-shimmer-loading>
                                    <ngx-shimmer-loading [width]="'100%'"></ngx-shimmer-loading>
                                    <ngx-shimmer-loading [width]="'100%'"></ngx-shimmer-loading>
            
                                    <div class="row d-flex align-items-center">
                                        <div class="col-xl-2 col-lg-2 col-12">
                                            <ngx-shimmer-loading [shape]="'circle'" [width]="'75px'"></ngx-shimmer-loading>
                                        </div>
                                        <div class="col-xl-10 col-lg-10 col-12">
                                            <ngx-shimmer-loading [width]="'250px'"></ngx-shimmer-loading>
                                            <ngx-shimmer-loading [width]="'250px'"></ngx-shimmer-loading>
                                            <ngx-shimmer-loading [width]="'250px'"></ngx-shimmer-loading>
                                        </div>
                                
                                    </div>
                                </div>
                            </ng-container>
                            

                            <div *ngIf="communityQuestionsList.length==0 && !communityQuestionLoading2">
                                <div class="no_que_found mt-5">
                                    <img src="assets/images/no_found.png" alt="" class="img-fluid" />
                                    No Results Found
                                </div>
                            </div>
                    </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-12 col-sm-12 col-12">
                <div class="home_wrap_right">
                    <!-- <div class="decideit_info">
                        <div class="post_que_header">
                            <h2 class="decideit_head"><span>Welcome To</span> Eyedea Exchange</h2>
                        </div>
                        <div class="advertisement_box">
                            <p>IJCAHPO Eyedea Exchange is your key to fast, easy and clutter-free group decisions.</p>
                            <p>Send to up to 1000 friends instantly, Now!</p>
                            <p>Threads Remain Live for 99 Hrs</p>
                        </div>
                    </div> -->
                    <div class="dicommon_links mt-5">
                        <h2 class="decideit_head"><span>Quick</span> Links</h2>
                        <ul>
                            <li><img src="assets/images/topque.png" alt="" class="img-fluid" /> <a routerLink="/topQuestion">Popular Threads</a></li>
                            <li><img src="assets/images/decideitstar.png" alt="" class="img-fluid" /> <a (click)="decideItStars('star')">EYEXchange Connect Stars</a></li>
                            <!-- <li><img src="assets/images/topresponders.png" alt="" class="img-fluid" /> <a (click)="decideItStars('responder')">Top
                                    <span>Responders</span></a></li> -->
                            <!-- <li><img src="assets/images/decideitcommunity.png" alt="" class="img-fluid" /> <a href="/communityList">DecideIt
                                    <span>Communities</span></a></li> -->
                            <li class="no_border"><img src="assets/images/contactus.png" alt="" class="img-fluid" />
                                <a href="/contactUs">Contact Us</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>