import { Component, OnInit, ViewEncapsulation} from '@angular/core';
import { AuthService } from "../services/auth.service";
import { Router } from "@angular/router";
import { Globals } from "../globals";
import { CookieService } from 'ngx-cookie-service';
import { NotificationService } from "../services/notification.service";
declare var $: any, PerfectScrollbar, Swal;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {

  constructor(private AuthService: AuthService, private router: Router, public globals: Globals, private cookieService: CookieService,
    private NotificationService: NotificationService,) { }
  btn_disabled;
  recentNotifications;
  PendingNotification = 0;
  currentDate = new Date();
  yesterdayDate = new Date();
  beforeWeekDate = new Date();
  searchEntity;
  ngOnInit(): void {
    this.searchEntity = {};
    if (this.cookieService.check('globalSearch')) {
      this.searchEntity = JSON.parse(this.cookieService.get('globalSearch'));
    }
    var self = this;
    // $("#search").keydown(function (e) {
    //   if (e.keyCode == 13) {
    //     self.globalSearch()
    //   }
    // });
    this.recentNotifications = {};
    console.log(window.location.pathname);
    var d1 = new Date();
    this.globals.cookieMessageExpireTime = new Date(d1);
    this.globals.cookieMessageExpireTime.setMinutes(d1.getMinutes() + this.globals.configurationText.cookieMessageExpireTime);
    console.log(this.globals.cookieMessageExpireTime);
    console.log(this.globals.isUser);
    setTimeout(() => {
      if (window.location.pathname == '/login') {
        // $("#loginClass").addClass('col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 d-flex align-items-center justify-content-end');
      }
    }, 500);

    if (this.cookieService.check('successMessage')) {
      console.log(this.cookieService.get('successMessage'));
      Swal.fire({
        type: this.globals.configurationText.sweetAlertTypeIconSuccess,
        icon: this.globals.configurationText.sweetAlertTypeIconSuccess,
        customClass: this.globals.configurationText.sweetAlertClassSuccess,
        toast: true,
        title: this.cookieService.get('successMessage'),
        animation: true,
        position: this.globals.configurationText.sweetAlertPosition,
        showConfirmButton: false,
        timer: this.globals.configurationText.sweetAlertTimer,
        timerProgressBar: true,
        showCancelButton: true,
        cancelButtonText: 'x',
      });
      this.cookieService.delete('successMessage');
    }
    if (this.cookieService.check('errorMessage')) {
      Swal.fire({
        type: this.globals.configurationText.sweetAlertTypeIconError,
        icon: this.globals.configurationText.sweetAlertTypeIconError,
        customClass: this.globals.configurationText.sweetAlertClassError,
        toast: true,
        title: this.cookieService.get('errorMessage'),
        animation: true,
        position: this.globals.configurationText.sweetAlertPosition,
        showConfirmButton: false,
        timer: this.globals.configurationText.sweetAlertTimer,
        timerProgressBar: true,
        showCancelButton: true,
        cancelButtonText: 'x',
      });
      this.cookieService.delete('errorMessage');
    }
    this.yesterdayDate.setDate(this.currentDate.getDate() - 1);
    this.beforeWeekDate.setDate(this.currentDate.getDate() - 7);
    if (this.globals.authData != null && this.globals.authData != undefined) {
      var entity = {
        'role_id': this.globals.authData.roles_id,
        'user_id': this.globals.authData.id
      }
      this.NotificationService.getRecentNotifications(entity).then(
        data => {
          //debugger;
          console.log(data);
          this.recentNotifications = data['data'];
          this.PendingNotification = this.recentNotifications.length;
        },
        (error) => {
          console.log(error);
          console.log(error.error.message);
          if (error.error.message == 'Unauthenticated.') {
            localStorage.clear();
            sessionStorage.clear();
            window.location.href = 'login';
          }
          this.globals.isLoading = false;
        }
      );
    }

    setTimeout(function () {
      if ($(window).width() < 992) {
        $('.sidebar_wrap').removeClass("active_menu_header");
        $('.sidebar_wrap').removeClass("sidebar_scroll");

        $('.sidebar_wrap .sidebar_box').click(function () {
          $('.sidebar_wrap').removeClass("active_menu_header");
          $('.sidebar_wrap').removeClass("sidebar_scroll");
          $('.menu_dropdown').removeClass("close_toggle");
        });
      }

      $('.menu_dropdown').click(function () {
        $('.sidebar_toggle').removeClass("active");
        $('.sidebar_wrap').removeClass("small_menu");
        $('.main_block').removeClass("large_content");
        $('header').removeClass("active_menu_right_block");
        $('footer').removeClass("active_menu_right_block");
        $('.menu_dropdown').toggleClass("close_toggle");
      });

    }, 500);

    var searchComplete = localStorage.getItem('searchComplete')
    if(searchComplete == 'yes'){
      this.searchEntity.search = ''
    }

  }

  serach_expand() {
    //$(".searchbar_mobile_show").addClass("searchbar_mobile_show_exapnd");
    this.globalSearch();
  }

  close_expand(){
    $(".searchbar_mobile_show").toggleClass('searchbar_mobile_show_exapnd')
  }

  headermenu() {
    $('.sidebar_wrap').toggleClass("active_menu_header");
    $("#menu_header").toggleClass("menu_header_btn");
  }
  frontLogin(){
    localStorage.setItem('isUser',"2");
    this.globals.isUser = localStorage.getItem('isUser');
    this.router.navigate(['/userDashboard']);
    console.log(this.globals.isUser);
  }
  notificationshow() {
    this.PendingNotification = 0;
  }

  clearNotification(notifications) {
    var clear = {
      'id': notifications.id,
      'type': 2,
      // UserId: this.globals.authData.UserId
    };
    this.NotificationService.clearDismissNotification(clear).then(
      data => {
        let index = this.recentNotifications.indexOf(notifications);
        if (index != -1) {
          this.recentNotifications.splice(index, 1);
        }
        this.PendingNotification = this.PendingNotification - 1;
      },
      error => {
        this.globals.isLoading = false;
        Swal.fire({
          icon: this.globals.commonTranslationText.common.alerts.somethingWrong
            .type,
          type: this.globals.commonTranslationText.common.alerts.somethingWrong
            .type,
          title: this.globals.commonTranslationText.common.alerts.somethingWrong
            .title,
          text: this.globals.commonTranslationText.common.alerts.somethingWrong
            .text,
          showConfirmButton: false,
          timer: 4000
        });
      }
    );
  }
  clearAllNotification() {
    //debugger;
    var clearAll = {
      'role_id': this.globals.authData.roles_id,
      'type': 1,
      'user_id': this.globals.authData.id
    };
    this.NotificationService.clearDismissNotification(clearAll).then(
      data => {
        this.recentNotifications = [];
        this.PendingNotification = 0;
      },
      error => {
        this.globals.isLoading = false;
        Swal.fire({
          icon: this.globals.commonTranslationText.common.alerts.somethingWrong
            .type,
          type: this.globals.commonTranslationText.common.alerts.somethingWrong
            .type,
          title: this.globals.commonTranslationText.common.alerts.somethingWrong
            .title,
          text: this.globals.commonTranslationText.common.alerts.somethingWrong
            .text,
          showConfirmButton: false,
          timer: 4000
        });
      }
    );
  }

  globalSearch() {
    var d1 = new Date(),
      d2 = new Date(d1);
    d2.setMinutes(d1.getMinutes() + 30);
    if (this.searchEntity.search.trim() != '' && this.searchEntity.search != undefined && this.searchEntity.search.length >= 3) {
      //debugger
      if(this.cookieService.check('globalSearch'))
      {
        var entity = JSON.parse(this.cookieService.get('globalSearch'));
        // if(entity.search != this.searchEntity.search)
        // {
          this.cookieService.set('globalSearch', JSON.stringify(this.searchEntity), d2, '/');
          window.location.href = "/globalSearch";
        // }
      }
      else
      {
        this.cookieService.set('globalSearch', JSON.stringify(this.searchEntity), d2, '/');
        window.location.href = "/globalSearch";
      }
    }
    else {
      var aaaa = this.router.url
      if(this.router.url == "/home"){
        window.stop();
      }else{
        window.location.href = "/home";
      }
      this.searchEntity.search = ''
       this.cookieService.set('globalSearch', JSON.stringify(this.searchEntity), d2, '/');
       if (window.location.pathname == '/globalSearch') {
         window.location.href = "/home";
       }
    }
  }
  redirect(screen) {
    var d1 = new Date(),
      d2 = new Date(d1);
    d2.setMinutes(d1.getMinutes() + 30);
    if (screen == 'home') {
      if (localStorage.getItem('email_available') == "0" && localStorage.getItem('username_available') == "0") {
        this.cookieService.set('errorMessage', this.globals.commonTranslationText.loginPage.alerts.emailUsernameCompulsory.text, d2, '/');
        window.location.href = '/profile';
      }
      else if (localStorage.getItem('email_available') == "0") {
        this.cookieService.set('errorMessage', this.globals.commonTranslationText.loginPage.alerts.emailCompulsory.text, d2, '/');
        window.location.href = '/profile';
      }
      else if (localStorage.getItem('username_available') == "0") {
        this.cookieService.set('errorMessage', this.globals.commonTranslationText.loginPage.alerts.usernameCompulsory.text, d2, '/');
        window.location.href = '/profile';
      }
      else {
        window.location.href = '/home'
      }

    }
    else if (screen == 'dashboard') {
      if (this.globals.authData == undefined) {
        window.location.href = '/login'
      }
      else {
        // /var firsttimelogin = localStorage.getItem('first_time_login');
        if (localStorage.getItem('email_available') == "0" && localStorage.getItem('username_available') == "0") {
          this.cookieService.set('errorMessage', this.globals.commonTranslationText.loginPage.alerts.emailUsernameCompulsory.text, d2, '/');
          window.location.href = '/profile';
        }
        else if (localStorage.getItem('email_available') == "0") {
          this.cookieService.set('errorMessage', this.globals.commonTranslationText.loginPage.alerts.emailCompulsory.text, d2, '/');
          window.location.href = '/profile';
        }
        else if (localStorage.getItem('username_available') == "0") {
          this.cookieService.set('errorMessage', this.globals.commonTranslationText.loginPage.alerts.usernameCompulsory.text, d2, '/');
          window.location.href = '/profile';
        }
        else {
          if (this.globals.isUser == '1')
            window.location.href = '/admin/adminDashboard';
          else if (this.globals.isUser == '2')
            window.location.href = '/userDashboard';
        }
      }
    }
    else if(screen == 'admin'){
      localStorage.setItem('isUser',"1");
      this.globals.isUser = localStorage.getItem('isUser');
      this.router.navigate(['/admin/adminDashboard']);
    }
    else if (screen == 'postQuestion') {
      if (this.globals.authData == undefined) {
        this.cookieService.set('URL', screen, d2);
        window.location.href = '/login'
      }
      else {
        // /var firsttimelogin = localStorage.getItem('first_time_login');
        if (localStorage.getItem('email_available') == "0" && localStorage.getItem('username_available') == "0") {
          this.cookieService.set('errorMessage', this.globals.commonTranslationText.loginPage.alerts.emailUsernameCompulsory.text, d2, '/');
          window.location.href = '/profile';
        }
        else if (localStorage.getItem('email_available') == "0") {
          this.cookieService.set('errorMessage', this.globals.commonTranslationText.loginPage.alerts.emailCompulsory.text, d2, '/');
          window.location.href = '/profile';
        }
        else if (localStorage.getItem('username_available') == "0") {
          this.cookieService.set('errorMessage', this.globals.commonTranslationText.loginPage.alerts.usernameCompulsory.text, d2, '/');
          window.location.href = '/profile';
        }
        else {
          window.location.href = '/postQuestion';
        }
      }
    }
    else if (screen == 'topquestion') {
      window.location.href = '/topQuestion'
    }
    // else if (screen == 'upgradeplan') {
    //   if (this.globals.authData == undefined) {
    //     window.location.href = '/login'
    //   }
    //   else {
    //     // /var firsttimelogin = localStorage.getItem('first_time_login');
    //     if (localStorage.getItem('email_available') == "0" && localStorage.getItem('username_available') == "0") {
    //       this.cookieService.set('errorMessage', this.globals.commonTranslationText.loginPage.alerts.emailUsernameCompulsory.text, d2, '/');
    //       window.location.href = '/profile';
    //     }
    //     else if (localStorage.getItem('email_available') == "0") {
    //       this.cookieService.set('errorMessage', this.globals.commonTranslationText.loginPage.alerts.emailCompulsory.text, d2, '/');
    //       window.location.href = '/profile';
    //     }
    //     else if (localStorage.getItem('username_available') == "0") {
    //       this.cookieService.set('errorMessage', this.globals.commonTranslationText.loginPage.alerts.usernameCompulsory.text, d2, '/');
    //       window.location.href = '/profile';
    //     }
    //     else {
    //       window.location.href = '/upgradePlan';
    //     }
    //   }
    // }
    else if (screen == 'users') {
      // this.router.navigate(["/users"]);    
    }
    else if (screen == 'questions') {
      // this.router.navigate(["/questions"]);    
    }
    else if (screen == 'communities') {
      window.location.href = '//admin/community/list'
    }
    else if (screen == 'configuration') {
      window.location.href = '//admin/configuration'
    }
    else if (screen == 'profile') {
      if (this.globals.authData == undefined) {
        window.location.href = '/login'
      }
      else {
        window.location.href = '/profile'
      }
    }
    else if (screen == 'notification') {
      if (this.globals.authData == undefined) {
        window.location.href = '/login'
      }
    }
  }
  login() {
    window.location.href = '/login'
  }
  logout() {
    this.globals.isLoading = true;
    this.btn_disabled = true;
    this.AuthService.logout().then(
      data => {
        this.globals.isLoading = true;
        window.location.href = "";
      },
      error => {
        console.log(error);
        if (error.error.message == 'Unauthenticated.') {
          localStorage.clear();
          sessionStorage.clear();
          window.location.href = 'login';
        }
        this.globals.isLoading = false;
        // this.globals.pageNotfound(error.error.code);
      }
    );
  }

}
