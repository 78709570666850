import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Globals } from '.././globals';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { PasswordService } from '../services/password.service';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../services/auth.service';
declare var $, Swal: any;

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordEntity;
  submitted;
  errorEntity;
  btn_disable;
  current_time;
  showEmailBlock;
  showPhoneNumberBlock;
  selectType;
  phoneValid;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public globals: Globals,
    private PasswordService: PasswordService,
    private AuthService: AuthService,
    private cookieService: CookieService
  ) {}

  ngOnInit() {
    this.showEmailBlock = true;
    this.showPhoneNumberBlock = false;
    this.selectType = 'email';
    // $(document).ready(function () {
    //   const body = document.querySelector('body');
    //   body.style.setProperty('--screen-height', $(window).height() + 'px');
    // });

    this.forgotPasswordEntity = {};
    this.errorEntity = {};
  }

  filter(s) {
    var ms = s % 1000;
    s = (s - ms) / 1000;
    var secs = s % 60;
    s = (s - secs) / 60;
    var mins = s % 60;
    var hrs = (s - mins) / 60;
    //return hrs + ':' + mins + ':' + secs + ':' + ms;
    return hrs + ':' + mins + ':' + secs;
  }
  requestResetPassword(forgotPasswordForm) {
    //debugger;
    this.submitted = true;
    if (forgotPasswordForm.valid) {
      this.btn_disable = true;
      this.globals.isLoading = true;
      this.forgotPasswordEntity.user_email_mobile = this.forgotPasswordEntity.EmailAddress;
        // this.selectType == 'email'
        //   ? this.forgotPasswordEntity.EmailAddress
        //   : this.forgotPasswordEntity.PhoneNumber.replace(/\D/g, '');
      this.forgotPasswordEntity.ResetPasswordURL = 'resetPassword'; //reset password page url for email
      this.PasswordService.requestResetPassword(this.forgotPasswordEntity).then(
        (data) => {
          this.globals.isLoading = false;
          if (data['message'] == 'Invalid Details') {
            Swal.fire({
              type: this.globals.configurationText.sweetAlertTypeIconError,
              icon: this.globals.configurationText.sweetAlertTypeIconError,
              customClass: this.globals.configurationText.sweetAlertClassError,
              toast: true,
              title: this.globals.commonTranslationText.forgotPasswordPage.alerts.invalidCredential.text,
              animation: true,
              position: this.globals.configurationText.sweetAlertPosition,
              showConfirmButton: false,
              timer: this.globals.configurationText.sweetAlertTimer,
              timerProgressBar: true,
              showCancelButton: true,
              cancelButtonText: 'x',
            });
           
          } else if (data['data']['user_id'] != '') {
            var text =  this.selectType == 'email' ? 'Please check your email. Use the password reset link in the email to set a new password.' : 'We sent you a text message on your phone number. Please enter the OTP in text message here to verify your phone number.';
            this.cookieService.set('successMessage', text, this.globals.cookieMessageExpireTime,'/');
            window.location.href = '/login';
          } else {
            this.cookieService.set('successMessage', this.globals.commonTranslationText.forgotPasswordPage.alerts.success.text, this.globals.cookieMessageExpireTime,'/');
            window.location.href = '/login';
          }
        },
        (error) => {
          if (error.status == 400) {
            if (error.error.time) {
              this.current_time = Date.now();
              const converted_last_time_stamp = new Date(error.error.time);
              if (this.current_time < converted_last_time_stamp.getTime()) {
                const remaining_time =
                  converted_last_time_stamp.getTime() - this.current_time;
                const time = this.filter(remaining_time);
                Swal.fire({
                  type: this.globals.configurationText.sweetAlertTypeIconError,
                  icon: this.globals.configurationText.sweetAlertTypeIconError,
                  customClass: this.globals.configurationText.sweetAlertClassError,
                  toast: true,
                  title: this.globals.commonTranslationText.forgotPasswordPage.alerts.lockUser.text + time,
                  animation: true,
                  position: this.globals.configurationText.sweetAlertPosition,
                  showConfirmButton: false,
                  timer: this.globals.configurationText.sweetAlertTimer,
                  timerProgressBar: true,
                  showCancelButton: true,
                  cancelButtonText: 'x',
                });
              } else {
                // this.globals.pageNotfound(error.error.code);
              }
            } else {
              Swal.fire({
                type: this.globals.configurationText.sweetAlertTypeIconError,
                icon: this.globals.configurationText.sweetAlertTypeIconError,
                customClass: this.globals.configurationText.sweetAlertClassError,
                toast: true,
                title: this.globals.commonTranslationText.forgotPasswordPage.alerts.invalidCredential.text,
                animation: true,
                position: this.globals.configurationText.sweetAlertPosition,
                showConfirmButton: false,
                timer: this.globals.configurationText.sweetAlertTimer,
                timerProgressBar: true,
                showCancelButton: true,
                cancelButtonText: 'x',
              });
            }
          } else if (error.status == 422) {
            this.errorEntity.email =
              error.error.message.EmailAddress != ''
                ? error.error.message.EmailAddress
                : '';
          } else if (error.status == 404) {
            Swal.fire({
              type: this.globals.configurationText.sweetAlertTypeIconError,
              icon: this.globals.configurationText.sweetAlertTypeIconError,
              customClass: this.globals.configurationText.sweetAlertClassError,
              toast: true,
              title: error.error.message,
              animation: true,
              position: this.globals.configurationText.sweetAlertPosition,
              showConfirmButton: false,
              timer: this.globals.configurationText.sweetAlertTimer,
              timerProgressBar: true,
              showCancelButton: true,
              cancelButtonText: 'x',
            });
          } else if (error.status == 401) {
            Swal.fire({
              title: this.globals.commonTranslationText.loginPage.alerts
                .commonMessage.title,
              text: error.error.message,
              icon: 'warning',
              type: this.globals.commonTranslationText.loginPage.alerts
                .commonMessage.type,
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonClass: 'theme_btn',
              confirmButtonText: 'Resend',
              cancelButtonClass: 'cancel_btn',
              cancelButtonText: 'Cancel',
            }).then((result) => {
              if (result.value) {
                if (error.error.data.user_email_mobile_pending == 1) {
                  var entity = {
                    user_id: error.error.data.user_id,
                  };
                  this.AuthService.resendEmailVerification(entity).then(
                    (data) => {
                      this.globals.isLoading = false;
                      console.log(data);
                      this.cookieService.set('successMessage', this.globals.commonTranslationText.loginPage.alerts.activeEmailResend.text, this.globals.cookieMessageExpireTime,'/');
                      window.location.href = '/login';
                    },
                    (error) => {
                      console.log(error.error.code);
                      this.globals.isLoading = false;
                    }
                  );
                } else {
                  this.router.navigate([
                    '/verifyOTP/' +
                      window.btoa(error.error.data.user_id) +
                      '/' +
                      window.btoa(error.error.data.phone),
                  ]);
                }
                this.globals.isLoading = true;
              }
            });
          } else {
            // this.globals.pageNotfound(error.error.code);
          }
          this.globals.isLoading = false;
          this.btn_disable = false;
          this.submitted = false;
        }
      );
    }
  }
  //   login forgot reset
  // country state category
}
