import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Globals } from '.././globals';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { PasswordService } from '../services/password.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie-service';
declare var $, Swal: any;

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ResetPasswordComponent implements OnInit {

  passwordEntity;
  resetPasswordLinkEntity;
  submitted;
  btn_disable;
  newconfsame;
  UserDetail;
  errorEntity;
  type;
  token;
  constructor(public globals: Globals, private router: Router, private route: ActivatedRoute, private PasswordService: PasswordService,
    private cookieService: CookieService) { }

  ngOnInit(): void {


    // const body = document.querySelector('body');
    // var count = $(window).height();
    // body.style.setProperty('--screen-height', count + "px");

    $("#newpassword-show").click(function () {
      $(this).toggleClass("fa-eye-slash fa-eye")
      if ($("#newpassword").attr("type") == "password") {
        $("#newpassword").attr("type", "text");

      }
      else {
        $("#newpassword").attr("type", "password");
      }
    });
    $("#confpassword-show").click(function () {
      $(this).toggleClass("fa-eye-slash fa-eye")
      if ($("#confpassword").attr("type") == "password") {
        $("#confpassword").attr("type", "text");

      }
      else {
        $("#confpassword").attr("type", "password");
      }
    });


    this.passwordEntity = {};
    this.resetPasswordLinkEntity = {};
    this.errorEntity = {};
    // this.globals.isLoading = true;
    //debugger
    this.type = this.route.snapshot.paramMap.get('type');
    this.token = this.route.snapshot.paramMap.get('token');
    console.log(this.type);
    console.log(this.token);
    // try {
    //   let id = this.route.snapshot.paramMap.get('id');
    //   this.UserDetail = new JwtHelperService().decodeToken(id);
    //   this.resetPasswordLinkEntity.UserId = this.UserDetail.UserId;
    //   this.resetPasswordLinkEntity.ForgotPasswordCode = this.UserDetail.ForgotPasswordCode;

    //   this.PasswordService.checkResetPasswordLink(this.resetPasswordLinkEntity)
    //     .then((data) => {
    //       this.globals.isLoading = false;
    //     },
    //       (error) => {
    //         if (error.status == 404) {
    //           swal({
    //             type: this.globals.commonTranslationText.resetPasswordPage.alerts.invalidLink.type,
    //             title: this.globals.commonTranslationText.resetPasswordPage.alerts.invalidLink.title,
    //             text: this.globals.commonTranslationText.resetPasswordPage.alerts.invalidLink.text,
    //           })
    //         }
    //         this.router.navigate(['/login']);
    //         this.globals.isLoading = false;
    //         this.btn_disable = false;
    //         this.submitted = false;
    //       });
    // } catch (exception) {
    //   this.globals.isLoading = false;
    //   swal({
    //     type: this.globals.commonTranslationText.resetPasswordPage.alerts.invalidLink.type,
    //     title: this.globals.commonTranslationText.resetPasswordPage.alerts.invalidLink.title,
    //     text: this.globals.commonTranslationText.resetPasswordPage.alerts.invalidLink.text,
    //   })
    //   this.router.navigate(['/login']);
    // }
  }

  resetPassword(resetPasswordForm) {
    //debugger
    this.submitted = true;

    if (resetPasswordForm.valid && !this.newconfsame) {

      this.globals.isLoading = true;
      this.btn_disable = true;

      if(this.type == this.globals.configurationText.resetPassword.email)
      {
        this.passwordEntity.password = this.passwordEntity.Password;
        this.passwordEntity.password_confirmation = this.passwordEntity.ConfirmPassword;
        this.passwordEntity.LoginURL = 'login';
        this.passwordEntity.ForgotPasswordURL = 'forgotPassword';
        this.passwordEntity.type = this.type;
        this.passwordEntity.token = this.token;
      }
      else{
        this.passwordEntity.password = this.passwordEntity.Password;
        this.passwordEntity.password_confirmation = this.passwordEntity.ConfirmPassword;
        this.passwordEntity.LoginURL = 'login';
        this.passwordEntity.ForgotPasswordURL = 'forgotPassword';
        this.passwordEntity.type = this.type;
        this.passwordEntity.user_id = window.atob(this.token);
      }
      //debugger
      console.log(this.passwordEntity);
      this.PasswordService.resetPassword(this.passwordEntity)
        .then((data) => {
          this.globals.isLoading = false;
          this.cookieService.set('successMessage', this.globals.commonTranslationText.resetPasswordPage.alerts.success.text, this.globals.cookieMessageExpireTime,'/');
          window.location.href = '/login';
          // alert("Successfully reset");
        },
          (error) => {
            if (error.error.code == 422) {
              this.errorEntity.Password = (error.error.message.Password != "") ? error.error.message.Password : '';
              this.errorEntity.ConfirmPassword = (error.error.message.ConfirmPassword != "") ? error.error.message.ConfirmPassword : '';
            }
            else {
              this.globals.pageNotfound(error.error.code);
            }

            this.globals.isLoading = false;
            this.btn_disable = false;
            this.submitted = false;
          });
    }
  }

  confirmPassword() {
    if (this.passwordEntity.ConfirmPassword != this.passwordEntity.Password) {
      this.newconfsame = true;
    } else {
      this.newconfsame = false;
    }
  }
}

