import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Router,ActivatedRoute } from '@angular/router';
import { Globals } from '.././globals';
import { CookieService } from 'ngx-cookie-service';
declare var $: any,Swal;

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    public globals: Globals, private route: ActivatedRoute,
    private cookieService: CookieService
  ) {}

  canActivate(route, state: RouterStateSnapshot) {
    this.globals.isLoading = false;
    let permission = this.globals.checkRoutePermission();
    //debugger;
    setTimeout(function () {
      if ($('.sidebar_wrap').hasClass('active_menu')) {
        $('.sidebar_wrap').addClass('active_menu');
        $('header').addClass('active_menu_right_block');
        $('.content_block').addClass('active_menu_right_block');
        $('footer').addClass('active_menu_right_block');
      }
      $('.sidebar_wrap').on('mouseleave', function () {
        if ($('.sidebar_scroll')[0]) {
          $('.sidebar_wrap.sidebar_scroll .sidebar_box').animate(
            { scrollTop: 0 },
            'slow'
          );
          $('header').addClass('admin_small_right_block');
          $('.content_block').addClass('admin_small_right_block');
          $('footer').addClass('admin_small_right_block');
        }
      });

      if ($(window).width() < 768) {
        $('.sidebar_wrap').removeClass('active_menu');
        $('.sidebar_wrap').removeClass('sidebar_scroll');

        $('.sidebar_wrap .sidebar_box .has_click').click(function () {
          $('.sidebar_wrap').removeClass('active_menu');
          $('.sidebar_wrap').removeClass('sidebar_scroll');
          $('.mobile_toggle').removeClass('close_toggle');
        });
      }
      // $("html, body").animate({
      //   scrollTop: 0
      // }, "slow");

      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 500);

    var d1 = new Date(),
    d2 = new Date(d1);
    d2.setMinutes(d1.getMinutes() + this.globals.configurationText.cookieMessageExpireTime);
    if (
      this.authService.isLoggedIn() == true &&
      permission == undefined &&
      route.data['permission'] != undefined
    ) {
      this.globals.checkPermission().then(
        (data) => {
          let retrievedObject1 = JSON.parse(JSON.stringify(data));
          this.globals.permissions = JSON.stringify(data);
          let index = retrievedObject1.findIndex(
            (retrievedObject1) =>
              retrievedObject1.slug === route.data['permission'] &&
              retrievedObject1.has_access == 1
          );
          if (index == -1) {
            return this.router.navigate([
              '/pagenotfound/' + window.btoa('403'),
            ]);
          }
        },
        (error) => {
          this.globals.isLoading = false;
          this.globals.pageNotfound(error.error.code);
        }
      );
    } else if (
      this.authService.isLoggedIn() == true &&
      route.data['permission'] != undefined &&
      permission[route.data['permission']]==''
    ) {
      return this.router.navigate(['/pagenotfound/' + window.btoa('403')]);
    }

    if (state.url.split('/')[3] != undefined) {
      this.globals.currentLink =
        '/' +
        state.url.split('/')[1] +
        '/' +
        state.url.split('/')[2] +
        '/' +
        state.url.split('/')[3];
      this.globals.currentModule = state.url.split('/')[1];
      this.globals.currentLinkURL = '/' + state.url.split('/')[1];

    } else if (state.url.split('/')[2] != undefined) {
      this.globals.currentLink =
        '/' + state.url.split('/')[1] + '/' + state.url.split('/')[2];
      this.globals.currentModule = state.url.split('/')[1];
      this.globals.currentLinkURL = '/' + state.url.split('/')[1];
    } else {
      this.globals.currentLink = '/' + state.url.split('/')[1];
      this.globals.currentLinkURL = '/' + state.url.split('/')[1];
    }
    // setTimeout(() => {
    //   this.globals.getImageVideoExtensions();
    // }, 500);
     
    
    // console.log(this.globals.currentLink);
    if (this.authService.isLoggedIn() == true) {
      if (
        state.url == '/login' ||
        state.url.split('/')[1] == 'login' ||
        state.url == '/forgot-password' ||
        state.url == '/register' ||
        state.url.split('/')[1] == 'register' ||
        state.url.split('/')[1] == 'reset-password'
      ) {
        console.log(state.url.split('/')[2]);
        if(state.url.split('/')[2] == 'profile-email-verify')
        {
          console.log(route.params.email);
          let type = route.params.type;
          let token = route.params.token;
          let email = route.params.email;
          if ((type && token) || (type && token && email) ) {
            this.globals.isLoading = true;
            var entity:any;
            var verifyEmailAPILink;
            if(type=='profile-email-verify')
            {
              entity = {
                'id': token,
                'email': email
              }
              verifyEmailAPILink = 'verifyEmail';
            }
            else
            {
              entity = {
                'type': type,
                'token': token
              }
              verifyEmailAPILink = 'login/user-action';
            }
            this.authService.UserInviteAction(entity,verifyEmailAPILink)
              .then((data) => {
                this.globals.isLoading = false;
                
                this.cookieService.set('successMessage', 'Your email has been verified successfully', d2,'/');
                console.log(data);
                localStorage.setItem('email_available',"1");
                console.log(this.cookieService.get('URL'));
                if(this.cookieService.check('URL'))
                {
                  window.location.href = this.cookieService.get('URL');
                  this.cookieService.delete('URL');
                }
                else{
                  window.location.href = '/userDashboard';
                }
                
              },
                (error) => {
                  this.globals.isLoading = false;
                  console.log(error);
                });
          }
        }
        else
        { //debugger
          if (this.globals.authData.roles_id == 1)
          // window.location.href = '/admin/adminDashboard';
          {window.location.href = '/admin/adminDashboard';}
          else if (this.globals.authData.roles_id == 2)
          {
            if(localStorage.getItem('email_available') == "0" && localStorage.getItem('username_available') == "0" )
            {
              this.cookieService.set('errorMessage', this.globals.commonTranslationText.loginPage.alerts.emailUsernameCompulsory.text, d2,'/');
              window.location.href = '/profile';
            }
            else if(localStorage.getItem('email_available') == "0")
            {
              this.cookieService.set('errorMessage', this.globals.commonTranslationText.loginPage.alerts.emailCompulsory.text, d2,'/');
              window.location.href = '/profile';
            }
            else if(localStorage.getItem('username_available') == "0")
            {
              this.cookieService.set('errorMessage', this.globals.commonTranslationText.loginPage.alerts.usernameCompulsory.text, d2,'/');
              window.location.href = '/profile';
            }
            else
            {
              window.location.href = '/userDashboard';
            }
            
          }
        }
        this.globals.IsLoggedIn = true;
        

        return false;
      } else {
        if(localStorage.getItem('email_available') == "0" && localStorage.getItem('username_available') == "0" )
            {
              if(state.url == '/profile')
              {
                this.globals.IsLoggedIn = true;
              }
              else if(state.url.split('/')[1] == 'verifyOTP')
              {
                this.globals.IsLoggedIn = true;
              }
              else
              {
                this.cookieService.set('errorMessage', this.globals.commonTranslationText.loginPage.alerts.emailUsernameCompulsory.text, d2,'/');
                window.location.href = '/profile';
              }
            }
            else if(localStorage.getItem('email_available') == "0")
            {
              if(state.url == '/profile')
              {
                this.globals.IsLoggedIn = true;
              }
              else if(state.url.split('/')[1] == 'verifyOTP')
              {
                this.globals.IsLoggedIn = true;
              }
              else
              {
                this.cookieService.set('errorMessage', this.globals.commonTranslationText.loginPage.alerts.emailCompulsory.text, d2,'/');
                window.location.href = '/profile';
              }
            }
            else if(localStorage.getItem('username_available') == "0")
            {
              if(state.url == '/profile')
              {
                this.globals.IsLoggedIn = true;
              }
              else if(state.url.split('/')[1] == 'verifyOTP')
              {
                this.globals.IsLoggedIn = true;
              }
              else
              {
                this.cookieService.set('errorMessage', this.globals.commonTranslationText.loginPage.alerts.usernameCompulsory.text, d2,'/');
                window.location.href = '/profile';
              }
            }
            else
            {
              this.globals.IsLoggedIn = true;
            }
        return true;
      }
    } else {
      //debugger;

      if (
        state.url == '/home' ||
        state.url == '/verifyOTP' ||
        state.url.split('/')[1] == 'verifyOTP' ||
        state.url.split('/')[1] == 'home' ||
        state.url == '/login' ||
        state.url.split('/')[1] == 'login' ||
        state.url == '/forgotPassword' ||
        state.url == '/register' ||
        state.url.split('/')[1] == 'register' ||
        state.url.split('/')[1] == 'resetPassword' ||
        state.url.split('/')[1] == 'questionDetails' ||
        state.url.split('/')[1] == 'communityQuestions' ||
        state.url.split('/')[1] == 'decideItStars' ||
        state.url.split('/')[1] == 'topQuestion' ||
        state.url.split('/')[1] == 'communityList'
      ) {
        this.globals.IsLoggedIn = false;
        return true;
      } else {
        this.globals.IsLoggedIn = false;
        this.router.navigate(['/home']);
        return true;
      }
    }
  }
}
