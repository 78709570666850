<div class="container-fluid login_register_bg fixed-head">
  <div class="loginregister_white_block">
    <div class="login_logo_block">
      <div class="text-center">
        <a routerLink="/login">
          <img src="assets/images/forgotpassword.png" width="400" class="img-fluid" alt="">
        </a>
      </div>
    </div>
        <div class="white_login_block">
          <div class="row d-flex align-items-center">
            <div class="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12">
            </div>
            <div class="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div class="padding_login_block">
                <div class="login-head">
                <h3>Forgot Password?</h3>
              </div>
              <p class="padding_15">Enter your Registered Email Address:</p>
              <div class="clearfix"></div>
                <form #forgotPasswordForm="ngForm" (ngSubmit)="requestResetPassword(forgotPasswordForm)">
                 
                  <div class="form-group mb-5" *ngIf="showEmailBlock">
                    <label><span class="mendatory_feild">*</span> {{globals.commonTranslationText.forgotPasswordPage.form.emailAddress.label}}
                    </label>
                    <input kendoTextBox type="email" class="form-control"  pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}" #EmailAddress="ngModel" 
                      [(ngModel)]="forgotPasswordEntity.EmailAddress" name="EmailAddress" maxlength="100"
                      [ngClass]="{error: (EmailAddress.dirty && EmailAddress.invalid) || (EmailAddress.pristine && submitted)}"
                      required />
                    <span class="error_class"
                      *ngIf="(EmailAddress.dirty && EmailAddress.invalid) || (EmailAddress.pristine && submitted)">
                      <span *ngIf="EmailAddress.errors && EmailAddress.errors.required">
                        {{globals.commonTranslationText.forgotPasswordPage.form.emailAddress.required}}
                      </span>
                      <span *ngIf="EmailAddress.errors && EmailAddress.errors.pattern">
                        {{globals.commonTranslationText.forgotPasswordPage.form.emailAddress.pattern}}
                      </span>
                    </span>
                    <span class="error_class">
                      <span>{{ errorEntity.email }}</span>
                    </span>
                  </div>
                  <div class="login_button_block pt-0">
                    <button kendoButton type="submit"
                      class="all_btn theme_btn float-end">{{globals.commonTranslationText.forgotPasswordPage.buttons.submit}}</button>
                    <a routerLink="/login"
                      class="float-start">{{globals.commonTranslationText.forgotPasswordPage.buttons.signInPage}}</a>
                    <div class="clearfix"></div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
  </div>
</div>