<div class="home_wrap community_question question_detail pt_50 fixed-head" id="question_detail_page">
  <div class="container-fluid">
    <div class="row pb-5">
      <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 m-auto">
        <div class="community_question_wrap top_question_wrap que_detail_left">
          <!-- <div class="community_info_wrap">
                      <div class="community_info_bottom">
                          <div class="community_photo">
                              <img src="assets/images/top_communities/lamp.png" alt="" class="img-fluid">
                          </div>
                          <div class="community_name">
                              <strong>Technology</strong>
                              <img src="assets/images/favicon.png" alt="" class="img-fluid">
                              <span>/ technology</span>
                          </div>
                          <div class="clearfix"></div>
                      </div>
                  </div> -->
          <!-- <div class="sharethis-inline-share-buttons"></div> -->

          <div class="questions_detals_wrap">
            <div *ngIf="!questionDetailLoading1">
              <div class="que_post_details">
                <span class="posted_by"> Posted by: </span>
                <span class="posted_by_name">
                  <a
                    (click)="
                      publicProfile(
                        questionDetailEntity.UserId,
                        questionDetailEntity.send_anonymously
                      )
                    "
                    >{{ questionDetailEntity.display_name }}</a
                  ></span
                >
                <span class="time_duration">
                  {{ questionDetailEntity.posted_time }}
                </span>
                <!-- <span class="time_duration expires_date">
                                  <strong>{{questionDetailEntity.expire_title}}</strong>
                                  {{questionDetailEntity.Expire_time}} </span> -->
              </div>
              <div class="question_line mt-3">
                <!-- <p>{{questionDetailEntity.question_text}}</p> -->
                <div
                  class="editor_data mb-4"
                  [innerHtml]="questionDetailEntity.question_text"
                ></div>
                <!-- <div> -->

                <div class="quebox_media" *ngIf="questionDetailEntity.filedata?.length>0">
                  <div
                    [attr.id]="'drf' + questionDetailEntity.id"
                    class="carousel slide"
                    data-bs-ride="carousel"
                    data-bs-interval="false"
                  >
                    <div
                      class="carousel-indicators"
                      *ngIf="questionDetailEntity.filedata?.length > 1"
                    >
                      <button
                        type="button"
                        [attr.data-bs-target]="'#drf' + questionDetailEntity.id"
                        [attr.data-bs-slide-to]="i"
                        [attr.aria-label]="'Slide ' + i"
                        [ngClass]="{ active: i == 0 }"
                        *ngFor="
                          let list of questionDetailEntity.filedata;
                          let i = index
                        "
                        [attr.aria-current]="i == 0"
                      ></button>
                    </div>
                    <div class="carousel-inner">
                      <div
                        class="carousel-item"
                        [ngClass]="{ active: i == 0 }"
                        *ngFor="
                          let list of questionDetailEntity.filedata;
                          let i = index
                        "
                      >
                        <img
                          alt=""
                          class="d-block w-100"
                          *ngIf="list.type == 'img'"
                          src="{{ globals.profileImgeapiUrl }}/question/{{
                            questionDetailEntity.id
                          }}/{{ list.name }}"
                        />
                        <div
                          class="ratio ratio-16x9"
                          *ngIf="list.type == 'video'"
                        >
                          <video controls>
                            <source
                              src="{{ globals.profileImgeapiUrl }}/question/{{
                                questionDetailEntity.id
                              }}/{{ list.name }}"
                            />
                          </video>
                        </div>
                      </div>
                    </div>
                    <button
                      class="carousel-control-prev"
                      type="button"
                      [attr.data-bs-target]="'#drf' + questionDetailEntity.id"
                      data-bs-slide="prev"
                      *ngIf="questionDetailEntity.filedata.length > 1"
                    >
                      <span
                        class="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span class="visually-hidden">Previous</span>
                    </button>
                    <button
                      class="carousel-control-next"
                      type="button"
                      [attr.data-bs-target]="'#drf' + questionDetailEntity.id"
                      data-bs-slide="next"
                      *ngIf="questionDetailEntity.filedata.length > 1"
                    >
                      <span
                        class="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                      <span class="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>
                <!-- </div> -->
                <!-- <img *ngIf="questionDetailEntity.file_extention != '' && (questionDetailEntity.file_extention == 'jpg' || questionDetailEntity.file_extention == 'png' || questionDetailEntity.file_extention == 'jpeg' || questionDetailEntity.file_extention == 'JPG' || questionDetailEntity.file_extention == 'PNG' || questionDetailEntity.file_extention == 'JPEG')"
                                  src="{{globals.profileImgeapiUrl}}/question/{{questionDetailEntity.id}}/{{questionDetailEntity.image_video_url}}"
                                  alt="" class="img-fluid" />
                              <div class="embed-responsive embed-responsive-16by9 mb-5"
                                  *ngIf="questionDetailEntity.file_extention != '' && (questionDetailEntity.file_extention == 'mp4' || questionDetailEntity.file_extention == 'MP4' || questionDetailEntity.file_extention == 'MKV' || questionDetailEntity.file_extention == 'mkv' || questionDetailEntity.file_extention == 'WEBM' || questionDetailEntity.file_extention == 'webm')">
                                  <video id="video_tab" width="400" controls>
                                      <source id="video"
                                          src="{{globals.profileImgeapiUrl}}/question/{{questionDetailEntity.id}}/{{questionDetailEntity.image_video_url}}">
                                  </video>
                              </div> -->
              </div>
              <div class="questions_legends mt-3">
                <ul class="queicon_lists float-start">
                  <li
                    class="like_question"
                    *ngIf="
                      globals.authData == undefined ||
                      questionDetailEntity.is_like == '' ||
                        questionDetailEntity.is_like == null
                    "
                  >
                    <a
                      onclick="event.stopPropagation();"
                      (click)="
                        likeDislikeQuestionAnswer(questionDetailEntity, 1)
                      " id="thumb{{questionDetailEntity.id}}"
                      ><i class="fa-regular fa-thumbs-up"></i
                    ></a>
                    <span>{{ questionDetailEntity.likesCount }}</span>
                  </li>
                  <li
                    class="like_question"
                    *ngIf="
                      questionDetailEntity.is_like != '' &&
                      questionDetailEntity.is_like != null
                    "
                  >
                    <a
                      onclick="event.stopPropagation();"
                      (click)="
                        likeDislikeQuestionAnswer(questionDetailEntity, 0)
                      " id="thumb{{questionDetailEntity.id}}"
                      ><i class="fa-solid fa-thumbs-up"></i
                    ></a>
                    <span>{{ questionDetailEntity.likesCount }}</span>
                  </li>
                  <li>
                    <i class="fa-regular fa-comment-dots"></i>
                    {{ questionDetailEntity.commentsCount }}
                  </li>
                  <li>
                    <i class="fa-regular fa-eye"></i>
                    {{ questionDetailEntity.views }}
                  </li>

                  <!-- <li *ngIf="questionDetailEntity.community != null"><a
                                          (click)="communityQuestion(communityDetailEntity.id)"><i
                                              class="fa-solid fa-bullhorn"></i> {{questionDetailEntity.community}}</a></li> -->
                  <!-- <li
                    *ngIf="questionDetailEntity.community.length > 0"
                    class="community_lists"
                  >
                    <i class="fa-solid fa-bullhorn"></i>
                    <ng-container
                      *ngFor="let community of questionDetailEntity.community"
                    >
                      <a (click)="communityQuestion(community.id)">{{
                        community.name
                      }}</a>
                    </ng-container>
                  </li> -->

                  <!--  *ngIf="questionDetailEntity.invitee_can_invite_others != 0" -->
                  <!-- <li><a class="report" (click)="openInvitePopUp()"><i
                                              class="fa-solid fa-share"></i> Share</a></li> -->
                </ul>
                <!-- <a class="float-right mt-3 ml-4 invitation_link" *ngIf="globals.authData != undefined"
                                  (click)="openInvitePopUp()"> Invitations</a> -->

                <ul class="queicon_lists float-end">
                  <!-- <li>
                                      <a class="invitation_link" *ngIf="globals.authData != undefined"
                                          onclick='event.stopPropagation();' (click)="openInvitePopUp()"><i class="fa-solid fa-user-plus"></i> Invitations</a>
                                  </li>

                                  <li *ngIf="questionDetailEntity.community.length > 0">
                                      <a class="socialshare_link" (click)="sharebtn()"><i class="fa-solid fa-share-nodes"></i>
                                          Share</a>

                                      <div class="share_options top">
                                          <share-buttons [theme]="'material-light'"
                                              [include]="['facebook','twitter','linkedin']" [show]="9" [size]="1"
                                              [url]="'currentLink'"
                                              [image]="'https://discussionboard.uatbyopeneyes.com/assets/images/dummy.jpg'"
                                              [autoSetMeta]="true">
                                          </share-buttons>
                                      </div>

                                  </li> -->
                  <li
                    *ngIf="
                      globals.authData == undefined ||
                      (questionDetailEntity.created_by != globals.authData.id &&
                        questionDetailEntity.user_id == null &&
                          globals.reportQuestionAnswerEntity.question_id !=
                            questionDetailEntity.id)
                    "
                  >
                    <a
                      onclick="event.stopPropagation();"
                      (click)="
                        globals.reportPopUpOpen(
                          questionDetailEntity.id,
                          '',
                          questionDetailEntity.user_name
                        )
                      "
                      ><i class="fa-solid fa-flag"></i> Report</a
                    >
                  </li>
                  <li
                    *ngIf="
                      (questionDetailEntity.user_id != '' &&
                        questionDetailEntity.user_id != null) ||
                      (globals.reportQuestionAnswerEntity.reportedTime != '' &&
                        globals.reportQuestionAnswerEntity.reportedTime !=
                          undefined &&
                        globals.reportQuestionAnswerEntity.invitee_answer_id ==
                          '')
                    "
                  >
                    <a
                      onclick="event.stopPropagation();"
                      class="report_question_answer"
                      *ngIf="
                        globals.reportQuestionAnswerEntity.reportedTime != '' &&
                        globals.reportQuestionAnswerEntity.reportedTime !=
                          undefined &&
                        globals.reportQuestionAnswerEntity.invitee_answer_id ==
                          ''
                      "
                      title="{{
                        globals.commonTranslationText.questionDetail
                          .reportQuestionTooltip
                      }} {{
                        globals.reportQuestionAnswerEntity.reportedTime
                          | date : 'MMM d, y h:mm a'
                      }}"
                      ><i class="fa-solid fa-flag"></i> Reported
                    </a>
                    <a
                      onclick="event.stopPropagation();"
                      class="report_question_answer"
                      *ngIf="
                        globals.reportQuestionAnswerEntity.reportedTime == '' ||
                        globals.reportQuestionAnswerEntity.reportedTime ==
                          undefined ||
                        globals.reportQuestionAnswerEntity.invitee_answer_id !=
                          ''
                      "
                      title="{{
                        globals.commonTranslationText.questionDetail
                          .reportQuestionTooltip
                      }} {{
                        questionDetailEntity.user_id.created_at
                          | date : 'MMM d, y h:mm a'
                      }}"
                      ><i class="fa-solid fa-flag"></i> Reported
                    </a>
                  </li>
                </ul>
                <div class="clearfix"></div>
              </div>
              <div class="clearfix"></div>
              <div class="comment_editor mt-4 post_question_tabs">
                <div class="d-flex justify-content-between qd_info_tl">
                <label class="mb-2" *ngIf="globals.authData != undefined && postAnswerHide"
                  >Response as
                  <span>{{ globals.authData.first_name }} {{ globals.authData.last_name }}</span></label
                >
                <div class="position-relative" *ngIf="questionDetailEntity.question_type_id == globals.configurationText.questionType.expressYourself">
                  <span class="info_toggle" (click)="mediaInfoOpen()"><i class="fa-solid fa-circle-info"></i> Upload Media Note</span>
                </div>
                </div>
                <div class="clearfix"></div>
                <!-- <label *ngIf="globals.authData != undefined"
                  >Response as
                  <span>{{ globals.authData.first_name }} {{ globals.authData.last_name }}</span></label
                > -->
                <!-- <form *ngIf="postAnswerHide" #postAnswerForm="ngForm"> -->
                <form *ngIf="postAnswerHide" #postAnswerForm="ngForm" class="mb-5">
                  <div class="position-relative"
                    *ngIf="
                      questionDetailEntity.question_type_id ==
                      globals.configurationText.questionType.pickList
                    "
                  >
                    <div
                      class="radio_box"
                      *ngFor="
                        let options of questionDetailEntity.options;
                        let i = index
                      "
                      (change)="
                          answerSelect(
                            options,
                            i,
                            questionDetailEntity.question_type_id,
                            0
                          )
                        "
                    >
                      <input
                        type="radio"
                        name="user_option_id"
                        id="user_option_id{{ i }}"
                        [value]="options.id"
                        (change)="handleChange()"
                      />
                      <label for="user_option_id{{ i }}">{{
                        options.value
                      }}</label>
                    </div>
                    <span
                        class="error_class"
                        >{{errorMessage}}</span
                      >
                  </div>
                  <div class="position-relative"
                    *ngIf="
                      questionDetailEntity.question_type_id ==
                      globals.configurationText.questionType.multiPickList
                    "
                  >
                    <div
                      class="check_box"
                      *ngFor="
                        let options of questionDetailEntity.options;
                        let i = index
                      "
                    >
                      <input
                        type="checkbox"
                        name="user_option_id{{ i }}"
                        id="user_option_id{{ i }}"
                        (change)="
                          answerSelect(
                            options,
                            i,
                            questionDetailEntity.question_type_id,
                            0
                          )
                        "
                        [value]="options.id"
                      />
                      <label
                        for="user_option_id{{ i }}"
                        class="checkboxbtn_label"
                        >{{ options.value }}</label
                      >
                    </div>
                    <span
                        class="error_class"
                        >{{errorMessage}}</span
                      >
                  </div>
                  <div 
                    class="activeyes_box position-relative"
                    *ngIf="
                      questionDetailEntity.question_type_id ==
                      globals.configurationText.questionType.yesOrNo
                    "
                  >
                    <div class="radio_box" id="radio1">
                      <input
                        type="radio"
                        value="1"
                        #is_true="ngModel"
                        [(ngModel)]="postAnswerEntity.is_true"
                        (change)="handleChange()"
                        name="is_true"
                        id="is_true1"
                      />
                      <label for="is_true1">Yes</label>
                    </div>
                    <div class="radio_box" id="radio2">
                      <input
                        type="radio"
                        value="0"
                        #is_true="ngModel"
                        [(ngModel)]="postAnswerEntity.is_true"
                        (change)="handleChange()"
                        name="is_true"
                        id="is_true2"
                      />
                      <label for="is_true2">No</label>
                    </div>
                    <span
                        class="error_class"
                        >{{errorMessage}}</span
                      >
                  </div>
                  <div
                    *ngIf="
                      questionDetailEntity.question_type_id ==
                      globals.configurationText.questionType.rankedList
                    "
                  >
                    <!-- <div class="container-fluid">
                                          <div class="row"> -->
                    <div
                      class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 team"
                    >
                      <kendo-sortable
                        [kendoSortableBinding]="questionDetailEntity.options"
                        zone="twoWay"
                        emptyText="Move employees from Team B to Team A."
                        class="row"
                        itemClass="employee"
                        [emptyItemStyle]="{
                          'min-height': '150px',
                          width: '10%'
                        }"
                        emptyItemClass="empty"
                        activeItemClass="employee active"
                      >
                        <ng-template let-item="item">
                          {{ item.value }}
                        </ng-template>
                      </kendo-sortable>
                    </div>
                    <!-- </div>
                                      </div> -->
                  </div>
                  <div
                    class="post_que_editor"
                    *ngIf="
                      questionDetailEntity.question_type_id ==
                      globals.configurationText.questionType.expressYourself
                    "
                  >
                    <!-- <textarea kendoTextArea id="answer_text" name="answer_text"
                                          #answer_text="ngModel" [(ngModel)]="postAnswerEntity.answer_text"
                                          class="form-control" rows="4" placeholder="Enter your answer"
                                          (keyup)="countChar(0)"></textarea> -->
                    <!-- <i class="fa-pencil fa-solid" aria-hidden="true"></i> -->
                    <!-- <div id="charNum"></div><span id="limit">1000/1000</span> -->
                    <form #postQuestionForm="ngForm">
                      <div class="post_que_editor">
                        <kendo-editor
                          [value]="value"
                          id="abc"
                          placeholder="Enter your Response"
                          name="answer_text"
                          required
                          (valueChange)="countChar(0)"
                          #answer_text="ngModel"
                          [(ngModel)]="postAnswerEntity.answer_text"
                        >
                          <kendo-toolbar>
                            <kendo-toolbar-buttongroup>
                              <kendo-toolbar-button
                                kendoEditorBoldButton
                              ></kendo-toolbar-button>
                              <kendo-toolbar-button
                                kendoEditorItalicButton
                              ></kendo-toolbar-button>
                              <kendo-toolbar-button
                                kendoEditorUnderlineButton
                              ></kendo-toolbar-button>
                              <kendo-toolbar-button
                                kendoEditorStrikethroughButton
                              ></kendo-toolbar-button>
                            </kendo-toolbar-buttongroup>
                            <kendo-toolbar-buttongroup>
                              <kendo-toolbar-button
                                kendoEditorInsertUnorderedListButton
                              ></kendo-toolbar-button>
                              <kendo-toolbar-button
                                kendoEditorInsertOrderedListButton
                              ></kendo-toolbar-button>
                            </kendo-toolbar-buttongroup>
                            <kendo-toolbar-buttongroup>
                              <kendo-toolbar-button
                                kendoEditorCreateLinkButton
                              ></kendo-toolbar-button>
                              <kendo-toolbar-button
                                kendoEditorUnlinkButton
                              ></kendo-toolbar-button>
                            </kendo-toolbar-buttongroup>
                            <kendo-toolbar-buttongroup>
                              <kendo-toolbar-button text="Upload Media"></kendo-toolbar-button>
                            </kendo-toolbar-buttongroup>
                          </kendo-toolbar>
                        </kendo-editor>
                        <!-- <span class="error_class">
                          <span>
                            {{
                              globals.commonTranslationText.home.form.question
                                .required
                            }}
                          </span>
                          <span *ngIf="errorEntity.question_text != ''">
                            {{ errorEntity.question_text }}
                          </span>
                        </span> -->
                        <!-- <i class="fa-pencil fa-sm fa-solid" aria-hidden="true"></i> -->
                        <div id="charNum" class="limitwitheditor"></div>
                        <span id="limit" class="limitwitheditor"
                          >1000/1000</span
                        >
                        <span
                        class="error_class"
                        >{{maxxxxxxLength}}</span
                      >
                        <span
                        class="error_class"
                        >{{errorMessage}}</span
                      >
                      </div>
                      <div class="supporting_video d-flex align-items-center d-none">
                        <div class="form-group file_upload mb-3">
                          <input type="text" disabled="" class="form-control" />
                          <input
                            type="file"
                            accept="image/*,video/*"
                            (change)="imageVideoChange($event)"
                            name="fileToUpload"
                            id="fileToUpload"
                            class="form-control"
                            multiple
                          />
                          <label>Supporting video or photo</label>
                        </div>
                      </div>
                      <div class="clearfix"></div>
                      <!-- Priview -->
                      <div
                        id="xyz"
                        class="col-xl-12 col-lg-12 col-md-12"
                        *ngIf="this.mydata.length > 0"
                      >
                        <div class="upload_preview">
                          <div
                            class="row row-cols-sm-3 row-cols-1 upload_media"
                          >
                            <div
                              class="col"
                              *ngFor="let list of mydata; let i = index"
                            >
                              <div class="media_prev">
                                <button class="remove-prev" type="button"><i class="fa-solid fa-xmark" (click)="removeImageVideo(i,list.name)" ></i></button>
                                <div
                                  class="media_prev_inner"
                                  *ngIf="list.type == 'img'"
                                >
                                  <img alt="" [src]="list.url" />
                                </div>
                                <!-- Progress Bar -->
                                <!-- <div
                                  class="upload_media_status"
                                  *ngIf="
                                    list.type == 'img' &&
                                    list.size < checkImgSizeKB
                                  "
                                >
                                  <div class="first-progress">
                                    <div
                                                      [ngStyle]="{ 'width.100%': list.FileProgress }"
                                                      class="second-progress"
                                                    ></div>
                                    <div class="second-progress"></div>
                                  </div>
                                  <div class="text-center d-none">
                                    {{ list.FileProgessSize }} off
                                    {{ list.FileSize }}
                                  </div>
                                </div> -->
                                <!-- Progress Bar -->
                                <div
                                id="error"
                                  *ngIf="
                                    list.type == 'img' &&
                                    list.size > checkImgSizeKB
                                  "
                                  class="upload_media_error"
                                >
                                  Image Size is Not Proper
                                </div>
                                <div
                                  class="media_prev_inner"
                                  *ngIf="list.type == 'video'"
                                >
                                  <div class="ratio ratio-16x9">
                                    <video controls>
                                      <source [src]="list.url" />
                                    </video>
                                  </div>
                                </div>
                                <!-- Progress Bar -->
                                <!-- <div
                                  class="upload_media_status"
                                  *ngIf="
                                    list.type == 'video' &&
                                    list.size < checkVideoKB
                                  "
                                >
                                  <div class="first-progress">
                                    <div
                                                      [ngStyle]="{ 'width.100%': list.FileProgress }"
                                                      class="second-progress"
                                                    ></div>
                                    <div class="second-progress"></div>
                                  </div>
                                  <div class="text-center d-none">
                                    {{ list.FileProgessSize }} off
                                    {{ list.FileSize }}
                                  </div>
                                </div> -->
                                <!-- Progress Bar -->
                                <div
                                id="error"
                                  *ngIf="
                                    list.type == 'video' &&
                                    list.size > checkVideoKB
                                  "
                                  class="upload_media_error"
                                >
                                  Video Size is Not Proper
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- <div
                                            id="sliderid"
                                            class="carousel slide"
                                            data-bs-ride="carousel"
                                            data-bs-interval="false"
                                          >
                                            <div
                                              class="carousel-indicators"
                                              *ngIf="fileUploadLength > 1"
                                            >
                                              <button
                                                type="button"
                                                data-bs-target="#sliderid"
                                                [attr.data-bs-slide-to]="i"
                                                [attr.aria-label]="'Slide ' + i"
                                                [ngClass]="{ active: i == 0 }"
                                                *ngFor="let list of mydata; let i = index"
                                                [attr.aria-current]="i == 0"
                                              ></button>
                                            </div>
                                            <div class="carousel-inner">
                                              <div
                                                class="carousel-item"
                                                [ngClass]="{ active: i == 0 }"
                                                *ngFor="let list of mydata; let i = index"
                                              >
                                                <img
                                                  alt=""
                                                  class="d-block w-100"
                                                  *ngIf="list.type == 'img'"
                                                  [src]="list.url"
                                                />
                                                <div
                                                  class="ratio ratio-16x9"
                                                  *ngIf="list.type == 'video'"
                                                >
                                                  <video controls>
                                                    <source [src]="list.url" />
                                                  </video>
                                                </div>
                                              </div>
                                            </div>
                                            <button
                                              *ngIf="fileUploadLength > 1"
                                              class="carousel-control-prev"
                                              type="button"
                                              data-bs-target="#sliderid"
                                              data-bs-slide="prev"
                                            >
                                              <span
                                                class="carousel-control-prev-icon"
                                                aria-hidden="true"
                                              ></span>
                                              <span class="visually-hidden">Previous</span>
                                            </button>
                                            <button
                                              *ngIf="fileUploadLength > 1"
                                              class="carousel-control-next"
                                              type="button"
                                              data-bs-target="#sliderid"
                                              data-bs-slide="next"
                                            >
                                              <span
                                                class="carousel-control-next-icon"
                                                aria-hidden="true"
                                              ></span>
                                              <span class="visually-hidden">Next</span>
                                            </button>
                                          </div> -->
                          <!-- <button class="theme_btn mt-4" (click)="uploadImage()">
                                            Upload Image
                                          </button> -->
                        </div>
                      </div>
                      <!-- Priview -->

                      <div class="clearfix"></div>
                    </form>
                  </div>
                </form>
                <div class="clearfix"></div>
                <!-- <kendo-editor [value]="value" style="height: 370px;"></kendo-editor>                             -->
                <p *ngIf="!postAnswerHide" id="btnshowdiscussion" class="mt-4 mb-3">
                  Your response has already been submitted.
                </p>
                <!-- <p *ngIf="questionCommentsEntity.length == 0" class="mt-4 mb-3">
                  No Response have been posted for this thread yet.
                </p> -->
                <!-- <input
                  *ngIf="questionCommentsEntity.length > 0"
                  class="mt-4 ps-0"
                  (click)="showComments()"
                  id="btnshowdiscussion"
                  type="button"
                  value="View Response by other users ▼"
                  name="btnshowdiscussion"
                /> -->

                <!-- *ngIf="postAnswerHide" -->
                <button
                  kendoButton
                  type="button"
                  *ngIf="postAnswerHide"
                  (click)="postAnswer()"
                  id="next_btn"
                  class="theme_btn me-0 float-end"
                >
                  Post Response
                </button>
                <div class="clearfix"></div>
              </div>
            </div>
            <div
              class="comment_discussion"
              *ngIf="
                !questionDetailLoading2 
              "
            >
              <!-- <div class="sort_by_filter">
                              <div class="sort_by_header float-start">
                                  <label>Sort by</label>
                              </div>
                              <div class="sorting_select float-end">
                                  <kendo-dropdownlist [data]="listsortby" [(ngModel)]="selectedValuesortby">
                                  </kendo-dropdownlist>
                              </div>
                              <div class="clearfix"></div>
                              <hr>
                          </div> -->
              <div
                class="discussion_block mt-5"
                id="showdiscussion"
                
              >
                <div class="responses_heading">
                  <h4 class="float-start mb-0">Responses</h4>
                  <!-- reported response checkbox -->
                  <!-- <div class="check_box float-end" *ngIf="globals.authData && globals.authData.roles_id == 2">
                    <input
                      type="checkbox"
                      name="Check"
                      [(ngModel)]="AnswerEntity.Check"
                      id="Check"
                      [checked]="AnswerEntity.Check"
                      #Check="ngModel"
                      (click)="CheckChange()"
                    />
                    <label for="Check" class="checkboxbtn_label">{{
                      globals.adminTranslationText.AnswerDetail
                        .ReportedAnswers
                    }}</label>
                  </div> -->
                  <div class="clearfix"></div>
                </div>
                <!-- <button class="theme_btn float-end">Start new Discussion</button>-->
                <div class="clearfix"></div>
                <p *ngIf="questionCommentsEntity.length == 0" class="mb-3 mt-5 text-center">
                  No Response have been posted for this thread yet.
                </p>
                <!-- <div class="write_discusion">
                                  <form>
                                      <div class="form-group">
                                          <label>Write your comment</label>
                                          <textarea kendoTextArea class="form-control" name="Comment" maxlength="500"
                                              required></textarea>
                                      </div>
                                      <div class="form-group">
                                          <button type="submit" class="theme_btn pull-right">Post</button>
                                          <button type="button" class="themeoption_btn pull-right">Cancel</button>
                                          <div class="clearfix"></div>
                                      </div>
                                  </form>
                              </div>
                              <div class="clearfix"></div> -->
                <ng-container
                  *ngIf="
                    questionDetailEntity.question_type_id ==
                      globals.configurationText.questionType.expressYourself ||
                    (globals.authData != undefined &&
                      globals.authData.id == questionCommentsEntity[0].user.id)
                  "
                >
                  <!-- <div
                    class="forum_box"
                    *ngFor="let comment of questionCommentsEntity"
                  >
                    <div class="img_icon">
                      <img
                        *ngIf="
                          comment.user.profile_image_url != '' &&
                          comment.user.profile_image_url != null
                        "
                        src="{{ globals.profileImgeapiUrl }}/{{
                          comment.user.profile_image_url
                        }}"
                        alt=""
                        class="img-responsive"
                      />
                      <img
                        *ngIf="
                          comment.user.profile_image_url == '' ||
                          comment.user.profile_image_url == null
                        "
                        src="assets/images/dummy.jpg"
                        alt=""
                        class="img-responsive"
                      />
                    </div>
                    <div class="content_name">
                      <!-- <div class="name" *ngIf="communityDetailEntity != ''"><a
                                                  (click)="publicProfile(comment.user.id)">{{comment.user.user_name}} </a>
                                          </div>
                                          <div class="name" *ngIf="communityDetailEntity == ''">
                                              {{comment.user.first_name}} {{comment.user.last_name}}</div> --
                      <div class="name">
                        <a
                          (click)="
                            publicProfile(
                              comment.user.id,
                              comment.send_anonymously
                            )
                          "
                          >{{ comment.first_name }} {{ comment.last_name }}</a
                        >
                      </div>
                      <div
                        class="editor_data mb-4 mt-1"
                        *ngIf="
                          questionDetailEntity.question_type_id ==
                          globals.configurationText.questionType.yesOrNo
                        "
                      >
                        <span *ngIf="comment.is_true == 1">Yes</span>
                        <span *ngIf="comment.is_true == 0">No</span>
                      </div>
                      <div
                        class="editor_data mb-4 mt-1"
                        *ngIf="
                          questionDetailEntity.question_type_id ==
                            globals.configurationText.questionType
                              .multiPickList ||
                          questionDetailEntity.question_type_id ==
                            globals.configurationText.questionType.pickList ||
                          questionDetailEntity.question_type_id ==
                            globals.configurationText.questionType.rankedList
                        "
                      >
                        {{ comment.user_options }}
                      </div>
                      <div
                        class="editor_data mb-4 mt-1"
                        *ngIf="
                          questionDetailEntity.question_type_id ==
                          globals.configurationText.questionType.expressYourself
                        "
                       [innerHtml]="comment.answer_text">
                        
                      </div>
                      <!-- image video Display --
                      <div class="quebox_media" *ngIf="comment.filedata.length > 0">
                        <div
                          [attr.id]="'abc'+comment.id"
                          class="carousel slide"
                          data-bs-ride="carousel"
                          data-bs-interval="false"
                          
                        >
                          <div
                            class="carousel-indicators"
                            *ngIf="comment.filedata.length > 1"
                          >
                            <button
                              type="button"
                              [attr.data-bs-target]="'#abc'+comment.id"
                              [attr.data-bs-slide-to]="i"
                              [attr.aria-label]="'Slide ' + i"
                              [ngClass]="{ active: i == 0 }"
                              *ngFor="let list of comment.filedata; let i = index"
                              [attr.aria-current]="i == 0"
                            ></button>
                          </div>
                          <div class="carousel-inner">
                            <div
                              class="carousel-item"
                              [ngClass]="{ active: i == 0 }"
                              *ngFor="let list of comment.filedata; let i = index"
                            >
                              <img
                                alt=""
                                class="d-block w-100"
                                *ngIf="list.type == 'img'"
                                src="{{ globals.profileImgeapiUrl }}/answerimg/{{
                                    comment.id
                                }}/{{ list.Images }}"
                              />
                              <div
                                class="ratio ratio-16x9"
                                *ngIf="list.type == 'video'"
                              >
                                <video controls>
                                  <source
                                    src="{{ globals.profileImgeapiUrl }}/answerimg/{{
                                        comment.id
                                    }}/{{ list.Images }}"
                                  />
                                </video>
                              </div>
                            </div>
                          </div>
                          <button
                            *ngIf="comment.filedata.length > 1"
                            class="carousel-control-prev"
                            type="button"
                            [attr.data-bs-target]="'#abc'+comment.id"
                            data-bs-slide="prev"
                          >
                            <span
                              class="carousel-control-prev-icon"
                              aria-hidden="true"
                            ></span>
                            <span class="visually-hidden">Previous</span>
                          </button>
                          <button
                            *ngIf="comment.filedata.length > 1"
                            class="carousel-control-next"
                            type="button"
                            [attr.data-bs-target]="'#abc'+comment.id"
                            data-bs-slide="next"
                          >
                            <span
                              class="carousel-control-next-icon"
                              aria-hidden="true"
                            ></span>
                            <span class="visually-hidden">Next</span>
                          </button>
                        </div>
                      </div>
                      <!-- image video Display --
                      <div
                        class="like_question ans-action"
                        *ngIf="
                          questionDetailEntity.question_type_id ==
                            globals.configurationText.questionType
                              .expressYourself &&
                          (globals.authData == undefined ||
                            comment.is_like == '' || comment.is_like == null)
                        "
                      >
                        <a
                          onclick="event.stopPropagation();"
                          (click)="likeDislikeAnswer(comment, 1)"
                          ><i class="fa-regular fa-thumbs-up"></i
                        ></a>
                        <span>{{ comment.likesCount }}</span>
                      </div>
                      <div
                        class="like_question ans-action"
                        *ngIf="
                          questionDetailEntity.question_type_id ==
                            globals.configurationText.questionType
                              .expressYourself &&
                          comment.is_like != '' && comment.is_like != null
                        "
                      >
                        <a
                          onclick="event.stopPropagation();"
                          (click)="likeDislikeAnswer(comment, 0)"
                          ><i class="fa-solid fa-thumbs-up"></i
                        ></a>
                        <span>{{ comment.likesCount }}</span>
                      </div>
                      <div class="reply_time ans-action">
                        <span class="float-start"
                          ><i class="fa-regular fa-clock"></i>
                          {{ comment.posted_time }}</span
                        >
                      </div>
                      <div
                        class="ans-action"
                        *ngIf="
                          questionDetailEntity.question_type_id ==
                          globals.configurationText.questionType.expressYourself
                        "
                      >
                        <ul class="queicon_lists float-start">
                          <li
                            *ngIf="
                              globals.authData == undefined ||
                              ((comment.user_id == '' ||
                                comment.user_id == null) &&
                                comment.user.id != globals.authData.id &&
                                globals.reportQuestionAnswerEntity
                                  .invitee_answer_id != comment.id)
                            "
                          >
                            <a
                              class="report"
                              onclick="event.stopPropagation();"
                              (click)="
                                globals.reportPopUpOpen(
                                  questionDetailEntity.id,
                                  comment.id,
                                  questionDetailEntity.user_name
                                )
                              "
                              ><i class="fa-solid fa-flag"></i> Report</a
                            >
                          </li>
                          <li
                            *ngIf="
                              (comment.user_id != '' &&
                                comment.user_id != null) ||
                              (globals.reportQuestionAnswerEntity
                                .reportedTime != '' &&
                                globals.reportQuestionAnswerEntity
                                  .reportedTime != undefined &&
                                globals.reportQuestionAnswerEntity
                                  .invitee_answer_id == comment.id &&
                                comment.user.id != globals.authData.id)
                            "
                          >
                            <a
                              onclick="event.stopPropagation();"
                              class="report_question_answer"
                              *ngIf="
                                globals.reportQuestionAnswerEntity
                                  .reportedTime != '' &&
                                globals.reportQuestionAnswerEntity
                                  .reportedTime != undefined &&
                                globals.reportQuestionAnswerEntity
                                  .invitee_answer_id == comment.id
                              "
                              title="{{
                                globals.commonTranslationText.questionDetail
                                  .reportAnswerTooltip
                              }} {{
                                globals.reportQuestionAnswerEntity.reportedTime
                                  | date : 'MMM d, y h:mm a'
                              }}"
                              ><i class="fa-solid fa-flag"></i> Reported
                            </a>
                            <a
                              onclick="event.stopPropagation();"
                              class="report_question_answer"
                              *ngIf="
                                globals.reportQuestionAnswerEntity
                                  .reportedTime == '' ||
                                globals.reportQuestionAnswerEntity
                                  .reportedTime == undefined ||
                                globals.reportQuestionAnswerEntity
                                  .invitee_answer_id != comment.id
                              "
                              title="{{
                                globals.commonTranslationText.questionDetail
                                  .reportAnswerTooltip
                              }} {{
                                comment.user_id.created_at
                                  | date : 'MMM d, y h:mm a'
                              }}"
                              ><i class="fa-solid fa-flag"></i> Reported
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div
                        class="ans-action"
                        *ngIf="
                          globals.authData != undefined &&
                          globals.authData.id == comment.user.id
                        "
                      >
                        <!-- <div class="float-start mx-4 edit_answer" (click)="editAnswer()">
                                              <i class="fa-pencil fa-sm fa-solid" aria-hidden="true"></i> Edit
                                          </div> --
                        <div class="clearfix"></div>
                        <div
                          class="edit_answer_expand mt-3"
                          id="editAnswer"
                          style="display: none"
                        >
                          <form>
                            <div
                              class="activeyes_box"
                              *ngIf="
                                questionDetailEntity.question_type_id ==
                                globals.configurationText.questionType.yesOrNo
                              "
                            >
                              <div class="radio_box" id="radio1">
                                <input
                                  type="radio"
                                  [checked]="comment.is_true == 1"
                                  value="{{ comment.is_true }}"
                                  name="is_true"
                                  id="is_true1"
                                />
                                <label for="is_true1">Yes</label>
                              </div>
                              <div class="radio_box" id="radio2">
                                <input
                                  [checked]="comment.is_true == 0"
                                  type="radio"
                                  value="{{ comment.is_true }}"
                                  name="is_true"
                                  id="is_true2"
                                />
                                <label for="is_true2">No</label>
                              </div>
                            </div>
                            <div
                              *ngIf="
                                questionDetailEntity.question_type_id ==
                                globals.configurationText.questionType.pickList
                              "
                            >
                              <!-- <div class="radio_box"
                                                          *ngFor="let options of questionDetailEntity.options; let i = index;">
                                                          <input type="radio"
                                                              [checked]="options.checked"
                                                              name="user_option_id" id="user_option_id{{i}}"
                                                              [value]="options.id">
                                                          <label for="user_option_id{{i}}">{{options.value}}</label>
                                                      </div> --
                              <div
                                class="radio_box"
                                *ngFor="
                                  let options of questionDetailEntity.options;
                                  let i = index
                                "
                              >
                                <input
                                  [checked]="options.checked"
                                  (change)="optionChange(i, options)"
                                  type="radio"
                                  name="user_option_id"
                                  id="user_option_id{{ i }}"
                                  [value]="options.id"
                                />
                                <label for="user_option_id{{ i }}">{{
                                  options.value
                                }}</label>
                              </div>
                            </div>
                            <div
                              *ngIf="
                                questionDetailEntity.question_type_id ==
                                globals.configurationText.questionType
                                  .multiPickList
                              "
                            >
                              <div
                                class="check_box"
                                *ngFor="
                                  let options of questionDetailEntity.options;
                                  let i = index
                                "
                              >
                                <input
                                  [checked]="options.checked"
                                  type="checkbox"
                                  name="user_option_id{{ i }}"
                                  id="user_option_id{{ i }}"
                                  (change)="
                                    answerSelect(
                                      options,
                                      i,
                                      questionDetailEntity.question_type_id,
                                      1
                                    )
                                  "
                                  [value]="options.id"
                                />
                                <label
                                  for="user_option_id{{ i }}"
                                  class="checkboxbtn_label"
                                  >{{ options.value }}</label
                                >
                              </div>
                            </div>
                            <div
                              class="post_que_editor"
                              *ngIf="
                                questionDetailEntity.question_type_id ==
                                globals.configurationText.questionType
                                  .expressYourself
                              "
                            >
                              <textarea
                                value="{{ comment.answer_text }}"
                                kendoTextArea
                                id="answer_text"
                                name="answer_text"
                                #answer_text="ngModel"
                                [(ngModel)]="postEditAnswerEntity.answer_text"
                                (keyup)="countChar(1)"
                                class="form-control"
                                rows="4"
                                placeholder="Enter your answer"
                              ></textarea>
                              <i
                                class="fa-pencil fa-solid"
                                aria-hidden="true"
                              ></i>
                              <div id="charNum"></div>
                              <span id="limit">1000/1000</span>
                            </div>
                            <div
                              *ngIf="
                                questionDetailEntity.question_type_id ==
                                globals.configurationText.questionType
                                  .rankedList
                              "
                            >
                              <!-- <div class="container-fluid">
                                                          <div class="row"> --
                              <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 team"
                              >
                                <kendo-sortable
                                  [kendoSortableBinding]="userAnswerOptions"
                                  zone="twoWay"
                                  emptyText="Move employees from Team B to Team A."
                                  class="row"
                                  itemClass="employee"
                                  [emptyItemStyle]="{
                                    'min-height': '150px',
                                    width: '10%'
                                  }"
                                  emptyItemClass="empty"
                                  activeItemClass="employee active"
                                >
                                  <ng-template let-item="item">
                                    {{ item.value }}
                                  </ng-template>
                                </kendo-sortable>
                              </div>
                              <!-- </div>
                                                      </div> --
                            </div>
                            <!-- <div class="check_box">
                                                      <input
                                                          [disabled]="(globals.authData != undefined && globals.authData.is_profile_private == 1)"
                                                          [checked]="(globals.authData != undefined && globals.authData.is_profile_private == 1) || comment.send_anonymously == globals.configurationText.postAnswerSendOption.sendAnonymously "
                                                          type="checkbox"
                                                          (change)="sendOptionEdit(answerSendOption.id)"
                                                          name="Check1" id="Check1" [value]="answerSendOption.id">
                                                      <label for="Check1"
                                                          class="checkboxbtn_label">{{answerSendOption.display_text}}
                                                      </label>
                                                  </div> --
                            <button
                              kendoButton
                              type="button"
                              (click)="updateAnswer(comment.id)"
                              [disabled]="btn_disabled"
                              id="next_btn"
                              class="theme_btn mt-4 me-0 center"
                            >
                              Update Answer
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div> -->
                   <!-- second -->
                  <div
                    class="forum_box"
                  >
                  <ul class="comments_main">
                    <ng-template #recursiveList let-questionCommentsEntity let-index="index">
                      <ng-container *ngFor="let comment of questionCommentsEntity;let i=index;">
                        <li>
                    <div class="img_icon">
                      <img
                        *ngIf="
                          comment.user.profile_image_url != '' &&
                          comment.user.profile_image_url != null
                        "
                        src="{{ globals.profileImgeapiUrl }}/{{
                          comment.user.profile_image_url
                        }}"
                        alt=""
                        class="img-responsive"
                      />
                      <img
                        *ngIf="
                          comment.user.profile_image_url == '' ||
                          comment.user.profile_image_url == null
                        "
                        src="assets/images/dummy.jpg"
                        alt=""
                        class="img-responsive"
                      />
                    </div>
                    <div class="content_name">
                      <!-- <div class="name" *ngIf="communityDetailEntity != ''"><a
                                                  (click)="publicProfile(comment.user.id)">{{comment.user.user_name}} </a>
                                          </div>
                                          <div class="name" *ngIf="communityDetailEntity == ''">
                                              {{comment.user.first_name}} {{comment.user.last_name}}</div> -->
                      <div class="name">
                        <a
                          (click)="
                            publicProfile(
                              comment.user.id,
                              comment.send_anonymously
                            )
                          "
                          >{{ comment.first_name }} {{ comment.last_name }}</a
                        >
                      </div>
                      <div
                        class="editor_data mb-4 mt-1"
                        *ngIf="
                          questionDetailEntity.question_type_id ==
                          globals.configurationText.questionType.yesOrNo
                        "
                      >
                        <span *ngIf="comment.is_true == 1">Yes</span>
                        <span *ngIf="comment.is_true == 0">No</span>
                      </div>
                      <div
                        class="editor_data mb-4 mt-1"
                        *ngIf="
                          questionDetailEntity.question_type_id ==
                            globals.configurationText.questionType
                              .multiPickList ||
                          questionDetailEntity.question_type_id ==
                            globals.configurationText.questionType.pickList ||
                          questionDetailEntity.question_type_id ==
                            globals.configurationText.questionType.rankedList
                        "
                      >
                        {{ comment.user_options }}
                      </div>
                      <div
                        class="editor_data mb-4 mt-1"
                        *ngIf="
                          questionDetailEntity.question_type_id ==
                          globals.configurationText.questionType.expressYourself
                        "
                       [innerHtml]="comment.answer_text">
                        
                      </div>
                      <!-- image video Display -->
                      <div class="quebox_media" *ngIf="comment.filedata?.length > 0">
                        <div
                          [attr.id]="'abc'+comment.id"
                          class="carousel slide"
                          data-bs-ride="carousel"
                          data-bs-interval="false"
                          
                        >
                          <div
                            class="carousel-indicators"
                            *ngIf="comment.filedata.length > 1"
                          >
                            <button
                              type="button"
                              [attr.data-bs-target]="'#abc'+comment.id"
                              [attr.data-bs-slide-to]="i"
                              [attr.aria-label]="'Slide ' + i"
                              [ngClass]="{ active: i == 0 }"
                              *ngFor="let list of comment.filedata; let i = index"
                              [attr.aria-current]="i == 0"
                            ></button>
                          </div>
                          <div class="carousel-inner">
                            <div
                              class="carousel-item"
                              [ngClass]="{ active: i == 0 }"
                              *ngFor="let list of comment.filedata; let i = index"
                            >
                              <img
                                alt=""
                                class="d-block w-100"
                                *ngIf="list.type == 'img'"
                                src="{{ globals.profileImgeapiUrl }}/answerimg/{{
                                    comment.id
                                }}/{{ list.Images }}"
                              />
                              <div
                                class="ratio ratio-16x9"
                                *ngIf="list.type == 'video'"
                              >
                                <video controls>
                                  <source
                                    src="{{ globals.profileImgeapiUrl }}/answerimg/{{
                                        comment.id
                                    }}/{{ list.Images }}"
                                  />
                                </video>
                              </div>
                            </div>
                          </div>
                          <button
                            *ngIf="comment.filedata.length > 1"
                            class="carousel-control-prev"
                            type="button"
                            [attr.data-bs-target]="'#abc'+comment.id"
                            data-bs-slide="prev"
                          >
                            <span
                              class="carousel-control-prev-icon"
                              aria-hidden="true"
                            ></span>
                            <span class="visually-hidden">Previous</span>
                          </button>
                          <button
                            *ngIf="comment.filedata.length > 1"
                            class="carousel-control-next"
                            type="button"
                            [attr.data-bs-target]="'#abc'+comment.id"
                            data-bs-slide="next"
                          >
                            <span
                              class="carousel-control-next-icon"
                              aria-hidden="true"
                            ></span>
                            <span class="visually-hidden">Next</span>
                          </button>
                        </div>
                      </div>
                      <!-- image video Display -->
                      <div
                        class="like_question ans-action"
                        *ngIf="
                          questionDetailEntity.question_type_id ==
                            globals.configurationText.questionType
                              .expressYourself &&
                          (globals.authData == undefined ||
                            comment.is_like == '' || comment.is_like == null)
                        "
                      >
                        <a
                          (click)="likeDislikeAnswer(comment, 1)" id="thumb{{comment.id}}"
                          ><i class="fa-regular fa-thumbs-up"></i
                        ></a>
                        <span>{{ comment.likesCount }}</span>
                      </div>
                      <div
                        class="like_question ans-action"
                        *ngIf="
                          questionDetailEntity.question_type_id ==
                            globals.configurationText.questionType
                              .expressYourself &&
                          comment.is_like != '' && comment.is_like != null
                        "
                      >
                        <a
                          (click)="likeDislikeAnswer(comment, 0)" id="thumb{{comment.id}}"
                          ><i class="fa-solid fa-thumbs-up"></i
                        ></a>
                        <span>{{ comment.likesCount }}</span>
                      </div>
                      <div class="reply_time ans-action">
                        <span class="float-start"
                          ><i class="fa-regular fa-clock"></i>
                          {{ comment.posted_time }}</span
                        >
                      </div>
                      <div
                        class="ans-action"
                        *ngIf="
                          questionDetailEntity.question_type_id ==
                          globals.configurationText.questionType.expressYourself && globals.authData && globals.authData.roles_id == 2
                        "
                      >
                        <ul class="queicon_lists float-start">
                          <li
                            *ngIf="
                              globals.authData == undefined ||
                              ((comment.user_id == '' ||
                                comment.user_id == null) &&
                                comment.user.id != globals.authData.id &&
                                globals.reportQuestionAnswerEntity
                                  .invitee_answer_id != comment.id)
                            "
                          >
                            <a
                              class="report"
                              onclick="event.stopPropagation();"
                              (click)="
                                globals.reportPopUpOpen(
                                  questionDetailEntity.id,
                                  comment.id,
                                  questionDetailEntity.user_name
                                )
                              "
                              ><i class="fa-solid fa-flag"></i> Report</a
                            >
                          </li>
                          <li
                            *ngIf="
                              (comment.user_id != '' &&
                                comment.user_id != null) ||
                              (globals.reportQuestionAnswerEntity
                                .reportedTime != '' &&
                                globals.reportQuestionAnswerEntity
                                  .reportedTime != undefined &&
                                globals.reportQuestionAnswerEntity
                                  .invitee_answer_id == comment.id &&
                                comment.user.id != globals.authData.id)
                            "
                          >
                            <a
                              onclick="event.stopPropagation();"
                              class="report_question_answer"
                              *ngIf="
                                globals.reportQuestionAnswerEntity
                                  .reportedTime != '' &&
                                globals.reportQuestionAnswerEntity
                                  .reportedTime != undefined &&
                                globals.reportQuestionAnswerEntity
                                  .invitee_answer_id == comment.id
                              "
                              title="{{
                                globals.commonTranslationText.questionDetail
                                  .reportAnswerTooltip
                              }} {{
                                globals.reportQuestionAnswerEntity.reportedTime
                                  | date : 'MMM d, y h:mm a'
                              }}"
                              ><i class="fa-solid fa-flag"></i> Reported
                            </a>
                            <a
                              onclick="event.stopPropagation();"
                              class="report_question_answer"
                              *ngIf="
                                globals.reportQuestionAnswerEntity
                                  .reportedTime == '' ||
                                globals.reportQuestionAnswerEntity
                                  .reportedTime == undefined ||
                                globals.reportQuestionAnswerEntity
                                  .invitee_answer_id != comment.id
                              "
                              title="{{
                                globals.commonTranslationText.questionDetail
                                  .reportAnswerTooltip
                              }} {{
                                comment.user_id.created_at
                                  | date : 'MMM d, y h:mm a'
                              }}"
                              ><i class="fa-solid fa-flag"></i> Reported
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div class="reply_time ans-action d-inline-block ms-3 activeyes_box" *ngIf="globals.authData && globals.authData.roles_id == 1">
                        <kendo-switch (valueChange)="isActiveChange(comment,$event)"
                            [checked]="comment.is_active==1" [onLabel]="'Y'"
                            [offLabel]="'N'" [disabled]="false">
                        </kendo-switch>
                    </div>
                    
                      <div class="reply_time ans-action d-inline-block ms-3" *ngIf="globals.authData && globals.authData.roles_id == 1">
                        <a
                            class="bg-transparent border-0 p-0 ms-0"
                            (click)="ReportAnswer(comment)"
                          >
                            <i title="Reported By" class="fa-solid fa-flag"></i>
                            {{comment.reportedResponseCount}} View Reported
                        </a>
                        
                    </div>
                      
                    <div class="reply_time ans-action float-end">
                      <a *ngIf="(comment.hidecount <= 5 || !comment.parent_id) && globals.authData"
                                (click)="openCommentBox(comment,replyForm)" class="reply_btn first_level">
                                <i class="fa fa-reply"></i>
                                Reply</a>
                              </div>
                    
                                 <!-- reply form section  -->
                              <div class="clearfix"></div>
                              <div class="write_reply" id="write_reply{{comment.id}}">
                                <form class="w-100" #replyForm="ngForm"
                                  (ngSubmit)="adddynamicCommnetReply(replyForm,comment)">
                                  <div class="form-group">
                                    <label>
                                      <span class="req_filled">*</span>Write your reply</label>
                                    <textarea kendoTextArea class="form-control"
                                      #answer_text="ngModel"
                                      id="reply_answer_text{{comment.id}}"
                                      [(ngModel)]="postEntity.answer_text"
                                      name="answer_text"
                                      (keyup)="countCharReply(comment,$event)"
                                      maxlength="1000"
                                      [ngClass]="{red_border: (answer_text.dirty && answer_text.invalid) || (answer_text.pristine && submitted)}"
                                      required></textarea> 
                                    <span class="error_class"
                                      *ngIf="(answer_text.dirty && answer_text.invalid) || (answer_text.pristine && submitted)">
                                      <span
                                        *ngIf="answer_text.errors.required || (answer_text.pristine && submitted)">Please
                                        enter your reply</span>
                                      <span
                                        *ngIf="answer_text.errors && answer_text.errors.pattern">Please
                                        enter a valid reply</span>
                                    </span>
                                    <div class="limitwitheditor" id="charNumReply{{comment.id}}"></div>
                                    <span class="limitwitheditor" id="limitReply{{comment.id}}">1000/1000</span>
                                  </div>
                                  <div class="form-group mb-0">
                                    <button type="submit" class="ms-0 theme_btn"
                                      [disabled]="!replyForm.valid">Post</button>
                                    <button type="button" class="themeoption_btn"
                                      (click)="Cancel_replay(comment.id)">Cancel</button>
                                  </div>
                                  <div class="clearfix"></div>
                                </form>
                              </div>
                      <div
                        class="ans-action"
                        *ngIf="
                          globals.authData != undefined &&
                          globals.authData.id == comment.user.id
                        "
                      >
                        <!-- <div class="float-start mx-4 edit_answer" (click)="editAnswer()">
                                              <i class="fa-pencil fa-sm fa-solid" aria-hidden="true"></i> Edit
                                          </div> -->
                        <div class="clearfix"></div>
                        <div
                          class="edit_answer_expand mt-3"
                          id="editAnswer"
                          style="display: none"
                        >
                          <form>
                            <div 
                              class="activeyes_box position-relative"
                              *ngIf="
                                questionDetailEntity.question_type_id ==
                                globals.configurationText.questionType.yesOrNo
                              "
                            >
                              <div class="radio_box" id="radio1">
                                <input
                                  type="radio"
                                  [checked]="comment.is_true == 1"
                                  value="{{ comment.is_true }}"
                                  name="is_true"
                                  id="is_true1"
                                />
                                <label for="is_true1">Yes</label>
                              </div>
                              <div class="radio_box" id="radio2">
                                <input
                                  [checked]="comment.is_true == 0"
                                  type="radio"
                                  value="{{ comment.is_true }}"
                                  name="is_true"
                                  id="is_true2"
                                />
                                <label for="is_true2">No</label>
                              </div>
                            </div>
                            <div class="position-relative"
                              *ngIf="
                                questionDetailEntity.question_type_id ==
                                globals.configurationText.questionType.pickList
                              "
                            >
                              <!-- <div class="radio_box"
                                                          *ngFor="let options of questionDetailEntity.options; let i = index;">
                                                          <input type="radio"
                                                              [checked]="options.checked"
                                                              name="user_option_id" id="user_option_id{{i}}"
                                                              [value]="options.id">
                                                          <label for="user_option_id{{i}}">{{options.value}}</label>
                                                      </div> -->
                              <div
                                class="radio_box"
                                *ngFor="
                                  let options of questionDetailEntity.options;
                                  let i = index
                                "
                              >
                                <input
                                  [checked]="options.checked"
                                  (change)="optionChange(i, options)"
                                  type="radio"
                                  name="user_option_id"
                                  id="user_option_id{{ i }}"
                                  [value]="options.id"
                                />
                                <label for="user_option_id{{ i }}">{{
                                  options.value
                                }}</label>
                              </div>
                            </div>
                            <div class="position-relative"
                              *ngIf="
                                questionDetailEntity.question_type_id ==
                                globals.configurationText.questionType
                                  .multiPickList
                              "
                            >
                              <div
                                class="check_box"
                                *ngFor="
                                  let options of questionDetailEntity.options;
                                  let i = index
                                "
                              >
                                <input
                                  [checked]="options.checked"
                                  type="checkbox"
                                  name="user_option_id{{ i }}"
                                  id="user_option_id{{ i }}"
                                  (change)="
                                    answerSelect(
                                      options,
                                      i,
                                      questionDetailEntity.question_type_id,
                                      1
                                    )
                                  "
                                  [value]="options.id"
                                />
                                <label
                                  for="user_option_id{{ i }}"
                                  class="checkboxbtn_label"
                                  >{{ options.value }}</label
                                >
                              </div>
                            </div>
                            <div
                              class="post_que_editor"
                              *ngIf="
                                questionDetailEntity.question_type_id ==
                                globals.configurationText.questionType
                                  .expressYourself
                              "
                            >
                              <textarea
                                value="{{ comment.answer_text }}"
                                kendoTextArea
                                id="answer_text"
                                name="answer_text"
                                #answer_text="ngModel"
                                [(ngModel)]="postEditAnswerEntity.answer_text"
                                (keyup)="countChar(1)"
                                class="form-control"
                                rows="4"
                                placeholder="Enter your answer"
                              ></textarea>
                              <i
                                class="fa-pencil fa-solid"
                                aria-hidden="true"
                              ></i>
                              <div id="charNum"></div>
                              <span id="limit">1000/1000</span>
                            </div>
                            <div
                              *ngIf="
                                questionDetailEntity.question_type_id ==
                                globals.configurationText.questionType
                                  .rankedList
                              "
                            >
                              <!-- <div class="container-fluid">
                                                          <div class="row"> -->
                              <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 team"
                              >
                                <kendo-sortable
                                  [kendoSortableBinding]="userAnswerOptions"
                                  zone="twoWay"
                                  emptyText="Move employees from Team B to Team A."
                                  class="row"
                                  itemClass="employee"
                                  [emptyItemStyle]="{
                                    'min-height': '150px',
                                    width: '10%'
                                  }"
                                  emptyItemClass="empty"
                                  activeItemClass="employee active"
                                >
                                  <ng-template let-item="item">
                                    {{ item.value }}
                                  </ng-template>
                                </kendo-sortable>
                              </div>
                              <!-- </div>
                                                      </div> -->
                            </div>
                            <!-- <div class="check_box">
                                                      <input
                                                          [disabled]="(globals.authData != undefined && globals.authData.is_profile_private == 1)"
                                                          [checked]="(globals.authData != undefined && globals.authData.is_profile_private == 1) || comment.send_anonymously == globals.configurationText.postAnswerSendOption.sendAnonymously "
                                                          type="checkbox"
                                                          (change)="sendOptionEdit(answerSendOption.id)"
                                                          name="Check1" id="Check1" [value]="answerSendOption.id">
                                                      <label for="Check1"
                                                          class="checkboxbtn_label">{{answerSendOption.display_text}}
                                                      </label>
                                                  </div> -->
                            <button
                              kendoButton
                              type="button"
                              (click)="updateAnswer(comment.id)"
                              [disabled]="btn_disabled"
                              id="next_btn"
                              class="theme_btn mt-4 me-0 center"
                            >
                              Update Answer
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                    <ul class="comments_main" *ngIf="comment?.child?.length > 0">
											<ng-container
												*ngTemplateOutlet="recursiveList; context:{ $implicit: comment.child ,idx: i }">
											</ng-container>
										</ul>
                        </li>
                        </ng-container>
                        </ng-template>
                        <ng-container
                        *ngTemplateOutlet="recursiveList; context:{ $implicit: questionCommentsEntity }">
                      </ng-container>
                  </ul>
                  </div>
                  
                </ng-container>
                <div
                  *ngIf="
                    questionDetailEntity.question_type_id !=
                    globals.configurationText.questionType.expressYourself
                  "
                >
                  <div class="row">
                    <div
                      class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                      *ngIf="
                        questionDetailEntity.question_type_id ==
                          globals.configurationText.questionType.pickList ||
                        questionDetailEntity.question_type_id ==
                          globals.configurationText.questionType.multiPickList
                      "
                    >
                      <div class="graph_scroll_head">
                        Scroll right to view the entire chart
                      </div>
                      <div class="graph_block">
                        <div id="barchart1" class="height_amchart"></div>
                      </div>
                    </div>
                    <div
                      class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                      *ngIf="
                        questionDetailEntity.question_type_id ==
                        globals.configurationText.questionType.yesOrNo
                      "
                    >
                      <div class="graph_scroll_head">
                        Scroll right to view the entire chart
                      </div>
                      <div class="graph_block">
                        <div id="piechart" class="height_amchart"></div>
                      </div>
                    </div>
                    <div
                      class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                      *ngIf="
                        questionDetailEntity.question_type_id ==
                        globals.configurationText.questionType.rankedList
                      "
                    >
                      <div class="graph_scroll_head">
                        Scroll right to view the entire chart
                      </div>
                      <div class="graph_block">
                        <div id="barchart_ranked" class="height_amchart"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="text-center"><button class="theme_btn">View More</button></div> -->
              </div>
            </div>
            <!-- <div class="container">
                          <div class="row">
                              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                  <div id="barchart" class="height_amchart"></div>
                              </div>
                              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                  <div id="piechart" class="height_amchart"></div>
                              </div>
                              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                  <div id="funnelchart" class="height_amchart"></div>
                              </div>
                          </div>
                      </div> -->
            <!-- <div class="addthis_inline_share_toolbox"></div> -->
          </div>
        </div>
      </div>
      <!-- <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
        <div class="community_question_right question_detail_right">
          <div class="advertisement_box">
            <p>
              IJCAHPO Eyedea Exchange is your key to fast, easy and clutter-free
              group decisions.
            </p> -->
            <!-- <p>Send to up to 1000 friends instantly, Now!</p> -->
            <!--<p>Threads Remain Live for 99 Hrs</p>-->
          <!-- </div> -->
          <!-- <div class="about_community mt-5" *ngIf="!questionDetailLoading3">
                      <h3 class="about_comm_head">
                          About Community
                      </h3>
                     <div class="community_photo">
                          <img src="{{globals.profileImgeapiUrl}}/community-image/{{communityDetailEntity.community_thumbnail_image}}"
                              alt="" class="img-fluid">
                      </div>
                      <div class="community_name">
                          <a
                              (click)="communityQuestion(communityDetailEntity.id)"><strong>{{communityDetailEntity.name}}</strong></a>
                          <img src="assets/images/favicon.png" alt="" class="img-fluid">
                          <span>/ {{communityDetailEntity.category.name}}</span>
                      </div>
                      <div class="clearfix"></div>
                      <p [innerHtml]="communityDetailEntity.description"></p>



                      <div class="created_on">
                          <span> <i class="fa-solid fa-calendar-days"></i> Created On Oct 21, 2020</span>
                      </div>




                  </div> -->
          <!-- <div
            class="about_community related_community mt-5"
            *ngIf="!questionDetailLoading4"
          >
            <h3 class="about_comm_head">Related Topics</h3>
            <div
              *ngIf="
                relatedCommunityList.length == 0 && !questionDetailLoading4
              "
              class="no_records_found d-flex justify-content-center align-items-center"
            >
              <span>There are no related topics found</span>
            </div>
            <ul
              class="related_comm_lists"
              *ngIf="relatedCommunityList.length > 0 && !questionDetailLoading4"
            >
              <ng-container
                *ngFor="
                  let community of relatedCommunityList;
                  let i = index;
                  let last = last
                "
              >
                <li [ngClass]="{ no_border: last }">
                  <img
                    src="{{ globals.profileImgeapiUrl }}/community-image/{{
                      community.community_thumbnail_image
                    }}"
                    alt=""
                    class="img-fluid"
                  />
                  <a (click)="communityQuestion(community.id)"
                    ><span>{{ community.name }}</span></a
                  >
                </li>
              </ng-container>
              <button
                kendoButton
                type="submit"
                (click)="communityList()"
                class="mt-4 theme_btn theme_btn d-flex justify-content-center mx-auto"
              >
                View All
              </button>
            </ul>
          </div> -->
        <!-- </div>
      </div>
    </div> -->
  </div>
</div>

<kendo-dialog
  title="Invitations"
  *ngIf="invitePopUp"
  (close)="closeInvitePopUp()"
  [minWidth]="250"
  [width]="725"
>
  <div class="table_block">
    <div
      *ngIf="
        invitationLimit == questionDetailEntity.invitees_temp.length &&
        questionDetailEntity.invitees_only_to_me != 0
      "
      class="mb-5"
    >
      Sorry, based on current plan of question owner, only
      {{ invitationLimit }} invitations can be sent.
    </div>
    <div *ngIf="questionDetailEntity.invitees_only_to_me != 0" class="mb-5">
      Thread is expired.
    </div>
    <div
      *ngIf="
        questionDetailEntity.invitee_can_invite_others == 0 &&
        questionDetailEntity.owner_user_id != globals.authData.id
      "
      class="mb-5"
    >
      You can not send more invitations for this question.
    </div>
    <div class="clearfix"></div>
    <div
      *ngIf="
        invitationLimit != questionDetailEntity.invitees_temp.length &&
        (questionDetailEntity.invitee_can_invite_others ==
          globals.configurationText.postQuestionSendOption
            .recipientsInviteOthers ||
          questionDetailEntity.owner_user_id == globals.authData.id)
      "
    >
      <h5 class="edit_answer">Invite more people</h5>
      <div class="clearfix"></div>
      <p class="add_invitee">
        You can add up to
        {{
          invitationLimit - questionDetailEntity.invitees_temp.length
        }}
        invitees.
      </p>
      <div class="edit_answer_expand" id="inviteOthers">
        <form #inviteForm="ngForm" (ngSubmit)="inviteOthers(inviteForm)">
          <div class="form-group mb-5" id="group_name_new">
            <textarea
              kendoTextArea
              id="contact_details"
              name="contact_details"
              #contact_details="ngModel"
              [(ngModel)]="inviteOthersEntity.contact_details"
              (keyup)="checkNumberofInvitations()"
              (blur)="checkvalidation()"
              class="form-control"
              rows="4"
              placeholder="Enter email addresses or telephone numbers separated by commas (Example : bestfriend@url.com, 1233214565, sister@xyz.com)"
              required
              [ngClass]="{
                error:
                  (contact_details.dirty && contact_details.invalid) ||
                  (contact_details.pristine &&
                    invitesubmitted &&
                    contact_details.errors &&
                    contact_details.errors.required)
              }"
            ></textarea>
            <span
              class="error_class"
              *ngIf="
                (contact_details.dirty && contact_details.invalid) ||
                (contact_details.pristine && invitesubmitted)
              "
            >
              <!---  || errorEntity.contact_details-->
              <span
                *ngIf="
                  contact_details.errors && contact_details.errors.required
                "
              >
                Please enter contacts
              </span>
            </span>
            <span class="error_class" *ngIf="contactDetailsValidation">
              Please enter valid email or phone
            </span>
            <span class="error_class" *ngIf="errorEntity.InvitationError != ''">
              {{ errorEntity.InvitationError }}
            </span>
          </div>

          <div class="col-xl-12 text-center">
            <button kendoButton type="submit" class="theme_btn">Add</button>
          </div>
        </form>
      </div>
      <hr />
    </div>
    <!-- questionDetailEntity.display_status || -->
    <div
      *ngIf="
        questionDetailEntity.invitees_only_to_me == 0 ||
        (questionDetailEntity.invitees_only_to_me ==
          globals.configurationText.postQuestionSendOption
            .showInviteeListOnlyMe &&
          questionDetailEntity.owner_user_id == globals.authData.id)
      "
    >
      <h5 class="edit_answer">Invited people</h5>
      <ul class="invited_list">
        <ng-container *ngIf="questionDetailEntity.invitees_temp.length > 0">
          <li *ngFor="let invitee of questionDetailEntity.invitees_temp">
            <span
              *ngIf="
                invitee.address.contact_email != null &&
                invitee.address.contact_email != ''
              "
              >{{ invitee.address.contact_email }}
              <span
                class="invited_by"
                *ngIf="
                  invitee.user.id != globals.authData.id &&
                  invitee.user.first_name != '' &&
                    invitee.user.first_name != null
                "
              >
                (Invited by {{ invitee.user.first_name }}
                {{ invitee.user.last_name }})
              </span>
              <span
                class="invited_by"
                *ngIf="
                  invitee.user.id != globals.authData.id &&
                  (invitee.user.first_name == '' ||
                    invitee.user.first_name == null)
                "
              >
                (Invited by {{ invitee.user.user_name }})
              </span>
            </span>
            <span
              *ngIf="
                invitee.address.contact_phone != null &&
                invitee.address.contact_phone != ''
              "
              >{{ invitee.address.contact_phone }}
              <span
                class="invited_by"
                *ngIf="
                  invitee.user.id != globals.authData.id &&
                  invitee.user.first_name != '' &&
                    invitee.user.first_name != null
                "
              >
                (Invited by {{ invitee.user.first_name }}
                {{ invitee.user.last_name }})
              </span>
              <span
                class="invited_by"
                *ngIf="
                  invitee.user.id != globals.authData.id &&
                  (invitee.user.first_name == '' ||
                    invitee.user.first_name == null)
                "
              >
                (Invited by {{ invitee.user.user_name }})
              </span>
            </span>
          </li>
        </ng-container>
        <li *ngIf="questionDetailEntity.invitees_temp.length == 0">
          No invitations sent
        </li>
      </ul>
    </div>
  </div>
</kendo-dialog>

<kendo-dialog
                      title="{{
                        globals.adminTranslationText.AnswerDetail
                          .ReportedAnswerList.FlagTitle
                      }}"
                      *ngIf="reportedResponseListDialog"
                      (close)="close('cancel')"
                      [minWidth]="250"
                      [width]="725"
                    >
                      <kendo-grid
                        [data]="reportedanswersList"
                        [pageSize]="pageSize"
                        [skip]="skip"
                        [pageable]="true"
                        (pageChange)="pageChange($event)"
                        [sort]="sort"
                        [sortable]="{
                          allowUnsort: allowUnsort,
                          mode: 'single'
                        }"
                        (sortChange)="sortChange($event)"
                      >
                        <kendo-grid-messages
                          pagerItems="responses"
                          pagerItemsPerPage="responses per page"
                          noRecords="{{
                            globals.adminTranslationText.AnswerDetail
                              .ReportedAnswerList.recordNotFound
                          }}"
                        >
                        </kendo-grid-messages>
                        <ng-template kendoGridToolbarTemplate>
                          <input
                            placeholder="Search in all columns..."
                            kendoTextBox
                            (input)="onFilter($event.target.value)"
                          />
                        </ng-template>

                        <!-- <kendo-grid-column field="user_name" title="{{globals.adminTranslationText.AnswerDetail.ReportedAnswerList.collumns.User}}">
                                                </kendo-grid-column> -->
                        <kendo-grid-column
                          field="user"
                          title="{{
                            globals.adminTranslationText.AnswerDetail
                              .ReportedAnswerList.collumns.Name
                          }}"
                        >
                          <ng-template
                            kendoGridCellTemplate
                            let-dataItem
                            let-rowIndex="rowIndex"
                          >
                            <div
                              *ngIf="
                                dataItem.user == '' || dataItem.user == null
                              "
                            >
                              -
                            </div>
                            <div *ngIf="dataItem.user != ''">
                              {{ dataItem.user }}
                            </div>
                          </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column
                          field="created_at"
                          title="{{
                            globals.adminTranslationText.AnswerDetail
                              .ReportedAnswerList.collumns.Date
                          }}"
                        >
                        </kendo-grid-column>
                        <kendo-grid-column
                          field="comment"
                          title="{{
                            globals.adminTranslationText.AnswerDetail
                              .ReportedAnswerList.collumns.Comment
                          }}"
                        >
                        </kendo-grid-column>
                      </kendo-grid>
                    </kendo-dialog>

<kendo-dialog
  *ngIf="mediaInfoOpened" (close)="mediaInfoClose()"
  [minWidth]="250"
  [width]="725"
  class="mediaInfo_popup"
>
<button class="close_btn" (click)="mediaInfoClose()"><i class="fa-solid fa-xmark"></i></button>
<p>Should you wish to upload media, you have the option to select a maximum of three media files simultaneously. The valid image extensions encompass 'jpg', 'jpeg', 'png', 'JPEG', 'JPG', 'PNG', 'heic','BMP','bmp','webp','WEBP' and 'HEIC'. On the other hand, the acceptable video extensions consist of '.mp3', '.guv', 'mp4', 'MP4', 'MKV', 'mkv', 'WEBM', 'webm', 'avi', and 'mov'.</p>
</kendo-dialog>
                   