import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Globals } from '.././globals';
import { Router } from '@angular/router';
import { ActivatedRoute, Params } from '@angular/router';
import { GroupResult, groupBy } from '@progress/kendo-data-query';
import { HomeService } from '../services/home.service';
import { CookieService } from 'ngx-cookie-service';
declare var $: any, PerfectScrollbar, Swal;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit {

  public mediaInfoOpened = false;
  public mediaInfoClose(): void {
    this.mediaInfoOpened = false;
  }
  public mediaInfoOpen(): void {
    this.mediaInfoOpened = true;
  }

  percent = 0;
  @ViewChild('progressBar') progressBar: ElementRef;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public globals: Globals,
    private HomeService: HomeService,
    private elem: ElementRef,
    private cookieService: CookieService
  ) {}

  public listItems1: Array<{ text: string; value: number }> = [
    { text: 'Small', value: 1 },
    { text: 'Medium', value: 2 },
    { text: 'Large', value: 3 },
  ];
  public value: any = [];

  public isChecked = false;

  public isItemSelected(item) {
    console.log(this.value);
    return this.value.some((x) => x === item.id);
  }
  public onValueChange(selected) {
    //debugger;
    console.log(selected);
    if (this.globals.authData == undefined) {
      for (var i = 0; i < this.communityList.length; i++) {
        this.isItemSelected(i);
        if (
          this.communityList[i].id != undefined &&
          this.communityList[i].id != selected &&
          selected.length == 1
        ) {
          this.communityList[i].itemClass = 'parent';
        } else if (this.communityList[i].id != undefined) {
          this.communityList[i].itemClass = 'second-level';
        }
      }
      console.log(this.communityList);
    } else {
      var index;
      let array = [];
      var numofcommunities =
        this.postQuestionEntity.questionType == 0
          ? this.globals.authData.plan.number_of_communities
          : this.globals.authData.plan.community_question_number_of_communities;
      if (selected.length >= numofcommunities) {
        this.communityList.forEach(function (menu, key) {
          index = selected.findIndex((selected) => selected === menu.id);
          if (index != -1) {
            var ob = {
              id: menu.id,
              itemClass: 'second-level',
              name: menu.name,
            };
            array[key] = ob;
          } else if (menu.id == undefined) {
            var ob1 = {
              itemClass: 'first-level',
              name: menu.name,
            };
            array[key] = ob1;
          } else {
            var ob = {
              id: menu.id,
              itemClass: 'parent',
              name: menu.name,
            };
            array[key] = ob;
          }
        });
        this.communityList = array;
      } else {
        for (var i = 0; i < this.communityList.length; i++) {
          if (this.communityList[i].id != undefined) {
            this.communityList[i].itemClass = 'second-level';
          }
        }
      }
      this.errorEntity.community_id = false;
      console.log(array);
    }

    this.postQuestionEntity.community_id = selected;
    console.log(this.postQuestionEntity);
    console.log(this.communityList);
    this.isChecked = this.value.length === this.communityList.length;
  }

  public data;
  public source = [
    { name: 'Sports', itemClass: 'first-level' },
    {
      name: 'Badminton',
      itemClass: 'second-level',
      id: '4abe0482-49bf-11eb-bd3a-c85b767b9463',
    },
    {
      name: 'Carrom',
      itemClass: 'second-level',
      id: '3d10d3e5-49bf-11eb-bd3a-c85b767b9463',
    },
    {
      name: 'Chess',
      itemClass: 'second-level',
      id: '8c20f99d-49bf-11eb-bd3a-c85b767b9463',
    },
    { name: 'Music', itemClass: 'first-level' },
    {
      name: 'Disco',
      itemClass: 'second-level',
      id: '7837ee70-49c1-11eb-bd3a-c85b767b9463',
    },
    {
      name: 'Folk',
      itemClass: 'second-level',
      id: 'ed5ba809-49bf-11eb-bd3a-c85b767b9463',
    },
  ];

  public itemDisabled(itemArgs: { dataItem: any; index: number }) {
    const dataItem = itemArgs.dataItem;
    if (
      dataItem.itemClass === 'parent' ||
      dataItem.itemClass === 'first-level'
    ) {
      return true;
    }
  }

  public show: boolean = false;

  public selectedKeys = ['Private'];

  public onToggle(): void {
    this.show = !this.show;
  }

  public handleSelection({ index }: any): void {
    this.show = false;
  }

  public valueS = `
        Text(Optional)
    `;

  // public data: Array<any> = this.createRandomData(8);

  // public groupedData: GroupResult[] = groupBy(this.data, [{ field: 'title' }]);

  public virtual: any = {
    itemHeight: 28,
  };

  public createRandomData(count: number): any[] {
    const firstNames = [
      'Art and',
      'Relationships ',
      'Shopping ',
      'Parent  ',
      'Sexuality ',
      'Vacations ',
      'Food & ',
      'Travel ',
    ];
    const lastNames = ['Theatre', '', 'Tips', 'Child', '', '', 'Drink', ''];
    const titles = ['Entertainment', 'Life ', 'Social'];

    return Array(count)
      .fill({})
      .map((_, idx) => ({
        id: idx,
        name: firstNames[Math.floor(idx)] + ' ' + lastNames[Math.floor(idx)],
        title: titles[Math.floor(idx)],
      }));
  }

  // public handleFilter(query: string): void {
  //   const predicate = (item) =>
  //     item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
  //   this.groupedData = groupBy(this.data.filter(predicate), [
  //     { field: 'title' },
  //   ]);
  // }

  public listItems: Array<{ text: string; value: string; index: number }> = [
    { text: 'Select Your Response Format', value: '', index: 0 },
    { text: 'Option List', value: 'assets/images/list.png', index: 1 },
    { text: 'Yes or No', value: 'assets/images/toggle.png', index: 2 },
    { text: 'Ranked List', value: 'assets/images/choices.png', index: 3 },
    { text: 'Express yourself', value: 'assets/images/chat.png', index: 4 },
  ];
  public selectedValue = 0;

  public GroupItems: Array<string> = [
    'All Stars (54)',
    'Business Geeks (10)',
    'Crew (28)',
    'Deal Makers (14)',
    'Dominators (2)',
    'Evil Masterminds (36)',
    'Expert Care (12)',
    'Fervor Hounds (23)',
    'Force (14)',
    'Incognito (4)',
    'Legends (17)',
    'Naturals (41)',
    'Rebels (4)',
    'Ringmasters (7)',
    'Shakedown (21)',
    'Team Inspiration (53)',
    'Titans (19)',
    'Tribe (9)',
    'United (34)',
    'Warriors (52)',
  ];

  public listItemscommunity: Array<string> = [
    'Cars',
    'Health & Nutrition',
    'Music',
  ];
  public selectedValuecommunity = 'Health & Nutrition';

  OptionsList;
  RankList;
  defaultEntity;
  groupList;
  communityList;
  answerList;
  questionSendOptionsList;
  postQuestionEntity;
  image_video;
  push_Image_video;
  expandSteps;
  submitted;
  submitted1;
  contact_submitted;
  post_des_valid;
  errorEntity;
  addGroupPopup;
  addGroupEntity;
  group_submitted;
  contact_details;
  questiontypeid;
  recentCommunityList;
  sendOptionsList;
  topQuestionList;
  questionLoaderList;
  questionLoader;
  decideItStarList;
  decideItStarLoader;
  topCommunityLoader;
  topCommunityLoaderList;
  filterCommunityList;
  contactDetailsValidation;
  searchCommunityList;
  auto_video_play;
  communityHide;
  communityEmails;
  invitationLimit;
  authData: any;
  userRoleId: any;
  mydata;
  mydataToUpload;
  imageVideoName;
  imageVideoURL;
  displayImage_Video;
  files;
  filessssssss;
  uploadImageData;
  fileUploadLength;
  image_Video;
  allFiles;
  finalFiles;
  imageSize: any;
  videoSize: any;
  checkImgSizeKB;
  checkVideoKB;
  filteredFile;
  UserCommunity;
  maxxxxxxLength;
  len;
  errorMessage;
  ngOnInit(): void {
    // if (this.globals.authData && this.globals.authData.roles_id == 1) {
    //   $('#home_page').addClass(
    //     'col-md-10 col-sm-10 offset-md-2 main_block'
    //   );
    // }
    // $('body').on('click', '#info_toggle_homepage', function () {
    //   $(this).toggleClass('info_open');
    //   $('.info_message').slideToggle();
    // });
    setTimeout(() => {
      var euButton = $('.k-editor .k-editor-toolbar span').find( ":contains('Media')").parents('.k-button-solid-base');
      euButton.addClass('editor_upload');
      var euText = euButton.text();
      euButton.attr("title", euText);
      //alert(euText);
      euButton.find('span.k-button-text').html( "<label for='fileToUpload'>" + euText + "</lable>" );
    }, 2500);

    $('#AnimationGradient').AnimationGradient({
      //colors: ['#a960ee', '#ff333d', '#90e0ff', '#ffcb57']
      colors: ['#333399', '#66cccc', '#99cc00']
    });
    $("#AnimationGradient").parents('body').find('header').addClass('home_header');
    $("#AnimationGradient").parents('body').find('#home_page').removeClass('pt_50 fixed-head');
    $("#AnimationGradient").parents('body').find('.admin_home').addClass('d-none');
    
    $(window).scroll(function(){
      if ($(window).scrollTop() >= 50) {
        $("#AnimationGradient").parents('body').find('header').addClass('position-fixed').removeClass('home_header');
      }
      else {
        $("#AnimationGradient").parents('body').find('header').removeClass('position-fixed').addClass('home_header');
      }
    });
    
    if (this.cookieService.check('auto_video_play')) {
      this.auto_video_play = 0;
    } else {
      this.auto_video_play = 1;
      var d1 = new Date(),
        d2 = new Date(d1);
      d2.setDate(d1.getDate() + 365);
      this.cookieService.set('auto_video_play', 'hello', d2, '/');
    }

    //-------------------------- stick Start

    // $(window).scroll(function (event) {

    // if ($(window).scrollTop() >= $('#stick-here').offset().top + $('#stick-here').
    //   outerHeight() - window.innerHeight) {
    //   $(".home_wrap_right").addClass('stick-here');
    // }

    // var scroll = $(window).scrollTop();
    // var height = $("#stick-here").height()-$("#stick-here").height() / 2;
    // var height = $("#stick-here").height();
    // if (scroll >= height) {
    // if ($("#stick-here").scrollTop() > 0) {
    //   // $("#stick-here").removeClass("clearHeader").addClass("darkHeader");
    //   $(".home_wrap_right").addClass('stick-here');
    // } else {
    //   $(".home_wrap_right").removeClass('stick-here');
    // }
    // });

    //-------------------------- stick end
    this.data = this.source.slice();
    this.defaultEntity = {};
    this.groupList = [];
    this.communityList = [];
    this.searchCommunityList = [];
    this.filterCommunityList = [];
    this.answerList = [];
    this.postQuestionEntity = {};
    this.errorEntity = {};
    this.expandSteps = false;
    this.addGroupPopup = false;
    this.addGroupEntity = {};
    this.contact_details = [];
    this.questionSendOptionsList = [];
    this.recentCommunityList = [];
    this.sendOptionsList = [];
    this.topQuestionList = [];
    this.questionLoaderList = [];
    this.questionLoader = true;
    this.decideItStarList = [];
    this.decideItStarLoader = true;
    this.topCommunityLoader = true;
    this.topCommunityLoaderList = [];
    this.communityEmails = [];
    this.mydata = [];
    this.mydataToUpload = [];
    this.imageVideoName = [];
    this.displayImage_Video = [];
    this.files = [];
    this.filessssssss = [];
    this.allFiles = [];
    this.uploadImageData = [];
    this.image_video = [];
    this.push_Image_video = [];
    this.UserCommunity = [];
    if (this.globals.authData != undefined) {
      this.authData = JSON.parse(localStorage.getItem('authData'));
      this.invitationLimit = this.globals.authData.plan.number_of_invitations;
    }
    this.getImageVideoExtensions();
    for (var i = 0; i < 5; i++) {
      this.questionLoaderList.push(i);
    }
    for (var i = 0; i < 5; i++) {
      this.topCommunityLoaderList.push(i);
    }

    if (this.globals.authData != undefined) {
      var id = this.globals.authData.id;
    } else {
      var id = null;
    }

    if (this.cookieService.check('post_question')) {
      this.postQuestionEntity = JSON.parse(
        this.cookieService.get('post_question')
      );
      console.log(this.postQuestionEntity);
      // console.log( JSON.parse(this.cookieService.get('image_video_file')));
      this.questionSendOptionsList =
        this.postQuestionEntity.questionSendOptionsList;
      console.log(this.questionSendOptionsList);
      this.questiontypeid = this.postQuestionEntity.question_type_id;
      this.countChar();
      this.answerTypeChange(this.postQuestionEntity.question_type_id);
      this.value = this.postQuestionEntity.community_id;
      this.onValueChange(this.postQuestionEntity.community_id);
      // this.imagechange();
      if (
        this.postQuestionEntity.question_type_multiSelect_id ==
        this.globals.configurationText.questionType.multiPickList
      ) {
        $('#multiSelection').attr('checked', true);
      }
      if (
        this.postQuestionEntity.image_video_path != '' &&
        (this.postQuestionEntity.image != '' ||
          this.postQuestionEntity.video != '')
      ) {
        this.postQuestionEntity.image_video =
          this.postQuestionEntity.image != ''
            ? this.postQuestionEntity.image
            : this.postQuestionEntity.video;
      }
    }
    // $(document).on('change', '.file_multi_video', function (evt) {
    //   //debugger;
    //   var $source = $('#video_here');
    //   $source[0].src = URL.createObjectURL(this.files[0]);
    //   $source.parent()[0].load();
    // });
    this.HomeService.getDefaultData(id).then(
      (data) => {
        console.log(data);
        this.defaultEntity = data['data'];
        console.log(this.defaultEntity);
        if (!this.cookieService.check('post_question')) {
          this.questionSendOptionsList = data['data']['questionSendOptions'];
          this.sendOptionsList = data['data']['questionSendOptions'];
          this.postQuestionEntity.community_id = '';
          this.postQuestionEntity.questionType = 1;
          if (this.authData && this.authData.roles_id == 1) {
            this.postQuestionEntity.postas = 1;
          } else {
            this.postQuestionEntity.postas = 0;
          }
        }
        console.log(this.postQuestionEntity.question_type_id);
        var answerType: any;
        answerType = data['data']['questionTypes'];
        // if (
        //   this.postQuestionEntity.question_type_id == '' ||
        //   this.postQuestionEntity.question_type_id == undefined
        // ) {
        //   this.postQuestionEntity.question_type_id = '';
        //   var answerTypeSelect: any = {
        //     id: '',
        //     display_text: 'Select Your Response Format',
        //     value: '',
        //   };
        // } else {
        //   var answerTypeSelect: any = {
        //     //id: '',
        //     display_text: 'Select Your Response Format',
        //     value: '',
        //   };
        // }

        // this.answerList.push(answerTypeSelect);
        this.answerList = [...this.answerList, ...answerType];
        this.postQuestionEntity.question_type_id =
          this.globals.configurationText.questionType.expressYourself;
        if (
          this.postQuestionEntity.question_type_id != '' &&
          this.postQuestionEntity.question_type_id != undefined
        ) {
          this.postQuestionEntity.question_type_id =
            this.postQuestionEntity.question_type_id;
        }
        // var group: any;
        // group = data['data']['groups'];
        // var groupeSelect = {
        //   id: '',
        //   name: 'Choose existing group',
        //   value: '',
        // };
        // this.groupList.push(groupeSelect);
        // this.groupList = [...this.groupList, ...group];
        // this.postQuestionEntity.groupid = '';
        var community: any;
        community = data['data']['categories'];
        var communitySelect = {
          id: '',
          name: 'Select Topic',
          value: '',
        };
        this.searchCommunityList.push(communitySelect);
        this.searchCommunityList = [...this.searchCommunityList, ...community];
        this.communityList = data['data']['categories'];
        if (
          this.cookieService.check('post_question') &&
          this.postQuestionEntity.community_id != ''
        ) {
          for (var a = 0; a < this.communityList.length; a++) {
            if (
              this.postQuestionEntity.community_id == this.communityList[a].id
            ) {
              this.isItemSelected(this.communityList[a]);
            }
          }
        }
        this.filterCommunityList = this.communityList;
        for (var i = 0; i < this.answerList.length; i++) {
          if (
            this.answerList[i].id ==
              this.globals.configurationText.questionType.pickList ||
            this.answerList[i].id ==
              this.globals.configurationText.questionType.multiPickList
          ) {
            this.answerList[i].value = 'assets/images/list.png';
          } else if (
            this.answerList[i].id ==
            this.globals.configurationText.questionType.yesOrNo
          ) {
            this.answerList[i].value = 'assets/images/toggle.png';
          } else if (
            this.answerList[i].id ==
            this.globals.configurationText.questionType.rankedList
          ) {
            this.answerList[i].value = 'assets/images/choices.png';
          } else if (
            this.answerList[i].id ==
            this.globals.configurationText.questionType.expressYourself
          ) {
            this.answerList[i].value = 'assets/images/chat.png';
          }
        }
        this.answerList = this.answerList.filter((answer) => {
          return (
            answer.id !=
            this.globals.configurationText.questionType.multiPickList
          );
        });
        if (this.authData) {
          this.userRoleId = this.authData.roles_id;
        }
      },
      (error) => {
        this.globals.isLoading = false;
        console.log(error);
      }
    );

    $('#next_div').hide();

    // $('.file_upload input[type="file"]').change(function (e) {
    //   var fileName = e.target.files[0].name;
    //   $('.file_upload input[type="text"]').val(fileName);
    // });

    this.postQuestionEntity.communityId = '';
    this.HomeService.getTopQuestion('top_communities', '').then(
      (data) => {
        console.log(data);
        this.recentCommunityList = data['data'];
        this.topCommunityLoader = false;
      },
      (error) => {
        this.topCommunityLoader = false;
        console.log(error);
      }
    );

    // $(window).on('load resize scroll', function () {
    //   var f = $('footer');
    //   f.css({ position: 'static' });
    //   if (f.offset().top + f.height() < $(window).height()) {
    //     f.css({ position: 'fixed', bottom: '0', width: '100%' });
    //   }
    // });

    this.HomeService.getImageVideoSize().then((data) => {
      // console.log(data['data'])
      this.imageSize = data['data'].ImageVideo[0].value;
      this.videoSize = data['data'].ImageVideo[1].value;
    });
  }

  ngAfterViewInit() {
    this.topQuestions('');
    // this.decideItStarList = this.globals.topDecideItStars('decideit_stars', '',10);
    // console.log(this.decideItStarList);
    this.topDecideItStars();
    let promise = new Promise((resolve, reject) => {
      setTimeout(() => {
        this.percent = 100;
        resolve(this.percent);
      }, 1000);
    });
    promise.then((value) => {
      this.progressBar.nativeElement.style.width = value + '%';
    });
  }

  adminFilter(event) {
    if (event.target.checked == true) {
      var id = 1;
    } else {
      var id = 0;
    }
    this.HomeService.getTopQuestion('top_questions', id).then(
      (data) => {
        console.log(data);
        this.topQuestionList = this.globals.extensionCheck(data['data']);
        for (let i = 0; i < data['data'].length; i++) {
          this.imageVideoName.push({
            data: data['data'][i].filedata,
          });
        }
        console.log(this.imageVideoName);
        // this.imageVideoURL = this.imageVideoName
        // console.log(this.imageVideoURL)
        this.questionLoader = false;
      },
      (error) => {
        this.questionLoader = false;
        console.log(error);
      }
    );
  }

  postquestionbtn() {
    Swal.fire({
      type: this.globals.configurationText.sweetAlertTypeIconSuccess,
      icon: this.globals.configurationText.sweetAlertTypeIconSuccess,
      toast: true,
      title: 'Thread post successfully',
      animation: true,
      position: this.globals.configurationText.sweetAlertPosition,
      showConfirmButton: false,
      timer: this.globals.configurationText.sweetAlertTimer,
      timerProgressBar: true,
      showCancelButton: true,
      cancelButtonText: 'x',
    });
  }

  draftquestionbtn() {
    Swal.fire({
      type: this.globals.configurationText.sweetAlertTypeIconError,
      icon: this.globals.configurationText.sweetAlertTypeIconError,
      toast: true,
      title: 'Wrong Thread',
      animation: true,
      position: 'top',
      showConfirmButton: false,
      timer: 300000000000000,
      timerProgressBar: true,
      showCancelButton: true,
      cancelButtonText: 'x',
    });
  }

  AddOption(index) {
    var item = { OptionValue: '', IsCorrectOption: 0, IsActive: 0 };
    this.OptionsList.push(item);
  }
  RemoveOption(item) {
    var index = this.OptionsList.indexOf(item);
    this.OptionsList.splice(index, 1);
  }

  AddOptionrank(index) {
    var item = { OptionValue: '', IsCorrectOption: 0, IsActive: 0 };
    this.RankList.push(item);
  }
  RemoveOptionrank(item) {
    var index = this.RankList.indexOf(item);
    this.RankList.splice(index, 1);
  }

  answerTypeChange(value) {
    this.selectedValue = value;
    this.submitted1 = false;
    if (
      this.postQuestionEntity.question_type_id ==
      this.globals.configurationText.questionType.pickList
    ) {
      var item = { OptionValue: '', IsCorrectOption: 0, IsActive: 0 };
      var item1 = { OptionValue: '', IsCorrectOption: 0, IsActive: 0 };
      var item2 = { OptionValue: '', IsCorrectOption: 0, IsActive: 0 };
      this.OptionsList = [];
      if (
        this.postQuestionEntity.question_option != '' &&
        this.postQuestionEntity.question_option != undefined &&
        this.questiontypeid ==
          this.globals.configurationText.questionType.pickList
      ) {
        this.OptionsList = this.postQuestionEntity.question_option;
      } else {
        this.OptionsList.push(item);
        this.OptionsList.push(item1);
        this.OptionsList.push(item2);
      }
    } else if (
      this.postQuestionEntity.question_type_id ==
      this.globals.configurationText.questionType.rankedList
    ) {
      var rankListOption = { OptionValue: '', IsCorrectOption: 0, IsActive: 0 };
      var rankListOption1 = {
        OptionValue: '',
        IsCorrectOption: 0,
        IsActive: 0,
      };
      var rankListOption2 = {
        OptionValue: '',
        IsCorrectOption: 0,
        IsActive: 0,
      };
      this.RankList = [];
      if (
        this.postQuestionEntity.question_option != '' &&
        this.postQuestionEntity.question_option != undefined &&
        this.questiontypeid ==
          this.globals.configurationText.questionType.rankedList
      ) {
        this.RankList = this.postQuestionEntity.question_option;
      } else {
        this.RankList.push(rankListOption);
        this.RankList.push(rankListOption1);
        this.RankList.push(rankListOption2);
      }
    }
  }
  storeCookie() {
    var entity = {
      question_text: this.postQuestionEntity.question_text
        ? this.postQuestionEntity.question_text
        : '',
      question_type_id: this.postQuestionEntity.question_type_id
        ? this.postQuestionEntity.question_type_id
        : '',
      community_id: this.postQuestionEntity.community_id
        ? this.postQuestionEntity.community_id
        : '',
      contact_details: this.postQuestionEntity.contact_details
        ? this.postQuestionEntity.contact_details
        : '',
      image_video: this.postQuestionEntity.image_video
        ? this.postQuestionEntity.image_video
        : '',
      // 'image' : (this.postQuestionEntity.image ?  this.postQuestionEntity.image : ''),
    };
    var d1 = new Date(),
      d2 = new Date(d1);
    d2.setMinutes(d1.getMinutes() + 30);
    this.cookieService.set('post_question', JSON.stringify(entity), d2, '/'); // To Set Cookie // To Set Cookie
    this.cookieService.set('URL', this.globals.currentLink, d2, '/');
  }
  countChar() {
    var val;
    if (this.cookieService.check('post_question')) {
      val = this.postQuestionEntity.question_text;
    } else {
      val = $('#question_text').val();
    }
    var text = this.postQuestionEntity.question_text.replace(/<[^>]+>/g, '');
    this.len = text.length;
    console.log(this.len);
    if (this.len > 1000) {
      // val.value = val.substring(0, 255);
      // $('#question_text').val(val.substring(0, 1000));
      // var questiontext = val.substring(0, 1000);
      // console.log(questiontext);
      // this.postQuestionEntity.question_text = questiontext;
      // alert("Excied")
      this.maxxxxxxLength =
        'You are not allowed to enter more than 1000 characters.';
      this.errorMessage = '';
    } else {
      this.maxxxxxxLength = '';
      this.errorMessage = '';
      $('#limit').hide();
      $('#charNum').text(1000 - this.len + ' /1000');
    }
    console.log(this.postQuestionEntity.question_text);
    debugger;
    if (
      this.postQuestionEntity.question_text != '' &&
      this.postQuestionEntity.question_text != undefined
    ) {
      this.expandSteps = true;
    }
    // else{
    //   this.expandSteps = false;
    // }
  }

  next() {
    $('#next_div').show();
    $('#next_btn').hide();
  }

  imagevideoExtensionEntity;
  getImageVideoExtensions() {
    this.HomeService.getImageVideoExtensions().then(
      (data) => {
        console.log(data);
        this.imagevideoExtensionEntity = data['data'];
      },
      (error) => {
        console.log(error);
      }
    );
  }

  imagechange(event) {
    console.log(event);
    const extensionsImage = [
      'jpg',
      'jpeg',
      'png',
      'JPEG',
      'JPG',
      'PNG',
      'heic',
      'HEIC',
      'BMP',
      'bmp',
      'webp',
      'WEBP',
    ];
    const extensionsVideo = [
      'mp4',
      'MP4',
      'MKV',
      'mkv',
      'WEBM',
      'webm',
      'avi',
      'mov',
      'mp3',
      'guv',
    ];

    this.checkImgSizeKB = this.imageSize * 1024 * 1024;
    this.checkVideoKB = this.videoSize * 1024 * 1024;

    const files = event.target.files;
    const maxLength = this.mydata.length + files.length;

    if ($('#preview').is(':visible')) {
      $('#preview').hide();
      $('#xyz').show();
      // this.mydata = [];
    }

    if (files) {
      if (maxLength > 3) {
        Swal.fire({
          type: this.globals.configurationText.sweetAlertTypeIconError,
          icon: this.globals.configurationText.sweetAlertTypeIconError,
          customClass: this.globals.configurationText.sweetAlertClassError,
          toast: true,
          title: 'You are allowed to upload up to 3 media.',
          animation: true,
          position: this.globals.configurationText.sweetAlertPosition,
          showConfirmButton: false,
          timer: this.globals.configurationText.sweetAlertTimer,
          timerProgressBar: true,
          showCancelButton: true,
          cancelButtonText: 'x',
        });
        this.postQuestionEntity.image_video = this.mydata;
        return;
      }

      for (const file of files) {
        const extension = file.name.split('.').pop();
        const isImage = extensionsImage.includes(extension);
        const isVideo = extensionsVideo.includes(extension);

        this.mydataToUpload = [];
        const isImageSizeValid = isImage && file.size < this.checkImgSizeKB;
        const isVideoSizeValid = isVideo && file.size < this.checkVideoKB;
        const filename = Date.now() + '_' + file.name.replace(/ /g, '_');
        if (isImageSizeValid || isVideoSizeValid) {
          this.mydataToUpload.push({
            fileUpload: file,
            name: filename,
          });
        }
        this.uploadImageVideo();
        if (isImage || isVideo) {
          const fileType = isImage ? 'img' : 'video';

          const reader = new FileReader();
          reader.onload = (e) => {
            this.mydata.push({
              url: e.target.result,
              type: fileType,
              size: file.size,
              isValid:
                (fileType == 'img' && file.size < this.checkImgSizeKB) ||
                (fileType == 'video' && file.size < this.checkVideoKB),
              name: filename,
            });
            console.log(this.mydata);

            this.fileUploadLength = this.mydata.length;
          };

          reader.readAsDataURL(file);
          console.log(this.mydata);
        } else {
          Swal.fire({
            type: this.globals.configurationText.sweetAlertTypeIconError,
            icon: this.globals.configurationText.sweetAlertTypeIconError,
            customClass: this.globals.configurationText.sweetAlertClassError,
            toast: true,
            title:
              'you can able to upload a file Extension with ' +
              extensionsImage +
              extensionsVideo,
            animation: true,
            position: this.globals.configurationText.sweetAlertPosition,
            showConfirmButton: false,
            timer: this.globals.configurationText.sweetAlertTimer,
            timerProgressBar: true,
            showCancelButton: true,
            cancelButtonText: 'x',
          });
          this.postQuestionEntity.image_video = this.mydata;
        }
      }
    }
  }

  removeImageVideo(idx: number, list: any) {
    console.log(this.mydata);
    console.log(this.push_Image_video);
    if (this.mydata[idx].isValid == true) {
      var removeFile = this.push_Image_video.filter(function (name) {
        return name.name == list;
      });
      var fd = new FormData();
      fd.append('name', removeFile[0].name);
      fd.append('path', removeFile[0].path);
      this.HomeService.removeImageVideo(fd).then((data) => {
        console.log(data);
        this.mydata = this.mydata.filter((u, index) => index !== idx);
        this.push_Image_video = this.push_Image_video.filter(
          (item) => item.name !== list
        );
        this.postQuestionEntity.image_video = this.push_Image_video;
        console.log(this.postQuestionEntity.image_video);
      });
    } else {
      this.mydata = this.mydata.filter((u, index) => index !== idx);
    }
  }

  uploadImageVideo() {
    if (this.mydataToUpload.length > 0) {
      console.log(this.allFiles.length);
      // this.globals.isLoading = true;
      var fd = new FormData();
      console.log(this.allFiles);
      this.uploadImageData = [];
      // this.push_Image_video = [];
      for (let i = 0; i < this.mydataToUpload.length; i++) {
        //  console.log(this.files[i]);

        var Images = this.mydataToUpload[i].name;
        this.uploadImageData.push(Images);
        fd.append(
          'image_video_file[]',
          this.mydataToUpload[i].fileUpload,
          Images
        );
      }
      fd.append('image_video', this.uploadImageData);
      this.HomeService.fileUpload(fd).then(
        (data) => {
          console.log(data);
          // this.globals.isLoading = false;
          //  this.postQuestionEntity.image_video_file = input.files[0];
          $('#video_tab').hide();
          $('#img').show();
          this.postQuestionEntity.video = '';
          this.image_video = data;
          // this.postQuestionEntity.image_video = data['Files'];
          // this.postQuestionEntity.image_video =[]
          for (let i = 0; i < this.image_video.length; i++) {
            this.push_Image_video.push({
              name: this.image_video[i].name,
              path: this.image_video[i].path,
              type: this.image_video[i].type,
            });
          }
          this.postQuestionEntity.image_video = this.push_Image_video;
          console.log(this.postQuestionEntity.image_video);

          // for (let i = 0; i < this.image_video.length; i++) {
          //   this.displayImage_Video.push({
          //     // https://api.decideit.devbyopeneyes.com/public/upload/23-08-28/1693218697592_download.png
          //     url: this.globals.profileImgeapiUrl + "/public/" +this.postQuestionEntity.image_video[i].path+"/"+ this.postQuestionEntity.image_video[i].name,
          //     type: this.postQuestionEntity.image_video[i].type

          //   });
          // }
          // console.log(this.mydata);
          // this.postQuestionEntity.image = data['Files']['name'];
          // this.postQuestionEntity.path = data['Files']['path'];

          this.startProgress(
            this.postQuestionEntity.image_video,
            this.postQuestionEntity.image_video.length - 1
          );
          //  localStorage.setItem('anshuman',JSON.stringify(this.mydata));
        },
        (error) => {
          this.globals.isLoading = false;
          console.log(error);
          if (error.error.code == 422) {
            Swal.fire({
              type: this.globals.configurationText.sweetAlertTypeIconError,
              icon: this.globals.configurationText.sweetAlertTypeIconError,
              customClass: this.globals.configurationText.sweetAlertClassError,
              toast: true,
              title: error.error.message,
              animation: true,
              position: this.globals.configurationText.sweetAlertPosition,
              showConfirmButton: false,
              timer: this.globals.configurationText.sweetAlertTimer,
              timerProgressBar: true,
              showCancelButton: true,
              cancelButtonText: 'x',
            });
          }
        }
      );
    }
  }

  async startProgress(file, index) {
    if (file != null) {
      $('.second-progress').addClass('sucess');
    }
  }

  contactAdd() {
    // if(this.postQuestionEntity.contact_details != '' && this.postQuestionEntity.contact_details != undefined)
    // {
    //   this.contact_details.push(this.postQuestionEntity.contact_details);
    // }
    var b = [];
    b = this.postQuestionEntity.contact_details.split(',');
    var a = this.postQuestionEntity.contact_details.concat(
      b.filter(
        (seccondArrayItem) =>
          !this.postQuestionEntity.contact_details.includes(seccondArrayItem)
      )
    );
    this.postQuestionEntity.contact_details = '';
    $('#contact_details').val(a);
    this.postQuestionEntity.contact_details = a;
  }
  // selectGroup(value) {
  //   //debugger;
  //   //     var array1 = ["Vijendra","Singh"];
  //   // var array2 = ["Singh", "Shakya"];

  //   // var mergedArrayWithoutDuplicates = array1.concat(
  //   //   array2.filter(seccondArrayItem => !array1.includes(seccondArrayItem))
  //   // );
  //   // console.log(mergedArrayWithoutDuplicates);
  //   this.globals.isLoading = true;
  //   this.HomeService.getGroupData(value).then(
  //     (data) => {
  //       console.log(data);
  //       this.globals.isLoading = false;

  //       if (
  //         this.postQuestionEntity.contact_details != '' &&
  //         this.postQuestionEntity.contact_details != undefined
  //       ) {
  //         // this.postQuestionEntity.contact_details.push(data['data'])
  //         //  var a = $.merge(this.postQuestionEntity.contact_details, data['data']);
  //         var a = this.postQuestionEntity.contact_details.concat(
  //           data['data'].filter(
  //             (seccondArrayItem) =>
  //               !this.postQuestionEntity.contact_details.includes(
  //                 seccondArrayItem
  //               )
  //           )
  //         );
  //         this.postQuestionEntity.contact_details = '';
  //         $('#contact_details').val(a);
  //         this.postQuestionEntity.contact_details = a;
  //         console.log(this.postQuestionEntity.contact_details);

  //         // console.log(this.contact_details);
  //         //     var result = this.postQuestionEntity.contact_details.concat(this.contact_details.filter(item =>
  //         //       !JSON.stringify(this.postQuestionEntity.contact_details).includes(JSON.stringify(item))
  //         //  ));
  //       } else {
  //         this.postQuestionEntity.contact_details = data['data'];
  //         this.contact_details = this.postQuestionEntity.contact_details.concat(
  //           data['data'].filter(
  //             (seccondArrayItem) =>
  //               !this.postQuestionEntity.contact_details.includes(
  //                 seccondArrayItem
  //               )
  //           )
  //         );
  //         // this.contact_details.push(this.postQuestionEntity.contact_details);
  //       }
  //     },
  //     (error) => {
  //       this.globals.isLoading = false;
  //       console.log(error);
  //     }
  //   );
  // }
  unique_contact = [];
  checkvalidation() {
    //debugger;
    var contact_details: any;
    if (this.globals.authData != undefined) {
      if (
        this.postQuestionEntity.contact_details != undefined &&
        this.postQuestionEntity.contact_details != ''
      ) {
        contact_details = this.postQuestionEntity.contact_details.filter(
          function (v) {
            return $.trim(v) !== '';
          }
        );
        console.log(contact_details);
        var count = 0;
        for (var i = 0; i < contact_details.length; i++) {
          var emailformat =
            /^([a-zA-Z0-9_.-]{1,})+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z]{2,3})$/;
          var phoneformat = /^([0-9()_ ]{10,14})+$/;
          if (contact_details[i] != '') {
            console.log($.trim(contact_details[i]));
            if (
              !$.trim(contact_details[i]).match(emailformat) &&
              !$.trim(contact_details[i]).match(phoneformat)
            ) {
              count++;
            }
          }
        }
        this.unique_contact = contact_details.filter(function (item, pos) {
          return contact_details.indexOf(item) != pos;
        });
        console.log(this.unique_contact);
        if (this.unique_contact.length != 0) {
          Swal.fire({
            type: this.globals.configurationText.sweetAlertTypeIconError,
            icon: this.globals.configurationText.sweetAlertTypeIconError,
            customClass: this.globals.configurationText.sweetAlertClassError,
            toast: true,
            title:
              'You have one or more duplicate email or phone numbers in invitations. Please remove duplicate email or phone.',
            animation: true,
            position: this.globals.configurationText.sweetAlertPosition,
            showConfirmButton: false,
            timer: this.globals.configurationText.sweetAlertTimer,
            timerProgressBar: true,
            showCancelButton: true,
            cancelButtonText: 'x',
          });
        }
        if (
          (count > 0 &&
            (this.errorEntity.InvitationError == '' ||
              this.errorEntity.InvitationError == undefined)) ||
          contact_details.length == 0
        ) {
          this.contactDetailsValidation = true;
          $('#contact_details').addClass('error');
        } else {
          this.contactDetailsValidation = false;
          if (
            this.errorEntity.InvitationError == '' ||
            this.errorEntity.InvitationError == undefined
          )
            $('#contact_details').removeClass('error');
        }
      } else {
        this.contactDetailsValidation = false;
      }
    }

    //^[0][1-9]\d{9}$|^[1-9]\d{9}$
    // var emailformat = '[a-zA-Z0-9.-_]{1,1}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,3}';
    // var emailformat = '[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}';
  }
  checkNumberofInvitations() {
    console.log(JSON.stringify(this.postQuestionEntity.contact_details));
    if (
      this.postQuestionEntity.contact_details != undefined &&
      this.postQuestionEntity.contact_details != '' &&
      $.type(this.postQuestionEntity.contact_details) != 'array'
    ) {
      this.postQuestionEntity.contact_details =
        this.postQuestionEntity.contact_details.split(',');
    }
    if (
      this.postQuestionEntity.contact_details != undefined &&
      this.postQuestionEntity.contact_details != ''
    ) {
      var newArray = this.postQuestionEntity.contact_details.filter(function (
        v
      ) {
        return $.trim(v) !== '';
      });
      console.log(newArray);
    }
    if (this.globals.authData != undefined) {
      if (
        this.postQuestionEntity.contact_details != undefined &&
        this.postQuestionEntity.contact_details != ''
      ) {
        if (
          newArray.length >
          this.globals.authData.plan.community_question_number_of_invitations
        ) {
          this.errorEntity.InvitationError =
            this.globals.commonTranslationText.home.form.contactDetail
              .communityQuestionInvitationErrorMessage +
            this.globals.authData.plan.community_question_number_of_invitations;
          $('#contact_details').addClass('error');
          this.contactDetailsValidation = false;
        } else {
          this.errorEntity.InvitationError = '';
          if (
            this.postQuestionEntity.contact_details != undefined &&
            this.postQuestionEntity.contact_details != '' &&
            this.contactDetailsValidation == false
          ) {
            $('#contact_details').removeClass('error');
          }
        }
        // if (this.postQuestionEntity.questionType == 0) //for private
        // {
        //   if (newArray.length > this.globals.authData.plan.number_of_invitations) {
        //     this.errorEntity.InvitationError = this.globals.commonTranslationText.home.form.contactDetail.privateQuestionInvitationErrorMessage + this.globals.authData.plan.number_of_invitations;
        //     $("#contact_details").addClass('error');
        //     this.contactDetailsValidation = false;
        //   }
        //   else {
        //     this.errorEntity.InvitationError = '';
        //     if (this.postQuestionEntity.contact_details != undefined && this.postQuestionEntity.contact_details != '' && this.contactDetailsValidation == false) {
        //       $("#contact_details").removeClass('error');
        //     }
        //   }
        // }
        // else //for community
        // {
        //   if (newArray.length > this.globals.authData.plan.community_question_number_of_invitations) {
        //     this.errorEntity.InvitationError = this.globals.commonTranslationText.home.form.contactDetail.communityQuestionInvitationErrorMessage + this.globals.authData.plan.community_question_number_of_invitations;
        //     $("#contact_details").addClass('error');
        //     this.contactDetailsValidation = false;
        //   }
        //   else {
        //     this.errorEntity.InvitationError = '';
        //     if (this.postQuestionEntity.contact_details != undefined && this.postQuestionEntity.contact_details != '' && this.contactDetailsValidation == false) {
        //       $("#contact_details").removeClass('error');
        //     }
        //   }
        // }
        console.log(this.postQuestionEntity.contact_details.length);
      } else {
        this.errorEntity.InvitationError = '';
        this.contactDetailsValidation = false;
        this.postQuestionEntity.contact_details = [];
        if (this.postQuestionEntity.required == false)
          $('#contact_details').removeClass('error');
        if (this.postQuestionEntity.questionType == 1)
          $('#contact_details').removeClass('error');
      }
    }

    // var userInvitationDetails = this.postQuestionEntity.contact_details.sp
  }
  addGroupPopUp() {
    if (this.globals.authData == undefined) {
      var d1 = new Date(),
        d2 = new Date(d1);
      d2.setMinutes(d1.getMinutes() + 30);
      this.cookieService.set(
        'errorMessage',
        this.globals.commonTranslationText.home.form.alerts.firstLogin.text,
        d2,
        '/'
      );
      window.location.href = '/login';
      if (
        this.postQuestionEntity.question_type_id ==
          this.globals.configurationText.questionType.pickList ||
        this.postQuestionEntity.question_type_id ==
          this.globals.configurationText.questionType.multiPickList
      ) {
        this.postQuestionEntity.question_option = this.OptionsList;
      } else if (
        this.postQuestionEntity.question_type_id ==
        this.globals.configurationText.questionType.rankedList
      ) {
        this.postQuestionEntity.question_option = this.RankList;
      } else {
        this.postQuestionEntity.question_option = '';
      }
      if ($('input[name="multiSelection"]').is(':checked')) {
        this.postQuestionEntity.question_type_id =
          this.globals.configurationText.questionType.pickList;
        this.postQuestionEntity.question_type_multiSelect_id =
          $('#multiSelection').val();
      } else {
        this.postQuestionEntity.question_type_multiSelect_id = '';
      }
      //debugger;
      console.log(this.questionSendOptionsList);
      for (var i = 0; i < this.questionSendOptionsList.length; i++) {
        if (
          this.questionSendOptionsList[i].sendoptioncheck == true &&
          this.questionSendOptionsList[i].id ==
            this.globals.configurationText.postQuestionSendOption
              .showResultsOnlyMe
        ) {
          this.postQuestionEntity.answer_only_to_me =
            this.questionSendOptionsList[i].id;
        }
        if (
          this.questionSendOptionsList[i].sendoptioncheck == true &&
          this.questionSendOptionsList[i].id ==
            this.globals.configurationText.postQuestionSendOption
              .recipientsInviteOthers
        ) {
          this.postQuestionEntity.invitee_can_invite_others =
            this.questionSendOptionsList[i].id;
        }
        if (
          this.questionSendOptionsList[i].sendoptioncheck == true &&
          this.questionSendOptionsList[i].id ==
            this.globals.configurationText.postQuestionSendOption
              .showInviteeListOnlyMe
        ) {
          this.postQuestionEntity.invitees_only_to_me =
            this.questionSendOptionsList[i].id;
        }
        if (
          this.questionSendOptionsList[i].sendoptioncheck == true &&
          this.questionSendOptionsList[i].id ==
            this.globals.configurationText.postQuestionSendOption
              .sendAnonymously
        ) {
          this.postQuestionEntity.send_anonymously =
            this.questionSendOptionsList[i].id;
        }
      }
      var entity = {
        question_text: this.postQuestionEntity.question_text
          ? this.postQuestionEntity.question_text
          : '',
        question_type_id: this.postQuestionEntity.question_type_id
          ? this.postQuestionEntity.question_type_id
          : '',
        question_type_multiSelect_id: this.postQuestionEntity
          .question_type_multiSelect_id
          ? this.postQuestionEntity.question_type_multiSelect_id
          : '',
        community_id: this.postQuestionEntity.community_id
          ? this.postQuestionEntity.community_id
          : '',
        contact_details: this.postQuestionEntity.contact_details
          ? this.postQuestionEntity.contact_details
          : '',
        // image_video: this.postQuestionEntity.image_video
        //   ? this.postQuestionEntity.image_video
        //   : '',
        image: this.postQuestionEntity.image
          ? this.postQuestionEntity.image
          : '',
        video: this.postQuestionEntity.video
          ? this.postQuestionEntity.video
          : '',
        image_video_path: this.postQuestionEntity.image_video_path
          ? this.postQuestionEntity.image_video_path
          : '',
        path: this.postQuestionEntity.path ? this.postQuestionEntity.path : '',
        question_option: this.postQuestionEntity.question_option
          ? this.postQuestionEntity.question_option
          : '',
        questionSendOptionsList: this.questionSendOptionsList
          ? this.questionSendOptionsList
          : '',
        questionType: this.postQuestionEntity.questionType
          ? this.postQuestionEntity.questionType
          : '0',
      };

      this.cookieService.set('post_question', JSON.stringify(entity), d2, '/'); // To Set Cookie
      this.cookieService.set('URL', this.globals.currentLink, d2, '/');
    } else {
      this.checkNumberofInvitations();
      //this.checkvalidation();
      if (
        this.errorEntity.InvitationError == '' &&
        this.contactDetailsValidation == false &&
        this.unique_contact.length == 0
      ) {
        this.contact_submitted = true;
        this.group_submitted = false;
        this.addGroupEntity = {};
        if (
          this.postQuestionEntity.contact_details != undefined &&
          this.postQuestionEntity.contact_details != ''
        ) {
          this.contact_submitted = false;
          this.addGroupPopup = true;
        }
      }
    }

    console.log(this.postQuestionEntity.contact_details);
  }
  closeaddGroupPopup() {
    this.addGroupPopup = false;
  }
  addGroup(addGroupForm) {
    this.group_submitted = true;
    if (addGroupForm.valid) {
      this.globals.isLoading = true;
      this.group_submitted = false;
      var users_details: any;
      // users_details = this.postQuestionEntity.contact_details.split(',');
      users_details = this.postQuestionEntity.contact_details.filter(function (
        v
      ) {
        return $.trim(v) !== '';
      });
      this.addGroupEntity.group_details = {
        group_name: this.addGroupEntity.group_name,
        users_details: users_details,
      };
      console.log(this.addGroupEntity);
      this.HomeService.addGroup(this.addGroupEntity).then(
        (data) => {
          console.log(data);
          this.globals.isLoading = false;
          Swal.fire({
            type: this.globals.configurationText.sweetAlertTypeIconSuccess,
            icon: this.globals.configurationText.sweetAlertTypeIconSuccess,
            customClass: this.globals.configurationText.sweetAlertClassSuccess,
            toast: true,
            title:
              this.globals.commonTranslationText.home.form.alerts.addGroup.text,
            animation: true,
            position: this.globals.configurationText.sweetAlertPosition,
            showConfirmButton: false,
            timer: this.globals.configurationText.sweetAlertTimer,
            timerProgressBar: true,
            showCancelButton: true,
            cancelButtonText: 'x',
          });
          this.addGroupPopup = false;
        },
        (error) => {
          this.globals.isLoading = false;
          console.log(error);
          if (error.error.code == 422) {
            Swal.fire({
              type: this.globals.configurationText.sweetAlertTypeIconError,
              icon: this.globals.configurationText.sweetAlertTypeIconError,
              customClass: this.globals.configurationText.sweetAlertClassError,
              toast: true,
              title: error.error.message,
              animation: true,
              position: this.globals.configurationText.sweetAlertPosition,
              showConfirmButton: false,
              timer: this.globals.configurationText.sweetAlertTimer,
              timerProgressBar: true,
              showCancelButton: true,
              cancelButtonText: 'x',
            });
          }
        }
      );
    }
  }
  btn_disabled;
  postQuestion(postQuestionForm, value) {
    if (
      this.postQuestionEntity.question_text == '' ||
      this.postQuestionEntity.question_text == undefined
    ) {
      this.errorMessage = 'Please enter a thread';
    } else {
      //debugger;
      this.errorMessage = '';
      if (this.len <= 1000) {
        console.log(this.displayImage_Video);

        this.postQuestionEntity.question_status_id = value;
        if (this.globals.authData == undefined) {
          //debugger;
          var d1 = new Date(),
            d2 = new Date(d1);
          d2.setMinutes(d1.getMinutes() + 30);

          if (
            this.postQuestionEntity.question_type_id ==
              this.globals.configurationText.questionType.pickList ||
            this.postQuestionEntity.question_type_id ==
              this.globals.configurationText.questionType.multiPickList
          ) {
            this.postQuestionEntity.question_option = this.OptionsList;
          } else if (
            this.postQuestionEntity.question_type_id ==
            this.globals.configurationText.questionType.rankedList
          ) {
            this.postQuestionEntity.question_option = this.RankList;
          } else {
            this.postQuestionEntity.question_option = '';
          }
          if ($('input[name="multiSelection"]').is(':checked')) {
            this.postQuestionEntity.question_type_id =
              this.globals.configurationText.questionType.pickList;
            this.postQuestionEntity.question_type_multiSelect_id =
              $('#multiSelection').val();
          } else {
            this.postQuestionEntity.question_type_multiSelect_id = '';
          }
          //debugger;
          console.log(this.questionSendOptionsList);
          var entity = {
            question_text: this.postQuestionEntity.question_text
              ? this.postQuestionEntity.question_text
              : '',
            question_type_id: this.postQuestionEntity.question_type_id
              ? this.postQuestionEntity.question_type_id
              : '',
            question_type_multiSelect_id: this.postQuestionEntity
              .question_type_multiSelect_id
              ? this.postQuestionEntity.question_type_multiSelect_id
              : '',
            community_id: this.postQuestionEntity.community_id
              ? this.postQuestionEntity.community_id
              : '',
            contact_details: this.postQuestionEntity.contact_details
              ? this.postQuestionEntity.contact_details
              : '',
            image: this.postQuestionEntity.image
              ? this.postQuestionEntity.image
              : '',
            video: this.postQuestionEntity.video
              ? this.postQuestionEntity.video
              : '',
            image_video_path: this.postQuestionEntity.image_video_path
              ? this.postQuestionEntity.image_video_path
              : '',
            path: this.postQuestionEntity.path
              ? this.postQuestionEntity.path
              : '',
            question_option: this.postQuestionEntity.question_option
              ? this.postQuestionEntity.question_option
              : '',
            questionSendOptionsList: this.questionSendOptionsList
              ? this.questionSendOptionsList
              : '',
            questionType: this.postQuestionEntity.questionType
              ? this.postQuestionEntity.questionType
              : '0',
          };
          console.log(entity);
          this.cookieService.set(
            'post_question',
            JSON.stringify(entity),
            d2,
            '/'
          ); // To Set Cookie
          this.cookieService.set('URL', this.globals.currentLink, d2, '/');
          // localStorage.setItem("image_video_file", JSON.stringify(this.postQuestionEntity.image_video_file));
          // this.cookieService.set('image_video_file', JSON.stringify(this.postQuestionEntity.image_video_file), d2,'/');
          this.cookieService.set(
            'errorMessage',
            this.globals.commonTranslationText.home.form.alerts.firstLogin.text,
            d2,
            '/'
          );
          window.location.href = '/login';
        } else {
          if (
            value == this.globals.configurationText.questionStatus.saveAsDraft
          ) {
            this.postQuestionEntity.required = false;
            this.contact_submitted = false;
          } else {
            this.postQuestionEntity.required = true;
            this.submitted = true;
            this.submitted1 = true;
            if (this.postQuestionEntity.questionType == 0) {
              this.contact_submitted = true;
            } else {
              this.contact_submitted = false;
            }
          }

          this.checkNumberofInvitations();
          //this.checkvalidation();
          // if (
          //   this.postQuestionEntity.questionType == 1 &&
          //   this.postQuestionEntity.community_id.length == 0
          // ) {
          //   this.errorEntity.community_id = true;
          // } else {
          //   this.errorEntity.community_id = false;
          // }
          if (
            (postQuestionForm.valid ||
              value ==
                this.globals.configurationText.questionStatus.saveAsDraft) &&
            (this.errorEntity.InvitationError == '' ||
              this.errorEntity.InvitationError == undefined) &&
            this.contactDetailsValidation == false &&
            this.unique_contact.length == 0
          ) {
            // this.errorEntity.community_id == false
            const isWhitespace =
              (
                this.postQuestionEntity.question_text.replace(/<[^>]+>/g, '') ||
                ''
              ).trim().length === 0;
            if (isWhitespace) {
              if (this.len > 0) {
                this.errorMessage = 'Please enter a thread';
              }
              // Swal.fire({
              //   type: this.globals.configurationText.sweetAlertTypeIconError,
              //   icon: this.globals.configurationText.sweetAlertTypeIconError,
              //   customClass: this.globals.configurationText.sweetAlertClassError,
              //   toast: true,
              //   title: 'Please enter a thread',
              //   animation: true,
              //   position: this.globals.configurationText.sweetAlertPosition,
              //   showConfirmButton: false,
              //   timer: this.globals.configurationText.sweetAlertTimer,
              //   timerProgressBar: true,
              //   showCancelButton: true,
              //   cancelButtonText: 'x',
              // });
            } else {
              if (
                $('#xyz').is(':visible') == true &&
                $('#error').is(':visible') == true
              ) {
                // Swal.fire({
                //   type: this.globals.configurationText.sweetAlertTypeIconError,
                //   icon: this.globals.configurationText.sweetAlertTypeIconError,
                //   customClass: this.globals.configurationText.sweetAlertClassError,
                //   toast: true,
                //   title: 'The size of some of the uploaded files is not valid.',
                //   animation: true,
                //   position: this.globals.configurationText.sweetAlertPosition,
                //   showConfirmButton: false,
                //   timer: this.globals.configurationText.sweetAlertTimer,
                //   timerProgressBar: true,
                //   showCancelButton: true,
                //   cancelButtonText: 'x',
                // });
              } else {
                if (
                  postQuestionForm.form.value.question_type_id ==
                  '176c9480-1a9b-11eb-b17b-c85b767b9463'
                ) {
                  if (this.OptionsList.length == 2) {
                    if (
                      postQuestionForm.form.value.OptionValue0 ==
                      postQuestionForm.form.value.OptionValue1
                    ) {
                      Swal.fire({
                        icon: 'warning',
                        type: 'warning',
                        title: 'Oops..',
                        text: 'Options Should be Unique',
                        showCancelButton: false,
                        showConfirmButton: true,
                        confirmButtonClass: 'theme_btn',
                        confirmButtonText: 'Ok',
                        allowOutsideClick: false,
                      });
                    } else {
                      {
                        var fd = new FormData();
                        if (
                          this.postQuestionEntity.question_type_id ==
                            this.globals.configurationText.questionType
                              .pickList ||
                          this.postQuestionEntity.question_type_id ==
                            this.globals.configurationText.questionType
                              .multiPickList
                        ) {
                          this.postQuestionEntity.question_option =
                            this.OptionsList.filter(function (v) {
                              return $.trim(v.OptionValue) !== '';
                            });
                        } else if (
                          this.postQuestionEntity.question_type_id ==
                          this.globals.configurationText.questionType.rankedList
                        ) {
                          this.postQuestionEntity.question_option =
                            this.RankList.filter(function (v) {
                              return $.trim(v.OptionValue) !== '';
                            });
                        }
                        if ($('input[name="multiSelection"]').is(':checked')) {
                          this.postQuestionEntity.question_type_id =
                            $('#multiSelection').val();
                        }
                        if (
                          this.postQuestionEntity.image_video_path != '' &&
                          this.postQuestionEntity.image_video_path != undefined
                        ) {
                          fd.append(
                            'image_video',
                            this.postQuestionEntity.image_video
                          );
                          fd.append('path', this.postQuestionEntity.path);
                          fd.append(
                            'name',
                            this.postQuestionEntity.image_video
                          );
                        }
                        //debugger;
                        if (this.postQuestionEntity.image_video == undefined) {
                          fd.append('image_video_url', '[]');
                        } else {
                          fd.append(
                            'image_video_url',
                            JSON.stringify(this.postQuestionEntity.image_video)
                          );
                        }
                        // var users_details = [];
                        // users_details = this.postQuestionEntity.contact_details.split(",");
                        if (
                          this.postQuestionEntity.contact_details !=
                            undefined &&
                          $.type(this.postQuestionEntity.contact_details) !=
                            'array'
                        ) {
                          this.postQuestionEntity.contact_details =
                            this.postQuestionEntity.contact_details.split(',');
                        }
                        //  else {
                        //   this.postQuestionEntity.contact_details = [''];
                        // }
                        console.log(this.postQuestionEntity.contact_details);
                        console.log(
                          JSON.stringify(
                            this.postQuestionEntity.contact_details
                          )
                        );
                        fd.append(
                          'question_text',
                          this.postQuestionEntity.question_text
                        );
                        fd.append('post_as', this.postQuestionEntity.postas);
                        fd.append(
                          'question_type_id',
                          this.postQuestionEntity.question_type_id
                        );
                        //debugger;
                        if (this.postQuestionEntity.question_option) {
                          fd.append(
                            'question_option',
                            JSON.stringify(
                              this.postQuestionEntity.question_option
                            )
                          );
                        }
                        if (
                          this.postQuestionEntity.contact_details !=
                            undefined &&
                          this.postQuestionEntity.contact_details != ''
                        ) {
                          var phoneformat = /^([0-9()_ ]{10,14})+$/;
                          for (
                            var a = 0;
                            a < this.postQuestionEntity.contact_details.length;
                            a++
                          ) {
                            if (
                              this.postQuestionEntity.contact_details[a].match(
                                phoneformat
                              )
                            ) {
                              this.postQuestionEntity.contact_details[a] =
                                this.postQuestionEntity.contact_details[
                                  a
                                ].replace(/\D/g, '');
                            }
                          }
                          // this.postQuestionEntity.contact_details.filter(function(v){return v!==''})
                          fd.append(
                            'contact_details',
                            JSON.stringify(
                              this.postQuestionEntity.contact_details.filter(
                                function (v) {
                                  return $.trim(v) !== '';
                                }
                              )
                            )
                          );
                        }
                        var community_id =
                          '183c0d0d-508d-11ee-b584-9840bb1952c8';
                        this.UserCommunity.push(community_id);
                        this.postQuestionEntity.community_id =
                          this.UserCommunity;
                        fd.append(
                          'community_id',
                          JSON.stringify(this.postQuestionEntity.community_id)
                        );
                        fd.append(
                          'answer_only_to_me',
                          this.postQuestionEntity.answer_only_to_me
                            ? this.postQuestionEntity.answer_only_to_me
                            : ''
                        );
                        fd.append(
                          'invitee_can_invite_others',
                          this.postQuestionEntity.invitee_can_invite_others
                            ? this.postQuestionEntity.invitee_can_invite_others
                            : ''
                        );
                        fd.append(
                          'invitees_only_to_me',
                          this.postQuestionEntity.invitees_only_to_me
                            ? this.postQuestionEntity.invitees_only_to_me
                            : ''
                        );
                        fd.append(
                          'send_anonymously',
                          this.postQuestionEntity.send_anonymously
                            ? this.postQuestionEntity.send_anonymously
                            : ''
                        );
                        fd.append('question_status_id', value);
                        fd.append('user_id', this.globals.authData.id);
                        this.btn_disabled = true;
                        if (
                          value ==
                          this.globals.configurationText.questionStatus
                            .saveAsDraft
                        ) {
                          setTimeout(() => {
                            this.globals.postQuestionText =
                              'Please wait, saving thread as draft…';
                            this.globals.postQuestionLoading = true;
                          }, 300);
                        } else {
                          this.globals.postQuestionText =
                            'Please wait, publishing your thread…';
                          this.globals.postQuestionLoading = true;
                        }
                        this.HomeService.postQuestion(fd).then(
                          (data) => {
                            console.log(data);
                            if (
                              value ==
                              this.globals.configurationText.questionStatus
                                .saveAsDraft
                            ) {
                              var title = 'Save As Draft';
                              var text = 'Thread has been drafted successfully';
                            } else {
                              var title = 'Post Thread';
                              var text = 'Thread has been posted successfully';
                            }
                            this.cookieService.set(
                              'successMessage',
                              text,
                              this.globals.cookieMessageExpireTime,
                              '/'
                            );
                            this.cookieService.delete('post_question');
                            this.postQuestionEntity = {};
                            postQuestionForm.form.markAsPristine();
                            window.location.href = '/userDashboard';
                            this.contact_submitted = false;
                            this.submitted = false;
                            this.submitted1 = false;
                            this.postQuestionEntity.question_type_id = '';
                            this.postQuestionEntity.community_id = '';
                            this.questionSendOptionsList = this.sendOptionsList;
                            this.globals.postQuestionLoading = false;
                            $('#question_text').val('');
                            this.countChar();
                            // Swal.fire({
                            //   icon: this.globals.commonTranslationText.home.form.alerts.postQuestion.type,
                            //   type: this.globals.commonTranslationText.home.form.alerts.postQuestion.type,
                            //   title: title,
                            //   text: text,
                            //   showCancelButton: false,
                            //   showConfirmButton: true,
                            //   confirmButtonClass: 'theme_btn',
                            //   confirmButtonText: 'Ok',
                            //   allowOutsideClick: false,
                            // }).then((result) => {
                            //   this.cookieService.delete('post_question');
                            //   this.postQuestionEntity = {};
                            //   postQuestionForm.form.markAsPristine();
                            //   window.location.href = '/userDashboard';
                            //   this.contact_submitted = false;
                            //   this.submitted = false;
                            //   this.submitted1 = false;
                            //   this.postQuestionEntity.question_type_id = '';
                            //   this.postQuestionEntity.community_id = '';
                            //   this.questionSendOptionsList = this.sendOptionsList;
                            //   this.globals.isLoading = false;
                            //   $('#question_text').val('');
                            //   this.countChar();
                            //   console.log(this.postQuestionEntity.question_text);
                            // });
                          },
                          (error) => {
                            this.globals.postQuestionLoading = false;
                            console.log(error);
                            if (error.status == 422) {
                              Swal.fire({
                                icon: 'warning',
                                type: 'warning',
                                title: 'Oops..',
                                text: error.error.message,
                                showCancelButton: false,
                                showConfirmButton: true,
                                confirmButtonClass: 'theme_btn',
                                confirmButtonText: 'Ok',
                                allowOutsideClick: false,
                              });
                            }
                            this.btn_disabled = false;
                          }
                        );
                        console.log(this.postQuestionEntity);
                      }
                    }
                  } else if (this.OptionsList.length == 3) {
                    if (
                      postQuestionForm.form.value.OptionValue0 ==
                        postQuestionForm.form.value.OptionValue1 ||
                      postQuestionForm.form.value.OptionValue0 ==
                        postQuestionForm.form.value.OptionValue2 ||
                      postQuestionForm.form.value.OptionValue1 ==
                        postQuestionForm.form.value.OptionValue2
                    ) {
                      Swal.fire({
                        icon: 'warning',
                        type: 'warning',
                        title: 'Oops..',
                        text: 'Options Should be Unique',
                        showCancelButton: false,
                        showConfirmButton: true,
                        confirmButtonClass: 'theme_btn',
                        confirmButtonText: 'Ok',
                        allowOutsideClick: false,
                      });
                    } else {
                      {
                        var fd = new FormData();
                        if (
                          this.postQuestionEntity.question_type_id ==
                            this.globals.configurationText.questionType
                              .pickList ||
                          this.postQuestionEntity.question_type_id ==
                            this.globals.configurationText.questionType
                              .multiPickList
                        ) {
                          this.postQuestionEntity.question_option =
                            this.OptionsList.filter(function (v) {
                              return $.trim(v.OptionValue) !== '';
                            });
                        } else if (
                          this.postQuestionEntity.question_type_id ==
                          this.globals.configurationText.questionType.rankedList
                        ) {
                          this.postQuestionEntity.question_option =
                            this.RankList.filter(function (v) {
                              return $.trim(v.OptionValue) !== '';
                            });
                        }
                        if ($('input[name="multiSelection"]').is(':checked')) {
                          this.postQuestionEntity.question_type_id =
                            $('#multiSelection').val();
                        }
                        if (
                          this.postQuestionEntity.image_video_path != '' &&
                          this.postQuestionEntity.image_video_path != undefined
                        ) {
                          fd.append(
                            'image_video',
                            this.postQuestionEntity.image_video
                          );
                          fd.append('path', this.postQuestionEntity.path);
                          fd.append(
                            'name',
                            this.postQuestionEntity.image_video
                          );
                        }
                        //debugger;
                        if (this.postQuestionEntity.image_video == undefined) {
                          fd.append('image_video_url', '[]');
                        } else {
                          fd.append(
                            'image_video_url',
                            JSON.stringify(this.postQuestionEntity.image_video)
                          );
                        }
                        // var users_details = [];
                        // users_details = this.postQuestionEntity.contact_details.split(",");
                        if (
                          this.postQuestionEntity.contact_details !=
                            undefined &&
                          $.type(this.postQuestionEntity.contact_details) !=
                            'array'
                        ) {
                          this.postQuestionEntity.contact_details =
                            this.postQuestionEntity.contact_details.split(',');
                        }
                        //  else {
                        //   this.postQuestionEntity.contact_details = [''];
                        // }
                        console.log(this.postQuestionEntity.contact_details);
                        console.log(
                          JSON.stringify(
                            this.postQuestionEntity.contact_details
                          )
                        );
                        fd.append(
                          'question_text',
                          this.postQuestionEntity.question_text
                        );
                        fd.append('post_as', this.postQuestionEntity.postas);
                        console.log(this.postQuestionEntity.postas);
                        fd.append(
                          'question_type_id',
                          this.postQuestionEntity.question_type_id
                        );
                        //debugger;
                        if (this.postQuestionEntity.question_option) {
                          fd.append(
                            'question_option',
                            JSON.stringify(
                              this.postQuestionEntity.question_option
                            )
                          );
                        }
                        if (
                          this.postQuestionEntity.contact_details !=
                            undefined &&
                          this.postQuestionEntity.contact_details != ''
                        ) {
                          var phoneformat = /^([0-9()_ ]{10,14})+$/;
                          for (
                            var a = 0;
                            a < this.postQuestionEntity.contact_details.length;
                            a++
                          ) {
                            if (
                              this.postQuestionEntity.contact_details[a].match(
                                phoneformat
                              )
                            ) {
                              this.postQuestionEntity.contact_details[a] =
                                this.postQuestionEntity.contact_details[
                                  a
                                ].replace(/\D/g, '');
                            }
                          }
                          // this.postQuestionEntity.contact_details.filter(function(v){return v!==''})
                          fd.append(
                            'contact_details',
                            JSON.stringify(
                              this.postQuestionEntity.contact_details.filter(
                                function (v) {
                                  return $.trim(v) !== '';
                                }
                              )
                            )
                          );
                        }
                        var community_id =
                          '183c0d0d-508d-11ee-b584-9840bb1952c8';
                        this.UserCommunity.push(community_id);
                        this.postQuestionEntity.community_id =
                          this.UserCommunity;
                        fd.append(
                          'community_id',
                          JSON.stringify(this.postQuestionEntity.community_id)
                        );
                        fd.append(
                          'answer_only_to_me',
                          this.postQuestionEntity.answer_only_to_me
                            ? this.postQuestionEntity.answer_only_to_me
                            : ''
                        );
                        fd.append(
                          'invitee_can_invite_others',
                          this.postQuestionEntity.invitee_can_invite_others
                            ? this.postQuestionEntity.invitee_can_invite_others
                            : ''
                        );
                        fd.append(
                          'invitees_only_to_me',
                          this.postQuestionEntity.invitees_only_to_me
                            ? this.postQuestionEntity.invitees_only_to_me
                            : ''
                        );
                        fd.append(
                          'send_anonymously',
                          this.postQuestionEntity.send_anonymously
                            ? this.postQuestionEntity.send_anonymously
                            : ''
                        );
                        fd.append('question_status_id', value);
                        fd.append('user_id', this.globals.authData.id);
                        this.btn_disabled = true;
                        if (
                          value ==
                          this.globals.configurationText.questionStatus
                            .saveAsDraft
                        ) {
                          setTimeout(() => {
                            this.globals.postQuestionText =
                              'Please wait, saving thread as draft…';
                            this.globals.postQuestionLoading = true;
                          }, 300);
                        } else {
                          this.globals.postQuestionText =
                            'Please wait, publishing your thread…';
                          this.globals.postQuestionLoading = true;
                        }
                        this.HomeService.postQuestion(fd).then(
                          (data) => {
                            console.log(data);
                            if (
                              value ==
                              this.globals.configurationText.questionStatus
                                .saveAsDraft
                            ) {
                              var title = 'Save As Draft';
                              var text = 'Thread has been drafted successfully';
                            } else {
                              var title = 'Post Thread';
                              var text = 'Thread has been posted successfully';
                            }
                            this.cookieService.set(
                              'successMessage',
                              text,
                              this.globals.cookieMessageExpireTime,
                              '/'
                            );
                            this.cookieService.delete('post_question');
                            this.postQuestionEntity = {};
                            postQuestionForm.form.markAsPristine();
                            window.location.href = '/userDashboard';
                            this.contact_submitted = false;
                            this.submitted = false;
                            this.submitted1 = false;
                            this.postQuestionEntity.question_type_id = '';
                            this.postQuestionEntity.community_id = '';
                            this.questionSendOptionsList = this.sendOptionsList;
                            this.globals.postQuestionLoading = false;
                            $('#question_text').val('');
                            this.countChar();
                            // Swal.fire({
                            //   icon: this.globals.commonTranslationText.home.form.alerts.postQuestion.type,
                            //   type: this.globals.commonTranslationText.home.form.alerts.postQuestion.type,
                            //   title: title,
                            //   text: text,
                            //   showCancelButton: false,
                            //   showConfirmButton: true,
                            //   confirmButtonClass: 'theme_btn',
                            //   confirmButtonText: 'Ok',
                            //   allowOutsideClick: false,
                            // }).then((result) => {
                            //   this.cookieService.delete('post_question');
                            //   this.postQuestionEntity = {};
                            //   postQuestionForm.form.markAsPristine();
                            //   window.location.href = '/userDashboard';
                            //   this.contact_submitted = false;
                            //   this.submitted = false;
                            //   this.submitted1 = false;
                            //   this.postQuestionEntity.question_type_id = '';
                            //   this.postQuestionEntity.community_id = '';
                            //   this.questionSendOptionsList = this.sendOptionsList;
                            //   this.globals.isLoading = false;
                            //   $('#question_text').val('');
                            //   this.countChar();
                            //   console.log(this.postQuestionEntity.question_text);
                            // });
                          },
                          (error) => {
                            this.globals.postQuestionLoading = false;
                            console.log(error);
                            if (error.status == 422) {
                              Swal.fire({
                                icon: 'warning',
                                type: 'warning',
                                title: 'Oops..',
                                text: error.error.message,
                                showCancelButton: false,
                                showConfirmButton: true,
                                confirmButtonClass: 'theme_btn',
                                confirmButtonText: 'Ok',
                                allowOutsideClick: false,
                              });
                            }
                            this.btn_disabled = false;
                          }
                        );
                        console.log(this.postQuestionEntity);
                      }
                    }
                  } else if (this.OptionsList.length == 4) {
                    if (
                      postQuestionForm.form.value.OptionValue0 ==
                        postQuestionForm.form.value.OptionValue1 ||
                      postQuestionForm.form.value.OptionValue0 ==
                        postQuestionForm.form.value.OptionValue2 ||
                      postQuestionForm.form.value.OptionValue0 ==
                        postQuestionForm.form.value.OptionValue3 ||
                      postQuestionForm.form.value.OptionValue1 ==
                        postQuestionForm.form.value.OptionValue2 ||
                      postQuestionForm.form.value.OptionValue1 ==
                        postQuestionForm.form.value.OptionValue3 ||
                      postQuestionForm.form.value.OptionValue2 ==
                        postQuestionForm.form.value.OptionValue3
                    ) {
                      Swal.fire({
                        icon: 'warning',
                        type: 'warning',
                        title: 'Oops..',
                        text: 'Options Should be Unique',
                        showCancelButton: false,
                        showConfirmButton: true,
                        confirmButtonClass: 'theme_btn',
                        confirmButtonText: 'Ok',
                        allowOutsideClick: false,
                      });
                    } else {
                      {
                        var fd = new FormData();
                        if (
                          this.postQuestionEntity.question_type_id ==
                            this.globals.configurationText.questionType
                              .pickList ||
                          this.postQuestionEntity.question_type_id ==
                            this.globals.configurationText.questionType
                              .multiPickList
                        ) {
                          this.postQuestionEntity.question_option =
                            this.OptionsList.filter(function (v) {
                              return $.trim(v.OptionValue) !== '';
                            });
                        } else if (
                          this.postQuestionEntity.question_type_id ==
                          this.globals.configurationText.questionType.rankedList
                        ) {
                          this.postQuestionEntity.question_option =
                            this.RankList.filter(function (v) {
                              return $.trim(v.OptionValue) !== '';
                            });
                        }
                        if ($('input[name="multiSelection"]').is(':checked')) {
                          this.postQuestionEntity.question_type_id =
                            $('#multiSelection').val();
                        }
                        if (
                          this.postQuestionEntity.image_video_path != '' &&
                          this.postQuestionEntity.image_video_path != undefined
                        ) {
                          fd.append(
                            'image_video',
                            this.postQuestionEntity.image_video
                          );
                          fd.append('path', this.postQuestionEntity.path);
                          fd.append(
                            'name',
                            this.postQuestionEntity.image_video
                          );
                        }
                        //debugger;
                        if (this.postQuestionEntity.image_video == undefined) {
                          fd.append('image_video_url', '[]');
                        } else {
                          fd.append(
                            'image_video_url',
                            JSON.stringify(this.postQuestionEntity.image_video)
                          );
                        }
                        // var users_details = [];
                        // users_details = this.postQuestionEntity.contact_details.split(",");
                        if (
                          this.postQuestionEntity.contact_details !=
                            undefined &&
                          $.type(this.postQuestionEntity.contact_details) !=
                            'array'
                        ) {
                          this.postQuestionEntity.contact_details =
                            this.postQuestionEntity.contact_details.split(',');
                        }
                        //  else {
                        //   this.postQuestionEntity.contact_details = [''];
                        // }
                        console.log(this.postQuestionEntity.contact_details);
                        console.log(
                          JSON.stringify(
                            this.postQuestionEntity.contact_details
                          )
                        );
                        fd.append(
                          'question_text',
                          this.postQuestionEntity.question_text
                        );
                        fd.append('post_as', this.postQuestionEntity.postas);
                        fd.append(
                          'question_type_id',
                          this.postQuestionEntity.question_type_id
                        );
                        //debugger;
                        if (this.postQuestionEntity.question_option) {
                          fd.append(
                            'question_option',
                            JSON.stringify(
                              this.postQuestionEntity.question_option
                            )
                          );
                        }
                        if (
                          this.postQuestionEntity.contact_details !=
                            undefined &&
                          this.postQuestionEntity.contact_details != ''
                        ) {
                          var phoneformat = /^([0-9()_ ]{10,14})+$/;
                          for (
                            var a = 0;
                            a < this.postQuestionEntity.contact_details.length;
                            a++
                          ) {
                            if (
                              this.postQuestionEntity.contact_details[a].match(
                                phoneformat
                              )
                            ) {
                              this.postQuestionEntity.contact_details[a] =
                                this.postQuestionEntity.contact_details[
                                  a
                                ].replace(/\D/g, '');
                            }
                          }
                          // this.postQuestionEntity.contact_details.filter(function(v){return v!==''})
                          fd.append(
                            'contact_details',
                            JSON.stringify(
                              this.postQuestionEntity.contact_details.filter(
                                function (v) {
                                  return $.trim(v) !== '';
                                }
                              )
                            )
                          );
                        }
                        var community_id =
                          '183c0d0d-508d-11ee-b584-9840bb1952c8';
                        this.UserCommunity.push(community_id);
                        this.postQuestionEntity.community_id =
                          this.UserCommunity;
                        fd.append(
                          'community_id',
                          JSON.stringify(this.postQuestionEntity.community_id)
                        );
                        fd.append(
                          'answer_only_to_me',
                          this.postQuestionEntity.answer_only_to_me
                            ? this.postQuestionEntity.answer_only_to_me
                            : ''
                        );
                        fd.append(
                          'invitee_can_invite_others',
                          this.postQuestionEntity.invitee_can_invite_others
                            ? this.postQuestionEntity.invitee_can_invite_others
                            : ''
                        );
                        fd.append(
                          'invitees_only_to_me',
                          this.postQuestionEntity.invitees_only_to_me
                            ? this.postQuestionEntity.invitees_only_to_me
                            : ''
                        );
                        fd.append(
                          'send_anonymously',
                          this.postQuestionEntity.send_anonymously
                            ? this.postQuestionEntity.send_anonymously
                            : ''
                        );
                        fd.append('question_status_id', value);
                        fd.append('user_id', this.globals.authData.id);
                        this.btn_disabled = true;
                        if (
                          value ==
                          this.globals.configurationText.questionStatus
                            .saveAsDraft
                        ) {
                          setTimeout(() => {
                            this.globals.postQuestionText =
                              'Please wait, saving thread as draft…';
                            this.globals.postQuestionLoading = true;
                          }, 300);
                        } else {
                          this.globals.postQuestionText =
                            'Please wait, publishing your thread…';
                          this.globals.postQuestionLoading = true;
                        }
                        this.HomeService.postQuestion(fd).then(
                          (data) => {
                            console.log(data);
                            if (
                              value ==
                              this.globals.configurationText.questionStatus
                                .saveAsDraft
                            ) {
                              var title = 'Save As Draft';
                              var text = 'Thread has been drafted successfully';
                            } else {
                              var title = 'Post Thread';
                              var text = 'Thread has been posted successfully';
                            }
                            this.cookieService.set(
                              'successMessage',
                              text,
                              this.globals.cookieMessageExpireTime,
                              '/'
                            );
                            this.cookieService.delete('post_question');
                            this.postQuestionEntity = {};
                            postQuestionForm.form.markAsPristine();
                            window.location.href = '/userDashboard';
                            this.contact_submitted = false;
                            this.submitted = false;
                            this.submitted1 = false;
                            this.postQuestionEntity.question_type_id = '';
                            this.postQuestionEntity.community_id = '';
                            this.questionSendOptionsList = this.sendOptionsList;
                            this.globals.postQuestionLoading = false;
                            $('#question_text').val('');
                            this.countChar();
                            // Swal.fire({
                            //   icon: this.globals.commonTranslationText.home.form.alerts.postQuestion.type,
                            //   type: this.globals.commonTranslationText.home.form.alerts.postQuestion.type,
                            //   title: title,
                            //   text: text,
                            //   showCancelButton: false,
                            //   showConfirmButton: true,
                            //   confirmButtonClass: 'theme_btn',
                            //   confirmButtonText: 'Ok',
                            //   allowOutsideClick: false,
                            // }).then((result) => {
                            //   this.cookieService.delete('post_question');
                            //   this.postQuestionEntity = {};
                            //   postQuestionForm.form.markAsPristine();
                            //   window.location.href = '/userDashboard';
                            //   this.contact_submitted = false;
                            //   this.submitted = false;
                            //   this.submitted1 = false;
                            //   this.postQuestionEntity.question_type_id = '';
                            //   this.postQuestionEntity.community_id = '';
                            //   this.questionSendOptionsList = this.sendOptionsList;
                            //   this.globals.isLoading = false;
                            //   $('#question_text').val('');
                            //   this.countChar();
                            //   console.log(this.postQuestionEntity.question_text);
                            // });
                          },
                          (error) => {
                            this.globals.postQuestionLoading = false;
                            console.log(error);
                            if (error.status == 422) {
                              Swal.fire({
                                icon: 'warning',
                                type: 'warning',
                                title: 'Oops..',
                                text: error.error.message,
                                showCancelButton: false,
                                showConfirmButton: true,
                                confirmButtonClass: 'theme_btn',
                                confirmButtonText: 'Ok',
                                allowOutsideClick: false,
                              });
                            }
                            this.btn_disabled = false;
                          }
                        );
                        console.log(this.postQuestionEntity);
                      }
                    }
                  } else if (this.OptionsList.length == 5) {
                    if (
                      postQuestionForm.form.value.OptionValue0 ==
                        postQuestionForm.form.value.OptionValue1 ||
                      postQuestionForm.form.value.OptionValue0 ==
                        postQuestionForm.form.value.OptionValue2 ||
                      postQuestionForm.form.value.OptionValue0 ==
                        postQuestionForm.form.value.OptionValue3 ||
                      postQuestionForm.form.value.OptionValue0 ==
                        postQuestionForm.form.value.OptionValue4 ||
                      postQuestionForm.form.value.OptionValue1 ==
                        postQuestionForm.form.value.OptionValue2 ||
                      postQuestionForm.form.value.OptionValue1 ==
                        postQuestionForm.form.value.OptionValue3 ||
                      postQuestionForm.form.value.OptionValue1 ==
                        postQuestionForm.form.value.OptionValue4 ||
                      postQuestionForm.form.value.OptionValue2 ==
                        postQuestionForm.form.value.OptionValue3 ||
                      postQuestionForm.form.value.OptionValue2 ==
                        postQuestionForm.form.value.OptionValue4 ||
                      postQuestionForm.form.value.OptionValue3 ==
                        postQuestionForm.form.value.OptionValue4
                    ) {
                      Swal.fire({
                        icon: 'warning',
                        type: 'warning',
                        title: 'Oops..',
                        text: 'Options Should be Unique',
                        showCancelButton: false,
                        showConfirmButton: true,
                        confirmButtonClass: 'theme_btn',
                        confirmButtonText: 'Ok',
                        allowOutsideClick: false,
                      });
                    } else {
                      {
                        var fd = new FormData();
                        if (
                          this.postQuestionEntity.question_type_id ==
                            this.globals.configurationText.questionType
                              .pickList ||
                          this.postQuestionEntity.question_type_id ==
                            this.globals.configurationText.questionType
                              .multiPickList
                        ) {
                          this.postQuestionEntity.question_option =
                            this.OptionsList.filter(function (v) {
                              return $.trim(v.OptionValue) !== '';
                            });
                        } else if (
                          this.postQuestionEntity.question_type_id ==
                          this.globals.configurationText.questionType.rankedList
                        ) {
                          this.postQuestionEntity.question_option =
                            this.RankList.filter(function (v) {
                              return $.trim(v.OptionValue) !== '';
                            });
                        }
                        if ($('input[name="multiSelection"]').is(':checked')) {
                          this.postQuestionEntity.question_type_id =
                            $('#multiSelection').val();
                        }
                        if (
                          this.postQuestionEntity.image_video_path != '' &&
                          this.postQuestionEntity.image_video_path != undefined
                        ) {
                          fd.append(
                            'image_video',
                            this.postQuestionEntity.image_video
                          );
                          fd.append('path', this.postQuestionEntity.path);
                          fd.append(
                            'name',
                            this.postQuestionEntity.image_video
                          );
                        }
                        //debugger;
                        if (this.postQuestionEntity.image_video == undefined) {
                          fd.append('image_video_url', '[]');
                        } else {
                          fd.append(
                            'image_video_url',
                            JSON.stringify(this.postQuestionEntity.image_video)
                          );
                        }
                        // var users_details = [];
                        // users_details = this.postQuestionEntity.contact_details.split(",");
                        if (
                          this.postQuestionEntity.contact_details !=
                            undefined &&
                          $.type(this.postQuestionEntity.contact_details) !=
                            'array'
                        ) {
                          this.postQuestionEntity.contact_details =
                            this.postQuestionEntity.contact_details.split(',');
                        }
                        //  else {
                        //   this.postQuestionEntity.contact_details = [''];
                        // }
                        console.log(this.postQuestionEntity.contact_details);
                        console.log(
                          JSON.stringify(
                            this.postQuestionEntity.contact_details
                          )
                        );
                        fd.append(
                          'question_text',
                          this.postQuestionEntity.question_text
                        );
                        fd.append('post_as', this.postQuestionEntity.postas);
                        fd.append(
                          'question_type_id',
                          this.postQuestionEntity.question_type_id
                        );
                        //debugger;
                        if (this.postQuestionEntity.question_option) {
                          fd.append(
                            'question_option',
                            JSON.stringify(
                              this.postQuestionEntity.question_option
                            )
                          );
                        }
                        if (
                          this.postQuestionEntity.contact_details !=
                            undefined &&
                          this.postQuestionEntity.contact_details != ''
                        ) {
                          var phoneformat = /^([0-9()_ ]{10,14})+$/;
                          for (
                            var a = 0;
                            a < this.postQuestionEntity.contact_details.length;
                            a++
                          ) {
                            if (
                              this.postQuestionEntity.contact_details[a].match(
                                phoneformat
                              )
                            ) {
                              this.postQuestionEntity.contact_details[a] =
                                this.postQuestionEntity.contact_details[
                                  a
                                ].replace(/\D/g, '');
                            }
                          }
                          // this.postQuestionEntity.contact_details.filter(function(v){return v!==''})
                          fd.append(
                            'contact_details',
                            JSON.stringify(
                              this.postQuestionEntity.contact_details.filter(
                                function (v) {
                                  return $.trim(v) !== '';
                                }
                              )
                            )
                          );
                        }
                        var community_id =
                          '183c0d0d-508d-11ee-b584-9840bb1952c8';
                        this.UserCommunity.push(community_id);
                        this.postQuestionEntity.community_id =
                          this.UserCommunity;
                        fd.append(
                          'community_id',
                          JSON.stringify(this.postQuestionEntity.community_id)
                        );
                        fd.append(
                          'answer_only_to_me',
                          this.postQuestionEntity.answer_only_to_me
                            ? this.postQuestionEntity.answer_only_to_me
                            : ''
                        );
                        fd.append(
                          'invitee_can_invite_others',
                          this.postQuestionEntity.invitee_can_invite_others
                            ? this.postQuestionEntity.invitee_can_invite_others
                            : ''
                        );
                        fd.append(
                          'invitees_only_to_me',
                          this.postQuestionEntity.invitees_only_to_me
                            ? this.postQuestionEntity.invitees_only_to_me
                            : ''
                        );
                        fd.append(
                          'send_anonymously',
                          this.postQuestionEntity.send_anonymously
                            ? this.postQuestionEntity.send_anonymously
                            : ''
                        );
                        fd.append('question_status_id', value);
                        fd.append('user_id', this.globals.authData.id);
                        this.btn_disabled = true;
                        if (
                          value ==
                          this.globals.configurationText.questionStatus
                            .saveAsDraft
                        ) {
                          setTimeout(() => {
                            this.globals.postQuestionText =
                              'Please wait, saving thread as draft…';
                            this.globals.postQuestionLoading = true;
                          }, 300);
                        } else {
                          this.globals.postQuestionText =
                            'Please wait, publishing your thread…';
                          this.globals.postQuestionLoading = true;
                        }
                        this.HomeService.postQuestion(fd).then(
                          (data) => {
                            console.log(data);
                            if (
                              value ==
                              this.globals.configurationText.questionStatus
                                .saveAsDraft
                            ) {
                              var title = 'Save As Draft';
                              var text = 'Thread has been drafted successfully';
                            } else {
                              var title = 'Post Thread';
                              var text = 'Thread has been posted successfully';
                            }
                            this.cookieService.set(
                              'successMessage',
                              text,
                              this.globals.cookieMessageExpireTime,
                              '/'
                            );
                            this.cookieService.delete('post_question');
                            this.postQuestionEntity = {};
                            postQuestionForm.form.markAsPristine();
                            window.location.href = '/userDashboard';
                            this.contact_submitted = false;
                            this.submitted = false;
                            this.submitted1 = false;
                            this.postQuestionEntity.question_type_id = '';
                            this.postQuestionEntity.community_id = '';
                            this.questionSendOptionsList = this.sendOptionsList;
                            this.globals.postQuestionLoading = false;
                            $('#question_text').val('');
                            this.countChar();
                            // Swal.fire({
                            //   icon: this.globals.commonTranslationText.home.form.alerts.postQuestion.type,
                            //   type: this.globals.commonTranslationText.home.form.alerts.postQuestion.type,
                            //   title: title,
                            //   text: text,
                            //   showCancelButton: false,
                            //   showConfirmButton: true,
                            //   confirmButtonClass: 'theme_btn',
                            //   confirmButtonText: 'Ok',
                            //   allowOutsideClick: false,
                            // }).then((result) => {
                            //   this.cookieService.delete('post_question');
                            //   this.postQuestionEntity = {};
                            //   postQuestionForm.form.markAsPristine();
                            //   window.location.href = '/userDashboard';
                            //   this.contact_submitted = false;
                            //   this.submitted = false;
                            //   this.submitted1 = false;
                            //   this.postQuestionEntity.question_type_id = '';
                            //   this.postQuestionEntity.community_id = '';
                            //   this.questionSendOptionsList = this.sendOptionsList;
                            //   this.globals.isLoading = false;
                            //   $('#question_text').val('');
                            //   this.countChar();
                            //   console.log(this.postQuestionEntity.question_text);
                            // });
                          },
                          (error) => {
                            this.globals.postQuestionLoading = false;
                            console.log(error);
                            if (error.status == 422) {
                              Swal.fire({
                                icon: 'warning',
                                type: 'warning',
                                title: 'Oops..',
                                text: error.error.message,
                                showCancelButton: false,
                                showConfirmButton: true,
                                confirmButtonClass: 'theme_btn',
                                confirmButtonText: 'Ok',
                                allowOutsideClick: false,
                              });
                            }
                            this.btn_disabled = false;
                          }
                        );
                        console.log(this.postQuestionEntity);
                      }
                    }
                  } else if (this.OptionsList.length == 6) {
                    if (
                      postQuestionForm.form.value.OptionValue0 ==
                        postQuestionForm.form.value.OptionValue1 ||
                      postQuestionForm.form.value.OptionValue0 ==
                        postQuestionForm.form.value.OptionValue2 ||
                      postQuestionForm.form.value.OptionValue0 ==
                        postQuestionForm.form.value.OptionValue3 ||
                      postQuestionForm.form.value.OptionValue0 ==
                        postQuestionForm.form.value.OptionValue4 ||
                      postQuestionForm.form.value.OptionValue0 ==
                        postQuestionForm.form.value.OptionValue5 ||
                      postQuestionForm.form.value.OptionValue1 ==
                        postQuestionForm.form.value.OptionValue2 ||
                      postQuestionForm.form.value.OptionValue1 ==
                        postQuestionForm.form.value.OptionValue3 ||
                      postQuestionForm.form.value.OptionValue1 ==
                        postQuestionForm.form.value.OptionValue4 ||
                      postQuestionForm.form.value.OptionValue1 ==
                        postQuestionForm.form.value.OptionValue5 ||
                      postQuestionForm.form.value.OptionValue2 ==
                        postQuestionForm.form.value.OptionValue3 ||
                      postQuestionForm.form.value.OptionValue2 == 4 ||
                      postQuestionForm.form.value.OptionValue2 ==
                        postQuestionForm.form.value.OptionValue5 ||
                      postQuestionForm.form.value.OptionValue3 ==
                        postQuestionForm.form.value.OptionValue4 ||
                      postQuestionForm.form.value.OptionValue3 ==
                        postQuestionForm.form.value.OptionValue5 ||
                      postQuestionForm.form.value.OptionValue4 ==
                        postQuestionForm.form.value.OptionValue5
                    ) {
                      Swal.fire({
                        icon: 'warning',
                        type: 'warning',
                        title: 'Oops..',
                        text: 'Options Should be Unique',
                        showCancelButton: false,
                        showConfirmButton: true,
                        confirmButtonClass: 'theme_btn',
                        confirmButtonText: 'Ok',
                        allowOutsideClick: false,
                      });
                    } else {
                      {
                        var fd = new FormData();
                        if (
                          this.postQuestionEntity.question_type_id ==
                            this.globals.configurationText.questionType
                              .pickList ||
                          this.postQuestionEntity.question_type_id ==
                            this.globals.configurationText.questionType
                              .multiPickList
                        ) {
                          this.postQuestionEntity.question_option =
                            this.OptionsList.filter(function (v) {
                              return $.trim(v.OptionValue) !== '';
                            });
                        } else if (
                          this.postQuestionEntity.question_type_id ==
                          this.globals.configurationText.questionType.rankedList
                        ) {
                          this.postQuestionEntity.question_option =
                            this.RankList.filter(function (v) {
                              return $.trim(v.OptionValue) !== '';
                            });
                        }
                        if ($('input[name="multiSelection"]').is(':checked')) {
                          this.postQuestionEntity.question_type_id =
                            $('#multiSelection').val();
                        }
                        if (
                          this.postQuestionEntity.image_video_path != '' &&
                          this.postQuestionEntity.image_video_path != undefined
                        ) {
                          fd.append(
                            'image_video',
                            this.postQuestionEntity.image_video
                          );
                          fd.append('path', this.postQuestionEntity.path);
                          fd.append(
                            'name',
                            this.postQuestionEntity.image_video
                          );
                        }
                        //debugger;
                        if (this.postQuestionEntity.image_video == undefined) {
                          fd.append('image_video_url', '[]');
                        } else {
                          fd.append(
                            'image_video_url',
                            JSON.stringify(this.postQuestionEntity.image_video)
                          );
                        }
                        // var users_details = [];
                        // users_details = this.postQuestionEntity.contact_details.split(",");
                        if (
                          this.postQuestionEntity.contact_details !=
                            undefined &&
                          $.type(this.postQuestionEntity.contact_details) !=
                            'array'
                        ) {
                          this.postQuestionEntity.contact_details =
                            this.postQuestionEntity.contact_details.split(',');
                        }
                        //  else {
                        //   this.postQuestionEntity.contact_details = [''];
                        // }
                        console.log(this.postQuestionEntity.contact_details);
                        console.log(
                          JSON.stringify(
                            this.postQuestionEntity.contact_details
                          )
                        );
                        fd.append(
                          'question_text',
                          this.postQuestionEntity.question_text
                        );
                        fd.append('post_as', this.postQuestionEntity.postas);
                        fd.append(
                          'question_type_id',
                          this.postQuestionEntity.question_type_id
                        );
                        //debugger;
                        if (this.postQuestionEntity.question_option) {
                          fd.append(
                            'question_option',
                            JSON.stringify(
                              this.postQuestionEntity.question_option
                            )
                          );
                        }
                        if (
                          this.postQuestionEntity.contact_details !=
                            undefined &&
                          this.postQuestionEntity.contact_details != ''
                        ) {
                          var phoneformat = /^([0-9()_ ]{10,14})+$/;
                          for (
                            var a = 0;
                            a < this.postQuestionEntity.contact_details.length;
                            a++
                          ) {
                            if (
                              this.postQuestionEntity.contact_details[a].match(
                                phoneformat
                              )
                            ) {
                              this.postQuestionEntity.contact_details[a] =
                                this.postQuestionEntity.contact_details[
                                  a
                                ].replace(/\D/g, '');
                            }
                          }
                          // this.postQuestionEntity.contact_details.filter(function(v){return v!==''})
                          fd.append(
                            'contact_details',
                            JSON.stringify(
                              this.postQuestionEntity.contact_details.filter(
                                function (v) {
                                  return $.trim(v) !== '';
                                }
                              )
                            )
                          );
                        }
                        var community_id =
                          '183c0d0d-508d-11ee-b584-9840bb1952c8';
                        this.UserCommunity.push(community_id);
                        this.postQuestionEntity.community_id =
                          this.UserCommunity;
                        fd.append(
                          'community_id',
                          JSON.stringify(this.postQuestionEntity.community_id)
                        );
                        fd.append(
                          'answer_only_to_me',
                          this.postQuestionEntity.answer_only_to_me
                            ? this.postQuestionEntity.answer_only_to_me
                            : ''
                        );
                        fd.append(
                          'invitee_can_invite_others',
                          this.postQuestionEntity.invitee_can_invite_others
                            ? this.postQuestionEntity.invitee_can_invite_others
                            : ''
                        );
                        fd.append(
                          'invitees_only_to_me',
                          this.postQuestionEntity.invitees_only_to_me
                            ? this.postQuestionEntity.invitees_only_to_me
                            : ''
                        );
                        fd.append(
                          'send_anonymously',
                          this.postQuestionEntity.send_anonymously
                            ? this.postQuestionEntity.send_anonymously
                            : ''
                        );
                        fd.append('question_status_id', value);
                        fd.append('user_id', this.globals.authData.id);
                        this.btn_disabled = true;
                        if (
                          value ==
                          this.globals.configurationText.questionStatus
                            .saveAsDraft
                        ) {
                          setTimeout(() => {
                            this.globals.postQuestionText =
                              'Please wait, saving thread as draft…';
                            this.globals.postQuestionLoading = true;
                          }, 300);
                        } else {
                          this.globals.postQuestionText =
                            'Please wait, publishing your thread…';
                          this.globals.postQuestionLoading = true;
                        }
                        this.HomeService.postQuestion(fd).then(
                          (data) => {
                            console.log(data);
                            if (
                              value ==
                              this.globals.configurationText.questionStatus
                                .saveAsDraft
                            ) {
                              var title = 'Save As Draft';
                              var text = 'Thread has been drafted successfully';
                            } else {
                              var title = 'Post Thread';
                              var text = 'Thread has been posted successfully';
                            }
                            this.cookieService.set(
                              'successMessage',
                              text,
                              this.globals.cookieMessageExpireTime,
                              '/'
                            );
                            this.cookieService.delete('post_question');
                            this.postQuestionEntity = {};
                            postQuestionForm.form.markAsPristine();
                            window.location.href = '/userDashboard';
                            this.contact_submitted = false;
                            this.submitted = false;
                            this.submitted1 = false;
                            this.postQuestionEntity.question_type_id = '';
                            this.postQuestionEntity.community_id = '';
                            this.questionSendOptionsList = this.sendOptionsList;
                            this.globals.postQuestionLoading = false;
                            $('#question_text').val('');
                            this.countChar();
                            // Swal.fire({
                            //   icon: this.globals.commonTranslationText.home.form.alerts.postQuestion.type,
                            //   type: this.globals.commonTranslationText.home.form.alerts.postQuestion.type,
                            //   title: title,
                            //   text: text,
                            //   showCancelButton: false,
                            //   showConfirmButton: true,
                            //   confirmButtonClass: 'theme_btn',
                            //   confirmButtonText: 'Ok',
                            //   allowOutsideClick: false,
                            // }).then((result) => {
                            //   this.cookieService.delete('post_question');
                            //   this.postQuestionEntity = {};
                            //   postQuestionForm.form.markAsPristine();
                            //   window.location.href = '/userDashboard';
                            //   this.contact_submitted = false;
                            //   this.submitted = false;
                            //   this.submitted1 = false;
                            //   this.postQuestionEntity.question_type_id = '';
                            //   this.postQuestionEntity.community_id = '';
                            //   this.questionSendOptionsList = this.sendOptionsList;
                            //   this.globals.isLoading = false;
                            //   $('#question_text').val('');
                            //   this.countChar();
                            //   console.log(this.postQuestionEntity.question_text);
                            // });
                          },
                          (error) => {
                            this.globals.postQuestionLoading = false;
                            console.log(error);
                            if (error.status == 422) {
                              Swal.fire({
                                icon: 'warning',
                                type: 'warning',
                                title: 'Oops..',
                                text: error.error.message,
                                showCancelButton: false,
                                showConfirmButton: true,
                                confirmButtonClass: 'theme_btn',
                                confirmButtonText: 'Ok',
                                allowOutsideClick: false,
                              });
                            }
                            this.btn_disabled = false;
                          }
                        );
                        console.log(this.postQuestionEntity);
                      }
                    }
                  }
                } else {
                  {
                    this.errorMessage = '';
                    var fd = new FormData();
                    if (
                      this.postQuestionEntity.question_type_id ==
                        this.globals.configurationText.questionType.pickList ||
                      this.postQuestionEntity.question_type_id ==
                        this.globals.configurationText.questionType
                          .multiPickList
                    ) {
                      this.postQuestionEntity.question_option =
                        this.OptionsList.filter(function (v) {
                          return $.trim(v.OptionValue) !== '';
                        });
                    } else if (
                      this.postQuestionEntity.question_type_id ==
                      this.globals.configurationText.questionType.rankedList
                    ) {
                      this.postQuestionEntity.question_option =
                        this.RankList.filter(function (v) {
                          return $.trim(v.OptionValue) !== '';
                        });
                    }
                    if ($('input[name="multiSelection"]').is(':checked')) {
                      this.postQuestionEntity.question_type_id =
                        $('#multiSelection').val();
                    }
                    if (this.postQuestionEntity.image_video == undefined) {
                      fd.append('image_video_url', '[]');
                    } else {
                      fd.append(
                        'image_video_url',
                        JSON.stringify(this.postQuestionEntity.image_video)
                      );
                    }
                    //debugger;
                    // var users_details = [];
                    // users_details = this.postQuestionEntity.contact_details.split(",");
                    if (
                      this.postQuestionEntity.contact_details != undefined &&
                      $.type(this.postQuestionEntity.contact_details) != 'array'
                    ) {
                      this.postQuestionEntity.contact_details =
                        this.postQuestionEntity.contact_details.split(',');
                    }
                    //  else {
                    //   this.postQuestionEntity.contact_details = [''];
                    // }
                    console.log(this.postQuestionEntity.contact_details);
                    console.log(
                      JSON.stringify(this.postQuestionEntity.contact_details)
                    );
                    fd.append(
                      'question_text',
                      this.postQuestionEntity.question_text
                    );
                    fd.append('post_as', this.postQuestionEntity.postas);
                    fd.append(
                      'question_type_id',
                      this.postQuestionEntity.question_type_id
                    );
                    //debugger;
                    if (this.postQuestionEntity.question_option) {
                      fd.append(
                        'question_option',
                        JSON.stringify(this.postQuestionEntity.question_option)
                      );
                    }
                    if (
                      this.postQuestionEntity.contact_details != undefined &&
                      this.postQuestionEntity.contact_details != ''
                    ) {
                      var phoneformat = /^([0-9()_ ]{10,14})+$/;
                      for (
                        var a = 0;
                        a < this.postQuestionEntity.contact_details.length;
                        a++
                      ) {
                        if (
                          this.postQuestionEntity.contact_details[a].match(
                            phoneformat
                          )
                        ) {
                          this.postQuestionEntity.contact_details[a] =
                            this.postQuestionEntity.contact_details[a].replace(
                              /\D/g,
                              ''
                            );
                        }
                      }
                      // this.postQuestionEntity.contact_details.filter(function(v){return v!==''})
                      fd.append(
                        'contact_details',
                        JSON.stringify(
                          this.postQuestionEntity.contact_details.filter(
                            function (v) {
                              return $.trim(v) !== '';
                            }
                          )
                        )
                      );
                    }
                    var community_id = '183c0d0d-508d-11ee-b584-9840bb1952c8';
                    this.UserCommunity.push(community_id);
                    this.postQuestionEntity.community_id = this.UserCommunity;
                    fd.append(
                      'community_id',
                      JSON.stringify(this.postQuestionEntity.community_id)
                    );
                    fd.append(
                      'answer_only_to_me',
                      this.postQuestionEntity.answer_only_to_me
                        ? this.postQuestionEntity.answer_only_to_me
                        : ''
                    );
                    fd.append(
                      'invitee_can_invite_others',
                      this.postQuestionEntity.invitee_can_invite_others
                        ? this.postQuestionEntity.invitee_can_invite_others
                        : ''
                    );
                    fd.append(
                      'invitees_only_to_me',
                      this.postQuestionEntity.invitees_only_to_me
                        ? this.postQuestionEntity.invitees_only_to_me
                        : ''
                    );
                    fd.append(
                      'send_anonymously',
                      this.postQuestionEntity.send_anonymously
                        ? this.postQuestionEntity.send_anonymously
                        : ''
                    );
                    fd.append('question_status_id', value);
                    fd.append('user_id', this.globals.authData.id);
                    this.btn_disabled = true;
                    if (
                      value ==
                      this.globals.configurationText.questionStatus.saveAsDraft
                    ) {
                      setTimeout(() => {
                        this.globals.postQuestionText =
                          'Please wait, saving thread as draft…';
                        this.globals.postQuestionLoading = true;
                      }, 300);
                    } else {
                      this.globals.postQuestionText =
                        'Please wait, publishing your thread…';
                      this.globals.postQuestionLoading = true;
                    }
                    this.HomeService.postQuestion(fd).then(
                      (data) => {
                        console.log(data);
                        if (
                          value ==
                          this.globals.configurationText.questionStatus
                            .saveAsDraft
                        ) {
                          var title = 'Save As Draft';
                          var text = 'Thread has been drafted successfully';
                        } else {
                          var title = 'Post Thread';
                          var text = 'Thread has been posted successfully';
                        }
                        this.cookieService.set(
                          'successMessage',
                          text,
                          this.globals.cookieMessageExpireTime,
                          '/'
                        );
                        this.cookieService.delete('post_question');
                        this.postQuestionEntity = {};
                        postQuestionForm.form.markAsPristine();
                        window.location.href = '/userDashboard';
                        this.contact_submitted = false;
                        this.submitted = false;
                        this.submitted1 = false;
                        this.postQuestionEntity.question_type_id = '';
                        // this.postQuestionEntity.community_id = '';
                        this.questionSendOptionsList = this.sendOptionsList;
                        this.globals.postQuestionLoading = false;
                        $('#question_text').val('');
                        this.countChar();
                      },
                      (error) => {
                        this.globals.postQuestionLoading = false;
                        console.log(error);
                        if (error.status == 422) {
                          Swal.fire({
                            type: this.globals.configurationText
                              .sweetAlertTypeIconError,
                            icon: this.globals.configurationText
                              .sweetAlertTypeIconError,
                            customClass:
                              this.globals.configurationText
                                .sweetAlertClassError,
                            toast: true,
                            title: error.error.message,
                            animation: true,
                            position:
                              this.globals.configurationText.sweetAlertPosition,
                            showConfirmButton: false,
                            timer:
                              this.globals.configurationText.sweetAlertTimer,
                            timerProgressBar: true,
                            showCancelButton: true,
                            cancelButtonText: 'x',
                          });
                        }
                        this.btn_disabled = false;
                      }
                    );
                    console.log(this.postQuestionEntity);
                  }
                }
              }
            }
          }
        }
      }
      // else{
      //   alert("no")
      // }
    }
  }

  likeDislikeQuestionAnswer(question, is_like) {
    var id = question.id;
    //disable the button
    $('li.like_question a#thumb' + id).addClass('disabled_btn').parents('.padding_boxs').addClass('disabled_post');

    this.globals.likeDislikeQuestionAnswer(question.id, is_like, '').then(
      (data) => {
        console.log(data);
        if (is_like == 1) {
          question.likes = question.likes + 1;
          question.is_like = 1;
          $('li.like_question a#thumb' + id).parents('.padding_boxs').removeClass('disabled_post');;
        } else {
          question.likes = question.likes - 1;
          question.is_like = null;
          $('li.like_question a#thumb' + id).parents('.padding_boxs').removeClass('disabled_post');;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  topQuestions(id) {
    this.HomeService.getTopQuestion('top_questions', id).then(
      (data) => {
        console.log(data);
        this.topQuestionList = this.globals.extensionCheck(data['data']);
        for (let i = 0; i < data['data'].length; i++) {
          this.imageVideoName.push({
            data: data['data'][i].filedata,
          });
        }
        console.log(this.imageVideoName);
        // this.imageVideoURL = this.imageVideoName
        // console.log(this.imageVideoURL)
        this.questionLoader = false;
      },
      (error) => {
        this.questionLoader = false;
        console.log(error);
      }
    );
  }

  topDecideItStars() {
    this.globals.topDecideItStars('decideit_stars', '', 10).then(
      (data) => {
        console.log(data);
        this.decideItStarList = data['data'];
        this.decideItStarLoader = false;
      },
      (error) => {
        this.decideItStarLoader = false;
        console.log(error);
      }
    );
  }
  questionDetail(id) {
    this.globals.viewCount = 1;
    this.router.navigate(['/questionDetails/' + id]);
  }
  topCommunityList() {
    window.location.href = '/communityList';
  }
  publicProfile(id, value) {
    if (value == '') {
      window.location.href = '/publicProfile/' + window.btoa(id);
    } else {
      window.location.href =
        '/publicProfile/' + window.btoa(id) + '/' + window.btoa(value);
    }
  }
  // publicProfile(id) {
  //   window.location.href = '/publicProfile/' + window.btoa(id);
  // }
  decideItStars() {
    window.location.href = '/decideItStars/' + window.btoa('star');
  }
  communityQuestion(id) {
    window.location.href = '/communityQuestions/' + window.btoa(id);
  }

  handleFilter(value) {
    var entity = this.filterCommunityList;
    this.searchCommunityList = entity.filter(
      (s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
}
